import React, { useState, useEffect } from 'react'
import { Flex, Link, Tag, useColorModeValue } from '@chakra-ui/react'
import { useLocation } from 'react-router-dom'
import axios from 'api/axios'
import authHeader from 'hooks/authHeader'
import Card from 'components/card/Card'
import { getUserAccountId } from 'hooks/authService'
import Steps from './pipelineStep'
import PipelineForm from '../../formPipeline/components/formPipeline'
import { FaPlus } from 'react-icons/fa'
import NewPipelineStepForm from './newPipelineStep'
import { PipelineProvider } from 'contexts/pipeline-context'

export default function Pipeline(props) {
  const textColor = useColorModeValue('navy.700', 'white')

  const accountId = getUserAccountId()
  const location = useLocation()
  const pipelineIdParams = parseInt(location.pathname.replace('/admin/pipeline/', ''), 10)

  const [pipelineList, setPipelineList] = useState([])
  const [pipelineId, setPipelineId] = useState(null)
  const [stepsList, setStepsList] = useState()
  const [showPipelineForm, setShowPipelineForm] = useState(false)
  const [showPipeline, setShowPipeline] = useState(false)
  const [accountDeals, setAccountDeals] = useState({})

  useEffect(() => {
    getAccountPipelines()
    getAccountDeals()
    console.log('stepsList', stepsList)
  }, [])

  useEffect(() => {}, [stepsList])

  // useEffect(() => {
  //   // !isNaN(pipelineIdParams) ? getPipelineSteps(pipelineIdParams) : getPipelineSteps(pipelineId)
  // }, [pipelineIdParams, pipelineList, pipelineId])

  async function getAccountPipelines() {
    if (!accountId) return

    try {
      const response = await axios.get(`/pipelines?account_id=${accountId}&$sort[order]=1`, {
        headers: authHeader()
      })
      console.log('response.data.data', response.data.data)
      setPipelineList(response.data.data)
      if (pipelineIdParams) {
        const pipeline = response.data.data.find((pipeline) => pipeline.id === pipelineIdParams)
        console.log('pipelineIdParams:', pipelineIdParams)
        console.log('pipeline:', pipeline)

        const orderedSteps = pipeline.steps.sort((a, b) => a.order - b.order)

        console.log('orderedSteps', orderedSteps)

        setStepsList(orderedSteps)
        setPipelineId(pipeline.id)
      } else {
        !pipelineIdParams && setPipelineId(response.data.data[0].id)
        const orderedSteps = response.data.data[0].steps.sort((a, b) => a.order - b.order)
        console.log('orderedSteps', orderedSteps)
        setStepsList(orderedSteps)
      }
      // console.log('Pipeline Response: ', response.data)
      // !pipelineIdParams && redirectToMainPipeline(response.data.data[0].id)
      setShowPipeline(true)
    } catch (error) {
      console.error('Houve um erro ao buscar os Pipelines da conta.', error)
    }
  }

  // async function getPipelineSteps(pipelineId) {
  //   if (!pipelineId) return

  //   try {
  //     const response = await axios.get(
  //       `/steps?account_id=${accountId}&pipeline_id=${pipelineId}&$sort[order]=1`,
  //       { headers: authHeader() }
  //     )
  //     setStepsList(response.data.data)
  //     setShowPipeline(true)
  //   } catch (error) {
  //     setShowPipelineForm(true)
  //     console.log('Erro ao carregar as etapas do pipeline', error)
  //   }
  // }

  async function getAccountDeals() {
    if (!accountId) return

    try {
      const response = await axios.get(`/deals?account_id=${accountId}`, { headers: authHeader() })
      const accountDeals = response.data.data.reduce((acc, item) => {
        const {
          id,
          account_id,
          name,
          step: { id: step_id },
          ...rest
        } = item

        // Inicializar o array se o step_id não existir no acumulador
        if (!acc[step_id]) {
          acc[step_id] = []
        }

        // Adicionar o deal ao array do step_id
        acc[step_id].push({
          id,
          account_id,
          name,
          ...rest
        })

        return acc
      }, {})
      setAccountDeals(accountDeals)
      console.log('Account Deals: ', accountDeals)
      // setDealList(response.data.data);
    } catch (error) {
      console.error('Houve um erro ao buscar os Deals da conta.', error)
    }
  }

  return (
    <PipelineProvider>
      <Flex flexDirection={'column'} gap="20px">
        <Card borderRadius="50px" p="10px">
          <Flex flexDirection={'row'}>
            {pipelineList.length > 0 &&
              pipelineList.map((pipeline) => (
                <Link href={`/admin/pipeline/${pipeline.id}`} key={pipeline.id} cursor={'pointer'}>
                  <Tag colorScheme="blue" p="10px 20px" borderRadius={'50px'} mr="10px">
                    {pipeline.name}
                  </Tag>
                </Link>
              ))}
            <Link
              onClick={() => {
                setShowPipelineForm(!showPipelineForm)
              }}
            >
              <Tag colorScheme="blue" p="10px 20px" borderRadius={'50px'}>
                <FaPlus />
              </Tag>
            </Link>
          </Flex>
        </Card>

        {
          showPipelineForm && (
            <PipelineForm />
          ) /* Caso não tenha nenhum pipeline, abre o form de criação de pipeline*/
        }

        {showPipeline && (
          <Flex flexDirection="row" gap="10px">
            {stepsList &&
              stepsList.length > 0 &&
              stepsList.map((step, index) => (
                <Steps
                  name={step.name}
                  objective={step.objective}
                  pipelineId={pipelineIdParams ? pipelineIdParams : pipelineId}
                  stepId={step.id}
                  index={index}
                  key={step.id}
                  stepDeals={accountDeals[step.id]}
                  stepsList={stepsList}
                />
              ))}
            <NewPipelineStepForm
              pipelineId={pipelineId ? pipelineId : pipelineList[0]?.id}
              stepsLength={stepsList ? stepsList.length : pipelineList[0].steps.length}
              onUpdate={getAccountPipelines}
              setStepsList={setStepsList}
              stepsList={stepsList}
            />
          </Flex>
        )}

        {/* <PipelineForm /> */}
      </Flex>
    </PipelineProvider>
  )
}
