import React, { useState, useEffect } from 'react'
import {
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  Flex,
  Input,
  Text,
  Textarea,
  useDisclosure
} from '@chakra-ui/react'
import { useParams } from 'react-router-dom'
import axios from 'api/axios'
import authHeader from 'hooks/authHeader'

import Card from 'components/card/Card'
import { useLocation } from 'react-router-dom'
import { getUserAccountId } from 'hooks/authService'
import PipelineForm from '../../formPipeline/components/formPipeline'
import { FaPlus } from 'react-icons/fa'
import { usePipelineContext } from 'contexts/pipeline-context'

export default function NewPipelineStepForm({ onUpdate, pipelineId, stepsLength }) {
  const account_id = getUserAccountId()
  const { onOpen, onClose, isOpen } = useDisclosure()
  const firstFieldRef = React.useRef(null)

  const location = useLocation()
  const pipelineIdFromParams = parseInt(location.pathname.replace('/admin/pipeline/', ''), 10)

  const { orderedSteps, setOrderedSteps } = usePipelineContext()

  const [newStep, setNewStep] = useState({
    account_id,
    pipeline_id: pipelineIdFromParams,
    name: '',
    objective: '',
    order: stepsLength + 1
  })

  useEffect(() => {
    console.log('pipelineIdFromParams NewStepForm', pipelineIdFromParams)
  }, [pipelineIdFromParams])

  const handleStepChange = (event) => {
    const { name, value } = event.target
    setNewStep((prevStep) => ({
      ...prevStep,
      [name]: value
    }))
  }

  const handleStepSubmit = async (event) => {
    event.preventDefault()
    try {
      console.log('New Step: handler ', newStep)
      const response = await axios.post('/steps', newStep, { headers: authHeader() })
      setOrderedSteps([...orderedSteps, response.data])
      onUpdate(response.data.pipeline_id)
      onClose()
      console.log('Dados enviados com sucesso!', response.data)
    } catch (error) {
      console.error('Ocorreu um erro ao enviar os dados:', error)
    }
  }

  return (
    <Popover
      isOpen={isOpen}
      initialFocusRef={firstFieldRef}
      onOpen={onOpen}
      onClose={onClose}
      placement="right"
      closeOnBlur={false}
    >
      <PopoverTrigger>
        <Flex
          w="40px"
          h="100px"
          bg="white"
          borderRadius="20px"
          justifyContent={'center'}
          alignItems="center"
          cursor="pointer"
          background="linear-gradient(to bottom, rgba(255, 255, 255, 1) 10%, rgba(255, 255, 255, 0.2) 100%)"
        >
          <FaPlus color="#4A5568" />
        </Flex>
      </PopoverTrigger>
      <PopoverContent
        width={'270px'}
        p="20px"
        boxShadow={'xl'}
        borderRadius={'30px 10px 30px 30px'}
        mr="20px"
        mb="20px"
        _focus={{ border: 'none' }}
      >
        <Text fontSize="16px" fontWeight={'800'} mb="-10px" mt="-5px">
          Nova etapa
        </Text>
        <Flex flexDirection="column" pt="20px">
          <form onSubmit={handleStepSubmit}>
            <Input
              value={newStep.name}
              name="name"
              onChange={handleStepChange}
              variant="auth"
              fontSize="sm"
              placeholder="Nova etapa"
              mb="8px"
              size="md"
              borderRadius="20px"
              ref={firstFieldRef}
            />
            <Textarea
              value={newStep.objective}
              name="objective"
              onChange={handleStepChange}
              variant="filled"
              fontSize="sm"
              placeholder="Objetivo da etapa"
              style={{ border: '1px solid #e0e5f2' }}
              borderRadius={'20px'}
              size="md"
            />
            <Button mt="10px" type="submit" colorScheme="blue" background={'#49BFFF'} borderRadius={'50px'}>
              Adicionar etapa
            </Button>
          </form>
        </Flex>
        <PopoverArrow />
        <PopoverCloseButton mt="3px" />
      </PopoverContent>
    </Popover>
  )
}
