import React, { useEffect, useState } from 'react'
import { Box, Flex, Icon, Tag, Text, useColorModeValue } from '@chakra-ui/react'
import BarChart from 'components/charts/BarChart'
import Card from 'components/card/Card.js'
import { RiArrowUpSFill } from 'react-icons/ri'
import { getUserAccountId } from 'hooks/authService'
import axios from 'api/axios'
import authHeader from 'hooks/authHeader'
import formatDateDDMMYYYY from 'hooks/formatDateDDMMYYYY'

export default function LastActivities(props) {
  const { total, doneActivities, ...rest } = props

  const activities = countDoneActivitiesByDate(doneActivities)
  // console.log('results', results)

  function countDoneActivitiesByDate(activities) {
    if (!Array.isArray(activities)) {
      throw new Error('O parâmetro deve ser um array de atividades.')
    }

    const now = new Date()
    const startOfToday = new Date(now.getFullYear(), now.getMonth(), now.getDate())
    const startOfYesterday = new Date(startOfToday)
    startOfYesterday.setDate(startOfToday.getDate() - 1)

    const startOfWeek = new Date(startOfToday)
    startOfWeek.setDate(startOfToday.getDate() - startOfToday.getDay())

    const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1)

    const totals = {
      today: 0,
      yesterday: 0,
      thisWeek: 0,
      thisMonth: 0
    }

    activities.forEach((activity) => {
      if (activity.status !== 'done' || !activity.updated_at) return

      const updatedAt = new Date(activity.updated_at)

      if (updatedAt >= startOfToday) {
        totals.today++
      } else if (updatedAt >= startOfYesterday && updatedAt < startOfToday) {
        totals.yesterday++
      }

      if (updatedAt >= startOfWeek) {
        totals.thisWeek++
      }

      if (updatedAt >= startOfMonth) {
        totals.thisMonth++
      }
    })

    return totals
  }

  // Chakra Color Mode
  const textColor = useColorModeValue('secondaryGray.900', 'white')
  return (
    <Card align="center" direction="column" w="100%" {...rest}>
      <Flex justify="space-between" align="start" px="10px" pt="5px">
        <Flex flexDirection="column" align="start" me="20px" mb="12px">
          <Flex w="100%" gap={'10px'}>
            <Text me="auto" color="secondaryGray.600" fontSize="sm" fontWeight="500">
              Atividades Realizadas
            </Text>
            <Text me="auto" color="secondaryGray.600" fontSize="sm" fontWeight="500">
              Hoje: {activities.today}
            </Text>
            <Text me="auto" color="secondaryGray.600" fontSize="sm" fontWeight="500">
              Na semana: {activities.thisWeek}
            </Text>
            <Text me="auto" color="secondaryGray.600" fontSize="sm" fontWeight="500">
              No mês: {activities.thisMonth}
            </Text>
          </Flex>
        </Flex>
        <Flex align="center">
          <Icon as={RiArrowUpSFill} color="green.500" />
          <Text color="green.500" fontSize="sm" fontWeight="700">
            +2.45%
          </Text>
        </Flex>
      </Flex>
      {/* <Box h="240px" mt="auto">
        <BarChart chartData={barChartDataDailyTraffic} chartOptions={barChartOptionsDailyTraffic} />
      </Box> */}
      <Box
        maxH="400px"
        overflowY="auto"
        mt="auto"
        // border="1px solid #e2e8f0"
        borderRadius="md"
        css={{
          '&::-webkit-scrollbar': {
            width: '8px'
          },
          '&::-webkit-scrollbar-thumb': {
            background: '#cbd5e0',
            borderRadius: '4px'
          },
          '&::-webkit-scrollbar-thumb:hover': {
            background: '#a0aec0'
          }
        }}
      >
        <Flex h="240px" flexDirection="column">
          {doneActivities &&
            doneActivities.map((activity, index) => (
              <Flex gap="5px" mb="5px" key={index}>
                <Tag w="90px">{formatDateDDMMYYYY(activity.updated_at)}</Tag>
                <Tag>{activity.type}</Tag>
                <Tag>{activity.owner.first_name}</Tag>
                <Tag isTruncated noOfLines={1} maxWidth="65%">
                  {activity.description}
                </Tag>
              </Flex>
            ))}
        </Flex>
      </Box>
    </Card>
  )
}
