import { Button, Flex, Grid, GridItem, Text } from '@chakra-ui/react'
import { estadosUF } from 'contexts/dadosBrasileiros'
import { Dropdown } from 'primereact/dropdown'
import { InputText } from 'primereact/inputtext'
import { useState } from 'react'
import { FaAlgolia, FaBuilding, FaLocationArrow, FaSearch } from 'react-icons/fa'

export function FormAddress() {
  const [address, setAddress] = useState({
    cep: '',
    pais: 'Brasil',
    endereco: '',
    numero: '',
    complemento: '',
    bairro: '',
    estado: '',
    municipio: ''
  })

  function handleChange(e) {
    const { name, value } = e.target
    setAddress((prevState) => ({
      ...prevState,
      [name]: value
    }))
  }

  async function getAddress(cep) {
    return
  }

  return (
    <Flex flexDirection={'column'} gap={'20px'} p="10px">
      <Text fontSize={'18px'} fontWeight={'800'}>
        Novo Endereço
      </Text>
      <Grid templateColumns="repeat(6, 1fr)" gap={'0px 20px'} w={'100%'}>
        <GridItem w="100%" colSpan={3}>
          <Flex flexDirection={'column'}>
            <label
              style={{
                color: '#A0AEC0',
                fontSize: '12px',
                fontWeight: '500',
                paddingLeft: '0px'
              }}
            >
              CEP (Busca automática)
            </label>
            <Flex py="0.3rem" mt="-5px" alignItems={'center'}>
              <div className="p-inputgroup flex-1">
                <span className="p-inputgroup-addon">
                  <FaLocationArrow />
                </span>
                <InputText
                  value={address.cep}
                  name="cep"
                  onChange={handleChange}
                  placeholder="CEP"
                  v-model="value1"
                  style={{
                    padding: ' 0px 10px',
                    width: '100%',
                    height: '45px',
                    background: '#fafafa',
                    fontSize: '14px'
                  }}
                  className="outline-none"
                />
                <button
                  className="p-inputgroup-addon"
                  cursor={'pointer'}
                  style={{ background: '#f3f4f6' }}
                  onClick={() => getAddress('formData.business_data.cnpj')}
                >
                  <FaSearch />
                </button>
              </div>
            </Flex>
          </Flex>
        </GridItem>
        <GridItem w="100%" colSpan={3}>
          <Flex flexDirection={'column'}>
            <label
              style={{
                color: '#A0AEC0',
                fontSize: '12px',
                fontWeight: '500',
                paddingLeft: '0px'
              }}
            >
              País
            </label>
            <Flex py="0.3rem" mt="-5px" alignItems={'center'}>
              <div className="p-inputgroup flex-1">
                {/* <span className="p-inputgroup-addon">
                  <FaLocationArrow />
                </span> */}
                <InputText
                  value={address.pais}
                  name="pais"
                  onChange={handleChange}
                  placeholder="País"
                  v-model="value1"
                  style={{
                    padding: ' 0px 10px',
                    width: '100%',
                    height: '45px',
                    background: '#fafafa',
                    fontSize: '14px'
                  }}
                  className="outline-none"
                />
              </div>
            </Flex>
          </Flex>
        </GridItem>
        <GridItem w="100%" colSpan={4}>
          <Flex flexDirection={'column'} mb="15px">
            <label
              style={{
                color: '#A0AEC0',
                fontSize: '12px',
                fontWeight: '500',
                paddingLeft: '0px'
              }}
            >
              Endereço
            </label>
            <Flex py="0.3rem" mt="-5px" alignItems={'center'}>
              <div className="p-inputgroup flex-1">
                {/* <span className="p-inputgroup-addon">
                  <FaLocationArrow />
                </span> */}
                <InputText
                  value={address.endereco}
                  name="endereco"
                  onChange={handleChange}
                  placeholder="..."
                  v-model="value1"
                  style={{
                    padding: ' 0px 10px',
                    width: '100%',
                    height: '45px',
                    background: '#fafafa',
                    fontSize: '14px'
                  }}
                  className="outline-none"
                />
              </div>
            </Flex>
          </Flex>
        </GridItem>
        <GridItem w="100%" colSpan={2}>
          <Flex flexDirection={'column'} mb="15px">
            <label
              style={{
                color: '#A0AEC0',
                fontSize: '12px',
                fontWeight: '500',
                paddingLeft: '0px'
              }}
            >
              Número
            </label>
            <Flex py="0.3rem" mt="-5px" alignItems={'center'}>
              <div className="p-inputgroup flex-1">
                <InputText
                  value={address.numero}
                  name="numero"
                  onChange={handleChange}
                  placeholder="Número"
                  v-model="value1"
                  style={{
                    padding: ' 0px 10px',
                    width: '100%',
                    height: '40px',
                    background: '#fafafa',
                    fontSize: '14px'
                  }}
                  className="outline-none"
                />
              </div>
            </Flex>
          </Flex>
        </GridItem>
        <GridItem w="100%" colSpan={3}>
          <Flex flexDirection={'column'} mb="15px">
            <label
              style={{
                color: '#A0AEC0',
                fontSize: '12px',
                fontWeight: '500',
                paddingLeft: '0px'
              }}
            >
              Complemento
            </label>
            <Flex py="0.3rem" mt="-5px" alignItems={'center'}>
              <div className="p-inputgroup flex-1">
                {/* <span className="p-inputgroup-addon">
                  <FaLocationArrow />
                </span> */}
                <InputText
                  value={address.complemento}
                  name="complemento"
                  onChange={handleChange}
                  placeholder="Complemento"
                  v-model="value1"
                  style={{
                    padding: ' 0px 10px',
                    width: '100%',
                    height: '45px',
                    background: '#fafafa',
                    fontSize: '14px'
                  }}
                  className="outline-none"
                />
              </div>
            </Flex>
          </Flex>
        </GridItem>
        <GridItem w="100%" colSpan={3}>
          <Flex flexDirection={'column'} mb="15px">
            <label
              style={{
                color: '#A0AEC0',
                fontSize: '12px',
                fontWeight: '500',
                paddingLeft: '0px'
              }}
            >
              Bairro
            </label>
            <Flex py="0.3rem" mt="-5px" alignItems={'center'}>
              <div className="p-inputgroup flex-1">
                {/* <span className="p-inputgroup-addon">
                  <FaLocationArrow />
                </span> */}
                <InputText
                  value={address.bairro}
                  name="bairro"
                  onChange={handleChange}
                  placeholder="Bairro"
                  v-model="value1"
                  style={{
                    padding: ' 0px 10px',
                    width: '100%',
                    height: '45px',
                    background: '#fafafa',
                    fontSize: '14px'
                  }}
                  className="outline-none"
                />
              </div>
            </Flex>
          </Flex>
        </GridItem>
        <GridItem w="100%" colSpan={3}>
          <label
            style={{
              color: '#A0AEC0',
              fontSize: '12px',
              fontWeight: '500',
              paddingLeft: '0px',
              marginBottom: '-15px'
            }}
          >
            Estado
          </label>
          <div className="p-inputgroup flex-1">
            {/* <span className="p-inputgroup-addon">
              <FaBuilding />
            </span> */}
            <Dropdown
              value={address.estado}
              name="estado"
              onChange={handleChange}
              options={estadosUF}
              optionLabel="name"
              optionValue="id"
              placeholder="Selecione um estado"
              filter
              // valueTemplate={selectedCountryTemplate}
              // itemTemplate={countryOptionTemplate}
              // className="w-full md:w-16rem text-base"
              pt={{
                root: { width: '100%' },
                input: {
                  style: {
                    width: '100%',
                    background: '#fafafa',
                    fontSize: '14px'
                  }
                },
                trigger: {
                  style: {
                    background: '#fafafa',
                    fontSize: '14px'
                  }
                },
                item: {
                  style: {
                    fontSize: '14px',
                    padding: '8px 16px',
                    width: '100%',
                    background: '#fafafa'
                  }
                },
                panel: {
                  style: { marginTop: '5px' }
                }
              }}
            />
          </div>
        </GridItem>
        <GridItem w="100%" colSpan={3}>
          <label
            style={{
              color: '#A0AEC0',
              fontSize: '12px',
              fontWeight: '500',
              paddingLeft: '0px',
              marginBottom: '-15px'
            }}
          >
            Município
          </label>
          <div className="p-inputgroup flex-1">
            {/* <span className="p-inputgroup-addon">
              <FaBuilding />
            </span> */}
            <Dropdown
              value={address.municipio}
              name="municipio"
              onChange={handleChange}
              options={estadosUF}
              optionLabel="name"
              optionValue="id"
              placeholder="Selecione um município"
              filter
              // valueTemplate={selectedCountryTemplate}
              // itemTemplate={countryOptionTemplate}
              // className="w-full md:w-16rem text-base"
              pt={{
                root: { width: '100%' },
                input: {
                  style: {
                    width: '100%',
                    background: '#fafafa',
                    fontSize: '14px'
                  }
                },
                trigger: {
                  style: {
                    background: '#fafafa',
                    fontSize: '14px'
                  }
                },
                item: {
                  style: {
                    fontSize: '14px',
                    padding: '8px 16px',
                    width: '100%',
                    background: '#fafafa'
                  }
                },
                panel: {
                  style: { marginTop: '5px' }
                }
              }}
            />
          </div>
        </GridItem>
      </Grid>
      <Flex justifyContent={'right'}>
        <Button
          background={'#49BFFF'}
          colorScheme="blue"
          onClick={'d'}
          width={'fit-content'}
          borderRadius={'50px'}
        >
          Salvar
        </Button>
      </Flex>
    </Flex>
  )
}
