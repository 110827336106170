export const porteEmpresas = [
  { name: 'MEI', value: 'MEI' },
  { name: 'Microempresa (Até R$ 360mil/ano)', value: 'ME' },
  { name: 'Empresa de Pequeno Porte (R$ 360mil à R$ 4,8mi/ano)', value: 'EPP' },
  { name: 'Média Empresa I (Até R$ 6mi/ano)', value: 'Grupo IV' },
  { name: 'Média Empresa II (R$ 6mi à R$ 20mi/ano)', value: 'Grupo III' },
  { name: 'Grande Empresa I (R$ 20mi à R$ 50mi/ano)', value: 'Grupo II' },
  { name: 'Grande Empresa II (Mais que R$ 50mi/ano)', value: 'Grupo I' }
]

export const situacaoCadastral = [
  { value: 'ATIVA', name: 'Ativa' },
  { value: 'BAIXADA', name: 'Baixada' },
  { value: 'INAPTA', name: 'Inapta' },
  { value: 'SUSPENSA', name: 'Suspensa' },
  { value: 'NULA', name: 'Nula' }
]

export const estadosUF = [
  { value: 'AC', name: 'AC' },
  { value: 'AL', name: 'AL' },
  { value: 'AP', name: 'AP' },
  { value: 'AM', name: 'AM' },
  { value: 'BA', name: 'BA' },
  { value: 'CE', name: 'CE' },
  { value: 'ES', name: 'ES' },
  { value: 'GO', name: 'GO' },
  { value: 'MA', name: 'MA' },
  { value: 'MT', name: 'MT' },
  { value: 'MS', name: 'MS' },
  { value: 'MG', name: 'MG' },
  { value: 'PA', name: 'PA' },
  { value: 'PB', name: 'PB' },
  { value: 'PR', name: 'PR' },
  { value: 'PE', name: 'PE' },
  { value: 'PI', name: 'PI' },
  { value: 'RJ', name: 'RJ' },
  { value: 'RN', name: 'RN' },
  { value: 'RS', name: 'RS' },
  { value: 'RO', name: 'RO' },
  { value: 'RR', name: 'RR' },
  { value: 'SC', name: 'SC' },
  { value: 'SP', name: 'SP' },
  { value: 'SE', name: 'SE' },
  { value: 'TO', name: 'TO' }
]

export const listaMunicipios = [
  {
    value: 'Guajara-Mirim',
    name: 'Guajará-Mirim',
    uf: 'RO'
  },
  {
    value: 'Alto Alegre dos Parecis',
    name: 'Alto Alegre dos Parecis',
    uf: 'RO'
  },
  {
    value: 'Porto Velho',
    name: 'Porto Velho',
    uf: 'RO'
  },
  {
    value: 'Buritis',
    name: 'Buritis',
    uf: 'RO'
  },
  {
    value: 'Ji-Parana',
    name: 'Ji-Paraná',
    uf: 'RO'
  },
  {
    value: 'Chupinguaia',
    name: 'Chupinguaia',
    uf: 'RO'
  },
  {
    value: 'Ariquemes',
    name: 'Ariquemes',
    uf: 'RO'
  },
  {
    value: 'Cujubim',
    name: 'Cujubim',
    uf: 'RO'
  },
  {
    value: 'Cacoal',
    name: 'Cacoal',
    uf: 'RO'
  },
  {
    value: 'Nova Uniao',
    name: 'Nova União',
    uf: 'RO'
  },
  {
    value: 'Pimenta Bueno',
    name: 'Pimenta Bueno',
    uf: 'RO'
  },
  {
    value: 'Parecis',
    name: 'Parecis',
    uf: 'RO'
  },
  {
    value: 'Vilhena',
    name: 'Vilhena',
    uf: 'RO'
  },
  {
    value: 'Pimenteiras do Oeste',
    name: 'Pimenteiras do Oeste',
    uf: 'RO'
  },
  {
    value: 'Jaru',
    name: 'Jaru',
    uf: 'RO'
  },
  {
    value: 'Primavera de Rondonia',
    name: 'Primavera de Rondônia',
    uf: 'RO'
  },
  {
    value: 'Ouro Preto do Oeste',
    name: 'Ouro Preto do Oeste',
    uf: 'RO'
  },
  {
    value: "Sao Felipe D'Oeste",
    name: "São Felipe D'Oeste",
    uf: 'RO'
  },
  {
    value: 'Presidente Medici',
    name: 'Presidente Médici',
    uf: 'RO'
  },
  {
    value: 'Sao Francisco do Guapore',
    name: 'São Francisco do Guaporé',
    uf: 'RO'
  },
  {
    value: 'Costa Marques',
    name: 'Costa Marques',
    uf: 'RO'
  },
  {
    value: 'Teixeiropolis',
    name: 'Teixeirópolis',
    uf: 'RO'
  },
  {
    value: 'Colorado do Oeste',
    name: 'Colorado do Oeste',
    uf: 'RO'
  },
  {
    value: 'Vale do Anari',
    name: 'Vale do Anari',
    uf: 'RO'
  },
  {
    value: "Espigao D'Oeste",
    name: "Espigão D'Oeste",
    uf: 'RO'
  },
  {
    value: 'Amajari',
    name: 'Amajari',
    uf: 'RR'
  },
  {
    value: 'Cerejeiras',
    name: 'Cerejeiras',
    uf: 'RO'
  },
  {
    value: 'Canta',
    name: 'Cantá',
    uf: 'RR'
  },
  {
    value: 'Rolim de Moura',
    name: 'Rolim de Moura',
    uf: 'RO'
  },
  {
    value: 'Caroebe',
    name: 'Caroebe',
    uf: 'RR'
  },
  {
    value: 'Caldazinha',
    name: 'Caldazinha',
    uf: 'GO'
  },
  {
    value: 'Iracema',
    name: 'Iracema',
    uf: 'RR'
  },
  {
    value: "Alta Floresta D'Oeste",
    name: "Alta Floresta D'Oeste",
    uf: 'RO'
  },
  {
    value: 'Pacaraima',
    name: 'Pacaraima',
    uf: 'RR'
  },
  {
    value: "Alvorada D'Oeste",
    name: "Alvorada D'Oeste",
    uf: 'RO'
  },
  {
    value: 'Rorainopolis',
    name: 'Rorainópolis',
    uf: 'RR'
  },
  {
    value: 'Cabixi',
    name: 'Cabixi',
    uf: 'RO'
  },
  {
    value: 'Uiramuta',
    name: 'Uiramutã',
    uf: 'RR'
  },
  {
    value: "Machadinho D'Oeste",
    name: "Machadinho D'Oeste",
    uf: 'RO'
  },
  {
    value: 'Anapu',
    name: 'Anapu',
    uf: 'PA'
  },
  {
    value: "Nova Brasilandia D'Oeste",
    name: "Nova Brasilândia D'Oeste",
    uf: 'RO'
  },
  {
    value: 'Bannach',
    name: 'Bannach',
    uf: 'PA'
  },
  {
    value: "Santa Luzia D'Oeste",
    name: "Santa Luzia D'Oeste",
    uf: 'RO'
  },
  {
    value: 'Belterra',
    name: 'Belterra',
    uf: 'PA'
  },
  {
    value: 'Sao Miguel do Guapore',
    name: 'São Miguel do Guaporé',
    uf: 'RO'
  },
  {
    value: 'Cachoeira do Piria',
    name: 'Cachoeira do Piriá',
    uf: 'PA'
  },
  {
    value: 'Nova Mamore',
    name: 'Nova Mamoré',
    uf: 'RO'
  },
  {
    value: 'Canaa dos Carajas',
    name: 'Canaã dos Carajás',
    uf: 'PA'
  },
  {
    value: 'Jesupolis',
    name: 'Jesúpolis',
    uf: 'GO'
  },
  {
    value: 'Curua',
    name: 'Curuá',
    uf: 'PA'
  },
  {
    value: 'Professor Jamil',
    name: 'Professor Jamil',
    uf: 'GO'
  },
  {
    value: 'Floresta do Araguaia',
    name: 'Floresta do Araguaia',
    uf: 'PA'
  },
  {
    value: 'Santo Antonio de Goias',
    name: 'Santo Antônio de Goiás',
    uf: 'GO'
  },
  {
    value: 'Marituba',
    name: 'Marituba',
    uf: 'PA'
  },
  {
    value: 'Cocalzinho de Goias',
    name: 'Cocalzinho de Goiás',
    uf: 'GO'
  },
  {
    value: 'Nova Ipixuna',
    name: 'Nova Ipixuna',
    uf: 'PA'
  },
  {
    value: 'Terezopolis de Goias',
    name: 'Terezópolis de Goiás',
    uf: 'GO'
  },
  {
    value: 'Picarra',
    name: 'Piçarra',
    uf: 'PA'
  },
  {
    value: 'Uirapuru',
    name: 'Uirapuru',
    uf: 'GO'
  },
  {
    value: 'Placas',
    name: 'Placas',
    uf: 'PA'
  },
  {
    value: 'Buritinopolis',
    name: 'Buritinópolis',
    uf: 'GO'
  },
  {
    value: 'Quatipuru',
    name: 'Quatipuru',
    uf: 'PA'
  },
  {
    value: 'Buriti de Goias',
    name: 'Buriti de Goiás',
    uf: 'GO'
  },
  {
    value: 'Sao Joao da Ponta',
    name: 'São João da Ponta',
    uf: 'PA'
  },
  {
    value: 'Guaraita',
    name: 'Guaraíta',
    uf: 'GO'
  },
  {
    value: 'Sapucaia',
    name: 'Sapucaia',
    uf: 'PA'
  },
  {
    value: 'Vila Boa',
    name: 'Vila Boa',
    uf: 'GO'
  },
  {
    value: 'Tracuateua',
    name: 'Tracuateua',
    uf: 'PA'
  },
  {
    value: 'Inaciolandia',
    name: 'Inaciolândia',
    uf: 'GO'
  },
  {
    value: 'Vitoria do Jari',
    name: 'Vitória do Jari',
    uf: 'AP'
  },
  {
    value: 'Aparecida do Rio Doce',
    name: 'Aparecida do Rio Doce',
    uf: 'GO'
  },
  {
    value: 'Aguiarnopolis',
    name: 'Aguiarnópolis',
    uf: 'TO'
  },
  {
    value: 'Chapadao do Ceu',
    name: 'Chapadão do Céu',
    uf: 'GO'
  },
  {
    value: 'Bandeirantes do Tocantins',
    name: 'Bandeirantes do Tocantins',
    uf: 'TO'
  },
  {
    value: 'Perolandia',
    name: 'Perolândia',
    uf: 'GO'
  },
  {
    value: 'Barra do Ouro',
    name: 'Barra do Ouro',
    uf: 'TO'
  },
  {
    value: 'Cidade Ocidental',
    name: 'Cidade Ocidental',
    uf: 'GO'
  },
  {
    value: 'Chapada de Areia',
    name: 'Chapada de Areia',
    uf: 'TO'
  },
  {
    value: 'Montividiu do Norte',
    name: 'Montividiu do Norte',
    uf: 'GO'
  },
  {
    value: 'Chapada da Natividade',
    name: 'Chapada da Natividade',
    uf: 'TO'
  },
  {
    value: 'Castelandia',
    name: 'Castelândia',
    uf: 'GO'
  },
  {
    value: 'Crixas do Tocantins',
    name: 'Crixás do Tocantins',
    uf: 'TO'
  },
  {
    value: 'Santo Antonio da Barra',
    name: 'Santo Antônio da Barra',
    uf: 'GO'
  },
  {
    value: 'Ipueiras',
    name: 'Ipueiras',
    uf: 'TO'
  },
  {
    value: 'Alto Horizonte',
    name: 'Alto Horizonte',
    uf: 'GO'
  },
  {
    value: 'Lavandeira',
    name: 'Lavandeira',
    uf: 'TO'
  },
  {
    value: 'Nova Iguacu de Goias',
    name: 'Nova Iguaçu de Goiás',
    uf: 'GO'
  },
  {
    value: 'Luzinopolis',
    name: 'Luzinópolis',
    uf: 'TO'
  },
  {
    value: 'Cotriguacu',
    name: 'Cotriguaçu',
    uf: 'MT'
  },
  {
    value: 'Monte Santo do Tocantins',
    name: 'Monte Santo do Tocantins',
    uf: 'TO'
  },
  {
    value: 'Planalto da Serra',
    name: 'Planalto da Serra',
    uf: 'MT'
  },
  {
    value: 'Oliveira de Fatima',
    name: 'Oliveira de Fátima',
    uf: 'TO'
  },
  {
    value: 'Sao Pedro da Cipa',
    name: 'São Pedro da Cipa',
    uf: 'MT'
  },
  {
    value: 'Pugmil',
    name: 'Pugmil',
    uf: 'TO'
  },
  {
    value: 'Pontal do Araguaia',
    name: 'Pontal do Araguaia',
    uf: 'MT'
  },
  {
    value: 'Santa Rita do Tocantins',
    name: 'Santa Rita do Tocantins',
    uf: 'TO'
  },
  {
    value: 'Querencia',
    name: 'Querência',
    uf: 'MT'
  },
  {
    value: 'Santa Terezinha do Tocantins',
    name: 'Santa Terezinha do Tocantins',
    uf: 'TO'
  },
  {
    value: 'Ribeiraozinho',
    name: 'Ribeirãozinho',
    uf: 'MT'
  },
  {
    value: 'Talisma',
    name: 'Talismã',
    uf: 'TO'
  },
  {
    value: 'Porto Estrela',
    name: 'Porto Estrela',
    uf: 'MT'
  },
  {
    value: 'Tupirama',
    name: 'Tupirama',
    uf: 'TO'
  },
  {
    value: 'Nova Marilandia',
    name: 'Nova Marilândia',
    uf: 'MT'
  },
  {
    value: 'Agua Doce do Maranhao',
    name: 'Água Doce do Maranhão',
    uf: 'MA'
  },
  {
    value: 'Brasileia',
    name: 'Brasiléia',
    uf: 'AC'
  },
  {
    value: 'Alto Alegre do Maranhao',
    name: 'Alto Alegre do Maranhão',
    uf: 'MA'
  },
  {
    value: 'Cruzeiro do Sul',
    name: 'Cruzeiro do Sul',
    uf: 'AC'
  },
  {
    value: 'Alto Alegre do Pindare',
    name: 'Alto Alegre do Pindaré',
    uf: 'MA'
  },
  {
    value: 'Mancio Lima',
    name: 'Mâncio Lima',
    uf: 'AC'
  },
  {
    value: 'Amapa do Maranhao',
    name: 'Amapá do Maranhão',
    uf: 'MA'
  },
  {
    value: 'Nova Maringa',
    name: 'Nova Maringá',
    uf: 'MT'
  },
  {
    value: 'Apicum-Acu',
    name: 'Apicum-Açu',
    uf: 'MA'
  },
  {
    value: 'Feijo',
    name: 'Feijó',
    uf: 'AC'
  },
  {
    value: 'Araguana',
    name: 'Araguanã',
    uf: 'MA'
  },
  {
    value: 'Santo Afonso',
    name: 'Santo Afonso',
    uf: 'MT'
  },
  {
    value: 'Bacabeira',
    name: 'Bacabeira',
    uf: 'MA'
  },
  {
    value: 'Nova Bandeirantes',
    name: 'Nova Bandeirantes',
    uf: 'MT'
  },
  {
    value: 'Bacurituba',
    name: 'Bacurituba',
    uf: 'MA'
  },
  {
    value: 'Nova Monte Verde',
    name: 'Nova Monte Verde',
    uf: 'MT'
  },
  {
    value: 'Belagua',
    name: 'Belágua',
    uf: 'MA'
  },
  {
    value: 'Nova Guarita',
    name: 'Nova Guarita',
    uf: 'MT'
  },
  {
    value: 'Bela Vista do Maranhao',
    name: 'Bela Vista do Maranhão',
    uf: 'MA'
  },
  {
    value: 'Santa Carmem',
    name: 'Santa Carmem',
    uf: 'MT'
  },
  {
    value: 'Bernardo do Mearim',
    name: 'Bernardo do Mearim',
    uf: 'MA'
  },
  {
    value: 'Tabapora',
    name: 'Tabaporã',
    uf: 'MT'
  },
  {
    value: 'Boa Vista do Gurupi',
    name: 'Boa Vista do Gurupi',
    uf: 'MA'
  },
  {
    value: 'Alto Boa Vista',
    name: 'Alto Boa Vista',
    uf: 'MT'
  },
  {
    value: 'Bom Jesus das Selvas',
    name: 'Bom Jesus das Selvas',
    uf: 'MA'
  },
  {
    value: 'Canabrava do Norte',
    name: 'Canabrava do Norte',
    uf: 'MT'
  },
  {
    value: 'Bom Lugar',
    name: 'Bom Lugar',
    uf: 'MA'
  },
  {
    value: 'Confresa',
    name: 'Confresa',
    uf: 'MT'
  },
  {
    value: 'Brejo de Areia',
    name: 'Brejo de Areia',
    uf: 'MA'
  },
  {
    value: 'Sao Jose do Xingu',
    name: 'São José do Xingu',
    uf: 'MT'
  },
  {
    value: 'Buriticupu',
    name: 'Buriticupu',
    uf: 'MA'
  },
  {
    value: "Gloria D'Oeste",
    name: "Glória D'Oeste",
    uf: 'MT'
  },
  {
    value: 'Buritirana',
    name: 'Buritirana',
    uf: 'MA'
  },
  {
    value: "Lambari D'Oeste",
    name: "Lambari D'Oeste",
    uf: 'MT'
  },
  {
    value: 'Cachoeira Grande',
    name: 'Cachoeira Grande',
    uf: 'MA'
  },
  {
    value: 'Rio Branco',
    name: 'Rio Branco',
    uf: 'AC'
  },
  {
    value: 'Campestre do Maranhao',
    name: 'Campestre do Maranhão',
    uf: 'MA'
  },
  {
    value: 'Alcinopolis',
    name: 'Alcinópolis',
    uf: 'MS'
  },
  {
    value: 'Capinzal do Norte',
    name: 'Capinzal do Norte',
    uf: 'MA'
  },
  {
    value: 'Nova Alvorada do Sul',
    name: 'Nova Alvorada do Sul',
    uf: 'MS'
  },
  {
    value: 'Central do Maranhao',
    name: 'Central do Maranhão',
    uf: 'MA'
  },
  {
    value: 'Sena Madureira',
    name: 'Sena Madureira',
    uf: 'AC'
  },
  {
    value: 'Centro do Guilherme',
    name: 'Centro do Guilherme',
    uf: 'MA'
  },
  {
    value: 'Tarauaca',
    name: 'Tarauacá',
    uf: 'AC'
  },
  {
    value: 'Centro Novo do Maranhao',
    name: 'Centro Novo do Maranhão',
    uf: 'MA'
  },
  {
    value: 'Xapuri',
    name: 'Xapuri',
    uf: 'AC'
  },
  {
    value: 'Cidelandia',
    name: 'Cidelândia',
    uf: 'MA'
  },
  {
    value: 'Placido de Castro',
    name: 'Plácido de Castro',
    uf: 'AC'
  },
  {
    value: 'Conceicao do Lago-Acu',
    name: 'Conceição do Lago-Açu',
    uf: 'MA'
  },
  {
    value: 'Senador Guiomard',
    name: 'Senador Guiomard',
    uf: 'AC'
  },
  {
    value: 'Davinopolis',
    name: 'Davinópolis',
    uf: 'MA'
  },
  {
    value: 'Manoel Urbano',
    name: 'Manoel Urbano',
    uf: 'AC'
  },
  {
    value: 'Feira Nova do Maranhao',
    name: 'Feira Nova do Maranhão',
    uf: 'MA'
  },
  {
    value: 'Assis Brasil',
    name: 'Assis Brasil',
    uf: 'AC'
  },
  {
    value: 'Fernando Falcao',
    name: 'Fernando Falcão',
    uf: 'MA'
  },
  {
    value: 'Novo Horizonte do Sul',
    name: 'Novo Horizonte do Sul',
    uf: 'MS'
  },
  {
    value: 'Formosa da Serra Negra',
    name: 'Formosa da Serra Negra',
    uf: 'MA'
  },
  {
    value: 'Japora',
    name: 'Japorã',
    uf: 'MS'
  },
  {
    value: 'Governador Edison Lobao',
    name: 'Governador Edison Lobão',
    uf: 'MA'
  },
  {
    value: 'Laguna Carapa',
    name: 'Laguna Carapã',
    uf: 'MS'
  },
  {
    value: 'Governador Luiz Rocha',
    name: 'Governador Luiz Rocha',
    uf: 'MA'
  },
  {
    value: 'Angico',
    name: 'Angico',
    uf: 'TO'
  },
  {
    value: 'Governador Newton Bello',
    name: 'Governador Newton Bello',
    uf: 'MA'
  },
  {
    value: 'Aragominas',
    name: 'Aragominas',
    uf: 'TO'
  },
  {
    value: 'Governador Nunes Freire',
    name: 'Governador Nunes Freire',
    uf: 'MA'
  },
  {
    value: 'Araguana',
    name: 'Araguanã',
    uf: 'TO'
  },
  {
    value: 'Igarape do Meio',
    name: 'Igarapé do Meio',
    uf: 'MA'
  },
  {
    value: 'Cachoeirinha',
    name: 'Cachoeirinha',
    uf: 'TO'
  },
  {
    value: 'Itaipava do Grajau',
    name: 'Itaipava do Grajaú',
    uf: 'MA'
  },
  {
    value: 'Campos Lindos',
    name: 'Campos Lindos',
    uf: 'TO'
  },
  {
    value: 'Itinga do Maranhao',
    name: 'Itinga do Maranhão',
    uf: 'MA'
  },
  {
    value: 'Carmolandia',
    name: 'Carmolândia',
    uf: 'TO'
  },
  {
    value: 'Jatoba',
    name: 'Jatobá',
    uf: 'MA'
  },
  {
    value: 'Carrasco Bonito',
    name: 'Carrasco Bonito',
    uf: 'TO'
  },
  {
    value: 'Jenipapo dos Vieiras',
    name: 'Jenipapo dos Vieiras',
    uf: 'MA'
  },
  {
    value: 'Darcinopolis',
    name: 'Darcinópolis',
    uf: 'TO'
  },
  {
    value: 'Junco do Maranhao',
    name: 'Junco do Maranhão',
    uf: 'MA'
  },
  {
    value: 'Esperantina',
    name: 'Esperantina',
    uf: 'TO'
  },
  {
    value: 'Lagoa do Mato',
    name: 'Lagoa do Mato',
    uf: 'MA'
  },
  {
    value: 'Maurilandia do Tocantins',
    name: 'Maurilândia do Tocantins',
    uf: 'TO'
  },
  {
    value: 'Lago dos Rodrigues',
    name: 'Lago dos Rodrigues',
    uf: 'MA'
  },
  {
    value: 'Palmeiras do Tocantins',
    name: 'Palmeiras do Tocantins',
    uf: 'TO'
  },
  {
    value: 'Lagoa Grande do Maranhao',
    name: 'Lagoa Grande do Maranhão',
    uf: 'MA'
  },
  {
    value: 'Muricilandia',
    name: 'Muricilândia',
    uf: 'TO'
  },
  {
    value: 'Lajeado Novo',
    name: 'Lajeado Novo',
    uf: 'MA'
  },
  {
    value: 'Palmeirante',
    name: 'Palmeirante',
    uf: 'TO'
  },
  {
    value: 'Maracacume',
    name: 'Maracaçumé',
    uf: 'MA'
  },
  {
    value: "Pau D'Arco",
    name: "Pau D'Arco",
    uf: 'TO'
  },
  {
    value: 'Maraja do Sena',
    name: 'Marajá do Sena',
    uf: 'MA'
  },
  {
    value: 'Riachinho',
    name: 'Riachinho',
    uf: 'TO'
  },
  {
    value: 'Maranhaozinho',
    name: 'Maranhãozinho',
    uf: 'MA'
  },
  {
    value: 'Santa Fe do Araguaia',
    name: 'Santa Fé do Araguaia',
    uf: 'TO'
  },
  {
    value: 'Matoes do Norte',
    name: 'Matões do Norte',
    uf: 'MA'
  },
  {
    value: 'Sao Bento do Tocantins',
    name: 'São Bento do Tocantins',
    uf: 'TO'
  },
  {
    value: 'Milagres do Maranhao',
    name: 'Milagres do Maranhão',
    uf: 'MA'
  },
  {
    value: 'Sao Miguel do Tocantins',
    name: 'São Miguel do Tocantins',
    uf: 'TO'
  },
  {
    value: 'Nova Colinas',
    name: 'Nova Colinas',
    uf: 'MA'
  },
  {
    value: 'Novo Airao',
    name: 'Novo Airão',
    uf: 'AM'
  },
  {
    value: 'Nova Olinda do Maranhao',
    name: 'Nova Olinda do Maranhão',
    uf: 'MA'
  },
  {
    value: 'Anori',
    name: 'Anori',
    uf: 'AM'
  },
  {
    value: 'Olinda Nova do Maranhao',
    name: 'Olinda Nova do Maranhão',
    uf: 'MA'
  },
  {
    value: 'Atalaia do Norte',
    name: 'Atalaia do Norte',
    uf: 'AM'
  },
  {
    value: 'Paulino Neves',
    name: 'Paulino Neves',
    uf: 'MA'
  },
  {
    value: 'Autazes',
    name: 'Autazes',
    uf: 'AM'
  },
  {
    value: 'Pedro do Rosario',
    name: 'Pedro do Rosário',
    uf: 'MA'
  },
  {
    value: 'Barcelos',
    name: 'Barcelos',
    uf: 'AM'
  },
  {
    value: 'Peritoro',
    name: 'Peritoró',
    uf: 'MA'
  },
  {
    value: 'Barreirinha',
    name: 'Barreirinha',
    uf: 'AM'
  },
  {
    value: 'Porto Rico do Maranhao',
    name: 'Porto Rico do Maranhão',
    uf: 'MA'
  },
  {
    value: 'Benjamin Constant',
    name: 'Benjamin Constant',
    uf: 'AM'
  },
  {
    value: 'Presidente Medici',
    name: 'Presidente Médici',
    uf: 'MA'
  },
  {
    value: 'Boca do Acre',
    name: 'Boca do Acre',
    uf: 'AM'
  },
  {
    value: 'Presidente Sarney',
    name: 'Presidente Sarney',
    uf: 'MA'
  },
  {
    value: 'Borba',
    name: 'Borba',
    uf: 'AM'
  },
  {
    value: 'Raposa',
    name: 'Raposa',
    uf: 'MA'
  },
  {
    value: 'Canutama',
    name: 'Canutama',
    uf: 'AM'
  },
  {
    value: 'Ribamar Fiquene',
    name: 'Ribamar Fiquene',
    uf: 'MA'
  },
  {
    value: 'Carauari',
    name: 'Carauari',
    uf: 'AM'
  },
  {
    value: 'Santa Filomena do Maranhao',
    name: 'Santa Filomena do Maranhão',
    uf: 'MA'
  },
  {
    value: 'Careiro',
    name: 'Careiro',
    uf: 'AM'
  },
  {
    value: 'Santana do Maranhao',
    name: 'Santana do Maranhão',
    uf: 'MA'
  },
  {
    value: 'Coari',
    name: 'Coari',
    uf: 'AM'
  },
  {
    value: 'Santo Amaro do Maranhao',
    name: 'Santo Amaro do Maranhão',
    uf: 'MA'
  },
  {
    value: 'Codajas',
    name: 'Codajás',
    uf: 'AM'
  },
  {
    value: 'Sao Domingos do Azeitao',
    name: 'São Domingos do Azeitão',
    uf: 'MA'
  },
  {
    value: 'Eirunepe',
    name: 'Eirunepé',
    uf: 'AM'
  },
  {
    value: 'Sao Francisco do Brejao',
    name: 'São Francisco do Brejão',
    uf: 'MA'
  },
  {
    value: 'Envira',
    name: 'Envira',
    uf: 'AM'
  },
  {
    value: 'Sao Joao do Caru',
    name: 'São João do Carú',
    uf: 'MA'
  },
  {
    value: 'Fonte Boa',
    name: 'Fonte Boa',
    uf: 'AM'
  },
  {
    value: 'Sao Joao do Paraiso',
    name: 'São João do Paraíso',
    uf: 'MA'
  },
  {
    value: 'Humaita',
    name: 'Humaitá',
    uf: 'AM'
  },
  {
    value: 'Sao Joao do Soter',
    name: 'São João do Soter',
    uf: 'MA'
  },
  {
    value: 'Santa Isabel do Rio Negro',
    name: 'Santa Isabel do Rio Negro',
    uf: 'AM'
  },
  {
    value: 'Sao Jose dos Basilios',
    name: 'São José dos Basílios',
    uf: 'MA'
  },
  {
    value: 'Ipixuna',
    name: 'Ipixuna',
    uf: 'AM'
  },
  {
    value: 'Sao Pedro da Agua Branca',
    name: 'São Pedro da Água Branca',
    uf: 'MA'
  },
  {
    value: 'Itacoatiara',
    name: 'Itacoatiara',
    uf: 'AM'
  },
  {
    value: 'Sao Pedro dos Crentes',
    name: 'São Pedro dos Crentes',
    uf: 'MA'
  },
  {
    value: 'Itapiranga',
    name: 'Itapiranga',
    uf: 'AM'
  },
  {
    value: 'Sao Raimundo do Doca Bezerra',
    name: 'São Raimundo do Doca Bezerra',
    uf: 'MA'
  },
  {
    value: 'Japura',
    name: 'Japurá',
    uf: 'AM'
  },
  {
    value: 'Sao Roberto',
    name: 'São Roberto',
    uf: 'MA'
  },
  {
    value: 'Jurua',
    name: 'Juruá',
    uf: 'AM'
  },
  {
    value: 'Satubinha',
    name: 'Satubinha',
    uf: 'MA'
  },
  {
    value: 'Jutai',
    name: 'Jutaí',
    uf: 'AM'
  },
  {
    value: 'Senador Alexandre Costa',
    name: 'Senador Alexandre Costa',
    uf: 'MA'
  },
  {
    value: 'Labrea',
    name: 'Lábrea',
    uf: 'AM'
  },
  {
    value: 'Senador La Rocque',
    name: 'Senador La Rocque',
    uf: 'MA'
  },
  {
    value: 'Manacapuru',
    name: 'Manacapuru',
    uf: 'AM'
  },
  {
    value: 'Serrano do Maranhao',
    name: 'Serrano do Maranhão',
    uf: 'MA'
  },
  {
    value: 'Manaus',
    name: 'Manaus',
    uf: 'AM'
  },
  {
    value: 'Sucupira do Riachao',
    name: 'Sucupira do Riachão',
    uf: 'MA'
  },
  {
    value: 'Manicore',
    name: 'Manicoré',
    uf: 'AM'
  },
  {
    value: 'Trizidela do Vale',
    name: 'Trizidela do Vale',
    uf: 'MA'
  },
  {
    value: 'Maraa',
    name: 'Maraã',
    uf: 'AM'
  },
  {
    value: 'Tufilandia',
    name: 'Tufilândia',
    uf: 'MA'
  },
  {
    value: 'Maues',
    name: 'Maués',
    uf: 'AM'
  },
  {
    value: 'Turilandia',
    name: 'Turilândia',
    uf: 'MA'
  },
  {
    value: 'Nhamunda',
    name: 'Nhamundá',
    uf: 'AM'
  },
  {
    value: 'Vila Nova dos Martirios',
    name: 'Vila Nova dos Martírios',
    uf: 'MA'
  },
  {
    value: 'Nova Olinda do Norte',
    name: 'Nova Olinda do Norte',
    uf: 'AM'
  },
  {
    value: 'Acaua',
    name: 'Acauã',
    uf: 'PI'
  },
  {
    value: 'Novo Aripuana',
    name: 'Novo Aripuanã',
    uf: 'AM'
  },
  {
    value: 'Alvorada do Gurgueia',
    name: 'Alvorada do Gurguéia',
    uf: 'PI'
  },
  {
    value: 'Parintins',
    name: 'Parintins',
    uf: 'AM'
  },
  {
    value: 'Assuncao do Piaui',
    name: 'Assunção do Piauí',
    uf: 'PI'
  },
  {
    value: 'Pauini',
    name: 'Pauini',
    uf: 'AM'
  },
  {
    value: "Barra D'Alcantara",
    name: "Barra D'Alcântara",
    uf: 'PI'
  },
  {
    value: 'Santo Antonio do Ica',
    name: 'Santo Antônio do Içá',
    uf: 'AM'
  },
  {
    value: 'Bela Vista do Piaui',
    name: 'Bela Vista do Piauí',
    uf: 'PI'
  },
  {
    value: 'Sao Paulo de Olivenca',
    name: 'São Paulo de Olivença',
    uf: 'AM'
  },
  {
    value: 'Belem do Piaui',
    name: 'Belém do Piauí',
    uf: 'PI'
  },
  {
    value: 'Silves',
    name: 'Silves',
    uf: 'AM'
  },
  {
    value: 'Betania do Piaui',
    name: 'Betânia do Piauí',
    uf: 'PI'
  },
  {
    value: 'Tapaua',
    name: 'Tapauá',
    uf: 'AM'
  },
  {
    value: 'Boa Hora',
    name: 'Boa Hora',
    uf: 'PI'
  },
  {
    value: 'Tefe',
    name: 'Tefé',
    uf: 'AM'
  },
  {
    value: 'Boqueirao do Piaui',
    name: 'Boqueirão do Piauí',
    uf: 'PI'
  },
  {
    value: 'Sao Gabriel da Cachoeira',
    name: 'São Gabriel da Cachoeira',
    uf: 'AM'
  },
  {
    value: 'Brejo do Piaui',
    name: 'Brejo do Piauí',
    uf: 'PI'
  },
  {
    value: 'Urucara',
    name: 'Urucará',
    uf: 'AM'
  },
  {
    value: 'Cajazeiras do Piaui',
    name: 'Cajazeiras do Piauí',
    uf: 'PI'
  },
  {
    value: 'Urucurituba',
    name: 'Urucurituba',
    uf: 'AM'
  },
  {
    value: 'Cajueiro da Praia',
    name: 'Cajueiro da Praia',
    uf: 'PI'
  },
  {
    value: 'Alvaraes',
    name: 'Alvarães',
    uf: 'AM'
  },
  {
    value: 'Campo Alegre do Fidalgo',
    name: 'Campo Alegre do Fidalgo',
    uf: 'PI'
  },
  {
    value: 'Amatura',
    name: 'Amaturá',
    uf: 'AM'
  },
  {
    value: 'Campo Grande do Piaui',
    name: 'Campo Grande do Piauí',
    uf: 'PI'
  },
  {
    value: 'Anama',
    name: 'Anamã',
    uf: 'AM'
  },
  {
    value: 'Campo Largo do Piaui',
    name: 'Campo Largo do Piauí',
    uf: 'PI'
  },
  {
    value: 'Beruri',
    name: 'Beruri',
    uf: 'AM'
  },
  {
    value: 'Capitao Gervasio Oliveira',
    name: 'Capitão Gervásio Oliveira',
    uf: 'PI'
  },
  {
    value: 'Boa Vista do Ramos',
    name: 'Boa Vista do Ramos',
    uf: 'AM'
  },
  {
    value: 'Caraubas do Piaui',
    name: 'Caraúbas do Piauí',
    uf: 'PI'
  },
  {
    value: 'Caapiranga',
    name: 'Caapiranga',
    uf: 'AM'
  },
  {
    value: 'Caridade do Piaui',
    name: 'Caridade do Piauí',
    uf: 'PI'
  },
  {
    value: 'Boa Vista',
    name: 'Boa Vista',
    uf: 'RR'
  },
  {
    value: 'Caxingo',
    name: 'Caxingó',
    uf: 'PI'
  },
  {
    value: 'Caracarai',
    name: 'Caracaraí',
    uf: 'RR'
  },
  {
    value: 'Cocal de Telha',
    name: 'Cocal de Telha',
    uf: 'PI'
  },
  {
    value: 'Alto Alegre',
    name: 'Alto Alegre',
    uf: 'RR'
  },
  {
    value: 'Cocal dos Alves',
    name: 'Cocal dos Alves',
    uf: 'PI'
  },
  {
    value: 'Bonfim',
    name: 'Bonfim',
    uf: 'RR'
  },
  {
    value: 'Currais',
    name: 'Currais',
    uf: 'PI'
  },
  {
    value: 'Mucajai',
    name: 'Mucajaí',
    uf: 'RR'
  },
  {
    value: 'Curralinhos',
    name: 'Curralinhos',
    uf: 'PI'
  },
  {
    value: 'Normandia',
    name: 'Normandia',
    uf: 'RR'
  },
  {
    value: 'Curral Novo do Piaui',
    name: 'Curral Novo do Piauí',
    uf: 'PI'
  },
  {
    value: 'Sao Joao da Baliza',
    name: 'São João da Baliza',
    uf: 'RR'
  },
  {
    value: 'Floresta do Piaui',
    name: 'Floresta do Piauí',
    uf: 'PI'
  },
  {
    value: 'Sao Luiz',
    name: 'São Luiz',
    uf: 'RR'
  },
  {
    value: 'Francisco Macedo',
    name: 'Francisco Macedo',
    uf: 'PI'
  },
  {
    value: 'Mateiros',
    name: 'Mateiros',
    uf: 'TO'
  },
  {
    value: 'Geminiano',
    name: 'Geminiano',
    uf: 'PI'
  },
  {
    value: 'Guaribas',
    name: 'Guaribas',
    uf: 'PI'
  },
  {
    value: 'Novo Jardim',
    name: 'Novo Jardim',
    uf: 'TO'
  },
  {
    value: 'Ilha Grande',
    name: 'Ilha Grande',
    uf: 'PI'
  },
  {
    value: 'Rio da Conceicao',
    name: 'Rio da Conceição',
    uf: 'TO'
  },
  {
    value: 'Jatoba do Piaui',
    name: 'Jatobá do Piauí',
    uf: 'PI'
  },
  {
    value: 'Taipas do Tocantins',
    name: 'Taipas do Tocantins',
    uf: 'TO'
  },
  {
    value: 'Joao Costa',
    name: 'João Costa',
    uf: 'PI'
  },
  {
    value: 'Cariri do Tocantins',
    name: 'Cariri do Tocantins',
    uf: 'TO'
  },
  {
    value: 'Joca Marques',
    name: 'Joca Marques',
    uf: 'PI'
  },
  {
    value: 'Jau do Tocantins',
    name: 'Jaú do Tocantins',
    uf: 'TO'
  },
  {
    value: 'Juazeiro do Piaui',
    name: 'Juazeiro do Piauí',
    uf: 'PI'
  },
  {
    value: 'Sandolandia',
    name: 'Sandolândia',
    uf: 'TO'
  },
  {
    value: 'Julio Borges',
    name: 'Júlio Borges',
    uf: 'PI'
  },
  {
    value: 'Sao Salvador do Tocantins',
    name: 'São Salvador do Tocantins',
    uf: 'TO'
  },
  {
    value: 'Jurema',
    name: 'Jurema',
    uf: 'PI'
  },
  {
    value: 'Sucupira',
    name: 'Sucupira',
    uf: 'TO'
  },
  {
    value: 'Lagoinha do Piaui',
    name: 'Lagoinha do Piauí',
    uf: 'PI'
  },
  {
    value: 'Abreulandia',
    name: 'Abreulândia',
    uf: 'TO'
  },
  {
    value: 'Lagoa de Sao Francisco',
    name: 'Lagoa de São Francisco',
    uf: 'PI'
  },
  {
    value: 'Brasilandia do Tocantins',
    name: 'Brasilândia do Tocantins',
    uf: 'TO'
  },
  {
    value: 'Lagoa do Piaui',
    name: 'Lagoa do Piauí',
    uf: 'PI'
  },
  {
    value: 'Bom Jesus do Tocantins',
    name: 'Bom Jesus do Tocantins',
    uf: 'TO'
  },
  {
    value: 'Lagoa do Sitio',
    name: 'Lagoa do Sítio',
    uf: 'PI'
  },
  {
    value: 'Centenario',
    name: 'Centenário',
    uf: 'TO'
  },
  {
    value: 'Madeiro',
    name: 'Madeiro',
    uf: 'PI'
  },
  {
    value: 'Tabocao',
    name: 'Tabocão',
    uf: 'TO'
  },
  {
    value: 'Massape do Piaui',
    name: 'Massapê do Piauí',
    uf: 'PI'
  },
  {
    value: 'Itapiratins',
    name: 'Itapiratins',
    uf: 'TO'
  },
  {
    value: 'Milton Brandao',
    name: 'Milton Brandão',
    uf: 'PI'
  },
  {
    value: 'Juarina',
    name: 'Juarina',
    uf: 'TO'
  },
  {
    value: 'Morro Cabeca no Tempo',
    name: 'Morro Cabeça no Tempo',
    uf: 'PI'
  },
  {
    value: 'Lajeado',
    name: 'Lajeado',
    uf: 'TO'
  },
  {
    value: 'Morro do Chapeu do Piaui',
    name: 'Morro do Chapéu do Piauí',
    uf: 'PI'
  },
  {
    value: 'Lagoa do Tocantins',
    name: 'Lagoa do Tocantins',
    uf: 'TO'
  },
  {
    value: 'Murici dos Portelas',
    name: 'Murici dos Portelas',
    uf: 'PI'
  },
  {
    value: 'Piraque',
    name: 'Piraquê',
    uf: 'TO'
  },
  {
    value: 'Nossa Senhora de Nazare',
    name: 'Nossa Senhora de Nazaré',
    uf: 'PI'
  },
  {
    value: 'Recursolandia',
    name: 'Recursolândia',
    uf: 'TO'
  },
  {
    value: 'Novo Santo Antonio',
    name: 'Novo Santo Antônio',
    uf: 'PI'
  },
  {
    value: 'Rio dos Bois',
    name: 'Rio dos Bois',
    uf: 'TO'
  },
  {
    value: "Olho D'Agua do Piaui",
    name: "Olho D'Água do Piauí",
    uf: 'PI'
  },
  {
    value: 'Santa Maria do Tocantins',
    name: 'Santa Maria do Tocantins',
    uf: 'TO'
  },
  {
    value: 'Pajeu do Piaui',
    name: 'Pajeú do Piauí',
    uf: 'PI'
  },
  {
    value: 'Sao Felix do Tocantins',
    name: 'São Félix do Tocantins',
    uf: 'TO'
  },
  {
    value: 'Paqueta',
    name: 'Paquetá',
    uf: 'PI'
  },
  {
    value: 'Tupiratins',
    name: 'Tupiratins',
    uf: 'TO'
  },
  {
    value: 'Pavussu',
    name: 'Pavussu',
    uf: 'PI'
  },
  {
    value: 'Lagoa da Confusao',
    name: 'Lagoa da Confusão',
    uf: 'TO'
  },
  {
    value: 'Pedro Laurentino',
    name: 'Pedro Laurentino',
    uf: 'PI'
  },
  {
    value: 'Santa Barbara do Para',
    name: 'Santa Bárbara do Pará',
    uf: 'PA'
  },
  {
    value: 'Nova Santa Rita',
    name: 'Nova Santa Rita',
    uf: 'PI'
  },
  {
    value: 'Santa Luzia do Para',
    name: 'Santa Luzia do Pará',
    uf: 'PA'
  },
  {
    value: 'Porto Alegre do Piaui',
    name: 'Porto Alegre do Piauí',
    uf: 'PI'
  },
  {
    value: 'Terra Alta',
    name: 'Terra Alta',
    uf: 'PA'
  },
  {
    value: 'Riacho Frio',
    name: 'Riacho Frio',
    uf: 'PI'
  },
  {
    value: 'Abel Figueiredo',
    name: 'Abel Figueiredo',
    uf: 'PA'
  },
  {
    value: 'Ribeira do Piaui',
    name: 'Ribeira do Piauí',
    uf: 'PI'
  },
  {
    value: 'Eldorado do Carajas',
    name: 'Eldorado do Carajás',
    uf: 'PA'
  },
  {
    value: 'Santo Antonio dos Milagres',
    name: 'Santo Antônio dos Milagres',
    uf: 'PI'
  },
  {
    value: 'Palestina do Para',
    name: 'Palestina do Pará',
    uf: 'PA'
  },
  {
    value: 'Sao Francisco de Assis do Piaui',
    name: 'São Francisco de Assis do Piauí',
    uf: 'PI'
  },
  {
    value: 'Sao Domingos do Araguaia',
    name: 'São Domingos do Araguaia',
    uf: 'PA'
  },
  {
    value: 'Sao Goncalo do Gurgueia',
    name: 'São Gonçalo do Gurguéia',
    uf: 'PI'
  },
  {
    value: 'Agua Azul do Norte',
    name: 'Água Azul do Norte',
    uf: 'PA'
  },
  {
    value: 'Sao Joao da Fronteira',
    name: 'São João da Fronteira',
    uf: 'PI'
  },
  {
    value: 'Cumaru do Norte',
    name: 'Cumaru do Norte',
    uf: 'PA'
  },
  {
    value: 'Sao Joao da Varjota',
    name: 'São João da Varjota',
    uf: 'PI'
  },
  {
    value: "Pau D'Arco",
    name: "Pau D'Arco",
    uf: 'PA'
  },
  {
    value: 'Sao Joao do Arraial',
    name: 'São João do Arraial',
    uf: 'PI'
  },
  {
    value: 'Aurora do Para',
    name: 'Aurora do Pará',
    uf: 'PA'
  },
  {
    value: 'Sao Luis do Piaui',
    name: 'São Luis do Piauí',
    uf: 'PI'
  },
  {
    value: 'Nova Esperanca do Piria',
    name: 'Nova Esperança do Piriá',
    uf: 'PA'
  },
  {
    value: 'Sao Miguel da Baixa Grande',
    name: 'São Miguel da Baixa Grande',
    uf: 'PI'
  },
  {
    value: 'Sao Joao de Pirabas',
    name: 'São João de Pirabas',
    uf: 'PA'
  },
  {
    value: 'Sao Miguel do Fidalgo',
    name: 'São Miguel do Fidalgo',
    uf: 'PI'
  },
  {
    value: 'Tailandia',
    name: 'Tailândia',
    uf: 'PA'
  },
  {
    value: 'Sebastiao Barros',
    name: 'Sebastião Barros',
    uf: 'PI'
  },
  {
    value: 'Tucuma',
    name: 'Tucumã',
    uf: 'PA'
  },
  {
    value: 'Sebastiao Leal',
    name: 'Sebastião Leal',
    uf: 'PI'
  },
  {
    value: 'Uruara',
    name: 'Uruará',
    uf: 'PA'
  },
  {
    value: 'Sussuapara',
    name: 'Sussuapara',
    uf: 'PI'
  },
  {
    value: 'Abaetetuba',
    name: 'Abaetetuba',
    uf: 'PA'
  },
  {
    value: 'Tamboril do Piaui',
    name: 'Tamboril do Piauí',
    uf: 'PI'
  },
  {
    value: 'Acara',
    name: 'Acará',
    uf: 'PA'
  },
  {
    value: 'Tanque do Piaui',
    name: 'Tanque do Piauí',
    uf: 'PI'
  },
  {
    value: 'Afua',
    name: 'Afuá',
    uf: 'PA'
  },
  {
    value: 'Vera Mendes',
    name: 'Vera Mendes',
    uf: 'PI'
  },
  {
    value: 'Alenquer',
    name: 'Alenquer',
    uf: 'PA'
  },
  {
    value: 'Vila Nova do Piaui',
    name: 'Vila Nova do Piauí',
    uf: 'PI'
  },
  {
    value: 'Almeirim',
    name: 'Almeirim',
    uf: 'PA'
  },
  {
    value: 'Wall Ferraz',
    name: 'Wall Ferraz',
    uf: 'PI'
  },
  {
    value: 'Altamira',
    name: 'Altamira',
    uf: 'PA'
  },
  {
    value: 'Bodo',
    name: 'Bodó',
    uf: 'RN'
  },
  {
    value: 'Anajas',
    name: 'Anajás',
    uf: 'PA'
  },
  {
    value: 'Caicara do Norte',
    name: 'Caiçara do Norte',
    uf: 'RN'
  },
  {
    value: 'Ananindeua',
    name: 'Ananindeua',
    uf: 'PA'
  },
  {
    value: 'Fernando Pedroza',
    name: 'Fernando Pedroza',
    uf: 'RN'
  },
  {
    value: 'Augusto Correa',
    name: 'Augusto Corrêa',
    uf: 'PA'
  },
  {
    value: 'Itaja',
    name: 'Itajá',
    uf: 'RN'
  },
  {
    value: 'Aveiro',
    name: 'Aveiro',
    uf: 'PA'
  },
  {
    value: 'Major Sales',
    name: 'Major Sales',
    uf: 'RN'
  },
  {
    value: 'Bagre',
    name: 'Bagre',
    uf: 'PA'
  },
  {
    value: 'Rio do Fogo',
    name: 'Rio do Fogo',
    uf: 'RN'
  },
  {
    value: 'Baiao',
    name: 'Baião',
    uf: 'PA'
  },
  {
    value: 'Santa Maria',
    name: 'Santa Maria',
    uf: 'RN'
  },
  {
    value: 'Barcarena',
    name: 'Barcarena',
    uf: 'PA'
  },
  {
    value: 'Porto do Mangue',
    name: 'Porto do Mangue',
    uf: 'RN'
  },
  {
    value: 'Belem',
    name: 'Belém',
    uf: 'PA'
  },
  {
    value: 'Tibau',
    name: 'Tibau',
    uf: 'RN'
  },
  {
    value: 'Benevides',
    name: 'Benevides',
    uf: 'PA'
  },
  {
    value: 'Sao Miguel do Gostoso',
    name: 'São Miguel do Gostoso',
    uf: 'RN'
  },
  {
    value: 'Bonito',
    name: 'Bonito',
    uf: 'PA'
  },
  {
    value: 'Serrinha dos Pintos',
    name: 'Serrinha dos Pintos',
    uf: 'RN'
  },
  {
    value: 'Braganca',
    name: 'Bragança',
    uf: 'PA'
  },
  {
    value: 'Tenente Laurentino Cruz',
    name: 'Tenente Laurentino Cruz',
    uf: 'RN'
  },
  {
    value: 'Breves',
    name: 'Breves',
    uf: 'PA'
  },
  {
    value: 'Triunfo Potiguar',
    name: 'Triunfo Potiguar',
    uf: 'RN'
  },
  {
    value: 'Bujaru',
    name: 'Bujaru',
    uf: 'PA'
  },
  {
    value: 'Venha-Ver',
    name: 'Venha-Ver',
    uf: 'RN'
  },
  {
    value: 'Cachoeira do Arari',
    name: 'Cachoeira do Arari',
    uf: 'PA'
  },
  {
    value: 'Alcantil',
    name: 'Alcantil',
    uf: 'PB'
  },
  {
    value: 'Cameta',
    name: 'Cametá',
    uf: 'PA'
  },
  {
    value: 'Algodao de Jandaira',
    name: 'Algodão de Jandaíra',
    uf: 'PB'
  },
  {
    value: 'Capanema',
    name: 'Capanema',
    uf: 'PA'
  },
  {
    value: 'Amparo',
    name: 'Amparo',
    uf: 'PB'
  },
  {
    value: 'Capitao Poco',
    name: 'Capitão Poço',
    uf: 'PA'
  },
  {
    value: 'Aparecida',
    name: 'Aparecida',
    uf: 'PB'
  },
  {
    value: 'Castanhal',
    name: 'Castanhal',
    uf: 'PA'
  },
  {
    value: 'Areia de Baraunas',
    name: 'Areia de Baraúnas',
    uf: 'PB'
  },
  {
    value: 'Chaves',
    name: 'Chaves',
    uf: 'PA'
  },
  {
    value: 'Assuncao',
    name: 'Assunção',
    uf: 'PB'
  },
  {
    value: 'Colares',
    name: 'Colares',
    uf: 'PA'
  },
  {
    value: 'Barauna',
    name: 'Baraúna',
    uf: 'PB'
  },
  {
    value: 'Conceicao do Araguaia',
    name: 'Conceição do Araguaia',
    uf: 'PA'
  },
  {
    value: 'Barra de Santana',
    name: 'Barra de Santana',
    uf: 'PB'
  },
  {
    value: 'Curralinho',
    name: 'Curralinho',
    uf: 'PA'
  },
  {
    value: 'Bernardino Batista',
    name: 'Bernardino Batista',
    uf: 'PB'
  },
  {
    value: 'Curuca',
    name: 'Curuçá',
    uf: 'PA'
  },
  {
    value: 'Boa Vista',
    name: 'Boa Vista',
    uf: 'PB'
  },
  {
    value: 'Faro',
    name: 'Faro',
    uf: 'PA'
  },
  {
    value: 'Cacimbas',
    name: 'Cacimbas',
    uf: 'PB'
  },
  {
    value: 'Gurupa',
    name: 'Gurupá',
    uf: 'PA'
  },
  {
    value: 'Cajazeirinhas',
    name: 'Cajazeirinhas',
    uf: 'PB'
  },
  {
    value: 'Igarape-Acu',
    name: 'Igarapé-Açu',
    uf: 'PA'
  },
  {
    value: 'Capim',
    name: 'Capim',
    uf: 'PB'
  },
  {
    value: 'Igarape-Miri',
    name: 'Igarapé-Miri',
    uf: 'PA'
  },
  {
    value: 'Caraubas',
    name: 'Caraúbas',
    uf: 'PB'
  },
  {
    value: 'Inhangapi',
    name: 'Inhangapi',
    uf: 'PA'
  },
  {
    value: 'Casserengue',
    name: 'Casserengue',
    uf: 'PB'
  },
  {
    value: 'Irituia',
    name: 'Irituia',
    uf: 'PA'
  },
  {
    value: 'Caturite',
    name: 'Caturité',
    uf: 'PB'
  },
  {
    value: 'Itaituba',
    name: 'Itaituba',
    uf: 'PA'
  },
  {
    value: 'Coxixola',
    name: 'Coxixola',
    uf: 'PB'
  },
  {
    value: 'Itupiranga',
    name: 'Itupiranga',
    uf: 'PA'
  },
  {
    value: 'Cuite de Mamanguape',
    name: 'Cuité de Mamanguape',
    uf: 'PB'
  },
  {
    value: 'Jacunda',
    name: 'Jacundá',
    uf: 'PA'
  },
  {
    value: 'Curral de Cima',
    name: 'Curral de Cima',
    uf: 'PB'
  },
  {
    value: 'Juruti',
    name: 'Juruti',
    uf: 'PA'
  },
  {
    value: 'Damiao',
    name: 'Damião',
    uf: 'PB'
  },
  {
    value: 'Limoeiro do Ajuru',
    name: 'Limoeiro do Ajuru',
    uf: 'PA'
  },
  {
    value: 'Gado Bravo',
    name: 'Gado Bravo',
    uf: 'PB'
  },
  {
    value: 'Magalhaes Barata',
    name: 'Magalhães Barata',
    uf: 'PA'
  },
  {
    value: 'Logradouro',
    name: 'Logradouro',
    uf: 'PB'
  },
  {
    value: 'Maraba',
    name: 'Marabá',
    uf: 'PA'
  },
  {
    value: 'Marcacao',
    name: 'Marcação',
    uf: 'PB'
  },
  {
    value: 'Maracana',
    name: 'Maracanã',
    uf: 'PA'
  },
  {
    value: 'Marizopolis',
    name: 'Marizópolis',
    uf: 'PB'
  },
  {
    value: 'Marapanim',
    name: 'Marapanim',
    uf: 'PA'
  },
  {
    value: 'Matinhas',
    name: 'Matinhas',
    uf: 'PB'
  },
  {
    value: 'Melgaco',
    name: 'Melgaço',
    uf: 'PA'
  },
  {
    value: 'Mato Grosso',
    name: 'Mato Grosso',
    uf: 'PB'
  },
  {
    value: 'Mocajuba',
    name: 'Mocajuba',
    uf: 'PA'
  },
  {
    value: 'Matureia',
    name: 'Maturéia',
    uf: 'PB'
  },
  {
    value: 'Moju',
    name: 'Moju',
    uf: 'PA'
  },
  {
    value: 'Parari',
    name: 'Parari',
    uf: 'PB'
  },
  {
    value: 'Monte Alegre',
    name: 'Monte Alegre',
    uf: 'PA'
  },
  {
    value: 'Poco Dantas',
    name: 'Poço Dantas',
    uf: 'PB'
  },
  {
    value: 'Muana',
    name: 'Muaná',
    uf: 'PA'
  },
  {
    value: 'Poco de Jose de Moura',
    name: 'Poço de José de Moura',
    uf: 'PB'
  },
  {
    value: 'Nova Timboteua',
    name: 'Nova Timboteua',
    uf: 'PA'
  },
  {
    value: 'Pedro Regis',
    name: 'Pedro Régis',
    uf: 'PB'
  },
  {
    value: 'Obidos',
    name: 'Óbidos',
    uf: 'PA'
  },
  {
    value: 'Riachao',
    name: 'Riachão',
    uf: 'PB'
  },
  {
    value: 'Oeiras do Para',
    name: 'Oeiras do Pará',
    uf: 'PA'
  },
  {
    value: 'Riachao do Bacamarte',
    name: 'Riachão do Bacamarte',
    uf: 'PB'
  },
  {
    value: 'Oriximina',
    name: 'Oriximiná',
    uf: 'PA'
  },
  {
    value: 'Riachao do Poco',
    name: 'Riachão do Poço',
    uf: 'PB'
  },
  {
    value: 'Ourem',
    name: 'Ourém',
    uf: 'PA'
  },
  {
    value: 'Riacho de Santo Antonio',
    name: 'Riacho de Santo Antônio',
    uf: 'PB'
  },
  {
    value: 'Paragominas',
    name: 'Paragominas',
    uf: 'PA'
  },
  {
    value: 'Santa Cecilia',
    name: 'Santa Cecília',
    uf: 'PB'
  },
  {
    value: 'Peixe-Boi',
    name: 'Peixe-Boi',
    uf: 'PA'
  },
  {
    value: 'Santa Ines',
    name: 'Santa Inês',
    uf: 'PB'
  },
  {
    value: 'Ponta de Pedras',
    name: 'Ponta de Pedras',
    uf: 'PA'
  },
  {
    value: 'Joca Claudino',
    name: 'Joca Claudino',
    uf: 'PB'
  },
  {
    value: 'Portel',
    name: 'Portel',
    uf: 'PA'
  },
  {
    value: 'Santo Andre',
    name: 'Santo André',
    uf: 'PB'
  },
  {
    value: 'Porto de Moz',
    name: 'Porto de Moz',
    uf: 'PA'
  },
  {
    value: 'Sao Bentinho',
    name: 'São Bentinho',
    uf: 'PB'
  },
  {
    value: 'Prainha',
    name: 'Prainha',
    uf: 'PA'
  },
  {
    value: 'Sao Domingos do Cariri',
    name: 'São Domingos do Cariri',
    uf: 'PB'
  },
  {
    value: 'Primavera',
    name: 'Primavera',
    uf: 'PA'
  },
  {
    value: 'Sao Domingos',
    name: 'São Domingos',
    uf: 'PB'
  },
  {
    value: 'Salinopolis',
    name: 'Salinópolis',
    uf: 'PA'
  },
  {
    value: 'Sao Francisco',
    name: 'São Francisco',
    uf: 'PB'
  },
  {
    value: 'Salvaterra',
    name: 'Salvaterra',
    uf: 'PA'
  },
  {
    value: 'Sao Jose dos Ramos',
    name: 'São José dos Ramos',
    uf: 'PB'
  },
  {
    value: 'Santa Cruz do Arari',
    name: 'Santa Cruz do Arari',
    uf: 'PA'
  },
  {
    value: 'Sao Jose de Princesa',
    name: 'São José de Princesa',
    uf: 'PB'
  },
  {
    value: 'Santa Izabel do Para',
    name: 'Santa Izabel do Pará',
    uf: 'PA'
  },
  {
    value: 'Sao Jose do Brejo do Cruz',
    name: 'São José do Brejo do Cruz',
    uf: 'PB'
  },
  {
    value: 'Santa Maria do Para',
    name: 'Santa Maria do Pará',
    uf: 'PA'
  },
  {
    value: 'Sertaozinho',
    name: 'Sertãozinho',
    uf: 'PB'
  },
  {
    value: 'Santana do Araguaia',
    name: 'Santana do Araguaia',
    uf: 'PA'
  },
  {
    value: 'Sobrado',
    name: 'Sobrado',
    uf: 'PB'
  },
  {
    value: 'Santarem',
    name: 'Santarém',
    uf: 'PA'
  },
  {
    value: 'Sossego',
    name: 'Sossêgo',
    uf: 'PB'
  },
  {
    value: 'Santarem Novo',
    name: 'Santarém Novo',
    uf: 'PA'
  },
  {
    value: 'Tenorio',
    name: 'Tenório',
    uf: 'PB'
  },
  {
    value: 'Santo Antonio do Taua',
    name: 'Santo Antônio do Tauá',
    uf: 'PA'
  },
  {
    value: 'Vieiropolis',
    name: 'Vieirópolis',
    uf: 'PB'
  },
  {
    value: 'Sao Caetano de Odivelas',
    name: 'São Caetano de Odivelas',
    uf: 'PA'
  },
  {
    value: 'Zabele',
    name: 'Zabelê',
    uf: 'PB'
  },
  {
    value: 'Sao Domingos do Capim',
    name: 'São Domingos do Capim',
    uf: 'PA'
  },
  {
    value: 'Aracoiaba',
    name: 'Araçoiaba',
    uf: 'PE'
  },
  {
    value: 'Sao Felix do Xingu',
    name: 'São Félix do Xingu',
    uf: 'PA'
  },
  {
    value: 'Casinhas',
    name: 'Casinhas',
    uf: 'PE'
  },
  {
    value: 'Sao Francisco do Para',
    name: 'São Francisco do Pará',
    uf: 'PA'
  },
  {
    value: 'Jaqueira',
    name: 'Jaqueira',
    uf: 'PE'
  },
  {
    value: 'Sao Joao do Araguaia',
    name: 'São João do Araguaia',
    uf: 'PA'
  },
  {
    value: 'Jatoba',
    name: 'Jatobá',
    uf: 'PE'
  },
  {
    value: 'Sao Miguel do Guama',
    name: 'São Miguel do Guamá',
    uf: 'PA'
  },
  {
    value: 'Lagoa Grande',
    name: 'Lagoa Grande',
    uf: 'PE'
  },
  {
    value: 'Sao Sebastiao da Boa Vista',
    name: 'São Sebastião da Boa Vista',
    uf: 'PA'
  },
  {
    value: 'Manari',
    name: 'Manari',
    uf: 'PE'
  },
  {
    value: 'Senador Jose Porfirio',
    name: 'Senador José Porfírio',
    uf: 'PA'
  },
  {
    value: 'Santa Filomena',
    name: 'Santa Filomena',
    uf: 'PE'
  },
  {
    value: 'Soure',
    name: 'Soure',
    uf: 'PA'
  },
  {
    value: 'Tamandare',
    name: 'Tamandaré',
    uf: 'PE'
  },
  {
    value: 'Tome-Acu',
    name: 'Tomé-Açu',
    uf: 'PA'
  },
  {
    value: 'Campestre',
    name: 'Campestre',
    uf: 'AL'
  },
  {
    value: 'Tucurui',
    name: 'Tucuruí',
    uf: 'PA'
  },
  {
    value: 'Jequia da Praia',
    name: 'Jequiá da Praia',
    uf: 'AL'
  },
  {
    value: 'Vigia',
    name: 'Vigia',
    uf: 'PA'
  },
  {
    value: 'Alto Caparao',
    name: 'Alto Caparaó',
    uf: 'MG'
  },
  {
    value: 'Viseu',
    name: 'Viseu',
    uf: 'PA'
  },
  {
    value: 'Angelandia',
    name: 'Angelândia',
    uf: 'MG'
  },
  {
    value: 'Redencao',
    name: 'Redenção',
    uf: 'PA'
  },
  {
    value: 'Aricanduva',
    name: 'Aricanduva',
    uf: 'MG'
  },
  {
    value: 'Rio Maria',
    name: 'Rio Maria',
    uf: 'PA'
  },
  {
    value: 'Berizal',
    name: 'Berizal',
    uf: 'MG'
  },
  {
    value: 'Xinguara',
    name: 'Xinguara',
    uf: 'PA'
  },
  {
    value: 'Bonito de Minas',
    name: 'Bonito de Minas',
    uf: 'MG'
  },
  {
    value: 'Rondon do Para',
    name: 'Rondon do Pará',
    uf: 'PA'
  },
  {
    value: 'Brasilandia de Minas',
    name: 'Brasilândia de Minas',
    uf: 'MG'
  },
  {
    value: 'Bom Jesus do Tocantins',
    name: 'Bom Jesus do Tocantins',
    uf: 'PA'
  },
  {
    value: 'Bugre',
    name: 'Bugre',
    uf: 'MG'
  },
  {
    value: 'Brejo Grande do Araguaia',
    name: 'Brejo Grande do Araguaia',
    uf: 'PA'
  },
  {
    value: 'Cabeceira Grande',
    name: 'Cabeceira Grande',
    uf: 'MG'
  },
  {
    value: 'Concordia do Para',
    name: 'Concórdia do Pará',
    uf: 'PA'
  },
  {
    value: 'Campo Azul',
    name: 'Campo Azul',
    uf: 'MG'
  },
  {
    value: 'Curionopolis',
    name: 'Curionópolis',
    uf: 'PA'
  },
  {
    value: 'Cantagalo',
    name: 'Cantagalo',
    uf: 'MG'
  },
  {
    value: 'Dom Eliseu',
    name: 'Dom Eliseu',
    uf: 'PA'
  },
  {
    value: 'Catas Altas',
    name: 'Catas Altas',
    uf: 'MG'
  },
  {
    value: 'Garrafao do Norte',
    name: 'Garrafão do Norte',
    uf: 'PA'
  },
  {
    value: 'Catuti',
    name: 'Catuti',
    uf: 'MG'
  },
  {
    value: 'Mae do Rio',
    name: 'Mãe do Rio',
    uf: 'PA'
  },
  {
    value: 'Chapada Gaucha',
    name: 'Chapada Gaúcha',
    uf: 'MG'
  },
  {
    value: 'Medicilandia',
    name: 'Medicilândia',
    uf: 'PA'
  },
  {
    value: 'Conego Marinho',
    name: 'Cônego Marinho',
    uf: 'MG'
  },
  {
    value: 'Ourilandia do Norte',
    name: 'Ourilândia do Norte',
    uf: 'PA'
  },
  {
    value: 'Confins',
    name: 'Confins',
    uf: 'MG'
  },
  {
    value: 'Pacaja',
    name: 'Pacajá',
    uf: 'PA'
  },
  {
    value: 'Corrego Fundo',
    name: 'Córrego Fundo',
    uf: 'MG'
  },
  {
    value: 'Parauapebas',
    name: 'Parauapebas',
    uf: 'PA'
  },
  {
    value: 'Crisolita',
    name: 'Crisólita',
    uf: 'MG'
  },
  {
    value: 'Ruropolis',
    name: 'Rurópolis',
    uf: 'PA'
  },
  {
    value: 'Cuparaque',
    name: 'Cuparaque',
    uf: 'MG'
  },
  {
    value: 'Santa Maria das Barreiras',
    name: 'Santa Maria das Barreiras',
    uf: 'PA'
  },
  {
    value: 'Curral de Dentro',
    name: 'Curral de Dentro',
    uf: 'MG'
  },
  {
    value: 'Amapa',
    name: 'Amapá',
    uf: 'AP'
  },
  {
    value: 'Delta',
    name: 'Delta',
    uf: 'MG'
  },
  {
    value: 'Calcoene',
    name: 'Calçoene',
    uf: 'AP'
  },
  {
    value: 'Divisa Alegre',
    name: 'Divisa Alegre',
    uf: 'MG'
  },
  {
    value: 'Macapa',
    name: 'Macapá',
    uf: 'AP'
  },
  {
    value: 'Dom Bosco',
    name: 'Dom Bosco',
    uf: 'MG'
  },
  {
    value: 'Mazagao',
    name: 'Mazagão',
    uf: 'AP'
  },
  {
    value: 'Franciscopolis',
    name: 'Franciscópolis',
    uf: 'MG'
  },
  {
    value: 'Oiapoque',
    name: 'Oiapoque',
    uf: 'AP'
  },
  {
    value: 'Frei Lagonegro',
    name: 'Frei Lagonegro',
    uf: 'MG'
  },
  {
    value: 'Ferreira Gomes',
    name: 'Ferreira Gomes',
    uf: 'AP'
  },
  {
    value: 'Fruta de Leite',
    name: 'Fruta de Leite',
    uf: 'MG'
  },
  {
    value: 'Laranjal do Jari',
    name: 'Laranjal do Jari',
    uf: 'AP'
  },
  {
    value: 'Gameleiras',
    name: 'Gameleiras',
    uf: 'MG'
  },
  {
    value: 'Santana',
    name: 'Santana',
    uf: 'AP'
  },
  {
    value: 'Glaucilandia',
    name: 'Glaucilândia',
    uf: 'MG'
  },
  {
    value: 'Tartarugalzinho',
    name: 'Tartarugalzinho',
    uf: 'AP'
  },
  {
    value: 'Goiabeira',
    name: 'Goiabeira',
    uf: 'MG'
  },
  {
    value: 'Sao Geraldo do Araguaia',
    name: 'São Geraldo do Araguaia',
    uf: 'PA'
  },
  {
    value: 'Goiana',
    name: 'Goianá',
    uf: 'MG'
  },
  {
    value: 'Ipixuna do Para',
    name: 'Ipixuna do Pará',
    uf: 'PA'
  },
  {
    value: 'Guaraciama',
    name: 'Guaraciama',
    uf: 'MG'
  },
  {
    value: 'Ulianopolis',
    name: 'Ulianópolis',
    uf: 'PA'
  },
  {
    value: 'Ibiracatu',
    name: 'Ibiracatu',
    uf: 'MG'
  },
  {
    value: 'Breu Branco',
    name: 'Breu Branco',
    uf: 'PA'
  },
  {
    value: 'Imbe de Minas',
    name: 'Imbé de Minas',
    uf: 'MG'
  },
  {
    value: 'Goianesia do Para',
    name: 'Goianésia do Pará',
    uf: 'PA'
  },
  {
    value: 'Indaiabira',
    name: 'Indaiabira',
    uf: 'MG'
  },
  {
    value: 'Novo Repartimento',
    name: 'Novo Repartimento',
    uf: 'PA'
  },
  {
    value: 'Japonvar',
    name: 'Japonvar',
    uf: 'MG'
  },
  {
    value: 'Jacareacanga',
    name: 'Jacareacanga',
    uf: 'PA'
  },
  {
    value: 'Jenipapo de Minas',
    name: 'Jenipapo de Minas',
    uf: 'MG'
  },
  {
    value: 'Novo Progresso',
    name: 'Novo Progresso',
    uf: 'PA'
  },
  {
    value: 'Jose Goncalves de Minas',
    name: 'José Gonçalves de Minas',
    uf: 'MG'
  },
  {
    value: 'Trairao',
    name: 'Trairão',
    uf: 'PA'
  },
  {
    value: 'Jose Raydan',
    name: 'José Raydan',
    uf: 'MG'
  },
  {
    value: 'Terra Santa',
    name: 'Terra Santa',
    uf: 'PA'
  },
  {
    value: 'Josenopolis',
    name: 'Josenópolis',
    uf: 'MG'
  },
  {
    value: 'Brasil Novo',
    name: 'Brasil Novo',
    uf: 'PA'
  },
  {
    value: 'Juvenilia',
    name: 'Juvenília',
    uf: 'MG'
  },
  {
    value: 'Vitoria do Xingu',
    name: 'Vitória do Xingu',
    uf: 'PA'
  },
  {
    value: 'Leme do Prado',
    name: 'Leme do Prado',
    uf: 'MG'
  },
  {
    value: 'Acrelandia',
    name: 'Acrelândia',
    uf: 'AC'
  },
  {
    value: 'Luisburgo',
    name: 'Luisburgo',
    uf: 'MG'
  },
  {
    value: 'Bujari',
    name: 'Bujari',
    uf: 'AC'
  },
  {
    value: 'Luislandia',
    name: 'Luislândia',
    uf: 'MG'
  },
  {
    value: 'Capixaba',
    name: 'Capixaba',
    uf: 'AC'
  },
  {
    value: 'Mario Campos',
    name: 'Mário Campos',
    uf: 'MG'
  },
  {
    value: 'Porto Acre',
    name: 'Porto Acre',
    uf: 'AC'
  },
  {
    value: 'Martins Soares',
    name: 'Martins Soares',
    uf: 'MG'
  },
  {
    value: 'Epitaciolandia',
    name: 'Epitaciolândia',
    uf: 'AC'
  },
  {
    value: 'Miravania',
    name: 'Miravânia',
    uf: 'MG'
  },
  {
    value: 'Jordao',
    name: 'Jordão',
    uf: 'AC'
  },
  {
    value: 'Monte Formoso',
    name: 'Monte Formoso',
    uf: 'MG'
  },
  {
    value: 'Marechal Thaumaturgo',
    name: 'Marechal Thaumaturgo',
    uf: 'AC'
  },
  {
    value: 'Naque',
    name: 'Naque',
    uf: 'MG'
  },
  {
    value: 'Porto Walter',
    name: 'Porto Walter',
    uf: 'AC'
  },
  {
    value: 'Natalandia',
    name: 'Natalândia',
    uf: 'MG'
  },
  {
    value: 'Rodrigues Alves',
    name: 'Rodrigues Alves',
    uf: 'AC'
  },
  {
    value: 'Ninheira',
    name: 'Ninheira',
    uf: 'MG'
  },
  {
    value: 'Santa Rosa do Purus',
    name: 'Santa Rosa do Purus',
    uf: 'AC'
  },
  {
    value: 'Nova Belem',
    name: 'Nova Belém',
    uf: 'MG'
  },
  {
    value: 'Pedra Branca do Amapari',
    name: 'Pedra Branca do Amapari',
    uf: 'AP'
  },
  {
    value: 'Nova Porteirinha',
    name: 'Nova Porteirinha',
    uf: 'MG'
  },
  {
    value: 'Serra do Navio',
    name: 'Serra do Navio',
    uf: 'AP'
  },
  {
    value: 'Novo Oriente de Minas',
    name: 'Novo Oriente de Minas',
    uf: 'MG'
  },
  {
    value: 'Cutias',
    name: 'Cutias',
    uf: 'AP'
  },
  {
    value: 'Novorizonte',
    name: 'Novorizonte',
    uf: 'MG'
  },
  {
    value: 'Itaubal',
    name: 'Itaubal',
    uf: 'AP'
  },
  {
    value: "Olhos-d'Agua",
    name: "Olhos-d'Água",
    uf: 'MG'
  },
  {
    value: 'Porto Grande',
    name: 'Porto Grande',
    uf: 'AP'
  },
  {
    value: 'Oratorios',
    name: 'Oratórios',
    uf: 'MG'
  },
  {
    value: 'Pracuuba',
    name: 'Pracuúba',
    uf: 'AP'
  },
  {
    value: 'Orizania',
    name: 'Orizânia',
    uf: 'MG'
  },
  {
    value: 'Alto Paraiso',
    name: 'Alto Paraíso',
    uf: 'RO'
  },
  {
    value: 'Padre Carvalho',
    name: 'Padre Carvalho',
    uf: 'MG'
  },
  {
    value: 'Cacaulandia',
    name: 'Cacaulândia',
    uf: 'RO'
  },
  {
    value: 'Pai Pedro',
    name: 'Pai Pedro',
    uf: 'MG'
  },
  {
    value: 'Campo Novo de Rondonia',
    name: 'Campo Novo de Rondônia',
    uf: 'RO'
  },
  {
    value: 'Patis',
    name: 'Patis',
    uf: 'MG'
  },
  {
    value: 'Candeias do Jamari',
    name: 'Candeias do Jamari',
    uf: 'RO'
  },
  {
    value: 'Pedra Bonita',
    name: 'Pedra Bonita',
    uf: 'MG'
  },
  {
    value: 'Itapua do Oeste',
    name: 'Itapuã do Oeste',
    uf: 'RO'
  },
  {
    value: 'Periquito',
    name: 'Periquito',
    uf: 'MG'
  },
  {
    value: 'Monte Negro',
    name: 'Monte Negro',
    uf: 'RO'
  },
  {
    value: 'Piedade de Caratinga',
    name: 'Piedade de Caratinga',
    uf: 'MG'
  },
  {
    value: 'Rio Crespo',
    name: 'Rio Crespo',
    uf: 'RO'
  },
  {
    value: "Pingo-d'Agua",
    name: "Pingo-d'Água",
    uf: 'MG'
  },
  {
    value: 'Novo Horizonte do Oeste',
    name: 'Novo Horizonte do Oeste',
    uf: 'RO'
  },
  {
    value: 'Pintopolis',
    name: 'Pintópolis',
    uf: 'MG'
  },
  {
    value: 'Castanheiras',
    name: 'Castanheiras',
    uf: 'RO'
  },
  {
    value: 'Ponto Chique',
    name: 'Ponto Chique',
    uf: 'MG'
  },
  {
    value: 'Governador Jorge Teixeira',
    name: 'Governador Jorge Teixeira',
    uf: 'RO'
  },
  {
    value: 'Ponto dos Volantes',
    name: 'Ponto dos Volantes',
    uf: 'MG'
  },
  {
    value: 'Ministro Andreazza',
    name: 'Ministro Andreazza',
    uf: 'RO'
  },
  {
    value: 'Reduto',
    name: 'Reduto',
    uf: 'MG'
  },
  {
    value: 'Mirante da Serra',
    name: 'Mirante da Serra',
    uf: 'RO'
  },
  {
    value: 'Rosario da Limeira',
    name: 'Rosário da Limeira',
    uf: 'MG'
  },
  {
    value: 'Seringueiras',
    name: 'Seringueiras',
    uf: 'RO'
  },
  {
    value: 'Santa Barbara do Monte Verde',
    name: 'Santa Bárbara do Monte Verde',
    uf: 'MG'
  },
  {
    value: 'Afonso Cunha',
    name: 'Afonso Cunha',
    uf: 'MA'
  },
  {
    value: 'Santa Cruz de Minas',
    name: 'Santa Cruz de Minas',
    uf: 'MG'
  },
  {
    value: 'Alcantara',
    name: 'Alcântara',
    uf: 'MA'
  },
  {
    value: 'Santa Cruz de Salinas',
    name: 'Santa Cruz de Salinas',
    uf: 'MG'
  },
  {
    value: 'Aldeias Altas',
    name: 'Aldeias Altas',
    uf: 'MA'
  },
  {
    value: 'Santa Helena de Minas',
    name: 'Santa Helena de Minas',
    uf: 'MG'
  },
  {
    value: 'Altamira do Maranhao',
    name: 'Altamira do Maranhão',
    uf: 'MA'
  },
  {
    value: 'Santo Antonio do Retiro',
    name: 'Santo Antônio do Retiro',
    uf: 'MG'
  },
  {
    value: 'Alto Parnaiba',
    name: 'Alto Parnaíba',
    uf: 'MA'
  },
  {
    value: 'Sao Domingos das Dores',
    name: 'São Domingos das Dores',
    uf: 'MG'
  },
  {
    value: 'Amarante do Maranhao',
    name: 'Amarante do Maranhão',
    uf: 'MA'
  },
  {
    value: 'Sao Felix de Minas',
    name: 'São Félix de Minas',
    uf: 'MG'
  },
  {
    value: 'Anajatuba',
    name: 'Anajatuba',
    uf: 'MA'
  },
  {
    value: 'Sao Geraldo do Baixio',
    name: 'São Geraldo do Baixio',
    uf: 'MG'
  },
  {
    value: 'Anapurus',
    name: 'Anapurus',
    uf: 'MA'
  },
  {
    value: 'Sao Joao da Lagoa',
    name: 'São João da Lagoa',
    uf: 'MG'
  },
  {
    value: 'Araioses',
    name: 'Araioses',
    uf: 'MA'
  },
  {
    value: 'Sao Joao das Missoes',
    name: 'São João das Missões',
    uf: 'MG'
  },
  {
    value: 'Arari',
    name: 'Arari',
    uf: 'MA'
  },
  {
    value: 'Sao Joao do Pacui',
    name: 'São João do Pacuí',
    uf: 'MG'
  },
  {
    value: 'Axixa',
    name: 'Axixá',
    uf: 'MA'
  },
  {
    value: 'Sao Joaquim de Bicas',
    name: 'São Joaquim de Bicas',
    uf: 'MG'
  },
  {
    value: 'Bacabal',
    name: 'Bacabal',
    uf: 'MA'
  },
  {
    value: 'Sao Jose da Barra',
    name: 'São José da Barra',
    uf: 'MG'
  },
  {
    value: 'Bacuri',
    name: 'Bacuri',
    uf: 'MA'
  },
  {
    value: 'Sao Sebastiao da Vargem Alegre',
    name: 'São Sebastião da Vargem Alegre',
    uf: 'MG'
  },
  {
    value: 'Balsas',
    name: 'Balsas',
    uf: 'MA'
  },
  {
    value: 'Sao Sebastiao do Anta',
    name: 'São Sebastião do Anta',
    uf: 'MG'
  },
  {
    value: 'Barao de Grajau',
    name: 'Barão de Grajaú',
    uf: 'MA'
  },
  {
    value: 'Sarzedo',
    name: 'Sarzedo',
    uf: 'MG'
  },
  {
    value: 'Barra do Corda',
    name: 'Barra do Corda',
    uf: 'MA'
  },
  {
    value: 'Setubinha',
    name: 'Setubinha',
    uf: 'MG'
  },
  {
    value: 'Barreirinhas',
    name: 'Barreirinhas',
    uf: 'MA'
  },
  {
    value: 'Sem-Peixe',
    name: 'Sem-Peixe',
    uf: 'MG'
  },
  {
    value: 'Benedito Leite',
    name: 'Benedito Leite',
    uf: 'MA'
  },
  {
    value: 'Serranopolis de Minas',
    name: 'Serranópolis de Minas',
    uf: 'MG'
  },
  {
    value: 'Bequimao',
    name: 'Bequimão',
    uf: 'MA'
  },
  {
    value: 'Taparuba',
    name: 'Taparuba',
    uf: 'MG'
  },
  {
    value: 'Brejo',
    name: 'Brejo',
    uf: 'MA'
  },
  {
    value: 'Tocos do Moji',
    name: 'Tocos do Moji',
    uf: 'MG'
  },
  {
    value: 'Buriti',
    name: 'Buriti',
    uf: 'MA'
  },
  {
    value: 'Uniao de Minas',
    name: 'União de Minas',
    uf: 'MG'
  },
  {
    value: 'Buriti Bravo',
    name: 'Buriti Bravo',
    uf: 'MA'
  },
  {
    value: 'Uruana de Minas',
    name: 'Uruana de Minas',
    uf: 'MG'
  },
  {
    value: 'Cajapio',
    name: 'Cajapió',
    uf: 'MA'
  },
  {
    value: 'Vargem Alegre',
    name: 'Vargem Alegre',
    uf: 'MG'
  },
  {
    value: 'Cajari',
    name: 'Cajari',
    uf: 'MA'
  },
  {
    value: 'Vargem Grande do Rio Pardo',
    name: 'Vargem Grande do Rio Pardo',
    uf: 'MG'
  },
  {
    value: 'Candido Mendes',
    name: 'Cândido Mendes',
    uf: 'MA'
  },
  {
    value: 'Varjao de Minas',
    name: 'Varjão de Minas',
    uf: 'MG'
  },
  {
    value: 'Cantanhede',
    name: 'Cantanhede',
    uf: 'MA'
  },
  {
    value: 'Verdelandia',
    name: 'Verdelândia',
    uf: 'MG'
  },
  {
    value: 'Carolina',
    name: 'Carolina',
    uf: 'MA'
  },
  {
    value: 'Veredinha',
    name: 'Veredinha',
    uf: 'MG'
  },
  {
    value: 'Carutapera',
    name: 'Carutapera',
    uf: 'MA'
  },
  {
    value: 'Vermelho Novo',
    name: 'Vermelho Novo',
    uf: 'MG'
  },
  {
    value: 'Caxias',
    name: 'Caxias',
    uf: 'MA'
  },
  {
    value: 'Brejetuba',
    name: 'Brejetuba',
    uf: 'ES'
  },
  {
    value: 'Cedral',
    name: 'Cedral',
    uf: 'MA'
  },
  {
    value: 'Marataizes',
    name: 'Marataízes',
    uf: 'ES'
  },
  {
    value: 'Chapadinha',
    name: 'Chapadinha',
    uf: 'MA'
  },
  {
    value: 'Ponto Belo',
    name: 'Ponto Belo',
    uf: 'ES'
  },
  {
    value: 'Codo',
    name: 'Codó',
    uf: 'MA'
  },
  {
    value: 'Sao Roque do Canaa',
    name: 'São Roque do Canaã',
    uf: 'ES'
  },
  {
    value: 'Coelho Neto',
    name: 'Coelho Neto',
    uf: 'MA'
  },
  {
    value: 'Sooretama',
    name: 'Sooretama',
    uf: 'ES'
  },
  {
    value: 'Colinas',
    name: 'Colinas',
    uf: 'MA'
  },
  {
    value: 'Vila Valerio',
    name: 'Vila Valério',
    uf: 'ES'
  },
  {
    value: 'Coroata',
    name: 'Coroatá',
    uf: 'MA'
  },
  {
    value: 'Armacao dos Buzios',
    name: 'Armação dos Búzios',
    uf: 'RJ'
  },
  {
    value: 'Cururupu',
    name: 'Cururupu',
    uf: 'MA'
  },
  {
    value: 'Carapebus',
    name: 'Carapebus',
    uf: 'RJ'
  },
  {
    value: 'Dom Pedro',
    name: 'Dom Pedro',
    uf: 'MA'
  },
  {
    value: 'Iguaba Grande',
    name: 'Iguaba Grande',
    uf: 'RJ'
  },
  {
    value: 'Duque Bacelar',
    name: 'Duque Bacelar',
    uf: 'MA'
  },
  {
    value: 'Macuco',
    name: 'Macuco',
    uf: 'RJ'
  },
  {
    value: 'Esperantinopolis',
    name: 'Esperantinópolis',
    uf: 'MA'
  },
  {
    value: 'Pinheiral',
    name: 'Pinheiral',
    uf: 'RJ'
  },
  {
    value: 'Fortaleza dos Nogueiras',
    name: 'Fortaleza dos Nogueiras',
    uf: 'MA'
  },
  {
    value: 'Porto Real',
    name: 'Porto Real',
    uf: 'RJ'
  },
  {
    value: 'Fortuna',
    name: 'Fortuna',
    uf: 'MA'
  },
  {
    value: 'Sao Francisco de Itabapoana',
    name: 'São Francisco de Itabapoana',
    uf: 'RJ'
  },
  {
    value: 'Godofredo Viana',
    name: 'Godofredo Viana',
    uf: 'MA'
  },
  {
    value: 'Sao Jose de Uba',
    name: 'São José de Ubá',
    uf: 'RJ'
  },
  {
    value: 'Goncalves Dias',
    name: 'Gonçalves Dias',
    uf: 'MA'
  },
  {
    value: 'Seropedica',
    name: 'Seropédica',
    uf: 'RJ'
  },
  {
    value: 'Governador Archer',
    name: 'Governador Archer',
    uf: 'MA'
  },
  {
    value: 'Tangua',
    name: 'Tanguá',
    uf: 'RJ'
  },
  {
    value: 'Governador Eugenio Barros',
    name: 'Governador Eugênio Barros',
    uf: 'MA'
  },
  {
    value: 'Arco-Iris',
    name: 'Arco-Íris',
    uf: 'SP'
  },
  {
    value: 'Graca Aranha',
    name: 'Graça Aranha',
    uf: 'MA'
  },
  {
    value: 'Brejo Alegre',
    name: 'Brejo Alegre',
    uf: 'SP'
  },
  {
    value: 'Grajau',
    name: 'Grajaú',
    uf: 'MA'
  },
  {
    value: 'Canas',
    name: 'Canas',
    uf: 'SP'
  },
  {
    value: 'Guimaraes',
    name: 'Guimarães',
    uf: 'MA'
  },
  {
    value: 'Fernao',
    name: 'Fernão',
    uf: 'SP'
  },
  {
    value: 'Humberto de Campos',
    name: 'Humberto de Campos',
    uf: 'MA'
  },
  {
    value: 'Gaviao Peixoto',
    name: 'Gavião Peixoto',
    uf: 'SP'
  },
  {
    value: 'Icatu',
    name: 'Icatu',
    uf: 'MA'
  },
  {
    value: 'Ipigua',
    name: 'Ipiguá',
    uf: 'SP'
  },
  {
    value: 'Igarape Grande',
    name: 'Igarapé Grande',
    uf: 'MA'
  },
  {
    value: 'Jumirim',
    name: 'Jumirim',
    uf: 'SP'
  },
  {
    value: 'Imperatriz',
    name: 'Imperatriz',
    uf: 'MA'
  },
  {
    value: 'Nantes',
    name: 'Nantes',
    uf: 'SP'
  },
  {
    value: 'Sao Luis Gonzaga do Maranhao',
    name: 'São Luís Gonzaga do Maranhão',
    uf: 'MA'
  },
  {
    value: 'Nova Castilho',
    name: 'Nova Castilho',
    uf: 'SP'
  },
  {
    value: 'Itapecuru Mirim',
    name: 'Itapecuru Mirim',
    uf: 'MA'
  },
  {
    value: 'Ouroeste',
    name: 'Ouroeste',
    uf: 'SP'
  },
  {
    value: 'Joao Lisboa',
    name: 'João Lisboa',
    uf: 'MA'
  },
  {
    value: 'Paulistania',
    name: 'Paulistânia',
    uf: 'SP'
  },
  {
    value: 'Joselandia',
    name: 'Joselândia',
    uf: 'MA'
  },
  {
    value: 'Pracinha',
    name: 'Pracinha',
    uf: 'SP'
  },
  {
    value: 'Lago da Pedra',
    name: 'Lago da Pedra',
    uf: 'MA'
  },
  {
    value: 'Pratania',
    name: 'Pratânia',
    uf: 'SP'
  },
  {
    value: 'Lago do Junco',
    name: 'Lago do Junco',
    uf: 'MA'
  },
  {
    value: 'Quadra',
    name: 'Quadra',
    uf: 'SP'
  },
  {
    value: 'Lago Verde',
    name: 'Lago Verde',
    uf: 'MA'
  },
  {
    value: 'Ribeirao dos Indios',
    name: 'Ribeirão dos Índios',
    uf: 'SP'
  },
  {
    value: 'Lima Campos',
    name: 'Lima Campos',
    uf: 'MA'
  },
  {
    value: 'Santa Cruz da Esperanca',
    name: 'Santa Cruz da Esperança',
    uf: 'SP'
  },
  {
    value: 'Loreto',
    name: 'Loreto',
    uf: 'MA'
  },
  {
    value: 'Santa Salete',
    name: 'Santa Salete',
    uf: 'SP'
  },
  {
    value: 'Luis Domingues',
    name: 'Luís Domingues',
    uf: 'MA'
  },
  {
    value: 'Taquaral',
    name: 'Taquaral',
    uf: 'SP'
  },
  {
    value: 'Magalhaes de Almeida',
    name: 'Magalhães de Almeida',
    uf: 'MA'
  },
  {
    value: 'Trabiju',
    name: 'Trabiju',
    uf: 'SP'
  },
  {
    value: 'Mata Roma',
    name: 'Mata Roma',
    uf: 'MA'
  },
  {
    value: 'Vitoria Brasil',
    name: 'Vitória Brasil',
    uf: 'SP'
  },
  {
    value: 'Matinha',
    name: 'Matinha',
    uf: 'MA'
  },
  {
    value: 'Arapua',
    name: 'Arapuã',
    uf: 'PR'
  },
  {
    value: 'Matoes',
    name: 'Matões',
    uf: 'MA'
  },
  {
    value: 'Ariranha do Ivai',
    name: 'Ariranha do Ivaí',
    uf: 'PR'
  },
  {
    value: 'Mirador',
    name: 'Mirador',
    uf: 'MA'
  },
  {
    value: 'Bela Vista da Caroba',
    name: 'Bela Vista da Caroba',
    uf: 'PR'
  },
  {
    value: 'Mirinzal',
    name: 'Mirinzal',
    uf: 'MA'
  },
  {
    value: 'Boa Ventura de Sao Roque',
    name: 'Boa Ventura de São Roque',
    uf: 'PR'
  },
  {
    value: 'Moncao',
    name: 'Monção',
    uf: 'MA'
  },
  {
    value: 'Bom Jesus do Sul',
    name: 'Bom Jesus do Sul',
    uf: 'PR'
  },
  {
    value: 'Montes Altos',
    name: 'Montes Altos',
    uf: 'MA'
  },
  {
    value: 'Campina do Simao',
    name: 'Campina do Simão',
    uf: 'PR'
  },
  {
    value: 'Morros',
    name: 'Morros',
    uf: 'MA'
  },
  {
    value: 'Campo Magro',
    name: 'Campo Magro',
    uf: 'PR'
  },
  {
    value: 'Nina Rodrigues',
    name: 'Nina Rodrigues',
    uf: 'MA'
  },
  {
    value: 'Carambei',
    name: 'Carambeí',
    uf: 'PR'
  },
  {
    value: 'Nova Iorque',
    name: 'Nova Iorque',
    uf: 'MA'
  },
  {
    value: 'Coronel Domingos Soares',
    name: 'Coronel Domingos Soares',
    uf: 'PR'
  },
  {
    value: "Olho d'Agua das Cunhas",
    name: "Olho d'Água das Cunhãs",
    uf: 'MA'
  },
  {
    value: 'Cruzmaltina',
    name: 'Cruzmaltina',
    uf: 'PR'
  },
  {
    value: 'Paco do Lumiar',
    name: 'Paço do Lumiar',
    uf: 'MA'
  },
  {
    value: 'Esperanca Nova',
    name: 'Esperança Nova',
    uf: 'PR'
  },
  {
    value: 'Palmeirandia',
    name: 'Palmeirândia',
    uf: 'MA'
  },
  {
    value: 'Espigao Alto do Iguacu',
    name: 'Espigão Alto do Iguaçu',
    uf: 'PR'
  },
  {
    value: 'Paraibano',
    name: 'Paraibano',
    uf: 'MA'
  },
  {
    value: 'Fernandes Pinheiro',
    name: 'Fernandes Pinheiro',
    uf: 'PR'
  },
  {
    value: 'Parnarama',
    name: 'Parnarama',
    uf: 'MA'
  },
  {
    value: 'Foz do Jordao',
    name: 'Foz do Jordão',
    uf: 'PR'
  },
  {
    value: 'Passagem Franca',
    name: 'Passagem Franca',
    uf: 'MA'
  },
  {
    value: 'Goioxim',
    name: 'Goioxim',
    uf: 'PR'
  },
  {
    value: 'Pastos Bons',
    name: 'Pastos Bons',
    uf: 'MA'
  },
  {
    value: 'Guamiranga',
    name: 'Guamiranga',
    uf: 'PR'
  },
  {
    value: 'Pedreiras',
    name: 'Pedreiras',
    uf: 'MA'
  },
  {
    value: 'Imbau',
    name: 'Imbaú',
    uf: 'PR'
  },
  {
    value: 'Penalva',
    name: 'Penalva',
    uf: 'MA'
  },
  {
    value: 'Manfrinopolis',
    name: 'Manfrinópolis',
    uf: 'PR'
  },
  {
    value: 'Peri Mirim',
    name: 'Peri Mirim',
    uf: 'MA'
  },
  {
    value: 'Marquinho',
    name: 'Marquinho',
    uf: 'PR'
  },
  {
    value: 'Pindare-Mirim',
    name: 'Pindaré-Mirim',
    uf: 'MA'
  },
  {
    value: 'Perobal',
    name: 'Perobal',
    uf: 'PR'
  },
  {
    value: 'Pinheiro',
    name: 'Pinheiro',
    uf: 'MA'
  },
  {
    value: 'Pontal do Parana',
    name: 'Pontal do Paraná',
    uf: 'PR'
  },
  {
    value: 'Pio XII',
    name: 'Pio XII',
    uf: 'MA'
  },
  {
    value: 'Porto Barreiro',
    name: 'Porto Barreiro',
    uf: 'PR'
  },
  {
    value: 'Pirapemas',
    name: 'Pirapemas',
    uf: 'MA'
  },
  {
    value: 'Prado Ferreira',
    name: 'Prado Ferreira',
    uf: 'PR'
  },
  {
    value: 'Pocao de Pedras',
    name: 'Poção de Pedras',
    uf: 'MA'
  },
  {
    value: 'Quarto Centenario',
    name: 'Quarto Centenário',
    uf: 'PR'
  },
  {
    value: 'Porto Franco',
    name: 'Porto Franco',
    uf: 'MA'
  },
  {
    value: 'Reserva do Iguacu',
    name: 'Reserva do Iguaçu',
    uf: 'PR'
  },
  {
    value: 'Presidente Dutra',
    name: 'Presidente Dutra',
    uf: 'MA'
  },
  {
    value: 'Rio Branco do Ivai',
    name: 'Rio Branco do Ivaí',
    uf: 'PR'
  },
  {
    value: 'Presidente Juscelino',
    name: 'Presidente Juscelino',
    uf: 'MA'
  },
  {
    value: 'Serranopolis do Iguacu',
    name: 'Serranópolis do Iguaçu',
    uf: 'PR'
  },
  {
    value: 'Presidente Vargas',
    name: 'Presidente Vargas',
    uf: 'MA'
  },
  {
    value: 'Tamarana',
    name: 'Tamarana',
    uf: 'PR'
  },
  {
    value: 'Primeira Cruz',
    name: 'Primeira Cruz',
    uf: 'MA'
  },
  {
    value: 'Alto Bela Vista',
    name: 'Alto Bela Vista',
    uf: 'SC'
  },
  {
    value: 'Riachao',
    name: 'Riachão',
    uf: 'MA'
  },
  {
    value: 'Balneario Arroio do Silva',
    name: 'Balneário Arroio do Silva',
    uf: 'SC'
  },
  {
    value: 'Sao Jose de Ribamar',
    name: 'São José de Ribamar',
    uf: 'MA'
  },
  {
    value: 'Balneario Gaivota',
    name: 'Balneário Gaivota',
    uf: 'SC'
  },
  {
    value: 'Rosario',
    name: 'Rosário',
    uf: 'MA'
  },
  {
    value: 'Bandeirante',
    name: 'Bandeirante',
    uf: 'SC'
  },
  {
    value: 'Sambaiba',
    name: 'Sambaíba',
    uf: 'MA'
  },
  {
    value: 'Barra Bonita',
    name: 'Barra Bonita',
    uf: 'SC'
  },
  {
    value: 'Santa Helena',
    name: 'Santa Helena',
    uf: 'MA'
  },
  {
    value: 'Bela Vista do Toldo',
    name: 'Bela Vista do Toldo',
    uf: 'SC'
  },
  {
    value: 'Santa Luzia',
    name: 'Santa Luzia',
    uf: 'MA'
  },
  {
    value: 'Bocaina do Sul',
    name: 'Bocaina do Sul',
    uf: 'SC'
  },
  {
    value: 'Santa Quiteria do Maranhao',
    name: 'Santa Quitéria do Maranhão',
    uf: 'MA'
  },
  {
    value: 'Bom Jesus',
    name: 'Bom Jesus',
    uf: 'SC'
  },
  {
    value: 'Santa Rita',
    name: 'Santa Rita',
    uf: 'MA'
  },
  {
    value: 'Bom Jesus do Oeste',
    name: 'Bom Jesus do Oeste',
    uf: 'SC'
  },
  {
    value: 'Santo Antonio dos Lopes',
    name: 'Santo Antônio dos Lopes',
    uf: 'MA'
  },
  {
    value: 'Brunopolis',
    name: 'Brunópolis',
    uf: 'SC'
  },
  {
    value: 'Sao Benedito do Rio Preto',
    name: 'São Benedito do Rio Preto',
    uf: 'MA'
  },
  {
    value: 'Capao Alto',
    name: 'Capão Alto',
    uf: 'SC'
  },
  {
    value: 'Sao Bento',
    name: 'São Bento',
    uf: 'MA'
  },
  {
    value: 'Chapadao do Lageado',
    name: 'Chapadão do Lageado',
    uf: 'SC'
  },
  {
    value: 'Sao Bernardo',
    name: 'São Bernardo',
    uf: 'MA'
  },
  {
    value: 'Cunhatai',
    name: 'Cunhataí',
    uf: 'SC'
  },
  {
    value: 'Sao Domingos do Maranhao',
    name: 'São Domingos do Maranhão',
    uf: 'MA'
  },
  {
    value: 'Entre Rios',
    name: 'Entre Rios',
    uf: 'SC'
  },
  {
    value: 'Sao Felix de Balsas',
    name: 'São Félix de Balsas',
    uf: 'MA'
  },
  {
    value: 'Ermo',
    name: 'Ermo',
    uf: 'SC'
  },
  {
    value: 'Sao Francisco do Maranhao',
    name: 'São Francisco do Maranhão',
    uf: 'MA'
  },
  {
    value: 'Flor do Sertao',
    name: 'Flor do Sertão',
    uf: 'SC'
  },
  {
    value: 'Sao Joao Batista',
    name: 'São João Batista',
    uf: 'MA'
  },
  {
    value: 'Frei Rogerio',
    name: 'Frei Rogério',
    uf: 'SC'
  },
  {
    value: 'Sao Joao dos Patos',
    name: 'São João dos Patos',
    uf: 'MA'
  },
  {
    value: 'Ibiam',
    name: 'Ibiam',
    uf: 'SC'
  },
  {
    value: 'Sao Luis',
    name: 'São Luís',
    uf: 'MA'
  },
  {
    value: 'Iomere',
    name: 'Iomerê',
    uf: 'SC'
  },
  {
    value: 'Sao Mateus do Maranhao',
    name: 'São Mateus do Maranhão',
    uf: 'MA'
  },
  {
    value: 'Jupia',
    name: 'Jupiá',
    uf: 'SC'
  },
  {
    value: 'Sao Raimundo das Mangabeiras',
    name: 'São Raimundo das Mangabeiras',
    uf: 'MA'
  },
  {
    value: 'Luzerna',
    name: 'Luzerna',
    uf: 'SC'
  },
  {
    value: 'Sao Vicente Ferrer',
    name: 'São Vicente Ferrer',
    uf: 'MA'
  },
  {
    value: 'Paial',
    name: 'Paial',
    uf: 'SC'
  },
  {
    value: 'Sitio Novo',
    name: 'Sítio Novo',
    uf: 'MA'
  },
  {
    value: 'Painel',
    name: 'Painel',
    uf: 'SC'
  },
  {
    value: 'Sucupira do Norte',
    name: 'Sucupira do Norte',
    uf: 'MA'
  },
  {
    value: 'Palmeira',
    name: 'Palmeira',
    uf: 'SC'
  },
  {
    value: 'Tasso Fragoso',
    name: 'Tasso Fragoso',
    uf: 'MA'
  },
  {
    value: 'Princesa',
    name: 'Princesa',
    uf: 'SC'
  },
  {
    value: 'Timbiras',
    name: 'Timbiras',
    uf: 'MA'
  },
  {
    value: 'Saltinho',
    name: 'Saltinho',
    uf: 'SC'
  },
  {
    value: 'Timon',
    name: 'Timon',
    uf: 'MA'
  },
  {
    value: 'Santa Terezinha do Progresso',
    name: 'Santa Terezinha do Progresso',
    uf: 'SC'
  },
  {
    value: 'Tuntum',
    name: 'Tuntum',
    uf: 'MA'
  },
  {
    value: 'Santiago do Sul',
    name: 'Santiago do Sul',
    uf: 'SC'
  },
  {
    value: 'Turiacu',
    name: 'Turiaçu',
    uf: 'MA'
  },
  {
    value: 'Sao Bernardino',
    name: 'São Bernardino',
    uf: 'SC'
  },
  {
    value: 'Tutoia',
    name: 'Tutóia',
    uf: 'MA'
  },
  {
    value: 'Sao Pedro de Alcantara',
    name: 'São Pedro de Alcântara',
    uf: 'SC'
  },
  {
    value: 'Urbano Santos',
    name: 'Urbano Santos',
    uf: 'MA'
  },
  {
    value: 'Tigrinhos',
    name: 'Tigrinhos',
    uf: 'SC'
  },
  {
    value: 'Vargem Grande',
    name: 'Vargem Grande',
    uf: 'MA'
  },
  {
    value: 'Treviso',
    name: 'Treviso',
    uf: 'SC'
  },
  {
    value: 'Viana',
    name: 'Viana',
    uf: 'MA'
  },
  {
    value: 'Zortea',
    name: 'Zortéa',
    uf: 'SC'
  },
  {
    value: 'Vitoria do Mearim',
    name: 'Vitória do Mearim',
    uf: 'MA'
  },
  {
    value: 'Ararica',
    name: 'Araricá',
    uf: 'RS'
  },
  {
    value: 'Vitorino Freire',
    name: 'Vitorino Freire',
    uf: 'MA'
  },
  {
    value: 'Balneario Pinhal',
    name: 'Balneário Pinhal',
    uf: 'RS'
  },
  {
    value: 'Bom Jardim',
    name: 'Bom Jardim',
    uf: 'MA'
  },
  {
    value: 'Barra do Quarai',
    name: 'Barra do Quaraí',
    uf: 'RS'
  },
  {
    value: 'Santa Ines',
    name: 'Santa Inês',
    uf: 'MA'
  },
  {
    value: 'Benjamin Constant do Sul',
    name: 'Benjamin Constant do Sul',
    uf: 'RS'
  },
  {
    value: 'Paulo Ramos',
    name: 'Paulo Ramos',
    uf: 'MA'
  },
  {
    value: 'Boa Vista do Sul',
    name: 'Boa Vista do Sul',
    uf: 'RS'
  },
  {
    value: 'Acailandia',
    name: 'Açailândia',
    uf: 'MA'
  },
  {
    value: 'Capivari do Sul',
    name: 'Capivari do Sul',
    uf: 'RS'
  },
  {
    value: 'Estreito',
    name: 'Estreito',
    uf: 'MA'
  },
  {
    value: 'Caraa',
    name: 'Caraá',
    uf: 'RS'
  },
  {
    value: 'Careiro da Varzea',
    name: 'Careiro da Várzea',
    uf: 'AM'
  },
  {
    value: 'Cerrito',
    name: 'Cerrito',
    uf: 'RS'
  },
  {
    value: 'Guajara',
    name: 'Guajará',
    uf: 'AM'
  },
  {
    value: 'Chui',
    name: 'Chuí',
    uf: 'RS'
  },
  {
    value: 'Apui',
    name: 'Apuí',
    uf: 'AM'
  },
  {
    value: 'Chuvisca',
    name: 'Chuvisca',
    uf: 'RS'
  },
  {
    value: 'Teotonio Vilela',
    name: 'Teotônio Vilela',
    uf: 'AL'
  },
  {
    value: 'Cristal do Sul',
    name: 'Cristal do Sul',
    uf: 'RS'
  },
  {
    value: 'Forquilhinha',
    name: 'Forquilhinha',
    uf: 'SC'
  },
  {
    value: 'Dilermando de Aguiar',
    name: 'Dilermando de Aguiar',
    uf: 'RS'
  },
  {
    value: 'Theobroma',
    name: 'Theobroma',
    uf: 'RO'
  },
  {
    value: 'Dom Pedro de Alcantara',
    name: 'Dom Pedro de Alcântara',
    uf: 'RS'
  },
  {
    value: 'Urupa',
    name: 'Urupá',
    uf: 'RO'
  },
  {
    value: 'Doutor Ricardo',
    name: 'Doutor Ricardo',
    uf: 'RS'
  },
  {
    value: 'Vale do Paraiso',
    name: 'Vale do Paraíso',
    uf: 'RO'
  },
  {
    value: 'Esperanca do Sul',
    name: 'Esperança do Sul',
    uf: 'RS'
  },
  {
    value: 'Corumbiara',
    name: 'Corumbiara',
    uf: 'RO'
  },
  {
    value: 'Estrela Velha',
    name: 'Estrela Velha',
    uf: 'RS'
  },
  {
    value: 'Catunda',
    name: 'Catunda',
    uf: 'CE'
  },
  {
    value: 'Fazenda Vilanova',
    name: 'Fazenda Vilanova',
    uf: 'RS'
  },
  {
    value: 'Jijoca de Jericoacoara',
    name: 'Jijoca de Jericoacoara',
    uf: 'CE'
  },
  {
    value: 'Floriano Peixoto',
    name: 'Floriano Peixoto',
    uf: 'RS'
  },
  {
    value: 'Fortim',
    name: 'Fortim',
    uf: 'CE'
  },
  {
    value: 'Herveiras',
    name: 'Herveiras',
    uf: 'RS'
  },
  {
    value: 'Ararenda',
    name: 'Ararendá',
    uf: 'CE'
  },
  {
    value: 'Itaara',
    name: 'Itaara',
    uf: 'RS'
  },
  {
    value: 'Itaitinga',
    name: 'Itaitinga',
    uf: 'CE'
  },
  {
    value: 'Jari',
    name: 'Jari',
    uf: 'RS'
  },
  {
    value: 'Choro',
    name: 'Choró',
    uf: 'CE'
  },
  {
    value: 'Macambara',
    name: 'Maçambará',
    uf: 'RS'
  },
  {
    value: 'Coivaras',
    name: 'Coivaras',
    uf: 'PI'
  },
  {
    value: 'Mampituba',
    name: 'Mampituba',
    uf: 'RS'
  },
  {
    value: 'Jardim do Mulato',
    name: 'Jardim do Mulato',
    uf: 'PI'
  },
  {
    value: 'Marques de Souza',
    name: 'Marques de Souza',
    uf: 'RS'
  },
  {
    value: 'Lagoa Alegre',
    name: 'Lagoa Alegre',
    uf: 'PI'
  },
  {
    value: 'Monte Alegre dos Campos',
    name: 'Monte Alegre dos Campos',
    uf: 'RS'
  },
  {
    value: 'Agricolandia',
    name: 'Agricolândia',
    uf: 'PI'
  },
  {
    value: 'Muitos Capoes',
    name: 'Muitos Capões',
    uf: 'RS'
  },
  {
    value: 'Agua Branca',
    name: 'Água Branca',
    uf: 'PI'
  },
  {
    value: 'Nova Candelaria',
    name: 'Nova Candelária',
    uf: 'RS'
  },
  {
    value: 'Alto Longa',
    name: 'Alto Longá',
    uf: 'PI'
  },
  {
    value: 'Nova Ramada',
    name: 'Nova Ramada',
    uf: 'RS'
  },
  {
    value: 'Altos',
    name: 'Altos',
    uf: 'PI'
  },
  {
    value: 'Novo Cabrais',
    name: 'Novo Cabrais',
    uf: 'RS'
  },
  {
    value: 'Amarante',
    name: 'Amarante',
    uf: 'PI'
  },
  {
    value: 'Passa Sete',
    name: 'Passa Sete',
    uf: 'RS'
  },
  {
    value: 'Angical do Piaui',
    name: 'Angical do Piauí',
    uf: 'PI'
  },
  {
    value: 'Senador Salgado Filho',
    name: 'Senador Salgado Filho',
    uf: 'RS'
  },
  {
    value: 'Anisio de Abreu',
    name: 'Anísio de Abreu',
    uf: 'PI'
  },
  {
    value: 'Sete de Setembro',
    name: 'Sete de Setembro',
    uf: 'RS'
  },
  {
    value: 'Antonio Almeida',
    name: 'Antônio Almeida',
    uf: 'PI'
  },
  {
    value: 'Tabai',
    name: 'Tabaí',
    uf: 'RS'
  },
  {
    value: 'Aroazes',
    name: 'Aroazes',
    uf: 'PI'
  },
  {
    value: 'Toropi',
    name: 'Toropi',
    uf: 'RS'
  },
  {
    value: 'Arraial',
    name: 'Arraial',
    uf: 'PI'
  },
  {
    value: 'Turucu',
    name: 'Turuçu',
    uf: 'RS'
  },
  {
    value: 'Avelino Lopes',
    name: 'Avelino Lopes',
    uf: 'PI'
  },
  {
    value: 'Ubiretama',
    name: 'Ubiretama',
    uf: 'RS'
  },
  {
    value: 'Barras',
    name: 'Barras',
    uf: 'PI'
  },
  {
    value: 'Unistalda',
    name: 'Unistalda',
    uf: 'RS'
  },
  {
    value: 'Barreiras do Piaui',
    name: 'Barreiras do Piauí',
    uf: 'PI'
  },
  {
    value: 'Vale Verde',
    name: 'Vale Verde',
    uf: 'RS'
  },
  {
    value: 'Barro Duro',
    name: 'Barro Duro',
    uf: 'PI'
  },
  {
    value: 'Vespasiano Correa',
    name: 'Vespasiano Corrêa',
    uf: 'RS'
  },
  {
    value: 'Batalha',
    name: 'Batalha',
    uf: 'PI'
  },
  {
    value: 'Vila Langaro',
    name: 'Vila Lângaro',
    uf: 'RS'
  },
  {
    value: 'Beneditinos',
    name: 'Beneditinos',
    uf: 'PI'
  },
  {
    value: 'Campos de Julio',
    name: 'Campos de Júlio',
    uf: 'MT'
  },
  {
    value: 'Bertolinia',
    name: 'Bertolínia',
    uf: 'PI'
  },
  {
    value: 'Carlinda',
    name: 'Carlinda',
    uf: 'MT'
  },
  {
    value: 'Bocaina',
    name: 'Bocaina',
    uf: 'PI'
  },
  {
    value: 'Feliz Natal',
    name: 'Feliz Natal',
    uf: 'MT'
  },
  {
    value: 'Bom Jesus',
    name: 'Bom Jesus',
    uf: 'PI'
  },
  {
    value: 'Gaucha do Norte',
    name: 'Gaúcha do Norte',
    uf: 'MT'
  },
  {
    value: 'Buriti dos Lopes',
    name: 'Buriti dos Lopes',
    uf: 'PI'
  },
  {
    value: 'Nova Lacerda',
    name: 'Nova Lacerda',
    uf: 'MT'
  },
  {
    value: 'Campinas do Piaui',
    name: 'Campinas do Piauí',
    uf: 'PI'
  },
  {
    value: 'Nova Ubirata',
    name: 'Nova Ubiratã',
    uf: 'MT'
  },
  {
    value: 'Campo Maior',
    name: 'Campo Maior',
    uf: 'PI'
  },
  {
    value: 'Novo Mundo',
    name: 'Novo Mundo',
    uf: 'MT'
  },
  {
    value: 'Canto do Buriti',
    name: 'Canto do Buriti',
    uf: 'PI'
  },
  {
    value: 'Sapezal',
    name: 'Sapezal',
    uf: 'MT'
  },
  {
    value: 'Capitao de Campos',
    name: 'Capitão de Campos',
    uf: 'PI'
  },
  {
    value: 'Uniao do Sul',
    name: 'União do Sul',
    uf: 'MT'
  },
  {
    value: 'Caracol',
    name: 'Caracol',
    uf: 'PI'
  },
  {
    value: 'Abadia de Goias',
    name: 'Abadia de Goiás',
    uf: 'GO'
  },
  {
    value: 'Castelo do Piaui',
    name: 'Castelo do Piauí',
    uf: 'PI'
  },
  {
    value: 'Aguas Lindas de Goias',
    name: 'Águas Lindas de Goiás',
    uf: 'GO'
  },
  {
    value: 'Cocal',
    name: 'Cocal',
    uf: 'PI'
  },
  {
    value: 'Amaralina',
    name: 'Amaralina',
    uf: 'GO'
  },
  {
    value: 'Conceicao do Caninde',
    name: 'Conceição do Canindé',
    uf: 'PI'
  },
  {
    value: 'Bonopolis',
    name: 'Bonópolis',
    uf: 'GO'
  },
  {
    value: 'Corrente',
    name: 'Corrente',
    uf: 'PI'
  },
  {
    value: 'Novo Gama',
    name: 'Novo Gama',
    uf: 'GO'
  },
  {
    value: 'Cristalandia do Piaui',
    name: 'Cristalândia do Piauí',
    uf: 'PI'
  },
  {
    value: 'Porteirao',
    name: 'Porteirão',
    uf: 'GO'
  },
  {
    value: 'Cristino Castro',
    name: 'Cristino Castro',
    uf: 'PI'
  },
  {
    value: 'Santa Rita do Novo Destino',
    name: 'Santa Rita do Novo Destino',
    uf: 'GO'
  },
  {
    value: 'Curimata',
    name: 'Curimatá',
    uf: 'PI'
  },
  {
    value: 'Sao Patricio',
    name: 'São Patrício',
    uf: 'GO'
  },
  {
    value: 'Demerval Lobao',
    name: 'Demerval Lobão',
    uf: 'PI'
  },
  {
    value: 'Valparaiso de Goias',
    name: 'Valparaíso de Goiás',
    uf: 'GO'
  },
  {
    value: 'Dom Expedito Lopes',
    name: 'Dom Expedito Lopes',
    uf: 'PI'
  },
  {
    value: 'Vila Propicio',
    name: 'Vila Propício',
    uf: 'GO'
  },
  {
    value: 'Elesbao Veloso',
    name: 'Elesbão Veloso',
    uf: 'PI'
  },
  {
    value: 'Eliseu Martins',
    name: 'Eliseu Martins',
    uf: 'PI'
  },
  {
    value: 'Esperantina',
    name: 'Esperantina',
    uf: 'PI'
  },
  {
    value: 'Flores do Piaui',
    name: 'Flores do Piauí',
    uf: 'PI'
  },
  {
    value: 'Floriano',
    name: 'Floriano',
    uf: 'PI'
  },
  {
    value: 'Francinopolis',
    name: 'Francinópolis',
    uf: 'PI'
  },
  {
    value: 'Francisco Ayres',
    name: 'Francisco Ayres',
    uf: 'PI'
  },
  {
    value: 'Francisco Santos',
    name: 'Francisco Santos',
    uf: 'PI'
  },
  {
    value: 'Fronteiras',
    name: 'Fronteiras',
    uf: 'PI'
  },
  {
    value: 'Gilbues',
    name: 'Gilbués',
    uf: 'PI'
  },
  {
    value: 'Guadalupe',
    name: 'Guadalupe',
    uf: 'PI'
  },
  {
    value: 'Hugo Napoleao',
    name: 'Hugo Napoleão',
    uf: 'PI'
  },
  {
    value: 'Inhuma',
    name: 'Inhuma',
    uf: 'PI'
  },
  {
    value: 'Santa Cruz do Xingu',
    name: 'Santa Cruz do Xingu',
    uf: 'MT'
  },
  {
    value: 'Ipiranga do Piaui',
    name: 'Ipiranga do Piauí',
    uf: 'PI'
  },
  {
    value: 'Santa Rita do Trivelato',
    name: 'Santa Rita do Trivelato',
    uf: 'MT'
  },
  {
    value: 'Isaias Coelho',
    name: 'Isaías Coelho',
    uf: 'PI'
  },
  {
    value: 'Santo Antonio do Leste',
    name: 'Santo Antônio do Leste',
    uf: 'MT'
  },
  {
    value: 'Itainopolis',
    name: 'Itainópolis',
    uf: 'PI'
  },
  {
    value: 'Serra Nova Dourada',
    name: 'Serra Nova Dourada',
    uf: 'MT'
  },
  {
    value: 'Itaueira',
    name: 'Itaueira',
    uf: 'PI'
  },
  {
    value: 'Vale de Sao Domingos',
    name: 'Vale de São Domingos',
    uf: 'MT'
  },
  {
    value: 'Jaicos',
    name: 'Jaicós',
    uf: 'PI'
  },
  {
    value: "Pau D'Arco do Piaui",
    name: "Pau D'Arco do Piauí",
    uf: 'PI'
  },
  {
    value: 'Jerumenha',
    name: 'Jerumenha',
    uf: 'PI'
  },
  {
    value: 'Joaquim Pires',
    name: 'Joaquim Pires',
    uf: 'PI'
  },
  {
    value: 'Jundia',
    name: 'Jundiá',
    uf: 'RN'
  },
  {
    value: 'Jose de Freitas',
    name: 'José de Freitas',
    uf: 'PI'
  },
  {
    value: 'Landri Sales',
    name: 'Landri Sales',
    uf: 'PI'
  },
  {
    value: 'Luis Correia',
    name: 'Luís Correia',
    uf: 'PI'
  },
  {
    value: 'Luzilandia',
    name: 'Luzilândia',
    uf: 'PI'
  },
  {
    value: 'Mesquita',
    name: 'Mesquita',
    uf: 'RJ'
  },
  {
    value: 'Manoel Emidio',
    name: 'Manoel Emídio',
    uf: 'PI'
  },
  {
    value: 'Acegua',
    name: 'Aceguá',
    uf: 'RS'
  },
  {
    value: 'Marcos Parente',
    name: 'Marcos Parente',
    uf: 'PI'
  },
  {
    value: 'Almirante Tamandare do Sul',
    name: 'Almirante Tamandaré do Sul',
    uf: 'RS'
  },
  {
    value: 'Matias Olimpio',
    name: 'Matias Olímpio',
    uf: 'PI'
  },
  {
    value: 'Arroio do Padre',
    name: 'Arroio do Padre',
    uf: 'RS'
  },
  {
    value: 'Miguel Alves',
    name: 'Miguel Alves',
    uf: 'PI'
  },
  {
    value: 'Boa Vista do Cadeado',
    name: 'Boa Vista do Cadeado',
    uf: 'RS'
  },
  {
    value: 'Miguel Leao',
    name: 'Miguel Leão',
    uf: 'PI'
  },
  {
    value: 'Boa Vista do Incra',
    name: 'Boa Vista do Incra',
    uf: 'RS'
  },
  {
    value: 'Monsenhor Gil',
    name: 'Monsenhor Gil',
    uf: 'PI'
  },
  {
    value: 'Bozano',
    name: 'Bozano',
    uf: 'RS'
  },
  {
    value: 'Monsenhor Hipolito',
    name: 'Monsenhor Hipólito',
    uf: 'PI'
  },
  {
    value: 'Canudos do Vale',
    name: 'Canudos do Vale',
    uf: 'RS'
  },
  {
    value: 'Monte Alegre do Piaui',
    name: 'Monte Alegre do Piauí',
    uf: 'PI'
  },
  {
    value: 'Capao Bonito do Sul',
    name: 'Capão Bonito do Sul',
    uf: 'RS'
  },
  {
    value: 'Nazare do Piaui',
    name: 'Nazaré do Piauí',
    uf: 'PI'
  },
  {
    value: 'Capao do Cipo',
    name: 'Capão do Cipó',
    uf: 'RS'
  },
  {
    value: 'Nossa Senhora dos Remedios',
    name: 'Nossa Senhora dos Remédios',
    uf: 'PI'
  },
  {
    value: 'Coqueiro Baixo',
    name: 'Coqueiro Baixo',
    uf: 'RS'
  },
  {
    value: 'Novo Oriente do Piaui',
    name: 'Novo Oriente do Piauí',
    uf: 'PI'
  },
  {
    value: 'Coronel Pilar',
    name: 'Coronel Pilar',
    uf: 'RS'
  },
  {
    value: 'Oeiras',
    name: 'Oeiras',
    uf: 'PI'
  },
  {
    value: 'Cruzaltense',
    name: 'Cruzaltense',
    uf: 'RS'
  },
  {
    value: 'Domingos Mourao',
    name: 'Domingos Mourão',
    uf: 'PI'
  },
  {
    value: 'Forquetinha',
    name: 'Forquetinha',
    uf: 'RS'
  },
  {
    value: 'Padre Marcos',
    name: 'Padre Marcos',
    uf: 'PI'
  },
  {
    value: 'Itati',
    name: 'Itati',
    uf: 'RS'
  },
  {
    value: 'Paes Landim',
    name: 'Paes Landim',
    uf: 'PI'
  },
  {
    value: 'Jacuizinho',
    name: 'Jacuizinho',
    uf: 'RS'
  },
  {
    value: 'Palmeira do Piaui',
    name: 'Palmeira do Piauí',
    uf: 'PI'
  },
  {
    value: 'Lagoa Bonita do Sul',
    name: 'Lagoa Bonita do Sul',
    uf: 'RS'
  },
  {
    value: 'Palmeirais',
    name: 'Palmeirais',
    uf: 'PI'
  },
  {
    value: 'Mato Queimado',
    name: 'Mato Queimado',
    uf: 'RS'
  },
  {
    value: 'Parnagua',
    name: 'Parnaguá',
    uf: 'PI'
  },
  {
    value: 'Novo Xingu',
    name: 'Novo Xingu',
    uf: 'RS'
  },
  {
    value: 'Parnaiba',
    name: 'Parnaíba',
    uf: 'PI'
  },
  {
    value: 'Paulo Bento',
    name: 'Paulo Bento',
    uf: 'RS'
  },
  {
    value: 'Paulistana',
    name: 'Paulistana',
    uf: 'PI'
  },
  {
    value: 'Pedras Altas',
    name: 'Pedras Altas',
    uf: 'RS'
  },
  {
    value: 'Pedro II',
    name: 'Pedro II',
    uf: 'PI'
  },
  {
    value: 'Pinhal da Serra',
    name: 'Pinhal da Serra',
    uf: 'RS'
  },
  {
    value: 'Picos',
    name: 'Picos',
    uf: 'PI'
  },
  {
    value: 'Pinto Bandeira',
    name: 'Pinto Bandeira',
    uf: 'RS'
  },
  {
    value: 'Pimenteiras',
    name: 'Pimenteiras',
    uf: 'PI'
  },
  {
    value: 'Quatro Irmaos',
    name: 'Quatro Irmãos',
    uf: 'RS'
  },
  {
    value: 'Pio IX',
    name: 'Pio IX',
    uf: 'PI'
  },
  {
    value: 'Rolador',
    name: 'Rolador',
    uf: 'RS'
  },
  {
    value: 'Piracuruca',
    name: 'Piracuruca',
    uf: 'PI'
  },
  {
    value: 'Santa Cecilia do Sul',
    name: 'Santa Cecília do Sul',
    uf: 'RS'
  },
  {
    value: 'Piripiri',
    name: 'Piripiri',
    uf: 'PI'
  },
  {
    value: 'Santa Margarida do Sul',
    name: 'Santa Margarida do Sul',
    uf: 'RS'
  },
  {
    value: 'Porto',
    name: 'Porto',
    uf: 'PI'
  },
  {
    value: 'Sao Jose do Sul',
    name: 'São José do Sul',
    uf: 'RS'
  },
  {
    value: 'Prata do Piaui',
    name: 'Prata do Piauí',
    uf: 'PI'
  },
  {
    value: 'Sao Pedro das Missoes',
    name: 'São Pedro das Missões',
    uf: 'RS'
  },
  {
    value: 'Redencao do Gurgueia',
    name: 'Redenção do Gurguéia',
    uf: 'PI'
  },
  {
    value: 'Tio Hugo',
    name: 'Tio Hugo',
    uf: 'RS'
  },
  {
    value: 'Regeneracao',
    name: 'Regeneração',
    uf: 'PI'
  },
  {
    value: 'Westfalia',
    name: 'Westfália',
    uf: 'RS'
  },
  {
    value: 'Ribeiro Goncalves',
    name: 'Ribeiro Gonçalves',
    uf: 'PI'
  },
  {
    value: 'Figueirao',
    name: 'Figueirão',
    uf: 'MS'
  },
  {
    value: 'Rio Grande do Piaui',
    name: 'Rio Grande do Piauí',
    uf: 'PI'
  },
  {
    value: 'Santa Cruz do Piaui',
    name: 'Santa Cruz do Piauí',
    uf: 'PI'
  },
  {
    value: 'Santa Filomena',
    name: 'Santa Filomena',
    uf: 'PI'
  },
  {
    value: 'Ipiranga do Norte',
    name: 'Ipiranga do Norte',
    uf: 'MT'
  },
  {
    value: 'Santa Luz',
    name: 'Santa Luz',
    uf: 'PI'
  },
  {
    value: 'Itanhanga',
    name: 'Itanhangá',
    uf: 'MT'
  },
  {
    value: 'Santo Antonio de Lisboa',
    name: 'Santo Antônio de Lisboa',
    uf: 'PI'
  },
  {
    value: 'Aroeiras do Itaim',
    name: 'Aroeiras do Itaim',
    uf: 'PI'
  },
  {
    value: 'Santo Inacio do Piaui',
    name: 'Santo Inácio do Piauí',
    uf: 'PI'
  },
  {
    value: 'Sao Felix do Piaui',
    name: 'São Félix do Piauí',
    uf: 'PI'
  },
  {
    value: 'Sao Francisco do Piaui',
    name: 'São Francisco do Piauí',
    uf: 'PI'
  },
  {
    value: 'Sao Goncalo do Piaui',
    name: 'São Gonçalo do Piauí',
    uf: 'PI'
  },
  {
    value: 'Sao Joao da Serra',
    name: 'São João da Serra',
    uf: 'PI'
  },
  {
    value: 'Sao Joao do Piaui',
    name: 'São João do Piauí',
    uf: 'PI'
  },
  {
    value: 'Sao Jose do Peixe',
    name: 'São José do Peixe',
    uf: 'PI'
  },
  {
    value: 'Sao Jose do Piaui',
    name: 'São José do Piauí',
    uf: 'PI'
  },
  {
    value: 'Sao Juliao',
    name: 'São Julião',
    uf: 'PI'
  },
  {
    value: 'Sao Miguel do Tapuio',
    name: 'São Miguel do Tapuio',
    uf: 'PI'
  },
  {
    value: 'Sao Pedro do Piaui',
    name: 'São Pedro do Piauí',
    uf: 'PI'
  },
  {
    value: 'Sao Raimundo Nonato',
    name: 'São Raimundo Nonato',
    uf: 'PI'
  },
  {
    value: 'Simoes',
    name: 'Simões',
    uf: 'PI'
  },
  {
    value: 'Simplicio Mendes',
    name: 'Simplício Mendes',
    uf: 'PI'
  },
  {
    value: 'Socorro do Piaui',
    name: 'Socorro do Piauí',
    uf: 'PI'
  },
  {
    value: 'Teresina',
    name: 'Teresina',
    uf: 'PI'
  },
  {
    value: 'Uniao',
    name: 'União',
    uf: 'PI'
  },
  {
    value: 'Urucui',
    name: 'Uruçuí',
    uf: 'PI'
  },
  {
    value: 'Valenca do Piaui',
    name: 'Valença do Piauí',
    uf: 'PI'
  },
  {
    value: 'Varzea Grande',
    name: 'Várzea Grande',
    uf: 'PI'
  },
  {
    value: 'Dirceu Arcoverde',
    name: 'Dirceu Arcoverde',
    uf: 'PI'
  },
  {
    value: 'Acarape',
    name: 'Acarape',
    uf: 'CE'
  },
  {
    value: 'Banabuiu',
    name: 'Banabuiú',
    uf: 'CE'
  },
  {
    value: 'Barreira',
    name: 'Barreira',
    uf: 'CE'
  },
  {
    value: 'Barroquinha',
    name: 'Barroquinha',
    uf: 'CE'
  },
  {
    value: 'Chorozinho',
    name: 'Chorozinho',
    uf: 'CE'
  },
  {
    value: 'Croata',
    name: 'Croatá',
    uf: 'CE'
  },
  {
    value: 'Deputado Irapuan Pinheiro',
    name: 'Deputado Irapuan Pinheiro',
    uf: 'CE'
  },
  {
    value: 'Erere',
    name: 'Ereré',
    uf: 'CE'
  },
  {
    value: 'Eusebio',
    name: 'Eusébio',
    uf: 'CE'
  },
  {
    value: 'Graca',
    name: 'Graça',
    uf: 'CE'
  },
  {
    value: 'Guaiuba',
    name: 'Guaiúba',
    uf: 'CE'
  },
  {
    value: 'Horizonte',
    name: 'Horizonte',
    uf: 'CE'
  },
  {
    value: 'Ibaretama',
    name: 'Ibaretama',
    uf: 'CE'
  },
  {
    value: 'Ibicuitinga',
    name: 'Ibicuitinga',
    uf: 'CE'
  },
  {
    value: 'Ipaporanga',
    name: 'Ipaporanga',
    uf: 'CE'
  },
  {
    value: 'Madalena',
    name: 'Madalena',
    uf: 'CE'
  },
  {
    value: 'Miraima',
    name: 'Miraíma',
    uf: 'CE'
  },
  {
    value: 'Ocara',
    name: 'Ocara',
    uf: 'CE'
  },
  {
    value: 'Pindoretama',
    name: 'Pindoretama',
    uf: 'CE'
  },
  {
    value: 'Pires Ferreira',
    name: 'Pires Ferreira',
    uf: 'CE'
  },
  {
    value: 'Potiretama',
    name: 'Potiretama',
    uf: 'CE'
  },
  {
    value: 'Salitre',
    name: 'Salitre',
    uf: 'CE'
  },
  {
    value: 'Tarrafas',
    name: 'Tarrafas',
    uf: 'CE'
  },
  {
    value: 'Tejucuoca',
    name: 'Tejuçuoca',
    uf: 'CE'
  },
  {
    value: 'Tururu',
    name: 'Tururu',
    uf: 'CE'
  },
  {
    value: 'Arame',
    name: 'Arame',
    uf: 'MA'
  },
  {
    value: 'Miranda do Norte',
    name: 'Miranda do Norte',
    uf: 'MA'
  },
  {
    value: 'Santa Luzia do Parua',
    name: 'Santa Luzia do Paruá',
    uf: 'MA'
  },
  {
    value: 'Ze Doca',
    name: 'Zé Doca',
    uf: 'MA'
  },
  {
    value: 'Dom Inocencio',
    name: 'Dom Inocêncio',
    uf: 'PI'
  },
  {
    value: 'Sao Joao da Canabrava',
    name: 'São João da Canabrava',
    uf: 'PI'
  },
  {
    value: 'Passagem Franca do Piaui',
    name: 'Passagem Franca do Piauí',
    uf: 'PI'
  },
  {
    value: 'Santa Cruz dos Milagres',
    name: 'Santa Cruz dos Milagres',
    uf: 'PI'
  },
  {
    value: 'Buriti dos Montes',
    name: 'Buriti dos Montes',
    uf: 'PI'
  },
  {
    value: 'Cabeceiras do Piaui',
    name: 'Cabeceiras do Piauí',
    uf: 'PI'
  },
  {
    value: 'Abaiara',
    name: 'Abaiara',
    uf: 'CE'
  },
  {
    value: 'Acarau',
    name: 'Acaraú',
    uf: 'CE'
  },
  {
    value: 'Acopiara',
    name: 'Acopiara',
    uf: 'CE'
  },
  {
    value: 'Aiuaba',
    name: 'Aiuaba',
    uf: 'CE'
  },
  {
    value: 'Alcantaras',
    name: 'Alcântaras',
    uf: 'CE'
  },
  {
    value: 'Altaneira',
    name: 'Altaneira',
    uf: 'CE'
  },
  {
    value: 'Alto Santo',
    name: 'Alto Santo',
    uf: 'CE'
  },
  {
    value: 'Antonina do Norte',
    name: 'Antonina do Norte',
    uf: 'CE'
  },
  {
    value: 'Apuiares',
    name: 'Apuiarés',
    uf: 'CE'
  },
  {
    value: 'Aquiraz',
    name: 'Aquiraz',
    uf: 'CE'
  },
  {
    value: 'Aracati',
    name: 'Aracati',
    uf: 'CE'
  },
  {
    value: 'Aracoiaba',
    name: 'Aracoiaba',
    uf: 'CE'
  },
  {
    value: 'Araripe',
    name: 'Araripe',
    uf: 'CE'
  },
  {
    value: 'Aratuba',
    name: 'Aratuba',
    uf: 'CE'
  },
  {
    value: 'Arneiroz',
    name: 'Arneiroz',
    uf: 'CE'
  },
  {
    value: 'Assare',
    name: 'Assaré',
    uf: 'CE'
  },
  {
    value: 'Aurora',
    name: 'Aurora',
    uf: 'CE'
  },
  {
    value: 'Baixio',
    name: 'Baixio',
    uf: 'CE'
  },
  {
    value: 'Barbalha',
    name: 'Barbalha',
    uf: 'CE'
  },
  {
    value: 'Barro',
    name: 'Barro',
    uf: 'CE'
  },
  {
    value: 'Baturite',
    name: 'Baturité',
    uf: 'CE'
  },
  {
    value: 'Beberibe',
    name: 'Beberibe',
    uf: 'CE'
  },
  {
    value: 'Bela Cruz',
    name: 'Bela Cruz',
    uf: 'CE'
  },
  {
    value: 'Boa Viagem',
    name: 'Boa Viagem',
    uf: 'CE'
  },
  {
    value: 'Brejo Santo',
    name: 'Brejo Santo',
    uf: 'CE'
  },
  {
    value: 'Camocim',
    name: 'Camocim',
    uf: 'CE'
  },
  {
    value: 'Campos Sales',
    name: 'Campos Sales',
    uf: 'CE'
  },
  {
    value: 'Caninde',
    name: 'Canindé',
    uf: 'CE'
  },
  {
    value: 'Capistrano',
    name: 'Capistrano',
    uf: 'CE'
  },
  {
    value: 'Caridade',
    name: 'Caridade',
    uf: 'CE'
  },
  {
    value: 'Carire',
    name: 'Cariré',
    uf: 'CE'
  },
  {
    value: 'Caririacu',
    name: 'Caririaçu',
    uf: 'CE'
  },
  {
    value: 'Carius',
    name: 'Cariús',
    uf: 'CE'
  },
  {
    value: 'Carnaubal',
    name: 'Carnaubal',
    uf: 'CE'
  },
  {
    value: 'Cascavel',
    name: 'Cascavel',
    uf: 'CE'
  },
  {
    value: 'Catarina',
    name: 'Catarina',
    uf: 'CE'
  },
  {
    value: 'Caucaia',
    name: 'Caucaia',
    uf: 'CE'
  },
  {
    value: 'Cedro',
    name: 'Cedro',
    uf: 'CE'
  },
  {
    value: 'Chaval',
    name: 'Chaval',
    uf: 'CE'
  },
  {
    value: 'Sigefredo Pacheco',
    name: 'Sigefredo Pacheco',
    uf: 'PI'
  },
  {
    value: 'Coreau',
    name: 'Coreaú',
    uf: 'CE'
  },
  {
    value: 'Crateus',
    name: 'Crateús',
    uf: 'CE'
  },
  {
    value: 'Crato',
    name: 'Crato',
    uf: 'CE'
  },
  {
    value: 'Farias Brito',
    name: 'Farias Brito',
    uf: 'CE'
  },
  {
    value: 'Fortaleza',
    name: 'Fortaleza',
    uf: 'CE'
  },
  {
    value: 'Frecheirinha',
    name: 'Frecheirinha',
    uf: 'CE'
  },
  {
    value: 'General Sampaio',
    name: 'General Sampaio',
    uf: 'CE'
  },
  {
    value: 'Granja',
    name: 'Granja',
    uf: 'CE'
  },
  {
    value: 'Granjeiro',
    name: 'Granjeiro',
    uf: 'CE'
  },
  {
    value: 'Groairas',
    name: 'Groaíras',
    uf: 'CE'
  },
  {
    value: 'Guaraciaba do Norte',
    name: 'Guaraciaba do Norte',
    uf: 'CE'
  },
  {
    value: 'Guaramiranga',
    name: 'Guaramiranga',
    uf: 'CE'
  },
  {
    value: 'Hidrolandia',
    name: 'Hidrolândia',
    uf: 'CE'
  },
  {
    value: 'Ibiapina',
    name: 'Ibiapina',
    uf: 'CE'
  },
  {
    value: 'Ico',
    name: 'Icó',
    uf: 'CE'
  },
  {
    value: 'Iguatu',
    name: 'Iguatu',
    uf: 'CE'
  },
  {
    value: 'Independencia',
    name: 'Independência',
    uf: 'CE'
  },
  {
    value: 'Ipaumirim',
    name: 'Ipaumirim',
    uf: 'CE'
  },
  {
    value: 'Ipu',
    name: 'Ipu',
    uf: 'CE'
  },
  {
    value: 'Ipueiras',
    name: 'Ipueiras',
    uf: 'CE'
  },
  {
    value: 'Iracema',
    name: 'Iracema',
    uf: 'CE'
  },
  {
    value: 'Iraucuba',
    name: 'Irauçuba',
    uf: 'CE'
  },
  {
    value: 'Itaicaba',
    name: 'Itaiçaba',
    uf: 'CE'
  },
  {
    value: 'Itapaje',
    name: 'Itapajé',
    uf: 'CE'
  },
  {
    value: 'Itapipoca',
    name: 'Itapipoca',
    uf: 'CE'
  },
  {
    value: 'Itapiuna',
    name: 'Itapiúna',
    uf: 'CE'
  },
  {
    value: 'Itatira',
    name: 'Itatira',
    uf: 'CE'
  },
  {
    value: 'Jaguaretama',
    name: 'Jaguaretama',
    uf: 'CE'
  },
  {
    value: 'Jaguaribara',
    name: 'Jaguaribara',
    uf: 'CE'
  },
  {
    value: 'Jaguaribe',
    name: 'Jaguaribe',
    uf: 'CE'
  },
  {
    value: 'Jaguaruana',
    name: 'Jaguaruana',
    uf: 'CE'
  },
  {
    value: 'Jardim',
    name: 'Jardim',
    uf: 'CE'
  },
  {
    value: 'Jati',
    name: 'Jati',
    uf: 'CE'
  },
  {
    value: 'Juazeiro do Norte',
    name: 'Juazeiro do Norte',
    uf: 'CE'
  },
  {
    value: 'Jucas',
    name: 'Jucás',
    uf: 'CE'
  },
  {
    value: 'Lavras da Mangabeira',
    name: 'Lavras da Mangabeira',
    uf: 'CE'
  },
  {
    value: 'Limoeiro do Norte',
    name: 'Limoeiro do Norte',
    uf: 'CE'
  },
  {
    value: 'Maranguape',
    name: 'Maranguape',
    uf: 'CE'
  },
  {
    value: 'Marco',
    name: 'Marco',
    uf: 'CE'
  },
  {
    value: 'Martinopole',
    name: 'Martinópole',
    uf: 'CE'
  },
  {
    value: 'Massape',
    name: 'Massapê',
    uf: 'CE'
  },
  {
    value: 'Mauriti',
    name: 'Mauriti',
    uf: 'CE'
  },
  {
    value: 'Meruoca',
    name: 'Meruoca',
    uf: 'CE'
  },
  {
    value: 'Milagres',
    name: 'Milagres',
    uf: 'CE'
  },
  {
    value: 'Missao Velha',
    name: 'Missão Velha',
    uf: 'CE'
  },
  {
    value: 'Mombaca',
    name: 'Mombaça',
    uf: 'CE'
  },
  {
    value: 'Monsenhor Tabosa',
    name: 'Monsenhor Tabosa',
    uf: 'CE'
  },
  {
    value: 'Morada Nova',
    name: 'Morada Nova',
    uf: 'CE'
  },
  {
    value: 'Moraujo',
    name: 'Moraújo',
    uf: 'CE'
  },
  {
    value: 'Morrinhos',
    name: 'Morrinhos',
    uf: 'CE'
  },
  {
    value: 'Mucambo',
    name: 'Mucambo',
    uf: 'CE'
  },
  {
    value: 'Mulungu',
    name: 'Mulungu',
    uf: 'CE'
  },
  {
    value: 'Nova Olinda',
    name: 'Nova Olinda',
    uf: 'CE'
  },
  {
    value: 'Nova Russas',
    name: 'Nova Russas',
    uf: 'CE'
  },
  {
    value: 'Novo Oriente',
    name: 'Novo Oriente',
    uf: 'CE'
  },
  {
    value: 'Oros',
    name: 'Orós',
    uf: 'CE'
  },
  {
    value: 'Pacajus',
    name: 'Pacajus',
    uf: 'CE'
  },
  {
    value: 'Pacatuba',
    name: 'Pacatuba',
    uf: 'CE'
  },
  {
    value: 'Pacoti',
    name: 'Pacoti',
    uf: 'CE'
  },
  {
    value: 'Pacuja',
    name: 'Pacujá',
    uf: 'CE'
  },
  {
    value: 'Palhano',
    name: 'Palhano',
    uf: 'CE'
  },
  {
    value: 'Palmacia',
    name: 'Palmácia',
    uf: 'CE'
  },
  {
    value: 'Paracuru',
    name: 'Paracuru',
    uf: 'CE'
  },
  {
    value: 'Parambu',
    name: 'Parambu',
    uf: 'CE'
  },
  {
    value: 'Paramoti',
    name: 'Paramoti',
    uf: 'CE'
  },
  {
    value: 'Pedra Branca',
    name: 'Pedra Branca',
    uf: 'CE'
  },
  {
    value: 'Penaforte',
    name: 'Penaforte',
    uf: 'CE'
  },
  {
    value: 'Pentecoste',
    name: 'Pentecoste',
    uf: 'CE'
  },
  {
    value: 'Pereiro',
    name: 'Pereiro',
    uf: 'CE'
  },
  {
    value: 'Piquet Carneiro',
    name: 'Piquet Carneiro',
    uf: 'CE'
  },
  {
    value: 'Poranga',
    name: 'Poranga',
    uf: 'CE'
  },
  {
    value: 'Porteiras',
    name: 'Porteiras',
    uf: 'CE'
  },
  {
    value: 'Potengi',
    name: 'Potengi',
    uf: 'CE'
  },
  {
    value: 'Quixada',
    name: 'Quixadá',
    uf: 'CE'
  },
  {
    value: 'Quixeramobim',
    name: 'Quixeramobim',
    uf: 'CE'
  },
  {
    value: 'Quixere',
    name: 'Quixeré',
    uf: 'CE'
  },
  {
    value: 'Redencao',
    name: 'Redenção',
    uf: 'CE'
  },
  {
    value: 'Reriutaba',
    name: 'Reriutaba',
    uf: 'CE'
  },
  {
    value: 'Russas',
    name: 'Russas',
    uf: 'CE'
  },
  {
    value: 'Saboeiro',
    name: 'Saboeiro',
    uf: 'CE'
  },
  {
    value: 'Santana do Acarau',
    name: 'Santana do Acaraú',
    uf: 'CE'
  },
  {
    value: 'Santana do Cariri',
    name: 'Santana do Cariri',
    uf: 'CE'
  },
  {
    value: 'Santa Quiteria',
    name: 'Santa Quitéria',
    uf: 'CE'
  },
  {
    value: 'Sao Benedito',
    name: 'São Benedito',
    uf: 'CE'
  },
  {
    value: 'Sao Goncalo do Amarante',
    name: 'São Gonçalo do Amarante',
    uf: 'CE'
  },
  {
    value: 'Sao Joao do Jaguaribe',
    name: 'São João do Jaguaribe',
    uf: 'CE'
  },
  {
    value: 'Sao Luis do Curu',
    name: 'São Luís do Curu',
    uf: 'CE'
  },
  {
    value: 'Senador Pompeu',
    name: 'Senador Pompeu',
    uf: 'CE'
  },
  {
    value: 'Senador Sa',
    name: 'Senador Sá',
    uf: 'CE'
  },
  {
    value: 'Sobral',
    name: 'Sobral',
    uf: 'CE'
  },
  {
    value: 'Solonopole',
    name: 'Solonópole',
    uf: 'CE'
  },
  {
    value: 'Tabuleiro do Norte',
    name: 'Tabuleiro do Norte',
    uf: 'CE'
  },
  {
    value: 'Tamboril',
    name: 'Tamboril',
    uf: 'CE'
  },
  {
    value: 'Taua',
    name: 'Tauá',
    uf: 'CE'
  },
  {
    value: 'Tiangua',
    name: 'Tianguá',
    uf: 'CE'
  },
  {
    value: 'Trairi',
    name: 'Trairi',
    uf: 'CE'
  },
  {
    value: 'Ubajara',
    name: 'Ubajara',
    uf: 'CE'
  },
  {
    value: 'Umari',
    name: 'Umari',
    uf: 'CE'
  },
  {
    value: 'Uruburetama',
    name: 'Uruburetama',
    uf: 'CE'
  },
  {
    value: 'Uruoca',
    name: 'Uruoca',
    uf: 'CE'
  },
  {
    value: 'Varzea Alegre',
    name: 'Várzea Alegre',
    uf: 'CE'
  },
  {
    value: 'Vicosa do Ceara',
    name: 'Viçosa do Ceará',
    uf: 'CE'
  },
  {
    value: 'Maracanau',
    name: 'Maracanaú',
    uf: 'CE'
  },
  {
    value: 'Amontada',
    name: 'Amontada',
    uf: 'CE'
  },
  {
    value: 'Cruz',
    name: 'Cruz',
    uf: 'CE'
  },
  {
    value: 'Forquilha',
    name: 'Forquilha',
    uf: 'CE'
  },
  {
    value: 'Icapui',
    name: 'Icapuí',
    uf: 'CE'
  },
  {
    value: 'Itarema',
    name: 'Itarema',
    uf: 'CE'
  },
  {
    value: 'Milha',
    name: 'Milhã',
    uf: 'CE'
  },
  {
    value: 'Paraipaba',
    name: 'Paraipaba',
    uf: 'CE'
  },
  {
    value: 'Acari',
    name: 'Acari',
    uf: 'RN'
  },
  {
    value: 'Acu',
    name: 'Açu',
    uf: 'RN'
  },
  {
    value: 'Afonso Bezerra',
    name: 'Afonso Bezerra',
    uf: 'RN'
  },
  {
    value: 'Agua Nova',
    name: 'Água Nova',
    uf: 'RN'
  },
  {
    value: 'Alexandria',
    name: 'Alexandria',
    uf: 'RN'
  },
  {
    value: 'Almino Afonso',
    name: 'Almino Afonso',
    uf: 'RN'
  },
  {
    value: 'Alto do Rodrigues',
    name: 'Alto do Rodrigues',
    uf: 'RN'
  },
  {
    value: 'Angicos',
    name: 'Angicos',
    uf: 'RN'
  },
  {
    value: 'Antonio Martins',
    name: 'Antônio Martins',
    uf: 'RN'
  },
  {
    value: 'Apodi',
    name: 'Apodi',
    uf: 'RN'
  },
  {
    value: 'Areia Branca',
    name: 'Areia Branca',
    uf: 'RN'
  },
  {
    value: 'Ares',
    name: 'Arês',
    uf: 'RN'
  },
  {
    value: 'Campo Grande',
    name: 'Campo Grande',
    uf: 'RN'
  },
  {
    value: 'Baia Formosa',
    name: 'Baía Formosa',
    uf: 'RN'
  },
  {
    value: 'Barcelona',
    name: 'Barcelona',
    uf: 'RN'
  },
  {
    value: 'Bento Fernandes',
    name: 'Bento Fernandes',
    uf: 'RN'
  },
  {
    value: 'Bom Jesus',
    name: 'Bom Jesus',
    uf: 'RN'
  },
  {
    value: 'Brejinho',
    name: 'Brejinho',
    uf: 'RN'
  },
  {
    value: 'Caicara do Rio do Vento',
    name: 'Caiçara do Rio do Vento',
    uf: 'RN'
  },
  {
    value: 'Caico',
    name: 'Caicó',
    uf: 'RN'
  },
  {
    value: 'Campo Redondo',
    name: 'Campo Redondo',
    uf: 'RN'
  },
  {
    value: 'Canguaretama',
    name: 'Canguaretama',
    uf: 'RN'
  },
  {
    value: 'Caraubas',
    name: 'Caraúbas',
    uf: 'RN'
  },
  {
    value: 'Carnauba dos Dantas',
    name: 'Carnaúba dos Dantas',
    uf: 'RN'
  },
  {
    value: 'Carnaubais',
    name: 'Carnaubais',
    uf: 'RN'
  },
  {
    value: 'Ceara-Mirim',
    name: 'Ceará-Mirim',
    uf: 'RN'
  },
  {
    value: 'Cerro Cora',
    name: 'Cerro Corá',
    uf: 'RN'
  },
  {
    value: 'Coronel Ezequiel',
    name: 'Coronel Ezequiel',
    uf: 'RN'
  },
  {
    value: 'Coronel Joao Pessoa',
    name: 'Coronel João Pessoa',
    uf: 'RN'
  },
  {
    value: 'Cruzeta',
    name: 'Cruzeta',
    uf: 'RN'
  },
  {
    value: 'Currais Novos',
    name: 'Currais Novos',
    uf: 'RN'
  },
  {
    value: 'Doutor Severiano',
    name: 'Doutor Severiano',
    uf: 'RN'
  },
  {
    value: 'Encanto',
    name: 'Encanto',
    uf: 'RN'
  },
  {
    value: 'Equador',
    name: 'Equador',
    uf: 'RN'
  },
  {
    value: 'Espirito Santo',
    name: 'Espírito Santo',
    uf: 'RN'
  },
  {
    value: 'Extremoz',
    name: 'Extremoz',
    uf: 'RN'
  },
  {
    value: 'Felipe Guerra',
    name: 'Felipe Guerra',
    uf: 'RN'
  },
  {
    value: 'Florania',
    name: 'Florânia',
    uf: 'RN'
  },
  {
    value: 'Francisco Dantas',
    name: 'Francisco Dantas',
    uf: 'RN'
  },
  {
    value: 'Galinhos',
    name: 'Galinhos',
    uf: 'RN'
  },
  {
    value: 'Goianinha',
    name: 'Goianinha',
    uf: 'RN'
  },
  {
    value: 'Governador Dix-Sept Rosado',
    name: 'Governador Dix-Sept Rosado',
    uf: 'RN'
  },
  {
    value: 'Grossos',
    name: 'Grossos',
    uf: 'RN'
  },
  {
    value: 'Guamare',
    name: 'Guamaré',
    uf: 'RN'
  },
  {
    value: 'Ielmo Marinho',
    name: 'Ielmo Marinho',
    uf: 'RN'
  },
  {
    value: 'Ipanguacu',
    name: 'Ipanguaçu',
    uf: 'RN'
  },
  {
    value: 'Ipueira',
    name: 'Ipueira',
    uf: 'RN'
  },
  {
    value: 'Itau',
    name: 'Itaú',
    uf: 'RN'
  },
  {
    value: 'Jacana',
    name: 'Jaçanã',
    uf: 'RN'
  },
  {
    value: 'Jandaira',
    name: 'Jandaíra',
    uf: 'RN'
  },
  {
    value: 'Janduis',
    name: 'Janduís',
    uf: 'RN'
  },
  {
    value: 'Januario Cicco',
    name: 'Januário Cicco',
    uf: 'RN'
  },
  {
    value: 'Japi',
    name: 'Japi',
    uf: 'RN'
  },
  {
    value: 'Jardim de Angicos',
    name: 'Jardim de Angicos',
    uf: 'RN'
  },
  {
    value: 'Jardim de Piranhas',
    name: 'Jardim de Piranhas',
    uf: 'RN'
  },
  {
    value: 'Jardim do Serido',
    name: 'Jardim do Seridó',
    uf: 'RN'
  },
  {
    value: 'Joao Camara',
    name: 'João Câmara',
    uf: 'RN'
  },
  {
    value: 'Joao Dias',
    name: 'João Dias',
    uf: 'RN'
  },
  {
    value: 'Jose da Penha',
    name: 'José da Penha',
    uf: 'RN'
  },
  {
    value: 'Jucurutu',
    name: 'Jucurutu',
    uf: 'RN'
  },
  {
    value: 'Messias Targino',
    name: 'Messias Targino',
    uf: 'RN'
  },
  {
    value: "Lagoa d'Anta",
    name: "Lagoa d'Anta",
    uf: 'RN'
  },
  {
    value: 'Lagoa de Pedras',
    name: 'Lagoa de Pedras',
    uf: 'RN'
  },
  {
    value: 'Lagoa de Velhos',
    name: 'Lagoa de Velhos',
    uf: 'RN'
  },
  {
    value: 'Lagoa Nova',
    name: 'Lagoa Nova',
    uf: 'RN'
  },
  {
    value: 'Lagoa Salgada',
    name: 'Lagoa Salgada',
    uf: 'RN'
  },
  {
    value: 'Lajes',
    name: 'Lajes',
    uf: 'RN'
  },
  {
    value: 'Lajes Pintadas',
    name: 'Lajes Pintadas',
    uf: 'RN'
  },
  {
    value: 'Lucrecia',
    name: 'Lucrécia',
    uf: 'RN'
  },
  {
    value: 'Luis Gomes',
    name: 'Luís Gomes',
    uf: 'RN'
  },
  {
    value: 'Macaiba',
    name: 'Macaíba',
    uf: 'RN'
  },
  {
    value: 'Macau',
    name: 'Macau',
    uf: 'RN'
  },
  {
    value: 'Marcelino Vieira',
    name: 'Marcelino Vieira',
    uf: 'RN'
  },
  {
    value: 'Martins',
    name: 'Martins',
    uf: 'RN'
  },
  {
    value: 'Maxaranguape',
    name: 'Maxaranguape',
    uf: 'RN'
  },
  {
    value: 'Frutuoso Gomes',
    name: 'Frutuoso Gomes',
    uf: 'RN'
  },
  {
    value: 'Montanhas',
    name: 'Montanhas',
    uf: 'RN'
  },
  {
    value: 'Monte Alegre',
    name: 'Monte Alegre',
    uf: 'RN'
  },
  {
    value: 'Monte das Gameleiras',
    name: 'Monte das Gameleiras',
    uf: 'RN'
  },
  {
    value: 'Mossoro',
    name: 'Mossoró',
    uf: 'RN'
  },
  {
    value: 'Natal',
    name: 'Natal',
    uf: 'RN'
  },
  {
    value: 'Nisia Floresta',
    name: 'Nísia Floresta',
    uf: 'RN'
  },
  {
    value: 'Nova Cruz',
    name: 'Nova Cruz',
    uf: 'RN'
  },
  {
    value: "Olho d'Agua do Borges",
    name: "Olho d'Água do Borges",
    uf: 'RN'
  },
  {
    value: 'Ouro Branco',
    name: 'Ouro Branco',
    uf: 'RN'
  },
  {
    value: 'Parana',
    name: 'Paraná',
    uf: 'RN'
  },
  {
    value: 'Parau',
    name: 'Paraú',
    uf: 'RN'
  },
  {
    value: 'Parazinho',
    name: 'Parazinho',
    uf: 'RN'
  },
  {
    value: 'Parelhas',
    name: 'Parelhas',
    uf: 'RN'
  },
  {
    value: 'Parnamirim',
    name: 'Parnamirim',
    uf: 'RN'
  },
  {
    value: 'Passa e Fica',
    name: 'Passa e Fica',
    uf: 'RN'
  },
  {
    value: 'Passagem',
    name: 'Passagem',
    uf: 'RN'
  },
  {
    value: 'Patu',
    name: 'Patu',
    uf: 'RN'
  },
  {
    value: 'Pau dos Ferros',
    name: 'Pau dos Ferros',
    uf: 'RN'
  },
  {
    value: 'Pedra Grande',
    name: 'Pedra Grande',
    uf: 'RN'
  },
  {
    value: 'Pedra Preta',
    name: 'Pedra Preta',
    uf: 'RN'
  },
  {
    value: 'Pedro Avelino',
    name: 'Pedro Avelino',
    uf: 'RN'
  },
  {
    value: 'Pedro Velho',
    name: 'Pedro Velho',
    uf: 'RN'
  },
  {
    value: 'Pendencias',
    name: 'Pendências',
    uf: 'RN'
  },
  {
    value: 'Piloes',
    name: 'Pilões',
    uf: 'RN'
  },
  {
    value: 'Poco Branco',
    name: 'Poço Branco',
    uf: 'RN'
  },
  {
    value: 'Portalegre',
    name: 'Portalegre',
    uf: 'RN'
  },
  {
    value: 'Serra Caiada',
    name: 'Serra Caiada',
    uf: 'RN'
  },
  {
    value: 'Pureza',
    name: 'Pureza',
    uf: 'RN'
  },
  {
    value: 'Rafael Fernandes',
    name: 'Rafael Fernandes',
    uf: 'RN'
  },
  {
    value: 'Riacho da Cruz',
    name: 'Riacho da Cruz',
    uf: 'RN'
  },
  {
    value: 'Riacho de Santana',
    name: 'Riacho de Santana',
    uf: 'RN'
  },
  {
    value: 'Riachuelo',
    name: 'Riachuelo',
    uf: 'RN'
  },
  {
    value: 'Rodolfo Fernandes',
    name: 'Rodolfo Fernandes',
    uf: 'RN'
  },
  {
    value: 'Ruy Barbosa',
    name: 'Ruy Barbosa',
    uf: 'RN'
  },
  {
    value: 'Sao Francisco do Oeste',
    name: 'São Francisco do Oeste',
    uf: 'RN'
  },
  {
    value: 'Santa Cruz',
    name: 'Santa Cruz',
    uf: 'RN'
  },
  {
    value: 'Santana do Serido',
    name: 'Santana do Seridó',
    uf: 'RN'
  },
  {
    value: 'Santana do Matos',
    name: 'Santana do Matos',
    uf: 'RN'
  },
  {
    value: 'Santo Antonio',
    name: 'Santo Antônio',
    uf: 'RN'
  },
  {
    value: 'Sao Bento do Norte',
    name: 'São Bento do Norte',
    uf: 'RN'
  },
  {
    value: 'Sao Bento do Trairi',
    name: 'São Bento do Trairí',
    uf: 'RN'
  },
  {
    value: 'Sao Fernando',
    name: 'São Fernando',
    uf: 'RN'
  },
  {
    value: 'Sao Goncalo do Amarante',
    name: 'São Gonçalo do Amarante',
    uf: 'RN'
  },
  {
    value: 'Sao Joao do Sabugi',
    name: 'São João do Sabugi',
    uf: 'RN'
  },
  {
    value: 'Sao Jose de Mipibu',
    name: 'São José de Mipibu',
    uf: 'RN'
  },
  {
    value: 'Sao Jose do Campestre',
    name: 'São José do Campestre',
    uf: 'RN'
  },
  {
    value: 'Sao Jose do Serido',
    name: 'São José do Seridó',
    uf: 'RN'
  },
  {
    value: 'Sao Miguel',
    name: 'São Miguel',
    uf: 'RN'
  },
  {
    value: 'Sao Paulo do Potengi',
    name: 'São Paulo do Potengi',
    uf: 'RN'
  },
  {
    value: 'Sao Pedro',
    name: 'São Pedro',
    uf: 'RN'
  },
  {
    value: 'Sao Rafael',
    name: 'São Rafael',
    uf: 'RN'
  },
  {
    value: 'Sao Tome',
    name: 'São Tomé',
    uf: 'RN'
  },
  {
    value: 'Sao Vicente',
    name: 'São Vicente',
    uf: 'RN'
  },
  {
    value: 'Senador Eloi de Souza',
    name: 'Senador Elói de Souza',
    uf: 'RN'
  },
  {
    value: 'Senador Georgino Avelino',
    name: 'Senador Georgino Avelino',
    uf: 'RN'
  },
  {
    value: 'Serra de Sao Bento',
    name: 'Serra de São Bento',
    uf: 'RN'
  },
  {
    value: 'Serra Negra do Norte',
    name: 'Serra Negra do Norte',
    uf: 'RN'
  },
  {
    value: 'Serrinha',
    name: 'Serrinha',
    uf: 'RN'
  },
  {
    value: 'Severiano Melo',
    name: 'Severiano Melo',
    uf: 'RN'
  },
  {
    value: 'Sitio Novo',
    name: 'Sítio Novo',
    uf: 'RN'
  },
  {
    value: 'Taboleiro Grande',
    name: 'Taboleiro Grande',
    uf: 'RN'
  },
  {
    value: 'Taipu',
    name: 'Taipu',
    uf: 'RN'
  },
  {
    value: 'Tangara',
    name: 'Tangará',
    uf: 'RN'
  },
  {
    value: 'Tenente Ananias',
    name: 'Tenente Ananias',
    uf: 'RN'
  },
  {
    value: 'Tibau do Sul',
    name: 'Tibau do Sul',
    uf: 'RN'
  },
  {
    value: 'Timbauba dos Batistas',
    name: 'Timbaúba dos Batistas',
    uf: 'RN'
  },
  {
    value: 'Touros',
    name: 'Touros',
    uf: 'RN'
  },
  {
    value: 'Umarizal',
    name: 'Umarizal',
    uf: 'RN'
  },
  {
    value: 'Upanema',
    name: 'Upanema',
    uf: 'RN'
  },
  {
    value: 'Varzea',
    name: 'Várzea',
    uf: 'RN'
  },
  {
    value: 'Rafael Godeiro',
    name: 'Rafael Godeiro',
    uf: 'RN'
  },
  {
    value: 'Vera Cruz',
    name: 'Vera Cruz',
    uf: 'RN'
  },
  {
    value: 'Vicosa',
    name: 'Viçosa',
    uf: 'RN'
  },
  {
    value: 'Vila Flor',
    name: 'Vila Flor',
    uf: 'RN'
  },
  {
    value: 'Agua Branca',
    name: 'Água Branca',
    uf: 'PB'
  },
  {
    value: 'Aguiar',
    name: 'Aguiar',
    uf: 'PB'
  },
  {
    value: 'Alagoa Grande',
    name: 'Alagoa Grande',
    uf: 'PB'
  },
  {
    value: 'Alagoa Nova',
    name: 'Alagoa Nova',
    uf: 'PB'
  },
  {
    value: 'Alagoinha',
    name: 'Alagoinha',
    uf: 'PB'
  },
  {
    value: 'Alhandra',
    name: 'Alhandra',
    uf: 'PB'
  },
  {
    value: 'Sao Joao do Rio do Peixe',
    name: 'São João do Rio do Peixe',
    uf: 'PB'
  },
  {
    value: 'Aracagi',
    name: 'Araçagi',
    uf: 'PB'
  },
  {
    value: 'Arara',
    name: 'Arara',
    uf: 'PB'
  },
  {
    value: 'Araruna',
    name: 'Araruna',
    uf: 'PB'
  },
  {
    value: 'Areia',
    name: 'Areia',
    uf: 'PB'
  },
  {
    value: 'Areial',
    name: 'Areial',
    uf: 'PB'
  },
  {
    value: 'Aroeiras',
    name: 'Aroeiras',
    uf: 'PB'
  },
  {
    value: 'Serra do Mel',
    name: 'Serra do Mel',
    uf: 'RN'
  },
  {
    value: 'Baia da Traicao',
    name: 'Baía da Traição',
    uf: 'PB'
  },
  {
    value: 'Bananeiras',
    name: 'Bananeiras',
    uf: 'PB'
  },
  {
    value: 'Barra de Santa Rosa',
    name: 'Barra de Santa Rosa',
    uf: 'PB'
  },
  {
    value: 'Barra de Sao Miguel',
    name: 'Barra de São Miguel',
    uf: 'PB'
  },
  {
    value: 'Bayeux',
    name: 'Bayeux',
    uf: 'PB'
  },
  {
    value: 'Belem',
    name: 'Belém',
    uf: 'PB'
  },
  {
    value: 'Belem do Brejo do Cruz',
    name: 'Belém do Brejo do Cruz',
    uf: 'PB'
  },
  {
    value: 'Boa Ventura',
    name: 'Boa Ventura',
    uf: 'PB'
  },
  {
    value: 'Bom Jesus',
    name: 'Bom Jesus',
    uf: 'PB'
  },
  {
    value: 'Bom Sucesso',
    name: 'Bom Sucesso',
    uf: 'PB'
  },
  {
    value: 'Bonito de Santa Fe',
    name: 'Bonito de Santa Fé',
    uf: 'PB'
  },
  {
    value: 'Boqueirao',
    name: 'Boqueirão',
    uf: 'PB'
  },
  {
    value: 'Igaracy',
    name: 'Igaracy',
    uf: 'PB'
  },
  {
    value: 'Borborema',
    name: 'Borborema',
    uf: 'PB'
  },
  {
    value: 'Brejo do Cruz',
    name: 'Brejo do Cruz',
    uf: 'PB'
  },
  {
    value: 'Brejo dos Santos',
    name: 'Brejo dos Santos',
    uf: 'PB'
  },
  {
    value: 'Caapora',
    name: 'Caaporã',
    uf: 'PB'
  },
  {
    value: 'Cabaceiras',
    name: 'Cabaceiras',
    uf: 'PB'
  },
  {
    value: 'Cabedelo',
    name: 'Cabedelo',
    uf: 'PB'
  },
  {
    value: 'Cachoeira dos Indios',
    name: 'Cachoeira dos Índios',
    uf: 'PB'
  },
  {
    value: 'Cacimba de Areia',
    name: 'Cacimba de Areia',
    uf: 'PB'
  },
  {
    value: 'Cacimba de Dentro',
    name: 'Cacimba de Dentro',
    uf: 'PB'
  },
  {
    value: 'Caicara',
    name: 'Caiçara',
    uf: 'PB'
  },
  {
    value: 'Cajazeiras',
    name: 'Cajazeiras',
    uf: 'PB'
  },
  {
    value: 'Caldas Brandao',
    name: 'Caldas Brandão',
    uf: 'PB'
  },
  {
    value: 'Camalau',
    name: 'Camalaú',
    uf: 'PB'
  },
  {
    value: 'Campina Grande',
    name: 'Campina Grande',
    uf: 'PB'
  },
  {
    value: 'Carrapateira',
    name: 'Carrapateira',
    uf: 'PB'
  },
  {
    value: 'Catingueira',
    name: 'Catingueira',
    uf: 'PB'
  },
  {
    value: 'Catole do Rocha',
    name: 'Catolé do Rocha',
    uf: 'PB'
  },
  {
    value: 'Conceicao',
    name: 'Conceição',
    uf: 'PB'
  },
  {
    value: 'Condado',
    name: 'Condado',
    uf: 'PB'
  },
  {
    value: 'Conde',
    name: 'Conde',
    uf: 'PB'
  },
  {
    value: 'Congo',
    name: 'Congo',
    uf: 'PB'
  },
  {
    value: 'Coremas',
    name: 'Coremas',
    uf: 'PB'
  },
  {
    value: 'Cruz do Espirito Santo',
    name: 'Cruz do Espírito Santo',
    uf: 'PB'
  },
  {
    value: 'Cubati',
    name: 'Cubati',
    uf: 'PB'
  },
  {
    value: 'Cuite',
    name: 'Cuité',
    uf: 'PB'
  },
  {
    value: 'Cuitegi',
    name: 'Cuitegi',
    uf: 'PB'
  },
  {
    value: 'Curral Velho',
    name: 'Curral Velho',
    uf: 'PB'
  },
  {
    value: 'Desterro',
    name: 'Desterro',
    uf: 'PB'
  },
  {
    value: 'Vista Serrana',
    name: 'Vista Serrana',
    uf: 'PB'
  },
  {
    value: 'Diamante',
    name: 'Diamante',
    uf: 'PB'
  },
  {
    value: 'Dona Ines',
    name: 'Dona Inês',
    uf: 'PB'
  },
  {
    value: 'Duas Estradas',
    name: 'Duas Estradas',
    uf: 'PB'
  },
  {
    value: 'Emas',
    name: 'Emas',
    uf: 'PB'
  },
  {
    value: 'Esperanca',
    name: 'Esperança',
    uf: 'PB'
  },
  {
    value: 'Fagundes',
    name: 'Fagundes',
    uf: 'PB'
  },
  {
    value: 'Frei Martinho',
    name: 'Frei Martinho',
    uf: 'PB'
  },
  {
    value: 'Guarabira',
    name: 'Guarabira',
    uf: 'PB'
  },
  {
    value: 'Gurinhem',
    name: 'Gurinhém',
    uf: 'PB'
  },
  {
    value: 'Gurjao',
    name: 'Gurjão',
    uf: 'PB'
  },
  {
    value: 'Ibiara',
    name: 'Ibiara',
    uf: 'PB'
  },
  {
    value: 'Imaculada',
    name: 'Imaculada',
    uf: 'PB'
  },
  {
    value: 'Inga',
    name: 'Ingá',
    uf: 'PB'
  },
  {
    value: 'Itabaiana',
    name: 'Itabaiana',
    uf: 'PB'
  },
  {
    value: 'Itaporanga',
    name: 'Itaporanga',
    uf: 'PB'
  },
  {
    value: 'Itapororoca',
    name: 'Itapororoca',
    uf: 'PB'
  },
  {
    value: 'Itatuba',
    name: 'Itatuba',
    uf: 'PB'
  },
  {
    value: 'Jacarau',
    name: 'Jacaraú',
    uf: 'PB'
  },
  {
    value: 'Jerico',
    name: 'Jericó',
    uf: 'PB'
  },
  {
    value: 'Joao Pessoa',
    name: 'João Pessoa',
    uf: 'PB'
  },
  {
    value: 'Juarez Tavora',
    name: 'Juarez Távora',
    uf: 'PB'
  },
  {
    value: 'Juazeirinho',
    name: 'Juazeirinho',
    uf: 'PB'
  },
  {
    value: 'Junco do Serido',
    name: 'Junco do Seridó',
    uf: 'PB'
  },
  {
    value: 'Juripiranga',
    name: 'Juripiranga',
    uf: 'PB'
  },
  {
    value: 'Juru',
    name: 'Juru',
    uf: 'PB'
  },
  {
    value: 'Lagoa',
    name: 'Lagoa',
    uf: 'PB'
  },
  {
    value: 'Lagoa de Dentro',
    name: 'Lagoa de Dentro',
    uf: 'PB'
  },
  {
    value: 'Lagoa Seca',
    name: 'Lagoa Seca',
    uf: 'PB'
  },
  {
    value: 'Lastro',
    name: 'Lastro',
    uf: 'PB'
  },
  {
    value: 'Livramento',
    name: 'Livramento',
    uf: 'PB'
  },
  {
    value: 'Lucena',
    name: 'Lucena',
    uf: 'PB'
  },
  {
    value: "Mae d'Agua",
    name: "Mãe d'Água",
    uf: 'PB'
  },
  {
    value: 'Malta',
    name: 'Malta',
    uf: 'PB'
  },
  {
    value: 'Mamanguape',
    name: 'Mamanguape',
    uf: 'PB'
  },
  {
    value: 'Manaira',
    name: 'Manaíra',
    uf: 'PB'
  },
  {
    value: 'Mari',
    name: 'Mari',
    uf: 'PB'
  },
  {
    value: 'Massaranduba',
    name: 'Massaranduba',
    uf: 'PB'
  },
  {
    value: 'Mataraca',
    name: 'Mataraca',
    uf: 'PB'
  },
  {
    value: 'Mogeiro',
    name: 'Mogeiro',
    uf: 'PB'
  },
  {
    value: 'Montadas',
    name: 'Montadas',
    uf: 'PB'
  },
  {
    value: 'Monte Horebe',
    name: 'Monte Horebe',
    uf: 'PB'
  },
  {
    value: 'Monteiro',
    name: 'Monteiro',
    uf: 'PB'
  },
  {
    value: 'Mulungu',
    name: 'Mulungu',
    uf: 'PB'
  },
  {
    value: 'Natuba',
    name: 'Natuba',
    uf: 'PB'
  },
  {
    value: 'Nazarezinho',
    name: 'Nazarezinho',
    uf: 'PB'
  },
  {
    value: 'Nova Floresta',
    name: 'Nova Floresta',
    uf: 'PB'
  },
  {
    value: 'Nova Olinda',
    name: 'Nova Olinda',
    uf: 'PB'
  },
  {
    value: 'Nova Palmeira',
    name: 'Nova Palmeira',
    uf: 'PB'
  },
  {
    value: "Olho d'Agua",
    name: "Olho d'Água",
    uf: 'PB'
  },
  {
    value: 'Olivedos',
    name: 'Olivedos',
    uf: 'PB'
  },
  {
    value: 'Ouro Velho',
    name: 'Ouro Velho',
    uf: 'PB'
  },
  {
    value: 'Passagem',
    name: 'Passagem',
    uf: 'PB'
  },
  {
    value: 'Patos',
    name: 'Patos',
    uf: 'PB'
  },
  {
    value: 'Paulista',
    name: 'Paulista',
    uf: 'PB'
  },
  {
    value: 'Pedra Branca',
    name: 'Pedra Branca',
    uf: 'PB'
  },
  {
    value: 'Pedra Lavrada',
    name: 'Pedra Lavrada',
    uf: 'PB'
  },
  {
    value: 'Pedras de Fogo',
    name: 'Pedras de Fogo',
    uf: 'PB'
  },
  {
    value: 'Pianco',
    name: 'Piancó',
    uf: 'PB'
  },
  {
    value: 'Picui',
    name: 'Picuí',
    uf: 'PB'
  },
  {
    value: 'Pilar',
    name: 'Pilar',
    uf: 'PB'
  },
  {
    value: 'Piloes',
    name: 'Pilões',
    uf: 'PB'
  },
  {
    value: 'Piloezinhos',
    name: 'Pilõezinhos',
    uf: 'PB'
  },
  {
    value: 'Pirpirituba',
    name: 'Pirpirituba',
    uf: 'PB'
  },
  {
    value: 'Pitimbu',
    name: 'Pitimbu',
    uf: 'PB'
  },
  {
    value: 'Pocinhos',
    name: 'Pocinhos',
    uf: 'PB'
  },
  {
    value: 'Pombal',
    name: 'Pombal',
    uf: 'PB'
  },
  {
    value: 'Prata',
    name: 'Prata',
    uf: 'PB'
  },
  {
    value: 'Princesa Isabel',
    name: 'Princesa Isabel',
    uf: 'PB'
  },
  {
    value: 'Puxinana',
    name: 'Puxinanã',
    uf: 'PB'
  },
  {
    value: 'Queimadas',
    name: 'Queimadas',
    uf: 'PB'
  },
  {
    value: 'Quixaba',
    name: 'Quixaba',
    uf: 'PB'
  },
  {
    value: 'Remigio',
    name: 'Remígio',
    uf: 'PB'
  },
  {
    value: 'Riacho dos Cavalos',
    name: 'Riacho dos Cavalos',
    uf: 'PB'
  },
  {
    value: 'Rio Tinto',
    name: 'Rio Tinto',
    uf: 'PB'
  },
  {
    value: 'Salgadinho',
    name: 'Salgadinho',
    uf: 'PB'
  },
  {
    value: 'Salgado de Sao Felix',
    name: 'Salgado de São Félix',
    uf: 'PB'
  },
  {
    value: 'Santa Cruz',
    name: 'Santa Cruz',
    uf: 'PB'
  },
  {
    value: 'Santa Helena',
    name: 'Santa Helena',
    uf: 'PB'
  },
  {
    value: 'Santa Luzia',
    name: 'Santa Luzia',
    uf: 'PB'
  },
  {
    value: 'Santana de Mangueira',
    name: 'Santana de Mangueira',
    uf: 'PB'
  },
  {
    value: 'Santana dos Garrotes',
    name: 'Santana dos Garrotes',
    uf: 'PB'
  },
  {
    value: 'Santa Rita',
    name: 'Santa Rita',
    uf: 'PB'
  },
  {
    value: 'Santa Teresinha',
    name: 'Santa Teresinha',
    uf: 'PB'
  },
  {
    value: 'Sao Bento',
    name: 'São Bento',
    uf: 'PB'
  },
  {
    value: 'Sao Joao do Cariri',
    name: 'São João do Cariri',
    uf: 'PB'
  },
  {
    value: 'Sao Joao do Tigre',
    name: 'São João do Tigre',
    uf: 'PB'
  },
  {
    value: 'Sao Jose da Lagoa Tapada',
    name: 'São José da Lagoa Tapada',
    uf: 'PB'
  },
  {
    value: 'Sao Jose de Caiana',
    name: 'São José de Caiana',
    uf: 'PB'
  },
  {
    value: 'Sao Jose de Espinharas',
    name: 'São José de Espinharas',
    uf: 'PB'
  },
  {
    value: 'Sao Jose de Piranhas',
    name: 'São José de Piranhas',
    uf: 'PB'
  },
  {
    value: 'Sao Jose do Bonfim',
    name: 'São José do Bonfim',
    uf: 'PB'
  },
  {
    value: 'Sao Jose do Sabugi',
    name: 'São José do Sabugi',
    uf: 'PB'
  },
  {
    value: 'Sao Jose dos Cordeiros',
    name: 'São José dos Cordeiros',
    uf: 'PB'
  },
  {
    value: 'Sao Mamede',
    name: 'São Mamede',
    uf: 'PB'
  },
  {
    value: 'Sao Miguel de Taipu',
    name: 'São Miguel de Taipu',
    uf: 'PB'
  },
  {
    value: 'Sao Sebastiao de Lagoa de Roca',
    name: 'São Sebastião de Lagoa de Roça',
    uf: 'PB'
  },
  {
    value: 'Sao Sebastiao do Umbuzeiro',
    name: 'São Sebastião do Umbuzeiro',
    uf: 'PB'
  },
  {
    value: 'Sape',
    name: 'Sapé',
    uf: 'PB'
  },
  {
    value: 'Sao Vicente do Serido',
    name: 'São Vicente do Seridó',
    uf: 'PB'
  },
  {
    value: 'Serra Branca',
    name: 'Serra Branca',
    uf: 'PB'
  },
  {
    value: 'Serra da Raiz',
    name: 'Serra da Raiz',
    uf: 'PB'
  },
  {
    value: 'Serra Grande',
    name: 'Serra Grande',
    uf: 'PB'
  },
  {
    value: 'Serra Redonda',
    name: 'Serra Redonda',
    uf: 'PB'
  },
  {
    value: 'Serraria',
    name: 'Serraria',
    uf: 'PB'
  },
  {
    value: 'Solanea',
    name: 'Solânea',
    uf: 'PB'
  },
  {
    value: 'Soledade',
    name: 'Soledade',
    uf: 'PB'
  },
  {
    value: 'Sousa',
    name: 'Sousa',
    uf: 'PB'
  },
  {
    value: 'Sume',
    name: 'Sumé',
    uf: 'PB'
  },
  {
    value: 'Tacima',
    name: 'Tacima',
    uf: 'PB'
  },
  {
    value: 'Taperoa',
    name: 'Taperoá',
    uf: 'PB'
  },
  {
    value: 'Tavares',
    name: 'Tavares',
    uf: 'PB'
  },
  {
    value: 'Teixeira',
    name: 'Teixeira',
    uf: 'PB'
  },
  {
    value: 'Triunfo',
    name: 'Triunfo',
    uf: 'PB'
  },
  {
    value: 'Uirauna',
    name: 'Uiraúna',
    uf: 'PB'
  },
  {
    value: 'Umbuzeiro',
    name: 'Umbuzeiro',
    uf: 'PB'
  },
  {
    value: 'Varzea',
    name: 'Várzea',
    uf: 'PB'
  },
  {
    value: 'Baixa Grande do Ribeiro',
    name: 'Baixa Grande do Ribeiro',
    uf: 'PI'
  },
  {
    value: 'Canavieira',
    name: 'Canavieira',
    uf: 'PI'
  },
  {
    value: 'Colonia do Gurgueia',
    name: 'Colônia do Gurguéia',
    uf: 'PI'
  },
  {
    value: 'Bonfim do Piaui',
    name: 'Bonfim do Piauí',
    uf: 'PI'
  },
  {
    value: 'Colonia do Piaui',
    name: 'Colônia do Piauí',
    uf: 'PI'
  },
  {
    value: 'Coronel Jose Dias',
    name: 'Coronel José Dias',
    uf: 'PI'
  },
  {
    value: 'Fartura do Piaui',
    name: 'Fartura do Piauí',
    uf: 'PI'
  },
  {
    value: 'Lagoa do Barro do Piaui',
    name: 'Lagoa do Barro do Piauí',
    uf: 'PI'
  },
  {
    value: 'Santa Rosa do Piaui',
    name: 'Santa Rosa do Piauí',
    uf: 'PI'
  },
  {
    value: 'Sao Braz do Piaui',
    name: 'São Braz do Piauí',
    uf: 'PI'
  },
  {
    value: 'Sao Lourenco do Piaui',
    name: 'São Lourenço do Piauí',
    uf: 'PI'
  },
  {
    value: 'Varzea Branca',
    name: 'Várzea Branca',
    uf: 'PI'
  },
  {
    value: 'Alegrete do Piaui',
    name: 'Alegrete do Piauí',
    uf: 'PI'
  },
  {
    value: 'Caldeirao Grande do Piaui',
    name: 'Caldeirão Grande do Piauí',
    uf: 'PI'
  },
  {
    value: 'Jacobina do Piaui',
    name: 'Jacobina do Piauí',
    uf: 'PI'
  },
  {
    value: 'Marcolandia',
    name: 'Marcolândia',
    uf: 'PI'
  },
  {
    value: 'Patos do Piaui',
    name: 'Patos do Piauí',
    uf: 'PI'
  },
  {
    value: 'Queimada Nova',
    name: 'Queimada Nova',
    uf: 'PI'
  },
  {
    value: 'Santana do Piaui',
    name: 'Santana do Piauí',
    uf: 'PI'
  },
  {
    value: 'Brasileira',
    name: 'Brasileira',
    uf: 'PI'
  },
  {
    value: 'Sao Jose do Divino',
    name: 'São José do Divino',
    uf: 'PI'
  },
  {
    value: 'Bom Principio do Piaui',
    name: 'Bom Princípio do Piauí',
    uf: 'PI'
  },
  {
    value: 'Lagoa do Carro',
    name: 'Lagoa do Carro',
    uf: 'PE'
  },
  {
    value: 'Vertente do Lerio',
    name: 'Vertente do Lério',
    uf: 'PE'
  },
  {
    value: 'Xexeu',
    name: 'Xexéu',
    uf: 'PE'
  },
  {
    value: 'Jucati',
    name: 'Jucati',
    uf: 'PE'
  },
  {
    value: 'Santa Cruz',
    name: 'Santa Cruz',
    uf: 'PE'
  },
  {
    value: 'Dormentes',
    name: 'Dormentes',
    uf: 'PE'
  },
  {
    value: 'Afogados da Ingazeira',
    name: 'Afogados da Ingazeira',
    uf: 'PE'
  },
  {
    value: 'Afranio',
    name: 'Afrânio',
    uf: 'PE'
  },
  {
    value: 'Agrestina',
    name: 'Agrestina',
    uf: 'PE'
  },
  {
    value: 'Agua Preta',
    name: 'Água Preta',
    uf: 'PE'
  },
  {
    value: 'Aguas Belas',
    name: 'Águas Belas',
    uf: 'PE'
  },
  {
    value: 'Alagoinha',
    name: 'Alagoinha',
    uf: 'PE'
  },
  {
    value: 'Alianca',
    name: 'Aliança',
    uf: 'PE'
  },
  {
    value: 'Altinho',
    name: 'Altinho',
    uf: 'PE'
  },
  {
    value: 'Amaraji',
    name: 'Amaraji',
    uf: 'PE'
  },
  {
    value: 'Angelim',
    name: 'Angelim',
    uf: 'PE'
  },
  {
    value: 'Araripina',
    name: 'Araripina',
    uf: 'PE'
  },
  {
    value: 'Arcoverde',
    name: 'Arcoverde',
    uf: 'PE'
  },
  {
    value: 'Barra de Guabiraba',
    name: 'Barra de Guabiraba',
    uf: 'PE'
  },
  {
    value: 'Barreiros',
    name: 'Barreiros',
    uf: 'PE'
  },
  {
    value: 'Belem de Maria',
    name: 'Belém de Maria',
    uf: 'PE'
  },
  {
    value: 'Belem do Sao Francisco',
    name: 'Belém do São Francisco',
    uf: 'PE'
  },
  {
    value: 'Belo Jardim',
    name: 'Belo Jardim',
    uf: 'PE'
  },
  {
    value: 'Betania',
    name: 'Betânia',
    uf: 'PE'
  },
  {
    value: 'Bezerros',
    name: 'Bezerros',
    uf: 'PE'
  },
  {
    value: 'Bodoco',
    name: 'Bodocó',
    uf: 'PE'
  },
  {
    value: 'Bom Conselho',
    name: 'Bom Conselho',
    uf: 'PE'
  },
  {
    value: 'Bom Jardim',
    name: 'Bom Jardim',
    uf: 'PE'
  },
  {
    value: 'Bonito',
    name: 'Bonito',
    uf: 'PE'
  },
  {
    value: 'Brejao',
    name: 'Brejão',
    uf: 'PE'
  },
  {
    value: 'Brejinho',
    name: 'Brejinho',
    uf: 'PE'
  },
  {
    value: 'Brejo da Madre de Deus',
    name: 'Brejo da Madre de Deus',
    uf: 'PE'
  },
  {
    value: 'Buenos Aires',
    name: 'Buenos Aires',
    uf: 'PE'
  },
  {
    value: 'Buique',
    name: 'Buíque',
    uf: 'PE'
  },
  {
    value: 'Cabo de Santo Agostinho',
    name: 'Cabo de Santo Agostinho',
    uf: 'PE'
  },
  {
    value: 'Cabrobo',
    name: 'Cabrobó',
    uf: 'PE'
  },
  {
    value: 'Cachoeirinha',
    name: 'Cachoeirinha',
    uf: 'PE'
  },
  {
    value: 'Caetes',
    name: 'Caetés',
    uf: 'PE'
  },
  {
    value: 'Calcado',
    name: 'Calçado',
    uf: 'PE'
  },
  {
    value: 'Calumbi',
    name: 'Calumbi',
    uf: 'PE'
  },
  {
    value: 'Camocim de Sao Felix',
    name: 'Camocim de São Félix',
    uf: 'PE'
  },
  {
    value: 'Camutanga',
    name: 'Camutanga',
    uf: 'PE'
  },
  {
    value: 'Canhotinho',
    name: 'Canhotinho',
    uf: 'PE'
  },
  {
    value: 'Capoeiras',
    name: 'Capoeiras',
    uf: 'PE'
  },
  {
    value: 'Carnaiba',
    name: 'Carnaíba',
    uf: 'PE'
  },
  {
    value: 'Carpina',
    name: 'Carpina',
    uf: 'PE'
  },
  {
    value: 'Caruaru',
    name: 'Caruaru',
    uf: 'PE'
  },
  {
    value: 'Catende',
    name: 'Catende',
    uf: 'PE'
  },
  {
    value: 'Cedro',
    name: 'Cedro',
    uf: 'PE'
  },
  {
    value: 'Cha de Alegria',
    name: 'Chã de Alegria',
    uf: 'PE'
  },
  {
    value: 'Cha Grande',
    name: 'Chã Grande',
    uf: 'PE'
  },
  {
    value: 'Condado',
    name: 'Condado',
    uf: 'PE'
  },
  {
    value: 'Correntes',
    name: 'Correntes',
    uf: 'PE'
  },
  {
    value: 'Cortes',
    name: 'Cortês',
    uf: 'PE'
  },
  {
    value: 'Cumaru',
    name: 'Cumaru',
    uf: 'PE'
  },
  {
    value: 'Cupira',
    name: 'Cupira',
    uf: 'PE'
  },
  {
    value: 'Custodia',
    name: 'Custódia',
    uf: 'PE'
  },
  {
    value: 'Escada',
    name: 'Escada',
    uf: 'PE'
  },
  {
    value: 'Exu',
    name: 'Exu',
    uf: 'PE'
  },
  {
    value: 'Feira Nova',
    name: 'Feira Nova',
    uf: 'PE'
  },
  {
    value: 'Ferreiros',
    name: 'Ferreiros',
    uf: 'PE'
  },
  {
    value: 'Flores',
    name: 'Flores',
    uf: 'PE'
  },
  {
    value: 'Floresta',
    name: 'Floresta',
    uf: 'PE'
  },
  {
    value: 'Frei Miguelinho',
    name: 'Frei Miguelinho',
    uf: 'PE'
  },
  {
    value: 'Gameleira',
    name: 'Gameleira',
    uf: 'PE'
  },
  {
    value: 'Garanhuns',
    name: 'Garanhuns',
    uf: 'PE'
  },
  {
    value: 'Gloria do Goita',
    name: 'Glória do Goitá',
    uf: 'PE'
  },
  {
    value: 'Goiana',
    name: 'Goiana',
    uf: 'PE'
  },
  {
    value: 'Granito',
    name: 'Granito',
    uf: 'PE'
  },
  {
    value: 'Gravata',
    name: 'Gravatá',
    uf: 'PE'
  },
  {
    value: 'Iati',
    name: 'Iati',
    uf: 'PE'
  },
  {
    value: 'Ibimirim',
    name: 'Ibimirim',
    uf: 'PE'
  },
  {
    value: 'Ibirajuba',
    name: 'Ibirajuba',
    uf: 'PE'
  },
  {
    value: 'Igarassu',
    name: 'Igarassu',
    uf: 'PE'
  },
  {
    value: 'Iguaracy',
    name: 'Iguaracy',
    uf: 'PE'
  },
  {
    value: 'Inaja',
    name: 'Inajá',
    uf: 'PE'
  },
  {
    value: 'Ingazeira',
    name: 'Ingazeira',
    uf: 'PE'
  },
  {
    value: 'Ipojuca',
    name: 'Ipojuca',
    uf: 'PE'
  },
  {
    value: 'Ipubi',
    name: 'Ipubi',
    uf: 'PE'
  },
  {
    value: 'Itacuruba',
    name: 'Itacuruba',
    uf: 'PE'
  },
  {
    value: 'Itaiba',
    name: 'Itaíba',
    uf: 'PE'
  },
  {
    value: 'Ilha de Itamaraca',
    name: 'Ilha de Itamaracá',
    uf: 'PE'
  },
  {
    value: 'Itapetim',
    name: 'Itapetim',
    uf: 'PE'
  },
  {
    value: 'Itaquitinga',
    name: 'Itaquitinga',
    uf: 'PE'
  },
  {
    value: 'Jaboatao dos Guararapes',
    name: 'Jaboatão dos Guararapes',
    uf: 'PE'
  },
  {
    value: 'Jatauba',
    name: 'Jataúba',
    uf: 'PE'
  },
  {
    value: 'Joao Alfredo',
    name: 'João Alfredo',
    uf: 'PE'
  },
  {
    value: 'Joaquim Nabuco',
    name: 'Joaquim Nabuco',
    uf: 'PE'
  },
  {
    value: 'Jupi',
    name: 'Jupi',
    uf: 'PE'
  },
  {
    value: 'Jurema',
    name: 'Jurema',
    uf: 'PE'
  },
  {
    value: 'Lagoa de Itaenga',
    name: 'Lagoa de Itaenga',
    uf: 'PE'
  },
  {
    value: 'Lagoa do Ouro',
    name: 'Lagoa do Ouro',
    uf: 'PE'
  },
  {
    value: 'Lagoa dos Gatos',
    name: 'Lagoa dos Gatos',
    uf: 'PE'
  },
  {
    value: 'Lajedo',
    name: 'Lajedo',
    uf: 'PE'
  },
  {
    value: 'Limoeiro',
    name: 'Limoeiro',
    uf: 'PE'
  },
  {
    value: 'Macaparana',
    name: 'Macaparana',
    uf: 'PE'
  },
  {
    value: 'Machados',
    name: 'Machados',
    uf: 'PE'
  },
  {
    value: 'Maraial',
    name: 'Maraial',
    uf: 'PE'
  },
  {
    value: 'Mirandiba',
    name: 'Mirandiba',
    uf: 'PE'
  },
  {
    value: 'Moreno',
    name: 'Moreno',
    uf: 'PE'
  },
  {
    value: 'Nazare da Mata',
    name: 'Nazaré da Mata',
    uf: 'PE'
  },
  {
    value: 'Olinda',
    name: 'Olinda',
    uf: 'PE'
  },
  {
    value: 'Orobo',
    name: 'Orobó',
    uf: 'PE'
  },
  {
    value: 'Oroco',
    name: 'Orocó',
    uf: 'PE'
  },
  {
    value: 'Ouricuri',
    name: 'Ouricuri',
    uf: 'PE'
  },
  {
    value: 'Palmares',
    name: 'Palmares',
    uf: 'PE'
  },
  {
    value: 'Palmeirina',
    name: 'Palmeirina',
    uf: 'PE'
  },
  {
    value: 'Panelas',
    name: 'Panelas',
    uf: 'PE'
  },
  {
    value: 'Paranatama',
    name: 'Paranatama',
    uf: 'PE'
  },
  {
    value: 'Parnamirim',
    name: 'Parnamirim',
    uf: 'PE'
  },
  {
    value: 'Passira',
    name: 'Passira',
    uf: 'PE'
  },
  {
    value: 'Paudalho',
    name: 'Paudalho',
    uf: 'PE'
  },
  {
    value: 'Paulista',
    name: 'Paulista',
    uf: 'PE'
  },
  {
    value: 'Pedra',
    name: 'Pedra',
    uf: 'PE'
  },
  {
    value: 'Pesqueira',
    name: 'Pesqueira',
    uf: 'PE'
  },
  {
    value: 'Petrolandia',
    name: 'Petrolândia',
    uf: 'PE'
  },
  {
    value: 'Petrolina',
    name: 'Petrolina',
    uf: 'PE'
  },
  {
    value: 'Pocao',
    name: 'Poção',
    uf: 'PE'
  },
  {
    value: 'Pombos',
    name: 'Pombos',
    uf: 'PE'
  },
  {
    value: 'Primavera',
    name: 'Primavera',
    uf: 'PE'
  },
  {
    value: 'Quipapa',
    name: 'Quipapá',
    uf: 'PE'
  },
  {
    value: 'Recife',
    name: 'Recife',
    uf: 'PE'
  },
  {
    value: 'Riacho das Almas',
    name: 'Riacho das Almas',
    uf: 'PE'
  },
  {
    value: 'Ribeirao',
    name: 'Ribeirão',
    uf: 'PE'
  },
  {
    value: 'Rio Formoso',
    name: 'Rio Formoso',
    uf: 'PE'
  },
  {
    value: 'Saire',
    name: 'Sairé',
    uf: 'PE'
  },
  {
    value: 'Salgadinho',
    name: 'Salgadinho',
    uf: 'PE'
  },
  {
    value: 'Salgueiro',
    name: 'Salgueiro',
    uf: 'PE'
  },
  {
    value: 'Saloa',
    name: 'Saloá',
    uf: 'PE'
  },
  {
    value: 'Sanharo',
    name: 'Sanharó',
    uf: 'PE'
  },
  {
    value: 'Santa Cruz do Capibaribe',
    name: 'Santa Cruz do Capibaribe',
    uf: 'PE'
  },
  {
    value: 'Santa Maria da Boa Vista',
    name: 'Santa Maria da Boa Vista',
    uf: 'PE'
  },
  {
    value: 'Santa Maria do Cambuca',
    name: 'Santa Maria do Cambucá',
    uf: 'PE'
  },
  {
    value: 'Santa Terezinha',
    name: 'Santa Terezinha',
    uf: 'PE'
  },
  {
    value: 'Sao Benedito do Sul',
    name: 'São Benedito do Sul',
    uf: 'PE'
  },
  {
    value: 'Sao Bento do Una',
    name: 'São Bento do Una',
    uf: 'PE'
  },
  {
    value: 'Sao Caitano',
    name: 'São Caitano',
    uf: 'PE'
  },
  {
    value: 'Sao Joao',
    name: 'São João',
    uf: 'PE'
  },
  {
    value: 'Sao Joaquim do Monte',
    name: 'São Joaquim do Monte',
    uf: 'PE'
  },
  {
    value: 'Sao Jose da Coroa Grande',
    name: 'São José da Coroa Grande',
    uf: 'PE'
  },
  {
    value: 'Sao Jose do Belmonte',
    name: 'São José do Belmonte',
    uf: 'PE'
  },
  {
    value: 'Sao Jose do Egito',
    name: 'São José do Egito',
    uf: 'PE'
  },
  {
    value: 'Sao Lourenco da Mata',
    name: 'São Lourenço da Mata',
    uf: 'PE'
  },
  {
    value: 'Sao Vicente Ferrer',
    name: 'São Vicente Férrer',
    uf: 'PE'
  },
  {
    value: 'Serra Talhada',
    name: 'Serra Talhada',
    uf: 'PE'
  },
  {
    value: 'Serrita',
    name: 'Serrita',
    uf: 'PE'
  },
  {
    value: 'Sertania',
    name: 'Sertânia',
    uf: 'PE'
  },
  {
    value: 'Sirinhaem',
    name: 'Sirinhaém',
    uf: 'PE'
  },
  {
    value: 'Moreilandia',
    name: 'Moreilândia',
    uf: 'PE'
  },
  {
    value: 'Solidao',
    name: 'Solidão',
    uf: 'PE'
  },
  {
    value: 'Surubim',
    name: 'Surubim',
    uf: 'PE'
  },
  {
    value: 'Tabira',
    name: 'Tabira',
    uf: 'PE'
  },
  {
    value: 'Tacaimbo',
    name: 'Tacaimbó',
    uf: 'PE'
  },
  {
    value: 'Tacaratu',
    name: 'Tacaratu',
    uf: 'PE'
  },
  {
    value: 'Itambe',
    name: 'Itambé',
    uf: 'PE'
  },
  {
    value: 'Taquaritinga do Norte',
    name: 'Taquaritinga do Norte',
    uf: 'PE'
  },
  {
    value: 'Terezinha',
    name: 'Terezinha',
    uf: 'PE'
  },
  {
    value: 'Terra Nova',
    name: 'Terra Nova',
    uf: 'PE'
  },
  {
    value: 'Timbauba',
    name: 'Timbaúba',
    uf: 'PE'
  },
  {
    value: 'Toritama',
    name: 'Toritama',
    uf: 'PE'
  },
  {
    value: 'Tracunhaem',
    name: 'Tracunhaém',
    uf: 'PE'
  },
  {
    value: 'Trindade',
    name: 'Trindade',
    uf: 'PE'
  },
  {
    value: 'Triunfo',
    name: 'Triunfo',
    uf: 'PE'
  },
  {
    value: 'Tupanatinga',
    name: 'Tupanatinga',
    uf: 'PE'
  },
  {
    value: 'Tuparetama',
    name: 'Tuparetama',
    uf: 'PE'
  },
  {
    value: 'Venturosa',
    name: 'Venturosa',
    uf: 'PE'
  },
  {
    value: 'Verdejante',
    name: 'Verdejante',
    uf: 'PE'
  },
  {
    value: 'Vertentes',
    name: 'Vertentes',
    uf: 'PE'
  },
  {
    value: 'Vicencia',
    name: 'Vicência',
    uf: 'PE'
  },
  {
    value: 'Vitoria de Santo Antao',
    name: 'Vitória de Santo Antão',
    uf: 'PE'
  },
  {
    value: 'Camaragibe',
    name: 'Camaragibe',
    uf: 'PE'
  },
  {
    value: 'Abreu e Lima',
    name: 'Abreu e Lima',
    uf: 'PE'
  },
  {
    value: 'Itapissuma',
    name: 'Itapissuma',
    uf: 'PE'
  },
  {
    value: 'Carnaubeira da Penha',
    name: 'Carnaubeira da Penha',
    uf: 'PE'
  },
  {
    value: 'Quixaba',
    name: 'Quixaba',
    uf: 'PE'
  },
  {
    value: 'Santa Cruz da Baixa Verde',
    name: 'Santa Cruz da Baixa Verde',
    uf: 'PE'
  },
  {
    value: 'Paripueira',
    name: 'Paripueira',
    uf: 'AL'
  },
  {
    value: 'Estrela de Alagoas',
    name: 'Estrela de Alagoas',
    uf: 'AL'
  },
  {
    value: 'Pariconha',
    name: 'Pariconha',
    uf: 'AL'
  },
  {
    value: 'Santana do Sao Francisco',
    name: 'Santana do São Francisco',
    uf: 'SE'
  },
  {
    value: 'Sao Jose da Lapa',
    name: 'São José da Lapa',
    uf: 'MG'
  },
  {
    value: 'Capitao Andrade',
    name: 'Capitão Andrade',
    uf: 'MG'
  },
  {
    value: 'Catuji',
    name: 'Catuji',
    uf: 'MG'
  },
  {
    value: 'Jampruca',
    name: 'Jampruca',
    uf: 'MG'
  },
  {
    value: 'Divisopolis',
    name: 'Divisópolis',
    uf: 'MG'
  },
  {
    value: 'Mata Verde',
    name: 'Mata Verde',
    uf: 'MG'
  },
  {
    value: 'Palmopolis',
    name: 'Palmópolis',
    uf: 'MG'
  },
  {
    value: 'Entre Folhas',
    name: 'Entre Folhas',
    uf: 'MG'
  },
  {
    value: 'Ipaba',
    name: 'Ipaba',
    uf: 'MG'
  },
  {
    value: 'Santa Barbara do Leste',
    name: 'Santa Bárbara do Leste',
    uf: 'MG'
  },
  {
    value: 'Santa Rita de Minas',
    name: 'Santa Rita de Minas',
    uf: 'MG'
  },
  {
    value: 'Ubaporanga',
    name: 'Ubaporanga',
    uf: 'MG'
  },
  {
    value: 'Santana do Paraiso',
    name: 'Santana do Paraíso',
    uf: 'MG'
  },
  {
    value: 'Durande',
    name: 'Durandé',
    uf: 'MG'
  },
  {
    value: 'Sao Joao do Manhuacu',
    name: 'São João do Manhuaçu',
    uf: 'MG'
  },
  {
    value: 'Sao Joao do Manteninha',
    name: 'São João do Manteninha',
    uf: 'MG'
  },
  {
    value: 'Alfredo Vasconcelos',
    name: 'Alfredo Vasconcelos',
    uf: 'MG'
  },
  {
    value: 'Fervedouro',
    name: 'Fervedouro',
    uf: 'MG'
  },
  {
    value: 'Carneirinho',
    name: 'Carneirinho',
    uf: 'MG'
  },
  {
    value: 'Limeira do Oeste',
    name: 'Limeira do Oeste',
    uf: 'MG'
  },
  {
    value: 'Senador Amaral',
    name: 'Senador Amaral',
    uf: 'MG'
  },
  {
    value: 'Juatuba',
    name: 'Juatuba',
    uf: 'MG'
  },
  {
    value: 'Icarai de Minas',
    name: 'Icaraí de Minas',
    uf: 'MG'
  },
  {
    value: 'Lontra',
    name: 'Lontra',
    uf: 'MG'
  },
  {
    value: 'Montezuma',
    name: 'Montezuma',
    uf: 'MG'
  },
  {
    value: 'Urucuia',
    name: 'Urucuia',
    uf: 'MG'
  },
  {
    value: 'Agua Branca',
    name: 'Água Branca',
    uf: 'AL'
  },
  {
    value: 'Anadia',
    name: 'Anadia',
    uf: 'AL'
  },
  {
    value: 'Arapiraca',
    name: 'Arapiraca',
    uf: 'AL'
  },
  {
    value: 'Atalaia',
    name: 'Atalaia',
    uf: 'AL'
  },
  {
    value: 'Barra de Santo Antonio',
    name: 'Barra de Santo Antônio',
    uf: 'AL'
  },
  {
    value: 'Barra de Sao Miguel',
    name: 'Barra de São Miguel',
    uf: 'AL'
  },
  {
    value: 'Batalha',
    name: 'Batalha',
    uf: 'AL'
  },
  {
    value: 'Belem',
    name: 'Belém',
    uf: 'AL'
  },
  {
    value: 'Belo Monte',
    name: 'Belo Monte',
    uf: 'AL'
  },
  {
    value: 'Boca da Mata',
    name: 'Boca da Mata',
    uf: 'AL'
  },
  {
    value: 'Branquinha',
    name: 'Branquinha',
    uf: 'AL'
  },
  {
    value: 'Cacimbinhas',
    name: 'Cacimbinhas',
    uf: 'AL'
  },
  {
    value: 'Cajueiro',
    name: 'Cajueiro',
    uf: 'AL'
  },
  {
    value: 'Campo Alegre',
    name: 'Campo Alegre',
    uf: 'AL'
  },
  {
    value: 'Campo Grande',
    name: 'Campo Grande',
    uf: 'AL'
  },
  {
    value: 'Canapi',
    name: 'Canapi',
    uf: 'AL'
  },
  {
    value: 'Capela',
    name: 'Capela',
    uf: 'AL'
  },
  {
    value: 'Carneiros',
    name: 'Carneiros',
    uf: 'AL'
  },
  {
    value: 'Cha Preta',
    name: 'Chã Preta',
    uf: 'AL'
  },
  {
    value: 'Coite do Noia',
    name: 'Coité do Nóia',
    uf: 'AL'
  },
  {
    value: 'Colonia Leopoldina',
    name: 'Colônia Leopoldina',
    uf: 'AL'
  },
  {
    value: 'Coqueiro Seco',
    name: 'Coqueiro Seco',
    uf: 'AL'
  },
  {
    value: 'Coruripe',
    name: 'Coruripe',
    uf: 'AL'
  },
  {
    value: 'Delmiro Gouveia',
    name: 'Delmiro Gouveia',
    uf: 'AL'
  },
  {
    value: 'Dois Riachos',
    name: 'Dois Riachos',
    uf: 'AL'
  },
  {
    value: 'Feira Grande',
    name: 'Feira Grande',
    uf: 'AL'
  },
  {
    value: 'Feliz Deserto',
    name: 'Feliz Deserto',
    uf: 'AL'
  },
  {
    value: 'Flexeiras',
    name: 'Flexeiras',
    uf: 'AL'
  },
  {
    value: 'Girau do Ponciano',
    name: 'Girau do Ponciano',
    uf: 'AL'
  },
  {
    value: 'Ibateguara',
    name: 'Ibateguara',
    uf: 'AL'
  },
  {
    value: 'Igaci',
    name: 'Igaci',
    uf: 'AL'
  },
  {
    value: 'Igreja Nova',
    name: 'Igreja Nova',
    uf: 'AL'
  },
  {
    value: 'Inhapi',
    name: 'Inhapi',
    uf: 'AL'
  },
  {
    value: 'Jacare dos Homens',
    name: 'Jacaré dos Homens',
    uf: 'AL'
  },
  {
    value: 'Jacuipe',
    name: 'Jacuípe',
    uf: 'AL'
  },
  {
    value: 'Japaratinga',
    name: 'Japaratinga',
    uf: 'AL'
  },
  {
    value: 'Jaramataia',
    name: 'Jaramataia',
    uf: 'AL'
  },
  {
    value: 'Joaquim Gomes',
    name: 'Joaquim Gomes',
    uf: 'AL'
  },
  {
    value: 'Jundia',
    name: 'Jundiá',
    uf: 'AL'
  },
  {
    value: 'Junqueiro',
    name: 'Junqueiro',
    uf: 'AL'
  },
  {
    value: 'Lagoa da Canoa',
    name: 'Lagoa da Canoa',
    uf: 'AL'
  },
  {
    value: 'Limoeiro de Anadia',
    name: 'Limoeiro de Anadia',
    uf: 'AL'
  },
  {
    value: 'Maceio',
    name: 'Maceió',
    uf: 'AL'
  },
  {
    value: 'Major Isidoro',
    name: 'Major Isidoro',
    uf: 'AL'
  },
  {
    value: 'Maragogi',
    name: 'Maragogi',
    uf: 'AL'
  },
  {
    value: 'Maravilha',
    name: 'Maravilha',
    uf: 'AL'
  },
  {
    value: 'Marechal Deodoro',
    name: 'Marechal Deodoro',
    uf: 'AL'
  },
  {
    value: 'Maribondo',
    name: 'Maribondo',
    uf: 'AL'
  },
  {
    value: 'Mar Vermelho',
    name: 'Mar Vermelho',
    uf: 'AL'
  },
  {
    value: 'Mata Grande',
    name: 'Mata Grande',
    uf: 'AL'
  },
  {
    value: 'Matriz de Camaragibe',
    name: 'Matriz de Camaragibe',
    uf: 'AL'
  },
  {
    value: 'Messias',
    name: 'Messias',
    uf: 'AL'
  },
  {
    value: 'Minador do Negrao',
    name: 'Minador do Negrão',
    uf: 'AL'
  },
  {
    value: 'Monteiropolis',
    name: 'Monteirópolis',
    uf: 'AL'
  },
  {
    value: 'Murici',
    name: 'Murici',
    uf: 'AL'
  },
  {
    value: 'Novo Lino',
    name: 'Novo Lino',
    uf: 'AL'
  },
  {
    value: "Olho d'Agua das Flores",
    name: "Olho d'Água das Flores",
    uf: 'AL'
  },
  {
    value: "Olho d'Agua do Casado",
    name: "Olho d'Água do Casado",
    uf: 'AL'
  },
  {
    value: "Olho d'Agua Grande",
    name: "Olho d'Água Grande",
    uf: 'AL'
  },
  {
    value: 'Olivenca',
    name: 'Olivença',
    uf: 'AL'
  },
  {
    value: 'Ouro Branco',
    name: 'Ouro Branco',
    uf: 'AL'
  },
  {
    value: 'Palestina',
    name: 'Palestina',
    uf: 'AL'
  },
  {
    value: 'Palmeira dos Indios',
    name: 'Palmeira dos Índios',
    uf: 'AL'
  },
  {
    value: 'Pao de Acucar',
    name: 'Pão de Açúcar',
    uf: 'AL'
  },
  {
    value: 'Passo de Camaragibe',
    name: 'Passo de Camaragibe',
    uf: 'AL'
  },
  {
    value: 'Paulo Jacinto',
    name: 'Paulo Jacinto',
    uf: 'AL'
  },
  {
    value: 'Penedo',
    name: 'Penedo',
    uf: 'AL'
  },
  {
    value: 'Piacabucu',
    name: 'Piaçabuçu',
    uf: 'AL'
  },
  {
    value: 'Pilar',
    name: 'Pilar',
    uf: 'AL'
  },
  {
    value: 'Pindoba',
    name: 'Pindoba',
    uf: 'AL'
  },
  {
    value: 'Piranhas',
    name: 'Piranhas',
    uf: 'AL'
  },
  {
    value: 'Poco das Trincheiras',
    name: 'Poço das Trincheiras',
    uf: 'AL'
  },
  {
    value: 'Porto Calvo',
    name: 'Porto Calvo',
    uf: 'AL'
  },
  {
    value: 'Porto de Pedras',
    name: 'Porto de Pedras',
    uf: 'AL'
  },
  {
    value: 'Porto Real do Colegio',
    name: 'Porto Real do Colégio',
    uf: 'AL'
  },
  {
    value: 'Quebrangulo',
    name: 'Quebrangulo',
    uf: 'AL'
  },
  {
    value: 'Rio Largo',
    name: 'Rio Largo',
    uf: 'AL'
  },
  {
    value: 'Roteiro',
    name: 'Roteiro',
    uf: 'AL'
  },
  {
    value: 'Santa Luzia do Norte',
    name: 'Santa Luzia do Norte',
    uf: 'AL'
  },
  {
    value: 'Santana do Ipanema',
    name: 'Santana do Ipanema',
    uf: 'AL'
  },
  {
    value: 'Santana do Mundau',
    name: 'Santana do Mundaú',
    uf: 'AL'
  },
  {
    value: 'Sao Bras',
    name: 'São Brás',
    uf: 'AL'
  },
  {
    value: 'Sao Jose da Laje',
    name: 'São José da Laje',
    uf: 'AL'
  },
  {
    value: 'Sao Jose da Tapera',
    name: 'São José da Tapera',
    uf: 'AL'
  },
  {
    value: 'Sao Luis do Quitunde',
    name: 'São Luís do Quitunde',
    uf: 'AL'
  },
  {
    value: 'Sao Miguel dos Campos',
    name: 'São Miguel dos Campos',
    uf: 'AL'
  },
  {
    value: 'Sao Miguel dos Milagres',
    name: 'São Miguel dos Milagres',
    uf: 'AL'
  },
  {
    value: 'Sao Sebastiao',
    name: 'São Sebastião',
    uf: 'AL'
  },
  {
    value: 'Satuba',
    name: 'Satuba',
    uf: 'AL'
  },
  {
    value: "Tanque d'Arca",
    name: "Tanque d'Arca",
    uf: 'AL'
  },
  {
    value: 'Taquarana',
    name: 'Taquarana',
    uf: 'AL'
  },
  {
    value: 'Traipu',
    name: 'Traipu',
    uf: 'AL'
  },
  {
    value: 'Uniao dos Palmares',
    name: 'União dos Palmares',
    uf: 'AL'
  },
  {
    value: 'Vicosa',
    name: 'Viçosa',
    uf: 'AL'
  },
  {
    value: 'Craibas',
    name: 'Craíbas',
    uf: 'AL'
  },
  {
    value: 'Senador Rui Palmeira',
    name: 'Senador Rui Palmeira',
    uf: 'AL'
  },
  {
    value: 'Jaiba',
    name: 'Jaíba',
    uf: 'MG'
  },
  {
    value: 'Mamonas',
    name: 'Mamonas',
    uf: 'MG'
  },
  {
    value: 'Matias Cardoso',
    name: 'Matias Cardoso',
    uf: 'MG'
  },
  {
    value: 'Pedras de Maria da Cruz',
    name: 'Pedras de Maria da Cruz',
    uf: 'MG'
  },
  {
    value: 'Riachinho',
    name: 'Riachinho',
    uf: 'MG'
  },
  {
    value: 'Arapora',
    name: 'Araporã',
    uf: 'MG'
  },
  {
    value: 'Lagoa Grande',
    name: 'Lagoa Grande',
    uf: 'MG'
  },
  {
    value: 'Guapimirim',
    name: 'Guapimirim',
    uf: 'RJ'
  },
  {
    value: 'Belford Roxo',
    name: 'Belford Roxo',
    uf: 'RJ'
  },
  {
    value: 'Queimados',
    name: 'Queimados',
    uf: 'RJ'
  },
  {
    value: 'Japeri',
    name: 'Japeri',
    uf: 'RJ'
  },
  {
    value: 'Cardoso Moreira',
    name: 'Cardoso Moreira',
    uf: 'RJ'
  },
  {
    value: 'Varre-Sai',
    name: 'Varre-Sai',
    uf: 'RJ'
  },
  {
    value: 'Aperibe',
    name: 'Aperibé',
    uf: 'RJ'
  },
  {
    value: 'Rio das Ostras',
    name: 'Rio das Ostras',
    uf: 'RJ'
  },
  {
    value: 'Quatis',
    name: 'Quatis',
    uf: 'RJ'
  },
  {
    value: 'Areal',
    name: 'Areal',
    uf: 'RJ'
  },
  {
    value: 'Comendador Levy Gasparian',
    name: 'Comendador Levy Gasparian',
    uf: 'RJ'
  },
  {
    value: 'Marechal Floriano',
    name: 'Marechal Floriano',
    uf: 'ES'
  },
  {
    value: 'Irupi',
    name: 'Irupi',
    uf: 'ES'
  },
  {
    value: 'Sao Domingos do Norte',
    name: 'São Domingos do Norte',
    uf: 'ES'
  },
  {
    value: 'Vila Pavao',
    name: 'Vila Pavão',
    uf: 'ES'
  },
  {
    value: 'Lourdes',
    name: 'Lourdes',
    uf: 'SP'
  },
  {
    value: 'Santo Antonio do Aracangua',
    name: 'Santo Antônio do Aracanguá',
    uf: 'SP'
  },
  {
    value: 'Sao Joao de Iracema',
    name: 'São João de Iracema',
    uf: 'SP'
  },
  {
    value: 'Ilha Solteira',
    name: 'Ilha Solteira',
    uf: 'SP'
  },
  {
    value: 'Suzanapolis',
    name: 'Suzanápolis',
    uf: 'SP'
  },
  {
    value: 'Canitar',
    name: 'Canitar',
    uf: 'SP'
  },
  {
    value: 'Engenheiro Coelho',
    name: 'Engenheiro Coelho',
    uf: 'SP'
  },
  {
    value: 'Hortolandia',
    name: 'Hortolândia',
    uf: 'SP'
  },
  {
    value: 'Holambra',
    name: 'Holambra',
    uf: 'SP'
  },
  {
    value: 'Tuiuti',
    name: 'Tuiuti',
    uf: 'SP'
  },
  {
    value: 'Vargem',
    name: 'Vargem',
    uf: 'SP'
  },
  {
    value: 'Estiva Gerbi',
    name: 'Estiva Gerbi',
    uf: 'SP'
  },
  {
    value: 'Emilianopolis',
    name: 'Emilianópolis',
    uf: 'SP'
  },
  {
    value: 'Pedrinhas Paulista',
    name: 'Pedrinhas Paulista',
    uf: 'SP'
  },
  {
    value: 'Bertioga',
    name: 'Bertioga',
    uf: 'SP'
  },
  {
    value: 'Cajati',
    name: 'Cajati',
    uf: 'SP'
  },
  {
    value: 'Ilha Comprida',
    name: 'Ilha Comprida',
    uf: 'SP'
  },
  {
    value: 'Ubarana',
    name: 'Ubarana',
    uf: 'SP'
  },
  {
    value: 'Zacarias',
    name: 'Zacarias',
    uf: 'SP'
  },
  {
    value: 'Elisiario',
    name: 'Elisiário',
    uf: 'SP'
  },
  {
    value: 'Marapoama',
    name: 'Marapoama',
    uf: 'SP'
  },
  {
    value: 'Novais',
    name: 'Novais',
    uf: 'SP'
  },
  {
    value: 'Aspasia',
    name: 'Aspásia',
    uf: 'SP'
  },
  {
    value: 'Mesopolis',
    name: 'Mesópolis',
    uf: 'SP'
  },
  {
    value: 'Nova Canaa Paulista',
    name: 'Nova Canaã Paulista',
    uf: 'SP'
  },
  {
    value: 'Pontalinda',
    name: 'Pontalinda',
    uf: 'SP'
  },
  {
    value: 'Parisi',
    name: 'Parisi',
    uf: 'SP'
  },
  {
    value: 'Arapei',
    name: 'Arapeí',
    uf: 'SP'
  },
  {
    value: 'Potim',
    name: 'Potim',
    uf: 'SP'
  },
  {
    value: 'Alambari',
    name: 'Alambari',
    uf: 'SP'
  },
  {
    value: 'Barra do Chapeu',
    name: 'Barra do Chapéu',
    uf: 'SP'
  },
  {
    value: 'Campina do Monte Alegre',
    name: 'Campina do Monte Alegre',
    uf: 'SP'
  },
  {
    value: 'Fernando de Noronha',
    name: 'Fernando de Noronha',
    uf: 'PE'
  },
  {
    value: 'Barauna',
    name: 'Baraúna',
    uf: 'RN'
  },
  {
    value: 'Muquem do Sao Francisco',
    name: 'Muquém do São Francisco',
    uf: 'BA'
  },
  {
    value: 'Nova Fatima',
    name: 'Nova Fátima',
    uf: 'BA'
  },
  {
    value: 'Nova Ibia',
    name: 'Nova Ibiá',
    uf: 'BA'
  },
  {
    value: 'Nova Redencao',
    name: 'Nova Redenção',
    uf: 'BA'
  },
  {
    value: 'Novo Horizonte',
    name: 'Novo Horizonte',
    uf: 'BA'
  },
  {
    value: 'Novo Triunfo',
    name: 'Novo Triunfo',
    uf: 'BA'
  },
  {
    value: 'Ourolandia',
    name: 'Ourolândia',
    uf: 'BA'
  },
  {
    value: 'Pirai do Norte',
    name: 'Piraí do Norte',
    uf: 'BA'
  },
  {
    value: 'Ponto Novo',
    name: 'Ponto Novo',
    uf: 'BA'
  },
  {
    value: 'Presidente Tancredo Neves',
    name: 'Presidente Tancredo Neves',
    uf: 'BA'
  },
  {
    value: 'Quixabeira',
    name: 'Quixabeira',
    uf: 'BA'
  },
  {
    value: 'Ribeirao do Largo',
    name: 'Ribeirão do Largo',
    uf: 'BA'
  },
  {
    value: 'Sao Domingos',
    name: 'São Domingos',
    uf: 'BA'
  },
  {
    value: 'Sao Felix do Coribe',
    name: 'São Félix do Coribe',
    uf: 'BA'
  },
  {
    value: 'Sao Jose do Jacuipe',
    name: 'São José do Jacuípe',
    uf: 'BA'
  },
  {
    value: 'Sao Jose da Vitoria',
    name: 'São José da Vitória',
    uf: 'BA'
  },
  {
    value: 'Saubara',
    name: 'Saubara',
    uf: 'BA'
  },
  {
    value: 'Serra do Ramalho',
    name: 'Serra do Ramalho',
    uf: 'BA'
  },
  {
    value: 'Sitio do Mato',
    name: 'Sítio do Mato',
    uf: 'BA'
  },
  {
    value: 'Sitio do Quinto',
    name: 'Sítio do Quinto',
    uf: 'BA'
  },
  {
    value: 'Sobradinho',
    name: 'Sobradinho',
    uf: 'BA'
  },
  {
    value: 'Umburanas',
    name: 'Umburanas',
    uf: 'BA'
  },
  {
    value: 'Varzedo',
    name: 'Varzedo',
    uf: 'BA'
  },
  {
    value: 'Vereda',
    name: 'Vereda',
    uf: 'BA'
  },
  {
    value: 'Itaoca',
    name: 'Itaoca',
    uf: 'SP'
  },
  {
    value: 'Itapirapua Paulista',
    name: 'Itapirapuã Paulista',
    uf: 'SP'
  },
  {
    value: 'Ribeirao Grande',
    name: 'Ribeirão Grande',
    uf: 'SP'
  },
  {
    value: 'Bom Sucesso de Itarare',
    name: 'Bom Sucesso de Itararé',
    uf: 'SP'
  },
  {
    value: 'Nova Campina',
    name: 'Nova Campina',
    uf: 'SP'
  },
  {
    value: 'Taquarivai',
    name: 'Taquarivaí',
    uf: 'SP'
  },
  {
    value: 'Aluminio',
    name: 'Alumínio',
    uf: 'SP'
  },
  {
    value: 'Aracariguama',
    name: 'Araçariguama',
    uf: 'SP'
  },
  {
    value: 'Jussari',
    name: 'Jussari',
    uf: 'BA'
  },
  {
    value: 'America Dourada',
    name: 'América Dourada',
    uf: 'BA'
  },
  {
    value: 'Arataca',
    name: 'Arataca',
    uf: 'BA'
  },
  {
    value: 'Barro Alto',
    name: 'Barro Alto',
    uf: 'BA'
  },
  {
    value: 'Buritirama',
    name: 'Buritirama',
    uf: 'BA'
  },
  {
    value: 'Capela do Alto Alegre',
    name: 'Capela do Alto Alegre',
    uf: 'BA'
  },
  {
    value: 'Capim Grosso',
    name: 'Capim Grosso',
    uf: 'BA'
  },
  {
    value: 'Canudos',
    name: 'Canudos',
    uf: 'BA'
  },
  {
    value: "Dias d'Avila",
    name: "Dias d'Ávila",
    uf: 'BA'
  },
  {
    value: 'Fatima',
    name: 'Fátima',
    uf: 'BA'
  },
  {
    value: 'Filadelfia',
    name: 'Filadélfia',
    uf: 'BA'
  },
  {
    value: 'Gaviao',
    name: 'Gavião',
    uf: 'BA'
  },
  {
    value: 'Guajeru',
    name: 'Guajeru',
    uf: 'BA'
  },
  {
    value: 'Heliopolis',
    name: 'Heliópolis',
    uf: 'BA'
  },
  {
    value: 'Joao Dourado',
    name: 'João Dourado',
    uf: 'BA'
  },
  {
    value: 'Amparo do Sao Francisco',
    name: 'Amparo do São Francisco',
    uf: 'SE'
  },
  {
    value: 'Aquidaba',
    name: 'Aquidabã',
    uf: 'SE'
  },
  {
    value: 'Aracaju',
    name: 'Aracaju',
    uf: 'SE'
  },
  {
    value: 'Araua',
    name: 'Arauá',
    uf: 'SE'
  },
  {
    value: 'Areia Branca',
    name: 'Areia Branca',
    uf: 'SE'
  },
  {
    value: 'Barra dos Coqueiros',
    name: 'Barra dos Coqueiros',
    uf: 'SE'
  },
  {
    value: 'Brejo Grande',
    name: 'Brejo Grande',
    uf: 'SE'
  },
  {
    value: 'Boquim',
    name: 'Boquim',
    uf: 'SE'
  },
  {
    value: 'Eunapolis',
    name: 'Eunápolis',
    uf: 'BA'
  },
  {
    value: 'Campo do Brito',
    name: 'Campo do Brito',
    uf: 'SE'
  },
  {
    value: 'Canhoba',
    name: 'Canhoba',
    uf: 'SE'
  },
  {
    value: 'Caninde de Sao Francisco',
    name: 'Canindé de São Francisco',
    uf: 'SE'
  },
  {
    value: 'Capela',
    name: 'Capela',
    uf: 'SE'
  },
  {
    value: 'Carira',
    name: 'Carira',
    uf: 'SE'
  },
  {
    value: 'Carmopolis',
    name: 'Carmópolis',
    uf: 'SE'
  },
  {
    value: 'Cedro de Sao Joao',
    name: 'Cedro de São João',
    uf: 'SE'
  },
  {
    value: 'Cristinapolis',
    name: 'Cristinápolis',
    uf: 'SE'
  },
  {
    value: 'Nossa Senhora Aparecida',
    name: 'Nossa Senhora Aparecida',
    uf: 'SE'
  },
  {
    value: 'Cumbe',
    name: 'Cumbe',
    uf: 'SE'
  },
  {
    value: 'Divina Pastora',
    name: 'Divina Pastora',
    uf: 'SE'
  },
  {
    value: 'Estancia',
    name: 'Estância',
    uf: 'SE'
  },
  {
    value: 'Feira Nova',
    name: 'Feira Nova',
    uf: 'SE'
  },
  {
    value: 'Frei Paulo',
    name: 'Frei Paulo',
    uf: 'SE'
  },
  {
    value: 'General Maynard',
    name: 'General Maynard',
    uf: 'SE'
  },
  {
    value: 'Gararu',
    name: 'Gararu',
    uf: 'SE'
  },
  {
    value: 'Gracho Cardoso',
    name: 'Gracho Cardoso',
    uf: 'SE'
  },
  {
    value: 'Ilha das Flores',
    name: 'Ilha das Flores',
    uf: 'SE'
  },
  {
    value: 'Indiaroba',
    name: 'Indiaroba',
    uf: 'SE'
  },
  {
    value: 'Itabaiana',
    name: 'Itabaiana',
    uf: 'SE'
  },
  {
    value: 'Itabaianinha',
    name: 'Itabaianinha',
    uf: 'SE'
  },
  {
    value: 'Itabi',
    name: 'Itabi',
    uf: 'SE'
  },
  {
    value: "Itaporanga d'Ajuda",
    name: "Itaporanga d'Ajuda",
    uf: 'SE'
  },
  {
    value: 'Japaratuba',
    name: 'Japaratuba',
    uf: 'SE'
  },
  {
    value: 'Japoata',
    name: 'Japoatã',
    uf: 'SE'
  },
  {
    value: 'Lagarto',
    name: 'Lagarto',
    uf: 'SE'
  },
  {
    value: 'Laranjeiras',
    name: 'Laranjeiras',
    uf: 'SE'
  },
  {
    value: 'Macambira',
    name: 'Macambira',
    uf: 'SE'
  },
  {
    value: 'Malhada dos Bois',
    name: 'Malhada dos Bois',
    uf: 'SE'
  },
  {
    value: 'Malhador',
    name: 'Malhador',
    uf: 'SE'
  },
  {
    value: 'Maruim',
    name: 'Maruim',
    uf: 'SE'
  },
  {
    value: 'Moita Bonita',
    name: 'Moita Bonita',
    uf: 'SE'
  },
  {
    value: 'Monte Alegre de Sergipe',
    name: 'Monte Alegre de Sergipe',
    uf: 'SE'
  },
  {
    value: 'Muribeca',
    name: 'Muribeca',
    uf: 'SE'
  },
  {
    value: 'Neopolis',
    name: 'Neópolis',
    uf: 'SE'
  },
  {
    value: 'Nossa Senhora da Gloria',
    name: 'Nossa Senhora da Glória',
    uf: 'SE'
  },
  {
    value: 'Nossa Senhora das Dores',
    name: 'Nossa Senhora das Dores',
    uf: 'SE'
  },
  {
    value: 'Nossa Senhora de Lourdes',
    name: 'Nossa Senhora de Lourdes',
    uf: 'SE'
  },
  {
    value: 'Nossa Senhora do Socorro',
    name: 'Nossa Senhora do Socorro',
    uf: 'SE'
  },
  {
    value: 'Pacatuba',
    name: 'Pacatuba',
    uf: 'SE'
  },
  {
    value: 'Pedra Mole',
    name: 'Pedra Mole',
    uf: 'SE'
  },
  {
    value: 'Pedrinhas',
    name: 'Pedrinhas',
    uf: 'SE'
  },
  {
    value: 'Pinhao',
    name: 'Pinhão',
    uf: 'SE'
  },
  {
    value: 'Pirambu',
    name: 'Pirambu',
    uf: 'SE'
  },
  {
    value: 'Poco Redondo',
    name: 'Poço Redondo',
    uf: 'SE'
  },
  {
    value: 'Poco Verde',
    name: 'Poço Verde',
    uf: 'SE'
  },
  {
    value: 'Porto da Folha',
    name: 'Porto da Folha',
    uf: 'SE'
  },
  {
    value: 'Propria',
    name: 'Propriá',
    uf: 'SE'
  },
  {
    value: 'Riachao do Dantas',
    name: 'Riachão do Dantas',
    uf: 'SE'
  },
  {
    value: 'Riachuelo',
    name: 'Riachuelo',
    uf: 'SE'
  },
  {
    value: 'Ribeiropolis',
    name: 'Ribeirópolis',
    uf: 'SE'
  },
  {
    value: 'Rosario do Catete',
    name: 'Rosário do Catete',
    uf: 'SE'
  },
  {
    value: 'Salgado',
    name: 'Salgado',
    uf: 'SE'
  },
  {
    value: 'Santa Luzia do Itanhy',
    name: 'Santa Luzia do Itanhy',
    uf: 'SE'
  },
  {
    value: 'Torre de Pedra',
    name: 'Torre de Pedra',
    uf: 'SP'
  },
  {
    value: 'Santa Rosa de Lima',
    name: 'Santa Rosa de Lima',
    uf: 'SE'
  },
  {
    value: 'Santo Amaro das Brotas',
    name: 'Santo Amaro das Brotas',
    uf: 'SE'
  },
  {
    value: 'Sao Cristovao',
    name: 'São Cristóvão',
    uf: 'SE'
  },
  {
    value: 'Sao Domingos',
    name: 'São Domingos',
    uf: 'SE'
  },
  {
    value: 'Sao Francisco',
    name: 'São Francisco',
    uf: 'SE'
  },
  {
    value: 'Sao Miguel do Aleixo',
    name: 'São Miguel do Aleixo',
    uf: 'SE'
  },
  {
    value: 'Simao Dias',
    name: 'Simão Dias',
    uf: 'SE'
  },
  {
    value: 'Siriri',
    name: 'Siriri',
    uf: 'SE'
  },
  {
    value: 'Telha',
    name: 'Telha',
    uf: 'SE'
  },
  {
    value: 'Tobias Barreto',
    name: 'Tobias Barreto',
    uf: 'SE'
  },
  {
    value: 'Tomar do Geru',
    name: 'Tomar do Geru',
    uf: 'SE'
  },
  {
    value: 'Umbauba',
    name: 'Umbaúba',
    uf: 'SE'
  },
  {
    value: 'Adustina',
    name: 'Adustina',
    uf: 'BA'
  },
  {
    value: 'Andorinha',
    name: 'Andorinha',
    uf: 'BA'
  },
  {
    value: 'Apuarema',
    name: 'Apuarema',
    uf: 'BA'
  },
  {
    value: 'Aracas',
    name: 'Araçás',
    uf: 'BA'
  },
  {
    value: 'Banzae',
    name: 'Banzaê',
    uf: 'BA'
  },
  {
    value: 'Bom Jesus da Serra',
    name: 'Bom Jesus da Serra',
    uf: 'BA'
  },
  {
    value: 'Bonito',
    name: 'Bonito',
    uf: 'BA'
  },
  {
    value: 'Cabaceiras do Paraguacu',
    name: 'Cabaceiras do Paraguaçu',
    uf: 'BA'
  },
  {
    value: 'Caetanos',
    name: 'Caetanos',
    uf: 'BA'
  },
  {
    value: 'Caraibas',
    name: 'Caraíbas',
    uf: 'BA'
  },
  {
    value: 'Caturama',
    name: 'Caturama',
    uf: 'BA'
  },
  {
    value: 'Feira da Mata',
    name: 'Feira da Mata',
    uf: 'BA'
  },
  {
    value: 'Igrapiuna',
    name: 'Igrapiúna',
    uf: 'BA'
  },
  {
    value: 'Itabela',
    name: 'Itabela',
    uf: 'BA'
  },
  {
    value: 'Itaguacu da Bahia',
    name: 'Itaguaçu da Bahia',
    uf: 'BA'
  },
  {
    value: 'Itatim',
    name: 'Itatim',
    uf: 'BA'
  },
  {
    value: 'Iuiu',
    name: 'Iuiu',
    uf: 'BA'
  },
  {
    value: 'Jucurucu',
    name: 'Jucuruçu',
    uf: 'BA'
  },
  {
    value: 'Lagoa Real',
    name: 'Lagoa Real',
    uf: 'BA'
  },
  {
    value: 'Lajedo do Tabocal',
    name: 'Lajedo do Tabocal',
    uf: 'BA'
  },
  {
    value: 'Madre de Deus',
    name: 'Madre de Deus',
    uf: 'BA'
  },
  {
    value: 'Matina',
    name: 'Matina',
    uf: 'BA'
  },
  {
    value: 'Mirante',
    name: 'Mirante',
    uf: 'BA'
  },
  {
    value: 'Mulungu do Morro',
    name: 'Mulungu do Morro',
    uf: 'BA'
  },
  {
    value: 'Abaira',
    name: 'Abaíra',
    uf: 'BA'
  },
  {
    value: 'Abare',
    name: 'Abaré',
    uf: 'BA'
  },
  {
    value: 'Acajutiba',
    name: 'Acajutiba',
    uf: 'BA'
  },
  {
    value: 'Agua Fria',
    name: 'Água Fria',
    uf: 'BA'
  },
  {
    value: 'Erico Cardoso',
    name: 'Érico Cardoso',
    uf: 'BA'
  },
  {
    value: 'Aiquara',
    name: 'Aiquara',
    uf: 'BA'
  },
  {
    value: 'Alagoinhas',
    name: 'Alagoinhas',
    uf: 'BA'
  },
  {
    value: 'Alcobaca',
    name: 'Alcobaça',
    uf: 'BA'
  },
  {
    value: 'Almadina',
    name: 'Almadina',
    uf: 'BA'
  },
  {
    value: 'Amargosa',
    name: 'Amargosa',
    uf: 'BA'
  },
  {
    value: 'Amelia Rodrigues',
    name: 'Amélia Rodrigues',
    uf: 'BA'
  },
  {
    value: 'Anage',
    name: 'Anagé',
    uf: 'BA'
  },
  {
    value: 'Andarai',
    name: 'Andaraí',
    uf: 'BA'
  },
  {
    value: 'Angical',
    name: 'Angical',
    uf: 'BA'
  },
  {
    value: 'Anguera',
    name: 'Anguera',
    uf: 'BA'
  },
  {
    value: 'Antas',
    name: 'Antas',
    uf: 'BA'
  },
  {
    value: 'Antonio Cardoso',
    name: 'Antônio Cardoso',
    uf: 'BA'
  },
  {
    value: 'Antonio Goncalves',
    name: 'Antônio Gonçalves',
    uf: 'BA'
  },
  {
    value: 'Apora',
    name: 'Aporá',
    uf: 'BA'
  },
  {
    value: 'Aracatu',
    name: 'Aracatu',
    uf: 'BA'
  },
  {
    value: 'Araci',
    name: 'Araci',
    uf: 'BA'
  },
  {
    value: 'Aramari',
    name: 'Aramari',
    uf: 'BA'
  },
  {
    value: 'Aratuipe',
    name: 'Aratuípe',
    uf: 'BA'
  },
  {
    value: 'Aurelino Leal',
    name: 'Aurelino Leal',
    uf: 'BA'
  },
  {
    value: 'Baianopolis',
    name: 'Baianópolis',
    uf: 'BA'
  },
  {
    value: 'Baixa Grande',
    name: 'Baixa Grande',
    uf: 'BA'
  },
  {
    value: 'Barra',
    name: 'Barra',
    uf: 'BA'
  },
  {
    value: 'Barra da Estiva',
    name: 'Barra da Estiva',
    uf: 'BA'
  },
  {
    value: 'Barra do Choca',
    name: 'Barra do Choça',
    uf: 'BA'
  },
  {
    value: 'Barra do Mendes',
    name: 'Barra do Mendes',
    uf: 'BA'
  },
  {
    value: 'Barra do Rocha',
    name: 'Barra do Rocha',
    uf: 'BA'
  },
  {
    value: 'Barreiras',
    name: 'Barreiras',
    uf: 'BA'
  },
  {
    value: 'Barro Preto',
    name: 'Barro Preto',
    uf: 'BA'
  },
  {
    value: 'Belmonte',
    name: 'Belmonte',
    uf: 'BA'
  },
  {
    value: 'Belo Campo',
    name: 'Belo Campo',
    uf: 'BA'
  },
  {
    value: 'Biritinga',
    name: 'Biritinga',
    uf: 'BA'
  },
  {
    value: 'Boa Nova',
    name: 'Boa Nova',
    uf: 'BA'
  },
  {
    value: 'Boa Vista do Tupim',
    name: 'Boa Vista do Tupim',
    uf: 'BA'
  },
  {
    value: 'Bom Jesus da Lapa',
    name: 'Bom Jesus da Lapa',
    uf: 'BA'
  },
  {
    value: 'Boninal',
    name: 'Boninal',
    uf: 'BA'
  },
  {
    value: 'Boquira',
    name: 'Boquira',
    uf: 'BA'
  },
  {
    value: 'Botupora',
    name: 'Botuporã',
    uf: 'BA'
  },
  {
    value: 'Brejoes',
    name: 'Brejões',
    uf: 'BA'
  },
  {
    value: 'Brejolandia',
    name: 'Brejolândia',
    uf: 'BA'
  },
  {
    value: 'Brotas de Macaubas',
    name: 'Brotas de Macaúbas',
    uf: 'BA'
  },
  {
    value: 'Brumado',
    name: 'Brumado',
    uf: 'BA'
  },
  {
    value: 'Buerarema',
    name: 'Buerarema',
    uf: 'BA'
  },
  {
    value: 'Caatiba',
    name: 'Caatiba',
    uf: 'BA'
  },
  {
    value: 'Cachoeira',
    name: 'Cachoeira',
    uf: 'BA'
  },
  {
    value: 'Cacule',
    name: 'Caculé',
    uf: 'BA'
  },
  {
    value: 'Caem',
    name: 'Caém',
    uf: 'BA'
  },
  {
    value: 'Caetite',
    name: 'Caetité',
    uf: 'BA'
  },
  {
    value: 'Cafarnaum',
    name: 'Cafarnaum',
    uf: 'BA'
  },
  {
    value: 'Cairu',
    name: 'Cairu',
    uf: 'BA'
  },
  {
    value: 'Caldeirao Grande',
    name: 'Caldeirão Grande',
    uf: 'BA'
  },
  {
    value: 'Camacan',
    name: 'Camacan',
    uf: 'BA'
  },
  {
    value: 'Camacari',
    name: 'Camaçari',
    uf: 'BA'
  },
  {
    value: 'Camamu',
    name: 'Camamu',
    uf: 'BA'
  },
  {
    value: 'Campo Alegre de Lourdes',
    name: 'Campo Alegre de Lourdes',
    uf: 'BA'
  },
  {
    value: 'Campo Formoso',
    name: 'Campo Formoso',
    uf: 'BA'
  },
  {
    value: 'Canapolis',
    name: 'Canápolis',
    uf: 'BA'
  },
  {
    value: 'Canarana',
    name: 'Canarana',
    uf: 'BA'
  },
  {
    value: 'Canavieiras',
    name: 'Canavieiras',
    uf: 'BA'
  },
  {
    value: 'Candeal',
    name: 'Candeal',
    uf: 'BA'
  },
  {
    value: 'Candeias',
    name: 'Candeias',
    uf: 'BA'
  },
  {
    value: 'Candiba',
    name: 'Candiba',
    uf: 'BA'
  },
  {
    value: 'Candido Sales',
    name: 'Cândido Sales',
    uf: 'BA'
  },
  {
    value: 'Cansancao',
    name: 'Cansanção',
    uf: 'BA'
  },
  {
    value: 'Caravelas',
    name: 'Caravelas',
    uf: 'BA'
  },
  {
    value: 'Cardeal da Silva',
    name: 'Cardeal da Silva',
    uf: 'BA'
  },
  {
    value: 'Carinhanha',
    name: 'Carinhanha',
    uf: 'BA'
  },
  {
    value: 'Casa Nova',
    name: 'Casa Nova',
    uf: 'BA'
  },
  {
    value: 'Castro Alves',
    name: 'Castro Alves',
    uf: 'BA'
  },
  {
    value: 'Catolandia',
    name: 'Catolândia',
    uf: 'BA'
  },
  {
    value: 'Catu',
    name: 'Catu',
    uf: 'BA'
  },
  {
    value: 'Central',
    name: 'Central',
    uf: 'BA'
  },
  {
    value: 'Chorrocho',
    name: 'Chorrochó',
    uf: 'BA'
  },
  {
    value: 'Cicero Dantas',
    name: 'Cícero Dantas',
    uf: 'BA'
  },
  {
    value: 'Cipo',
    name: 'Cipó',
    uf: 'BA'
  },
  {
    value: 'Coaraci',
    name: 'Coaraci',
    uf: 'BA'
  },
  {
    value: 'Cocos',
    name: 'Cocos',
    uf: 'BA'
  },
  {
    value: 'Conceicao da Feira',
    name: 'Conceição da Feira',
    uf: 'BA'
  },
  {
    value: 'Conceicao do Almeida',
    name: 'Conceição do Almeida',
    uf: 'BA'
  },
  {
    value: 'Conceicao do Coite',
    name: 'Conceição do Coité',
    uf: 'BA'
  },
  {
    value: 'Conceicao do Jacuipe',
    name: 'Conceição do Jacuípe',
    uf: 'BA'
  },
  {
    value: 'Conde',
    name: 'Conde',
    uf: 'BA'
  },
  {
    value: 'Condeuba',
    name: 'Condeúba',
    uf: 'BA'
  },
  {
    value: 'Contendas do Sincora',
    name: 'Contendas do Sincorá',
    uf: 'BA'
  },
  {
    value: 'Coracao de Maria',
    name: 'Coração de Maria',
    uf: 'BA'
  },
  {
    value: 'Cordeiros',
    name: 'Cordeiros',
    uf: 'BA'
  },
  {
    value: 'Coribe',
    name: 'Coribe',
    uf: 'BA'
  },
  {
    value: 'Coronel Joao Sa',
    name: 'Coronel João Sá',
    uf: 'BA'
  },
  {
    value: 'Correntina',
    name: 'Correntina',
    uf: 'BA'
  },
  {
    value: 'Cotegipe',
    name: 'Cotegipe',
    uf: 'BA'
  },
  {
    value: 'Cravolandia',
    name: 'Cravolândia',
    uf: 'BA'
  },
  {
    value: 'Crisopolis',
    name: 'Crisópolis',
    uf: 'BA'
  },
  {
    value: 'Cristopolis',
    name: 'Cristópolis',
    uf: 'BA'
  },
  {
    value: 'Cruz das Almas',
    name: 'Cruz das Almas',
    uf: 'BA'
  },
  {
    value: 'Curaca',
    name: 'Curaçá',
    uf: 'BA'
  },
  {
    value: 'Dario Meira',
    name: 'Dário Meira',
    uf: 'BA'
  },
  {
    value: 'Dom Basilio',
    name: 'Dom Basílio',
    uf: 'BA'
  },
  {
    value: 'Dom Macedo Costa',
    name: 'Dom Macedo Costa',
    uf: 'BA'
  },
  {
    value: 'Elisio Medrado',
    name: 'Elísio Medrado',
    uf: 'BA'
  },
  {
    value: 'Encruzilhada',
    name: 'Encruzilhada',
    uf: 'BA'
  },
  {
    value: 'Entre Rios',
    name: 'Entre Rios',
    uf: 'BA'
  },
  {
    value: 'Esplanada',
    name: 'Esplanada',
    uf: 'BA'
  },
  {
    value: 'Euclides da Cunha',
    name: 'Euclides da Cunha',
    uf: 'BA'
  },
  {
    value: 'Feira de Santana',
    name: 'Feira de Santana',
    uf: 'BA'
  },
  {
    value: 'Firmino Alves',
    name: 'Firmino Alves',
    uf: 'BA'
  },
  {
    value: 'Floresta Azul',
    name: 'Floresta Azul',
    uf: 'BA'
  },
  {
    value: 'Formosa do Rio Preto',
    name: 'Formosa do Rio Preto',
    uf: 'BA'
  },
  {
    value: 'Gandu',
    name: 'Gandu',
    uf: 'BA'
  },
  {
    value: 'Gentio do Ouro',
    name: 'Gentio do Ouro',
    uf: 'BA'
  },
  {
    value: 'Gloria',
    name: 'Glória',
    uf: 'BA'
  },
  {
    value: 'Gongogi',
    name: 'Gongogi',
    uf: 'BA'
  },
  {
    value: 'Governador Mangabeira',
    name: 'Governador Mangabeira',
    uf: 'BA'
  },
  {
    value: 'Guanambi',
    name: 'Guanambi',
    uf: 'BA'
  },
  {
    value: 'Guaratinga',
    name: 'Guaratinga',
    uf: 'BA'
  },
  {
    value: 'Iacu',
    name: 'Iaçu',
    uf: 'BA'
  },
  {
    value: 'Ibiassuce',
    name: 'Ibiassucê',
    uf: 'BA'
  },
  {
    value: 'Ibicarai',
    name: 'Ibicaraí',
    uf: 'BA'
  },
  {
    value: 'Ibicoara',
    name: 'Ibicoara',
    uf: 'BA'
  },
  {
    value: 'Ibicui',
    name: 'Ibicuí',
    uf: 'BA'
  },
  {
    value: 'Ibipeba',
    name: 'Ibipeba',
    uf: 'BA'
  },
  {
    value: 'Santa Rita de Cassia',
    name: 'Santa Rita de Cássia',
    uf: 'BA'
  },
  {
    value: 'Ibipitanga',
    name: 'Ibipitanga',
    uf: 'BA'
  },
  {
    value: 'Ibiquera',
    name: 'Ibiquera',
    uf: 'BA'
  },
  {
    value: 'Ibirapitanga',
    name: 'Ibirapitanga',
    uf: 'BA'
  },
  {
    value: 'Ibirapua',
    name: 'Ibirapuã',
    uf: 'BA'
  },
  {
    value: 'Ibirataia',
    name: 'Ibirataia',
    uf: 'BA'
  },
  {
    value: 'Ibitiara',
    name: 'Ibitiara',
    uf: 'BA'
  },
  {
    value: 'Ibitita',
    name: 'Ibititá',
    uf: 'BA'
  },
  {
    value: 'Ibotirama',
    name: 'Ibotirama',
    uf: 'BA'
  },
  {
    value: 'Ichu',
    name: 'Ichu',
    uf: 'BA'
  },
  {
    value: 'Igapora',
    name: 'Igaporã',
    uf: 'BA'
  },
  {
    value: 'Iguai',
    name: 'Iguaí',
    uf: 'BA'
  },
  {
    value: 'Ilheus',
    name: 'Ilhéus',
    uf: 'BA'
  },
  {
    value: 'Inhambupe',
    name: 'Inhambupe',
    uf: 'BA'
  },
  {
    value: 'Ipecaeta',
    name: 'Ipecaetá',
    uf: 'BA'
  },
  {
    value: 'Ipiau',
    name: 'Ipiaú',
    uf: 'BA'
  },
  {
    value: 'Ipira',
    name: 'Ipirá',
    uf: 'BA'
  },
  {
    value: 'Ipupiara',
    name: 'Ipupiara',
    uf: 'BA'
  },
  {
    value: 'Irajuba',
    name: 'Irajuba',
    uf: 'BA'
  },
  {
    value: 'Iramaia',
    name: 'Iramaia',
    uf: 'BA'
  },
  {
    value: 'Iraquara',
    name: 'Iraquara',
    uf: 'BA'
  },
  {
    value: 'Irara',
    name: 'Irará',
    uf: 'BA'
  },
  {
    value: 'Irece',
    name: 'Irecê',
    uf: 'BA'
  },
  {
    value: 'Itaberaba',
    name: 'Itaberaba',
    uf: 'BA'
  },
  {
    value: 'Itabuna',
    name: 'Itabuna',
    uf: 'BA'
  },
  {
    value: 'Itacare',
    name: 'Itacaré',
    uf: 'BA'
  },
  {
    value: 'Itaete',
    name: 'Itaeté',
    uf: 'BA'
  },
  {
    value: 'Itagi',
    name: 'Itagi',
    uf: 'BA'
  },
  {
    value: 'Itagiba',
    name: 'Itagibá',
    uf: 'BA'
  },
  {
    value: 'Itagimirim',
    name: 'Itagimirim',
    uf: 'BA'
  },
  {
    value: 'Itaju do Colonia',
    name: 'Itaju do Colônia',
    uf: 'BA'
  },
  {
    value: 'Itajuipe',
    name: 'Itajuípe',
    uf: 'BA'
  },
  {
    value: 'Itamaraju',
    name: 'Itamaraju',
    uf: 'BA'
  },
  {
    value: 'Itamari',
    name: 'Itamari',
    uf: 'BA'
  },
  {
    value: 'Itambe',
    name: 'Itambé',
    uf: 'BA'
  },
  {
    value: 'Itanagra',
    name: 'Itanagra',
    uf: 'BA'
  },
  {
    value: 'Itanhem',
    name: 'Itanhém',
    uf: 'BA'
  },
  {
    value: 'Itaparica',
    name: 'Itaparica',
    uf: 'BA'
  },
  {
    value: 'Itape',
    name: 'Itapé',
    uf: 'BA'
  },
  {
    value: 'Itapebi',
    name: 'Itapebi',
    uf: 'BA'
  },
  {
    value: 'Itapetinga',
    name: 'Itapetinga',
    uf: 'BA'
  },
  {
    value: 'Itapicuru',
    name: 'Itapicuru',
    uf: 'BA'
  },
  {
    value: 'Itapitanga',
    name: 'Itapitanga',
    uf: 'BA'
  },
  {
    value: 'Itaquara',
    name: 'Itaquara',
    uf: 'BA'
  },
  {
    value: 'Itarantim',
    name: 'Itarantim',
    uf: 'BA'
  },
  {
    value: 'Itirucu',
    name: 'Itiruçu',
    uf: 'BA'
  },
  {
    value: 'Itiuba',
    name: 'Itiúba',
    uf: 'BA'
  },
  {
    value: 'Itororo',
    name: 'Itororó',
    uf: 'BA'
  },
  {
    value: 'Ituacu',
    name: 'Ituaçu',
    uf: 'BA'
  },
  {
    value: 'Itubera',
    name: 'Ituberá',
    uf: 'BA'
  },
  {
    value: 'Jacaraci',
    name: 'Jacaraci',
    uf: 'BA'
  },
  {
    value: 'Jacobina',
    name: 'Jacobina',
    uf: 'BA'
  },
  {
    value: 'Jaguaquara',
    name: 'Jaguaquara',
    uf: 'BA'
  },
  {
    value: 'Jaguarari',
    name: 'Jaguarari',
    uf: 'BA'
  },
  {
    value: 'Jaguaripe',
    name: 'Jaguaripe',
    uf: 'BA'
  },
  {
    value: 'Jandaira',
    name: 'Jandaíra',
    uf: 'BA'
  },
  {
    value: 'Jequie',
    name: 'Jequié',
    uf: 'BA'
  },
  {
    value: 'Jeremoabo',
    name: 'Jeremoabo',
    uf: 'BA'
  },
  {
    value: 'Jiquirica',
    name: 'Jiquiriçá',
    uf: 'BA'
  },
  {
    value: 'Jitauna',
    name: 'Jitaúna',
    uf: 'BA'
  },
  {
    value: 'Juazeiro',
    name: 'Juazeiro',
    uf: 'BA'
  },
  {
    value: 'Jussara',
    name: 'Jussara',
    uf: 'BA'
  },
  {
    value: 'Jussiape',
    name: 'Jussiape',
    uf: 'BA'
  },
  {
    value: 'Lafaiete Coutinho',
    name: 'Lafaiete Coutinho',
    uf: 'BA'
  },
  {
    value: 'Laje',
    name: 'Laje',
    uf: 'BA'
  },
  {
    value: 'Lajedao',
    name: 'Lajedão',
    uf: 'BA'
  },
  {
    value: 'Lajedinho',
    name: 'Lajedinho',
    uf: 'BA'
  },
  {
    value: 'Lamarao',
    name: 'Lamarão',
    uf: 'BA'
  },
  {
    value: 'Lauro de Freitas',
    name: 'Lauro de Freitas',
    uf: 'BA'
  },
  {
    value: 'Lencois',
    name: 'Lençóis',
    uf: 'BA'
  },
  {
    value: 'Licinio de Almeida',
    name: 'Licínio de Almeida',
    uf: 'BA'
  },
  {
    value: 'Livramento de Nossa Senhora',
    name: 'Livramento de Nossa Senhora',
    uf: 'BA'
  },
  {
    value: 'Macajuba',
    name: 'Macajuba',
    uf: 'BA'
  },
  {
    value: 'Macarani',
    name: 'Macarani',
    uf: 'BA'
  },
  {
    value: 'Macaubas',
    name: 'Macaúbas',
    uf: 'BA'
  },
  {
    value: 'Macurure',
    name: 'Macururé',
    uf: 'BA'
  },
  {
    value: 'Maiquinique',
    name: 'Maiquinique',
    uf: 'BA'
  },
  {
    value: 'Mairi',
    name: 'Mairi',
    uf: 'BA'
  },
  {
    value: 'Malhada',
    name: 'Malhada',
    uf: 'BA'
  },
  {
    value: 'Malhada de Pedras',
    name: 'Malhada de Pedras',
    uf: 'BA'
  },
  {
    value: 'Manoel Vitorino',
    name: 'Manoel Vitorino',
    uf: 'BA'
  },
  {
    value: 'Maracas',
    name: 'Maracás',
    uf: 'BA'
  },
  {
    value: 'Maragogipe',
    name: 'Maragogipe',
    uf: 'BA'
  },
  {
    value: 'Marau',
    name: 'Maraú',
    uf: 'BA'
  },
  {
    value: 'Marcionilio Souza',
    name: 'Marcionílio Souza',
    uf: 'BA'
  },
  {
    value: 'Mascote',
    name: 'Mascote',
    uf: 'BA'
  },
  {
    value: 'Mata de Sao Joao',
    name: 'Mata de São João',
    uf: 'BA'
  },
  {
    value: 'Medeiros Neto',
    name: 'Medeiros Neto',
    uf: 'BA'
  },
  {
    value: 'Miguel Calmon',
    name: 'Miguel Calmon',
    uf: 'BA'
  },
  {
    value: 'Milagres',
    name: 'Milagres',
    uf: 'BA'
  },
  {
    value: 'Mirangaba',
    name: 'Mirangaba',
    uf: 'BA'
  },
  {
    value: 'Monte Santo',
    name: 'Monte Santo',
    uf: 'BA'
  },
  {
    value: 'Morpara',
    name: 'Morpará',
    uf: 'BA'
  },
  {
    value: 'Morro do Chapeu',
    name: 'Morro do Chapéu',
    uf: 'BA'
  },
  {
    value: 'Mortugaba',
    name: 'Mortugaba',
    uf: 'BA'
  },
  {
    value: 'Mucuge',
    name: 'Mucugê',
    uf: 'BA'
  },
  {
    value: 'Mucuri',
    name: 'Mucuri',
    uf: 'BA'
  },
  {
    value: 'Mundo Novo',
    name: 'Mundo Novo',
    uf: 'BA'
  },
  {
    value: 'Muniz Ferreira',
    name: 'Muniz Ferreira',
    uf: 'BA'
  },
  {
    value: 'Muritiba',
    name: 'Muritiba',
    uf: 'BA'
  },
  {
    value: 'Mutuipe',
    name: 'Mutuípe',
    uf: 'BA'
  },
  {
    value: 'Nazare',
    name: 'Nazaré',
    uf: 'BA'
  },
  {
    value: 'Nilo Pecanha',
    name: 'Nilo Peçanha',
    uf: 'BA'
  },
  {
    value: 'Nova Canaa',
    name: 'Nova Canaã',
    uf: 'BA'
  },
  {
    value: 'Nova Itarana',
    name: 'Nova Itarana',
    uf: 'BA'
  },
  {
    value: 'Nova Soure',
    name: 'Nova Soure',
    uf: 'BA'
  },
  {
    value: 'Nova Vicosa',
    name: 'Nova Viçosa',
    uf: 'BA'
  },
  {
    value: 'Olindina',
    name: 'Olindina',
    uf: 'BA'
  },
  {
    value: 'Oliveira dos Brejinhos',
    name: 'Oliveira dos Brejinhos',
    uf: 'BA'
  },
  {
    value: 'Ouricangas',
    name: 'Ouriçangas',
    uf: 'BA'
  },
  {
    value: 'Palmas de Monte Alto',
    name: 'Palmas de Monte Alto',
    uf: 'BA'
  },
  {
    value: 'Palmeiras',
    name: 'Palmeiras',
    uf: 'BA'
  },
  {
    value: 'Paramirim',
    name: 'Paramirim',
    uf: 'BA'
  },
  {
    value: 'Paratinga',
    name: 'Paratinga',
    uf: 'BA'
  },
  {
    value: 'Paripiranga',
    name: 'Paripiranga',
    uf: 'BA'
  },
  {
    value: 'Pau Brasil',
    name: 'Pau Brasil',
    uf: 'BA'
  },
  {
    value: 'Paulo Afonso',
    name: 'Paulo Afonso',
    uf: 'BA'
  },
  {
    value: 'Pedrao',
    name: 'Pedrão',
    uf: 'BA'
  },
  {
    value: 'Pedro Alexandre',
    name: 'Pedro Alexandre',
    uf: 'BA'
  },
  {
    value: 'Piata',
    name: 'Piatã',
    uf: 'BA'
  },
  {
    value: 'Pilao Arcado',
    name: 'Pilão Arcado',
    uf: 'BA'
  },
  {
    value: 'Pindai',
    name: 'Pindaí',
    uf: 'BA'
  },
  {
    value: 'Pindobacu',
    name: 'Pindobaçu',
    uf: 'BA'
  },
  {
    value: 'Piripa',
    name: 'Piripá',
    uf: 'BA'
  },
  {
    value: 'Piritiba',
    name: 'Piritiba',
    uf: 'BA'
  },
  {
    value: 'Planaltino',
    name: 'Planaltino',
    uf: 'BA'
  },
  {
    value: 'Planalto',
    name: 'Planalto',
    uf: 'BA'
  },
  {
    value: 'Pocoes',
    name: 'Poções',
    uf: 'BA'
  },
  {
    value: 'Pojuca',
    name: 'Pojuca',
    uf: 'BA'
  },
  {
    value: 'Porto Seguro',
    name: 'Porto Seguro',
    uf: 'BA'
  },
  {
    value: 'Potiragua',
    name: 'Potiraguá',
    uf: 'BA'
  },
  {
    value: 'Prado',
    name: 'Prado',
    uf: 'BA'
  },
  {
    value: 'Presidente Dutra',
    name: 'Presidente Dutra',
    uf: 'BA'
  },
  {
    value: 'Presidente Janio Quadros',
    name: 'Presidente Jânio Quadros',
    uf: 'BA'
  },
  {
    value: 'Queimadas',
    name: 'Queimadas',
    uf: 'BA'
  },
  {
    value: 'Quijingue',
    name: 'Quijingue',
    uf: 'BA'
  },
  {
    value: 'Remanso',
    name: 'Remanso',
    uf: 'BA'
  },
  {
    value: 'Retirolandia',
    name: 'Retirolândia',
    uf: 'BA'
  },
  {
    value: 'Riachao das Neves',
    name: 'Riachão das Neves',
    uf: 'BA'
  },
  {
    value: 'Riachao do Jacuipe',
    name: 'Riachão do Jacuípe',
    uf: 'BA'
  },
  {
    value: 'Riacho de Santana',
    name: 'Riacho de Santana',
    uf: 'BA'
  },
  {
    value: 'Ribeira do Amparo',
    name: 'Ribeira do Amparo',
    uf: 'BA'
  },
  {
    value: 'Ribeira do Pombal',
    name: 'Ribeira do Pombal',
    uf: 'BA'
  },
  {
    value: 'Rio de Contas',
    name: 'Rio de Contas',
    uf: 'BA'
  },
  {
    value: 'Rio do Antonio',
    name: 'Rio do Antônio',
    uf: 'BA'
  },
  {
    value: 'Rio do Pires',
    name: 'Rio do Pires',
    uf: 'BA'
  },
  {
    value: 'Rio Real',
    name: 'Rio Real',
    uf: 'BA'
  },
  {
    value: 'Rodelas',
    name: 'Rodelas',
    uf: 'BA'
  },
  {
    value: 'Ruy Barbosa',
    name: 'Ruy Barbosa',
    uf: 'BA'
  },
  {
    value: 'Salinas da Margarida',
    name: 'Salinas da Margarida',
    uf: 'BA'
  },
  {
    value: 'Salvador',
    name: 'Salvador',
    uf: 'BA'
  },
  {
    value: 'Santa Barbara',
    name: 'Santa Bárbara',
    uf: 'BA'
  },
  {
    value: 'Santa Brigida',
    name: 'Santa Brígida',
    uf: 'BA'
  },
  {
    value: 'Santa Cruz Cabralia',
    name: 'Santa Cruz Cabrália',
    uf: 'BA'
  },
  {
    value: 'Santa Cruz da Vitoria',
    name: 'Santa Cruz da Vitória',
    uf: 'BA'
  },
  {
    value: 'Santa Ines',
    name: 'Santa Inês',
    uf: 'BA'
  },
  {
    value: 'Santaluz',
    name: 'Santaluz',
    uf: 'BA'
  },
  {
    value: 'Santa Maria da Vitoria',
    name: 'Santa Maria da Vitória',
    uf: 'BA'
  },
  {
    value: 'Santana',
    name: 'Santana',
    uf: 'BA'
  },
  {
    value: 'Santanopolis',
    name: 'Santanópolis',
    uf: 'BA'
  },
  {
    value: 'Santa Terezinha',
    name: 'Santa Terezinha',
    uf: 'BA'
  },
  {
    value: 'Santo Amaro',
    name: 'Santo Amaro',
    uf: 'BA'
  },
  {
    value: 'Santo Antonio de Jesus',
    name: 'Santo Antônio de Jesus',
    uf: 'BA'
  },
  {
    value: 'Santo Estevao',
    name: 'Santo Estêvão',
    uf: 'BA'
  },
  {
    value: 'Sao Desiderio',
    name: 'São Desidério',
    uf: 'BA'
  },
  {
    value: 'Sao Felix',
    name: 'São Félix',
    uf: 'BA'
  },
  {
    value: 'Sao Felipe',
    name: 'São Felipe',
    uf: 'BA'
  },
  {
    value: 'Sao Francisco do Conde',
    name: 'São Francisco do Conde',
    uf: 'BA'
  },
  {
    value: 'Sao Goncalo dos Campos',
    name: 'São Gonçalo dos Campos',
    uf: 'BA'
  },
  {
    value: 'Sao Miguel das Matas',
    name: 'São Miguel das Matas',
    uf: 'BA'
  },
  {
    value: 'Sao Sebastiao do Passe',
    name: 'São Sebastião do Passé',
    uf: 'BA'
  },
  {
    value: 'Sapeacu',
    name: 'Sapeaçu',
    uf: 'BA'
  },
  {
    value: 'Satiro Dias',
    name: 'Sátiro Dias',
    uf: 'BA'
  },
  {
    value: 'Saude',
    name: 'Saúde',
    uf: 'BA'
  },
  {
    value: 'Seabra',
    name: 'Seabra',
    uf: 'BA'
  },
  {
    value: 'Sebastiao Laranjeiras',
    name: 'Sebastião Laranjeiras',
    uf: 'BA'
  },
  {
    value: 'Senhor do Bonfim',
    name: 'Senhor do Bonfim',
    uf: 'BA'
  },
  {
    value: 'Sento Se',
    name: 'Sento Sé',
    uf: 'BA'
  },
  {
    value: 'Serra Dourada',
    name: 'Serra Dourada',
    uf: 'BA'
  },
  {
    value: 'Serra Preta',
    name: 'Serra Preta',
    uf: 'BA'
  },
  {
    value: 'Serrinha',
    name: 'Serrinha',
    uf: 'BA'
  },
  {
    value: 'Serrolandia',
    name: 'Serrolândia',
    uf: 'BA'
  },
  {
    value: 'Simoes Filho',
    name: 'Simões Filho',
    uf: 'BA'
  },
  {
    value: 'Souto Soares',
    name: 'Souto Soares',
    uf: 'BA'
  },
  {
    value: 'Tabocas do Brejo Velho',
    name: 'Tabocas do Brejo Velho',
    uf: 'BA'
  },
  {
    value: 'Tanhacu',
    name: 'Tanhaçu',
    uf: 'BA'
  },
  {
    value: 'Tanquinho',
    name: 'Tanquinho',
    uf: 'BA'
  },
  {
    value: 'Taperoa',
    name: 'Taperoá',
    uf: 'BA'
  },
  {
    value: 'Tapiramuta',
    name: 'Tapiramutá',
    uf: 'BA'
  },
  {
    value: 'Teodoro Sampaio',
    name: 'Teodoro Sampaio',
    uf: 'BA'
  },
  {
    value: 'Teofilandia',
    name: 'Teofilândia',
    uf: 'BA'
  },
  {
    value: 'Teolandia',
    name: 'Teolândia',
    uf: 'BA'
  },
  {
    value: 'Terra Nova',
    name: 'Terra Nova',
    uf: 'BA'
  },
  {
    value: 'Tremedal',
    name: 'Tremedal',
    uf: 'BA'
  },
  {
    value: 'Tucano',
    name: 'Tucano',
    uf: 'BA'
  },
  {
    value: 'Uaua',
    name: 'Uauá',
    uf: 'BA'
  },
  {
    value: 'Ubaira',
    name: 'Ubaíra',
    uf: 'BA'
  },
  {
    value: 'Ubaitaba',
    name: 'Ubaitaba',
    uf: 'BA'
  },
  {
    value: 'Ubata',
    name: 'Ubatã',
    uf: 'BA'
  },
  {
    value: 'Uibai',
    name: 'Uibaí',
    uf: 'BA'
  },
  {
    value: 'Una',
    name: 'Una',
    uf: 'BA'
  },
  {
    value: 'Urandi',
    name: 'Urandi',
    uf: 'BA'
  },
  {
    value: 'Urucuca',
    name: 'Uruçuca',
    uf: 'BA'
  },
  {
    value: 'Utinga',
    name: 'Utinga',
    uf: 'BA'
  },
  {
    value: 'Valenca',
    name: 'Valença',
    uf: 'BA'
  },
  {
    value: 'Valente',
    name: 'Valente',
    uf: 'BA'
  },
  {
    value: 'Varzea do Poco',
    name: 'Várzea do Poço',
    uf: 'BA'
  },
  {
    value: 'Vera Cruz',
    name: 'Vera Cruz',
    uf: 'BA'
  },
  {
    value: 'Vitoria da Conquista',
    name: 'Vitória da Conquista',
    uf: 'BA'
  },
  {
    value: 'Wagner',
    name: 'Wagner',
    uf: 'BA'
  },
  {
    value: 'Wenceslau Guimaraes',
    name: 'Wenceslau Guimarães',
    uf: 'BA'
  },
  {
    value: 'Xique-Xique',
    name: 'Xique-Xique',
    uf: 'BA'
  },
  {
    value: 'Lapao',
    name: 'Lapão',
    uf: 'BA'
  },
  {
    value: 'Maetinga',
    name: 'Maetinga',
    uf: 'BA'
  },
  {
    value: 'Mansidao',
    name: 'Mansidão',
    uf: 'BA'
  },
  {
    value: 'Nordestina',
    name: 'Nordestina',
    uf: 'BA'
  },
  {
    value: 'Pe de Serra',
    name: 'Pé de Serra',
    uf: 'BA'
  },
  {
    value: 'Pintadas',
    name: 'Pintadas',
    uf: 'BA'
  },
  {
    value: 'Rafael Jambeiro',
    name: 'Rafael Jambeiro',
    uf: 'BA'
  },
  {
    value: 'Santa Luzia',
    name: 'Santa Luzia',
    uf: 'BA'
  },
  {
    value: 'Sao Gabriel',
    name: 'São Gabriel',
    uf: 'BA'
  },
  {
    value: 'Tanque Novo',
    name: 'Tanque Novo',
    uf: 'BA'
  },
  {
    value: 'Teixeira de Freitas',
    name: 'Teixeira de Freitas',
    uf: 'BA'
  },
  {
    value: 'Varzea Nova',
    name: 'Várzea Nova',
    uf: 'BA'
  },
  {
    value: 'Varzea da Roca',
    name: 'Várzea da Roça',
    uf: 'BA'
  },
  {
    value: 'Wanderley',
    name: 'Wanderley',
    uf: 'BA'
  },
  {
    value: 'Abadia dos Dourados',
    name: 'Abadia dos Dourados',
    uf: 'MG'
  },
  {
    value: 'Abaete',
    name: 'Abaeté',
    uf: 'MG'
  },
  {
    value: 'Abre Campo',
    name: 'Abre Campo',
    uf: 'MG'
  },
  {
    value: 'Acaiaca',
    name: 'Acaiaca',
    uf: 'MG'
  },
  {
    value: 'Acucena',
    name: 'Açucena',
    uf: 'MG'
  },
  {
    value: 'Agua Boa',
    name: 'Água Boa',
    uf: 'MG'
  },
  {
    value: 'Agua Comprida',
    name: 'Água Comprida',
    uf: 'MG'
  },
  {
    value: 'Aguanil',
    name: 'Aguanil',
    uf: 'MG'
  },
  {
    value: 'Aguas Formosas',
    name: 'Águas Formosas',
    uf: 'MG'
  },
  {
    value: 'Aguas Vermelhas',
    name: 'Águas Vermelhas',
    uf: 'MG'
  },
  {
    value: 'Aimores',
    name: 'Aimorés',
    uf: 'MG'
  },
  {
    value: 'Aiuruoca',
    name: 'Aiuruoca',
    uf: 'MG'
  },
  {
    value: 'Alagoa',
    name: 'Alagoa',
    uf: 'MG'
  },
  {
    value: 'Albertina',
    name: 'Albertina',
    uf: 'MG'
  },
  {
    value: 'Alem Paraiba',
    name: 'Além Paraíba',
    uf: 'MG'
  },
  {
    value: 'Alfenas',
    name: 'Alfenas',
    uf: 'MG'
  },
  {
    value: 'Almenara',
    name: 'Almenara',
    uf: 'MG'
  },
  {
    value: 'Alpercata',
    name: 'Alpercata',
    uf: 'MG'
  },
  {
    value: 'Alpinopolis',
    name: 'Alpinópolis',
    uf: 'MG'
  },
  {
    value: 'Alterosa',
    name: 'Alterosa',
    uf: 'MG'
  },
  {
    value: 'Alto Rio Doce',
    name: 'Alto Rio Doce',
    uf: 'MG'
  },
  {
    value: 'Alvarenga',
    name: 'Alvarenga',
    uf: 'MG'
  },
  {
    value: 'Alvinopolis',
    name: 'Alvinópolis',
    uf: 'MG'
  },
  {
    value: 'Alvorada de Minas',
    name: 'Alvorada de Minas',
    uf: 'MG'
  },
  {
    value: 'Amparo do Serra',
    name: 'Amparo do Serra',
    uf: 'MG'
  },
  {
    value: 'Andradas',
    name: 'Andradas',
    uf: 'MG'
  },
  {
    value: 'Cachoeira de Pajeu',
    name: 'Cachoeira de Pajeú',
    uf: 'MG'
  },
  {
    value: 'Andrelandia',
    name: 'Andrelândia',
    uf: 'MG'
  },
  {
    value: 'Antonio Carlos',
    name: 'Antônio Carlos',
    uf: 'MG'
  },
  {
    value: 'Antonio Dias',
    name: 'Antônio Dias',
    uf: 'MG'
  },
  {
    value: 'Antonio Prado de Minas',
    name: 'Antônio Prado de Minas',
    uf: 'MG'
  },
  {
    value: 'Aracai',
    name: 'Araçaí',
    uf: 'MG'
  },
  {
    value: 'Aracitaba',
    name: 'Aracitaba',
    uf: 'MG'
  },
  {
    value: 'Aracuai',
    name: 'Araçuaí',
    uf: 'MG'
  },
  {
    value: 'Araguari',
    name: 'Araguari',
    uf: 'MG'
  },
  {
    value: 'Arantina',
    name: 'Arantina',
    uf: 'MG'
  },
  {
    value: 'Araponga',
    name: 'Araponga',
    uf: 'MG'
  },
  {
    value: 'Arapua',
    name: 'Arapuá',
    uf: 'MG'
  },
  {
    value: 'Araujos',
    name: 'Araújos',
    uf: 'MG'
  },
  {
    value: 'Araxa',
    name: 'Araxá',
    uf: 'MG'
  },
  {
    value: 'Arceburgo',
    name: 'Arceburgo',
    uf: 'MG'
  },
  {
    value: 'Arcos',
    name: 'Arcos',
    uf: 'MG'
  },
  {
    value: 'Areado',
    name: 'Areado',
    uf: 'MG'
  },
  {
    value: 'Argirita',
    name: 'Argirita',
    uf: 'MG'
  },
  {
    value: 'Arinos',
    name: 'Arinos',
    uf: 'MG'
  },
  {
    value: 'Astolfo Dutra',
    name: 'Astolfo Dutra',
    uf: 'MG'
  },
  {
    value: 'Ataleia',
    name: 'Ataléia',
    uf: 'MG'
  },
  {
    value: 'Augusto de Lima',
    name: 'Augusto de Lima',
    uf: 'MG'
  },
  {
    value: 'Baependi',
    name: 'Baependi',
    uf: 'MG'
  },
  {
    value: 'Baldim',
    name: 'Baldim',
    uf: 'MG'
  },
  {
    value: 'Bambui',
    name: 'Bambuí',
    uf: 'MG'
  },
  {
    value: 'Bandeira',
    name: 'Bandeira',
    uf: 'MG'
  },
  {
    value: 'Bandeira do Sul',
    name: 'Bandeira do Sul',
    uf: 'MG'
  },
  {
    value: 'Barao de Cocais',
    name: 'Barão de Cocais',
    uf: 'MG'
  },
  {
    value: 'Barao de Monte Alto',
    name: 'Barão de Monte Alto',
    uf: 'MG'
  },
  {
    value: 'Barbacena',
    name: 'Barbacena',
    uf: 'MG'
  },
  {
    value: 'Barra Longa',
    name: 'Barra Longa',
    uf: 'MG'
  },
  {
    value: 'Tres Marias',
    name: 'Três Marias',
    uf: 'MG'
  },
  {
    value: 'Barroso',
    name: 'Barroso',
    uf: 'MG'
  },
  {
    value: 'Bela Vista de Minas',
    name: 'Bela Vista de Minas',
    uf: 'MG'
  },
  {
    value: 'Belmiro Braga',
    name: 'Belmiro Braga',
    uf: 'MG'
  },
  {
    value: 'Belo Horizonte',
    name: 'Belo Horizonte',
    uf: 'MG'
  },
  {
    value: 'Belo Oriente',
    name: 'Belo Oriente',
    uf: 'MG'
  },
  {
    value: 'Belo Vale',
    name: 'Belo Vale',
    uf: 'MG'
  },
  {
    value: 'Berilo',
    name: 'Berilo',
    uf: 'MG'
  },
  {
    value: 'Bertopolis',
    name: 'Bertópolis',
    uf: 'MG'
  },
  {
    value: 'Betim',
    name: 'Betim',
    uf: 'MG'
  },
  {
    value: 'Bias Fortes',
    name: 'Bias Fortes',
    uf: 'MG'
  },
  {
    value: 'Bicas',
    name: 'Bicas',
    uf: 'MG'
  },
  {
    value: 'Biquinhas',
    name: 'Biquinhas',
    uf: 'MG'
  },
  {
    value: 'Boa Esperanca',
    name: 'Boa Esperança',
    uf: 'MG'
  },
  {
    value: 'Bocaina de Minas',
    name: 'Bocaina de Minas',
    uf: 'MG'
  },
  {
    value: 'Bocaiuva',
    name: 'Bocaiúva',
    uf: 'MG'
  },
  {
    value: 'Bom Despacho',
    name: 'Bom Despacho',
    uf: 'MG'
  },
  {
    value: 'Bom Jardim de Minas',
    name: 'Bom Jardim de Minas',
    uf: 'MG'
  },
  {
    value: 'Bom Jesus da Penha',
    name: 'Bom Jesus da Penha',
    uf: 'MG'
  },
  {
    value: 'Bom Jesus do Amparo',
    name: 'Bom Jesus do Amparo',
    uf: 'MG'
  },
  {
    value: 'Bom Jesus do Galho',
    name: 'Bom Jesus do Galho',
    uf: 'MG'
  },
  {
    value: 'Bom Repouso',
    name: 'Bom Repouso',
    uf: 'MG'
  },
  {
    value: 'Bom Sucesso',
    name: 'Bom Sucesso',
    uf: 'MG'
  },
  {
    value: 'Bonfim',
    name: 'Bonfim',
    uf: 'MG'
  },
  {
    value: 'Bonfinopolis de Minas',
    name: 'Bonfinópolis de Minas',
    uf: 'MG'
  },
  {
    value: 'Borda da Mata',
    name: 'Borda da Mata',
    uf: 'MG'
  },
  {
    value: 'Botelhos',
    name: 'Botelhos',
    uf: 'MG'
  },
  {
    value: 'Botumirim',
    name: 'Botumirim',
    uf: 'MG'
  },
  {
    value: 'Brasilia de Minas',
    name: 'Brasília de Minas',
    uf: 'MG'
  },
  {
    value: 'Bras Pires',
    name: 'Brás Pires',
    uf: 'MG'
  },
  {
    value: 'Braunas',
    name: 'Braúnas',
    uf: 'MG'
  },
  {
    value: 'Brazopolis',
    name: 'Brazópolis',
    uf: 'MG'
  },
  {
    value: 'Brumadinho',
    name: 'Brumadinho',
    uf: 'MG'
  },
  {
    value: 'Bueno Brandao',
    name: 'Bueno Brandão',
    uf: 'MG'
  },
  {
    value: 'Buenopolis',
    name: 'Buenópolis',
    uf: 'MG'
  },
  {
    value: 'Buritis',
    name: 'Buritis',
    uf: 'MG'
  },
  {
    value: 'Buritizeiro',
    name: 'Buritizeiro',
    uf: 'MG'
  },
  {
    value: 'Cabo Verde',
    name: 'Cabo Verde',
    uf: 'MG'
  },
  {
    value: 'Cachoeira da Prata',
    name: 'Cachoeira da Prata',
    uf: 'MG'
  },
  {
    value: 'Cachoeira de Minas',
    name: 'Cachoeira de Minas',
    uf: 'MG'
  },
  {
    value: 'Cachoeira Dourada',
    name: 'Cachoeira Dourada',
    uf: 'MG'
  },
  {
    value: 'Caetanopolis',
    name: 'Caetanópolis',
    uf: 'MG'
  },
  {
    value: 'Caete',
    name: 'Caeté',
    uf: 'MG'
  },
  {
    value: 'Caiana',
    name: 'Caiana',
    uf: 'MG'
  },
  {
    value: 'Cajuri',
    name: 'Cajuri',
    uf: 'MG'
  },
  {
    value: 'Caldas',
    name: 'Caldas',
    uf: 'MG'
  },
  {
    value: 'Camacho',
    name: 'Camacho',
    uf: 'MG'
  },
  {
    value: 'Camanducaia',
    name: 'Camanducaia',
    uf: 'MG'
  },
  {
    value: 'Cambui',
    name: 'Cambuí',
    uf: 'MG'
  },
  {
    value: 'Cambuquira',
    name: 'Cambuquira',
    uf: 'MG'
  },
  {
    value: 'Campanario',
    name: 'Campanário',
    uf: 'MG'
  },
  {
    value: 'Campanha',
    name: 'Campanha',
    uf: 'MG'
  },
  {
    value: 'Campestre',
    name: 'Campestre',
    uf: 'MG'
  },
  {
    value: 'Campina Verde',
    name: 'Campina Verde',
    uf: 'MG'
  },
  {
    value: 'Campo Belo',
    name: 'Campo Belo',
    uf: 'MG'
  },
  {
    value: 'Campo do Meio',
    name: 'Campo do Meio',
    uf: 'MG'
  },
  {
    value: 'Campo Florido',
    name: 'Campo Florido',
    uf: 'MG'
  },
  {
    value: 'Campos Altos',
    name: 'Campos Altos',
    uf: 'MG'
  },
  {
    value: 'Campos Gerais',
    name: 'Campos Gerais',
    uf: 'MG'
  },
  {
    value: 'Canaa',
    name: 'Canaã',
    uf: 'MG'
  },
  {
    value: 'Canapolis',
    name: 'Canápolis',
    uf: 'MG'
  },
  {
    value: 'Cana Verde',
    name: 'Cana Verde',
    uf: 'MG'
  },
  {
    value: 'Candeias',
    name: 'Candeias',
    uf: 'MG'
  },
  {
    value: 'Caparao',
    name: 'Caparaó',
    uf: 'MG'
  },
  {
    value: 'Capela Nova',
    name: 'Capela Nova',
    uf: 'MG'
  },
  {
    value: 'Capelinha',
    name: 'Capelinha',
    uf: 'MG'
  },
  {
    value: 'Capetinga',
    name: 'Capetinga',
    uf: 'MG'
  },
  {
    value: 'Capim Branco',
    name: 'Capim Branco',
    uf: 'MG'
  },
  {
    value: 'Capinopolis',
    name: 'Capinópolis',
    uf: 'MG'
  },
  {
    value: 'Capitao Eneas',
    name: 'Capitão Enéas',
    uf: 'MG'
  },
  {
    value: 'Capitolio',
    name: 'Capitólio',
    uf: 'MG'
  },
  {
    value: 'Caputira',
    name: 'Caputira',
    uf: 'MG'
  },
  {
    value: 'Carai',
    name: 'Caraí',
    uf: 'MG'
  },
  {
    value: 'Caranaiba',
    name: 'Caranaíba',
    uf: 'MG'
  },
  {
    value: 'Carandai',
    name: 'Carandaí',
    uf: 'MG'
  },
  {
    value: 'Carangola',
    name: 'Carangola',
    uf: 'MG'
  },
  {
    value: 'Caratinga',
    name: 'Caratinga',
    uf: 'MG'
  },
  {
    value: 'Carbonita',
    name: 'Carbonita',
    uf: 'MG'
  },
  {
    value: 'Careacu',
    name: 'Careaçu',
    uf: 'MG'
  },
  {
    value: 'Carlos Chagas',
    name: 'Carlos Chagas',
    uf: 'MG'
  },
  {
    value: 'Carmesia',
    name: 'Carmésia',
    uf: 'MG'
  },
  {
    value: 'Carmo da Cachoeira',
    name: 'Carmo da Cachoeira',
    uf: 'MG'
  },
  {
    value: 'Carmo da Mata',
    name: 'Carmo da Mata',
    uf: 'MG'
  },
  {
    value: 'Carmo de Minas',
    name: 'Carmo de Minas',
    uf: 'MG'
  },
  {
    value: 'Carmo do Cajuru',
    name: 'Carmo do Cajuru',
    uf: 'MG'
  },
  {
    value: 'Carmo do Paranaiba',
    name: 'Carmo do Paranaíba',
    uf: 'MG'
  },
  {
    value: 'Carmo do Rio Claro',
    name: 'Carmo do Rio Claro',
    uf: 'MG'
  },
  {
    value: 'Carmopolis de Minas',
    name: 'Carmópolis de Minas',
    uf: 'MG'
  },
  {
    value: 'Carrancas',
    name: 'Carrancas',
    uf: 'MG'
  },
  {
    value: 'Carvalhopolis',
    name: 'Carvalhópolis',
    uf: 'MG'
  },
  {
    value: 'Carvalhos',
    name: 'Carvalhos',
    uf: 'MG'
  },
  {
    value: 'Casa Grande',
    name: 'Casa Grande',
    uf: 'MG'
  },
  {
    value: 'Cascalho Rico',
    name: 'Cascalho Rico',
    uf: 'MG'
  },
  {
    value: 'Cassia',
    name: 'Cássia',
    uf: 'MG'
  },
  {
    value: 'Conceicao da Barra de Minas',
    name: 'Conceição da Barra de Minas',
    uf: 'MG'
  },
  {
    value: 'Cataguases',
    name: 'Cataguases',
    uf: 'MG'
  },
  {
    value: 'Catas Altas da Noruega',
    name: 'Catas Altas da Noruega',
    uf: 'MG'
  },
  {
    value: 'Caxambu',
    name: 'Caxambu',
    uf: 'MG'
  },
  {
    value: 'Cedro do Abaete',
    name: 'Cedro do Abaeté',
    uf: 'MG'
  },
  {
    value: 'Central de Minas',
    name: 'Central de Minas',
    uf: 'MG'
  },
  {
    value: 'Centralina',
    name: 'Centralina',
    uf: 'MG'
  },
  {
    value: 'Chacara',
    name: 'Chácara',
    uf: 'MG'
  },
  {
    value: 'Chale',
    name: 'Chalé',
    uf: 'MG'
  },
  {
    value: 'Chapada do Norte',
    name: 'Chapada do Norte',
    uf: 'MG'
  },
  {
    value: 'Chiador',
    name: 'Chiador',
    uf: 'MG'
  },
  {
    value: 'Cipotanea',
    name: 'Cipotânea',
    uf: 'MG'
  },
  {
    value: 'Claraval',
    name: 'Claraval',
    uf: 'MG'
  },
  {
    value: 'Claro dos Pocoes',
    name: 'Claro dos Poções',
    uf: 'MG'
  },
  {
    value: 'Claudio',
    name: 'Cláudio',
    uf: 'MG'
  },
  {
    value: 'Coimbra',
    name: 'Coimbra',
    uf: 'MG'
  },
  {
    value: 'Coluna',
    name: 'Coluna',
    uf: 'MG'
  },
  {
    value: 'Comendador Gomes',
    name: 'Comendador Gomes',
    uf: 'MG'
  },
  {
    value: 'Comercinho',
    name: 'Comercinho',
    uf: 'MG'
  },
  {
    value: 'Conceicao da Aparecida',
    name: 'Conceição da Aparecida',
    uf: 'MG'
  },
  {
    value: 'Conceicao das Pedras',
    name: 'Conceição das Pedras',
    uf: 'MG'
  },
  {
    value: 'Conceicao das Alagoas',
    name: 'Conceição das Alagoas',
    uf: 'MG'
  },
  {
    value: 'Conceicao de Ipanema',
    name: 'Conceição de Ipanema',
    uf: 'MG'
  },
  {
    value: 'Conceicao do Mato Dentro',
    name: 'Conceição do Mato Dentro',
    uf: 'MG'
  },
  {
    value: 'Conceicao do Para',
    name: 'Conceição do Pará',
    uf: 'MG'
  },
  {
    value: 'Conceicao do Rio Verde',
    name: 'Conceição do Rio Verde',
    uf: 'MG'
  },
  {
    value: 'Conceicao dos Ouros',
    name: 'Conceição dos Ouros',
    uf: 'MG'
  },
  {
    value: 'Congonhal',
    name: 'Congonhal',
    uf: 'MG'
  },
  {
    value: 'Congonhas',
    name: 'Congonhas',
    uf: 'MG'
  },
  {
    value: 'Congonhas do Norte',
    name: 'Congonhas do Norte',
    uf: 'MG'
  },
  {
    value: 'Conquista',
    name: 'Conquista',
    uf: 'MG'
  },
  {
    value: 'Conselheiro Lafaiete',
    name: 'Conselheiro Lafaiete',
    uf: 'MG'
  },
  {
    value: 'Conselheiro Pena',
    name: 'Conselheiro Pena',
    uf: 'MG'
  },
  {
    value: 'Consolacao',
    name: 'Consolação',
    uf: 'MG'
  },
  {
    value: 'Contagem',
    name: 'Contagem',
    uf: 'MG'
  },
  {
    value: 'Coqueiral',
    name: 'Coqueiral',
    uf: 'MG'
  },
  {
    value: 'Coracao de Jesus',
    name: 'Coração de Jesus',
    uf: 'MG'
  },
  {
    value: 'Cordisburgo',
    name: 'Cordisburgo',
    uf: 'MG'
  },
  {
    value: 'Cordislandia',
    name: 'Cordislândia',
    uf: 'MG'
  },
  {
    value: 'Corinto',
    name: 'Corinto',
    uf: 'MG'
  },
  {
    value: 'Coroaci',
    name: 'Coroaci',
    uf: 'MG'
  },
  {
    value: 'Coromandel',
    name: 'Coromandel',
    uf: 'MG'
  },
  {
    value: 'Coronel Fabriciano',
    name: 'Coronel Fabriciano',
    uf: 'MG'
  },
  {
    value: 'Coronel Murta',
    name: 'Coronel Murta',
    uf: 'MG'
  },
  {
    value: 'Coronel Pacheco',
    name: 'Coronel Pacheco',
    uf: 'MG'
  },
  {
    value: 'Coronel Xavier Chaves',
    name: 'Coronel Xavier Chaves',
    uf: 'MG'
  },
  {
    value: 'Corrego Danta',
    name: 'Córrego Danta',
    uf: 'MG'
  },
  {
    value: 'Corrego do Bom Jesus',
    name: 'Córrego do Bom Jesus',
    uf: 'MG'
  },
  {
    value: 'Corrego Novo',
    name: 'Córrego Novo',
    uf: 'MG'
  },
  {
    value: 'Couto de Magalhaes de Minas',
    name: 'Couto de Magalhães de Minas',
    uf: 'MG'
  },
  {
    value: 'Cristais',
    name: 'Cristais',
    uf: 'MG'
  },
  {
    value: 'Cristalia',
    name: 'Cristália',
    uf: 'MG'
  },
  {
    value: 'Cristiano Otoni',
    name: 'Cristiano Otoni',
    uf: 'MG'
  },
  {
    value: 'Cristina',
    name: 'Cristina',
    uf: 'MG'
  },
  {
    value: 'Crucilandia',
    name: 'Crucilândia',
    uf: 'MG'
  },
  {
    value: 'Cruzeiro da Fortaleza',
    name: 'Cruzeiro da Fortaleza',
    uf: 'MG'
  },
  {
    value: 'Cruzilia',
    name: 'Cruzília',
    uf: 'MG'
  },
  {
    value: 'Curvelo',
    name: 'Curvelo',
    uf: 'MG'
  },
  {
    value: 'Datas',
    name: 'Datas',
    uf: 'MG'
  },
  {
    value: 'Delfim Moreira',
    name: 'Delfim Moreira',
    uf: 'MG'
  },
  {
    value: 'Delfinopolis',
    name: 'Delfinópolis',
    uf: 'MG'
  },
  {
    value: 'Descoberto',
    name: 'Descoberto',
    uf: 'MG'
  },
  {
    value: 'Desterro de Entre Rios',
    name: 'Desterro de Entre Rios',
    uf: 'MG'
  },
  {
    value: 'Desterro do Melo',
    name: 'Desterro do Melo',
    uf: 'MG'
  },
  {
    value: 'Diamantina',
    name: 'Diamantina',
    uf: 'MG'
  },
  {
    value: 'Diogo de Vasconcelos',
    name: 'Diogo de Vasconcelos',
    uf: 'MG'
  },
  {
    value: 'Dionisio',
    name: 'Dionísio',
    uf: 'MG'
  },
  {
    value: 'Divinesia',
    name: 'Divinésia',
    uf: 'MG'
  },
  {
    value: 'Divino',
    name: 'Divino',
    uf: 'MG'
  },
  {
    value: 'Divino das Laranjeiras',
    name: 'Divino das Laranjeiras',
    uf: 'MG'
  },
  {
    value: 'Divinolandia de Minas',
    name: 'Divinolândia de Minas',
    uf: 'MG'
  },
  {
    value: 'Divinopolis',
    name: 'Divinópolis',
    uf: 'MG'
  },
  {
    value: 'Divisa Nova',
    name: 'Divisa Nova',
    uf: 'MG'
  },
  {
    value: 'Dom Cavati',
    name: 'Dom Cavati',
    uf: 'MG'
  },
  {
    value: 'Dom Joaquim',
    name: 'Dom Joaquim',
    uf: 'MG'
  },
  {
    value: 'Dom Silverio',
    name: 'Dom Silvério',
    uf: 'MG'
  },
  {
    value: 'Dom Vicoso',
    name: 'Dom Viçoso',
    uf: 'MG'
  },
  {
    value: 'Dona Euzebia',
    name: 'Dona Euzébia',
    uf: 'MG'
  },
  {
    value: 'Dores de Campos',
    name: 'Dores de Campos',
    uf: 'MG'
  },
  {
    value: 'Dores de Guanhaes',
    name: 'Dores de Guanhães',
    uf: 'MG'
  },
  {
    value: 'Dores do Indaia',
    name: 'Dores do Indaiá',
    uf: 'MG'
  },
  {
    value: 'Dores do Turvo',
    name: 'Dores do Turvo',
    uf: 'MG'
  },
  {
    value: 'Doresopolis',
    name: 'Doresópolis',
    uf: 'MG'
  },
  {
    value: 'Douradoquara',
    name: 'Douradoquara',
    uf: 'MG'
  },
  {
    value: 'Eloi Mendes',
    name: 'Elói Mendes',
    uf: 'MG'
  },
  {
    value: 'Engenheiro Caldas',
    name: 'Engenheiro Caldas',
    uf: 'MG'
  },
  {
    value: 'Engenheiro Navarro',
    name: 'Engenheiro Navarro',
    uf: 'MG'
  },
  {
    value: 'Entre Rios de Minas',
    name: 'Entre Rios de Minas',
    uf: 'MG'
  },
  {
    value: 'Ervalia',
    name: 'Ervália',
    uf: 'MG'
  },
  {
    value: 'Esmeraldas',
    name: 'Esmeraldas',
    uf: 'MG'
  },
  {
    value: 'Espera Feliz',
    name: 'Espera Feliz',
    uf: 'MG'
  },
  {
    value: 'Espinosa',
    name: 'Espinosa',
    uf: 'MG'
  },
  {
    value: 'Espirito Santo do Dourado',
    name: 'Espírito Santo do Dourado',
    uf: 'MG'
  },
  {
    value: 'Estiva',
    name: 'Estiva',
    uf: 'MG'
  },
  {
    value: 'Estrela Dalva',
    name: 'Estrela Dalva',
    uf: 'MG'
  },
  {
    value: 'Estrela do Indaia',
    name: 'Estrela do Indaiá',
    uf: 'MG'
  },
  {
    value: 'Estrela do Sul',
    name: 'Estrela do Sul',
    uf: 'MG'
  },
  {
    value: 'Eugenopolis',
    name: 'Eugenópolis',
    uf: 'MG'
  },
  {
    value: 'Ewbank da Camara',
    name: 'Ewbank da Câmara',
    uf: 'MG'
  },
  {
    value: 'Extrema',
    name: 'Extrema',
    uf: 'MG'
  },
  {
    value: 'Fama',
    name: 'Fama',
    uf: 'MG'
  },
  {
    value: 'Faria Lemos',
    name: 'Faria Lemos',
    uf: 'MG'
  },
  {
    value: 'Felicio dos Santos',
    name: 'Felício dos Santos',
    uf: 'MG'
  },
  {
    value: 'Sao Goncalo do Rio Preto',
    name: 'São Gonçalo do Rio Preto',
    uf: 'MG'
  },
  {
    value: 'Felisburgo',
    name: 'Felisburgo',
    uf: 'MG'
  },
  {
    value: 'Felixlandia',
    name: 'Felixlândia',
    uf: 'MG'
  },
  {
    value: 'Fernandes Tourinho',
    name: 'Fernandes Tourinho',
    uf: 'MG'
  },
  {
    value: 'Ferros',
    name: 'Ferros',
    uf: 'MG'
  },
  {
    value: 'Florestal',
    name: 'Florestal',
    uf: 'MG'
  },
  {
    value: 'Formiga',
    name: 'Formiga',
    uf: 'MG'
  },
  {
    value: 'Formoso',
    name: 'Formoso',
    uf: 'MG'
  },
  {
    value: 'Fortaleza de Minas',
    name: 'Fortaleza de Minas',
    uf: 'MG'
  },
  {
    value: 'Fortuna de Minas',
    name: 'Fortuna de Minas',
    uf: 'MG'
  },
  {
    value: 'Francisco Badaro',
    name: 'Francisco Badaró',
    uf: 'MG'
  },
  {
    value: 'Francisco Dumont',
    name: 'Francisco Dumont',
    uf: 'MG'
  },
  {
    value: 'Francisco Sa',
    name: 'Francisco Sá',
    uf: 'MG'
  },
  {
    value: 'Frei Gaspar',
    name: 'Frei Gaspar',
    uf: 'MG'
  },
  {
    value: 'Frei Inocencio',
    name: 'Frei Inocêncio',
    uf: 'MG'
  },
  {
    value: 'Fronteira',
    name: 'Fronteira',
    uf: 'MG'
  },
  {
    value: 'Frutal',
    name: 'Frutal',
    uf: 'MG'
  },
  {
    value: 'Funilandia',
    name: 'Funilândia',
    uf: 'MG'
  },
  {
    value: 'Galileia',
    name: 'Galiléia',
    uf: 'MG'
  },
  {
    value: 'Goncalves',
    name: 'Gonçalves',
    uf: 'MG'
  },
  {
    value: 'Gonzaga',
    name: 'Gonzaga',
    uf: 'MG'
  },
  {
    value: 'Gouveia',
    name: 'Gouveia',
    uf: 'MG'
  },
  {
    value: 'Governador Valadares',
    name: 'Governador Valadares',
    uf: 'MG'
  },
  {
    value: 'Grao Mogol',
    name: 'Grão Mogol',
    uf: 'MG'
  },
  {
    value: 'Grupiara',
    name: 'Grupiara',
    uf: 'MG'
  },
  {
    value: 'Guanhaes',
    name: 'Guanhães',
    uf: 'MG'
  },
  {
    value: 'Guape',
    name: 'Guapé',
    uf: 'MG'
  },
  {
    value: 'Guaraciaba',
    name: 'Guaraciaba',
    uf: 'MG'
  },
  {
    value: 'Guaranesia',
    name: 'Guaranésia',
    uf: 'MG'
  },
  {
    value: 'Guarani',
    name: 'Guarani',
    uf: 'MG'
  },
  {
    value: 'Guarara',
    name: 'Guarará',
    uf: 'MG'
  },
  {
    value: 'Guarda-Mor',
    name: 'Guarda-Mor',
    uf: 'MG'
  },
  {
    value: 'Guaxupe',
    name: 'Guaxupé',
    uf: 'MG'
  },
  {
    value: 'Guidoval',
    name: 'Guidoval',
    uf: 'MG'
  },
  {
    value: 'Guimarania',
    name: 'Guimarânia',
    uf: 'MG'
  },
  {
    value: 'Guiricema',
    name: 'Guiricema',
    uf: 'MG'
  },
  {
    value: 'Gurinhata',
    name: 'Gurinhatã',
    uf: 'MG'
  },
  {
    value: 'Heliodora',
    name: 'Heliodora',
    uf: 'MG'
  },
  {
    value: 'Iapu',
    name: 'Iapu',
    uf: 'MG'
  },
  {
    value: 'Ibertioga',
    name: 'Ibertioga',
    uf: 'MG'
  },
  {
    value: 'Ibia',
    name: 'Ibiá',
    uf: 'MG'
  },
  {
    value: 'Ibiai',
    name: 'Ibiaí',
    uf: 'MG'
  },
  {
    value: 'Ibiraci',
    name: 'Ibiraci',
    uf: 'MG'
  },
  {
    value: 'Ibirite',
    name: 'Ibirité',
    uf: 'MG'
  },
  {
    value: 'Ibitiura de Minas',
    name: 'Ibitiúra de Minas',
    uf: 'MG'
  },
  {
    value: 'Ibituruna',
    name: 'Ibituruna',
    uf: 'MG'
  },
  {
    value: 'Igarape',
    name: 'Igarapé',
    uf: 'MG'
  },
  {
    value: 'Igaratinga',
    name: 'Igaratinga',
    uf: 'MG'
  },
  {
    value: 'Iguatama',
    name: 'Iguatama',
    uf: 'MG'
  },
  {
    value: 'Ijaci',
    name: 'Ijaci',
    uf: 'MG'
  },
  {
    value: 'Ilicinea',
    name: 'Ilicínea',
    uf: 'MG'
  },
  {
    value: 'Inconfidentes',
    name: 'Inconfidentes',
    uf: 'MG'
  },
  {
    value: 'Indianopolis',
    name: 'Indianópolis',
    uf: 'MG'
  },
  {
    value: 'Ingai',
    name: 'Ingaí',
    uf: 'MG'
  },
  {
    value: 'Inhapim',
    name: 'Inhapim',
    uf: 'MG'
  },
  {
    value: 'Inhauma',
    name: 'Inhaúma',
    uf: 'MG'
  },
  {
    value: 'Inimutaba',
    name: 'Inimutaba',
    uf: 'MG'
  },
  {
    value: 'Ipanema',
    name: 'Ipanema',
    uf: 'MG'
  },
  {
    value: 'Ipatinga',
    name: 'Ipatinga',
    uf: 'MG'
  },
  {
    value: 'Ipiacu',
    name: 'Ipiaçu',
    uf: 'MG'
  },
  {
    value: 'Ipuiuna',
    name: 'Ipuiúna',
    uf: 'MG'
  },
  {
    value: 'Irai de Minas',
    name: 'Iraí de Minas',
    uf: 'MG'
  },
  {
    value: 'Itabira',
    name: 'Itabira',
    uf: 'MG'
  },
  {
    value: 'Itabirinha',
    name: 'Itabirinha',
    uf: 'MG'
  },
  {
    value: 'Itabirito',
    name: 'Itabirito',
    uf: 'MG'
  },
  {
    value: 'Itacambira',
    name: 'Itacambira',
    uf: 'MG'
  },
  {
    value: 'Itacarambi',
    name: 'Itacarambi',
    uf: 'MG'
  },
  {
    value: 'Itaguara',
    name: 'Itaguara',
    uf: 'MG'
  },
  {
    value: 'Itaipe',
    name: 'Itaipé',
    uf: 'MG'
  },
  {
    value: 'Itajuba',
    name: 'Itajubá',
    uf: 'MG'
  },
  {
    value: 'Itamarandiba',
    name: 'Itamarandiba',
    uf: 'MG'
  },
  {
    value: 'Itamarati de Minas',
    name: 'Itamarati de Minas',
    uf: 'MG'
  },
  {
    value: 'Itambacuri',
    name: 'Itambacuri',
    uf: 'MG'
  },
  {
    value: 'Itambe do Mato Dentro',
    name: 'Itambé do Mato Dentro',
    uf: 'MG'
  },
  {
    value: 'Itamogi',
    name: 'Itamogi',
    uf: 'MG'
  },
  {
    value: 'Itamonte',
    name: 'Itamonte',
    uf: 'MG'
  },
  {
    value: 'Itanhandu',
    name: 'Itanhandu',
    uf: 'MG'
  },
  {
    value: 'Itanhomi',
    name: 'Itanhomi',
    uf: 'MG'
  },
  {
    value: 'Itaobim',
    name: 'Itaobim',
    uf: 'MG'
  },
  {
    value: 'Itapagipe',
    name: 'Itapagipe',
    uf: 'MG'
  },
  {
    value: 'Itapecerica',
    name: 'Itapecerica',
    uf: 'MG'
  },
  {
    value: 'Itapeva',
    name: 'Itapeva',
    uf: 'MG'
  },
  {
    value: 'Itatiaiucu',
    name: 'Itatiaiuçu',
    uf: 'MG'
  },
  {
    value: 'Itauna',
    name: 'Itaúna',
    uf: 'MG'
  },
  {
    value: 'Itaverava',
    name: 'Itaverava',
    uf: 'MG'
  },
  {
    value: 'Itinga',
    name: 'Itinga',
    uf: 'MG'
  },
  {
    value: 'Itueta',
    name: 'Itueta',
    uf: 'MG'
  },
  {
    value: 'Ituiutaba',
    name: 'Ituiutaba',
    uf: 'MG'
  },
  {
    value: 'Itumirim',
    name: 'Itumirim',
    uf: 'MG'
  },
  {
    value: 'Iturama',
    name: 'Iturama',
    uf: 'MG'
  },
  {
    value: 'Itutinga',
    name: 'Itutinga',
    uf: 'MG'
  },
  {
    value: 'Jaboticatubas',
    name: 'Jaboticatubas',
    uf: 'MG'
  },
  {
    value: 'Jacinto',
    name: 'Jacinto',
    uf: 'MG'
  },
  {
    value: 'Jacui',
    name: 'Jacuí',
    uf: 'MG'
  },
  {
    value: 'Jacutinga',
    name: 'Jacutinga',
    uf: 'MG'
  },
  {
    value: 'Jaguaracu',
    name: 'Jaguaraçu',
    uf: 'MG'
  },
  {
    value: 'Janauba',
    name: 'Janaúba',
    uf: 'MG'
  },
  {
    value: 'Januaria',
    name: 'Januária',
    uf: 'MG'
  },
  {
    value: 'Japaraiba',
    name: 'Japaraíba',
    uf: 'MG'
  },
  {
    value: 'Jeceaba',
    name: 'Jeceaba',
    uf: 'MG'
  },
  {
    value: 'Jequeri',
    name: 'Jequeri',
    uf: 'MG'
  },
  {
    value: 'Jequitai',
    name: 'Jequitaí',
    uf: 'MG'
  },
  {
    value: 'Jequitiba',
    name: 'Jequitibá',
    uf: 'MG'
  },
  {
    value: 'Jequitinhonha',
    name: 'Jequitinhonha',
    uf: 'MG'
  },
  {
    value: 'Jesuania',
    name: 'Jesuânia',
    uf: 'MG'
  },
  {
    value: 'Joaima',
    name: 'Joaíma',
    uf: 'MG'
  },
  {
    value: 'Joanesia',
    name: 'Joanésia',
    uf: 'MG'
  },
  {
    value: 'Joao Monlevade',
    name: 'João Monlevade',
    uf: 'MG'
  },
  {
    value: 'Joao Pinheiro',
    name: 'João Pinheiro',
    uf: 'MG'
  },
  {
    value: 'Joaquim Felicio',
    name: 'Joaquim Felício',
    uf: 'MG'
  },
  {
    value: 'Jordania',
    name: 'Jordânia',
    uf: 'MG'
  },
  {
    value: 'Nova Uniao',
    name: 'Nova União',
    uf: 'MG'
  },
  {
    value: 'Juiz de Fora',
    name: 'Juiz de Fora',
    uf: 'MG'
  },
  {
    value: 'Juramento',
    name: 'Juramento',
    uf: 'MG'
  },
  {
    value: 'Juruaia',
    name: 'Juruaia',
    uf: 'MG'
  },
  {
    value: 'Ladainha',
    name: 'Ladainha',
    uf: 'MG'
  },
  {
    value: 'Lagamar',
    name: 'Lagamar',
    uf: 'MG'
  },
  {
    value: 'Lagoa da Prata',
    name: 'Lagoa da Prata',
    uf: 'MG'
  },
  {
    value: 'Lagoa dos Patos',
    name: 'Lagoa dos Patos',
    uf: 'MG'
  },
  {
    value: 'Lagoa Dourada',
    name: 'Lagoa Dourada',
    uf: 'MG'
  },
  {
    value: 'Lagoa Formosa',
    name: 'Lagoa Formosa',
    uf: 'MG'
  },
  {
    value: 'Lagoa Santa',
    name: 'Lagoa Santa',
    uf: 'MG'
  },
  {
    value: 'Lajinha',
    name: 'Lajinha',
    uf: 'MG'
  },
  {
    value: 'Lambari',
    name: 'Lambari',
    uf: 'MG'
  },
  {
    value: 'Lamim',
    name: 'Lamim',
    uf: 'MG'
  },
  {
    value: 'Laranjal',
    name: 'Laranjal',
    uf: 'MG'
  },
  {
    value: 'Lassance',
    name: 'Lassance',
    uf: 'MG'
  },
  {
    value: 'Lavras',
    name: 'Lavras',
    uf: 'MG'
  },
  {
    value: 'Leandro Ferreira',
    name: 'Leandro Ferreira',
    uf: 'MG'
  },
  {
    value: 'Leopoldina',
    name: 'Leopoldina',
    uf: 'MG'
  },
  {
    value: 'Liberdade',
    name: 'Liberdade',
    uf: 'MG'
  },
  {
    value: 'Lima Duarte',
    name: 'Lima Duarte',
    uf: 'MG'
  },
  {
    value: 'Luminarias',
    name: 'Luminárias',
    uf: 'MG'
  },
  {
    value: 'Luz',
    name: 'Luz',
    uf: 'MG'
  },
  {
    value: 'Machacalis',
    name: 'Machacalis',
    uf: 'MG'
  },
  {
    value: 'Machado',
    name: 'Machado',
    uf: 'MG'
  },
  {
    value: 'Madre de Deus de Minas',
    name: 'Madre de Deus de Minas',
    uf: 'MG'
  },
  {
    value: 'Malacacheta',
    name: 'Malacacheta',
    uf: 'MG'
  },
  {
    value: 'Manga',
    name: 'Manga',
    uf: 'MG'
  },
  {
    value: 'Manhuacu',
    name: 'Manhuaçu',
    uf: 'MG'
  },
  {
    value: 'Manhumirim',
    name: 'Manhumirim',
    uf: 'MG'
  },
  {
    value: 'Mantena',
    name: 'Mantena',
    uf: 'MG'
  },
  {
    value: 'Maravilhas',
    name: 'Maravilhas',
    uf: 'MG'
  },
  {
    value: 'Mar de Espanha',
    name: 'Mar de Espanha',
    uf: 'MG'
  },
  {
    value: 'Maria da Fe',
    name: 'Maria da Fé',
    uf: 'MG'
  },
  {
    value: 'Mariana',
    name: 'Mariana',
    uf: 'MG'
  },
  {
    value: 'Marilac',
    name: 'Marilac',
    uf: 'MG'
  },
  {
    value: 'Maripa de Minas',
    name: 'Maripá de Minas',
    uf: 'MG'
  },
  {
    value: 'Marlieria',
    name: 'Marliéria',
    uf: 'MG'
  },
  {
    value: 'Marmelopolis',
    name: 'Marmelópolis',
    uf: 'MG'
  },
  {
    value: 'Martinho Campos',
    name: 'Martinho Campos',
    uf: 'MG'
  },
  {
    value: 'Materlandia',
    name: 'Materlândia',
    uf: 'MG'
  },
  {
    value: 'Mateus Leme',
    name: 'Mateus Leme',
    uf: 'MG'
  },
  {
    value: 'Matias Barbosa',
    name: 'Matias Barbosa',
    uf: 'MG'
  },
  {
    value: 'Matipo',
    name: 'Matipó',
    uf: 'MG'
  },
  {
    value: 'Mato Verde',
    name: 'Mato Verde',
    uf: 'MG'
  },
  {
    value: 'Matozinhos',
    name: 'Matozinhos',
    uf: 'MG'
  },
  {
    value: 'Matutina',
    name: 'Matutina',
    uf: 'MG'
  },
  {
    value: 'Medeiros',
    name: 'Medeiros',
    uf: 'MG'
  },
  {
    value: 'Medina',
    name: 'Medina',
    uf: 'MG'
  },
  {
    value: 'Mendes Pimentel',
    name: 'Mendes Pimentel',
    uf: 'MG'
  },
  {
    value: 'Merces',
    name: 'Mercês',
    uf: 'MG'
  },
  {
    value: 'Mesquita',
    name: 'Mesquita',
    uf: 'MG'
  },
  {
    value: 'Minas Novas',
    name: 'Minas Novas',
    uf: 'MG'
  },
  {
    value: 'Minduri',
    name: 'Minduri',
    uf: 'MG'
  },
  {
    value: 'Mirabela',
    name: 'Mirabela',
    uf: 'MG'
  },
  {
    value: 'Miradouro',
    name: 'Miradouro',
    uf: 'MG'
  },
  {
    value: 'Mirai',
    name: 'Miraí',
    uf: 'MG'
  },
  {
    value: 'Moeda',
    name: 'Moeda',
    uf: 'MG'
  },
  {
    value: 'Moema',
    name: 'Moema',
    uf: 'MG'
  },
  {
    value: 'Monjolos',
    name: 'Monjolos',
    uf: 'MG'
  },
  {
    value: 'Monsenhor Paulo',
    name: 'Monsenhor Paulo',
    uf: 'MG'
  },
  {
    value: 'Montalvania',
    name: 'Montalvânia',
    uf: 'MG'
  },
  {
    value: 'Monte Alegre de Minas',
    name: 'Monte Alegre de Minas',
    uf: 'MG'
  },
  {
    value: 'Monte Azul',
    name: 'Monte Azul',
    uf: 'MG'
  },
  {
    value: 'Monte Belo',
    name: 'Monte Belo',
    uf: 'MG'
  },
  {
    value: 'Monte Carmelo',
    name: 'Monte Carmelo',
    uf: 'MG'
  },
  {
    value: 'Monte Santo de Minas',
    name: 'Monte Santo de Minas',
    uf: 'MG'
  },
  {
    value: 'Montes Claros',
    name: 'Montes Claros',
    uf: 'MG'
  },
  {
    value: 'Monte Siao',
    name: 'Monte Sião',
    uf: 'MG'
  },
  {
    value: 'Morada Nova de Minas',
    name: 'Morada Nova de Minas',
    uf: 'MG'
  },
  {
    value: 'Morro da Garca',
    name: 'Morro da Garça',
    uf: 'MG'
  },
  {
    value: 'Morro do Pilar',
    name: 'Morro do Pilar',
    uf: 'MG'
  },
  {
    value: 'Munhoz',
    name: 'Munhoz',
    uf: 'MG'
  },
  {
    value: 'Muriae',
    name: 'Muriaé',
    uf: 'MG'
  },
  {
    value: 'Mutum',
    name: 'Mutum',
    uf: 'MG'
  },
  {
    value: 'Muzambinho',
    name: 'Muzambinho',
    uf: 'MG'
  },
  {
    value: 'Nacip Raydan',
    name: 'Nacip Raydan',
    uf: 'MG'
  },
  {
    value: 'Nanuque',
    name: 'Nanuque',
    uf: 'MG'
  },
  {
    value: 'Natercia',
    name: 'Natércia',
    uf: 'MG'
  },
  {
    value: 'Nazareno',
    name: 'Nazareno',
    uf: 'MG'
  },
  {
    value: 'Nepomuceno',
    name: 'Nepomuceno',
    uf: 'MG'
  },
  {
    value: 'Nova Era',
    name: 'Nova Era',
    uf: 'MG'
  },
  {
    value: 'Nova Lima',
    name: 'Nova Lima',
    uf: 'MG'
  },
  {
    value: 'Nova Modica',
    name: 'Nova Módica',
    uf: 'MG'
  },
  {
    value: 'Nova Ponte',
    name: 'Nova Ponte',
    uf: 'MG'
  },
  {
    value: 'Nova Resende',
    name: 'Nova Resende',
    uf: 'MG'
  },
  {
    value: 'Nova Serrana',
    name: 'Nova Serrana',
    uf: 'MG'
  },
  {
    value: 'Novo Cruzeiro',
    name: 'Novo Cruzeiro',
    uf: 'MG'
  },
  {
    value: 'Olaria',
    name: 'Olaria',
    uf: 'MG'
  },
  {
    value: 'Olimpio Noronha',
    name: 'Olímpio Noronha',
    uf: 'MG'
  },
  {
    value: 'Oliveira',
    name: 'Oliveira',
    uf: 'MG'
  },
  {
    value: 'Oliveira Fortes',
    name: 'Oliveira Fortes',
    uf: 'MG'
  },
  {
    value: 'Onca de Pitangui',
    name: 'Onça de Pitangui',
    uf: 'MG'
  },
  {
    value: 'Ouro Branco',
    name: 'Ouro Branco',
    uf: 'MG'
  },
  {
    value: 'Ouro Fino',
    name: 'Ouro Fino',
    uf: 'MG'
  },
  {
    value: 'Ouro Preto',
    name: 'Ouro Preto',
    uf: 'MG'
  },
  {
    value: 'Ouro Verde de Minas',
    name: 'Ouro Verde de Minas',
    uf: 'MG'
  },
  {
    value: 'Padre Paraiso',
    name: 'Padre Paraíso',
    uf: 'MG'
  },
  {
    value: 'Paineiras',
    name: 'Paineiras',
    uf: 'MG'
  },
  {
    value: 'Pains',
    name: 'Pains',
    uf: 'MG'
  },
  {
    value: 'Paiva',
    name: 'Paiva',
    uf: 'MG'
  },
  {
    value: 'Palma',
    name: 'Palma',
    uf: 'MG'
  },
  {
    value: 'Fronteira dos Vales',
    name: 'Fronteira dos Vales',
    uf: 'MG'
  },
  {
    value: 'Papagaios',
    name: 'Papagaios',
    uf: 'MG'
  },
  {
    value: 'Paracatu',
    name: 'Paracatu',
    uf: 'MG'
  },
  {
    value: 'Para de Minas',
    name: 'Pará de Minas',
    uf: 'MG'
  },
  {
    value: 'Paraguacu',
    name: 'Paraguaçu',
    uf: 'MG'
  },
  {
    value: 'Paraisopolis',
    name: 'Paraisópolis',
    uf: 'MG'
  },
  {
    value: 'Paraopeba',
    name: 'Paraopeba',
    uf: 'MG'
  },
  {
    value: 'Passabem',
    name: 'Passabém',
    uf: 'MG'
  },
  {
    value: 'Passa Quatro',
    name: 'Passa Quatro',
    uf: 'MG'
  },
  {
    value: 'Passa Tempo',
    name: 'Passa Tempo',
    uf: 'MG'
  },
  {
    value: 'Passa Vinte',
    name: 'Passa Vinte',
    uf: 'MG'
  },
  {
    value: 'Passos',
    name: 'Passos',
    uf: 'MG'
  },
  {
    value: 'Patos de Minas',
    name: 'Patos de Minas',
    uf: 'MG'
  },
  {
    value: 'Patrocinio',
    name: 'Patrocínio',
    uf: 'MG'
  },
  {
    value: 'Patrocinio do Muriae',
    name: 'Patrocínio do Muriaé',
    uf: 'MG'
  },
  {
    value: 'Paula Candido',
    name: 'Paula Cândido',
    uf: 'MG'
  },
  {
    value: 'Paulistas',
    name: 'Paulistas',
    uf: 'MG'
  },
  {
    value: 'Pavao',
    name: 'Pavão',
    uf: 'MG'
  },
  {
    value: 'Pecanha',
    name: 'Peçanha',
    uf: 'MG'
  },
  {
    value: 'Pedra Azul',
    name: 'Pedra Azul',
    uf: 'MG'
  },
  {
    value: 'Pedra do Anta',
    name: 'Pedra do Anta',
    uf: 'MG'
  },
  {
    value: 'Pedra do Indaia',
    name: 'Pedra do Indaiá',
    uf: 'MG'
  },
  {
    value: 'Pedra Dourada',
    name: 'Pedra Dourada',
    uf: 'MG'
  },
  {
    value: 'Pedralva',
    name: 'Pedralva',
    uf: 'MG'
  },
  {
    value: 'Pedrinopolis',
    name: 'Pedrinópolis',
    uf: 'MG'
  },
  {
    value: 'Pedro Leopoldo',
    name: 'Pedro Leopoldo',
    uf: 'MG'
  },
  {
    value: 'Pedro Teixeira',
    name: 'Pedro Teixeira',
    uf: 'MG'
  },
  {
    value: 'Pequeri',
    name: 'Pequeri',
    uf: 'MG'
  },
  {
    value: 'Pequi',
    name: 'Pequi',
    uf: 'MG'
  },
  {
    value: 'Perdigao',
    name: 'Perdigão',
    uf: 'MG'
  },
  {
    value: 'Perdizes',
    name: 'Perdizes',
    uf: 'MG'
  },
  {
    value: 'Perdoes',
    name: 'Perdões',
    uf: 'MG'
  },
  {
    value: 'Pescador',
    name: 'Pescador',
    uf: 'MG'
  },
  {
    value: 'Piau',
    name: 'Piau',
    uf: 'MG'
  },
  {
    value: 'Piedade de Ponte Nova',
    name: 'Piedade de Ponte Nova',
    uf: 'MG'
  },
  {
    value: 'Piedade do Rio Grande',
    name: 'Piedade do Rio Grande',
    uf: 'MG'
  },
  {
    value: 'Piedade dos Gerais',
    name: 'Piedade dos Gerais',
    uf: 'MG'
  },
  {
    value: 'Pimenta',
    name: 'Pimenta',
    uf: 'MG'
  },
  {
    value: 'Piracema',
    name: 'Piracema',
    uf: 'MG'
  },
  {
    value: 'Pirajuba',
    name: 'Pirajuba',
    uf: 'MG'
  },
  {
    value: 'Piranga',
    name: 'Piranga',
    uf: 'MG'
  },
  {
    value: 'Pirangucu',
    name: 'Piranguçu',
    uf: 'MG'
  },
  {
    value: 'Piranguinho',
    name: 'Piranguinho',
    uf: 'MG'
  },
  {
    value: 'Pirapetinga',
    name: 'Pirapetinga',
    uf: 'MG'
  },
  {
    value: 'Pirapora',
    name: 'Pirapora',
    uf: 'MG'
  },
  {
    value: 'Pirauba',
    name: 'Piraúba',
    uf: 'MG'
  },
  {
    value: 'Pitangui',
    name: 'Pitangui',
    uf: 'MG'
  },
  {
    value: 'Piumhi',
    name: 'Piumhi',
    uf: 'MG'
  },
  {
    value: 'Planura',
    name: 'Planura',
    uf: 'MG'
  },
  {
    value: 'Poco Fundo',
    name: 'Poço Fundo',
    uf: 'MG'
  },
  {
    value: 'Pocos de Caldas',
    name: 'Poços de Caldas',
    uf: 'MG'
  },
  {
    value: 'Pocrane',
    name: 'Pocrane',
    uf: 'MG'
  },
  {
    value: 'Pompeu',
    name: 'Pompéu',
    uf: 'MG'
  },
  {
    value: 'Ponte Nova',
    name: 'Ponte Nova',
    uf: 'MG'
  },
  {
    value: 'Porteirinha',
    name: 'Porteirinha',
    uf: 'MG'
  },
  {
    value: 'Porto Firme',
    name: 'Porto Firme',
    uf: 'MG'
  },
  {
    value: 'Pote',
    name: 'Poté',
    uf: 'MG'
  },
  {
    value: 'Pouso Alegre',
    name: 'Pouso Alegre',
    uf: 'MG'
  },
  {
    value: 'Pouso Alto',
    name: 'Pouso Alto',
    uf: 'MG'
  },
  {
    value: 'Prados',
    name: 'Prados',
    uf: 'MG'
  },
  {
    value: 'Prata',
    name: 'Prata',
    uf: 'MG'
  },
  {
    value: 'Pratapolis',
    name: 'Pratápolis',
    uf: 'MG'
  },
  {
    value: 'Pratinha',
    name: 'Pratinha',
    uf: 'MG'
  },
  {
    value: 'Presidente Bernardes',
    name: 'Presidente Bernardes',
    uf: 'MG'
  },
  {
    value: 'Presidente Juscelino',
    name: 'Presidente Juscelino',
    uf: 'MG'
  },
  {
    value: 'Presidente Kubitschek',
    name: 'Presidente Kubitschek',
    uf: 'MG'
  },
  {
    value: 'Presidente Olegario',
    name: 'Presidente Olegário',
    uf: 'MG'
  },
  {
    value: 'Alto Jequitiba',
    name: 'Alto Jequitibá',
    uf: 'MG'
  },
  {
    value: 'Prudente de Morais',
    name: 'Prudente de Morais',
    uf: 'MG'
  },
  {
    value: 'Quartel Geral',
    name: 'Quartel Geral',
    uf: 'MG'
  },
  {
    value: 'Queluzito',
    name: 'Queluzito',
    uf: 'MG'
  },
  {
    value: 'Raposos',
    name: 'Raposos',
    uf: 'MG'
  },
  {
    value: 'Raul Soares',
    name: 'Raul Soares',
    uf: 'MG'
  },
  {
    value: 'Recreio',
    name: 'Recreio',
    uf: 'MG'
  },
  {
    value: 'Resende Costa',
    name: 'Resende Costa',
    uf: 'MG'
  },
  {
    value: 'Resplendor',
    name: 'Resplendor',
    uf: 'MG'
  },
  {
    value: 'Ressaquinha',
    name: 'Ressaquinha',
    uf: 'MG'
  },
  {
    value: 'Riacho dos Machados',
    name: 'Riacho dos Machados',
    uf: 'MG'
  },
  {
    value: 'Ribeirao das Neves',
    name: 'Ribeirão das Neves',
    uf: 'MG'
  },
  {
    value: 'Ribeirao Vermelho',
    name: 'Ribeirão Vermelho',
    uf: 'MG'
  },
  {
    value: 'Rio Acima',
    name: 'Rio Acima',
    uf: 'MG'
  },
  {
    value: 'Rio Casca',
    name: 'Rio Casca',
    uf: 'MG'
  },
  {
    value: 'Rio Doce',
    name: 'Rio Doce',
    uf: 'MG'
  },
  {
    value: 'Rio do Prado',
    name: 'Rio do Prado',
    uf: 'MG'
  },
  {
    value: 'Rio Espera',
    name: 'Rio Espera',
    uf: 'MG'
  },
  {
    value: 'Rio Manso',
    name: 'Rio Manso',
    uf: 'MG'
  },
  {
    value: 'Rio Novo',
    name: 'Rio Novo',
    uf: 'MG'
  },
  {
    value: 'Rio Paranaiba',
    name: 'Rio Paranaíba',
    uf: 'MG'
  },
  {
    value: 'Rio Pardo de Minas',
    name: 'Rio Pardo de Minas',
    uf: 'MG'
  },
  {
    value: 'Rio Piracicaba',
    name: 'Rio Piracicaba',
    uf: 'MG'
  },
  {
    value: 'Rio Pomba',
    name: 'Rio Pomba',
    uf: 'MG'
  },
  {
    value: 'Rio Preto',
    name: 'Rio Preto',
    uf: 'MG'
  },
  {
    value: 'Rio Vermelho',
    name: 'Rio Vermelho',
    uf: 'MG'
  },
  {
    value: 'Ritapolis',
    name: 'Ritápolis',
    uf: 'MG'
  },
  {
    value: 'Rochedo de Minas',
    name: 'Rochedo de Minas',
    uf: 'MG'
  },
  {
    value: 'Rodeiro',
    name: 'Rodeiro',
    uf: 'MG'
  },
  {
    value: 'Romaria',
    name: 'Romaria',
    uf: 'MG'
  },
  {
    value: 'Rubelita',
    name: 'Rubelita',
    uf: 'MG'
  },
  {
    value: 'Rubim',
    name: 'Rubim',
    uf: 'MG'
  },
  {
    value: 'Sabara',
    name: 'Sabará',
    uf: 'MG'
  },
  {
    value: 'Sabinopolis',
    name: 'Sabinópolis',
    uf: 'MG'
  },
  {
    value: 'Sacramento',
    name: 'Sacramento',
    uf: 'MG'
  },
  {
    value: 'Salinas',
    name: 'Salinas',
    uf: 'MG'
  },
  {
    value: 'Salto da Divisa',
    name: 'Salto da Divisa',
    uf: 'MG'
  },
  {
    value: 'Santa Barbara',
    name: 'Santa Bárbara',
    uf: 'MG'
  },
  {
    value: 'Santa Barbara do Tugurio',
    name: 'Santa Bárbara do Tugúrio',
    uf: 'MG'
  },
  {
    value: 'Santa Cruz do Escalvado',
    name: 'Santa Cruz do Escalvado',
    uf: 'MG'
  },
  {
    value: 'Santa Efigenia de Minas',
    name: 'Santa Efigênia de Minas',
    uf: 'MG'
  },
  {
    value: 'Santa Fe de Minas',
    name: 'Santa Fé de Minas',
    uf: 'MG'
  },
  {
    value: 'Santa Juliana',
    name: 'Santa Juliana',
    uf: 'MG'
  },
  {
    value: 'Santa Luzia',
    name: 'Santa Luzia',
    uf: 'MG'
  },
  {
    value: 'Santa Margarida',
    name: 'Santa Margarida',
    uf: 'MG'
  },
  {
    value: 'Santa Maria de Itabira',
    name: 'Santa Maria de Itabira',
    uf: 'MG'
  },
  {
    value: 'Santa Maria do Salto',
    name: 'Santa Maria do Salto',
    uf: 'MG'
  },
  {
    value: 'Santa Maria do Suacui',
    name: 'Santa Maria do Suaçuí',
    uf: 'MG'
  },
  {
    value: 'Santana da Vargem',
    name: 'Santana da Vargem',
    uf: 'MG'
  },
  {
    value: 'Santana de Cataguases',
    name: 'Santana de Cataguases',
    uf: 'MG'
  },
  {
    value: 'Santana de Pirapama',
    name: 'Santana de Pirapama',
    uf: 'MG'
  },
  {
    value: 'Santana do Deserto',
    name: 'Santana do Deserto',
    uf: 'MG'
  },
  {
    value: 'Santana do Garambeu',
    name: 'Santana do Garambéu',
    uf: 'MG'
  },
  {
    value: 'Santana do Jacare',
    name: 'Santana do Jacaré',
    uf: 'MG'
  },
  {
    value: 'Santana do Manhuacu',
    name: 'Santana do Manhuaçu',
    uf: 'MG'
  },
  {
    value: 'Santana do Riacho',
    name: 'Santana do Riacho',
    uf: 'MG'
  },
  {
    value: 'Santana dos Montes',
    name: 'Santana dos Montes',
    uf: 'MG'
  },
  {
    value: 'Santa Rita de Caldas',
    name: 'Santa Rita de Caldas',
    uf: 'MG'
  },
  {
    value: 'Santa Rita de Jacutinga',
    name: 'Santa Rita de Jacutinga',
    uf: 'MG'
  },
  {
    value: 'Santa Rita de Ibitipoca',
    name: 'Santa Rita de Ibitipoca',
    uf: 'MG'
  },
  {
    value: 'Santa Rita do Itueto',
    name: 'Santa Rita do Itueto',
    uf: 'MG'
  },
  {
    value: 'Santa Rita do Sapucai',
    name: 'Santa Rita do Sapucaí',
    uf: 'MG'
  },
  {
    value: 'Santa Rosa da Serra',
    name: 'Santa Rosa da Serra',
    uf: 'MG'
  },
  {
    value: 'Santa Vitoria',
    name: 'Santa Vitória',
    uf: 'MG'
  },
  {
    value: 'Santo Antonio do Amparo',
    name: 'Santo Antônio do Amparo',
    uf: 'MG'
  },
  {
    value: 'Santo Antonio do Aventureiro',
    name: 'Santo Antônio do Aventureiro',
    uf: 'MG'
  },
  {
    value: 'Santo Antonio do Grama',
    name: 'Santo Antônio do Grama',
    uf: 'MG'
  },
  {
    value: 'Santo Antonio do Itambe',
    name: 'Santo Antônio do Itambé',
    uf: 'MG'
  },
  {
    value: 'Santo Antonio do Jacinto',
    name: 'Santo Antônio do Jacinto',
    uf: 'MG'
  },
  {
    value: 'Santo Antonio do Monte',
    name: 'Santo Antônio do Monte',
    uf: 'MG'
  },
  {
    value: 'Santo Antonio do Rio Abaixo',
    name: 'Santo Antônio do Rio Abaixo',
    uf: 'MG'
  },
  {
    value: 'Santo Hipolito',
    name: 'Santo Hipólito',
    uf: 'MG'
  },
  {
    value: 'Santos Dumont',
    name: 'Santos Dumont',
    uf: 'MG'
  },
  {
    value: 'Sao Bento Abade',
    name: 'São Bento Abade',
    uf: 'MG'
  },
  {
    value: 'Sao Bras do Suacui',
    name: 'São Brás do Suaçuí',
    uf: 'MG'
  },
  {
    value: 'Sao Domingos do Prata',
    name: 'São Domingos do Prata',
    uf: 'MG'
  },
  {
    value: 'Sao Francisco',
    name: 'São Francisco',
    uf: 'MG'
  },
  {
    value: 'Sao Francisco de Paula',
    name: 'São Francisco de Paula',
    uf: 'MG'
  },
  {
    value: 'Sao Francisco de Sales',
    name: 'São Francisco de Sales',
    uf: 'MG'
  },
  {
    value: 'Sao Francisco do Gloria',
    name: 'São Francisco do Glória',
    uf: 'MG'
  },
  {
    value: 'Sao Geraldo',
    name: 'São Geraldo',
    uf: 'MG'
  },
  {
    value: 'Sao Geraldo da Piedade',
    name: 'São Geraldo da Piedade',
    uf: 'MG'
  },
  {
    value: 'Sao Goncalo do Abaete',
    name: 'São Gonçalo do Abaeté',
    uf: 'MG'
  },
  {
    value: 'Sao Goncalo do Para',
    name: 'São Gonçalo do Pará',
    uf: 'MG'
  },
  {
    value: 'Sao Goncalo do Rio Abaixo',
    name: 'São Gonçalo do Rio Abaixo',
    uf: 'MG'
  },
  {
    value: 'Sao Goncalo do Sapucai',
    name: 'São Gonçalo do Sapucaí',
    uf: 'MG'
  },
  {
    value: 'Sao Gotardo',
    name: 'São Gotardo',
    uf: 'MG'
  },
  {
    value: 'Sao Joao Batista do Gloria',
    name: 'São João Batista do Glória',
    uf: 'MG'
  },
  {
    value: 'Sao Joao da Mata',
    name: 'São João da Mata',
    uf: 'MG'
  },
  {
    value: 'Sao Joao da Ponte',
    name: 'São João da Ponte',
    uf: 'MG'
  },
  {
    value: 'Sao Joao del Rei',
    name: 'São João del Rei',
    uf: 'MG'
  },
  {
    value: 'Sao Joao do Oriente',
    name: 'São João do Oriente',
    uf: 'MG'
  },
  {
    value: 'Sao Joao do Paraiso',
    name: 'São João do Paraíso',
    uf: 'MG'
  },
  {
    value: 'Sao Joao Evangelista',
    name: 'São João Evangelista',
    uf: 'MG'
  },
  {
    value: 'Sao Joao Nepomuceno',
    name: 'São João Nepomuceno',
    uf: 'MG'
  },
  {
    value: 'Sao Jose da Safira',
    name: 'São José da Safira',
    uf: 'MG'
  },
  {
    value: 'Sao Jose da Varginha',
    name: 'São José da Varginha',
    uf: 'MG'
  },
  {
    value: 'Sao Jose do Alegre',
    name: 'São José do Alegre',
    uf: 'MG'
  },
  {
    value: 'Sao Jose do Divino',
    name: 'São José do Divino',
    uf: 'MG'
  },
  {
    value: 'Sao Jose do Goiabal',
    name: 'São José do Goiabal',
    uf: 'MG'
  },
  {
    value: 'Sao Jose do Jacuri',
    name: 'São José do Jacuri',
    uf: 'MG'
  },
  {
    value: 'Sao Jose do Mantimento',
    name: 'São José do Mantimento',
    uf: 'MG'
  },
  {
    value: 'Sao Lourenco',
    name: 'São Lourenço',
    uf: 'MG'
  },
  {
    value: 'Sao Miguel do Anta',
    name: 'São Miguel do Anta',
    uf: 'MG'
  },
  {
    value: 'Sao Pedro da Uniao',
    name: 'São Pedro da União',
    uf: 'MG'
  },
  {
    value: 'Sao Pedro dos Ferros',
    name: 'São Pedro dos Ferros',
    uf: 'MG'
  },
  {
    value: 'Sao Pedro do Suacui',
    name: 'São Pedro do Suaçuí',
    uf: 'MG'
  },
  {
    value: 'Sao Romao',
    name: 'São Romão',
    uf: 'MG'
  },
  {
    value: 'Sao Roque de Minas',
    name: 'São Roque de Minas',
    uf: 'MG'
  },
  {
    value: 'Sao Sebastiao da Bela Vista',
    name: 'São Sebastião da Bela Vista',
    uf: 'MG'
  },
  {
    value: 'Sao Sebastiao do Maranhao',
    name: 'São Sebastião do Maranhão',
    uf: 'MG'
  },
  {
    value: 'Sao Sebastiao do Oeste',
    name: 'São Sebastião do Oeste',
    uf: 'MG'
  },
  {
    value: 'Sao Sebastiao do Paraiso',
    name: 'São Sebastião do Paraíso',
    uf: 'MG'
  },
  {
    value: 'Sao Sebastiao do Rio Preto',
    name: 'São Sebastião do Rio Preto',
    uf: 'MG'
  },
  {
    value: 'Sao Sebastiao do Rio Verde',
    name: 'São Sebastião do Rio Verde',
    uf: 'MG'
  },
  {
    value: 'Sao Tiago',
    name: 'São Tiago',
    uf: 'MG'
  },
  {
    value: 'Sao Tomas de Aquino',
    name: 'São Tomás de Aquino',
    uf: 'MG'
  },
  {
    value: 'Sao Tome das Letras',
    name: 'São Tomé das Letras',
    uf: 'MG'
  },
  {
    value: 'Sao Vicente de Minas',
    name: 'São Vicente de Minas',
    uf: 'MG'
  },
  {
    value: 'Sapucai-Mirim',
    name: 'Sapucaí-Mirim',
    uf: 'MG'
  },
  {
    value: 'Sardoa',
    name: 'Sardoá',
    uf: 'MG'
  },
  {
    value: 'Senador Cortes',
    name: 'Senador Cortes',
    uf: 'MG'
  },
  {
    value: 'Senador Firmino',
    name: 'Senador Firmino',
    uf: 'MG'
  },
  {
    value: 'Senador Jose Bento',
    name: 'Senador José Bento',
    uf: 'MG'
  },
  {
    value: 'Senador Modestino Goncalves',
    name: 'Senador Modestino Gonçalves',
    uf: 'MG'
  },
  {
    value: 'Senhora de Oliveira',
    name: 'Senhora de Oliveira',
    uf: 'MG'
  },
  {
    value: 'Senhora do Porto',
    name: 'Senhora do Porto',
    uf: 'MG'
  },
  {
    value: 'Senhora dos Remedios',
    name: 'Senhora dos Remédios',
    uf: 'MG'
  },
  {
    value: 'Sericita',
    name: 'Sericita',
    uf: 'MG'
  },
  {
    value: 'Seritinga',
    name: 'Seritinga',
    uf: 'MG'
  },
  {
    value: 'Serra Azul de Minas',
    name: 'Serra Azul de Minas',
    uf: 'MG'
  },
  {
    value: 'Serra da Saudade',
    name: 'Serra da Saudade',
    uf: 'MG'
  },
  {
    value: 'Serra dos Aimores',
    name: 'Serra dos Aimorés',
    uf: 'MG'
  },
  {
    value: 'Serra do Salitre',
    name: 'Serra do Salitre',
    uf: 'MG'
  },
  {
    value: 'Serrania',
    name: 'Serrania',
    uf: 'MG'
  },
  {
    value: 'Serranos',
    name: 'Serranos',
    uf: 'MG'
  },
  {
    value: 'Serro',
    name: 'Serro',
    uf: 'MG'
  },
  {
    value: 'Sete Lagoas',
    name: 'Sete Lagoas',
    uf: 'MG'
  },
  {
    value: 'Silveirania',
    name: 'Silveirânia',
    uf: 'MG'
  },
  {
    value: 'Silvianopolis',
    name: 'Silvianópolis',
    uf: 'MG'
  },
  {
    value: 'Simao Pereira',
    name: 'Simão Pereira',
    uf: 'MG'
  },
  {
    value: 'Simonesia',
    name: 'Simonésia',
    uf: 'MG'
  },
  {
    value: 'Sobralia',
    name: 'Sobrália',
    uf: 'MG'
  },
  {
    value: 'Soledade de Minas',
    name: 'Soledade de Minas',
    uf: 'MG'
  },
  {
    value: 'Tabuleiro',
    name: 'Tabuleiro',
    uf: 'MG'
  },
  {
    value: 'Taiobeiras',
    name: 'Taiobeiras',
    uf: 'MG'
  },
  {
    value: 'Tapira',
    name: 'Tapira',
    uf: 'MG'
  },
  {
    value: 'Tapirai',
    name: 'Tapiraí',
    uf: 'MG'
  },
  {
    value: 'Taquaracu de Minas',
    name: 'Taquaraçu de Minas',
    uf: 'MG'
  },
  {
    value: 'Tarumirim',
    name: 'Tarumirim',
    uf: 'MG'
  },
  {
    value: 'Teixeiras',
    name: 'Teixeiras',
    uf: 'MG'
  },
  {
    value: 'Teofilo Otoni',
    name: 'Teófilo Otoni',
    uf: 'MG'
  },
  {
    value: 'Timoteo',
    name: 'Timóteo',
    uf: 'MG'
  },
  {
    value: 'Tiradentes',
    name: 'Tiradentes',
    uf: 'MG'
  },
  {
    value: 'Tiros',
    name: 'Tiros',
    uf: 'MG'
  },
  {
    value: 'Tocantins',
    name: 'Tocantins',
    uf: 'MG'
  },
  {
    value: 'Toledo',
    name: 'Toledo',
    uf: 'MG'
  },
  {
    value: 'Tombos',
    name: 'Tombos',
    uf: 'MG'
  },
  {
    value: 'Tres Coracoes',
    name: 'Três Corações',
    uf: 'MG'
  },
  {
    value: 'Tres Pontas',
    name: 'Três Pontas',
    uf: 'MG'
  },
  {
    value: 'Tumiritinga',
    name: 'Tumiritinga',
    uf: 'MG'
  },
  {
    value: 'Tupaciguara',
    name: 'Tupaciguara',
    uf: 'MG'
  },
  {
    value: 'Turmalina',
    name: 'Turmalina',
    uf: 'MG'
  },
  {
    value: 'Turvolandia',
    name: 'Turvolândia',
    uf: 'MG'
  },
  {
    value: 'Uba',
    name: 'Ubá',
    uf: 'MG'
  },
  {
    value: 'Ubai',
    name: 'Ubaí',
    uf: 'MG'
  },
  {
    value: 'Uberaba',
    name: 'Uberaba',
    uf: 'MG'
  },
  {
    value: 'Uberlandia',
    name: 'Uberlândia',
    uf: 'MG'
  },
  {
    value: 'Umburatiba',
    name: 'Umburatiba',
    uf: 'MG'
  },
  {
    value: 'Unai',
    name: 'Unaí',
    uf: 'MG'
  },
  {
    value: 'Urucania',
    name: 'Urucânia',
    uf: 'MG'
  },
  {
    value: 'Vargem Bonita',
    name: 'Vargem Bonita',
    uf: 'MG'
  },
  {
    value: 'Varginha',
    name: 'Varginha',
    uf: 'MG'
  },
  {
    value: 'Varzea da Palma',
    name: 'Várzea da Palma',
    uf: 'MG'
  },
  {
    value: 'Varzelandia',
    name: 'Varzelândia',
    uf: 'MG'
  },
  {
    value: 'Vazante',
    name: 'Vazante',
    uf: 'MG'
  },
  {
    value: 'Wenceslau Braz',
    name: 'Wenceslau Braz',
    uf: 'MG'
  },
  {
    value: 'Verissimo',
    name: 'Veríssimo',
    uf: 'MG'
  },
  {
    value: 'Vespasiano',
    name: 'Vespasiano',
    uf: 'MG'
  },
  {
    value: 'Vicosa',
    name: 'Viçosa',
    uf: 'MG'
  },
  {
    value: 'Vieiras',
    name: 'Vieiras',
    uf: 'MG'
  },
  {
    value: 'Mathias Lobato',
    name: 'Mathias Lobato',
    uf: 'MG'
  },
  {
    value: 'Virgem da Lapa',
    name: 'Virgem da Lapa',
    uf: 'MG'
  },
  {
    value: 'Virginia',
    name: 'Virgínia',
    uf: 'MG'
  },
  {
    value: 'Virginopolis',
    name: 'Virginópolis',
    uf: 'MG'
  },
  {
    value: 'Virgolandia',
    name: 'Virgolândia',
    uf: 'MG'
  },
  {
    value: 'Visconde do Rio Branco',
    name: 'Visconde do Rio Branco',
    uf: 'MG'
  },
  {
    value: 'Volta Grande',
    name: 'Volta Grande',
    uf: 'MG'
  },
  {
    value: 'Saltinho',
    name: 'Saltinho',
    uf: 'SP'
  },
  {
    value: 'Sao Lourenco da Serra',
    name: 'São Lourenço da Serra',
    uf: 'SP'
  },
  {
    value: 'Doutor Ulysses',
    name: 'Doutor Ulysses',
    uf: 'PR'
  },
  {
    value: 'Itaperucu',
    name: 'Itaperuçu',
    uf: 'PR'
  },
  {
    value: 'Pinhais',
    name: 'Pinhais',
    uf: 'PR'
  },
  {
    value: 'Tunas do Parana',
    name: 'Tunas do Paraná',
    uf: 'PR'
  },
  {
    value: 'Nova Santa Barbara',
    name: 'Nova Santa Bárbara',
    uf: 'PR'
  },
  {
    value: 'Maua da Serra',
    name: 'Mauá da Serra',
    uf: 'PR'
  },
  {
    value: 'Pitangueiras',
    name: 'Pitangueiras',
    uf: 'PR'
  },
  {
    value: 'Anahy',
    name: 'Anahy',
    uf: 'PR'
  },
  {
    value: 'Diamante do Sul',
    name: 'Diamante do Sul',
    uf: 'PR'
  },
  {
    value: 'Iguatu',
    name: 'Iguatu',
    uf: 'PR'
  },
  {
    value: 'Santa Lucia',
    name: 'Santa Lúcia',
    uf: 'PR'
  },
  {
    value: 'Boa Esperanca do Iguacu',
    name: 'Boa Esperança do Iguaçu',
    uf: 'PR'
  },
  {
    value: 'Cruzeiro do Iguacu',
    name: 'Cruzeiro do Iguaçu',
    uf: 'PR'
  },
  {
    value: 'Flor da Serra do Sul',
    name: 'Flor da Serra do Sul',
    uf: 'PR'
  },
  {
    value: 'Nova Esperanca do Sudoeste',
    name: 'Nova Esperança do Sudoeste',
    uf: 'PR'
  },
  {
    value: 'Nova Laranjeiras',
    name: 'Nova Laranjeiras',
    uf: 'PR'
  },
  {
    value: 'Rio Bonito do Iguacu',
    name: 'Rio Bonito do Iguaçu',
    uf: 'PR'
  },
  {
    value: 'Virmond',
    name: 'Virmond',
    uf: 'PR'
  },
  {
    value: 'Iracema do Oeste',
    name: 'Iracema do Oeste',
    uf: 'PR'
  },
  {
    value: 'Maripa',
    name: 'Maripá',
    uf: 'PR'
  },
  {
    value: 'Sao Pedro do Iguacu',
    name: 'São Pedro do Iguaçu',
    uf: 'PR'
  },
  {
    value: 'Cafezal do Sul',
    name: 'Cafezal do Sul',
    uf: 'PR'
  },
  {
    value: 'Saudade do Iguacu',
    name: 'Saudade do Iguaçu',
    uf: 'PR'
  },
  {
    value: 'Pinhal de Sao Bento',
    name: 'Pinhal de São Bento',
    uf: 'PR'
  },
  {
    value: 'Ventania',
    name: 'Ventania',
    uf: 'PR'
  },
  {
    value: 'Candoi',
    name: 'Candói',
    uf: 'PR'
  },
  {
    value: 'Laranjal',
    name: 'Laranjal',
    uf: 'PR'
  },
  {
    value: 'Mato Rico',
    name: 'Mato Rico',
    uf: 'PR'
  },
  {
    value: 'Santa Maria do Oeste',
    name: 'Santa Maria do Oeste',
    uf: 'PR'
  },
  {
    value: 'Lidianopolis',
    name: 'Lidianópolis',
    uf: 'PR'
  },
  {
    value: 'Angulo',
    name: 'Ângulo',
    uf: 'PR'
  },
  {
    value: 'Farol',
    name: 'Farol',
    uf: 'PR'
  },
  {
    value: "Rancho Alegre D'Oeste",
    name: "Rancho Alegre D'Oeste",
    uf: 'PR'
  },
  {
    value: 'Sao Manoel do Parana',
    name: 'São Manoel do Paraná',
    uf: 'PR'
  },
  {
    value: 'Novo Itacolomi',
    name: 'Novo Itacolomi',
    uf: 'PR'
  },
  {
    value: 'Santa Monica',
    name: 'Santa Mônica',
    uf: 'PR'
  },
  {
    value: 'Brasilandia do Sul',
    name: 'Brasilândia do Sul',
    uf: 'PR'
  },
  {
    value: 'Alto Paraiso',
    name: 'Alto Paraíso',
    uf: 'PR'
  },
  {
    value: 'Itaipulandia',
    name: 'Itaipulândia',
    uf: 'PR'
  },
  {
    value: 'Ramilandia',
    name: 'Ramilândia',
    uf: 'PR'
  },
  {
    value: 'Entre Rios do Oeste',
    name: 'Entre Rios do Oeste',
    uf: 'PR'
  },
  {
    value: 'Mercedes',
    name: 'Mercedes',
    uf: 'PR'
  },
  {
    value: 'Pato Bragado',
    name: 'Pato Bragado',
    uf: 'PR'
  },
  {
    value: 'Quatro Pontes',
    name: 'Quatro Pontes',
    uf: 'PR'
  },
  {
    value: 'Bombinhas',
    name: 'Bombinhas',
    uf: 'SC'
  },
  {
    value: 'Morro Grande',
    name: 'Morro Grande',
    uf: 'SC'
  },
  {
    value: 'Passo de Torres',
    name: 'Passo de Torres',
    uf: 'SC'
  },
  {
    value: 'Cocal do Sul',
    name: 'Cocal do Sul',
    uf: 'SC'
  },
  {
    value: 'Capivari de Baixo',
    name: 'Capivari de Baixo',
    uf: 'SC'
  },
  {
    value: 'Sangao',
    name: 'Sangão',
    uf: 'SC'
  },
  {
    value: 'Balneario Barra do Sul',
    name: 'Balneário Barra do Sul',
    uf: 'SC'
  },
  {
    value: 'Sao Joao do Itaperiu',
    name: 'São João do Itaperiú',
    uf: 'SC'
  },
  {
    value: 'Calmon',
    name: 'Calmon',
    uf: 'SC'
  },
  {
    value: 'Santa Terezinha',
    name: 'Santa Terezinha',
    uf: 'SC'
  },
  {
    value: 'Braco do Trombudo',
    name: 'Braço do Trombudo',
    uf: 'SC'
  },
  {
    value: 'Mirim Doce',
    name: 'Mirim Doce',
    uf: 'SC'
  },
  {
    value: 'Monte Carlo',
    name: 'Monte Carlo',
    uf: 'SC'
  },
  {
    value: 'Vargem',
    name: 'Vargem',
    uf: 'SC'
  },
  {
    value: 'Vargem Bonita',
    name: 'Vargem Bonita',
    uf: 'SC'
  },
  {
    value: 'Cerro Negro',
    name: 'Cerro Negro',
    uf: 'SC'
  },
  {
    value: 'Ponte Alta do Norte',
    name: 'Ponte Alta do Norte',
    uf: 'SC'
  },
  {
    value: 'Rio Rufino',
    name: 'Rio Rufino',
    uf: 'SC'
  },
  {
    value: 'Sao Cristovao do Sul',
    name: 'São Cristóvão do Sul',
    uf: 'SC'
  },
  {
    value: 'Macieira',
    name: 'Macieira',
    uf: 'SC'
  },
  {
    value: 'Aguas Frias',
    name: 'Águas Frias',
    uf: 'SC'
  },
  {
    value: 'Cordilheira Alta',
    name: 'Cordilheira Alta',
    uf: 'SC'
  },
  {
    value: 'Formosa do Sul',
    name: 'Formosa do Sul',
    uf: 'SC'
  },
  {
    value: 'Guatambu',
    name: 'Guatambú',
    uf: 'SC'
  },
  {
    value: 'Irati',
    name: 'Irati',
    uf: 'SC'
  },
  {
    value: 'Jardinopolis',
    name: 'Jardinópolis',
    uf: 'SC'
  },
  {
    value: 'Nova Itaberaba',
    name: 'Nova Itaberaba',
    uf: 'SC'
  },
  {
    value: 'Novo Horizonte',
    name: 'Novo Horizonte',
    uf: 'SC'
  },
  {
    value: 'Planalto Alegre',
    name: 'Planalto Alegre',
    uf: 'SC'
  },
  {
    value: 'Sul Brasil',
    name: 'Sul Brasil',
    uf: 'SC'
  },
  {
    value: 'Arabuta',
    name: 'Arabutã',
    uf: 'SC'
  },
  {
    value: 'Arvoredo',
    name: 'Arvoredo',
    uf: 'SC'
  },
  {
    value: 'Afonso Claudio',
    name: 'Afonso Cláudio',
    uf: 'ES'
  },
  {
    value: 'Alegre',
    name: 'Alegre',
    uf: 'ES'
  },
  {
    value: 'Alfredo Chaves',
    name: 'Alfredo Chaves',
    uf: 'ES'
  },
  {
    value: 'Anchieta',
    name: 'Anchieta',
    uf: 'ES'
  },
  {
    value: 'Apiaca',
    name: 'Apiacá',
    uf: 'ES'
  },
  {
    value: 'Aracruz',
    name: 'Aracruz',
    uf: 'ES'
  },
  {
    value: 'Atilio Vivacqua',
    name: 'Atílio Vivácqua',
    uf: 'ES'
  },
  {
    value: 'Baixo Guandu',
    name: 'Baixo Guandu',
    uf: 'ES'
  },
  {
    value: 'Barra de Sao Francisco',
    name: 'Barra de São Francisco',
    uf: 'ES'
  },
  {
    value: 'Boa Esperanca',
    name: 'Boa Esperança',
    uf: 'ES'
  },
  {
    value: 'Bom Jesus do Norte',
    name: 'Bom Jesus do Norte',
    uf: 'ES'
  },
  {
    value: 'Cachoeiro de Itapemirim',
    name: 'Cachoeiro de Itapemirim',
    uf: 'ES'
  },
  {
    value: 'Cariacica',
    name: 'Cariacica',
    uf: 'ES'
  },
  {
    value: 'Castelo',
    name: 'Castelo',
    uf: 'ES'
  },
  {
    value: 'Colatina',
    name: 'Colatina',
    uf: 'ES'
  },
  {
    value: 'Conceicao da Barra',
    name: 'Conceição da Barra',
    uf: 'ES'
  },
  {
    value: 'Conceicao do Castelo',
    name: 'Conceição do Castelo',
    uf: 'ES'
  },
  {
    value: 'Divino de Sao Lourenco',
    name: 'Divino de São Lourenço',
    uf: 'ES'
  },
  {
    value: 'Domingos Martins',
    name: 'Domingos Martins',
    uf: 'ES'
  },
  {
    value: 'Dores do Rio Preto',
    name: 'Dores do Rio Preto',
    uf: 'ES'
  },
  {
    value: 'Ecoporanga',
    name: 'Ecoporanga',
    uf: 'ES'
  },
  {
    value: 'Fundao',
    name: 'Fundão',
    uf: 'ES'
  },
  {
    value: 'Guacui',
    name: 'Guaçuí',
    uf: 'ES'
  },
  {
    value: 'Guarapari',
    name: 'Guarapari',
    uf: 'ES'
  },
  {
    value: 'Ibiracu',
    name: 'Ibiraçu',
    uf: 'ES'
  },
  {
    value: 'Iconha',
    name: 'Iconha',
    uf: 'ES'
  },
  {
    value: 'Itaguacu',
    name: 'Itaguaçu',
    uf: 'ES'
  },
  {
    value: 'Itapemirim',
    name: 'Itapemirim',
    uf: 'ES'
  },
  {
    value: 'Itarana',
    name: 'Itarana',
    uf: 'ES'
  },
  {
    value: 'Iuna',
    name: 'Iúna',
    uf: 'ES'
  },
  {
    value: 'Jeronimo Monteiro',
    name: 'Jerônimo Monteiro',
    uf: 'ES'
  },
  {
    value: 'Linhares',
    name: 'Linhares',
    uf: 'ES'
  },
  {
    value: 'Mantenopolis',
    name: 'Mantenópolis',
    uf: 'ES'
  },
  {
    value: 'Mimoso do Sul',
    name: 'Mimoso do Sul',
    uf: 'ES'
  },
  {
    value: 'Montanha',
    name: 'Montanha',
    uf: 'ES'
  },
  {
    value: 'Mucurici',
    name: 'Mucurici',
    uf: 'ES'
  },
  {
    value: 'Muniz Freire',
    name: 'Muniz Freire',
    uf: 'ES'
  },
  {
    value: 'Muqui',
    name: 'Muqui',
    uf: 'ES'
  },
  {
    value: 'Nova Venecia',
    name: 'Nova Venécia',
    uf: 'ES'
  },
  {
    value: 'Pancas',
    name: 'Pancas',
    uf: 'ES'
  },
  {
    value: 'Pinheiros',
    name: 'Pinheiros',
    uf: 'ES'
  },
  {
    value: 'Piuma',
    name: 'Piúma',
    uf: 'ES'
  },
  {
    value: 'Presidente Kennedy',
    name: 'Presidente Kennedy',
    uf: 'ES'
  },
  {
    value: 'Rio Novo do Sul',
    name: 'Rio Novo do Sul',
    uf: 'ES'
  },
  {
    value: 'Santa Leopoldina',
    name: 'Santa Leopoldina',
    uf: 'ES'
  },
  {
    value: 'Santa Teresa',
    name: 'Santa Teresa',
    uf: 'ES'
  },
  {
    value: 'Sao Gabriel da Palha',
    name: 'São Gabriel da Palha',
    uf: 'ES'
  },
  {
    value: 'Sao Jose do Calcado',
    name: 'São José do Calçado',
    uf: 'ES'
  },
  {
    value: 'Sao Mateus',
    name: 'São Mateus',
    uf: 'ES'
  },
  {
    value: 'Serra',
    name: 'Serra',
    uf: 'ES'
  },
  {
    value: 'Viana',
    name: 'Viana',
    uf: 'ES'
  },
  {
    value: 'Vila Velha',
    name: 'Vila Velha',
    uf: 'ES'
  },
  {
    value: 'Vitoria',
    name: 'Vitória',
    uf: 'ES'
  },
  {
    value: 'Marilandia',
    name: 'Marilândia',
    uf: 'ES'
  },
  {
    value: 'Ibatiba',
    name: 'Ibatiba',
    uf: 'ES'
  },
  {
    value: 'Rio Bananal',
    name: 'Rio Bananal',
    uf: 'ES'
  },
  {
    value: 'Jaguare',
    name: 'Jaguaré',
    uf: 'ES'
  },
  {
    value: 'Pedro Canario',
    name: 'Pedro Canário',
    uf: 'ES'
  },
  {
    value: 'Agua Doce do Norte',
    name: 'Água Doce do Norte',
    uf: 'ES'
  },
  {
    value: 'Alto Rio Novo',
    name: 'Alto Rio Novo',
    uf: 'ES'
  },
  {
    value: 'Joao Neiva',
    name: 'João Neiva',
    uf: 'ES'
  },
  {
    value: 'Laranja da Terra',
    name: 'Laranja da Terra',
    uf: 'ES'
  },
  {
    value: 'Santa Maria de Jetiba',
    name: 'Santa Maria de Jetibá',
    uf: 'ES'
  },
  {
    value: 'Vargem Alta',
    name: 'Vargem Alta',
    uf: 'ES'
  },
  {
    value: 'Venda Nova do Imigrante',
    name: 'Venda Nova do Imigrante',
    uf: 'ES'
  },
  {
    value: 'Itau de Minas',
    name: 'Itaú de Minas',
    uf: 'MG'
  },
  {
    value: 'Aguia Branca',
    name: 'Águia Branca',
    uf: 'ES'
  },
  {
    value: 'Coronel Martins',
    name: 'Coronel Martins',
    uf: 'SC'
  },
  {
    value: 'Ipuacu',
    name: 'Ipuaçu',
    uf: 'SC'
  },
  {
    value: 'Lajeado Grande',
    name: 'Lajeado Grande',
    uf: 'SC'
  },
  {
    value: 'Ouro Verde',
    name: 'Ouro Verde',
    uf: 'SC'
  },
  {
    value: 'Passos Maia',
    name: 'Passos Maia',
    uf: 'SC'
  },
  {
    value: 'Belmonte',
    name: 'Belmonte',
    uf: 'SC'
  },
  {
    value: 'Paraiso',
    name: 'Paraíso',
    uf: 'SC'
  },
  {
    value: 'Riqueza',
    name: 'Riqueza',
    uf: 'SC'
  },
  {
    value: 'Santa Helena',
    name: 'Santa Helena',
    uf: 'SC'
  },
  {
    value: 'Sao Joao do Oeste',
    name: 'São João do Oeste',
    uf: 'SC'
  },
  {
    value: 'Sao Miguel da Boa Vista',
    name: 'São Miguel da Boa Vista',
    uf: 'SC'
  },
  {
    value: 'Nova Santa Rita',
    name: 'Nova Santa Rita',
    uf: 'RS'
  },
  {
    value: 'Mariana Pimentel',
    name: 'Mariana Pimentel',
    uf: 'RS'
  },
  {
    value: 'Sertao Santana',
    name: 'Sertão Santana',
    uf: 'RS'
  },
  {
    value: 'Gramado Xavier',
    name: 'Gramado Xavier',
    uf: 'RS'
  },
  {
    value: 'Passo do Sobrado',
    name: 'Passo do Sobrado',
    uf: 'RS'
  },
  {
    value: 'Sinimbu',
    name: 'Sinimbu',
    uf: 'RS'
  },
  {
    value: 'Vale do Sol',
    name: 'Vale do Sol',
    uf: 'RS'
  },
  {
    value: 'Barao do Triunfo',
    name: 'Barão do Triunfo',
    uf: 'RS'
  },
  {
    value: 'Minas do Leao',
    name: 'Minas do Leão',
    uf: 'RS'
  },
  {
    value: 'Morrinhos do Sul',
    name: 'Morrinhos do Sul',
    uf: 'RS'
  },
  {
    value: 'Tres Forquilhas',
    name: 'Três Forquilhas',
    uf: 'RS'
  },
  {
    value: 'Arambare',
    name: 'Arambaré',
    uf: 'RS'
  },
  {
    value: 'Sentinela do Sul',
    name: 'Sentinela do Sul',
    uf: 'RS'
  },
  {
    value: 'Maquine',
    name: 'Maquiné',
    uf: 'RS'
  },
  {
    value: 'Xangri-la',
    name: 'Xangri-lá',
    uf: 'RS'
  },
  {
    value: 'Pinhal Grande',
    name: 'Pinhal Grande',
    uf: 'RS'
  },
  {
    value: 'Quevedos',
    name: 'Quevedos',
    uf: 'RS'
  },
  {
    value: 'Sao Joao do Polesine',
    name: 'São João do Polêsine',
    uf: 'RS'
  },
  {
    value: 'Sao Martinho da Serra',
    name: 'São Martinho da Serra',
    uf: 'RS'
  },
  {
    value: 'Vila Nova do Sul',
    name: 'Vila Nova do Sul',
    uf: 'RS'
  },
  {
    value: 'Coxilha',
    name: 'Coxilha',
    uf: 'RS'
  },
  {
    value: 'Gentil',
    name: 'Gentil',
    uf: 'RS'
  },
  {
    value: 'Angra dos Reis',
    name: 'Angra dos Reis',
    uf: 'RJ'
  },
  {
    value: 'Araruama',
    name: 'Araruama',
    uf: 'RJ'
  },
  {
    value: 'Barra do Pirai',
    name: 'Barra do Piraí',
    uf: 'RJ'
  },
  {
    value: 'Barra Mansa',
    name: 'Barra Mansa',
    uf: 'RJ'
  },
  {
    value: 'Bom Jardim',
    name: 'Bom Jardim',
    uf: 'RJ'
  },
  {
    value: 'Bom Jesus do Itabapoana',
    name: 'Bom Jesus do Itabapoana',
    uf: 'RJ'
  },
  {
    value: 'Cabo Frio',
    name: 'Cabo Frio',
    uf: 'RJ'
  },
  {
    value: 'Cachoeiras de Macacu',
    name: 'Cachoeiras de Macacu',
    uf: 'RJ'
  },
  {
    value: 'Cambuci',
    name: 'Cambuci',
    uf: 'RJ'
  },
  {
    value: 'Campos dos Goytacazes',
    name: 'Campos dos Goytacazes',
    uf: 'RJ'
  },
  {
    value: 'Cantagalo',
    name: 'Cantagalo',
    uf: 'RJ'
  },
  {
    value: 'Carmo',
    name: 'Carmo',
    uf: 'RJ'
  },
  {
    value: 'Casimiro de Abreu',
    name: 'Casimiro de Abreu',
    uf: 'RJ'
  },
  {
    value: 'Conceicao de Macabu',
    name: 'Conceição de Macabu',
    uf: 'RJ'
  },
  {
    value: 'Cordeiro',
    name: 'Cordeiro',
    uf: 'RJ'
  },
  {
    value: 'Duas Barras',
    name: 'Duas Barras',
    uf: 'RJ'
  },
  {
    value: 'Duque de Caxias',
    name: 'Duque de Caxias',
    uf: 'RJ'
  },
  {
    value: 'Engenheiro Paulo de Frontin',
    name: 'Engenheiro Paulo de Frontin',
    uf: 'RJ'
  },
  {
    value: 'Itaborai',
    name: 'Itaboraí',
    uf: 'RJ'
  },
  {
    value: 'Itaguai',
    name: 'Itaguaí',
    uf: 'RJ'
  },
  {
    value: 'Itaocara',
    name: 'Itaocara',
    uf: 'RJ'
  },
  {
    value: 'Itaperuna',
    name: 'Itaperuna',
    uf: 'RJ'
  },
  {
    value: 'Laje do Muriae',
    name: 'Laje do Muriaé',
    uf: 'RJ'
  },
  {
    value: 'Macae',
    name: 'Macaé',
    uf: 'RJ'
  },
  {
    value: 'Mage',
    name: 'Magé',
    uf: 'RJ'
  },
  {
    value: 'Mangaratiba',
    name: 'Mangaratiba',
    uf: 'RJ'
  },
  {
    value: 'Marica',
    name: 'Maricá',
    uf: 'RJ'
  },
  {
    value: 'Mendes',
    name: 'Mendes',
    uf: 'RJ'
  },
  {
    value: 'Miguel Pereira',
    name: 'Miguel Pereira',
    uf: 'RJ'
  },
  {
    value: 'Miracema',
    name: 'Miracema',
    uf: 'RJ'
  },
  {
    value: 'Natividade',
    name: 'Natividade',
    uf: 'RJ'
  },
  {
    value: 'Nilopolis',
    name: 'Nilópolis',
    uf: 'RJ'
  },
  {
    value: 'Niteroi',
    name: 'Niterói',
    uf: 'RJ'
  },
  {
    value: 'Nova Friburgo',
    name: 'Nova Friburgo',
    uf: 'RJ'
  },
  {
    value: 'Nova Iguacu',
    name: 'Nova Iguaçu',
    uf: 'RJ'
  },
  {
    value: 'Paracambi',
    name: 'Paracambi',
    uf: 'RJ'
  },
  {
    value: 'Paraiba do Sul',
    name: 'Paraíba do Sul',
    uf: 'RJ'
  },
  {
    value: 'Paraty',
    name: 'Paraty',
    uf: 'RJ'
  },
  {
    value: 'Petropolis',
    name: 'Petrópolis',
    uf: 'RJ'
  },
  {
    value: 'Pirai',
    name: 'Piraí',
    uf: 'RJ'
  },
  {
    value: 'Porciuncula',
    name: 'Porciúncula',
    uf: 'RJ'
  },
  {
    value: 'Resende',
    name: 'Resende',
    uf: 'RJ'
  },
  {
    value: 'Rio Bonito',
    name: 'Rio Bonito',
    uf: 'RJ'
  },
  {
    value: 'Rio Claro',
    name: 'Rio Claro',
    uf: 'RJ'
  },
  {
    value: 'Rio das Flores',
    name: 'Rio das Flores',
    uf: 'RJ'
  },
  {
    value: 'Santa Maria Madalena',
    name: 'Santa Maria Madalena',
    uf: 'RJ'
  },
  {
    value: 'Santo Antonio de Padua',
    name: 'Santo Antônio de Pádua',
    uf: 'RJ'
  },
  {
    value: 'Sao Fidelis',
    name: 'São Fidélis',
    uf: 'RJ'
  },
  {
    value: 'Sao Goncalo',
    name: 'São Gonçalo',
    uf: 'RJ'
  },
  {
    value: 'Sao Joao da Barra',
    name: 'São João da Barra',
    uf: 'RJ'
  },
  {
    value: 'Sao Joao de Meriti',
    name: 'São João de Meriti',
    uf: 'RJ'
  },
  {
    value: 'Sao Pedro da Aldeia',
    name: 'São Pedro da Aldeia',
    uf: 'RJ'
  },
  {
    value: 'Sao Sebastiao do Alto',
    name: 'São Sebastião do Alto',
    uf: 'RJ'
  },
  {
    value: 'Sapucaia',
    name: 'Sapucaia',
    uf: 'RJ'
  },
  {
    value: 'Saquarema',
    name: 'Saquarema',
    uf: 'RJ'
  },
  {
    value: 'Silva Jardim',
    name: 'Silva Jardim',
    uf: 'RJ'
  },
  {
    value: 'Sumidouro',
    name: 'Sumidouro',
    uf: 'RJ'
  },
  {
    value: 'Teresopolis',
    name: 'Teresópolis',
    uf: 'RJ'
  },
  {
    value: 'Trajano de Moraes',
    name: 'Trajano de Moraes',
    uf: 'RJ'
  },
  {
    value: 'Tres Rios',
    name: 'Três Rios',
    uf: 'RJ'
  },
  {
    value: 'Valenca',
    name: 'Valença',
    uf: 'RJ'
  },
  {
    value: 'Vassouras',
    name: 'Vassouras',
    uf: 'RJ'
  },
  {
    value: 'Volta Redonda',
    name: 'Volta Redonda',
    uf: 'RJ'
  },
  {
    value: 'Arraial do Cabo',
    name: 'Arraial do Cabo',
    uf: 'RJ'
  },
  {
    value: 'Italva',
    name: 'Italva',
    uf: 'RJ'
  },
  {
    value: 'Mato Castelhano',
    name: 'Mato Castelhano',
    uf: 'RS'
  },
  {
    value: 'Mormaco',
    name: 'Mormaço',
    uf: 'RS'
  },
  {
    value: 'Muliterno',
    name: 'Muliterno',
    uf: 'RS'
  },
  {
    value: 'Nicolau Vergueiro',
    name: 'Nicolau Vergueiro',
    uf: 'RS'
  },
  {
    value: 'Pontao',
    name: 'Pontão',
    uf: 'RS'
  },
  {
    value: 'Santo Antonio do Palma',
    name: 'Santo Antônio do Palma',
    uf: 'RS'
  },
  {
    value: 'Barra Funda',
    name: 'Barra Funda',
    uf: 'RS'
  },
  {
    value: 'Coqueiros do Sul',
    name: 'Coqueiros do Sul',
    uf: 'RS'
  },
  {
    value: 'Engenho Velho',
    name: 'Engenho Velho',
    uf: 'RS'
  },
  {
    value: 'Gramado dos Loureiros',
    name: 'Gramado dos Loureiros',
    uf: 'RS'
  },
  {
    value: 'Lagoa dos Tres Cantos',
    name: 'Lagoa dos Três Cantos',
    uf: 'RS'
  },
  {
    value: 'Nova Boa Vista',
    name: 'Nova Boa Vista',
    uf: 'RS'
  },
  {
    value: 'Rio dos Indios',
    name: 'Rio dos Índios',
    uf: 'RS'
  },
  {
    value: 'Santo Antonio do Planalto',
    name: 'Santo Antônio do Planalto',
    uf: 'RS'
  },
  {
    value: 'Barra do Rio Azul',
    name: 'Barra do Rio Azul',
    uf: 'RS'
  },
  {
    value: 'Carlos Gomes',
    name: 'Carlos Gomes',
    uf: 'RS'
  },
  {
    value: 'Centenario',
    name: 'Centenário',
    uf: 'RS'
  },
  {
    value: 'Charrua',
    name: 'Charrua',
    uf: 'RS'
  },
  {
    value: 'Ponte Preta',
    name: 'Ponte Preta',
    uf: 'RS'
  },
  {
    value: 'Ametista do Sul',
    name: 'Ametista do Sul',
    uf: 'RS'
  },
  {
    value: 'Dois Irmaos das Missoes',
    name: 'Dois Irmãos das Missões',
    uf: 'RS'
  },
  {
    value: 'Novo Tiradentes',
    name: 'Novo Tiradentes',
    uf: 'RS'
  },
  {
    value: 'Pinheirinho do Vale',
    name: 'Pinheirinho do Vale',
    uf: 'RS'
  },
  {
    value: 'Santo Expedito do Sul',
    name: 'Santo Expedito do Sul',
    uf: 'RS'
  },
  {
    value: 'Tupanci do Sul',
    name: 'Tupanci do Sul',
    uf: 'RS'
  },
  {
    value: 'Boa Vista das Missoes',
    name: 'Boa Vista das Missões',
    uf: 'RS'
  },
  {
    value: 'Lajeado do Bugre',
    name: 'Lajeado do Bugre',
    uf: 'RS'
  },
  {
    value: 'Novo Barreiro',
    name: 'Novo Barreiro',
    uf: 'RS'
  },
  {
    value: 'Sagrada Familia',
    name: 'Sagrada Família',
    uf: 'RS'
  },
  {
    value: 'Sao Jose das Missoes',
    name: 'São José das Missões',
    uf: 'RS'
  },
  {
    value: 'Nova Padua',
    name: 'Nova Pádua',
    uf: 'RS'
  },
  {
    value: 'Monte Belo do Sul',
    name: 'Monte Belo do Sul',
    uf: 'RS'
  },
  {
    value: 'Santa Tereza',
    name: 'Santa Tereza',
    uf: 'RS'
  },
  {
    value: 'Sao Valentim do Sul',
    name: 'São Valentim do Sul',
    uf: 'RS'
  },
  {
    value: 'Uniao da Serra',
    name: 'União da Serra',
    uf: 'RS'
  },
  {
    value: 'Rio de Janeiro',
    name: 'Rio de Janeiro',
    uf: 'RJ'
  },
  {
    value: 'Itatiaia',
    name: 'Itatiaia',
    uf: 'RJ'
  },
  {
    value: 'Paty do Alferes',
    name: 'Paty do Alferes',
    uf: 'RJ'
  },
  {
    value: 'Quissama',
    name: 'Quissamã',
    uf: 'RJ'
  },
  {
    value: 'Sao Jose do Vale do Rio Preto',
    name: 'São José do Vale do Rio Preto',
    uf: 'RJ'
  },
  {
    value: 'Ibitirama',
    name: 'Ibitirama',
    uf: 'ES'
  },
  {
    value: 'Campestre da Serra',
    name: 'Campestre da Serra',
    uf: 'RS'
  },
  {
    value: 'Sao Jose dos Ausentes',
    name: 'São José dos Ausentes',
    uf: 'RS'
  },
  {
    value: 'Lindolfo Collor',
    name: 'Lindolfo Collor',
    uf: 'RS'
  },
  {
    value: 'Morro Reuter',
    name: 'Morro Reuter',
    uf: 'RS'
  },
  {
    value: 'Picada Cafe',
    name: 'Picada Café',
    uf: 'RS'
  },
  {
    value: 'Presidente Lucena',
    name: 'Presidente Lucena',
    uf: 'RS'
  },
  {
    value: 'Capitao',
    name: 'Capitão',
    uf: 'RS'
  },
  {
    value: 'Itapuca',
    name: 'Itapuca',
    uf: 'RS'
  },
  {
    value: 'Colinas',
    name: 'Colinas',
    uf: 'RS'
  },
  {
    value: 'Mato Leitao',
    name: 'Mato Leitão',
    uf: 'RS'
  },
  {
    value: 'Santa Clara do Sul',
    name: 'Santa Clara do Sul',
    uf: 'RS'
  },
  {
    value: 'Serio',
    name: 'Sério',
    uf: 'RS'
  },
  {
    value: 'Travesseiro',
    name: 'Travesseiro',
    uf: 'RS'
  },
  {
    value: 'Marata',
    name: 'Maratá',
    uf: 'RS'
  },
  {
    value: 'Pareci Novo',
    name: 'Pareci Novo',
    uf: 'RS'
  },
  {
    value: 'Sao Pedro da Serra',
    name: 'São Pedro da Serra',
    uf: 'RS'
  },
  {
    value: 'Alto Feliz',
    name: 'Alto Feliz',
    uf: 'RS'
  },
  {
    value: 'Linha Nova',
    name: 'Linha Nova',
    uf: 'RS'
  },
  {
    value: 'Vale Real',
    name: 'Vale Real',
    uf: 'RS'
  },
  {
    value: 'Inhacora',
    name: 'Inhacorá',
    uf: 'RS'
  },
  {
    value: 'Vitoria das Missoes',
    name: 'Vitória das Missões',
    uf: 'RS'
  },
  {
    value: 'Coronel Barros',
    name: 'Coronel Barros',
    uf: 'RS'
  },
  {
    value: 'Novo Machado',
    name: 'Novo Machado',
    uf: 'RS'
  },
  {
    value: 'Sao Jose do Inhacora',
    name: 'São José do Inhacorá',
    uf: 'RS'
  },
  {
    value: 'Salvador das Missoes',
    name: 'Salvador das Missões',
    uf: 'RS'
  },
  {
    value: 'Sao Pedro do Butia',
    name: 'São Pedro do Butiá',
    uf: 'RS'
  },
  {
    value: 'Porto Maua',
    name: 'Porto Mauá',
    uf: 'RS'
  },
  {
    value: 'Porto Vera Cruz',
    name: 'Porto Vera Cruz',
    uf: 'RS'
  },
  {
    value: 'Barra do Guarita',
    name: 'Barra do Guarita',
    uf: 'RS'
  },
  {
    value: 'Bom Progresso',
    name: 'Bom Progresso',
    uf: 'RS'
  },
  {
    value: 'Derrubadas',
    name: 'Derrubadas',
    uf: 'RS'
  },
  {
    value: 'Sao Valerio do Sul',
    name: 'São Valério do Sul',
    uf: 'RS'
  },
  {
    value: 'Tiradentes do Sul',
    name: 'Tiradentes do Sul',
    uf: 'RS'
  },
  {
    value: 'Manoel Viana',
    name: 'Manoel Viana',
    uf: 'RS'
  },
  {
    value: 'Garruchos',
    name: 'Garruchos',
    uf: 'RS'
  },
  {
    value: 'Candiota',
    name: 'Candiota',
    uf: 'RS'
  },
  {
    value: 'Hulha Negra',
    name: 'Hulha Negra',
    uf: 'RS'
  },
  {
    value: 'Sao Jose do Povo',
    name: 'São José do Povo',
    uf: 'MT'
  },
  {
    value: 'Adamantina',
    name: 'Adamantina',
    uf: 'SP'
  },
  {
    value: 'Adolfo',
    name: 'Adolfo',
    uf: 'SP'
  },
  {
    value: 'Aguai',
    name: 'Aguaí',
    uf: 'SP'
  },
  {
    value: 'Aguas da Prata',
    name: 'Águas da Prata',
    uf: 'SP'
  },
  {
    value: 'Aguas de Lindoia',
    name: 'Águas de Lindóia',
    uf: 'SP'
  },
  {
    value: 'Aguas de Sao Pedro',
    name: 'Águas de São Pedro',
    uf: 'SP'
  },
  {
    value: 'Agudos',
    name: 'Agudos',
    uf: 'SP'
  },
  {
    value: 'Alfredo Marcondes',
    name: 'Alfredo Marcondes',
    uf: 'SP'
  },
  {
    value: 'Altair',
    name: 'Altair',
    uf: 'SP'
  },
  {
    value: 'Altinopolis',
    name: 'Altinópolis',
    uf: 'SP'
  },
  {
    value: 'Alto Alegre',
    name: 'Alto Alegre',
    uf: 'SP'
  },
  {
    value: 'Alvares Florence',
    name: 'Álvares Florence',
    uf: 'SP'
  },
  {
    value: 'Alvares Machado',
    name: 'Álvares Machado',
    uf: 'SP'
  },
  {
    value: 'Alvaro de Carvalho',
    name: 'Álvaro de Carvalho',
    uf: 'SP'
  },
  {
    value: 'Alvinlandia',
    name: 'Alvinlândia',
    uf: 'SP'
  },
  {
    value: 'Americana',
    name: 'Americana',
    uf: 'SP'
  },
  {
    value: 'Americo Brasiliense',
    name: 'Américo Brasiliense',
    uf: 'SP'
  },
  {
    value: 'Americo de Campos',
    name: 'Américo de Campos',
    uf: 'SP'
  },
  {
    value: 'Amparo',
    name: 'Amparo',
    uf: 'SP'
  },
  {
    value: 'Analandia',
    name: 'Analândia',
    uf: 'SP'
  },
  {
    value: 'Andradina',
    name: 'Andradina',
    uf: 'SP'
  },
  {
    value: 'Angatuba',
    name: 'Angatuba',
    uf: 'SP'
  },
  {
    value: 'Anhembi',
    name: 'Anhembi',
    uf: 'SP'
  },
  {
    value: 'Anhumas',
    name: 'Anhumas',
    uf: 'SP'
  },
  {
    value: 'Aparecida',
    name: 'Aparecida',
    uf: 'SP'
  },
  {
    value: "Aparecida d'Oeste",
    name: "Aparecida d'Oeste",
    uf: 'SP'
  },
  {
    value: 'Apiai',
    name: 'Apiaí',
    uf: 'SP'
  },
  {
    value: 'Aracatuba',
    name: 'Araçatuba',
    uf: 'SP'
  },
  {
    value: 'Aracoiaba da Serra',
    name: 'Araçoiaba da Serra',
    uf: 'SP'
  },
  {
    value: 'Aramina',
    name: 'Aramina',
    uf: 'SP'
  },
  {
    value: 'Arandu',
    name: 'Arandu',
    uf: 'SP'
  },
  {
    value: 'Araraquara',
    name: 'Araraquara',
    uf: 'SP'
  },
  {
    value: 'Araras',
    name: 'Araras',
    uf: 'SP'
  },
  {
    value: 'Arealva',
    name: 'Arealva',
    uf: 'SP'
  },
  {
    value: 'Areias',
    name: 'Areias',
    uf: 'SP'
  },
  {
    value: 'Areiopolis',
    name: 'Areiópolis',
    uf: 'SP'
  },
  {
    value: 'Ariranha',
    name: 'Ariranha',
    uf: 'SP'
  },
  {
    value: 'Artur Nogueira',
    name: 'Artur Nogueira',
    uf: 'SP'
  },
  {
    value: 'Aruja',
    name: 'Arujá',
    uf: 'SP'
  },
  {
    value: 'Assis',
    name: 'Assis',
    uf: 'SP'
  },
  {
    value: 'Atibaia',
    name: 'Atibaia',
    uf: 'SP'
  },
  {
    value: 'Auriflama',
    name: 'Auriflama',
    uf: 'SP'
  },
  {
    value: 'Avai',
    name: 'Avaí',
    uf: 'SP'
  },
  {
    value: 'Avanhandava',
    name: 'Avanhandava',
    uf: 'SP'
  },
  {
    value: 'Avare',
    name: 'Avaré',
    uf: 'SP'
  },
  {
    value: 'Bady Bassitt',
    name: 'Bady Bassitt',
    uf: 'SP'
  },
  {
    value: 'Balbinos',
    name: 'Balbinos',
    uf: 'SP'
  },
  {
    value: 'Balsamo',
    name: 'Bálsamo',
    uf: 'SP'
  },
  {
    value: 'Bananal',
    name: 'Bananal',
    uf: 'SP'
  },
  {
    value: 'Barbosa',
    name: 'Barbosa',
    uf: 'SP'
  },
  {
    value: 'Barao de Antonina',
    name: 'Barão de Antonina',
    uf: 'SP'
  },
  {
    value: 'Bariri',
    name: 'Bariri',
    uf: 'SP'
  },
  {
    value: 'Barra Bonita',
    name: 'Barra Bonita',
    uf: 'SP'
  },
  {
    value: 'Barra do Turvo',
    name: 'Barra do Turvo',
    uf: 'SP'
  },
  {
    value: 'Barretos',
    name: 'Barretos',
    uf: 'SP'
  },
  {
    value: 'Barrinha',
    name: 'Barrinha',
    uf: 'SP'
  },
  {
    value: 'Barueri',
    name: 'Barueri',
    uf: 'SP'
  },
  {
    value: 'Bastos',
    name: 'Bastos',
    uf: 'SP'
  },
  {
    value: 'Batatais',
    name: 'Batatais',
    uf: 'SP'
  },
  {
    value: 'Bauru',
    name: 'Bauru',
    uf: 'SP'
  },
  {
    value: 'Bebedouro',
    name: 'Bebedouro',
    uf: 'SP'
  },
  {
    value: 'Bento de Abreu',
    name: 'Bento de Abreu',
    uf: 'SP'
  },
  {
    value: 'Bernardino de Campos',
    name: 'Bernardino de Campos',
    uf: 'SP'
  },
  {
    value: 'Bilac',
    name: 'Bilac',
    uf: 'SP'
  },
  {
    value: 'Birigui',
    name: 'Birigui',
    uf: 'SP'
  },
  {
    value: 'Biritiba Mirim',
    name: 'Biritiba Mirim',
    uf: 'SP'
  },
  {
    value: 'Boa Esperanca do Sul',
    name: 'Boa Esperança do Sul',
    uf: 'SP'
  },
  {
    value: 'Bocaina',
    name: 'Bocaina',
    uf: 'SP'
  },
  {
    value: 'Bofete',
    name: 'Bofete',
    uf: 'SP'
  },
  {
    value: 'Boituva',
    name: 'Boituva',
    uf: 'SP'
  },
  {
    value: 'Bom Jesus dos Perdoes',
    name: 'Bom Jesus dos Perdões',
    uf: 'SP'
  },
  {
    value: 'Bora',
    name: 'Borá',
    uf: 'SP'
  },
  {
    value: 'Boraceia',
    name: 'Boracéia',
    uf: 'SP'
  },
  {
    value: 'Borborema',
    name: 'Borborema',
    uf: 'SP'
  },
  {
    value: 'Botucatu',
    name: 'Botucatu',
    uf: 'SP'
  },
  {
    value: 'Braganca Paulista',
    name: 'Bragança Paulista',
    uf: 'SP'
  },
  {
    value: 'Brauna',
    name: 'Braúna',
    uf: 'SP'
  },
  {
    value: 'Brodowski',
    name: 'Brodowski',
    uf: 'SP'
  },
  {
    value: 'Brotas',
    name: 'Brotas',
    uf: 'SP'
  },
  {
    value: 'Buri',
    name: 'Buri',
    uf: 'SP'
  },
  {
    value: 'Buritama',
    name: 'Buritama',
    uf: 'SP'
  },
  {
    value: 'Buritizal',
    name: 'Buritizal',
    uf: 'SP'
  },
  {
    value: 'Cabralia Paulista',
    name: 'Cabrália Paulista',
    uf: 'SP'
  },
  {
    value: 'Cabreuva',
    name: 'Cabreúva',
    uf: 'SP'
  },
  {
    value: 'Cacapava',
    name: 'Caçapava',
    uf: 'SP'
  },
  {
    value: 'Cachoeira Paulista',
    name: 'Cachoeira Paulista',
    uf: 'SP'
  },
  {
    value: 'Caconde',
    name: 'Caconde',
    uf: 'SP'
  },
  {
    value: 'Cafelandia',
    name: 'Cafelândia',
    uf: 'SP'
  },
  {
    value: 'Caiabu',
    name: 'Caiabu',
    uf: 'SP'
  },
  {
    value: 'Caieiras',
    name: 'Caieiras',
    uf: 'SP'
  },
  {
    value: 'Caiua',
    name: 'Caiuá',
    uf: 'SP'
  },
  {
    value: 'Cajamar',
    name: 'Cajamar',
    uf: 'SP'
  },
  {
    value: 'Cajobi',
    name: 'Cajobi',
    uf: 'SP'
  },
  {
    value: 'Cajuru',
    name: 'Cajuru',
    uf: 'SP'
  },
  {
    value: 'Campinas',
    name: 'Campinas',
    uf: 'SP'
  },
  {
    value: 'Campo Limpo Paulista',
    name: 'Campo Limpo Paulista',
    uf: 'SP'
  },
  {
    value: 'Campos do Jordao',
    name: 'Campos do Jordão',
    uf: 'SP'
  },
  {
    value: 'Campos Novos Paulista',
    name: 'Campos Novos Paulista',
    uf: 'SP'
  },
  {
    value: 'Cananeia',
    name: 'Cananéia',
    uf: 'SP'
  },
  {
    value: 'Candido Mota',
    name: 'Cândido Mota',
    uf: 'SP'
  },
  {
    value: 'Candido Rodrigues',
    name: 'Cândido Rodrigues',
    uf: 'SP'
  },
  {
    value: 'Capao Bonito',
    name: 'Capão Bonito',
    uf: 'SP'
  },
  {
    value: 'Capela do Alto',
    name: 'Capela do Alto',
    uf: 'SP'
  },
  {
    value: 'Capivari',
    name: 'Capivari',
    uf: 'SP'
  },
  {
    value: 'Caraguatatuba',
    name: 'Caraguatatuba',
    uf: 'SP'
  },
  {
    value: 'Carapicuiba',
    name: 'Carapicuíba',
    uf: 'SP'
  },
  {
    value: 'Cardoso',
    name: 'Cardoso',
    uf: 'SP'
  },
  {
    value: 'Casa Branca',
    name: 'Casa Branca',
    uf: 'SP'
  },
  {
    value: 'Cassia dos Coqueiros',
    name: 'Cássia dos Coqueiros',
    uf: 'SP'
  },
  {
    value: 'Castilho',
    name: 'Castilho',
    uf: 'SP'
  },
  {
    value: 'Catanduva',
    name: 'Catanduva',
    uf: 'SP'
  },
  {
    value: 'Catigua',
    name: 'Catiguá',
    uf: 'SP'
  },
  {
    value: 'Cedral',
    name: 'Cedral',
    uf: 'SP'
  },
  {
    value: 'Cerqueira Cesar',
    name: 'Cerqueira César',
    uf: 'SP'
  },
  {
    value: 'Cerquilho',
    name: 'Cerquilho',
    uf: 'SP'
  },
  {
    value: 'Cesario Lange',
    name: 'Cesário Lange',
    uf: 'SP'
  },
  {
    value: 'Charqueada',
    name: 'Charqueada',
    uf: 'SP'
  },
  {
    value: 'Chavantes',
    name: 'Chavantes',
    uf: 'SP'
  },
  {
    value: 'Clementina',
    name: 'Clementina',
    uf: 'SP'
  },
  {
    value: 'Colina',
    name: 'Colina',
    uf: 'SP'
  },
  {
    value: 'Colombia',
    name: 'Colômbia',
    uf: 'SP'
  },
  {
    value: 'Conchal',
    name: 'Conchal',
    uf: 'SP'
  },
  {
    value: 'Conchas',
    name: 'Conchas',
    uf: 'SP'
  },
  {
    value: 'Cordeiropolis',
    name: 'Cordeirópolis',
    uf: 'SP'
  },
  {
    value: 'Coroados',
    name: 'Coroados',
    uf: 'SP'
  },
  {
    value: 'Coronel Macedo',
    name: 'Coronel Macedo',
    uf: 'SP'
  },
  {
    value: 'Corumbatai',
    name: 'Corumbataí',
    uf: 'SP'
  },
  {
    value: 'Cosmopolis',
    name: 'Cosmópolis',
    uf: 'SP'
  },
  {
    value: 'Cosmorama',
    name: 'Cosmorama',
    uf: 'SP'
  },
  {
    value: 'Cotia',
    name: 'Cotia',
    uf: 'SP'
  },
  {
    value: 'Cravinhos',
    name: 'Cravinhos',
    uf: 'SP'
  },
  {
    value: 'Cristais Paulista',
    name: 'Cristais Paulista',
    uf: 'SP'
  },
  {
    value: 'Cruzalia',
    name: 'Cruzália',
    uf: 'SP'
  },
  {
    value: 'Cruzeiro',
    name: 'Cruzeiro',
    uf: 'SP'
  },
  {
    value: 'Cubatao',
    name: 'Cubatão',
    uf: 'SP'
  },
  {
    value: 'Cunha',
    name: 'Cunha',
    uf: 'SP'
  },
  {
    value: 'Descalvado',
    name: 'Descalvado',
    uf: 'SP'
  },
  {
    value: 'Diadema',
    name: 'Diadema',
    uf: 'SP'
  },
  {
    value: 'Divinolandia',
    name: 'Divinolândia',
    uf: 'SP'
  },
  {
    value: 'Dobrada',
    name: 'Dobrada',
    uf: 'SP'
  },
  {
    value: 'Dois Corregos',
    name: 'Dois Córregos',
    uf: 'SP'
  },
  {
    value: 'Dolcinopolis',
    name: 'Dolcinópolis',
    uf: 'SP'
  },
  {
    value: 'Dourado',
    name: 'Dourado',
    uf: 'SP'
  },
  {
    value: 'Dracena',
    name: 'Dracena',
    uf: 'SP'
  },
  {
    value: 'Duartina',
    name: 'Duartina',
    uf: 'SP'
  },
  {
    value: 'Dumont',
    name: 'Dumont',
    uf: 'SP'
  },
  {
    value: 'Echapora',
    name: 'Echaporã',
    uf: 'SP'
  },
  {
    value: 'Eldorado',
    name: 'Eldorado',
    uf: 'SP'
  },
  {
    value: 'Elias Fausto',
    name: 'Elias Fausto',
    uf: 'SP'
  },
  {
    value: 'Embu das Artes',
    name: 'Embu das Artes',
    uf: 'SP'
  },
  {
    value: 'Embu-Guacu',
    name: 'Embu-Guaçu',
    uf: 'SP'
  },
  {
    value: "Estrela d'Oeste",
    name: "Estrela d'Oeste",
    uf: 'SP'
  },
  {
    value: 'Estrela do Norte',
    name: 'Estrela do Norte',
    uf: 'SP'
  },
  {
    value: 'Fartura',
    name: 'Fartura',
    uf: 'SP'
  },
  {
    value: 'Fernandopolis',
    name: 'Fernandópolis',
    uf: 'SP'
  },
  {
    value: 'Fernando Prestes',
    name: 'Fernando Prestes',
    uf: 'SP'
  },
  {
    value: 'Ferraz de Vasconcelos',
    name: 'Ferraz de Vasconcelos',
    uf: 'SP'
  },
  {
    value: 'Flora Rica',
    name: 'Flora Rica',
    uf: 'SP'
  },
  {
    value: 'Floreal',
    name: 'Floreal',
    uf: 'SP'
  },
  {
    value: 'Florida Paulista',
    name: 'Flórida Paulista',
    uf: 'SP'
  },
  {
    value: 'Florinea',
    name: 'Florínea',
    uf: 'SP'
  },
  {
    value: 'Franca',
    name: 'Franca',
    uf: 'SP'
  },
  {
    value: 'Francisco Morato',
    name: 'Francisco Morato',
    uf: 'SP'
  },
  {
    value: 'Franco da Rocha',
    name: 'Franco da Rocha',
    uf: 'SP'
  },
  {
    value: 'Gabriel Monteiro',
    name: 'Gabriel Monteiro',
    uf: 'SP'
  },
  {
    value: 'Galia',
    name: 'Gália',
    uf: 'SP'
  },
  {
    value: 'Garca',
    name: 'Garça',
    uf: 'SP'
  },
  {
    value: 'Gastao Vidigal',
    name: 'Gastão Vidigal',
    uf: 'SP'
  },
  {
    value: 'General Salgado',
    name: 'General Salgado',
    uf: 'SP'
  },
  {
    value: 'Getulina',
    name: 'Getulina',
    uf: 'SP'
  },
  {
    value: 'Glicerio',
    name: 'Glicério',
    uf: 'SP'
  },
  {
    value: 'Guaicara',
    name: 'Guaiçara',
    uf: 'SP'
  },
  {
    value: 'Guaimbe',
    name: 'Guaimbê',
    uf: 'SP'
  },
  {
    value: 'Guaira',
    name: 'Guaíra',
    uf: 'SP'
  },
  {
    value: 'Guapiacu',
    name: 'Guapiaçu',
    uf: 'SP'
  },
  {
    value: 'Guapiara',
    name: 'Guapiara',
    uf: 'SP'
  },
  {
    value: 'Guara',
    name: 'Guará',
    uf: 'SP'
  },
  {
    value: 'Guaracai',
    name: 'Guaraçaí',
    uf: 'SP'
  },
  {
    value: 'Guaraci',
    name: 'Guaraci',
    uf: 'SP'
  },
  {
    value: "Guarani d'Oeste",
    name: "Guarani d'Oeste",
    uf: 'SP'
  },
  {
    value: 'Guaranta',
    name: 'Guarantã',
    uf: 'SP'
  },
  {
    value: 'Guararapes',
    name: 'Guararapes',
    uf: 'SP'
  },
  {
    value: 'Guararema',
    name: 'Guararema',
    uf: 'SP'
  },
  {
    value: 'Guaratingueta',
    name: 'Guaratinguetá',
    uf: 'SP'
  },
  {
    value: 'Guarei',
    name: 'Guareí',
    uf: 'SP'
  },
  {
    value: 'Guariba',
    name: 'Guariba',
    uf: 'SP'
  },
  {
    value: 'Guaruja',
    name: 'Guarujá',
    uf: 'SP'
  },
  {
    value: 'Guarulhos',
    name: 'Guarulhos',
    uf: 'SP'
  },
  {
    value: 'Guzolandia',
    name: 'Guzolândia',
    uf: 'SP'
  },
  {
    value: 'Herculandia',
    name: 'Herculândia',
    uf: 'SP'
  },
  {
    value: 'Iacanga',
    name: 'Iacanga',
    uf: 'SP'
  },
  {
    value: 'Iacri',
    name: 'Iacri',
    uf: 'SP'
  },
  {
    value: 'Ibate',
    name: 'Ibaté',
    uf: 'SP'
  },
  {
    value: 'Ibira',
    name: 'Ibirá',
    uf: 'SP'
  },
  {
    value: 'Ibirarema',
    name: 'Ibirarema',
    uf: 'SP'
  },
  {
    value: 'Ibitinga',
    name: 'Ibitinga',
    uf: 'SP'
  },
  {
    value: 'Ibiuna',
    name: 'Ibiúna',
    uf: 'SP'
  },
  {
    value: 'Icem',
    name: 'Icém',
    uf: 'SP'
  },
  {
    value: 'Iepe',
    name: 'Iepê',
    uf: 'SP'
  },
  {
    value: 'Igaracu do Tiete',
    name: 'Igaraçu do Tietê',
    uf: 'SP'
  },
  {
    value: 'Igarapava',
    name: 'Igarapava',
    uf: 'SP'
  },
  {
    value: 'Igarata',
    name: 'Igaratá',
    uf: 'SP'
  },
  {
    value: 'Iguape',
    name: 'Iguape',
    uf: 'SP'
  },
  {
    value: 'Ilhabela',
    name: 'Ilhabela',
    uf: 'SP'
  },
  {
    value: 'Indaiatuba',
    name: 'Indaiatuba',
    uf: 'SP'
  },
  {
    value: 'Indiana',
    name: 'Indiana',
    uf: 'SP'
  },
  {
    value: 'Indiapora',
    name: 'Indiaporã',
    uf: 'SP'
  },
  {
    value: 'Inubia Paulista',
    name: 'Inúbia Paulista',
    uf: 'SP'
  },
  {
    value: 'Ipaussu',
    name: 'Ipaussu',
    uf: 'SP'
  },
  {
    value: 'Ipero',
    name: 'Iperó',
    uf: 'SP'
  },
  {
    value: 'Ipeuna',
    name: 'Ipeúna',
    uf: 'SP'
  },
  {
    value: 'Iporanga',
    name: 'Iporanga',
    uf: 'SP'
  },
  {
    value: 'Ipua',
    name: 'Ipuã',
    uf: 'SP'
  },
  {
    value: 'Iracemapolis',
    name: 'Iracemápolis',
    uf: 'SP'
  },
  {
    value: 'Irapua',
    name: 'Irapuã',
    uf: 'SP'
  },
  {
    value: 'Irapuru',
    name: 'Irapuru',
    uf: 'SP'
  },
  {
    value: 'Itabera',
    name: 'Itaberá',
    uf: 'SP'
  },
  {
    value: 'Itai',
    name: 'Itaí',
    uf: 'SP'
  },
  {
    value: 'Itajobi',
    name: 'Itajobi',
    uf: 'SP'
  },
  {
    value: 'Itaju',
    name: 'Itaju',
    uf: 'SP'
  },
  {
    value: 'Itanhaem',
    name: 'Itanhaém',
    uf: 'SP'
  },
  {
    value: 'Itapecerica da Serra',
    name: 'Itapecerica da Serra',
    uf: 'SP'
  },
  {
    value: 'Itapetininga',
    name: 'Itapetininga',
    uf: 'SP'
  },
  {
    value: 'Itapeva',
    name: 'Itapeva',
    uf: 'SP'
  },
  {
    value: 'Itapevi',
    name: 'Itapevi',
    uf: 'SP'
  },
  {
    value: 'Itapira',
    name: 'Itapira',
    uf: 'SP'
  },
  {
    value: 'Itapolis',
    name: 'Itápolis',
    uf: 'SP'
  },
  {
    value: 'Itaporanga',
    name: 'Itaporanga',
    uf: 'SP'
  },
  {
    value: 'Itapui',
    name: 'Itapuí',
    uf: 'SP'
  },
  {
    value: 'Itapura',
    name: 'Itapura',
    uf: 'SP'
  },
  {
    value: 'Itaquaquecetuba',
    name: 'Itaquaquecetuba',
    uf: 'SP'
  },
  {
    value: 'Itarare',
    name: 'Itararé',
    uf: 'SP'
  },
  {
    value: 'Itariri',
    name: 'Itariri',
    uf: 'SP'
  },
  {
    value: 'Itatiba',
    name: 'Itatiba',
    uf: 'SP'
  },
  {
    value: 'Itatinga',
    name: 'Itatinga',
    uf: 'SP'
  },
  {
    value: 'Itirapina',
    name: 'Itirapina',
    uf: 'SP'
  },
  {
    value: 'Itirapua',
    name: 'Itirapuã',
    uf: 'SP'
  },
  {
    value: 'Itobi',
    name: 'Itobi',
    uf: 'SP'
  },
  {
    value: 'Itu',
    name: 'Itu',
    uf: 'SP'
  },
  {
    value: 'Itupeva',
    name: 'Itupeva',
    uf: 'SP'
  },
  {
    value: 'Ituverava',
    name: 'Ituverava',
    uf: 'SP'
  },
  {
    value: 'Jaborandi',
    name: 'Jaborandi',
    uf: 'SP'
  },
  {
    value: 'Jaboticabal',
    name: 'Jaboticabal',
    uf: 'SP'
  },
  {
    value: 'Jacarei',
    name: 'Jacareí',
    uf: 'SP'
  },
  {
    value: 'Jaci',
    name: 'Jaci',
    uf: 'SP'
  },
  {
    value: 'Jacupiranga',
    name: 'Jacupiranga',
    uf: 'SP'
  },
  {
    value: 'Jaguariuna',
    name: 'Jaguariúna',
    uf: 'SP'
  },
  {
    value: 'Jales',
    name: 'Jales',
    uf: 'SP'
  },
  {
    value: 'Jambeiro',
    name: 'Jambeiro',
    uf: 'SP'
  },
  {
    value: 'Jandira',
    name: 'Jandira',
    uf: 'SP'
  },
  {
    value: 'Jardinopolis',
    name: 'Jardinópolis',
    uf: 'SP'
  },
  {
    value: 'Jarinu',
    name: 'Jarinu',
    uf: 'SP'
  },
  {
    value: 'Jau',
    name: 'Jaú',
    uf: 'SP'
  },
  {
    value: 'Jeriquara',
    name: 'Jeriquara',
    uf: 'SP'
  },
  {
    value: 'Joanopolis',
    name: 'Joanópolis',
    uf: 'SP'
  },
  {
    value: 'Joao Ramalho',
    name: 'João Ramalho',
    uf: 'SP'
  },
  {
    value: 'Jose Bonifacio',
    name: 'José Bonifácio',
    uf: 'SP'
  },
  {
    value: 'Julio Mesquita',
    name: 'Júlio Mesquita',
    uf: 'SP'
  },
  {
    value: 'Jundiai',
    name: 'Jundiaí',
    uf: 'SP'
  },
  {
    value: 'Junqueiropolis',
    name: 'Junqueirópolis',
    uf: 'SP'
  },
  {
    value: 'Juquia',
    name: 'Juquiá',
    uf: 'SP'
  },
  {
    value: 'Juquitiba',
    name: 'Juquitiba',
    uf: 'SP'
  },
  {
    value: 'Lagoinha',
    name: 'Lagoinha',
    uf: 'SP'
  },
  {
    value: 'Laranjal Paulista',
    name: 'Laranjal Paulista',
    uf: 'SP'
  },
  {
    value: 'Lavinia',
    name: 'Lavínia',
    uf: 'SP'
  },
  {
    value: 'Lavrinhas',
    name: 'Lavrinhas',
    uf: 'SP'
  },
  {
    value: 'Leme',
    name: 'Leme',
    uf: 'SP'
  },
  {
    value: 'Lencois Paulista',
    name: 'Lençóis Paulista',
    uf: 'SP'
  },
  {
    value: 'Limeira',
    name: 'Limeira',
    uf: 'SP'
  },
  {
    value: 'Lindoia',
    name: 'Lindóia',
    uf: 'SP'
  },
  {
    value: 'Lins',
    name: 'Lins',
    uf: 'SP'
  },
  {
    value: 'Lorena',
    name: 'Lorena',
    uf: 'SP'
  },
  {
    value: 'Louveira',
    name: 'Louveira',
    uf: 'SP'
  },
  {
    value: 'Lucelia',
    name: 'Lucélia',
    uf: 'SP'
  },
  {
    value: 'Lucianopolis',
    name: 'Lucianópolis',
    uf: 'SP'
  },
  {
    value: 'Luis Antonio',
    name: 'Luís Antônio',
    uf: 'SP'
  },
  {
    value: 'Luiziania',
    name: 'Luiziânia',
    uf: 'SP'
  },
  {
    value: 'Lupercio',
    name: 'Lupércio',
    uf: 'SP'
  },
  {
    value: 'Lutecia',
    name: 'Lutécia',
    uf: 'SP'
  },
  {
    value: 'Macatuba',
    name: 'Macatuba',
    uf: 'SP'
  },
  {
    value: 'Macaubal',
    name: 'Macaubal',
    uf: 'SP'
  },
  {
    value: 'Macedonia',
    name: 'Macedônia',
    uf: 'SP'
  },
  {
    value: 'Magda',
    name: 'Magda',
    uf: 'SP'
  },
  {
    value: 'Mairinque',
    name: 'Mairinque',
    uf: 'SP'
  },
  {
    value: 'Mairipora',
    name: 'Mairiporã',
    uf: 'SP'
  },
  {
    value: 'Manduri',
    name: 'Manduri',
    uf: 'SP'
  },
  {
    value: 'Maraba Paulista',
    name: 'Marabá Paulista',
    uf: 'SP'
  },
  {
    value: 'Maracai',
    name: 'Maracaí',
    uf: 'SP'
  },
  {
    value: 'Mariapolis',
    name: 'Mariápolis',
    uf: 'SP'
  },
  {
    value: 'Marilia',
    name: 'Marília',
    uf: 'SP'
  },
  {
    value: 'Marinopolis',
    name: 'Marinópolis',
    uf: 'SP'
  },
  {
    value: 'Martinopolis',
    name: 'Martinópolis',
    uf: 'SP'
  },
  {
    value: 'Matao',
    name: 'Matão',
    uf: 'SP'
  },
  {
    value: 'Maua',
    name: 'Mauá',
    uf: 'SP'
  },
  {
    value: 'Mendonca',
    name: 'Mendonça',
    uf: 'SP'
  },
  {
    value: 'Meridiano',
    name: 'Meridiano',
    uf: 'SP'
  },
  {
    value: 'Miguelopolis',
    name: 'Miguelópolis',
    uf: 'SP'
  },
  {
    value: 'Mineiros do Tiete',
    name: 'Mineiros do Tietê',
    uf: 'SP'
  },
  {
    value: 'Miracatu',
    name: 'Miracatu',
    uf: 'SP'
  },
  {
    value: 'Mira Estrela',
    name: 'Mira Estrela',
    uf: 'SP'
  },
  {
    value: 'Mirandopolis',
    name: 'Mirandópolis',
    uf: 'SP'
  },
  {
    value: 'Mirante do Paranapanema',
    name: 'Mirante do Paranapanema',
    uf: 'SP'
  },
  {
    value: 'Mirassol',
    name: 'Mirassol',
    uf: 'SP'
  },
  {
    value: 'Mirassolandia',
    name: 'Mirassolândia',
    uf: 'SP'
  },
  {
    value: 'Mococa',
    name: 'Mococa',
    uf: 'SP'
  },
  {
    value: 'Mogi das Cruzes',
    name: 'Mogi das Cruzes',
    uf: 'SP'
  },
  {
    value: 'Mogi Guacu',
    name: 'Mogi Guaçu',
    uf: 'SP'
  },
  {
    value: 'Mogi Mirim',
    name: 'Mogi Mirim',
    uf: 'SP'
  },
  {
    value: 'Mombuca',
    name: 'Mombuca',
    uf: 'SP'
  },
  {
    value: 'Moncoes',
    name: 'Monções',
    uf: 'SP'
  },
  {
    value: 'Mongagua',
    name: 'Mongaguá',
    uf: 'SP'
  },
  {
    value: 'Monte Alegre do Sul',
    name: 'Monte Alegre do Sul',
    uf: 'SP'
  },
  {
    value: 'Monte Alto',
    name: 'Monte Alto',
    uf: 'SP'
  },
  {
    value: 'Monte Aprazivel',
    name: 'Monte Aprazível',
    uf: 'SP'
  },
  {
    value: 'Monte Azul Paulista',
    name: 'Monte Azul Paulista',
    uf: 'SP'
  },
  {
    value: 'Monte Castelo',
    name: 'Monte Castelo',
    uf: 'SP'
  },
  {
    value: 'Monteiro Lobato',
    name: 'Monteiro Lobato',
    uf: 'SP'
  },
  {
    value: 'Monte Mor',
    name: 'Monte Mor',
    uf: 'SP'
  },
  {
    value: 'Morro Agudo',
    name: 'Morro Agudo',
    uf: 'SP'
  },
  {
    value: 'Morungaba',
    name: 'Morungaba',
    uf: 'SP'
  },
  {
    value: 'Murutinga do Sul',
    name: 'Murutinga do Sul',
    uf: 'SP'
  },
  {
    value: 'Narandiba',
    name: 'Narandiba',
    uf: 'SP'
  },
  {
    value: 'Natividade da Serra',
    name: 'Natividade da Serra',
    uf: 'SP'
  },
  {
    value: 'Nazare Paulista',
    name: 'Nazaré Paulista',
    uf: 'SP'
  },
  {
    value: 'Neves Paulista',
    name: 'Neves Paulista',
    uf: 'SP'
  },
  {
    value: 'Nhandeara',
    name: 'Nhandeara',
    uf: 'SP'
  },
  {
    value: 'Nipoa',
    name: 'Nipoã',
    uf: 'SP'
  },
  {
    value: 'Nova Alianca',
    name: 'Nova Aliança',
    uf: 'SP'
  },
  {
    value: 'Nova Europa',
    name: 'Nova Europa',
    uf: 'SP'
  },
  {
    value: 'Nova Granada',
    name: 'Nova Granada',
    uf: 'SP'
  },
  {
    value: 'Nova Guataporanga',
    name: 'Nova Guataporanga',
    uf: 'SP'
  },
  {
    value: 'Nova Independencia',
    name: 'Nova Independência',
    uf: 'SP'
  },
  {
    value: 'Nova Luzitania',
    name: 'Nova Luzitânia',
    uf: 'SP'
  },
  {
    value: 'Nova Odessa',
    name: 'Nova Odessa',
    uf: 'SP'
  },
  {
    value: 'Novo Horizonte',
    name: 'Novo Horizonte',
    uf: 'SP'
  },
  {
    value: 'Nuporanga',
    name: 'Nuporanga',
    uf: 'SP'
  },
  {
    value: 'Ocaucu',
    name: 'Ocauçu',
    uf: 'SP'
  },
  {
    value: 'Oleo',
    name: 'Óleo',
    uf: 'SP'
  },
  {
    value: 'Olimpia',
    name: 'Olímpia',
    uf: 'SP'
  },
  {
    value: 'Onda Verde',
    name: 'Onda Verde',
    uf: 'SP'
  },
  {
    value: 'Oriente',
    name: 'Oriente',
    uf: 'SP'
  },
  {
    value: 'Orindiuva',
    name: 'Orindiúva',
    uf: 'SP'
  },
  {
    value: 'Orlandia',
    name: 'Orlândia',
    uf: 'SP'
  },
  {
    value: 'Osasco',
    name: 'Osasco',
    uf: 'SP'
  },
  {
    value: 'Oscar Bressane',
    name: 'Oscar Bressane',
    uf: 'SP'
  },
  {
    value: 'Osvaldo Cruz',
    name: 'Osvaldo Cruz',
    uf: 'SP'
  },
  {
    value: 'Ourinhos',
    name: 'Ourinhos',
    uf: 'SP'
  },
  {
    value: 'Ouro Verde',
    name: 'Ouro Verde',
    uf: 'SP'
  },
  {
    value: 'Pacaembu',
    name: 'Pacaembu',
    uf: 'SP'
  },
  {
    value: 'Palestina',
    name: 'Palestina',
    uf: 'SP'
  },
  {
    value: 'Palmares Paulista',
    name: 'Palmares Paulista',
    uf: 'SP'
  },
  {
    value: "Palmeira d'Oeste",
    name: "Palmeira d'Oeste",
    uf: 'SP'
  },
  {
    value: 'Palmital',
    name: 'Palmital',
    uf: 'SP'
  },
  {
    value: 'Panorama',
    name: 'Panorama',
    uf: 'SP'
  },
  {
    value: 'Paraguacu Paulista',
    name: 'Paraguaçu Paulista',
    uf: 'SP'
  },
  {
    value: 'Paraibuna',
    name: 'Paraibuna',
    uf: 'SP'
  },
  {
    value: 'Paraiso',
    name: 'Paraíso',
    uf: 'SP'
  },
  {
    value: 'Paranapanema',
    name: 'Paranapanema',
    uf: 'SP'
  },
  {
    value: 'Paranapua',
    name: 'Paranapuã',
    uf: 'SP'
  },
  {
    value: 'Parapua',
    name: 'Parapuã',
    uf: 'SP'
  },
  {
    value: 'Pardinho',
    name: 'Pardinho',
    uf: 'SP'
  },
  {
    value: 'Pariquera-Acu',
    name: 'Pariquera-Açu',
    uf: 'SP'
  },
  {
    value: 'Patrocinio Paulista',
    name: 'Patrocínio Paulista',
    uf: 'SP'
  },
  {
    value: 'Pauliceia',
    name: 'Paulicéia',
    uf: 'SP'
  },
  {
    value: 'Paulinia',
    name: 'Paulínia',
    uf: 'SP'
  },
  {
    value: 'Paulo de Faria',
    name: 'Paulo de Faria',
    uf: 'SP'
  },
  {
    value: 'Pederneiras',
    name: 'Pederneiras',
    uf: 'SP'
  },
  {
    value: 'Pedra Bela',
    name: 'Pedra Bela',
    uf: 'SP'
  },
  {
    value: 'Pedranopolis',
    name: 'Pedranópolis',
    uf: 'SP'
  },
  {
    value: 'Pedregulho',
    name: 'Pedregulho',
    uf: 'SP'
  },
  {
    value: 'Pedreira',
    name: 'Pedreira',
    uf: 'SP'
  },
  {
    value: 'Pedro de Toledo',
    name: 'Pedro de Toledo',
    uf: 'SP'
  },
  {
    value: 'Penapolis',
    name: 'Penápolis',
    uf: 'SP'
  },
  {
    value: 'Pereira Barreto',
    name: 'Pereira Barreto',
    uf: 'SP'
  },
  {
    value: 'Pereiras',
    name: 'Pereiras',
    uf: 'SP'
  },
  {
    value: 'Peruibe',
    name: 'Peruíbe',
    uf: 'SP'
  },
  {
    value: 'Piacatu',
    name: 'Piacatu',
    uf: 'SP'
  },
  {
    value: 'Piedade',
    name: 'Piedade',
    uf: 'SP'
  },
  {
    value: 'Pilar do Sul',
    name: 'Pilar do Sul',
    uf: 'SP'
  },
  {
    value: 'Pindamonhangaba',
    name: 'Pindamonhangaba',
    uf: 'SP'
  },
  {
    value: 'Pindorama',
    name: 'Pindorama',
    uf: 'SP'
  },
  {
    value: 'Espirito Santo do Pinhal',
    name: 'Espírito Santo do Pinhal',
    uf: 'SP'
  },
  {
    value: 'Pinhalzinho',
    name: 'Pinhalzinho',
    uf: 'SP'
  },
  {
    value: 'Piquerobi',
    name: 'Piquerobi',
    uf: 'SP'
  },
  {
    value: 'Piquete',
    name: 'Piquete',
    uf: 'SP'
  },
  {
    value: 'Piracaia',
    name: 'Piracaia',
    uf: 'SP'
  },
  {
    value: 'Piracicaba',
    name: 'Piracicaba',
    uf: 'SP'
  },
  {
    value: 'Piraju',
    name: 'Piraju',
    uf: 'SP'
  },
  {
    value: 'Pirajui',
    name: 'Pirajuí',
    uf: 'SP'
  },
  {
    value: 'Pirangi',
    name: 'Pirangi',
    uf: 'SP'
  },
  {
    value: 'Pirapora do Bom Jesus',
    name: 'Pirapora do Bom Jesus',
    uf: 'SP'
  },
  {
    value: 'Pirapozinho',
    name: 'Pirapozinho',
    uf: 'SP'
  },
  {
    value: 'Pirassununga',
    name: 'Pirassununga',
    uf: 'SP'
  },
  {
    value: 'Piratininga',
    name: 'Piratininga',
    uf: 'SP'
  },
  {
    value: 'Pitangueiras',
    name: 'Pitangueiras',
    uf: 'SP'
  },
  {
    value: 'Planalto',
    name: 'Planalto',
    uf: 'SP'
  },
  {
    value: 'Platina',
    name: 'Platina',
    uf: 'SP'
  },
  {
    value: 'Poa',
    name: 'Poá',
    uf: 'SP'
  },
  {
    value: 'Poloni',
    name: 'Poloni',
    uf: 'SP'
  },
  {
    value: 'Pompeia',
    name: 'Pompéia',
    uf: 'SP'
  },
  {
    value: 'Pongai',
    name: 'Pongaí',
    uf: 'SP'
  },
  {
    value: 'Pontal',
    name: 'Pontal',
    uf: 'SP'
  },
  {
    value: 'Pontes Gestal',
    name: 'Pontes Gestal',
    uf: 'SP'
  },
  {
    value: 'Populina',
    name: 'Populina',
    uf: 'SP'
  },
  {
    value: 'Porangaba',
    name: 'Porangaba',
    uf: 'SP'
  },
  {
    value: 'Porto Feliz',
    name: 'Porto Feliz',
    uf: 'SP'
  },
  {
    value: 'Porto Ferreira',
    name: 'Porto Ferreira',
    uf: 'SP'
  },
  {
    value: 'Potirendaba',
    name: 'Potirendaba',
    uf: 'SP'
  },
  {
    value: 'Pradopolis',
    name: 'Pradópolis',
    uf: 'SP'
  },
  {
    value: 'Praia Grande',
    name: 'Praia Grande',
    uf: 'SP'
  },
  {
    value: 'Presidente Alves',
    name: 'Presidente Alves',
    uf: 'SP'
  },
  {
    value: 'Presidente Bernardes',
    name: 'Presidente Bernardes',
    uf: 'SP'
  },
  {
    value: 'Presidente Epitacio',
    name: 'Presidente Epitácio',
    uf: 'SP'
  },
  {
    value: 'Presidente Prudente',
    name: 'Presidente Prudente',
    uf: 'SP'
  },
  {
    value: 'Presidente Venceslau',
    name: 'Presidente Venceslau',
    uf: 'SP'
  },
  {
    value: 'Promissao',
    name: 'Promissão',
    uf: 'SP'
  },
  {
    value: 'Quata',
    name: 'Quatá',
    uf: 'SP'
  },
  {
    value: 'Queiroz',
    name: 'Queiroz',
    uf: 'SP'
  },
  {
    value: 'Queluz',
    name: 'Queluz',
    uf: 'SP'
  },
  {
    value: 'Quintana',
    name: 'Quintana',
    uf: 'SP'
  },
  {
    value: 'Rafard',
    name: 'Rafard',
    uf: 'SP'
  },
  {
    value: 'Rancharia',
    name: 'Rancharia',
    uf: 'SP'
  },
  {
    value: 'Redencao da Serra',
    name: 'Redenção da Serra',
    uf: 'SP'
  },
  {
    value: 'Regente Feijo',
    name: 'Regente Feijó',
    uf: 'SP'
  },
  {
    value: 'Reginopolis',
    name: 'Reginópolis',
    uf: 'SP'
  },
  {
    value: 'Registro',
    name: 'Registro',
    uf: 'SP'
  },
  {
    value: 'Restinga',
    name: 'Restinga',
    uf: 'SP'
  },
  {
    value: 'Ribeira',
    name: 'Ribeira',
    uf: 'SP'
  },
  {
    value: 'Ribeirao Bonito',
    name: 'Ribeirão Bonito',
    uf: 'SP'
  },
  {
    value: 'Ribeirao Branco',
    name: 'Ribeirão Branco',
    uf: 'SP'
  },
  {
    value: 'Ribeirao Corrente',
    name: 'Ribeirão Corrente',
    uf: 'SP'
  },
  {
    value: 'Ribeirao do Sul',
    name: 'Ribeirão do Sul',
    uf: 'SP'
  },
  {
    value: 'Ribeirao Pires',
    name: 'Ribeirão Pires',
    uf: 'SP'
  },
  {
    value: 'Ribeirao Preto',
    name: 'Ribeirão Preto',
    uf: 'SP'
  },
  {
    value: 'Riversul',
    name: 'Riversul',
    uf: 'SP'
  },
  {
    value: 'Rifaina',
    name: 'Rifaina',
    uf: 'SP'
  },
  {
    value: 'Rincao',
    name: 'Rincão',
    uf: 'SP'
  },
  {
    value: 'Rinopolis',
    name: 'Rinópolis',
    uf: 'SP'
  },
  {
    value: 'Rio Claro',
    name: 'Rio Claro',
    uf: 'SP'
  },
  {
    value: 'Rio das Pedras',
    name: 'Rio das Pedras',
    uf: 'SP'
  },
  {
    value: 'Rio Grande da Serra',
    name: 'Rio Grande da Serra',
    uf: 'SP'
  },
  {
    value: 'Riolandia',
    name: 'Riolândia',
    uf: 'SP'
  },
  {
    value: 'Roseira',
    name: 'Roseira',
    uf: 'SP'
  },
  {
    value: 'Rubiacea',
    name: 'Rubiácea',
    uf: 'SP'
  },
  {
    value: 'Rubineia',
    name: 'Rubinéia',
    uf: 'SP'
  },
  {
    value: 'Sabino',
    name: 'Sabino',
    uf: 'SP'
  },
  {
    value: 'Sagres',
    name: 'Sagres',
    uf: 'SP'
  },
  {
    value: 'Sales',
    name: 'Sales',
    uf: 'SP'
  },
  {
    value: 'Sales Oliveira',
    name: 'Sales Oliveira',
    uf: 'SP'
  },
  {
    value: 'Salesopolis',
    name: 'Salesópolis',
    uf: 'SP'
  },
  {
    value: 'Salmourao',
    name: 'Salmourão',
    uf: 'SP'
  },
  {
    value: 'Salto',
    name: 'Salto',
    uf: 'SP'
  },
  {
    value: 'Salto de Pirapora',
    name: 'Salto de Pirapora',
    uf: 'SP'
  },
  {
    value: 'Salto Grande',
    name: 'Salto Grande',
    uf: 'SP'
  },
  {
    value: 'Sandovalina',
    name: 'Sandovalina',
    uf: 'SP'
  },
  {
    value: 'Santa Adelia',
    name: 'Santa Adélia',
    uf: 'SP'
  },
  {
    value: 'Santa Albertina',
    name: 'Santa Albertina',
    uf: 'SP'
  },
  {
    value: "Santa Barbara d'Oeste",
    name: "Santa Bárbara d'Oeste",
    uf: 'SP'
  },
  {
    value: 'Aguas de Santa Barbara',
    name: 'Águas de Santa Bárbara',
    uf: 'SP'
  },
  {
    value: 'Santa Branca',
    name: 'Santa Branca',
    uf: 'SP'
  },
  {
    value: "Santa Clara d'Oeste",
    name: "Santa Clara d'Oeste",
    uf: 'SP'
  },
  {
    value: 'Santa Cruz da Conceicao',
    name: 'Santa Cruz da Conceição',
    uf: 'SP'
  },
  {
    value: 'Santa Cruz das Palmeiras',
    name: 'Santa Cruz das Palmeiras',
    uf: 'SP'
  },
  {
    value: 'Santa Cruz do Rio Pardo',
    name: 'Santa Cruz do Rio Pardo',
    uf: 'SP'
  },
  {
    value: 'Santa Ernestina',
    name: 'Santa Ernestina',
    uf: 'SP'
  },
  {
    value: 'Santa Fe do Sul',
    name: 'Santa Fé do Sul',
    uf: 'SP'
  },
  {
    value: 'Santa Gertrudes',
    name: 'Santa Gertrudes',
    uf: 'SP'
  },
  {
    value: 'Santa Isabel',
    name: 'Santa Isabel',
    uf: 'SP'
  },
  {
    value: 'Santa Lucia',
    name: 'Santa Lúcia',
    uf: 'SP'
  },
  {
    value: 'Santa Maria da Serra',
    name: 'Santa Maria da Serra',
    uf: 'SP'
  },
  {
    value: 'Santa Mercedes',
    name: 'Santa Mercedes',
    uf: 'SP'
  },
  {
    value: 'Santana da Ponte Pensa',
    name: 'Santana da Ponte Pensa',
    uf: 'SP'
  },
  {
    value: 'Santana de Parnaiba',
    name: 'Santana de Parnaíba',
    uf: 'SP'
  },
  {
    value: "Santa Rita d'Oeste",
    name: "Santa Rita d'Oeste",
    uf: 'SP'
  },
  {
    value: 'Santa Rita do Passa Quatro',
    name: 'Santa Rita do Passa Quatro',
    uf: 'SP'
  },
  {
    value: 'Santa Rosa de Viterbo',
    name: 'Santa Rosa de Viterbo',
    uf: 'SP'
  },
  {
    value: 'Santo Anastacio',
    name: 'Santo Anastácio',
    uf: 'SP'
  },
  {
    value: 'Santo Andre',
    name: 'Santo André',
    uf: 'SP'
  },
  {
    value: 'Santo Antonio da Alegria',
    name: 'Santo Antônio da Alegria',
    uf: 'SP'
  },
  {
    value: 'Santo Antonio de Posse',
    name: 'Santo Antônio de Posse',
    uf: 'SP'
  },
  {
    value: 'Santo Antonio do Jardim',
    name: 'Santo Antônio do Jardim',
    uf: 'SP'
  },
  {
    value: 'Santo Antonio do Pinhal',
    name: 'Santo Antônio do Pinhal',
    uf: 'SP'
  },
  {
    value: 'Santo Expedito',
    name: 'Santo Expedito',
    uf: 'SP'
  },
  {
    value: 'Santopolis do Aguapei',
    name: 'Santópolis do Aguapeí',
    uf: 'SP'
  },
  {
    value: 'Santos',
    name: 'Santos',
    uf: 'SP'
  },
  {
    value: 'Sao Bento do Sapucai',
    name: 'São Bento do Sapucaí',
    uf: 'SP'
  },
  {
    value: 'Sao Bernardo do Campo',
    name: 'São Bernardo do Campo',
    uf: 'SP'
  },
  {
    value: 'Sao Caetano do Sul',
    name: 'São Caetano do Sul',
    uf: 'SP'
  },
  {
    value: 'Sao Carlos',
    name: 'São Carlos',
    uf: 'SP'
  },
  {
    value: 'Sao Francisco',
    name: 'São Francisco',
    uf: 'SP'
  },
  {
    value: 'Sao Joao da Boa Vista',
    name: 'São João da Boa Vista',
    uf: 'SP'
  },
  {
    value: 'Sao Joao das Duas Pontes',
    name: 'São João das Duas Pontes',
    uf: 'SP'
  },
  {
    value: "Sao Joao do Pau d'Alho",
    name: "São João do Pau d'Alho",
    uf: 'SP'
  },
  {
    value: 'Sao Joaquim da Barra',
    name: 'São Joaquim da Barra',
    uf: 'SP'
  },
  {
    value: 'Sao Jose da Bela Vista',
    name: 'São José da Bela Vista',
    uf: 'SP'
  },
  {
    value: 'Sao Jose do Barreiro',
    name: 'São José do Barreiro',
    uf: 'SP'
  },
  {
    value: 'Sao Jose do Rio Pardo',
    name: 'São José do Rio Pardo',
    uf: 'SP'
  },
  {
    value: 'Sao Jose do Rio Preto',
    name: 'São José do Rio Preto',
    uf: 'SP'
  },
  {
    value: 'Sao Jose dos Campos',
    name: 'São José dos Campos',
    uf: 'SP'
  },
  {
    value: 'Sao Luiz do Paraitinga',
    name: 'São Luiz do Paraitinga',
    uf: 'SP'
  },
  {
    value: 'Sao Manuel',
    name: 'São Manuel',
    uf: 'SP'
  },
  {
    value: 'Sao Miguel Arcanjo',
    name: 'São Miguel Arcanjo',
    uf: 'SP'
  },
  {
    value: 'Sao Paulo',
    name: 'São Paulo',
    uf: 'SP'
  },
  {
    value: 'Sao Pedro',
    name: 'São Pedro',
    uf: 'SP'
  },
  {
    value: 'Sao Pedro do Turvo',
    name: 'São Pedro do Turvo',
    uf: 'SP'
  },
  {
    value: 'Sao Roque',
    name: 'São Roque',
    uf: 'SP'
  },
  {
    value: 'Sao Sebastiao',
    name: 'São Sebastião',
    uf: 'SP'
  },
  {
    value: 'Sao Sebastiao da Grama',
    name: 'São Sebastião da Grama',
    uf: 'SP'
  },
  {
    value: 'Sao Simao',
    name: 'São Simão',
    uf: 'SP'
  },
  {
    value: 'Sao Vicente',
    name: 'São Vicente',
    uf: 'SP'
  },
  {
    value: 'Sarapui',
    name: 'Sarapuí',
    uf: 'SP'
  },
  {
    value: 'Sarutaia',
    name: 'Sarutaiá',
    uf: 'SP'
  },
  {
    value: 'Sebastianopolis do Sul',
    name: 'Sebastianópolis do Sul',
    uf: 'SP'
  },
  {
    value: 'Serra Azul',
    name: 'Serra Azul',
    uf: 'SP'
  },
  {
    value: 'Serrana',
    name: 'Serrana',
    uf: 'SP'
  },
  {
    value: 'Serra Negra',
    name: 'Serra Negra',
    uf: 'SP'
  },
  {
    value: 'Sertaozinho',
    name: 'Sertãozinho',
    uf: 'SP'
  },
  {
    value: 'Sete Barras',
    name: 'Sete Barras',
    uf: 'SP'
  },
  {
    value: 'Severinia',
    name: 'Severínia',
    uf: 'SP'
  },
  {
    value: 'Silveiras',
    name: 'Silveiras',
    uf: 'SP'
  },
  {
    value: 'Socorro',
    name: 'Socorro',
    uf: 'SP'
  },
  {
    value: 'Sorocaba',
    name: 'Sorocaba',
    uf: 'SP'
  },
  {
    value: 'Sud Mennucci',
    name: 'Sud Mennucci',
    uf: 'SP'
  },
  {
    value: 'Sumare',
    name: 'Sumaré',
    uf: 'SP'
  },
  {
    value: 'Suzano',
    name: 'Suzano',
    uf: 'SP'
  },
  {
    value: 'Tabapua',
    name: 'Tabapuã',
    uf: 'SP'
  },
  {
    value: 'Tabatinga',
    name: 'Tabatinga',
    uf: 'SP'
  },
  {
    value: 'Taboao da Serra',
    name: 'Taboão da Serra',
    uf: 'SP'
  },
  {
    value: 'Taciba',
    name: 'Taciba',
    uf: 'SP'
  },
  {
    value: 'Taguai',
    name: 'Taguaí',
    uf: 'SP'
  },
  {
    value: 'Taiacu',
    name: 'Taiaçu',
    uf: 'SP'
  },
  {
    value: 'Taiuva',
    name: 'Taiúva',
    uf: 'SP'
  },
  {
    value: 'Tambau',
    name: 'Tambaú',
    uf: 'SP'
  },
  {
    value: 'Tanabi',
    name: 'Tanabi',
    uf: 'SP'
  },
  {
    value: 'Tapirai',
    name: 'Tapiraí',
    uf: 'SP'
  },
  {
    value: 'Tapiratiba',
    name: 'Tapiratiba',
    uf: 'SP'
  },
  {
    value: 'Taquaritinga',
    name: 'Taquaritinga',
    uf: 'SP'
  },
  {
    value: 'Taquarituba',
    name: 'Taquarituba',
    uf: 'SP'
  },
  {
    value: 'Tarabai',
    name: 'Tarabai',
    uf: 'SP'
  },
  {
    value: 'Tatui',
    name: 'Tatuí',
    uf: 'SP'
  },
  {
    value: 'Taubate',
    name: 'Taubaté',
    uf: 'SP'
  },
  {
    value: 'Tejupa',
    name: 'Tejupá',
    uf: 'SP'
  },
  {
    value: 'Teodoro Sampaio',
    name: 'Teodoro Sampaio',
    uf: 'SP'
  },
  {
    value: 'Terra Roxa',
    name: 'Terra Roxa',
    uf: 'SP'
  },
  {
    value: 'Tiete',
    name: 'Tietê',
    uf: 'SP'
  },
  {
    value: 'Timburi',
    name: 'Timburi',
    uf: 'SP'
  },
  {
    value: 'Torrinha',
    name: 'Torrinha',
    uf: 'SP'
  },
  {
    value: 'Tremembe',
    name: 'Tremembé',
    uf: 'SP'
  },
  {
    value: 'Tres Fronteiras',
    name: 'Três Fronteiras',
    uf: 'SP'
  },
  {
    value: 'Tupa',
    name: 'Tupã',
    uf: 'SP'
  },
  {
    value: 'Tupi Paulista',
    name: 'Tupi Paulista',
    uf: 'SP'
  },
  {
    value: 'Turiuba',
    name: 'Turiúba',
    uf: 'SP'
  },
  {
    value: 'Turmalina',
    name: 'Turmalina',
    uf: 'SP'
  },
  {
    value: 'Ubatuba',
    name: 'Ubatuba',
    uf: 'SP'
  },
  {
    value: 'Ubirajara',
    name: 'Ubirajara',
    uf: 'SP'
  },
  {
    value: 'Uchoa',
    name: 'Uchoa',
    uf: 'SP'
  },
  {
    value: 'Uniao Paulista',
    name: 'União Paulista',
    uf: 'SP'
  },
  {
    value: 'Urania',
    name: 'Urânia',
    uf: 'SP'
  },
  {
    value: 'Uru',
    name: 'Uru',
    uf: 'SP'
  },
  {
    value: 'Urupes',
    name: 'Urupês',
    uf: 'SP'
  },
  {
    value: 'Valentim Gentil',
    name: 'Valentim Gentil',
    uf: 'SP'
  },
  {
    value: 'Valinhos',
    name: 'Valinhos',
    uf: 'SP'
  },
  {
    value: 'Valparaiso',
    name: 'Valparaíso',
    uf: 'SP'
  },
  {
    value: 'Vargem Grande do Sul',
    name: 'Vargem Grande do Sul',
    uf: 'SP'
  },
  {
    value: 'Varzea Paulista',
    name: 'Várzea Paulista',
    uf: 'SP'
  },
  {
    value: 'Vera Cruz',
    name: 'Vera Cruz',
    uf: 'SP'
  },
  {
    value: 'Vinhedo',
    name: 'Vinhedo',
    uf: 'SP'
  },
  {
    value: 'Viradouro',
    name: 'Viradouro',
    uf: 'SP'
  },
  {
    value: 'Vista Alegre do Alto',
    name: 'Vista Alegre do Alto',
    uf: 'SP'
  },
  {
    value: 'Votorantim',
    name: 'Votorantim',
    uf: 'SP'
  },
  {
    value: 'Votuporanga',
    name: 'Votuporanga',
    uf: 'SP'
  },
  {
    value: 'Borebi',
    name: 'Borebi',
    uf: 'SP'
  },
  {
    value: 'Dirce Reis',
    name: 'Dirce Reis',
    uf: 'SP'
  },
  {
    value: 'Embauba',
    name: 'Embaúba',
    uf: 'SP'
  },
  {
    value: 'Espirito Santo do Turvo',
    name: 'Espírito Santo do Turvo',
    uf: 'SP'
  },
  {
    value: 'Euclides da Cunha Paulista',
    name: 'Euclides da Cunha Paulista',
    uf: 'SP'
  },
  {
    value: 'Guatapara',
    name: 'Guatapará',
    uf: 'SP'
  },
  {
    value: 'Iaras',
    name: 'Iaras',
    uf: 'SP'
  },
  {
    value: 'Motuca',
    name: 'Motuca',
    uf: 'SP'
  },
  {
    value: 'Rosana',
    name: 'Rosana',
    uf: 'SP'
  },
  {
    value: 'Taruma',
    name: 'Tarumã',
    uf: 'SP'
  },
  {
    value: 'Vargem Grande Paulista',
    name: 'Vargem Grande Paulista',
    uf: 'SP'
  },
  {
    value: 'Sao Vendelino',
    name: 'São Vendelino',
    uf: 'RS'
  },
  {
    value: 'Imigrante',
    name: 'Imigrante',
    uf: 'RS'
  },
  {
    value: 'Imbe',
    name: 'Imbé',
    uf: 'RS'
  },
  {
    value: 'Ibirapuita',
    name: 'Ibirapuitã',
    uf: 'RS'
  },
  {
    value: 'Estacao',
    name: 'Estação',
    uf: 'RS'
  },
  {
    value: 'Vista Gaucha',
    name: 'Vista Gaúcha',
    uf: 'RS'
  },
  {
    value: 'Vista Alegre do Prata',
    name: 'Vista Alegre do Prata',
    uf: 'RS'
  },
  {
    value: 'Vista Alegre',
    name: 'Vista Alegre',
    uf: 'RS'
  },
  {
    value: 'Vila Maria',
    name: 'Vila Maria',
    uf: 'RS'
  },
  {
    value: 'Vila Flores',
    name: 'Vila Flores',
    uf: 'RS'
  },
  {
    value: 'Taquarucu do Sul',
    name: 'Taquaruçu do Sul',
    uf: 'RS'
  },
  {
    value: 'Silveira Martins',
    name: 'Silveira Martins',
    uf: 'RS'
  },
  {
    value: 'Segredo',
    name: 'Segredo',
    uf: 'RS'
  },
  {
    value: 'Vanini',
    name: 'Vanini',
    uf: 'RS'
  },
  {
    value: 'Tupandi',
    name: 'Tupandi',
    uf: 'RS'
  },
  {
    value: 'Tunas',
    name: 'Tunas',
    uf: 'RS'
  },
  {
    value: 'Trindade do Sul',
    name: 'Trindade do Sul',
    uf: 'RS'
  },
  {
    value: 'Tres Palmeiras',
    name: 'Três Palmeiras',
    uf: 'RS'
  },
  {
    value: 'Tres Cachoeiras',
    name: 'Três Cachoeiras',
    uf: 'RS'
  },
  {
    value: 'Tres Arroios',
    name: 'Três Arroios',
    uf: 'RS'
  },
  {
    value: 'Terra de Areia',
    name: 'Terra de Areia',
    uf: 'RS'
  },
  {
    value: 'Sede Nova',
    name: 'Sede Nova',
    uf: 'RS'
  },
  {
    value: 'Santa Maria do Herval',
    name: 'Santa Maria do Herval',
    uf: 'RS'
  },
  {
    value: 'Saldanha Marinho',
    name: 'Saldanha Marinho',
    uf: 'RS'
  },
  {
    value: 'Sao Miguel das Missoes',
    name: 'São Miguel das Missões',
    uf: 'RS'
  },
  {
    value: 'Sao Jose do Hortencio',
    name: 'São José do Hortêncio',
    uf: 'RS'
  },
  {
    value: 'Sao Jose do Herval',
    name: 'São José do Herval',
    uf: 'RS'
  },
  {
    value: 'Sao Jorge',
    name: 'São Jorge',
    uf: 'RS'
  },
  {
    value: 'Sao Joao da Urtiga',
    name: 'São João da Urtiga',
    uf: 'RS'
  },
  {
    value: 'Sao Domingos do Sul',
    name: 'São Domingos do Sul',
    uf: 'RS'
  },
  {
    value: 'Riozinho',
    name: 'Riozinho',
    uf: 'RS'
  },
  {
    value: 'Relvado',
    name: 'Relvado',
    uf: 'RS'
  },
  {
    value: 'Quinze de Novembro',
    name: 'Quinze de Novembro',
    uf: 'RS'
  },
  {
    value: 'Protasio Alves',
    name: 'Protásio Alves',
    uf: 'RS'
  },
  {
    value: 'Progresso',
    name: 'Progresso',
    uf: 'RS'
  },
  {
    value: 'Pouso Novo',
    name: 'Pouso Novo',
    uf: 'RS'
  },
  {
    value: 'Poco das Antas',
    name: 'Poço das Antas',
    uf: 'RS'
  },
  {
    value: 'Pirapo',
    name: 'Pirapó',
    uf: 'RS'
  },
  {
    value: 'Pinhal',
    name: 'Pinhal',
    uf: 'RS'
  },
  {
    value: 'Paverama',
    name: 'Paverama',
    uf: 'RS'
  },
  {
    value: 'Paraiso do Sul',
    name: 'Paraíso do Sul',
    uf: 'RS'
  },
  {
    value: 'Pantano Grande',
    name: 'Pantano Grande',
    uf: 'RS'
  },
  {
    value: 'Nova Roma do Sul',
    name: 'Nova Roma do Sul',
    uf: 'RS'
  },
  {
    value: 'Nova Hartz',
    name: 'Nova Hartz',
    uf: 'RS'
  },
  {
    value: 'Nova Esperanca do Sul',
    name: 'Nova Esperança do Sul',
    uf: 'RS'
  },
  {
    value: 'Nova Alvorada',
    name: 'Nova Alvorada',
    uf: 'RS'
  },
  {
    value: 'Morro Redondo',
    name: 'Morro Redondo',
    uf: 'RS'
  },
  {
    value: 'Montauri',
    name: 'Montauri',
    uf: 'RS'
  },
  {
    value: 'Lagoao',
    name: 'Lagoão',
    uf: 'RS'
  },
  {
    value: 'Jaquirana',
    name: 'Jaquirana',
    uf: 'RS'
  },
  {
    value: 'Jaboticaba',
    name: 'Jaboticaba',
    uf: 'RS'
  },
  {
    value: 'Ivora',
    name: 'Ivorá',
    uf: 'RS'
  },
  {
    value: 'Itacurubi',
    name: 'Itacurubi',
    uf: 'RS'
  },
  {
    value: 'Ipiranga do Sul',
    name: 'Ipiranga do Sul',
    uf: 'RS'
  },
  {
    value: 'Abatia',
    name: 'Abatiá',
    uf: 'PR'
  },
  {
    value: 'Adrianopolis',
    name: 'Adrianópolis',
    uf: 'PR'
  },
  {
    value: 'Agudos do Sul',
    name: 'Agudos do Sul',
    uf: 'PR'
  },
  {
    value: 'Almirante Tamandare',
    name: 'Almirante Tamandaré',
    uf: 'PR'
  },
  {
    value: 'Alto Parana',
    name: 'Alto Paraná',
    uf: 'PR'
  },
  {
    value: 'Alto Piquiri',
    name: 'Alto Piquiri',
    uf: 'PR'
  },
  {
    value: 'Alvorada do Sul',
    name: 'Alvorada do Sul',
    uf: 'PR'
  },
  {
    value: 'Amapora',
    name: 'Amaporã',
    uf: 'PR'
  },
  {
    value: 'Ampere',
    name: 'Ampére',
    uf: 'PR'
  },
  {
    value: 'Andira',
    name: 'Andirá',
    uf: 'PR'
  },
  {
    value: 'Antonina',
    name: 'Antonina',
    uf: 'PR'
  },
  {
    value: 'Antonio Olinto',
    name: 'Antônio Olinto',
    uf: 'PR'
  },
  {
    value: 'Apucarana',
    name: 'Apucarana',
    uf: 'PR'
  },
  {
    value: 'Arapongas',
    name: 'Arapongas',
    uf: 'PR'
  },
  {
    value: 'Arapoti',
    name: 'Arapoti',
    uf: 'PR'
  },
  {
    value: 'Araruna',
    name: 'Araruna',
    uf: 'PR'
  },
  {
    value: 'Marilandia do Sul',
    name: 'Marilândia do Sul',
    uf: 'PR'
  },
  {
    value: 'Araucaria',
    name: 'Araucária',
    uf: 'PR'
  },
  {
    value: 'Assai',
    name: 'Assaí',
    uf: 'PR'
  },
  {
    value: 'Astorga',
    name: 'Astorga',
    uf: 'PR'
  },
  {
    value: 'Atalaia',
    name: 'Atalaia',
    uf: 'PR'
  },
  {
    value: 'Balsa Nova',
    name: 'Balsa Nova',
    uf: 'PR'
  },
  {
    value: 'Bandeirantes',
    name: 'Bandeirantes',
    uf: 'PR'
  },
  {
    value: 'Barbosa Ferraz',
    name: 'Barbosa Ferraz',
    uf: 'PR'
  },
  {
    value: 'Barracao',
    name: 'Barracão',
    uf: 'PR'
  },
  {
    value: 'Barra do Jacare',
    name: 'Barra do Jacaré',
    uf: 'PR'
  },
  {
    value: 'Bela Vista do Paraiso',
    name: 'Bela Vista do Paraíso',
    uf: 'PR'
  },
  {
    value: 'Bituruna',
    name: 'Bituruna',
    uf: 'PR'
  },
  {
    value: 'Boa Esperanca',
    name: 'Boa Esperança',
    uf: 'PR'
  },
  {
    value: 'Bocaiuva do Sul',
    name: 'Bocaiúva do Sul',
    uf: 'PR'
  },
  {
    value: 'Bom Sucesso',
    name: 'Bom Sucesso',
    uf: 'PR'
  },
  {
    value: 'Borrazopolis',
    name: 'Borrazópolis',
    uf: 'PR'
  },
  {
    value: 'Cafeara',
    name: 'Cafeara',
    uf: 'PR'
  },
  {
    value: 'California',
    name: 'Califórnia',
    uf: 'PR'
  },
  {
    value: 'Cambara',
    name: 'Cambará',
    uf: 'PR'
  },
  {
    value: 'Cambe',
    name: 'Cambé',
    uf: 'PR'
  },
  {
    value: 'Cambira',
    name: 'Cambira',
    uf: 'PR'
  },
  {
    value: 'Campina da Lagoa',
    name: 'Campina da Lagoa',
    uf: 'PR'
  },
  {
    value: 'Campina Grande do Sul',
    name: 'Campina Grande do Sul',
    uf: 'PR'
  },
  {
    value: 'Campo do Tenente',
    name: 'Campo do Tenente',
    uf: 'PR'
  },
  {
    value: 'Campo Largo',
    name: 'Campo Largo',
    uf: 'PR'
  },
  {
    value: 'Campo Mourao',
    name: 'Campo Mourão',
    uf: 'PR'
  },
  {
    value: 'Candido de Abreu',
    name: 'Cândido de Abreu',
    uf: 'PR'
  },
  {
    value: 'Capanema',
    name: 'Capanema',
    uf: 'PR'
  },
  {
    value: 'Capitao Leonidas Marques',
    name: 'Capitão Leônidas Marques',
    uf: 'PR'
  },
  {
    value: 'Carlopolis',
    name: 'Carlópolis',
    uf: 'PR'
  },
  {
    value: 'Cascavel',
    name: 'Cascavel',
    uf: 'PR'
  },
  {
    value: 'Castro',
    name: 'Castro',
    uf: 'PR'
  },
  {
    value: 'Catanduvas',
    name: 'Catanduvas',
    uf: 'PR'
  },
  {
    value: 'Centenario do Sul',
    name: 'Centenário do Sul',
    uf: 'PR'
  },
  {
    value: 'Cerro Azul',
    name: 'Cerro Azul',
    uf: 'PR'
  },
  {
    value: 'Chopinzinho',
    name: 'Chopinzinho',
    uf: 'PR'
  },
  {
    value: 'Cianorte',
    name: 'Cianorte',
    uf: 'PR'
  },
  {
    value: 'Cidade Gaucha',
    name: 'Cidade Gaúcha',
    uf: 'PR'
  },
  {
    value: 'Clevelandia',
    name: 'Clevelândia',
    uf: 'PR'
  },
  {
    value: 'Mangueirinha',
    name: 'Mangueirinha',
    uf: 'PR'
  },
  {
    value: 'Colombo',
    name: 'Colombo',
    uf: 'PR'
  },
  {
    value: 'Colorado',
    name: 'Colorado',
    uf: 'PR'
  },
  {
    value: 'Congonhinhas',
    name: 'Congonhinhas',
    uf: 'PR'
  },
  {
    value: 'Conselheiro Mairinck',
    name: 'Conselheiro Mairinck',
    uf: 'PR'
  },
  {
    value: 'Contenda',
    name: 'Contenda',
    uf: 'PR'
  },
  {
    value: 'Corbelia',
    name: 'Corbélia',
    uf: 'PR'
  },
  {
    value: 'Cornelio Procopio',
    name: 'Cornélio Procópio',
    uf: 'PR'
  },
  {
    value: 'Coronel Vivida',
    name: 'Coronel Vivida',
    uf: 'PR'
  },
  {
    value: 'Cruzeiro do Oeste',
    name: 'Cruzeiro do Oeste',
    uf: 'PR'
  },
  {
    value: 'Cruzeiro do Sul',
    name: 'Cruzeiro do Sul',
    uf: 'PR'
  },
  {
    value: 'Cruz Machado',
    name: 'Cruz Machado',
    uf: 'PR'
  },
  {
    value: 'Curitiba',
    name: 'Curitiba',
    uf: 'PR'
  },
  {
    value: 'Curiuva',
    name: 'Curiúva',
    uf: 'PR'
  },
  {
    value: 'Diamante do Norte',
    name: 'Diamante do Norte',
    uf: 'PR'
  },
  {
    value: 'Dois Vizinhos',
    name: 'Dois Vizinhos',
    uf: 'PR'
  },
  {
    value: 'Doutor Camargo',
    name: 'Doutor Camargo',
    uf: 'PR'
  },
  {
    value: 'Eneas Marques',
    name: 'Enéas Marques',
    uf: 'PR'
  },
  {
    value: 'Engenheiro Beltrao',
    name: 'Engenheiro Beltrão',
    uf: 'PR'
  },
  {
    value: 'Faxinal',
    name: 'Faxinal',
    uf: 'PR'
  },
  {
    value: 'Fenix',
    name: 'Fênix',
    uf: 'PR'
  },
  {
    value: 'Florai',
    name: 'Floraí',
    uf: 'PR'
  },
  {
    value: 'Floresta',
    name: 'Floresta',
    uf: 'PR'
  },
  {
    value: 'Florestopolis',
    name: 'Florestópolis',
    uf: 'PR'
  },
  {
    value: 'Florida',
    name: 'Flórida',
    uf: 'PR'
  },
  {
    value: 'Formosa do Oeste',
    name: 'Formosa do Oeste',
    uf: 'PR'
  },
  {
    value: 'Foz do Iguacu',
    name: 'Foz do Iguaçu',
    uf: 'PR'
  },
  {
    value: 'Francisco Beltrao',
    name: 'Francisco Beltrão',
    uf: 'PR'
  },
  {
    value: 'General Carneiro',
    name: 'General Carneiro',
    uf: 'PR'
  },
  {
    value: 'Goioere',
    name: 'Goioerê',
    uf: 'PR'
  },
  {
    value: 'Guaira',
    name: 'Guaíra',
    uf: 'PR'
  },
  {
    value: 'Guairaca',
    name: 'Guairaçá',
    uf: 'PR'
  },
  {
    value: 'Guapirama',
    name: 'Guapirama',
    uf: 'PR'
  },
  {
    value: 'Guaporema',
    name: 'Guaporema',
    uf: 'PR'
  },
  {
    value: 'Guaraci',
    name: 'Guaraci',
    uf: 'PR'
  },
  {
    value: 'Guaraniacu',
    name: 'Guaraniaçu',
    uf: 'PR'
  },
  {
    value: 'Guarapuava',
    name: 'Guarapuava',
    uf: 'PR'
  },
  {
    value: 'Guaraquecaba',
    name: 'Guaraqueçaba',
    uf: 'PR'
  },
  {
    value: 'Guaratuba',
    name: 'Guaratuba',
    uf: 'PR'
  },
  {
    value: 'Ibaiti',
    name: 'Ibaiti',
    uf: 'PR'
  },
  {
    value: 'Ibipora',
    name: 'Ibiporã',
    uf: 'PR'
  },
  {
    value: 'Icaraima',
    name: 'Icaraíma',
    uf: 'PR'
  },
  {
    value: 'Iguaracu',
    name: 'Iguaraçu',
    uf: 'PR'
  },
  {
    value: 'Imbituva',
    name: 'Imbituva',
    uf: 'PR'
  },
  {
    value: 'Inacio Martins',
    name: 'Inácio Martins',
    uf: 'PR'
  },
  {
    value: 'Inaja',
    name: 'Inajá',
    uf: 'PR'
  },
  {
    value: 'Ipiranga',
    name: 'Ipiranga',
    uf: 'PR'
  },
  {
    value: 'Ipora',
    name: 'Iporã',
    uf: 'PR'
  },
  {
    value: 'Irati',
    name: 'Irati',
    uf: 'PR'
  },
  {
    value: 'Iretama',
    name: 'Iretama',
    uf: 'PR'
  },
  {
    value: 'Itaguaje',
    name: 'Itaguajé',
    uf: 'PR'
  },
  {
    value: 'Itambaraca',
    name: 'Itambaracá',
    uf: 'PR'
  },
  {
    value: 'Itambe',
    name: 'Itambé',
    uf: 'PR'
  },
  {
    value: "Itapejara d'Oeste",
    name: "Itapejara d'Oeste",
    uf: 'PR'
  },
  {
    value: 'Itauna do Sul',
    name: 'Itaúna do Sul',
    uf: 'PR'
  },
  {
    value: 'Ivai',
    name: 'Ivaí',
    uf: 'PR'
  },
  {
    value: 'Ivaipora',
    name: 'Ivaiporã',
    uf: 'PR'
  },
  {
    value: 'Ivatuba',
    name: 'Ivatuba',
    uf: 'PR'
  },
  {
    value: 'Jaboti',
    name: 'Jaboti',
    uf: 'PR'
  },
  {
    value: 'Jacarezinho',
    name: 'Jacarezinho',
    uf: 'PR'
  },
  {
    value: 'Jaguapita',
    name: 'Jaguapitã',
    uf: 'PR'
  },
  {
    value: 'Jaguariaiva',
    name: 'Jaguariaíva',
    uf: 'PR'
  },
  {
    value: 'Jandaia do Sul',
    name: 'Jandaia do Sul',
    uf: 'PR'
  },
  {
    value: 'Janiopolis',
    name: 'Janiópolis',
    uf: 'PR'
  },
  {
    value: 'Japira',
    name: 'Japira',
    uf: 'PR'
  },
  {
    value: 'Japura',
    name: 'Japurá',
    uf: 'PR'
  },
  {
    value: 'Jardim Alegre',
    name: 'Jardim Alegre',
    uf: 'PR'
  },
  {
    value: 'Jardim Olinda',
    name: 'Jardim Olinda',
    uf: 'PR'
  },
  {
    value: 'Jataizinho',
    name: 'Jataizinho',
    uf: 'PR'
  },
  {
    value: 'Joaquim Tavora',
    name: 'Joaquim Távora',
    uf: 'PR'
  },
  {
    value: 'Jundiai do Sul',
    name: 'Jundiaí do Sul',
    uf: 'PR'
  },
  {
    value: 'Jussara',
    name: 'Jussara',
    uf: 'PR'
  },
  {
    value: 'Kalore',
    name: 'Kaloré',
    uf: 'PR'
  },
  {
    value: 'Lapa',
    name: 'Lapa',
    uf: 'PR'
  },
  {
    value: 'Laranjeiras do Sul',
    name: 'Laranjeiras do Sul',
    uf: 'PR'
  },
  {
    value: 'Leopolis',
    name: 'Leópolis',
    uf: 'PR'
  },
  {
    value: 'Loanda',
    name: 'Loanda',
    uf: 'PR'
  },
  {
    value: 'Lobato',
    name: 'Lobato',
    uf: 'PR'
  },
  {
    value: 'Londrina',
    name: 'Londrina',
    uf: 'PR'
  },
  {
    value: 'Lupionopolis',
    name: 'Lupionópolis',
    uf: 'PR'
  },
  {
    value: 'Mallet',
    name: 'Mallet',
    uf: 'PR'
  },
  {
    value: 'Mambore',
    name: 'Mamborê',
    uf: 'PR'
  },
  {
    value: 'Mandaguacu',
    name: 'Mandaguaçu',
    uf: 'PR'
  },
  {
    value: 'Mandaguari',
    name: 'Mandaguari',
    uf: 'PR'
  },
  {
    value: 'Mandirituba',
    name: 'Mandirituba',
    uf: 'PR'
  },
  {
    value: 'Manoel Ribas',
    name: 'Manoel Ribas',
    uf: 'PR'
  },
  {
    value: 'Marechal Candido Rondon',
    name: 'Marechal Cândido Rondon',
    uf: 'PR'
  },
  {
    value: 'Maria Helena',
    name: 'Maria Helena',
    uf: 'PR'
  },
  {
    value: 'Marialva',
    name: 'Marialva',
    uf: 'PR'
  },
  {
    value: 'Mariluz',
    name: 'Mariluz',
    uf: 'PR'
  },
  {
    value: 'Maringa',
    name: 'Maringá',
    uf: 'PR'
  },
  {
    value: 'Mariopolis',
    name: 'Mariópolis',
    uf: 'PR'
  },
  {
    value: 'Marmeleiro',
    name: 'Marmeleiro',
    uf: 'PR'
  },
  {
    value: 'Marumbi',
    name: 'Marumbi',
    uf: 'PR'
  },
  {
    value: 'Matelandia',
    name: 'Matelândia',
    uf: 'PR'
  },
  {
    value: 'Medianeira',
    name: 'Medianeira',
    uf: 'PR'
  },
  {
    value: 'Mirador',
    name: 'Mirador',
    uf: 'PR'
  },
  {
    value: 'Miraselva',
    name: 'Miraselva',
    uf: 'PR'
  },
  {
    value: 'Moreira Sales',
    name: 'Moreira Sales',
    uf: 'PR'
  },
  {
    value: 'Morretes',
    name: 'Morretes',
    uf: 'PR'
  },
  {
    value: 'Munhoz de Melo',
    name: 'Munhoz de Melo',
    uf: 'PR'
  },
  {
    value: 'Nossa Senhora das Gracas',
    name: 'Nossa Senhora das Graças',
    uf: 'PR'
  },
  {
    value: 'Nova Alianca do Ivai',
    name: 'Nova Aliança do Ivaí',
    uf: 'PR'
  },
  {
    value: 'Nova America da Colina',
    name: 'Nova América da Colina',
    uf: 'PR'
  },
  {
    value: 'Nova Cantu',
    name: 'Nova Cantu',
    uf: 'PR'
  },
  {
    value: 'Nova Esperanca',
    name: 'Nova Esperança',
    uf: 'PR'
  },
  {
    value: 'Nova Fatima',
    name: 'Nova Fátima',
    uf: 'PR'
  },
  {
    value: 'Nova Londrina',
    name: 'Nova Londrina',
    uf: 'PR'
  },
  {
    value: 'Ortigueira',
    name: 'Ortigueira',
    uf: 'PR'
  },
  {
    value: 'Ourizona',
    name: 'Ourizona',
    uf: 'PR'
  },
  {
    value: 'Paicandu',
    name: 'Paiçandu',
    uf: 'PR'
  },
  {
    value: 'Palmas',
    name: 'Palmas',
    uf: 'PR'
  },
  {
    value: 'Palmeira',
    name: 'Palmeira',
    uf: 'PR'
  },
  {
    value: 'Palmital',
    name: 'Palmital',
    uf: 'PR'
  },
  {
    value: 'Palotina',
    name: 'Palotina',
    uf: 'PR'
  },
  {
    value: 'Paraiso do Norte',
    name: 'Paraíso do Norte',
    uf: 'PR'
  },
  {
    value: 'Paranacity',
    name: 'Paranacity',
    uf: 'PR'
  },
  {
    value: 'Paranagua',
    name: 'Paranaguá',
    uf: 'PR'
  },
  {
    value: 'Paranapoema',
    name: 'Paranapoema',
    uf: 'PR'
  },
  {
    value: 'Paranavai',
    name: 'Paranavaí',
    uf: 'PR'
  },
  {
    value: 'Pato Branco',
    name: 'Pato Branco',
    uf: 'PR'
  },
  {
    value: 'Paula Freitas',
    name: 'Paula Freitas',
    uf: 'PR'
  },
  {
    value: 'Paulo Frontin',
    name: 'Paulo Frontin',
    uf: 'PR'
  },
  {
    value: 'Peabiru',
    name: 'Peabiru',
    uf: 'PR'
  },
  {
    value: "Perola d'Oeste",
    name: "Pérola d'Oeste",
    uf: 'PR'
  },
  {
    value: 'Pien',
    name: 'Piên',
    uf: 'PR'
  },
  {
    value: 'Pinhalao',
    name: 'Pinhalão',
    uf: 'PR'
  },
  {
    value: 'Pinhao',
    name: 'Pinhão',
    uf: 'PR'
  },
  {
    value: 'Pirai do Sul',
    name: 'Piraí do Sul',
    uf: 'PR'
  },
  {
    value: 'Piraquara',
    name: 'Piraquara',
    uf: 'PR'
  },
  {
    value: 'Pitanga',
    name: 'Pitanga',
    uf: 'PR'
  },
  {
    value: 'Planaltina do Parana',
    name: 'Planaltina do Paraná',
    uf: 'PR'
  },
  {
    value: 'Planalto',
    name: 'Planalto',
    uf: 'PR'
  },
  {
    value: 'Ponta Grossa',
    name: 'Ponta Grossa',
    uf: 'PR'
  },
  {
    value: 'Porecatu',
    name: 'Porecatu',
    uf: 'PR'
  },
  {
    value: 'Porto Amazonas',
    name: 'Porto Amazonas',
    uf: 'PR'
  },
  {
    value: 'Porto Rico',
    name: 'Porto Rico',
    uf: 'PR'
  },
  {
    value: 'Porto Vitoria',
    name: 'Porto Vitória',
    uf: 'PR'
  },
  {
    value: 'Presidente Castelo Branco',
    name: 'Presidente Castelo Branco',
    uf: 'PR'
  },
  {
    value: 'Primeiro de Maio',
    name: 'Primeiro de Maio',
    uf: 'PR'
  },
  {
    value: 'Prudentopolis',
    name: 'Prudentópolis',
    uf: 'PR'
  },
  {
    value: 'Quatigua',
    name: 'Quatiguá',
    uf: 'PR'
  },
  {
    value: 'Quatro Barras',
    name: 'Quatro Barras',
    uf: 'PR'
  },
  {
    value: 'Querencia do Norte',
    name: 'Querência do Norte',
    uf: 'PR'
  },
  {
    value: 'Quinta do Sol',
    name: 'Quinta do Sol',
    uf: 'PR'
  },
  {
    value: 'Quitandinha',
    name: 'Quitandinha',
    uf: 'PR'
  },
  {
    value: 'Rancho Alegre',
    name: 'Rancho Alegre',
    uf: 'PR'
  },
  {
    value: 'Realeza',
    name: 'Realeza',
    uf: 'PR'
  },
  {
    value: 'Reboucas',
    name: 'Rebouças',
    uf: 'PR'
  },
  {
    value: 'Renascenca',
    name: 'Renascença',
    uf: 'PR'
  },
  {
    value: 'Reserva',
    name: 'Reserva',
    uf: 'PR'
  },
  {
    value: 'Ribeirao Claro',
    name: 'Ribeirão Claro',
    uf: 'PR'
  },
  {
    value: 'Ribeirao do Pinhal',
    name: 'Ribeirão do Pinhal',
    uf: 'PR'
  },
  {
    value: 'Rio Azul',
    name: 'Rio Azul',
    uf: 'PR'
  },
  {
    value: 'Rio Bom',
    name: 'Rio Bom',
    uf: 'PR'
  },
  {
    value: 'Rio Branco do Sul',
    name: 'Rio Branco do Sul',
    uf: 'PR'
  },
  {
    value: 'Rio Negro',
    name: 'Rio Negro',
    uf: 'PR'
  },
  {
    value: 'Rolandia',
    name: 'Rolândia',
    uf: 'PR'
  },
  {
    value: 'Roncador',
    name: 'Roncador',
    uf: 'PR'
  },
  {
    value: 'Rondon',
    name: 'Rondon',
    uf: 'PR'
  },
  {
    value: 'Sabaudia',
    name: 'Sabáudia',
    uf: 'PR'
  },
  {
    value: 'Salgado Filho',
    name: 'Salgado Filho',
    uf: 'PR'
  },
  {
    value: 'Salto do Itarare',
    name: 'Salto do Itararé',
    uf: 'PR'
  },
  {
    value: 'Salto do Lontra',
    name: 'Salto do Lontra',
    uf: 'PR'
  },
  {
    value: 'Santa Amelia',
    name: 'Santa Amélia',
    uf: 'PR'
  },
  {
    value: 'Santa Cecilia do Pavao',
    name: 'Santa Cecília do Pavão',
    uf: 'PR'
  },
  {
    value: 'Santa Cruz de Monte Castelo',
    name: 'Santa Cruz de Monte Castelo',
    uf: 'PR'
  },
  {
    value: 'Santa Fe',
    name: 'Santa Fé',
    uf: 'PR'
  },
  {
    value: 'Santa Ines',
    name: 'Santa Inês',
    uf: 'PR'
  },
  {
    value: 'Santa Isabel do Ivai',
    name: 'Santa Isabel do Ivaí',
    uf: 'PR'
  },
  {
    value: 'Santa Izabel do Oeste',
    name: 'Santa Izabel do Oeste',
    uf: 'PR'
  },
  {
    value: 'Santa Mariana',
    name: 'Santa Mariana',
    uf: 'PR'
  },
  {
    value: 'Santana do Itarare',
    name: 'Santana do Itararé',
    uf: 'PR'
  },
  {
    value: 'Santo Antonio do Sudoeste',
    name: 'Santo Antônio do Sudoeste',
    uf: 'PR'
  },
  {
    value: 'Santo Antonio da Platina',
    name: 'Santo Antônio da Platina',
    uf: 'PR'
  },
  {
    value: 'Santo Antonio do Caiua',
    name: 'Santo Antônio do Caiuá',
    uf: 'PR'
  },
  {
    value: 'Santo Antonio do Paraiso',
    name: 'Santo Antônio do Paraíso',
    uf: 'PR'
  },
  {
    value: 'Santo Inacio',
    name: 'Santo Inácio',
    uf: 'PR'
  },
  {
    value: 'Sao Carlos do Ivai',
    name: 'São Carlos do Ivaí',
    uf: 'PR'
  },
  {
    value: 'Sao Jeronimo da Serra',
    name: 'São Jerônimo da Serra',
    uf: 'PR'
  },
  {
    value: 'Sao Joao',
    name: 'São João',
    uf: 'PR'
  },
  {
    value: 'Sao Joao do Caiua',
    name: 'São João do Caiuá',
    uf: 'PR'
  },
  {
    value: 'Sao Joao do Ivai',
    name: 'São João do Ivaí',
    uf: 'PR'
  },
  {
    value: 'Sao Joao do Triunfo',
    name: 'São João do Triunfo',
    uf: 'PR'
  },
  {
    value: 'Sao Jorge do Ivai',
    name: 'São Jorge do Ivaí',
    uf: 'PR'
  },
  {
    value: "Sao Jorge d'Oeste",
    name: "São Jorge d'Oeste",
    uf: 'PR'
  },
  {
    value: 'Sao Jose da Boa Vista',
    name: 'São José da Boa Vista',
    uf: 'PR'
  },
  {
    value: 'Sao Jose dos Pinhais',
    name: 'São José dos Pinhais',
    uf: 'PR'
  },
  {
    value: 'Sao Mateus do Sul',
    name: 'São Mateus do Sul',
    uf: 'PR'
  },
  {
    value: 'Sao Miguel do Iguacu',
    name: 'São Miguel do Iguaçu',
    uf: 'PR'
  },
  {
    value: 'Sao Pedro do Ivai',
    name: 'São Pedro do Ivaí',
    uf: 'PR'
  },
  {
    value: 'Sao Pedro do Parana',
    name: 'São Pedro do Paraná',
    uf: 'PR'
  },
  {
    value: 'Sao Sebastiao da Amoreira',
    name: 'São Sebastião da Amoreira',
    uf: 'PR'
  },
  {
    value: 'Sao Tome',
    name: 'São Tomé',
    uf: 'PR'
  },
  {
    value: 'Sapopema',
    name: 'Sapopema',
    uf: 'PR'
  },
  {
    value: 'Senges',
    name: 'Sengés',
    uf: 'PR'
  },
  {
    value: 'Sertaneja',
    name: 'Sertaneja',
    uf: 'PR'
  },
  {
    value: 'Sertanopolis',
    name: 'Sertanópolis',
    uf: 'PR'
  },
  {
    value: 'Siqueira Campos',
    name: 'Siqueira Campos',
    uf: 'PR'
  },
  {
    value: 'Tamboara',
    name: 'Tamboara',
    uf: 'PR'
  },
  {
    value: 'Tapejara',
    name: 'Tapejara',
    uf: 'PR'
  },
  {
    value: 'Teixeira Soares',
    name: 'Teixeira Soares',
    uf: 'PR'
  },
  {
    value: 'Telemaco Borba',
    name: 'Telêmaco Borba',
    uf: 'PR'
  },
  {
    value: 'Terra Boa',
    name: 'Terra Boa',
    uf: 'PR'
  },
  {
    value: 'Terra Rica',
    name: 'Terra Rica',
    uf: 'PR'
  },
  {
    value: 'Terra Roxa',
    name: 'Terra Roxa',
    uf: 'PR'
  },
  {
    value: 'Tibagi',
    name: 'Tibagi',
    uf: 'PR'
  },
  {
    value: 'Tijucas do Sul',
    name: 'Tijucas do Sul',
    uf: 'PR'
  },
  {
    value: 'Toledo',
    name: 'Toledo',
    uf: 'PR'
  },
  {
    value: 'Tomazina',
    name: 'Tomazina',
    uf: 'PR'
  },
  {
    value: 'Tuneiras do Oeste',
    name: 'Tuneiras do Oeste',
    uf: 'PR'
  },
  {
    value: 'Ubirata',
    name: 'Ubiratã',
    uf: 'PR'
  },
  {
    value: 'Umuarama',
    name: 'Umuarama',
    uf: 'PR'
  },
  {
    value: 'Uniao da Vitoria',
    name: 'União da Vitória',
    uf: 'PR'
  },
  {
    value: 'Uniflor',
    name: 'Uniflor',
    uf: 'PR'
  },
  {
    value: 'Urai',
    name: 'Uraí',
    uf: 'PR'
  },
  {
    value: 'Wenceslau Braz',
    name: 'Wenceslau Braz',
    uf: 'PR'
  },
  {
    value: 'Vere',
    name: 'Verê',
    uf: 'PR'
  },
  {
    value: 'Vitorino',
    name: 'Vitorino',
    uf: 'PR'
  },
  {
    value: 'Xambre',
    name: 'Xambrê',
    uf: 'PR'
  },
  {
    value: 'Altonia',
    name: 'Altônia',
    uf: 'PR'
  },
  {
    value: 'Assis Chateaubriand',
    name: 'Assis Chateaubriand',
    uf: 'PR'
  },
  {
    value: 'Quedas do Iguacu',
    name: 'Quedas do Iguaçu',
    uf: 'PR'
  },
  {
    value: 'Ceu Azul',
    name: 'Céu Azul',
    uf: 'PR'
  },
  {
    value: 'Grandes Rios',
    name: 'Grandes Rios',
    uf: 'PR'
  },
  {
    value: 'Indianopolis',
    name: 'Indianópolis',
    uf: 'PR'
  },
  {
    value: 'Matinhos',
    name: 'Matinhos',
    uf: 'PR'
  },
  {
    value: 'Nova Aurora',
    name: 'Nova Aurora',
    uf: 'PR'
  },
  {
    value: 'Nova Olimpia',
    name: 'Nova Olímpia',
    uf: 'PR'
  },
  {
    value: 'Perola',
    name: 'Pérola',
    uf: 'PR'
  },
  {
    value: 'Santa Helena',
    name: 'Santa Helena',
    uf: 'PR'
  },
  {
    value: 'Tapira',
    name: 'Tapira',
    uf: 'PR'
  },
  {
    value: 'Marilena',
    name: 'Marilena',
    uf: 'PR'
  },
  {
    value: 'Francisco Alves',
    name: 'Francisco Alves',
    uf: 'PR'
  },
  {
    value: 'Nova Santa Rosa',
    name: 'Nova Santa Rosa',
    uf: 'PR'
  },
  {
    value: 'Boa Vista da Aparecida',
    name: 'Boa Vista da Aparecida',
    uf: 'PR'
  },
  {
    value: 'Braganey',
    name: 'Braganey',
    uf: 'PR'
  },
  {
    value: 'Cafelandia',
    name: 'Cafelândia',
    uf: 'PR'
  },
  {
    value: 'Tres Barras do Parana',
    name: 'Três Barras do Paraná',
    uf: 'PR'
  },
  {
    value: 'Vera Cruz do Oeste',
    name: 'Vera Cruz do Oeste',
    uf: 'PR'
  },
  {
    value: 'Pranchita',
    name: 'Pranchita',
    uf: 'PR'
  },
  {
    value: 'Tupassi',
    name: 'Tupãssi',
    uf: 'PR'
  },
  {
    value: 'Nova Prata do Iguacu',
    name: 'Nova Prata do Iguaçu',
    uf: 'PR'
  },
  {
    value: 'Jesuitas',
    name: 'Jesuítas',
    uf: 'PR'
  },
  {
    value: 'Sao Jorge do Patrocinio',
    name: 'São Jorge do Patrocínio',
    uf: 'PR'
  },
  {
    value: 'Abelardo Luz',
    name: 'Abelardo Luz',
    uf: 'SC'
  },
  {
    value: 'Agrolandia',
    name: 'Agrolândia',
    uf: 'SC'
  },
  {
    value: 'Agronomica',
    name: 'Agronômica',
    uf: 'SC'
  },
  {
    value: 'Agua Doce',
    name: 'Água Doce',
    uf: 'SC'
  },
  {
    value: 'Aguas de Chapeco',
    name: 'Águas de Chapecó',
    uf: 'SC'
  },
  {
    value: 'Aguas Mornas',
    name: 'Águas Mornas',
    uf: 'SC'
  },
  {
    value: 'Alfredo Wagner',
    name: 'Alfredo Wagner',
    uf: 'SC'
  },
  {
    value: 'Anchieta',
    name: 'Anchieta',
    uf: 'SC'
  },
  {
    value: 'Angelina',
    name: 'Angelina',
    uf: 'SC'
  },
  {
    value: 'Anita Garibaldi',
    name: 'Anita Garibaldi',
    uf: 'SC'
  },
  {
    value: 'Anitapolis',
    name: 'Anitápolis',
    uf: 'SC'
  },
  {
    value: 'Antonio Carlos',
    name: 'Antônio Carlos',
    uf: 'SC'
  },
  {
    value: 'Araquari',
    name: 'Araquari',
    uf: 'SC'
  },
  {
    value: 'Ararangua',
    name: 'Araranguá',
    uf: 'SC'
  },
  {
    value: 'Armazem',
    name: 'Armazém',
    uf: 'SC'
  },
  {
    value: 'Arroio Trinta',
    name: 'Arroio Trinta',
    uf: 'SC'
  },
  {
    value: 'Ascurra',
    name: 'Ascurra',
    uf: 'SC'
  },
  {
    value: 'Atalanta',
    name: 'Atalanta',
    uf: 'SC'
  },
  {
    value: 'Aurora',
    name: 'Aurora',
    uf: 'SC'
  },
  {
    value: 'Balneario Camboriu',
    name: 'Balneário Camboriú',
    uf: 'SC'
  },
  {
    value: 'Barra Velha',
    name: 'Barra Velha',
    uf: 'SC'
  },
  {
    value: 'Benedito Novo',
    name: 'Benedito Novo',
    uf: 'SC'
  },
  {
    value: 'Biguacu',
    name: 'Biguaçu',
    uf: 'SC'
  },
  {
    value: 'Blumenau',
    name: 'Blumenau',
    uf: 'SC'
  },
  {
    value: 'Bom Retiro',
    name: 'Bom Retiro',
    uf: 'SC'
  },
  {
    value: 'Botuvera',
    name: 'Botuverá',
    uf: 'SC'
  },
  {
    value: 'Braco do Norte',
    name: 'Braço do Norte',
    uf: 'SC'
  },
  {
    value: 'Brusque',
    name: 'Brusque',
    uf: 'SC'
  },
  {
    value: 'Cacador',
    name: 'Caçador',
    uf: 'SC'
  },
  {
    value: 'Caibi',
    name: 'Caibi',
    uf: 'SC'
  },
  {
    value: 'Camboriu',
    name: 'Camboriú',
    uf: 'SC'
  },
  {
    value: 'Campo Alegre',
    name: 'Campo Alegre',
    uf: 'SC'
  },
  {
    value: 'Campo Belo do Sul',
    name: 'Campo Belo do Sul',
    uf: 'SC'
  },
  {
    value: 'Campo Ere',
    name: 'Campo Erê',
    uf: 'SC'
  },
  {
    value: 'Campos Novos',
    name: 'Campos Novos',
    uf: 'SC'
  },
  {
    value: 'Canelinha',
    name: 'Canelinha',
    uf: 'SC'
  },
  {
    value: 'Canoinhas',
    name: 'Canoinhas',
    uf: 'SC'
  },
  {
    value: 'Capinzal',
    name: 'Capinzal',
    uf: 'SC'
  },
  {
    value: 'Catanduvas',
    name: 'Catanduvas',
    uf: 'SC'
  },
  {
    value: 'Caxambu do Sul',
    name: 'Caxambu do Sul',
    uf: 'SC'
  },
  {
    value: 'Chapeco',
    name: 'Chapecó',
    uf: 'SC'
  },
  {
    value: 'Concordia',
    name: 'Concórdia',
    uf: 'SC'
  },
  {
    value: 'Coronel Freitas',
    name: 'Coronel Freitas',
    uf: 'SC'
  },
  {
    value: 'Corupa',
    name: 'Corupá',
    uf: 'SC'
  },
  {
    value: 'Criciuma',
    name: 'Criciúma',
    uf: 'SC'
  },
  {
    value: 'Cunha Pora',
    name: 'Cunha Porã',
    uf: 'SC'
  },
  {
    value: 'Curitibanos',
    name: 'Curitibanos',
    uf: 'SC'
  },
  {
    value: 'Descanso',
    name: 'Descanso',
    uf: 'SC'
  },
  {
    value: 'Dionisio Cerqueira',
    name: 'Dionísio Cerqueira',
    uf: 'SC'
  },
  {
    value: 'Dona Emma',
    name: 'Dona Emma',
    uf: 'SC'
  },
  {
    value: 'Erval Velho',
    name: 'Erval Velho',
    uf: 'SC'
  },
  {
    value: 'Faxinal dos Guedes',
    name: 'Faxinal dos Guedes',
    uf: 'SC'
  },
  {
    value: 'Florianopolis',
    name: 'Florianópolis',
    uf: 'SC'
  },
  {
    value: 'Fraiburgo',
    name: 'Fraiburgo',
    uf: 'SC'
  },
  {
    value: 'Galvao',
    name: 'Galvão',
    uf: 'SC'
  },
  {
    value: 'Governador Celso Ramos',
    name: 'Governador Celso Ramos',
    uf: 'SC'
  },
  {
    value: 'Garopaba',
    name: 'Garopaba',
    uf: 'SC'
  },
  {
    value: 'Garuva',
    name: 'Garuva',
    uf: 'SC'
  },
  {
    value: 'Gaspar',
    name: 'Gaspar',
    uf: 'SC'
  },
  {
    value: 'Grao-Para',
    name: 'Grão-Pará',
    uf: 'SC'
  },
  {
    value: 'Gravatal',
    name: 'Gravatal',
    uf: 'SC'
  },
  {
    value: 'Guabiruba',
    name: 'Guabiruba',
    uf: 'SC'
  },
  {
    value: 'Guaraciaba',
    name: 'Guaraciaba',
    uf: 'SC'
  },
  {
    value: 'Guaramirim',
    name: 'Guaramirim',
    uf: 'SC'
  },
  {
    value: 'Guaruja do Sul',
    name: 'Guarujá do Sul',
    uf: 'SC'
  },
  {
    value: "Herval d'Oeste",
    name: "Herval d'Oeste",
    uf: 'SC'
  },
  {
    value: 'Ibicare',
    name: 'Ibicaré',
    uf: 'SC'
  },
  {
    value: 'Ibirama',
    name: 'Ibirama',
    uf: 'SC'
  },
  {
    value: 'Icara',
    name: 'Içara',
    uf: 'SC'
  },
  {
    value: 'Ilhota',
    name: 'Ilhota',
    uf: 'SC'
  },
  {
    value: 'Imarui',
    name: 'Imaruí',
    uf: 'SC'
  },
  {
    value: 'Imbituba',
    name: 'Imbituba',
    uf: 'SC'
  },
  {
    value: 'Imbuia',
    name: 'Imbuia',
    uf: 'SC'
  },
  {
    value: 'Indaial',
    name: 'Indaial',
    uf: 'SC'
  },
  {
    value: 'Ipira',
    name: 'Ipira',
    uf: 'SC'
  },
  {
    value: 'Ipumirim',
    name: 'Ipumirim',
    uf: 'SC'
  },
  {
    value: 'Irani',
    name: 'Irani',
    uf: 'SC'
  },
  {
    value: 'Irineopolis',
    name: 'Irineópolis',
    uf: 'SC'
  },
  {
    value: 'Ita',
    name: 'Itá',
    uf: 'SC'
  },
  {
    value: 'Itaiopolis',
    name: 'Itaiópolis',
    uf: 'SC'
  },
  {
    value: 'Itajai',
    name: 'Itajaí',
    uf: 'SC'
  },
  {
    value: 'Itapema',
    name: 'Itapema',
    uf: 'SC'
  },
  {
    value: 'Itapiranga',
    name: 'Itapiranga',
    uf: 'SC'
  },
  {
    value: 'Ituporanga',
    name: 'Ituporanga',
    uf: 'SC'
  },
  {
    value: 'Jabora',
    name: 'Jaborá',
    uf: 'SC'
  },
  {
    value: 'Jacinto Machado',
    name: 'Jacinto Machado',
    uf: 'SC'
  },
  {
    value: 'Jaguaruna',
    name: 'Jaguaruna',
    uf: 'SC'
  },
  {
    value: 'Jaragua do Sul',
    name: 'Jaraguá do Sul',
    uf: 'SC'
  },
  {
    value: 'Joacaba',
    name: 'Joaçaba',
    uf: 'SC'
  },
  {
    value: 'Joinville',
    name: 'Joinville',
    uf: 'SC'
  },
  {
    value: 'Lacerdopolis',
    name: 'Lacerdópolis',
    uf: 'SC'
  },
  {
    value: 'Lages',
    name: 'Lages',
    uf: 'SC'
  },
  {
    value: 'Laguna',
    name: 'Laguna',
    uf: 'SC'
  },
  {
    value: 'Laurentino',
    name: 'Laurentino',
    uf: 'SC'
  },
  {
    value: 'Lauro Muller',
    name: 'Lauro Müller',
    uf: 'SC'
  },
  {
    value: 'Lebon Regis',
    name: 'Lebon Régis',
    uf: 'SC'
  },
  {
    value: 'Leoberto Leal',
    name: 'Leoberto Leal',
    uf: 'SC'
  },
  {
    value: 'Lontras',
    name: 'Lontras',
    uf: 'SC'
  },
  {
    value: 'Luiz Alves',
    name: 'Luiz Alves',
    uf: 'SC'
  },
  {
    value: 'Mafra',
    name: 'Mafra',
    uf: 'SC'
  },
  {
    value: 'Major Gercino',
    name: 'Major Gercino',
    uf: 'SC'
  },
  {
    value: 'Major Vieira',
    name: 'Major Vieira',
    uf: 'SC'
  },
  {
    value: 'Maravilha',
    name: 'Maravilha',
    uf: 'SC'
  },
  {
    value: 'Massaranduba',
    name: 'Massaranduba',
    uf: 'SC'
  },
  {
    value: 'Matos Costa',
    name: 'Matos Costa',
    uf: 'SC'
  },
  {
    value: 'Meleiro',
    name: 'Meleiro',
    uf: 'SC'
  },
  {
    value: 'Modelo',
    name: 'Modelo',
    uf: 'SC'
  },
  {
    value: 'Mondai',
    name: 'Mondaí',
    uf: 'SC'
  },
  {
    value: 'Monte Castelo',
    name: 'Monte Castelo',
    uf: 'SC'
  },
  {
    value: 'Morro da Fumaca',
    name: 'Morro da Fumaça',
    uf: 'SC'
  },
  {
    value: 'Navegantes',
    name: 'Navegantes',
    uf: 'SC'
  },
  {
    value: 'Nova Erechim',
    name: 'Nova Erechim',
    uf: 'SC'
  },
  {
    value: 'Nova Trento',
    name: 'Nova Trento',
    uf: 'SC'
  },
  {
    value: 'Nova Veneza',
    name: 'Nova Veneza',
    uf: 'SC'
  },
  {
    value: 'Orleans',
    name: 'Orleans',
    uf: 'SC'
  },
  {
    value: 'Ouro',
    name: 'Ouro',
    uf: 'SC'
  },
  {
    value: 'Palhoca',
    name: 'Palhoça',
    uf: 'SC'
  },
  {
    value: 'Palma Sola',
    name: 'Palma Sola',
    uf: 'SC'
  },
  {
    value: 'Palmitos',
    name: 'Palmitos',
    uf: 'SC'
  },
  {
    value: 'Papanduva',
    name: 'Papanduva',
    uf: 'SC'
  },
  {
    value: 'Paulo Lopes',
    name: 'Paulo Lopes',
    uf: 'SC'
  },
  {
    value: 'Pedras Grandes',
    name: 'Pedras Grandes',
    uf: 'SC'
  },
  {
    value: 'Penha',
    name: 'Penha',
    uf: 'SC'
  },
  {
    value: 'Peritiba',
    name: 'Peritiba',
    uf: 'SC'
  },
  {
    value: 'Petrolandia',
    name: 'Petrolândia',
    uf: 'SC'
  },
  {
    value: 'Balneario Picarras',
    name: 'Balneário Piçarras',
    uf: 'SC'
  },
  {
    value: 'Pinhalzinho',
    name: 'Pinhalzinho',
    uf: 'SC'
  },
  {
    value: 'Pinheiro Preto',
    name: 'Pinheiro Preto',
    uf: 'SC'
  },
  {
    value: 'Piratuba',
    name: 'Piratuba',
    uf: 'SC'
  },
  {
    value: 'Pomerode',
    name: 'Pomerode',
    uf: 'SC'
  },
  {
    value: 'Ponte Alta',
    name: 'Ponte Alta',
    uf: 'SC'
  },
  {
    value: 'Ponte Serrada',
    name: 'Ponte Serrada',
    uf: 'SC'
  },
  {
    value: 'Porto Belo',
    name: 'Porto Belo',
    uf: 'SC'
  },
  {
    value: 'Porto Uniao',
    name: 'Porto União',
    uf: 'SC'
  },
  {
    value: 'Pouso Redondo',
    name: 'Pouso Redondo',
    uf: 'SC'
  },
  {
    value: 'Praia Grande',
    name: 'Praia Grande',
    uf: 'SC'
  },
  {
    value: 'Presidente Castello Branco',
    name: 'Presidente Castello Branco',
    uf: 'SC'
  },
  {
    value: 'Presidente Getulio',
    name: 'Presidente Getúlio',
    uf: 'SC'
  },
  {
    value: 'Presidente Nereu',
    name: 'Presidente Nereu',
    uf: 'SC'
  },
  {
    value: 'Quilombo',
    name: 'Quilombo',
    uf: 'SC'
  },
  {
    value: 'Rancho Queimado',
    name: 'Rancho Queimado',
    uf: 'SC'
  },
  {
    value: 'Rio das Antas',
    name: 'Rio das Antas',
    uf: 'SC'
  },
  {
    value: 'Rio do Campo',
    name: 'Rio do Campo',
    uf: 'SC'
  },
  {
    value: 'Rio do Oeste',
    name: 'Rio do Oeste',
    uf: 'SC'
  },
  {
    value: 'Rio dos Cedros',
    name: 'Rio dos Cedros',
    uf: 'SC'
  },
  {
    value: 'Rio do Sul',
    name: 'Rio do Sul',
    uf: 'SC'
  },
  {
    value: 'Rio Fortuna',
    name: 'Rio Fortuna',
    uf: 'SC'
  },
  {
    value: 'Rio Negrinho',
    name: 'Rio Negrinho',
    uf: 'SC'
  },
  {
    value: 'Rodeio',
    name: 'Rodeio',
    uf: 'SC'
  },
  {
    value: 'Romelandia',
    name: 'Romelândia',
    uf: 'SC'
  },
  {
    value: 'Salete',
    name: 'Salete',
    uf: 'SC'
  },
  {
    value: 'Salto Veloso',
    name: 'Salto Veloso',
    uf: 'SC'
  },
  {
    value: 'Santa Cecilia',
    name: 'Santa Cecília',
    uf: 'SC'
  },
  {
    value: 'Santa Rosa de Lima',
    name: 'Santa Rosa de Lima',
    uf: 'SC'
  },
  {
    value: 'Santo Amaro da Imperatriz',
    name: 'Santo Amaro da Imperatriz',
    uf: 'SC'
  },
  {
    value: 'Sao Bento do Sul',
    name: 'São Bento do Sul',
    uf: 'SC'
  },
  {
    value: 'Sao Bonifacio',
    name: 'São Bonifácio',
    uf: 'SC'
  },
  {
    value: 'Sao Carlos',
    name: 'São Carlos',
    uf: 'SC'
  },
  {
    value: 'Sao Domingos',
    name: 'São Domingos',
    uf: 'SC'
  },
  {
    value: 'Sao Francisco do Sul',
    name: 'São Francisco do Sul',
    uf: 'SC'
  },
  {
    value: 'Sao Joao Batista',
    name: 'São João Batista',
    uf: 'SC'
  },
  {
    value: 'Sao Joao do Sul',
    name: 'São João do Sul',
    uf: 'SC'
  },
  {
    value: 'Sao Joaquim',
    name: 'São Joaquim',
    uf: 'SC'
  },
  {
    value: 'Sao Jose',
    name: 'São José',
    uf: 'SC'
  },
  {
    value: 'Sao Jose do Cedro',
    name: 'São José do Cedro',
    uf: 'SC'
  },
  {
    value: 'Sao Jose do Cerrito',
    name: 'São José do Cerrito',
    uf: 'SC'
  },
  {
    value: 'Sao Lourenco do Oeste',
    name: 'São Lourenço do Oeste',
    uf: 'SC'
  },
  {
    value: 'Sao Ludgero',
    name: 'São Ludgero',
    uf: 'SC'
  },
  {
    value: 'Sao Martinho',
    name: 'São Martinho',
    uf: 'SC'
  },
  {
    value: 'Sao Miguel do Oeste',
    name: 'São Miguel do Oeste',
    uf: 'SC'
  },
  {
    value: 'Saudades',
    name: 'Saudades',
    uf: 'SC'
  },
  {
    value: 'Schroeder',
    name: 'Schroeder',
    uf: 'SC'
  },
  {
    value: 'Seara',
    name: 'Seara',
    uf: 'SC'
  },
  {
    value: 'Sideropolis',
    name: 'Siderópolis',
    uf: 'SC'
  },
  {
    value: 'Sombrio',
    name: 'Sombrio',
    uf: 'SC'
  },
  {
    value: 'Taio',
    name: 'Taió',
    uf: 'SC'
  },
  {
    value: 'Tangara',
    name: 'Tangará',
    uf: 'SC'
  },
  {
    value: 'Tijucas',
    name: 'Tijucas',
    uf: 'SC'
  },
  {
    value: 'Timbo',
    name: 'Timbó',
    uf: 'SC'
  },
  {
    value: 'Tres Barras',
    name: 'Três Barras',
    uf: 'SC'
  },
  {
    value: 'Treze de Maio',
    name: 'Treze de Maio',
    uf: 'SC'
  },
  {
    value: 'Treze Tilias',
    name: 'Treze Tílias',
    uf: 'SC'
  },
  {
    value: 'Trombudo Central',
    name: 'Trombudo Central',
    uf: 'SC'
  },
  {
    value: 'Tubarao',
    name: 'Tubarão',
    uf: 'SC'
  },
  {
    value: 'Turvo',
    name: 'Turvo',
    uf: 'SC'
  },
  {
    value: 'Urubici',
    name: 'Urubici',
    uf: 'SC'
  },
  {
    value: 'Urussanga',
    name: 'Urussanga',
    uf: 'SC'
  },
  {
    value: 'Vargeao',
    name: 'Vargeão',
    uf: 'SC'
  },
  {
    value: 'Vidal Ramos',
    name: 'Vidal Ramos',
    uf: 'SC'
  },
  {
    value: 'Videira',
    name: 'Videira',
    uf: 'SC'
  },
  {
    value: 'Witmarsum',
    name: 'Witmarsum',
    uf: 'SC'
  },
  {
    value: 'Xanxere',
    name: 'Xanxerê',
    uf: 'SC'
  },
  {
    value: 'Xavantina',
    name: 'Xavantina',
    uf: 'SC'
  },
  {
    value: 'Xaxim',
    name: 'Xaxim',
    uf: 'SC'
  },
  {
    value: 'Bom Jardim da Serra',
    name: 'Bom Jardim da Serra',
    uf: 'SC'
  },
  {
    value: 'Maracaja',
    name: 'Maracajá',
    uf: 'SC'
  },
  {
    value: 'Timbe do Sul',
    name: 'Timbé do Sul',
    uf: 'SC'
  },
  {
    value: 'Correia Pinto',
    name: 'Correia Pinto',
    uf: 'SC'
  },
  {
    value: 'Otacilio Costa',
    name: 'Otacílio Costa',
    uf: 'SC'
  },
  {
    value: 'Ipe',
    name: 'Ipê',
    uf: 'RS'
  },
  {
    value: 'Ibarama',
    name: 'Ibarama',
    uf: 'RS'
  },
  {
    value: 'Harmonia',
    name: 'Harmonia',
    uf: 'RS'
  },
  {
    value: 'Guabiju',
    name: 'Guabiju',
    uf: 'RS'
  },
  {
    value: 'Glorinha',
    name: 'Glorinha',
    uf: 'RS'
  },
  {
    value: 'Faxinalzinho',
    name: 'Faxinalzinho',
    uf: 'RS'
  },
  {
    value: 'Fagundes Varela',
    name: 'Fagundes Varela',
    uf: 'RS'
  },
  {
    value: 'Eugenio de Castro',
    name: 'Eugênio de Castro',
    uf: 'RS'
  },
  {
    value: 'Ernestina',
    name: 'Ernestina',
    uf: 'RS'
  },
  {
    value: 'Erebango',
    name: 'Erebango',
    uf: 'RS'
  },
  {
    value: 'Entre-Ijuis',
    name: 'Entre-Ijuís',
    uf: 'RS'
  },
  {
    value: 'Entre Rios do Sul',
    name: 'Entre Rios do Sul',
    uf: 'RS'
  },
  {
    value: 'Eldorado do Sul',
    name: 'Eldorado do Sul',
    uf: 'RS'
  },
  {
    value: 'Doutor Mauricio Cardoso',
    name: 'Doutor Maurício Cardoso',
    uf: 'RS'
  },
  {
    value: 'Dois Lajeados',
    name: 'Dois Lajeados',
    uf: 'RS'
  },
  {
    value: 'Dezesseis de Novembro',
    name: 'Dezesseis de Novembro',
    uf: 'RS'
  },
  {
    value: 'Cristal',
    name: 'Cristal',
    uf: 'RS'
  },
  {
    value: 'Cidreira',
    name: 'Cidreira',
    uf: 'RS'
  },
  {
    value: 'Cerro Grande do Sul',
    name: 'Cerro Grande do Sul',
    uf: 'RS'
  },
  {
    value: 'Cerro Grande',
    name: 'Cerro Grande',
    uf: 'RS'
  },
  {
    value: 'Cerro Branco',
    name: 'Cerro Branco',
    uf: 'RS'
  },
  {
    value: 'Caseiros',
    name: 'Caseiros',
    uf: 'RS'
  },
  {
    value: 'Capela de Santana',
    name: 'Capela de Santana',
    uf: 'RS'
  },
  {
    value: 'Campos Borges',
    name: 'Campos Borges',
    uf: 'RS'
  },
  {
    value: 'Camargo',
    name: 'Camargo',
    uf: 'RS'
  },
  {
    value: 'Brochier',
    name: 'Brochier',
    uf: 'RS'
  },
  {
    value: 'Cantagalo',
    name: 'Cantagalo',
    uf: 'PR'
  },
  {
    value: 'Turvo',
    name: 'Turvo',
    uf: 'PR'
  },
  {
    value: 'Altamira do Parana',
    name: 'Altamira do Paraná',
    uf: 'PR'
  },
  {
    value: 'Figueira',
    name: 'Figueira',
    uf: 'PR'
  },
  {
    value: 'Lunardelli',
    name: 'Lunardelli',
    uf: 'PR'
  },
  {
    value: 'Sarandi',
    name: 'Sarandi',
    uf: 'PR'
  },
  {
    value: 'Juranda',
    name: 'Juranda',
    uf: 'PR'
  },
  {
    value: 'Douradina',
    name: 'Douradina',
    uf: 'PR'
  },
  {
    value: 'Santa Terezinha de Itaipu',
    name: 'Santa Terezinha de Itaipu',
    uf: 'PR'
  },
  {
    value: 'Missal',
    name: 'Missal',
    uf: 'PR'
  },
  {
    value: 'Sao Jose das Palmeiras',
    name: 'São José das Palmeiras',
    uf: 'PR'
  },
  {
    value: 'Rosario do Ivai',
    name: 'Rosário do Ivaí',
    uf: 'PR'
  },
  {
    value: 'Campo Bonito',
    name: 'Campo Bonito',
    uf: 'PR'
  },
  {
    value: 'Sulina',
    name: 'Sulina',
    uf: 'PR'
  },
  {
    value: 'Corumbatai do Sul',
    name: 'Corumbataí do Sul',
    uf: 'PR'
  },
  {
    value: 'Luiziana',
    name: 'Luiziana',
    uf: 'PR'
  },
  {
    value: 'Boqueirao do Leao',
    name: 'Boqueirão do Leão',
    uf: 'RS'
  },
  {
    value: 'Barao',
    name: 'Barão',
    uf: 'RS'
  },
  {
    value: 'Aurea',
    name: 'Áurea',
    uf: 'RS'
  },
  {
    value: 'Arroio do Sal',
    name: 'Arroio do Sal',
    uf: 'RS'
  },
  {
    value: 'Andre da Rocha',
    name: 'André da Rocha',
    uf: 'RS'
  },
  {
    value: 'Amaral Ferrador',
    name: 'Amaral Ferrador',
    uf: 'RS'
  },
  {
    value: 'Alto Alegre',
    name: 'Alto Alegre',
    uf: 'RS'
  },
  {
    value: 'Alegria',
    name: 'Alegria',
    uf: 'RS'
  },
  {
    value: 'Agua Santa',
    name: 'Água Santa',
    uf: 'RS'
  },
  {
    value: 'Agudo',
    name: 'Agudo',
    uf: 'RS'
  },
  {
    value: 'Ajuricaba',
    name: 'Ajuricaba',
    uf: 'RS'
  },
  {
    value: 'Alecrim',
    name: 'Alecrim',
    uf: 'RS'
  },
  {
    value: 'Alegrete',
    name: 'Alegrete',
    uf: 'RS'
  },
  {
    value: 'Alpestre',
    name: 'Alpestre',
    uf: 'RS'
  },
  {
    value: 'Alvorada',
    name: 'Alvorada',
    uf: 'RS'
  },
  {
    value: 'Anta Gorda',
    name: 'Anta Gorda',
    uf: 'RS'
  },
  {
    value: 'Antonio Prado',
    name: 'Antônio Prado',
    uf: 'RS'
  },
  {
    value: 'Aratiba',
    name: 'Aratiba',
    uf: 'RS'
  },
  {
    value: 'Arroio do Meio',
    name: 'Arroio do Meio',
    uf: 'RS'
  },
  {
    value: 'Arroio dos Ratos',
    name: 'Arroio dos Ratos',
    uf: 'RS'
  },
  {
    value: 'Arroio do Tigre',
    name: 'Arroio do Tigre',
    uf: 'RS'
  },
  {
    value: 'Arroio Grande',
    name: 'Arroio Grande',
    uf: 'RS'
  },
  {
    value: 'Arvorezinha',
    name: 'Arvorezinha',
    uf: 'RS'
  },
  {
    value: 'Augusto Pestana',
    name: 'Augusto Pestana',
    uf: 'RS'
  },
  {
    value: 'Bage',
    name: 'Bagé',
    uf: 'RS'
  },
  {
    value: 'Barao de Cotegipe',
    name: 'Barão de Cotegipe',
    uf: 'RS'
  },
  {
    value: 'Barracao',
    name: 'Barracão',
    uf: 'RS'
  },
  {
    value: 'Barra do Ribeiro',
    name: 'Barra do Ribeiro',
    uf: 'RS'
  },
  {
    value: 'Barros Cassal',
    name: 'Barros Cassal',
    uf: 'RS'
  },
  {
    value: 'Bento Goncalves',
    name: 'Bento Gonçalves',
    uf: 'RS'
  },
  {
    value: 'Boa Vista do Burica',
    name: 'Boa Vista do Buricá',
    uf: 'RS'
  },
  {
    value: 'Bom Jesus',
    name: 'Bom Jesus',
    uf: 'RS'
  },
  {
    value: 'Bom Retiro do Sul',
    name: 'Bom Retiro do Sul',
    uf: 'RS'
  },
  {
    value: 'Bossoroca',
    name: 'Bossoroca',
    uf: 'RS'
  },
  {
    value: 'Braga',
    name: 'Braga',
    uf: 'RS'
  },
  {
    value: 'Butia',
    name: 'Butiá',
    uf: 'RS'
  },
  {
    value: 'Cacapava do Sul',
    name: 'Caçapava do Sul',
    uf: 'RS'
  },
  {
    value: 'Cacequi',
    name: 'Cacequi',
    uf: 'RS'
  },
  {
    value: 'Cachoeira do Sul',
    name: 'Cachoeira do Sul',
    uf: 'RS'
  },
  {
    value: 'Cachoeirinha',
    name: 'Cachoeirinha',
    uf: 'RS'
  },
  {
    value: 'Cacique Doble',
    name: 'Cacique Doble',
    uf: 'RS'
  },
  {
    value: 'Caibate',
    name: 'Caibaté',
    uf: 'RS'
  },
  {
    value: 'Caicara',
    name: 'Caiçara',
    uf: 'RS'
  },
  {
    value: 'Camaqua',
    name: 'Camaquã',
    uf: 'RS'
  },
  {
    value: 'Cambara do Sul',
    name: 'Cambará do Sul',
    uf: 'RS'
  },
  {
    value: 'Campina das Missoes',
    name: 'Campina das Missões',
    uf: 'RS'
  },
  {
    value: 'Campinas do Sul',
    name: 'Campinas do Sul',
    uf: 'RS'
  },
  {
    value: 'Campo Bom',
    name: 'Campo Bom',
    uf: 'RS'
  },
  {
    value: 'Campo Novo',
    name: 'Campo Novo',
    uf: 'RS'
  },
  {
    value: 'Candelaria',
    name: 'Candelária',
    uf: 'RS'
  },
  {
    value: 'Candido Godoi',
    name: 'Cândido Godói',
    uf: 'RS'
  },
  {
    value: 'Canela',
    name: 'Canela',
    uf: 'RS'
  },
  {
    value: 'Cangucu',
    name: 'Canguçu',
    uf: 'RS'
  },
  {
    value: 'Canoas',
    name: 'Canoas',
    uf: 'RS'
  },
  {
    value: 'Carazinho',
    name: 'Carazinho',
    uf: 'RS'
  },
  {
    value: 'Carlos Barbosa',
    name: 'Carlos Barbosa',
    uf: 'RS'
  },
  {
    value: 'Casca',
    name: 'Casca',
    uf: 'RS'
  },
  {
    value: 'Catuipe',
    name: 'Catuípe',
    uf: 'RS'
  },
  {
    value: 'Caxias do Sul',
    name: 'Caxias do Sul',
    uf: 'RS'
  },
  {
    value: 'Cerro Largo',
    name: 'Cerro Largo',
    uf: 'RS'
  },
  {
    value: 'Chapada',
    name: 'Chapada',
    uf: 'RS'
  },
  {
    value: 'Chiapetta',
    name: 'Chiapetta',
    uf: 'RS'
  },
  {
    value: 'Ciriaco',
    name: 'Ciríaco',
    uf: 'RS'
  },
  {
    value: 'Colorado',
    name: 'Colorado',
    uf: 'RS'
  },
  {
    value: 'Condor',
    name: 'Condor',
    uf: 'RS'
  },
  {
    value: 'Constantina',
    name: 'Constantina',
    uf: 'RS'
  },
  {
    value: 'Coronel Bicaco',
    name: 'Coronel Bicaco',
    uf: 'RS'
  },
  {
    value: 'Crissiumal',
    name: 'Crissiumal',
    uf: 'RS'
  },
  {
    value: 'Cruz Alta',
    name: 'Cruz Alta',
    uf: 'RS'
  },
  {
    value: 'Cruzeiro do Sul',
    name: 'Cruzeiro do Sul',
    uf: 'RS'
  },
  {
    value: 'David Canabarro',
    name: 'David Canabarro',
    uf: 'RS'
  },
  {
    value: 'Dois Irmaos',
    name: 'Dois Irmãos',
    uf: 'RS'
  },
  {
    value: 'Dom Feliciano',
    name: 'Dom Feliciano',
    uf: 'RS'
  },
  {
    value: 'Dom Pedrito',
    name: 'Dom Pedrito',
    uf: 'RS'
  },
  {
    value: 'Dona Francisca',
    name: 'Dona Francisca',
    uf: 'RS'
  },
  {
    value: 'Encantado',
    name: 'Encantado',
    uf: 'RS'
  },
  {
    value: 'Encruzilhada do Sul',
    name: 'Encruzilhada do Sul',
    uf: 'RS'
  },
  {
    value: 'Erechim',
    name: 'Erechim',
    uf: 'RS'
  },
  {
    value: 'Herval',
    name: 'Herval',
    uf: 'RS'
  },
  {
    value: 'Erval Grande',
    name: 'Erval Grande',
    uf: 'RS'
  },
  {
    value: 'Erval Seco',
    name: 'Erval Seco',
    uf: 'RS'
  },
  {
    value: 'Esmeralda',
    name: 'Esmeralda',
    uf: 'RS'
  },
  {
    value: 'Espumoso',
    name: 'Espumoso',
    uf: 'RS'
  },
  {
    value: 'Estancia Velha',
    name: 'Estância Velha',
    uf: 'RS'
  },
  {
    value: 'Esteio',
    name: 'Esteio',
    uf: 'RS'
  },
  {
    value: 'Estrela',
    name: 'Estrela',
    uf: 'RS'
  },
  {
    value: 'Farroupilha',
    name: 'Farroupilha',
    uf: 'RS'
  },
  {
    value: 'Faxinal do Soturno',
    name: 'Faxinal do Soturno',
    uf: 'RS'
  },
  {
    value: 'Feliz',
    name: 'Feliz',
    uf: 'RS'
  },
  {
    value: 'Flores da Cunha',
    name: 'Flores da Cunha',
    uf: 'RS'
  },
  {
    value: 'Fontoura Xavier',
    name: 'Fontoura Xavier',
    uf: 'RS'
  },
  {
    value: 'Formigueiro',
    name: 'Formigueiro',
    uf: 'RS'
  },
  {
    value: 'Frederico Westphalen',
    name: 'Frederico Westphalen',
    uf: 'RS'
  },
  {
    value: 'Garibaldi',
    name: 'Garibaldi',
    uf: 'RS'
  },
  {
    value: 'Gaurama',
    name: 'Gaurama',
    uf: 'RS'
  },
  {
    value: 'General Camara',
    name: 'General Câmara',
    uf: 'RS'
  },
  {
    value: 'Sao Vicente do Sul',
    name: 'São Vicente do Sul',
    uf: 'RS'
  },
  {
    value: 'Getulio Vargas',
    name: 'Getúlio Vargas',
    uf: 'RS'
  },
  {
    value: 'Flores de Goias',
    name: 'Flores de Goiás',
    uf: 'GO'
  },
  {
    value: 'Formosa',
    name: 'Formosa',
    uf: 'GO'
  },
  {
    value: 'Formoso',
    name: 'Formoso',
    uf: 'GO'
  },
  {
    value: 'Formoso do Araguaia',
    name: 'Formoso do Araguaia',
    uf: 'TO'
  },
  {
    value: 'Goianapolis',
    name: 'Goianápolis',
    uf: 'GO'
  },
  {
    value: 'Goiandira',
    name: 'Goiandira',
    uf: 'GO'
  },
  {
    value: 'Goianesia',
    name: 'Goianésia',
    uf: 'GO'
  },
  {
    value: 'Goiania',
    name: 'Goiânia',
    uf: 'GO'
  },
  {
    value: 'Goianira',
    name: 'Goianira',
    uf: 'GO'
  },
  {
    value: 'Goias',
    name: 'Goiás',
    uf: 'GO'
  },
  {
    value: 'Goiatuba',
    name: 'Goiatuba',
    uf: 'GO'
  },
  {
    value: 'Guapo',
    name: 'Guapó',
    uf: 'GO'
  },
  {
    value: 'Guarani de Goias',
    name: 'Guarani de Goiás',
    uf: 'GO'
  },
  {
    value: 'Gurupi',
    name: 'Gurupi',
    uf: 'TO'
  },
  {
    value: 'Heitorai',
    name: 'Heitoraí',
    uf: 'GO'
  },
  {
    value: 'Hidrolandia',
    name: 'Hidrolândia',
    uf: 'GO'
  },
  {
    value: 'Hidrolina',
    name: 'Hidrolina',
    uf: 'GO'
  },
  {
    value: 'Iaciara',
    name: 'Iaciara',
    uf: 'GO'
  },
  {
    value: 'Inhumas',
    name: 'Inhumas',
    uf: 'GO'
  },
  {
    value: 'Ipameri',
    name: 'Ipameri',
    uf: 'GO'
  },
  {
    value: 'Ipora',
    name: 'Iporá',
    uf: 'GO'
  },
  {
    value: 'Israelandia',
    name: 'Israelândia',
    uf: 'GO'
  },
  {
    value: 'Itaberai',
    name: 'Itaberaí',
    uf: 'GO'
  },
  {
    value: 'Itacaja',
    name: 'Itacajá',
    uf: 'TO'
  },
  {
    value: 'Itaguaru',
    name: 'Itaguaru',
    uf: 'GO'
  },
  {
    value: 'Itaguatins',
    name: 'Itaguatins',
    uf: 'TO'
  },
  {
    value: 'Itaja',
    name: 'Itajá',
    uf: 'GO'
  },
  {
    value: 'Itapaci',
    name: 'Itapaci',
    uf: 'GO'
  },
  {
    value: 'Itapirapua',
    name: 'Itapirapuã',
    uf: 'GO'
  },
  {
    value: 'Itapora do Tocantins',
    name: 'Itaporã do Tocantins',
    uf: 'TO'
  },
  {
    value: 'Itapuranga',
    name: 'Itapuranga',
    uf: 'GO'
  },
  {
    value: 'Itaruma',
    name: 'Itarumã',
    uf: 'GO'
  },
  {
    value: 'Itaucu',
    name: 'Itauçu',
    uf: 'GO'
  },
  {
    value: 'Itumbiara',
    name: 'Itumbiara',
    uf: 'GO'
  },
  {
    value: 'Ivolandia',
    name: 'Ivolândia',
    uf: 'GO'
  },
  {
    value: 'Jandaia',
    name: 'Jandaia',
    uf: 'GO'
  },
  {
    value: 'Jaragua',
    name: 'Jaraguá',
    uf: 'GO'
  },
  {
    value: 'Jatai',
    name: 'Jataí',
    uf: 'GO'
  },
  {
    value: 'Jaupaci',
    name: 'Jaupaci',
    uf: 'GO'
  },
  {
    value: 'Joviania',
    name: 'Joviânia',
    uf: 'GO'
  },
  {
    value: 'Jussara',
    name: 'Jussara',
    uf: 'GO'
  },
  {
    value: 'Alianca do Tocantins',
    name: 'Aliança do Tocantins',
    uf: 'TO'
  },
  {
    value: 'Leopoldo de Bulhoes',
    name: 'Leopoldo de Bulhões',
    uf: 'GO'
  },
  {
    value: 'Luziania',
    name: 'Luziânia',
    uf: 'GO'
  },
  {
    value: 'Mairipotaba',
    name: 'Mairipotaba',
    uf: 'GO'
  },
  {
    value: 'Mambai',
    name: 'Mambaí',
    uf: 'GO'
  },
  {
    value: 'Mara Rosa',
    name: 'Mara Rosa',
    uf: 'GO'
  },
  {
    value: 'Marzagao',
    name: 'Marzagão',
    uf: 'GO'
  },
  {
    value: 'Paranaiguara',
    name: 'Paranaiguara',
    uf: 'GO'
  },
  {
    value: 'Maurilandia',
    name: 'Maurilândia',
    uf: 'GO'
  },
  {
    value: 'Mineiros',
    name: 'Mineiros',
    uf: 'GO'
  },
  {
    value: 'Miracema do Tocantins',
    name: 'Miracema do Tocantins',
    uf: 'TO'
  },
  {
    value: 'Miranorte',
    name: 'Miranorte',
    uf: 'TO'
  },
  {
    value: 'Moipora',
    name: 'Moiporá',
    uf: 'GO'
  },
  {
    value: 'Monte Alegre de Goias',
    name: 'Monte Alegre de Goiás',
    uf: 'GO'
  },
  {
    value: 'Monte do Carmo',
    name: 'Monte do Carmo',
    uf: 'TO'
  },
  {
    value: 'Montes Claros de Goias',
    name: 'Montes Claros de Goiás',
    uf: 'GO'
  },
  {
    value: 'Morrinhos',
    name: 'Morrinhos',
    uf: 'GO'
  },
  {
    value: 'Mossamedes',
    name: 'Mossâmedes',
    uf: 'GO'
  },
  {
    value: 'Mozarlandia',
    name: 'Mozarlândia',
    uf: 'GO'
  },
  {
    value: 'Mutunopolis',
    name: 'Mutunópolis',
    uf: 'GO'
  },
  {
    value: 'Natividade',
    name: 'Natividade',
    uf: 'TO'
  },
  {
    value: 'Nazare',
    name: 'Nazaré',
    uf: 'TO'
  },
  {
    value: 'Nazario',
    name: 'Nazário',
    uf: 'GO'
  },
  {
    value: 'Neropolis',
    name: 'Nerópolis',
    uf: 'GO'
  },
  {
    value: 'Niquelandia',
    name: 'Niquelândia',
    uf: 'GO'
  },
  {
    value: 'Nova America',
    name: 'Nova América',
    uf: 'GO'
  },
  {
    value: 'Nova Aurora',
    name: 'Nova Aurora',
    uf: 'GO'
  },
  {
    value: 'Nova Roma',
    name: 'Nova Roma',
    uf: 'GO'
  },
  {
    value: 'Nova Veneza',
    name: 'Nova Veneza',
    uf: 'GO'
  },
  {
    value: 'Novo Acordo',
    name: 'Novo Acordo',
    uf: 'TO'
  },
  {
    value: 'Novo Brasil',
    name: 'Novo Brasil',
    uf: 'GO'
  },
  {
    value: 'Orizona',
    name: 'Orizona',
    uf: 'GO'
  },
  {
    value: 'Ouro Verde de Goias',
    name: 'Ouro Verde de Goiás',
    uf: 'GO'
  },
  {
    value: 'Ouvidor',
    name: 'Ouvidor',
    uf: 'GO'
  },
  {
    value: 'Padre Bernardo',
    name: 'Padre Bernardo',
    uf: 'GO'
  },
  {
    value: 'Palmeiras de Goias',
    name: 'Palmeiras de Goiás',
    uf: 'GO'
  },
  {
    value: 'Palmelo',
    name: 'Palmelo',
    uf: 'GO'
  },
  {
    value: 'Palminopolis',
    name: 'Palminópolis',
    uf: 'GO'
  },
  {
    value: 'Panama',
    name: 'Panamá',
    uf: 'GO'
  },
  {
    value: 'Paraiso do Tocantins',
    name: 'Paraíso do Tocantins',
    uf: 'TO'
  },
  {
    value: 'Parana',
    name: 'Paranã',
    uf: 'TO'
  },
  {
    value: 'Parauna',
    name: 'Paraúna',
    uf: 'GO'
  },
  {
    value: 'Pedro Afonso',
    name: 'Pedro Afonso',
    uf: 'TO'
  },
  {
    value: 'Peixe',
    name: 'Peixe',
    uf: 'TO'
  },
  {
    value: 'Colmeia',
    name: 'Colméia',
    uf: 'TO'
  },
  {
    value: 'Petrolina de Goias',
    name: 'Petrolina de Goiás',
    uf: 'GO'
  },
  {
    value: 'Goiatins',
    name: 'Goiatins',
    uf: 'TO'
  },
  {
    value: 'Pilar de Goias',
    name: 'Pilar de Goiás',
    uf: 'GO'
  },
  {
    value: 'Pindorama do Tocantins',
    name: 'Pindorama do Tocantins',
    uf: 'TO'
  },
  {
    value: 'Piracanjuba',
    name: 'Piracanjuba',
    uf: 'GO'
  },
  {
    value: 'Piranhas',
    name: 'Piranhas',
    uf: 'GO'
  },
  {
    value: 'Pirenopolis',
    name: 'Pirenópolis',
    uf: 'GO'
  },
  {
    value: 'Pires do Rio',
    name: 'Pires do Rio',
    uf: 'GO'
  },
  {
    value: 'Pium',
    name: 'Pium',
    uf: 'TO'
  },
  {
    value: 'Pontalina',
    name: 'Pontalina',
    uf: 'GO'
  },
  {
    value: 'Ponte Alta do Bom Jesus',
    name: 'Ponte Alta do Bom Jesus',
    uf: 'TO'
  },
  {
    value: 'Ponte Alta do Tocantins',
    name: 'Ponte Alta do Tocantins',
    uf: 'TO'
  },
  {
    value: 'Porangatu',
    name: 'Porangatu',
    uf: 'GO'
  },
  {
    value: 'Portelandia',
    name: 'Portelândia',
    uf: 'GO'
  },
  {
    value: 'Porto Nacional',
    name: 'Porto Nacional',
    uf: 'TO'
  },
  {
    value: 'Posse',
    name: 'Posse',
    uf: 'GO'
  },
  {
    value: 'Quirinopolis',
    name: 'Quirinópolis',
    uf: 'GO'
  },
  {
    value: 'Rialma',
    name: 'Rialma',
    uf: 'GO'
  },
  {
    value: 'Rianapolis',
    name: 'Rianápolis',
    uf: 'GO'
  },
  {
    value: 'Lizarda',
    name: 'Lizarda',
    uf: 'TO'
  },
  {
    value: 'Campo Limpo de Goias',
    name: 'Campo Limpo de Goiás',
    uf: 'GO'
  },
  {
    value: 'Gameleira de Goias',
    name: 'Gameleira de Goiás',
    uf: 'GO'
  },
  {
    value: 'Ipiranga de Goias',
    name: 'Ipiranga de Goiás',
    uf: 'GO'
  },
  {
    value: 'Lagoa Santa',
    name: 'Lagoa Santa',
    uf: 'GO'
  },
  {
    value: 'Bom Jesus do Araguaia',
    name: 'Bom Jesus do Araguaia',
    uf: 'MT'
  },
  {
    value: 'Colniza',
    name: 'Colniza',
    uf: 'MT'
  },
  {
    value: "Conquista D'Oeste",
    name: "Conquista D'Oeste",
    uf: 'MT'
  },
  {
    value: 'Curvelandia',
    name: 'Curvelândia',
    uf: 'MT'
  },
  {
    value: 'Nova Nazare',
    name: 'Nova Nazaré',
    uf: 'MT'
  },
  {
    value: 'Nova Santa Helena',
    name: 'Nova Santa Helena',
    uf: 'MT'
  },
  {
    value: 'Novo Santo Antonio',
    name: 'Novo Santo Antônio',
    uf: 'MT'
  },
  {
    value: 'Rondolandia',
    name: 'Rondolândia',
    uf: 'MT'
  },
  {
    value: 'Barrocas',
    name: 'Barrocas',
    uf: 'BA'
  },
  {
    value: 'Luis Eduardo Magalhaes',
    name: 'Luís Eduardo Magalhães',
    uf: 'BA'
  },
  {
    value: 'Governador Lindenberg',
    name: 'Governador Lindenberg',
    uf: 'ES'
  },
  {
    value: 'Nazaria',
    name: 'Nazária',
    uf: 'PI'
  },
  {
    value: 'Mojui dos Campos',
    name: 'Mojuí dos Campos',
    uf: 'PA'
  },
  {
    value: 'Balneario Rincao',
    name: 'Balneário Rincão',
    uf: 'SC'
  },
  {
    value: 'Pescaria Brava',
    name: 'Pescaria Brava',
    uf: 'SC'
  },
  {
    value: 'Paraiso das Aguas',
    name: 'Paraíso das Águas',
    uf: 'MS'
  },
  {
    value: 'Girua',
    name: 'Giruá',
    uf: 'RS'
  },
  {
    value: 'Gramado',
    name: 'Gramado',
    uf: 'RS'
  },
  {
    value: 'Gravatai',
    name: 'Gravataí',
    uf: 'RS'
  },
  {
    value: 'Guaiba',
    name: 'Guaíba',
    uf: 'RS'
  },
  {
    value: 'Guapore',
    name: 'Guaporé',
    uf: 'RS'
  },
  {
    value: 'Guarani das Missoes',
    name: 'Guarani das Missões',
    uf: 'RS'
  },
  {
    value: 'Horizontina',
    name: 'Horizontina',
    uf: 'RS'
  },
  {
    value: 'Charqueadas',
    name: 'Charqueadas',
    uf: 'RS'
  },
  {
    value: 'Humaita',
    name: 'Humaitá',
    uf: 'RS'
  },
  {
    value: 'Ibiaca',
    name: 'Ibiaçá',
    uf: 'RS'
  },
  {
    value: 'Ibiraiaras',
    name: 'Ibiraiaras',
    uf: 'RS'
  },
  {
    value: 'Ibiruba',
    name: 'Ibirubá',
    uf: 'RS'
  },
  {
    value: 'Igrejinha',
    name: 'Igrejinha',
    uf: 'RS'
  },
  {
    value: 'Ijui',
    name: 'Ijuí',
    uf: 'RS'
  },
  {
    value: 'Ilopolis',
    name: 'Ilópolis',
    uf: 'RS'
  },
  {
    value: 'Independencia',
    name: 'Independência',
    uf: 'RS'
  },
  {
    value: 'Irai',
    name: 'Iraí',
    uf: 'RS'
  },
  {
    value: 'Itaqui',
    name: 'Itaqui',
    uf: 'RS'
  },
  {
    value: 'Itatiba do Sul',
    name: 'Itatiba do Sul',
    uf: 'RS'
  },
  {
    value: 'Ivoti',
    name: 'Ivoti',
    uf: 'RS'
  },
  {
    value: 'Jacutinga',
    name: 'Jacutinga',
    uf: 'RS'
  },
  {
    value: 'Jaguarao',
    name: 'Jaguarão',
    uf: 'RS'
  },
  {
    value: 'Jaguari',
    name: 'Jaguari',
    uf: 'RS'
  },
  {
    value: 'Julio de Castilhos',
    name: 'Júlio de Castilhos',
    uf: 'RS'
  },
  {
    value: 'Lagoa Vermelha',
    name: 'Lagoa Vermelha',
    uf: 'RS'
  },
  {
    value: 'Lajeado',
    name: 'Lajeado',
    uf: 'RS'
  },
  {
    value: 'Lavras do Sul',
    name: 'Lavras do Sul',
    uf: 'RS'
  },
  {
    value: 'Liberato Salzano',
    name: 'Liberato Salzano',
    uf: 'RS'
  },
  {
    value: 'Machadinho',
    name: 'Machadinho',
    uf: 'RS'
  },
  {
    value: 'Marau',
    name: 'Marau',
    uf: 'RS'
  },
  {
    value: 'Marcelino Ramos',
    name: 'Marcelino Ramos',
    uf: 'RS'
  },
  {
    value: 'Mariano Moro',
    name: 'Mariano Moro',
    uf: 'RS'
  },
  {
    value: 'Mata',
    name: 'Mata',
    uf: 'RS'
  },
  {
    value: 'Maximiliano de Almeida',
    name: 'Maximiliano de Almeida',
    uf: 'RS'
  },
  {
    value: 'Miraguai',
    name: 'Miraguaí',
    uf: 'RS'
  },
  {
    value: 'Montenegro',
    name: 'Montenegro',
    uf: 'RS'
  },
  {
    value: 'Mostardas',
    name: 'Mostardas',
    uf: 'RS'
  },
  {
    value: 'Mucum',
    name: 'Muçum',
    uf: 'RS'
  },
  {
    value: 'Nao-Me-Toque',
    name: 'Não-Me-Toque',
    uf: 'RS'
  },
  {
    value: 'Nonoai',
    name: 'Nonoai',
    uf: 'RS'
  },
  {
    value: 'Nova Araca',
    name: 'Nova Araçá',
    uf: 'RS'
  },
  {
    value: 'Nova Bassano',
    name: 'Nova Bassano',
    uf: 'RS'
  },
  {
    value: 'Nova Brescia',
    name: 'Nova Bréscia',
    uf: 'RS'
  },
  {
    value: 'Nova Palma',
    name: 'Nova Palma',
    uf: 'RS'
  },
  {
    value: 'Nova Petropolis',
    name: 'Nova Petrópolis',
    uf: 'RS'
  },
  {
    value: 'Nova Prata',
    name: 'Nova Prata',
    uf: 'RS'
  },
  {
    value: 'Novo Hamburgo',
    name: 'Novo Hamburgo',
    uf: 'RS'
  },
  {
    value: 'Osorio',
    name: 'Osório',
    uf: 'RS'
  },
  {
    value: 'Paim Filho',
    name: 'Paim Filho',
    uf: 'RS'
  },
  {
    value: 'Palmeira das Missoes',
    name: 'Palmeira das Missões',
    uf: 'RS'
  },
  {
    value: 'Palmitinho',
    name: 'Palmitinho',
    uf: 'RS'
  },
  {
    value: 'Panambi',
    name: 'Panambi',
    uf: 'RS'
  },
  {
    value: 'Parai',
    name: 'Paraí',
    uf: 'RS'
  },
  {
    value: 'Passo Fundo',
    name: 'Passo Fundo',
    uf: 'RS'
  },
  {
    value: 'Pedro Osorio',
    name: 'Pedro Osório',
    uf: 'RS'
  },
  {
    value: 'Pejucara',
    name: 'Pejuçara',
    uf: 'RS'
  },
  {
    value: 'Pelotas',
    name: 'Pelotas',
    uf: 'RS'
  },
  {
    value: 'Pinheiro Machado',
    name: 'Pinheiro Machado',
    uf: 'RS'
  },
  {
    value: 'Piratini',
    name: 'Piratini',
    uf: 'RS'
  },
  {
    value: 'Planalto',
    name: 'Planalto',
    uf: 'RS'
  },
  {
    value: 'Portao',
    name: 'Portão',
    uf: 'RS'
  },
  {
    value: 'Porto Alegre',
    name: 'Porto Alegre',
    uf: 'RS'
  },
  {
    value: 'Porto Lucena',
    name: 'Porto Lucena',
    uf: 'RS'
  },
  {
    value: 'Porto Xavier',
    name: 'Porto Xavier',
    uf: 'RS'
  },
  {
    value: 'Putinga',
    name: 'Putinga',
    uf: 'RS'
  },
  {
    value: 'Quarai',
    name: 'Quaraí',
    uf: 'RS'
  },
  {
    value: 'Redentora',
    name: 'Redentora',
    uf: 'RS'
  },
  {
    value: 'Restinga Seca',
    name: 'Restinga Sêca',
    uf: 'RS'
  },
  {
    value: 'Rio Grande',
    name: 'Rio Grande',
    uf: 'RS'
  },
  {
    value: 'Rio Pardo',
    name: 'Rio Pardo',
    uf: 'RS'
  },
  {
    value: 'Roca Sales',
    name: 'Roca Sales',
    uf: 'RS'
  },
  {
    value: 'Rodeio Bonito',
    name: 'Rodeio Bonito',
    uf: 'RS'
  },
  {
    value: 'Rolante',
    name: 'Rolante',
    uf: 'RS'
  },
  {
    value: 'Ronda Alta',
    name: 'Ronda Alta',
    uf: 'RS'
  },
  {
    value: 'Rondinha',
    name: 'Rondinha',
    uf: 'RS'
  },
  {
    value: 'Roque Gonzales',
    name: 'Roque Gonzales',
    uf: 'RS'
  },
  {
    value: 'Rosario do Sul',
    name: 'Rosário do Sul',
    uf: 'RS'
  },
  {
    value: 'Salvador do Sul',
    name: 'Salvador do Sul',
    uf: 'RS'
  },
  {
    value: 'Sananduva',
    name: 'Sananduva',
    uf: 'RS'
  },
  {
    value: 'Santa Barbara do Sul',
    name: 'Santa Bárbara do Sul',
    uf: 'RS'
  },
  {
    value: 'Santa Cruz do Sul',
    name: 'Santa Cruz do Sul',
    uf: 'RS'
  },
  {
    value: 'Santa Maria',
    name: 'Santa Maria',
    uf: 'RS'
  },
  {
    value: 'Santana da Boa Vista',
    name: 'Santana da Boa Vista',
    uf: 'RS'
  },
  {
    value: "Sant'Ana do Livramento",
    name: "Sant'Ana do Livramento",
    uf: 'RS'
  },
  {
    value: 'Santa Rosa',
    name: 'Santa Rosa',
    uf: 'RS'
  },
  {
    value: 'Santa Vitoria do Palmar',
    name: 'Santa Vitória do Palmar',
    uf: 'RS'
  },
  {
    value: 'Santiago',
    name: 'Santiago',
    uf: 'RS'
  },
  {
    value: 'Santo Angelo',
    name: 'Santo Ângelo',
    uf: 'RS'
  },
  {
    value: 'Santo Antonio da Patrulha',
    name: 'Santo Antônio da Patrulha',
    uf: 'RS'
  },
  {
    value: 'Santo Antonio das Missoes',
    name: 'Santo Antônio das Missões',
    uf: 'RS'
  },
  {
    value: 'Santo Augusto',
    name: 'Santo Augusto',
    uf: 'RS'
  },
  {
    value: 'Santo Cristo',
    name: 'Santo Cristo',
    uf: 'RS'
  },
  {
    value: 'Sao Borja',
    name: 'São Borja',
    uf: 'RS'
  },
  {
    value: 'Sao Francisco de Assis',
    name: 'São Francisco de Assis',
    uf: 'RS'
  },
  {
    value: 'Sao Francisco de Paula',
    name: 'São Francisco de Paula',
    uf: 'RS'
  },
  {
    value: 'Sao Gabriel',
    name: 'São Gabriel',
    uf: 'RS'
  },
  {
    value: 'Sao Jeronimo',
    name: 'São Jerônimo',
    uf: 'RS'
  },
  {
    value: 'Sao Jose do Norte',
    name: 'São José do Norte',
    uf: 'RS'
  },
  {
    value: 'Sao Jose do Ouro',
    name: 'São José do Ouro',
    uf: 'RS'
  },
  {
    value: 'Sao Leopoldo',
    name: 'São Leopoldo',
    uf: 'RS'
  },
  {
    value: 'Sao Lourenco do Sul',
    name: 'São Lourenço do Sul',
    uf: 'RS'
  },
  {
    value: 'Sao Luiz Gonzaga',
    name: 'São Luiz Gonzaga',
    uf: 'RS'
  },
  {
    value: 'Sao Marcos',
    name: 'São Marcos',
    uf: 'RS'
  },
  {
    value: 'Sao Martinho',
    name: 'São Martinho',
    uf: 'RS'
  },
  {
    value: 'Sao Nicolau',
    name: 'São Nicolau',
    uf: 'RS'
  },
  {
    value: 'Sao Paulo das Missoes',
    name: 'São Paulo das Missões',
    uf: 'RS'
  },
  {
    value: 'Sao Pedro do Sul',
    name: 'São Pedro do Sul',
    uf: 'RS'
  },
  {
    value: 'Sao Sebastiao do Cai',
    name: 'São Sebastião do Caí',
    uf: 'RS'
  },
  {
    value: 'Sao Sepe',
    name: 'São Sepé',
    uf: 'RS'
  },
  {
    value: 'Sao Valentim',
    name: 'São Valentim',
    uf: 'RS'
  },
  {
    value: 'Sapiranga',
    name: 'Sapiranga',
    uf: 'RS'
  },
  {
    value: 'Sapucaia do Sul',
    name: 'Sapucaia do Sul',
    uf: 'RS'
  },
  {
    value: 'Sarandi',
    name: 'Sarandi',
    uf: 'RS'
  },
  {
    value: 'Seberi',
    name: 'Seberi',
    uf: 'RS'
  },
  {
    value: 'Selbach',
    name: 'Selbach',
    uf: 'RS'
  },
  {
    value: 'Serafina Correa',
    name: 'Serafina Corrêa',
    uf: 'RS'
  },
  {
    value: 'Sertao',
    name: 'Sertão',
    uf: 'RS'
  },
  {
    value: 'Severiano de Almeida',
    name: 'Severiano de Almeida',
    uf: 'RS'
  },
  {
    value: 'Capao da Canoa',
    name: 'Capão da Canoa',
    uf: 'RS'
  },
  {
    value: 'Sobradinho',
    name: 'Sobradinho',
    uf: 'RS'
  },
  {
    value: 'Soledade',
    name: 'Soledade',
    uf: 'RS'
  },
  {
    value: 'Tapejara',
    name: 'Tapejara',
    uf: 'RS'
  },
  {
    value: 'Tapera',
    name: 'Tapera',
    uf: 'RS'
  },
  {
    value: 'Tapes',
    name: 'Tapes',
    uf: 'RS'
  },
  {
    value: 'Taquara',
    name: 'Taquara',
    uf: 'RS'
  },
  {
    value: 'Taquari',
    name: 'Taquari',
    uf: 'RS'
  },
  {
    value: 'Tenente Portela',
    name: 'Tenente Portela',
    uf: 'RS'
  },
  {
    value: 'Torres',
    name: 'Torres',
    uf: 'RS'
  },
  {
    value: 'Tramandai',
    name: 'Tramandaí',
    uf: 'RS'
  },
  {
    value: 'Tres Coroas',
    name: 'Três Coroas',
    uf: 'RS'
  },
  {
    value: 'Tres de Maio',
    name: 'Três de Maio',
    uf: 'RS'
  },
  {
    value: 'Tres Passos',
    name: 'Três Passos',
    uf: 'RS'
  },
  {
    value: 'Triunfo',
    name: 'Triunfo',
    uf: 'RS'
  },
  {
    value: 'Tucunduva',
    name: 'Tucunduva',
    uf: 'RS'
  },
  {
    value: 'Tupancireta',
    name: 'Tupanciretã',
    uf: 'RS'
  },
  {
    value: 'Tuparendi',
    name: 'Tuparendi',
    uf: 'RS'
  },
  {
    value: 'Uruguaiana',
    name: 'Uruguaiana',
    uf: 'RS'
  },
  {
    value: 'Vacaria',
    name: 'Vacaria',
    uf: 'RS'
  },
  {
    value: 'Venancio Aires',
    name: 'Venâncio Aires',
    uf: 'RS'
  },
  {
    value: 'Vera Cruz',
    name: 'Vera Cruz',
    uf: 'RS'
  },
  {
    value: 'Veranopolis',
    name: 'Veranópolis',
    uf: 'RS'
  },
  {
    value: 'Viadutos',
    name: 'Viadutos',
    uf: 'RS'
  },
  {
    value: 'Viamao',
    name: 'Viamão',
    uf: 'RS'
  },
  {
    value: 'Vicente Dutra',
    name: 'Vicente Dutra',
    uf: 'RS'
  },
  {
    value: 'Palmares do Sul',
    name: 'Palmares do Sul',
    uf: 'RS'
  },
  {
    value: 'Victor Graeff',
    name: 'Victor Graeff',
    uf: 'RS'
  },
  {
    value: 'Tavares',
    name: 'Tavares',
    uf: 'RS'
  },
  {
    value: 'Capao do Leao',
    name: 'Capão do Leão',
    uf: 'RS'
  },
  {
    value: 'Salto do Jacui',
    name: 'Salto do Jacuí',
    uf: 'RS'
  },
  {
    value: 'Cotipora',
    name: 'Cotiporã',
    uf: 'RS'
  },
  {
    value: 'Colider',
    name: 'Colíder',
    uf: 'MT'
  },
  {
    value: 'Nova Brasilandia',
    name: 'Nova Brasilândia',
    uf: 'MT'
  },
  {
    value: 'Paranatinga',
    name: 'Paranatinga',
    uf: 'MT'
  },
  {
    value: 'Sinop',
    name: 'Sinop',
    uf: 'MT'
  },
  {
    value: 'Alta Floresta',
    name: 'Alta Floresta',
    uf: 'MT'
  },
  {
    value: 'Araputanga',
    name: 'Araputanga',
    uf: 'MT'
  },
  {
    value: 'Jauru',
    name: 'Jauru',
    uf: 'MT'
  },
  {
    value: 'Sao Jose dos Quatro Marcos',
    name: 'São José dos Quatro Marcos',
    uf: 'MT'
  },
  {
    value: 'Rio Branco',
    name: 'Rio Branco',
    uf: 'MT'
  },
  {
    value: 'Salto do Ceu',
    name: 'Salto do Céu',
    uf: 'MT'
  },
  {
    value: 'Pontes e Lacerda',
    name: 'Pontes e Lacerda',
    uf: 'MT'
  },
  {
    value: 'Acorizal',
    name: 'Acorizal',
    uf: 'MT'
  },
  {
    value: 'Agua Clara',
    name: 'Água Clara',
    uf: 'MS'
  },
  {
    value: 'Alto Araguaia',
    name: 'Alto Araguaia',
    uf: 'MT'
  },
  {
    value: 'Alto Garcas',
    name: 'Alto Garças',
    uf: 'MT'
  },
  {
    value: 'Alto Paraguai',
    name: 'Alto Paraguai',
    uf: 'MT'
  },
  {
    value: 'Amambai',
    name: 'Amambai',
    uf: 'MS'
  },
  {
    value: 'Anastacio',
    name: 'Anastácio',
    uf: 'MS'
  },
  {
    value: 'Anaurilandia',
    name: 'Anaurilândia',
    uf: 'MS'
  },
  {
    value: 'Antonio Joao',
    name: 'Antônio João',
    uf: 'MS'
  },
  {
    value: 'Aparecida do Taboado',
    name: 'Aparecida do Taboado',
    uf: 'MS'
  },
  {
    value: 'Aquidauana',
    name: 'Aquidauana',
    uf: 'MS'
  },
  {
    value: 'Araguainha',
    name: 'Araguainha',
    uf: 'MT'
  },
  {
    value: 'Arenapolis',
    name: 'Arenápolis',
    uf: 'MT'
  },
  {
    value: 'Aripuana',
    name: 'Aripuanã',
    uf: 'MT'
  },
  {
    value: 'Bandeirantes',
    name: 'Bandeirantes',
    uf: 'MS'
  },
  {
    value: 'Barao de Melgaco',
    name: 'Barão de Melgaço',
    uf: 'MT'
  },
  {
    value: 'Barra do Bugres',
    name: 'Barra do Bugres',
    uf: 'MT'
  },
  {
    value: 'Barra do Garcas',
    name: 'Barra do Garças',
    uf: 'MT'
  },
  {
    value: 'Bataguassu',
    name: 'Bataguassu',
    uf: 'MS'
  },
  {
    value: 'Bataypora',
    name: 'Batayporã',
    uf: 'MS'
  },
  {
    value: 'Bela Vista',
    name: 'Bela Vista',
    uf: 'MS'
  },
  {
    value: 'Bonito',
    name: 'Bonito',
    uf: 'MS'
  },
  {
    value: 'Brasilandia',
    name: 'Brasilândia',
    uf: 'MS'
  },
  {
    value: 'Caceres',
    name: 'Cáceres',
    uf: 'MT'
  },
  {
    value: 'Camapua',
    name: 'Camapuã',
    uf: 'MS'
  },
  {
    value: 'Campo Grande',
    name: 'Campo Grande',
    uf: 'MS'
  },
  {
    value: 'Caracol',
    name: 'Caracol',
    uf: 'MS'
  },
  {
    value: 'Caarapo',
    name: 'Caarapó',
    uf: 'MS'
  },
  {
    value: 'Cassilandia',
    name: 'Cassilândia',
    uf: 'MS'
  },
  {
    value: 'Chapada dos Guimaraes',
    name: 'Chapada dos Guimarães',
    uf: 'MT'
  },
  {
    value: 'Corguinho',
    name: 'Corguinho',
    uf: 'MS'
  },
  {
    value: 'Corumba',
    name: 'Corumbá',
    uf: 'MS'
  },
  {
    value: 'Coxim',
    name: 'Coxim',
    uf: 'MS'
  },
  {
    value: 'Cuiaba',
    name: 'Cuiabá',
    uf: 'MT'
  },
  {
    value: 'Diamantino',
    name: 'Diamantino',
    uf: 'MT'
  },
  {
    value: 'Dom Aquino',
    name: 'Dom Aquino',
    uf: 'MT'
  },
  {
    value: 'Dourados',
    name: 'Dourados',
    uf: 'MS'
  },
  {
    value: 'Fatima do Sul',
    name: 'Fátima do Sul',
    uf: 'MS'
  },
  {
    value: 'General Carneiro',
    name: 'General Carneiro',
    uf: 'MT'
  },
  {
    value: 'Gloria de Dourados',
    name: 'Glória de Dourados',
    uf: 'MS'
  },
  {
    value: 'Guia Lopes da Laguna',
    name: 'Guia Lopes da Laguna',
    uf: 'MS'
  },
  {
    value: 'Guiratinga',
    name: 'Guiratinga',
    uf: 'MT'
  },
  {
    value: 'Iguatemi',
    name: 'Iguatemi',
    uf: 'MS'
  },
  {
    value: 'Inocencia',
    name: 'Inocência',
    uf: 'MS'
  },
  {
    value: 'Itapora',
    name: 'Itaporã',
    uf: 'MS'
  },
  {
    value: 'Itiquira',
    name: 'Itiquira',
    uf: 'MT'
  },
  {
    value: 'Ivinhema',
    name: 'Ivinhema',
    uf: 'MS'
  },
  {
    value: 'Jaciara',
    name: 'Jaciara',
    uf: 'MT'
  },
  {
    value: 'Jaraguari',
    name: 'Jaraguari',
    uf: 'MS'
  },
  {
    value: 'Jardim',
    name: 'Jardim',
    uf: 'MS'
  },
  {
    value: 'Jatei',
    name: 'Jateí',
    uf: 'MS'
  },
  {
    value: 'Ladario',
    name: 'Ladário',
    uf: 'MS'
  },
  {
    value: 'Luciara',
    name: 'Luciara',
    uf: 'MT'
  },
  {
    value: 'Maracaju',
    name: 'Maracaju',
    uf: 'MS'
  },
  {
    value: 'Vila Bela da Santissima Trindade',
    name: 'Vila Bela da Santíssima Trindade',
    uf: 'MT'
  },
  {
    value: 'Miranda',
    name: 'Miranda',
    uf: 'MS'
  },
  {
    value: 'Navirai',
    name: 'Naviraí',
    uf: 'MS'
  },
  {
    value: 'Nioaque',
    name: 'Nioaque',
    uf: 'MS'
  },
  {
    value: 'Nobres',
    name: 'Nobres',
    uf: 'MT'
  },
  {
    value: 'Nortelandia',
    name: 'Nortelândia',
    uf: 'MT'
  },
  {
    value: 'Nossa Senhora do Livramento',
    name: 'Nossa Senhora do Livramento',
    uf: 'MT'
  },
  {
    value: 'Nova Andradina',
    name: 'Nova Andradina',
    uf: 'MS'
  },
  {
    value: 'Paranaiba',
    name: 'Paranaíba',
    uf: 'MS'
  },
  {
    value: 'Pedro Gomes',
    name: 'Pedro Gomes',
    uf: 'MS'
  },
  {
    value: 'Pocone',
    name: 'Poconé',
    uf: 'MT'
  },
  {
    value: 'Ponta Pora',
    name: 'Ponta Porã',
    uf: 'MS'
  },
  {
    value: 'Ponte Branca',
    name: 'Ponte Branca',
    uf: 'MT'
  },
  {
    value: 'Porto dos Gauchos',
    name: 'Porto dos Gaúchos',
    uf: 'MT'
  },
  {
    value: 'Porto Murtinho',
    name: 'Porto Murtinho',
    uf: 'MS'
  },
  {
    value: 'Poxoreu',
    name: 'Poxoréu',
    uf: 'MT'
  },
  {
    value: 'Ribas do Rio Pardo',
    name: 'Ribas do Rio Pardo',
    uf: 'MS'
  },
  {
    value: 'Rio Brilhante',
    name: 'Rio Brilhante',
    uf: 'MS'
  },
  {
    value: 'Rio Negro',
    name: 'Rio Negro',
    uf: 'MS'
  },
  {
    value: 'Rio Verde de Mato Grosso',
    name: 'Rio Verde de Mato Grosso',
    uf: 'MS'
  },
  {
    value: 'Rochedo',
    name: 'Rochedo',
    uf: 'MS'
  },
  {
    value: 'Rondonopolis',
    name: 'Rondonópolis',
    uf: 'MT'
  },
  {
    value: 'Rosario Oeste',
    name: 'Rosário Oeste',
    uf: 'MT'
  },
  {
    value: 'Santo Antonio do Leverger',
    name: 'Santo Antônio do Leverger',
    uf: 'MT'
  },
  {
    value: 'Sidrolandia',
    name: 'Sidrolândia',
    uf: 'MS'
  },
  {
    value: 'Terenos',
    name: 'Terenos',
    uf: 'MS'
  },
  {
    value: 'Tesouro',
    name: 'Tesouro',
    uf: 'MT'
  },
  {
    value: 'Torixoreu',
    name: 'Torixoréu',
    uf: 'MT'
  },
  {
    value: 'Tres Lagoas',
    name: 'Três Lagoas',
    uf: 'MS'
  },
  {
    value: 'Varzea Grande',
    name: 'Várzea Grande',
    uf: 'MT'
  },
  {
    value: 'Angelica',
    name: 'Angélica',
    uf: 'MS'
  },
  {
    value: 'Aral Moreira',
    name: 'Aral Moreira',
    uf: 'MS'
  },
  {
    value: 'Eldorado',
    name: 'Eldorado',
    uf: 'MS'
  },
  {
    value: 'Deodapolis',
    name: 'Deodápolis',
    uf: 'MS'
  },
  {
    value: "Mirassol d'Oeste",
    name: "Mirassol d'Oeste",
    uf: 'MT'
  },
  {
    value: 'Mundo Novo',
    name: 'Mundo Novo',
    uf: 'MS'
  },
  {
    value: 'Pedra Preta',
    name: 'Pedra Preta',
    uf: 'MT'
  },
  {
    value: 'Sao Felix do Araguaia',
    name: 'São Félix do Araguaia',
    uf: 'MT'
  },
  {
    value: 'Tangara da Serra',
    name: 'Tangará da Serra',
    uf: 'MT'
  },
  {
    value: 'Vicentina',
    name: 'Vicentina',
    uf: 'MS'
  },
  {
    value: 'Juscimeira',
    name: 'Juscimeira',
    uf: 'MT'
  },
  {
    value: 'Agua Boa',
    name: 'Água Boa',
    uf: 'MT'
  },
  {
    value: 'Canarana',
    name: 'Canarana',
    uf: 'MT'
  },
  {
    value: 'Nova Xavantina',
    name: 'Nova Xavantina',
    uf: 'MT'
  },
  {
    value: 'Santa Terezinha',
    name: 'Santa Terezinha',
    uf: 'MT'
  },
  {
    value: 'Sao Jose do Rio Claro',
    name: 'São José do Rio Claro',
    uf: 'MT'
  },
  {
    value: 'Abadiania',
    name: 'Abadiânia',
    uf: 'GO'
  },
  {
    value: 'Agua Limpa',
    name: 'Água Limpa',
    uf: 'GO'
  },
  {
    value: 'Alexania',
    name: 'Alexânia',
    uf: 'GO'
  },
  {
    value: 'Almas',
    name: 'Almas',
    uf: 'TO'
  },
  {
    value: 'Aloandia',
    name: 'Aloândia',
    uf: 'GO'
  },
  {
    value: 'Alto Paraiso de Goias',
    name: 'Alto Paraíso de Goiás',
    uf: 'GO'
  },
  {
    value: 'Alvorada',
    name: 'Alvorada',
    uf: 'TO'
  },
  {
    value: 'Alvorada do Norte',
    name: 'Alvorada do Norte',
    uf: 'GO'
  },
  {
    value: 'Amorinopolis',
    name: 'Amorinópolis',
    uf: 'GO'
  },
  {
    value: 'Ananas',
    name: 'Ananás',
    uf: 'TO'
  },
  {
    value: 'Anapolis',
    name: 'Anápolis',
    uf: 'GO'
  },
  {
    value: 'Anhanguera',
    name: 'Anhanguera',
    uf: 'GO'
  },
  {
    value: 'Anicuns',
    name: 'Anicuns',
    uf: 'GO'
  },
  {
    value: 'Aparecida de Goiania',
    name: 'Aparecida de Goiânia',
    uf: 'GO'
  },
  {
    value: 'Apore',
    name: 'Aporé',
    uf: 'GO'
  },
  {
    value: 'Aracu',
    name: 'Araçu',
    uf: 'GO'
  },
  {
    value: 'Aragarcas',
    name: 'Aragarças',
    uf: 'GO'
  },
  {
    value: 'Aragoiania',
    name: 'Aragoiânia',
    uf: 'GO'
  },
  {
    value: 'Araguacema',
    name: 'Araguacema',
    uf: 'TO'
  },
  {
    value: 'Araguacu',
    name: 'Araguaçu',
    uf: 'TO'
  },
  {
    value: 'Araguaina',
    name: 'Araguaína',
    uf: 'TO'
  },
  {
    value: 'Araguatins',
    name: 'Araguatins',
    uf: 'TO'
  },
  {
    value: 'Arapoema',
    name: 'Arapoema',
    uf: 'TO'
  },
  {
    value: 'Arraias',
    name: 'Arraias',
    uf: 'TO'
  },
  {
    value: 'Aruana',
    name: 'Aruanã',
    uf: 'GO'
  },
  {
    value: 'Aurilandia',
    name: 'Aurilândia',
    uf: 'GO'
  },
  {
    value: 'Aurora do Tocantins',
    name: 'Aurora do Tocantins',
    uf: 'TO'
  },
  {
    value: 'Avelinopolis',
    name: 'Avelinópolis',
    uf: 'GO'
  },
  {
    value: 'Axixa do Tocantins',
    name: 'Axixá do Tocantins',
    uf: 'TO'
  },
  {
    value: 'Babaculandia',
    name: 'Babaçulândia',
    uf: 'TO'
  },
  {
    value: 'Baliza',
    name: 'Baliza',
    uf: 'GO'
  },
  {
    value: 'Barro Alto',
    name: 'Barro Alto',
    uf: 'GO'
  },
  {
    value: 'Bela Vista de Goias',
    name: 'Bela Vista de Goiás',
    uf: 'GO'
  },
  {
    value: 'Bom Jardim de Goias',
    name: 'Bom Jardim de Goiás',
    uf: 'GO'
  },
  {
    value: 'Bom Jesus de Goias',
    name: 'Bom Jesus de Goiás',
    uf: 'GO'
  },
  {
    value: 'Brazabrantes',
    name: 'Brazabrantes',
    uf: 'GO'
  },
  {
    value: 'Brejinho de Nazare',
    name: 'Brejinho de Nazaré',
    uf: 'TO'
  },
  {
    value: 'Britania',
    name: 'Britânia',
    uf: 'GO'
  },
  {
    value: 'Buriti Alegre',
    name: 'Buriti Alegre',
    uf: 'GO'
  },
  {
    value: 'Cabeceiras',
    name: 'Cabeceiras',
    uf: 'GO'
  },
  {
    value: 'Cachoeira Alta',
    name: 'Cachoeira Alta',
    uf: 'GO'
  },
  {
    value: 'Cachoeira de Goias',
    name: 'Cachoeira de Goiás',
    uf: 'GO'
  },
  {
    value: 'Cacu',
    name: 'Caçu',
    uf: 'GO'
  },
  {
    value: 'Caiaponia',
    name: 'Caiapônia',
    uf: 'GO'
  },
  {
    value: 'Caldas Novas',
    name: 'Caldas Novas',
    uf: 'GO'
  },
  {
    value: 'Campestre de Goias',
    name: 'Campestre de Goiás',
    uf: 'GO'
  },
  {
    value: 'Campinorte',
    name: 'Campinorte',
    uf: 'GO'
  },
  {
    value: 'Campo Alegre de Goias',
    name: 'Campo Alegre de Goiás',
    uf: 'GO'
  },
  {
    value: 'Campos Belos',
    name: 'Campos Belos',
    uf: 'GO'
  },
  {
    value: 'Carmo do Rio Verde',
    name: 'Carmo do Rio Verde',
    uf: 'GO'
  },
  {
    value: 'Catalao',
    name: 'Catalão',
    uf: 'GO'
  },
  {
    value: 'Caturai',
    name: 'Caturaí',
    uf: 'GO'
  },
  {
    value: 'Cavalcante',
    name: 'Cavalcante',
    uf: 'GO'
  },
  {
    value: 'Ceres',
    name: 'Ceres',
    uf: 'GO'
  },
  {
    value: 'Divinopolis de Goias',
    name: 'Divinópolis de Goiás',
    uf: 'GO'
  },
  {
    value: 'Colinas do Tocantins',
    name: 'Colinas do Tocantins',
    uf: 'TO'
  },
  {
    value: 'Conceicao do Tocantins',
    name: 'Conceição do Tocantins',
    uf: 'TO'
  },
  {
    value: 'Corrego do Ouro',
    name: 'Córrego do Ouro',
    uf: 'GO'
  },
  {
    value: 'Corumba de Goias',
    name: 'Corumbá de Goiás',
    uf: 'GO'
  },
  {
    value: 'Corumbaiba',
    name: 'Corumbaíba',
    uf: 'GO'
  },
  {
    value: 'Couto Magalhaes',
    name: 'Couto Magalhães',
    uf: 'TO'
  },
  {
    value: 'Cristalandia',
    name: 'Cristalândia',
    uf: 'TO'
  },
  {
    value: 'Cristalina',
    name: 'Cristalina',
    uf: 'GO'
  },
  {
    value: 'Cristianopolis',
    name: 'Cristianópolis',
    uf: 'GO'
  },
  {
    value: 'Crixas',
    name: 'Crixás',
    uf: 'GO'
  },
  {
    value: 'Crominia',
    name: 'Cromínia',
    uf: 'GO'
  },
  {
    value: 'Cumari',
    name: 'Cumari',
    uf: 'GO'
  },
  {
    value: 'Damianopolis',
    name: 'Damianópolis',
    uf: 'GO'
  },
  {
    value: 'Damolandia',
    name: 'Damolândia',
    uf: 'GO'
  },
  {
    value: 'Davinopolis',
    name: 'Davinópolis',
    uf: 'GO'
  },
  {
    value: 'Dianopolis',
    name: 'Dianópolis',
    uf: 'TO'
  },
  {
    value: 'Diorama',
    name: 'Diorama',
    uf: 'GO'
  },
  {
    value: 'Dois Irmaos do Tocantins',
    name: 'Dois Irmãos do Tocantins',
    uf: 'TO'
  },
  {
    value: 'Duere',
    name: 'Dueré',
    uf: 'TO'
  },
  {
    value: 'Edeia',
    name: 'Edéia',
    uf: 'GO'
  },
  {
    value: 'Estrela do Norte',
    name: 'Estrela do Norte',
    uf: 'GO'
  },
  {
    value: 'Fazenda Nova',
    name: 'Fazenda Nova',
    uf: 'GO'
  },
  {
    value: 'Filadelfia',
    name: 'Filadélfia',
    uf: 'TO'
  },
  {
    value: 'Firminopolis',
    name: 'Firminópolis',
    uf: 'GO'
  },
  {
    value: 'Rio Verde',
    name: 'Rio Verde',
    uf: 'GO'
  },
  {
    value: 'Rubiataba',
    name: 'Rubiataba',
    uf: 'GO'
  },
  {
    value: 'Sanclerlandia',
    name: 'Sanclerlândia',
    uf: 'GO'
  },
  {
    value: 'Santa Barbara de Goias',
    name: 'Santa Bárbara de Goiás',
    uf: 'GO'
  },
  {
    value: 'Santa Cruz de Goias',
    name: 'Santa Cruz de Goiás',
    uf: 'GO'
  },
  {
    value: 'Santa Helena de Goias',
    name: 'Santa Helena de Goiás',
    uf: 'GO'
  },
  {
    value: 'Santa Rita do Araguaia',
    name: 'Santa Rita do Araguaia',
    uf: 'GO'
  },
  {
    value: 'Santa Rosa de Goias',
    name: 'Santa Rosa de Goiás',
    uf: 'GO'
  },
  {
    value: 'Santa Tereza de Goias',
    name: 'Santa Tereza de Goiás',
    uf: 'GO'
  },
  {
    value: 'Santa Terezinha de Goias',
    name: 'Santa Terezinha de Goiás',
    uf: 'GO'
  },
  {
    value: 'Sao Domingos',
    name: 'São Domingos',
    uf: 'GO'
  },
  {
    value: 'Sao Francisco de Goias',
    name: 'São Francisco de Goiás',
    uf: 'GO'
  },
  {
    value: 'Planaltina',
    name: 'Planaltina',
    uf: 'GO'
  },
  {
    value: "Sao Joao d'Alianca",
    name: "São João d'Aliança",
    uf: 'GO'
  },
  {
    value: 'Sao Luis de Montes Belos',
    name: 'São Luís de Montes Belos',
    uf: 'GO'
  },
  {
    value: 'Sao Miguel do Araguaia',
    name: 'São Miguel do Araguaia',
    uf: 'GO'
  },
  {
    value: 'Sao Sebastiao do Tocantins',
    name: 'São Sebastião do Tocantins',
    uf: 'TO'
  },
  {
    value: 'Sao Simao',
    name: 'São Simão',
    uf: 'GO'
  },
  {
    value: 'Serranopolis',
    name: 'Serranópolis',
    uf: 'GO'
  },
  {
    value: 'Silvania',
    name: 'Silvânia',
    uf: 'GO'
  },
  {
    value: "Sitio d'Abadia",
    name: "Sítio d'Abadia",
    uf: 'GO'
  },
  {
    value: 'Sitio Novo do Tocantins',
    name: 'Sítio Novo do Tocantins',
    uf: 'TO'
  },
  {
    value: 'Taguatinga',
    name: 'Taguatinga',
    uf: 'TO'
  },
  {
    value: 'Taquaral de Goias',
    name: 'Taquaral de Goiás',
    uf: 'GO'
  },
  {
    value: 'Tocantinia',
    name: 'Tocantínia',
    uf: 'TO'
  },
  {
    value: 'Tocantinopolis',
    name: 'Tocantinópolis',
    uf: 'TO'
  },
  {
    value: 'Tres Ranchos',
    name: 'Três Ranchos',
    uf: 'GO'
  },
  {
    value: 'Trindade',
    name: 'Trindade',
    uf: 'GO'
  },
  {
    value: 'Guarai',
    name: 'Guaraí',
    uf: 'TO'
  },
  {
    value: 'Presidente Kennedy',
    name: 'Presidente Kennedy',
    uf: 'TO'
  },
  {
    value: 'Turvania',
    name: 'Turvânia',
    uf: 'GO'
  },
  {
    value: 'Uruacu',
    name: 'Uruaçu',
    uf: 'GO'
  },
  {
    value: 'Uruana',
    name: 'Uruana',
    uf: 'GO'
  },
  {
    value: 'Urutai',
    name: 'Urutaí',
    uf: 'GO'
  },
  {
    value: 'Varjao',
    name: 'Varjão',
    uf: 'GO'
  },
  {
    value: 'Vianopolis',
    name: 'Vianópolis',
    uf: 'GO'
  },
  {
    value: 'Xambioa',
    name: 'Xambioá',
    uf: 'TO'
  },
  {
    value: 'Acreuna',
    name: 'Acreúna',
    uf: 'GO'
  },
  {
    value: 'Minacu',
    name: 'Minaçu',
    uf: 'GO'
  },
  {
    value: 'Palmeiropolis',
    name: 'Palmeirópolis',
    uf: 'TO'
  },
  {
    value: 'Mundo Novo',
    name: 'Mundo Novo',
    uf: 'GO'
  },
  {
    value: 'Nova Crixas',
    name: 'Nova Crixás',
    uf: 'GO'
  },
  {
    value: 'Nova Gloria',
    name: 'Nova Glória',
    uf: 'GO'
  },
  {
    value: 'Vicentinopolis',
    name: 'Vicentinópolis',
    uf: 'GO'
  },
  {
    value: 'Silvanopolis',
    name: 'Silvanópolis',
    uf: 'TO'
  },
  {
    value: 'Americano do Brasil',
    name: 'Americano do Brasil',
    uf: 'GO'
  },
  {
    value: 'Nova Olinda',
    name: 'Nova Olinda',
    uf: 'TO'
  },
  {
    value: 'Wanderlandia',
    name: 'Wanderlândia',
    uf: 'TO'
  },
  {
    value: 'Figueiropolis',
    name: 'Figueirópolis',
    uf: 'TO'
  },
  {
    value: 'Araguapaz',
    name: 'Araguapaz',
    uf: 'GO'
  },
  {
    value: 'Arenopolis',
    name: 'Arenópolis',
    uf: 'GO'
  },
  {
    value: 'Cachoeira Dourada',
    name: 'Cachoeira Dourada',
    uf: 'GO'
  },
  {
    value: 'Doverlandia',
    name: 'Doverlândia',
    uf: 'GO'
  },
  {
    value: 'Santo Antonio do Descoberto',
    name: 'Santo Antônio do Descoberto',
    uf: 'GO'
  },
  {
    value: 'Rio Sono',
    name: 'Rio Sono',
    uf: 'TO'
  },
  {
    value: 'Indiara',
    name: 'Indiara',
    uf: 'GO'
  },
  {
    value: 'Fatima',
    name: 'Fátima',
    uf: 'TO'
  },
  {
    value: 'Augustinopolis',
    name: 'Augustinópolis',
    uf: 'TO'
  },
  {
    value: 'Campinacu',
    name: 'Campinaçu',
    uf: 'GO'
  },
  {
    value: 'Santa Isabel',
    name: 'Santa Isabel',
    uf: 'GO'
  },
  {
    value: 'Sao Valerio',
    name: 'São Valério',
    uf: 'TO'
  },
  {
    value: 'Barrolandia',
    name: 'Barrolândia',
    uf: 'TO'
  },
  {
    value: 'Bernardo Sayao',
    name: 'Bernardo Sayão',
    uf: 'TO'
  },
  {
    value: 'Combinado',
    name: 'Combinado',
    uf: 'TO'
  },
  {
    value: 'Goianorte',
    name: 'Goianorte',
    uf: 'TO'
  },
  {
    value: 'Brasilia',
    name: 'Brasília',
    uf: 'DF'
  },
  {
    value: 'Novo Alegre',
    name: 'Novo Alegre',
    uf: 'TO'
  },
  {
    value: 'Pequizeiro',
    name: 'Pequizeiro',
    uf: 'TO'
  },
  {
    value: 'Marianopolis do Tocantins',
    name: 'Marianópolis do Tocantins',
    uf: 'TO'
  },
  {
    value: 'Aparecida do Rio Negro',
    name: 'Aparecida do Rio Negro',
    uf: 'TO'
  },
  {
    value: 'Buriti do Tocantins',
    name: 'Buriti do Tocantins',
    uf: 'TO'
  },
  {
    value: 'Caseara',
    name: 'Caseara',
    uf: 'TO'
  },
  {
    value: 'Divinopolis do Tocantins',
    name: 'Divinópolis do Tocantins',
    uf: 'TO'
  },
  {
    value: 'Nova Rosalandia',
    name: 'Nova Rosalândia',
    uf: 'TO'
  },
  {
    value: 'Porto Alegre do Tocantins',
    name: 'Porto Alegre do Tocantins',
    uf: 'TO'
  },
  {
    value: 'Praia Norte',
    name: 'Praia Norte',
    uf: 'TO'
  },
  {
    value: 'Sampaio',
    name: 'Sampaio',
    uf: 'TO'
  },
  {
    value: 'Santa Rosa do Tocantins',
    name: 'Santa Rosa do Tocantins',
    uf: 'TO'
  },
  {
    value: 'Santa Tereza do Tocantins',
    name: 'Santa Tereza do Tocantins',
    uf: 'TO'
  },
  {
    value: 'Palmas',
    name: 'Palmas',
    uf: 'TO'
  },
  {
    value: 'Novo Planalto',
    name: 'Novo Planalto',
    uf: 'GO'
  },
  {
    value: 'Palestina de Goias',
    name: 'Palestina de Goiás',
    uf: 'GO'
  },
  {
    value: 'Paranhos',
    name: 'Paranhos',
    uf: 'MS'
  },
  {
    value: 'Ribeirao Cascalheira',
    name: 'Ribeirão Cascalheira',
    uf: 'MT'
  },
  {
    value: 'Santa Fe de Goias',
    name: 'Santa Fé de Goiás',
    uf: 'GO'
  },
  {
    value: 'Santa Rita do Pardo',
    name: 'Santa Rita do Pardo',
    uf: 'MS'
  },
  {
    value: 'Sao Joao da Parauna',
    name: 'São João da Paraúna',
    uf: 'GO'
  },
  {
    value: 'Sao Luiz do Norte',
    name: 'São Luiz do Norte',
    uf: 'GO'
  },
  {
    value: 'Sao Miguel do Passa Quatro',
    name: 'São Miguel do Passa Quatro',
    uf: 'GO'
  },
  {
    value: 'Senador Canedo',
    name: 'Senador Canedo',
    uf: 'GO'
  },
  {
    value: 'Simolandia',
    name: 'Simolândia',
    uf: 'GO'
  },
  {
    value: 'Sonora',
    name: 'Sonora',
    uf: 'MS'
  },
  {
    value: 'Teresina de Goias',
    name: 'Teresina de Goiás',
    uf: 'GO'
  },
  {
    value: 'Trombas',
    name: 'Trombas',
    uf: 'GO'
  },
  {
    value: 'Tapurah',
    name: 'Tapurah',
    uf: 'MT'
  },
  {
    value: 'Turvelandia',
    name: 'Turvelândia',
    uf: 'GO'
  },
  {
    value: 'Alagoinha do Piaui',
    name: 'Alagoinha do Piauí',
    uf: 'PI'
  },
  {
    value: 'Adelandia',
    name: 'Adelândia',
    uf: 'GO'
  },
  {
    value: 'Agua Fria de Goias',
    name: 'Água Fria de Goiás',
    uf: 'GO'
  },
  {
    value: 'Apiacas',
    name: 'Apiacás',
    uf: 'MT'
  },
  {
    value: 'Bonfinopolis',
    name: 'Bonfinópolis',
    uf: 'GO'
  },
  {
    value: 'Campo Novo do Parecis',
    name: 'Campo Novo do Parecis',
    uf: 'MT'
  },
  {
    value: 'Campo Verde',
    name: 'Campo Verde',
    uf: 'MT'
  },
  {
    value: 'Campos Verdes',
    name: 'Campos Verdes',
    uf: 'GO'
  },
  {
    value: 'Castanheira',
    name: 'Castanheira',
    uf: 'MT'
  },
  {
    value: 'Cezarina',
    name: 'Cezarina',
    uf: 'GO'
  },
  {
    value: 'Chapadao do Sul',
    name: 'Chapadão do Sul',
    uf: 'MS'
  },
  {
    value: 'Claudia',
    name: 'Cláudia',
    uf: 'MT'
  },
  {
    value: 'Colinas do Sul',
    name: 'Colinas do Sul',
    uf: 'GO'
  },
  {
    value: 'Dois Irmaos do Buriti',
    name: 'Dois Irmãos do Buriti',
    uf: 'MS'
  },
  {
    value: 'Edealina',
    name: 'Edealina',
    uf: 'GO'
  },
  {
    value: 'Faina',
    name: 'Faina',
    uf: 'GO'
  },
  {
    value: 'Gouvelandia',
    name: 'Gouvelândia',
    uf: 'GO'
  },
  {
    value: 'Bodoquena',
    name: 'Bodoquena',
    uf: 'MS'
  },
  {
    value: 'Costa Rica',
    name: 'Costa Rica',
    uf: 'MS'
  },
  {
    value: 'Douradina',
    name: 'Douradina',
    uf: 'MS'
  },
  {
    value: 'Itaquirai',
    name: 'Itaquiraí',
    uf: 'MS'
  },
  {
    value: 'Sao Gabriel do Oeste',
    name: 'São Gabriel do Oeste',
    uf: 'MS'
  },
  {
    value: 'Selviria',
    name: 'Selvíria',
    uf: 'MS'
  },
  {
    value: 'Sete Quedas',
    name: 'Sete Quedas',
    uf: 'MS'
  },
  {
    value: 'Tacuru',
    name: 'Tacuru',
    uf: 'MS'
  },
  {
    value: 'Taquarussu',
    name: 'Taquarussu',
    uf: 'MS'
  },
  {
    value: 'Juara',
    name: 'Juara',
    uf: 'MT'
  },
  {
    value: 'Teutonia',
    name: 'Teutônia',
    uf: 'RS'
  },
  {
    value: 'Bom Principio',
    name: 'Bom Princípio',
    uf: 'RS'
  },
  {
    value: 'Parobe',
    name: 'Parobé',
    uf: 'RS'
  },
  {
    value: 'Fortaleza dos Valos',
    name: 'Fortaleza dos Valos',
    uf: 'RS'
  },
  {
    value: 'Joia',
    name: 'Jóia',
    uf: 'RS'
  },
  {
    value: 'Juina',
    name: 'Juína',
    uf: 'MT'
  },
  {
    value: 'Denise',
    name: 'Denise',
    uf: 'MT'
  },
  {
    value: 'Iranduba',
    name: 'Iranduba',
    uf: 'AM'
  },
  {
    value: 'Itamarati',
    name: 'Itamarati',
    uf: 'AM'
  },
  {
    value: 'Manaquiri',
    name: 'Manaquiri',
    uf: 'AM'
  },
  {
    value: 'Presidente Figueiredo',
    name: 'Presidente Figueiredo',
    uf: 'AM'
  },
  {
    value: 'Rio Preto da Eva',
    name: 'Rio Preto da Eva',
    uf: 'AM'
  },
  {
    value: 'Sao Sebastiao do Uatuma',
    name: 'São Sebastião do Uatumã',
    uf: 'AM'
  },
  {
    value: 'Tabatinga',
    name: 'Tabatinga',
    uf: 'AM'
  },
  {
    value: 'Uarini',
    name: 'Uarini',
    uf: 'AM'
  },
  {
    value: 'Tonantins',
    name: 'Tonantins',
    uf: 'AM'
  },
  {
    value: 'Quixelo',
    name: 'Quixelô',
    uf: 'CE'
  },
  {
    value: 'Umirim',
    name: 'Umirim',
    uf: 'CE'
  },
  {
    value: 'Varjota',
    name: 'Varjota',
    uf: 'CE'
  },
  {
    value: 'Jaborandi',
    name: 'Jaborandi',
    uf: 'BA'
  },
  {
    value: 'Jangada',
    name: 'Jangada',
    uf: 'MT'
  },
  {
    value: 'Campinapolis',
    name: 'Campinápolis',
    uf: 'MT'
  },
  {
    value: 'Cocalinho',
    name: 'Cocalinho',
    uf: 'MT'
  },
  {
    value: 'Novo Sao Joaquim',
    name: 'Novo São Joaquim',
    uf: 'MT'
  },
  {
    value: 'Araguaiana',
    name: 'Araguaiana',
    uf: 'MT'
  },
  {
    value: 'Primavera do Leste',
    name: 'Primavera do Leste',
    uf: 'MT'
  },
  {
    value: 'Brasnorte',
    name: 'Brasnorte',
    uf: 'MT'
  },
  {
    value: 'Porto Esperidiao',
    name: 'Porto Esperidião',
    uf: 'MT'
  },
  {
    value: 'Indiavai',
    name: 'Indiavaí',
    uf: 'MT'
  },
  {
    value: 'Reserva do Cabacal',
    name: 'Reserva do Cabaçal',
    uf: 'MT'
  },
  {
    value: "Figueiropolis D'Oeste",
    name: "Figueirópolis D'Oeste",
    uf: 'MT'
  },
  {
    value: 'Comodoro',
    name: 'Comodoro',
    uf: 'MT'
  },
  {
    value: 'Paranaita',
    name: 'Paranaíta',
    uf: 'MT'
  },
  {
    value: 'Guaranta do Norte',
    name: 'Guarantã do Norte',
    uf: 'MT'
  },
  {
    value: 'Nova Canaa do Norte',
    name: 'Nova Canaã do Norte',
    uf: 'MT'
  },
  {
    value: 'Peixoto de Azevedo',
    name: 'Peixoto de Azevedo',
    uf: 'MT'
  },
  {
    value: 'Nova Olimpia',
    name: 'Nova Olímpia',
    uf: 'MT'
  },
  {
    value: 'Porto Alegre do Norte',
    name: 'Porto Alegre do Norte',
    uf: 'MT'
  },
  {
    value: 'Vila Rica',
    name: 'Vila Rica',
    uf: 'MT'
  },
  {
    value: 'Marcelandia',
    name: 'Marcelândia',
    uf: 'MT'
  },
  {
    value: 'Itauba',
    name: 'Itaúba',
    uf: 'MT'
  },
  {
    value: 'Novo Horizonte do Norte',
    name: 'Novo Horizonte do Norte',
    uf: 'MT'
  },
  {
    value: 'Vera',
    name: 'Vera',
    uf: 'MT'
  },
  {
    value: 'Sorriso',
    name: 'Sorriso',
    uf: 'MT'
  },
  {
    value: 'Terra Nova do Norte',
    name: 'Terra Nova do Norte',
    uf: 'MT'
  },
  {
    value: 'Alto Taquari',
    name: 'Alto Taquari',
    uf: 'MT'
  },
  {
    value: 'Nova Tebas',
    name: 'Nova Tebas',
    uf: 'PR'
  },
  {
    value: "Diamante D'Oeste",
    name: "Diamante D'Oeste",
    uf: 'PR'
  },
  {
    value: 'Quiterianopolis',
    name: 'Quiterianópolis',
    uf: 'CE'
  },
  {
    value: 'Itaguari',
    name: 'Itaguari',
    uf: 'GO'
  },
  {
    value: 'Juruena',
    name: 'Juruena',
    uf: 'MT'
  },
  {
    value: 'Juti',
    name: 'Juti',
    uf: 'MS'
  },
  {
    value: 'Lucas do Rio Verde',
    name: 'Lucas do Rio Verde',
    uf: 'MT'
  },
  {
    value: 'Matrincha',
    name: 'Matrinchã',
    uf: 'GO'
  },
  {
    value: 'Matupa',
    name: 'Matupá',
    uf: 'MT'
  },
  {
    value: 'Mimoso de Goias',
    name: 'Mimoso de Goiás',
    uf: 'GO'
  },
  {
    value: 'Montividiu',
    name: 'Montividiu',
    uf: 'GO'
  },
  {
    value: 'Morro Agudo de Goias',
    name: 'Morro Agudo de Goiás',
    uf: 'GO'
  },
  {
    value: 'Nova Mutum',
    name: 'Nova Mutum',
    uf: 'MT'
  },
  {
    value: 'Abdon Batista',
    name: 'Abdon Batista',
    uf: 'SC'
  },
  {
    value: 'Apiuna',
    name: 'Apiúna',
    uf: 'SC'
  },
  {
    value: 'Celso Ramos',
    name: 'Celso Ramos',
    uf: 'SC'
  },
  {
    value: 'Doutor Pedrinho',
    name: 'Doutor Pedrinho',
    uf: 'SC'
  },
  {
    value: 'Godoy Moreira',
    name: 'Godoy Moreira',
    uf: 'PR'
  },
  {
    value: 'Ibema',
    name: 'Ibema',
    uf: 'PR'
  },
  {
    value: 'Ipora do Oeste',
    name: 'Iporã do Oeste',
    uf: 'SC'
  },
  {
    value: 'Iraceminha',
    name: 'Iraceminha',
    uf: 'SC'
  },
  {
    value: 'Ivate',
    name: 'Ivaté',
    uf: 'PR'
  },
  {
    value: 'Jose Boiteux',
    name: 'José Boiteux',
    uf: 'SC'
  },
  {
    value: 'Lindoeste',
    name: 'Lindoeste',
    uf: 'PR'
  },
  {
    value: 'Lindoia do Sul',
    name: 'Lindóia do Sul',
    uf: 'SC'
  },
  {
    value: 'Marema',
    name: 'Marema',
    uf: 'SC'
  },
  {
    value: 'Ouro Verde do Oeste',
    name: 'Ouro Verde do Oeste',
    uf: 'PR'
  },
  {
    value: 'Santa Rosa do Sul',
    name: 'Santa Rosa do Sul',
    uf: 'SC'
  },
  {
    value: 'Santa Tereza do Oeste',
    name: 'Santa Tereza do Oeste',
    uf: 'PR'
  },
  {
    value: 'Timbo Grande',
    name: 'Timbó Grande',
    uf: 'SC'
  },
  {
    value: 'Uniao do Oeste',
    name: 'União do Oeste',
    uf: 'SC'
  },
  {
    value: 'Urupema',
    name: 'Urupema',
    uf: 'SC'
  },
  {
    value: 'Vitor Meireles',
    name: 'Vitor Meireles',
    uf: 'SC'
  },
  {
    value: 'Bom Sucesso do Sul',
    name: 'Bom Sucesso do Sul',
    uf: 'PR'
  },
  {
    value: 'Honorio Serpa',
    name: 'Honório Serpa',
    uf: 'PR'
  },
  {
    value: 'Fazenda Rio Grande',
    name: 'Fazenda Rio Grande',
    uf: 'PR'
  },
  {
    value: 'Itapoa',
    name: 'Itapoá',
    uf: 'SC'
  },
  {
    value: 'Serra Alta',
    name: 'Serra Alta',
    uf: 'SC'
  },
  {
    value: 'Tunapolis',
    name: 'Tunápolis',
    uf: 'SC'
  },
  {
    value: 'Guarinos',
    name: 'Guarinos',
    uf: 'GO'
  },
  {
    value: 'Rio Quente',
    name: 'Rio Quente',
    uf: 'GO'
  }
]

export const naturezasJuridicas = [
  { value: '1015', name: 'Órgão Público do Poder Executivo Federal' },
  { value: '1023', name: 'Órgão Público do Poder Executivo Estadual ou do Distrito Federal' },
  { value: '1031', name: 'Órgão Público do Poder Executivo Municipal' },
  { value: '1040', name: 'Órgão Público do Poder Legislativo Federal' },
  { value: '1058', name: 'Órgão Público do Poder Legislativo Estadual ou do Distrito Federal' },
  { value: '1066', name: 'Órgão Público do Poder Legislativo Municipal' },
  { value: '1074', name: 'Órgão Público do Poder Judiciário Federal' },
  { value: '1082', name: 'Órgão Público do Poder Judiciário Estadual' },
  { value: '1104', name: 'Autarquia Federal' },
  { value: '1112', name: 'Autarquia Estadual ou do Distrito Federal' },
  { value: '1120', name: 'Autarquia Municipal' },
  { value: '1139', name: 'Fundação Pública de Direito Público Federal' },
  { value: '1147', name: 'Fundação Pública de Direito Público Estadual ou do Distrito Federal' },
  { value: '1155', name: 'Fundação Pública de Direito Público Municipal' },
  { value: '1163', name: 'Órgão Público Autônomo Federal' },
  { value: '1171', name: 'Órgão Público Autônomo Estadual ou do Distrito Federal' },
  { value: '1180', name: 'Órgão Público Autônomo Municipal' },
  { value: '1198', name: 'Comissão Polinacional' },
  { value: '1210', name: 'Consórcio Público de Direito Público (Associação Pública)' },
  { value: '1228', name: 'Consórcio Público de Direito Privado' },
  { value: '1236', name: 'Estado ou Distrito Federal' },
  { value: '1244', name: 'Município' },
  { value: '1252', name: 'Fundação Pública de Direito Privado Federal' },
  { value: '1260', name: 'Fundação Pública de Direito Privado Estadual ou do Distrito Federal' },
  { value: '1279', name: 'Fundação Pública de Direito Privado Municipal' },
  { value: '1287', name: 'Fundo Público da Administração Indireta Federal' },
  { value: '1295', name: 'Fundo Público da Administração Indireta Estadual ou do Distrito Federal' },
  { value: '1309', name: 'Fundo Público da Administração Indireta Municipal' },
  { value: '1317', name: 'Fundo Público da Administração Direta Federal' },
  { value: '1325', name: 'Fundo Público da Administração Direta Estadual ou do Distrito Federal' },
  { value: '1333', name: 'Fundo Público da Administração Direta Municipal' },
  { value: '1341', name: 'União' },
  { value: '2011', name: 'Empresa Pública' },
  { value: '2038', name: 'Sociedade de Economia Mista' },
  { value: '2046', name: 'Sociedade Anônima Aberta' },
  { value: '2054', name: 'Sociedade Anônima Fechada' },
  { value: '2062', name: 'Sociedade Empresária Limitada' },
  { value: '2070', name: 'Sociedade Empresária em Nome Coletivo' },
  { value: '2089', name: 'Sociedade Empresária em Comandita Simples' },
  { value: '2097', name: 'Sociedade Empresária em Comandita por Ações' },
  { value: '2100', name: 'Sociedade Mercantil de Capital e Indústria' },
  { value: '2127', name: 'Sociedade em Conta de Participação' },
  { value: '2135', name: 'Empresário (Individual)' },
  { value: '2143', name: 'Cooperativa' },
  { value: '2151', name: 'Consórcio de Sociedades' },
  { value: '2160', name: 'Grupo de Sociedades' },
  { value: '2178', name: 'Estabelecimento, no Brasil, de Sociedade Estrangeira' },
  { value: '2216', name: 'Empresa Domiciliada no Exterior' },
  { value: '2224', name: 'Clube/Fundo de Investimento' },
  { value: '2232', name: 'Sociedade Simples Pura' },
  { value: '2240', name: 'Sociedade Simples Limitada' },
  { value: '2259', name: 'Sociedade Simples em Nome Coletivo' },
  { value: '2267', name: 'Sociedade Simples em Comandita Simples' },
  { value: '2275', name: 'Empresa Binacional' },
  { value: '2283', name: 'Consórcio de Empregadores' },
  { value: '2291', name: 'Consórcio Simples' },
  { value: '2305', name: 'Empresa Individual de Responsabilidade Limitada (de Natureza Empresária)' },
  { value: '2313', name: 'Empresa Individual de Responsabilidade Limitada (de Natureza Simples)' },
  { value: '2321', name: 'Natureza Jurídica Inválida' },
  { value: '2330', name: 'Cooperativas de Consumo' },
  { value: '2348', name: 'Empresa Simples de Inovação' },
  { value: '3034', name: 'Serviço Notarial e Registral (Cartório)' },
  { value: '3069', name: 'Fundação Privada' },
  { value: '3077', name: 'Serviço Social Autônomo' },
  { value: '3085', name: 'Condomínio Edilício' },
  { value: '3107', name: 'Comissão de Conciliação Prévia' },
  { value: '3115', name: 'Entidade de Mediação e Arbitragem' },
  { value: '3131', name: 'Entidade Sindical' },
  { value: '3204', name: 'Estabelecimento, no Brasil, de Fundação ou Associação Estrangeiras' },
  { value: '3212', name: 'Fundação ou Associação Domiciliada no Exterior' },
  { value: '3220', name: 'Organização Religiosa' },
  { value: '3239', name: 'Comunidade Indígena' },
  { value: '3247', name: 'Fundo Privado' },
  { value: '3255', name: 'Órgão de Direção Nacional de Partido Político' },
  { value: '3263', name: 'Órgão de Direção Regional de Partido Político' },
  { value: '3271', name: 'Órgão de Direção Local de Partido Político' },
  { value: '3280', name: 'Comitê Financeiro de Partido Político' },
  { value: '3298', name: 'Frente Plebiscitária ou Referendária' },
  { value: '3301', name: 'Organização Social (OS)' },
  { value: '3328', name: 'Plano de Benefícios de Previdência Complementar Fechada' },
  { value: '3999', name: 'Associação Privada' },
  { value: '4014', name: 'Empresa Individual Imobiliária' },
  { value: '4090', name: 'Candidato a Cargo Político Eletivo' },
  { value: '4120', name: 'Produtor Rural (Pessoa Física)' },
  { value: '5010', name: 'Organização Internacional' },
  { value: '5029', name: 'Representação Diplomática Estrangeira' },
  { value: '5037', name: 'Outras Instituições Extraterritoriais' },
  { value: '8885', name: 'Natureza Jurídica Não Informada' }
]

export const estadosRegioes = [
  {
    label: 'Norte',
    items: [
      { label: 'Amazonas', value: 'Amazonas' },
      { label: 'Acre', value: 'Acre' },
      { label: 'Rondônia', value: 'Rondonia' },
      { label: 'Roraima', value: 'Roraima' },
      { label: 'Amapá', value: 'Amapa' },
      { label: 'Pará', value: 'Para' },
      { label: 'Tocantins', value: 'Tocantins' }
    ]
  },
  {
    label: 'Nordeste',
    items: [
      { label: 'Maranhão', value: 'Maranhao' },
      { label: 'Piauí', value: 'Piaui' },
      { label: 'Rio Grande do Norte', value: 'Rio Grande do Norte' },
      { label: 'Ceará', value: 'Ceara' },
      { label: 'Paraíba', value: 'Paraíba' },
      { label: 'Bahia', value: 'Bahia' },
      { label: 'Pernambuco', value: 'Pernambuco' },
      { label: 'Alagoas', value: 'Alagoas' },
      { label: 'Sergipe', value: 'Sergipe' }
    ]
  },
  {
    label: 'Centro-oeste',
    items: [
      { label: 'Distrito Federal', value: 'Distrito Federal' },
      { label: 'Goiás', value: 'Goias' },
      { label: 'Mato Grosso', value: 'Mato Grosso' },
      { label: 'Mato Grosso do Sul', value: 'Mato Grosso do Sul' }
    ]
  },
  {
    label: 'Sudeste',
    items: [
      { label: 'Minas Gerais', value: 'Minas Gerais' },
      { label: 'Espírito Santo', value: 'Espirito Santo' },
      { label: 'Rio de Janeiro', value: 'Rio de Janeiro' },
      { label: 'São Paulo', value: 'Sao Paulo' }
    ]
  },
  {
    label: 'Sul',
    items: [
      { label: 'Santa Catarina', value: 'Santa Catarina' },
      { label: 'Paraná', value: 'Parana' },
      { label: 'Rio Grande do Sul', value: 'Rio Grande do Sul' }
    ]
  }
]

export const ptBR = {
  firstDayOfWeek: 1,
  dayNames: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
  dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'],
  dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
  monthNames: [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro'
  ],
  monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
  today: 'Hoje',
  clear: 'Limpar',
  dateFormat: 'dd/mm/yy', // Formato de data
  weekHeader: 'Sem',
  chooseDate: 'Escolher data' // Corrige o erro, adicionando esta chave
}

export const cnaes = [
  { value: '0111301', name: '0111301 - Cultivo de arroz' },
  { value: '0111302', name: '0111302 - Cultivo de milho' },
  { value: '0111303', name: '0111303 - Cultivo de trigo' },
  { value: '0111399', name: '0111399 - Cultivo de outros cereais não especificados anteriormente' },
  { value: '0112101', name: '0112101 - Cultivo de algodão herbáceo' },
  { value: '0112102', name: '0112102 - Cultivo de juta' },
  {
    value: '0112199',
    name: '0112199 - Cultivo de outras fibras de lavoura temporária não especificadas anteriormente'
  },
  { value: '0113000', name: '0113000 - Cultivo de cana-de-açúcar' },
  { value: '0114800', name: '0114800 - Cultivo de fumo' },
  { value: '0115600', name: '0115600 - Cultivo de soja' },
  { value: '0116401', name: '0116401 - Cultivo de amendoim' },
  { value: '0116402', name: '0116402 - Cultivo de girassol' },
  { value: '0116403', name: '0116403 - Cultivo de mamona' },
  {
    value: '0116499',
    name: '0116499 - Cultivo de outras oleaginosas de lavoura temporária não especificadas anteriormente'
  },
  { value: '0119901', name: '0119901 - Cultivo de abacaxi' },
  { value: '0119902', name: '0119902 - Cultivo de alho' },
  { value: '0119903', name: '0119903 - Cultivo de batata-inglesa' },
  { value: '0119904', name: '0119904 - Cultivo de cebola' },
  { value: '0119905', name: '0119905 - Cultivo de feijão' },
  { value: '0119906', name: '0119906 - Cultivo de mandioca' },
  { value: '0119907', name: '0119907 - Cultivo de melão' },
  { value: '0119908', name: '0119908 - Cultivo de melancia' },
  { value: '0119909', name: '0119909 - Cultivo de tomate rasteiro' },
  {
    value: '0119999',
    name: '0119999 - Cultivo de outras plantas de lavoura temporária não especificadas anteriormente'
  },
  { value: '0121101', name: '0121101 - Horticultura' },
  { value: '0121102', name: '0121102 - Cultivo de morango' },
  { value: '0122900', name: '0122900 - Cultivo de flores e plantas ornamentais' },
  { value: '0131800', name: '0131800 - Cultivo de laranja' },
  { value: '0132600', name: '0132600 - Cultivo de uva' },
  { value: '0133401', name: '0133401 - Cultivo de açaí' },
  { value: '0133402', name: '0133402 - Cultivo de banana' },
  { value: '0133403', name: '0133403 - Cultivo de caju' },
  { value: '0133404', name: '0133404 - Cultivo de cítricos' },
  { value: '0133405', name: '0133405 - Cultivo de coco-da-baía' },
  { value: '0133406', name: '0133406 - Cultivo de guaraná' },
  { value: '0133407', name: '0133407 - Cultivo de maíz' },
  { value: '0133408', name: '0133408 - Cultivo de mamão' },
  { value: '0133409', name: '0133409 - Cultivo de maracujá' },
  { value: '0133410', name: '0133410 - Cultivo de manga' },
  { value: '0133411', name: '0133411 - Cultivo de pêssego' },
  {
    value: '0133499',
    name: '0133499 - Cultivo de frutas de lavoura permanente não especificadas anteriormente'
  },
  { value: '0134200', name: '0134200 - Cultivo de café' },
  { value: '0135100', name: '0135100 - Cultivo de cacau' },
  { value: '0139301', name: '0139301 - Cultivo de chuchu-da-índia' },
  { value: '0139302', name: '0139302 - Cultivo de erva-mate' },
  { value: '0139303', name: '0139303 - Cultivo de pimenta-do-reino' },
  { value: '0139304', name: '0139304 - Cultivo de plantas para condimento' },
  { value: '0139305', name: '0139305 - Cultivo de dendê' },
  { value: '0139306', name: '0139306 - Cultivo de seringueira' },
  {
    value: '0139399',
    name: '0139399 - Cultivo de outras plantas de lavoura permanente não especificadas anteriormente'
  },
  { value: '0141501', name: '0141501 - Produção de sementes certificadas' },
  {
    value: '0141502',
    name: '0141502 - Produção de sementes certificadas de forrageiras para formação de pasto'
  },
  { value: '0142300', name: '0142300 - Produção de mudas e outras formas de propagação vegetal' },
  { value: '0151201', name: '0151201 - Criação de bovinos para corte' },
  { value: '0151202', name: '0151202 - Criação de bovinos para leite' },
  { value: '0151203', name: '0151203 - Criação de bovinos' },
  { value: '0152101', name: '0152101 - Criação de bufalinos' },
  { value: '0152102', name: '0152102 - Criação de equinos' },
  { value: '0152103', name: '0152103 - Criação de asininos e muares' },
  { value: '0153901', name: '0153901 - Criação de caprinos' },
  { value: '0153902', name: '0153902 - Criação de ovinos' },
  { value: '0154700', name: '0154700 - Criação de sonhos' },
  { value: '0155501', name: '0155501 - Criação de frangos para corte' },
  { value: '0155502', name: '0155502 - Produção de pintos de um dia' },
  { value: '0155503', name: '0155503 - Criação de outros galináceos' },
  { value: '0155504', name: '0155504 - Criação de aves' },
  { value: '0155505', name: '0155505 - Produção de ovos' },
  { value: '0159801', name: '0159801 - Apicultura' },
  { value: '0159802', name: '0159802 - Criação de animais de estimação' },
  { value: '0159803', name: '0159803 - Criação de escargot' },
  { value: '0159804', name: '0159804 - Criação de bicho-da-seda' },
  { value: '0159899', name: '0159899 - Criação de outros animais não especificados anteriormente' },
  { value: '0161001', name: '0161001 - Serviço de pulverização e controle de pragas agrícolas' },
  { value: '0161002', name: '0161002 - Serviço de poda de árvores para lavouras' },
  { value: '0161003', name: '0161003 - Serviço de preparação de terreno' },
  { value: '0161099', name: '0161099 - Atividades de apoio à agricultura não especificadas anteriormente' },
  { value: '0162801', name: '0162801 - Serviço de inseminação artificial em animais' },
  { value: '0162802', name: '0162802 - Serviço de tosquiamento de ovinos' },
  { value: '0162803', name: '0162803 - Serviço de manejo de animais' },
  { value: '0162899', name: '0162899 - Atividades de apoio à pecuária não especificadas anteriormente' },
  { value: '0163600', name: '0163600 - Atividades de pós-colheita' },
  { value: '0170900', name: '0170900 - Câmara e serviços relacionados' },
  { value: '0210101', name: '0210101 - Cultivo de eucalipto' },
  { value: '0210102', name: '0210102 - Cultivo de acácia-negra' },
  { value: '0210103', name: '0210103 - Cultivo de pinus' },
  { value: '0210104', name: '0210104 - Cultivo de teca' },
  { value: '0210105', name: '0210105 - Cultivo de espécies madeireiras: acácia-negra, pinus e teca' },
  { value: '0210106', name: '0210106 - Cultivo de mudas em viveiros florestais' },
  { value: '0210107', name: '0210107 - Extração de madeira em florestas plantadas' },
  { value: '0210108', name: '0210108 - Produção de carvão vegetal - florestas plantadas' },
  { value: '0210109', name: '0210109 - Produção de casca de acácia-negra - florestas plantadas' },
  {
    value: '0210199',
    name: '0210199 - Produção de produtos não-madeireiros não especificados anteriormente em florestas plantadas'
  },
  { value: '0220901', name: '0220901 - Extração de madeira em florestas nativas' },
  { value: '0220902', name: '0220902 - Produção de carvão vegetal - florestas nativas' },
  { value: '0220903', name: '0220903 - Coleta de castanha-do-pará em florestas nativas' },
  { value: '0220904', name: '0220904 - Coleta de latex em florestas nativas' },
  { value: '0220905', name: '0220905 - Coleta de palmito em florestas nativas' },
  { value: '0220906', name: '0220906 - Conservação de florestas nativas' },
  {
    value: '0220999',
    name: '0220999 - Coleta de produtos não-madeireiros não especificados anteriormente em florestas nativas'
  },
  { value: '0230600', name: '0230600 - Atividades de apoio à produção florestal' },
  { value: '0311601', name: '0311601 - Pesca de peixes em água salgada' },
  { value: '0311602', name: '0311602 - Pesca de crustáceos e moluscos em água salgada' },
  { value: '0311603', name: '0311603 - Coleta de outros produtos marinhos' },
  { value: '0311604', name: '0311604 - Atividades de apoio à pesca em água salgada' },
  { value: '0312401', name: '0312401 - Pesca de peixes em água doce' },
  { value: '0312402', name: '0312402 - Pesca de crustáceos e moluscos em água doce' },
  { value: '0312403', name: '0312403 - Coleta de outros produtos aquáticos de água doce' },
  { value: '0312404', name: '0312404 - Atividades de apoio à pesca em água doce' },
  { value: '0321301', name: '0321301 - Criação de peixes em água salgada e salobra' },
  { value: '0321302', name: '0321302 - Criação de camarões em água salgada e salobra' },
  { value: '0321303', name: '0321303 - Criação de ostras e mexilhões em água salgada e salobra' },
  { value: '0321304', name: '0321304 - Criação de peixes ornamentais em água salgada e salobra' },
  { value: '0321305', name: '0321305 - Atividades de apoio à aquicultura em água salgada e salobra' },
  {
    value: '0321399',
    name: '0321399 - Cultivos e semicultivos da aquicultura em água salgada e salobra não especificados anteriormente'
  },
  { value: '0322101', name: '0322101 - Criação de peixes em água doce' },
  { value: '0322102', name: '0322102 - Criação de camarões em água doce' },
  { value: '0322103', name: '0322103 - Criação de ostras e mexilhões em água doce' },
  { value: '0322104', name: '0322104 - Criação de peixes ornamentais em água doce' },
  { value: '0322105', name: '0322105 - Ranicultura' },
  { value: '0322106', name: '0322106 - Criação de jacarés' },
  { value: '0322107', name: '0322107 - Atividades de apoio à aquicultura em água doce' },
  {
    value: '0322199',
    name: '0322199 - Cultivos e semicultivos da aquicultura em água doce não especificados anteriormente'
  },
  { value: '0500301', name: '0500301 - Extração de carvão mineral' },
  { value: '0500302', name: '0500302 - Beneficiamento de carvão mineral' },
  { value: '0600001', name: '0600001 - Extração de petróleo e gás natural' },
  { value: '0600002', name: '0600002 - Extração e beneficiamento de xisto' },
  { value: '0600003', name: '0600003 - Extração e beneficiamento de areias betuminosas' },
  { value: '0710301', name: '0710301 - Extratificação de minério de ferro' },
  { value: '0710302', name: '0710302 - Pelotização' },
  { value: '0721901', name: '0721901 - Extrato de minério de alumínio' },
  { value: '0721902', name: '0721902 - Beneficiamento de minério de alumínio' },
  { value: '0722701', name: '0722701 - Extraordinário de minério de estanho' },
  { value: '0722702', name: '0722702 - Beneficiamento de minérios de estanho' },
  { value: '0723501', name: '0723501 - Extração de minério de manganês' },
  { value: '0723502', name: '0723502 - Beneficiamento de minério de manganês' },
  { value: '0724301', name: '0724301 - Extração de minérios de metais preciosos' },
  { value: '0724302', name: '0724302 - Beneficiamento de minério de metais preciosos' },
  { value: '0725100', name: '0725100 - Extração de minerais radioativos' },
  { value: '0729401', name: '0729401 - Extraordinário de minérios de níquel e titânio' },
  { value: '0729402', name: '0729402 - Extratificação de minério de tungstênio' },
  { value: '0729403', name: '0729403 - Extraordinário de minério de níquel' },
  {
    value: '0729404',
    name: '0729404 - Extração de minérios de cobre, zinco e outros minerais metálicos não-ferrosos não especificados anteriormente'
  },
  {
    value: '0729405',
    name: '0729405 - Beneficiamento de minérios de cobre-zinco e outros minerais metálicos não-ferrosos não especificados anteriormente'
  },
  { value: '0810001', name: '0810001 - Extração de ardósia e beneficiamento associado' },
  { value: '0810002', name: '0810002 - Extração de granito e beneficiamento associado' },
  { value: '0810003', name: '0810003 - Extraído de mármore e beneficiamento associado' },
  { value: '0810004', name: '0810004 - Extração de calcário e dolomita e beneficiamento associado' },
  { value: '0810005', name: '0810005 - Extraordinário de gesso e caulim' },
  { value: '0810006', name: '0810006 - Extrato de areia' },
  { value: '0810007', name: '0810007 - Extração de argila e beneficiamento associado' },
  { value: '0810008', name: '0810008 - Extração de saibro e beneficiamento associado' },
  { value: '0810009', name: '0810009 - Extração de basalto e beneficiamento associado' },
  { value: '0810010', name: '0810010 - Beneficiamento de gesso e caulim associado à extração' },
  {
    value: '0810099',
    name: '0810099 - Extração e britamento de pedras e outros materiais para construção e beneficiamento associado'
  },
  { value: '0891600', name: '0891600 - Extração de minerais para fabricação de adubos' },
  { value: '0892401', name: '0892401 - Extração de sal marinho' },
  { value: '0892402', name: '0892402 - Extração de sal-gema' },
  { value: '0892403', name: '0892403 - Refino e outros tratamentos do sal' },
  { value: '0893200', name: '0893200 - Extraordinário de gemas (pedras preciosas e semipreciosas)' },
  { value: '0899101', name: '0899101 - Extraordinário de grafita' },
  { value: '0899102', name: '0899102 - Extração de quartzo' },
  { value: '0899103', name: '0899103 - Extração de amianto' },
  {
    value: '0899199',
    name: '0899199 - Extração de outros minerais não-metálicos não especificados anteriormente'
  },
  { value: '0910600', name: '0910600 - Atividades de apoio à extração de petróleo e gás natural' },
  { value: '0990401', name: '0990401 - Atividades de apoio à extração de minério de ferro' },
  { value: '0990402', name: '0990402 - Atividades de apoio à extração de minerais metálicos não-ferrosos' },
  { value: '0990403', name: '0990403 - Atividades de apoio à extração de minerais não-metálicos' },
  { value: '1011201', name: '1011201 - Frigorífico - abate de bovinos' },
  { value: '1011202', name: '1011202 - Frigorífico - abate de equinos' },
  { value: '1011203', name: '1011203 - Frigorífico - abate de ovinos e caprinos' },
  { value: '1011204', name: '1011204 - Frigorífico - abate de bufalinos' },
  { value: '1011205', name: '1011205 - Matadouro - abate de reses sob contrato - exceto abate de suínos' },
  { value: '1012101', name: '1012101 - Abate de aves' },
  { value: '1012102', name: '1012102 - Abate de pequenos animais' },
  { value: '1012103', name: '1012103 - Frigorífico - abate de suínos' },
  { value: '1012104', name: '1012104 - Matadouro - abate de suínos sob contrato' },
  { value: '1013901', name: '1013901 - Fabricação de produtos de carne' },
  { value: '1013902', name: '1013902 - Preparação de subprodutos do abate' },
  { value: '1020101', name: '1020101 - Preservação de peixes' },
  { value: '1020102', name: '1020102 - Fabricação de conservas de peixes' },
  { value: '1031700', name: '1031700 - Fabricação de conservas de frutas' },
  { value: '1032501', name: '1032501 - Fabricação de conservas de palmito' },
  { value: '1032599', name: '1032599 - Fabricação de conservas de legumes e outros vegetais' },
  { value: '1033301', name: '1033301 - Fabricação de sucos concentrados de frutas' },
  { value: '1033302', name: '1033302 - Fabricação de sucos de frutas exceto concentrados' },
  { value: '1041400', name: '1041400 - Fabricação de óleos vegetais em bruto' },
  { value: '1042200', name: '1042200 - Fabricação de óleos vegetais refinados' },
  {
    value: '1043100',
    name: '1043100 - Fabricação de margarina e outras gorduras vegetais e de óleos não-comestíveis de animais'
  },
  { value: '1051100', name: '1051100 - Preparação do leite' },
  { value: '1052000', name: '1052000 - Fabricação de laticínios' },
  { value: '1053800', name: '1053800 - Fabricação de sorvetes e outros gelados comestíveis' },
  { value: '1061901', name: '1061901 - Beneficiamento de arroz' },
  { value: '1061902', name: '1061902 - Fabricação de produtos do arroz' },
  { value: '1062700', name: '1062700 - Moagem de trigo e fabricação de derivados' },
  { value: '1063500', name: '1063500 - Fabricação de farinha de mandioca e derivados' },
  { value: '1064300', name: '1064300 - Fabricação de farinha de milho e derivados' },
  { value: '1065101', name: '1065101 - Fabricação de amidos e féculas de vegetais' },
  { value: '1065102', name: '1065102 - Fabricação de óleo de milho em bruto' },
  { value: '1065103', name: '1065103 - Fabricação de óleo de milho refinado' },
  { value: '1066000', name: '1066000 - Fabricação de alimentos para animais' },
  {
    value: '1069400',
    name: '1069400 - Moagem e fabricação de produtos de origem vegetal não especificados anteriormente'
  },
  { value: '1071600', name: '1071600 - Fabricação de açúcar em bruto' },
  { value: '1072401', name: '1072401 - Fabricação de açúcar de cana refinado' },
  { value: '1072402', name: '1072402 - Fabricação de açúcar de cereais (dextrose) e de beterraba' },
  { value: '1081301', name: '1081301 - Beneficiamento de café' },
  { value: '1081302', name: '1081302 - Torrefação e moagem de café' },
  { value: '1082100', name: '1082100 - Fabricação de produtos à base de café' },
  { value: '1091100', name: '1091100 - Fabricação de produtos de panificação' },
  { value: '1091101', name: '1091101 - Fabricação de produtos de panificação industrial' },
  {
    value: '1091102',
    name: '1091102 - Fabricação de produtos de padaria e confeitaria com predominância de produção própria'
  },
  { value: '1092900', name: '1092900 - Fabricação de biscoitos e bolachas' },
  { value: '1093701', name: '1093701 - Fabricação de produtos derivados do cacau e de chocolates' },
  { value: '1093702', name: '1093702 - Fabricação de frutas cristalizadas' },
  { value: '1094500', name: '1094500 - Fabricação de massas alimentícias' },
  { value: '1095300', name: '1095300 - Fabricação de especiarias, temperos e condimentos' },
  { value: '1096100', name: '1096100 - Fabricação de alimentos e pratos prontos' },
  { value: '1099601', name: '1099601 - Fabricação de vinagres' },
  { value: '1099602', name: '1099602 - Fabricação de pães alimentícios' },
  { value: '1099603', name: '1099603 - Fabricação de fermentos e leveduras' },
  { value: '1099604', name: '1099604 - Fabricação de gelo comum' },
  { value: '1099605', name: '1099605 - Fabricação de produtos para infusão (chá, etc.)' },
  { value: '1099606', name: '1099606 - Fabricação de adoçantes naturais e artificiais' },
  { value: '1099607', name: '1099607 - Fabricação de alimentos dietéticos e complementos alimentares' },
  {
    value: '1099699',
    name: '1099699 - Fabricação de outros produtos alimentícios não especificados anteriormente'
  },
  { value: '1111901', name: '1111901 - Fabricação de aguardente de cana-de-açúcar' },
  { value: '1111902', name: '1111902 - Fabricação de outras aguardentes e bebidas destiladas' },
  { value: '1112700', name: '1112700 - Fabricação de vinho' },
  { value: '1113501', name: '1113501 - Fabricação de malte' },
  { value: '1113502', name: '1113502 - Fabricação de cervejas e chopes' },
  { value: '1121600', name: '1121600 - Fabricação de águas envasadas' },
  { value: '1122401', name: '1122401 - Fabricação de refrigerantes' },
  { value: '1122402', name: '1122402 - Fabricação de chá mate e outros chás prontos para consumo' },
  { value: '1122403', name: '1122403 - Fabricação de refrescos, exceto refrescos de frutas' },
  { value: '1122404', name: '1122404 - Fabricação de bebidas isotônicas' },
  {
    value: '1122499',
    name: '1122499 - Fabricação de outras bebidas não-alcoólicas não especificadas anteriormente'
  },
  { value: '1210700', name: '1210700 - Processamento industrial do fumo' },
  { value: '1220401', name: '1220401 - Fabricação de cigarros' },
  { value: '1220402', name: '1220402 - Fabricação de cigarrilhas e charutos' },
  { value: '1220403', name: '1220403 - Fabricação de filtros para cigarros' },
  { value: '1220499', name: '1220499 - Fabricação de outros produtos do fumo, cigarrilhas e charutos' },
  { value: '1311100', name: '1311100 - Preparação e fiamento de fibras de algodão' },
  { value: '1312000', name: '1312000 - Preparação e fiação de fibras têxteis naturais' },
  { value: '1313800', name: '1313800 - Fiaçamento de fibras artificiais e sintéticas' },
  { value: '1314600', name: '1314600 - Fabricação de linhas para costurar e bordar' },
  { value: '1321900', name: '1321900 - Tecelagem de fios de algodão' },
  { value: '1322700', name: '1322700 - Tecelagem de fios de fibras têxteis naturais' },
  { value: '1323500', name: '1323500 - Tecelagem de fios de fibras artificiais e sintéticas' },
  { value: '1330800', name: '1330800 - Fabricação de tecidos de malha' },
  {
    value: '1340501',
    name: '1340501 - Estamparia e texturização em fios, artefatos têxteis e peças do vestuário'
  },
  { value: '1340502', name: '1340502 - Alvejamento de tecidos, artefatos têxteis e peças do vestuário' },
  {
    value: '1340599',
    name: '1340599 - Outros serviços de acabamento em fios, artefatos têxteis e peças do vestuário'
  },
  { value: '1351100', name: '1351100 - Fabricação de artefatos têxteis para uso doméstico' },
  { value: '1352900', name: '1352900 - Fabricação de artefatos de tapeçaria' },
  { value: '1353700', name: '1353700 - Fabricação de artefatos de cordoaria' },
  { value: '1354500', name: '1354500 - Fabricação de tecidos especiais' },
  {
    value: '1359600',
    name: '1359600 - Fabricação de outros produtos têxteis não especificados anteriormente'
  },
  { value: '1411801', name: '1411801 - Confecção de roupas íntimas' },
  { value: '1411802', name: '1411802 - Faculdade de roupas íntimas' },
  { value: '1412601', name: '1412601 - Confecção de peças de vestuário' },
  { value: '1412602', name: '1412602 - Confecção de peças do vestuário exceto roupas íntimas' },
  { value: '1412603', name: '1412603 - Faculdade de peças do vestuário' },
  { value: '1413401', name: '1413401 - Confecção de roupas profissionais' },
  { value: '1413402', name: '1413402 - Confecção de roupas profissionais' },
  { value: '1413403', name: '1413403 - Faculdade de roupas profissionais' },
  { value: '1414200', name: '1414200 - Fabricação de acessórios do vestuário' },
  { value: '1421500', name: '1421500 - Fabricação de meias' },
  { value: '1422300', name: '1422300 - Fabricação de artigos do vestuário, exceto meias' },
  { value: '1510600', name: '1510600 - Curtimento e outras preparações de couro' },
  { value: '1521100', name: '1521100 - Fabricação de artigos para viagem' },
  { value: '1529700', name: '1529700 - Fabricação de artefatos de couro não especificados anteriormente' },
  { value: '1531901', name: '1531901 - Fabricação de calçados de couro' },
  { value: '1531902', name: '1531902 - Acabamento de calçados de couro sob contrato' },
  { value: '1532700', name: '1532700 - Fabricação de tênis de qualquer material' },
  { value: '1533500', name: '1533500 - Fabricação de calçados de material sintético' },
  { value: '1539400', name: '1539400 - Fabricação de calçados de materiais não especificados anteriormente' },
  { value: '1540800', name: '1540800 - Fabricação de partes para calçados' },
  { value: '1610201', name: '1610201 - Serrarias com desdobramento de madeira' },
  { value: '1610202', name: '1610202 - Serrarias sem desdobramento de madeira' },
  { value: '1610203', name: '1610203 - Serrarias com desdobramento de madeira em bruto' },
  { value: '1610204', name: '1610204 - Serrarias sem desdobramento de madeira em bruto  -Resserragem' },
  { value: '1610205', name: '1610205 - Serviço de tratamento de madeira realizado sob contrato' },
  { value: '1621800', name: '1621800 - Fabricação de madeira laminada e de chapas de madeira compensada' },
  { value: '1622601', name: '1622601 - Fabricação de casas de madeira pré-fabricadas' },
  {
    value: '1622602',
    name: '1622602 - Fabricação de esquadrias de madeira e de peças de madeira para instalações industriais e comerciais'
  },
  { value: '1622699', name: '1622699 - Fabricação de outros artigos de carpintaria para construção' },
  { value: '1623400', name: '1623400 - Fabricação de artefatos de tanoaria e de embalagens de madeira' },
  { value: '1629301', name: '1629301 - Fabricação de artefatos diversos de madeira' },
  {
    value: '1629302',
    name: '1629302 - Fabricação de artefatos diversos de cortiça, palha, vime e outros materiais translúcidos, exceto móveis'
  },
  { value: '1710900', name: '1710900 - Fabricação de celulose e outras pastas para a fabricação de papel' },
  { value: '1721400', name: '1721400 - Fabricação de papel' },
  { value: '1722200', name: '1722200 - Fabricação de cartolina e papel-cartão' },
  { value: '1731100', name: '1731100 - Fabricação de embalagens de papel' },
  { value: '1732000', name: '1732000 - Fabricação de embalagens de cartolina e papel-cartão' },
  { value: '1733800', name: '1733800 - Fabricação de chapas e de embalagens de papelão ondulado' },
  { value: '1741901', name: '1741901 - Fabricação de formulários contínuos' },
  {
    value: '1741902',
    name: '1741902 - Fabricação de produtos de papel, papel cartão e papelão ondulado para uso comercial e de escritório, exceto formulário contínuo'
  },
  { value: '1742701', name: '1742701 - Fabricação de fraldas descartáveis' },
  { value: '1742702', name: '1742702 - Fabricação de absorventes higiênicos' },
  {
    value: '1742799',
    name: '1742799 - Fabricação de produtos de papel para uso doméstico e higiênico-sanitário não especificados anteriormente'
  },
  {
    value: '1749400',
    name: '1749400 - Fabricação de produtos de pastas celulósicas, cartolina, papel-cartão e papelão ondulado não especificados anteriormente'
  },
  { value: '1811301', name: '1811301 - Impressão de jornais' },
  { value: '1811302', name: '1811302 - Impressão de livros' },
  { value: '1812100', name: '1812100 - Impressão de material de segurança' },
  { value: '1813001', name: '1813001 - Impressão de material para uso publicitário' },
  { value: '1813099', name: '1813099 - Impressão de material para outros usos' },
  { value: '1821100', name: '1821100 - Serviços de pré-impressão' },
  { value: '1822900', name: '1822900 - Serviços de acabamentos gráficos' },
  { value: '1822901', name: '1822901 - Serviços de encadernação e plastificação' },
  { value: '1822999', name: '1822999 - Serviços de acabamentos gráficos' },
  { value: '1830001', name: '1830001 - Reprodução de som em qualquer suporte' },
  { value: '1830002', name: '1830002 - Reprodução de vídeo em qualquer suporte' },
  { value: '1830003', name: '1830003 - Reprodução de software em qualquer suporte' },
  { value: '1910100', name: '1910100 - Coquerias' },
  { value: '1921700', name: '1921700 - Fabricação de produtos do refino de petróleo' },
  { value: '1922501', name: '1922501 - Fórmula de combustíveis' },
  { value: '1922502', name: '1922502 - Rerrefino de óleos lubrificantes' },
  { value: '1922599', name: '1922599 - Fabricação de outros produtos derivados do petróleo' },
  { value: '1931400', name: '1931400 - Fabricação de álcool' },
  { value: '1932200', name: '1932200 - Fabricação de biocombustíveis' },
  { value: '2011800', name: '2011800 - Fabricação de cloro e alcalis' },
  { value: '2012600', name: '2012600 - Fabricação de intermediários para fertilizantes' },
  { value: '2013400', name: '2013400 - Fabricação de adubos e fertilizantes' },
  { value: '2013401', name: '2013401 - Fabricação de adubos e fertilizantes organo-minerais' },
  { value: '2013402', name: '2013402 - Fabricação de adubos e fertilizantes' },
  { value: '2014200', name: '2014200 - Fabricação de gases industriais' },
  { value: '2019301', name: '2019301 - Elaboração de combustíveis nucleares' },
  {
    value: '2019399',
    name: '2019399 - Fabricação de outros produtos químicos inorgânicos não especificados anteriormente'
  },
  { value: '2021500', name: '2021500 - Fabricação de produtos petroquímicos básicos' },
  { value: '2022300', name: '2022300 - Fabricação de intermediários para plastificantes' },
  {
    value: '2029100',
    name: '2029100 - Fabricação de produtos químicos orgânicos não especificados anteriormente'
  },
  { value: '2031200', name: '2031200 - Fabricação de resinas termoplásticas' },
  { value: '2032100', name: '2032100 - Fabricação de resinas termofixas' },
  { value: '2033900', name: '2033900 - Fabricação de elastômeros' },
  { value: '2040100', name: '2040100 - Fabricação de fibras artificiais e sintéticas' },
  { value: '2051700', name: '2051700 - Fabricação de defensivos agrícolas' },
  { value: '2052500', name: '2052500 - Fabricação de desinfetantes domissanitários' },
  { value: '2061400', name: '2061400 - Fabricação de sabões e detergentes sintéticos' },
  { value: '2062200', name: '2062200 - Fabricação de produtos de limpeza e polimento' },
  { value: '2063100', name: '2063100 - Fabricação de cosméticos' },
  { value: '2071100', name: '2071100 - Fabricação de tintas, esmaltes e lacas' },
  { value: '2072000', name: '2072000 - Fabricação de tintas de impressão' },
  { value: '2073800', name: '2073800 - Fabricação de impermeabilizantes' },
  { value: '2091600', name: '2091600 - Fabricação de adesivos e selantes' },
  { value: '2092401', name: '2092401 - Fabricação de pólvoras' },
  { value: '2092402', name: '2092402 - Fabricação de artigos pirotécnicos' },
  { value: '2092403', name: '2092403 - Fabricação de fósforos de segurança' },
  { value: '2093200', name: '2093200 - Fabricação de aditivos de uso industrial' },
  { value: '2094100', name: '2094100 - Fabricação de catalisadores' },
  {
    value: '2099101',
    name: '2099101 - Fabricação de chapas papéis e outros materiais e produtos químicos para fotografia'
  },
  {
    value: '2099199',
    name: '2099199 - Fabricação de outros produtos químicos não especificados anteriormente'
  },
  { value: '2110600', name: '2110600 - Fabricação de produtos farmacêuticos' },
  { value: '2121101', name: '2121101 - Fabricação de medicamentos alopáticos para uso humano' },
  { value: '2121102', name: '2121102 - Fabricação de medicamentos homeopáticos para uso humano' },
  { value: '2121103', name: '2121103 - Fabricação de medicamentos fitoterápicos para uso humano' },
  { value: '2122000', name: '2122000 - Fabricação de medicamentos para uso veterinário' },
  { value: '2123800', name: '2123800 - Fabricação de preparações farmacêuticas' },
  { value: '2211100', name: '2211100 - Fabricação de pneumáticos e de câmaras-de-ar' },
  { value: '2212900', name: '2212900 - Reforma de pneumáticos usados' },
  { value: '2219600', name: '2219600 - Fabricação de artefatos de borracha não especificados anteriormente' },
  { value: '2221800', name: '2221800 - Fabricação de laminados planos e tubulares de material plástico' },
  { value: '2222600', name: '2222600 - Fabricação de embalagens de material plástico' },
  {
    value: '2223400',
    name: '2223400 - Fabricação de tubos e acessórios de material plástico para uso na construção'
  },
  {
    value: '2229301',
    name: '2229301 - Fabricação de artefatos de material plástico para uso pessoal e doméstico'
  },
  { value: '2229302', name: '2229302 - Fabricação de artefatos de material plástico para usos industriais' },
  { value: '2229303', name: '2229303 - Fabricação de artefatos de material plástico para uso na construção' },
  {
    value: '2229399',
    name: '2229399 - Fabricação de artefatos de material plástico para outros usos não especificados anteriormente'
  },
  { value: '2311700', name: '2311700 - Fabricação de vidro plano e de segurança' },
  { value: '2312500', name: '2312500 - Fabricação de embalagens de vidro' },
  { value: '2319200', name: '2319200 - Fabricação de artigos de vidro' },
  { value: '2320600', name: '2320600 - Fabricação de cimento' },
  { value: '2330301', name: '2330301 - Fabricação de estruturas pré-moldadas de concreto armado' },
  { value: '2330302', name: '2330302 - Fabricação de artefatos de cimento para uso na construção' },
  { value: '2330303', name: '2330303 - Fabricação de artefatos de fibrocimento para uso na construção' },
  { value: '2330304', name: '2330304 - Fabricação de casas pré-moldadas de concreto' },
  { value: '2330305', name: '2330305 - Preparação de massa de concreto e argamassa para construção' },
  {
    value: '2330399',
    name: '2330399 - Fabricação de outros artefatos e produtos de concreto, fibrocimento, gesso e materiais semelhantes'
  },
  { value: '2341900', name: '2341900 - Fabricação de produtos cerâmicos refratários' },
  { value: '2342701', name: '2342701 - Fabricação de azulejos e pisos' },
  {
    value: '2342702',
    name: '2342702 - Fabricação de artefatos de cerâmica e barro cozido para uso na construção'
  },
  { value: '2349401', name: '2349401 - Fabricação de material sanitário de cerâmica' },
  {
    value: '2349499',
    name: '2349499 - Fabricação de produtos cerâmicos não-refratários não especificados anteriormente'
  },
  { value: '2391501', name: '2391501 - Britamento de pedras' },
  { value: '2391502', name: '2391502 - Aparelhamento de pedras para construção' },
  {
    value: '2391503',
    name: '2391503 - Aparelhamento de placas e execução de trabalhos em marcenaria e outras pedras'
  },
  { value: '2392300', name: '2392300 - Fabricação de cal e gesso' },
  { value: '2399101', name: '2399101 - Decoração e outros trabalhos em cerâmica, louça, vidro e cristal' },
  { value: '2399102', name: '2399102 - Fabricação de abrasivos' },
  {
    value: '2399199',
    name: '2399199 - Fabricação de outros produtos de minerais não-metálicos não especificados anteriormente'
  },
  { value: '2411300', name: '2411300 - Produção de ferro-gusa' },
  { value: '2412100', name: '2412100 - Produção de ferroligas' },
  { value: '2421100', name: '2421100 - Produção de semi-acabados de aço' },
  { value: '2422901', name: '2422901 - Produção de laminados planos de aço ao carbono' },
  { value: '2422902', name: '2422902 - Produção de laminados planos de aços especiais' },
  { value: '2423701', name: '2423701 - Produção de tubos de aço sem costura' },
  { value: '2423702', name: '2423702 - Produção de laminados longos de aço' },
  { value: '2424501', name: '2424501 - Produção de arames de aço' },
  { value: '2424502', name: '2424502 - Produção de relaminados exceto arames' },
  { value: '2431800', name: '2431800 - Produção de tubos de aço com costura' },
  { value: '2439300', name: '2439300 - Produção de outros tubos de ferro e aço' },
  { value: '2441501', name: '2441501 - Produção de alumínio e suas ligas em formas primárias' },
  { value: '2441502', name: '2441502 - Produção de laminados de alumínio' },
  { value: '2442300', name: '2442300 - Metalurgia dos metais preciosos' },
  { value: '2443100', name: '2443100 - Metalurgia do cobre' },
  { value: '2449101', name: '2449101 - Produção de zinco em formas primárias' },
  { value: '2449102', name: '2449102 - Produção de laminados de zinco' },
  { value: '2449103', name: '2449103 - Fabricação de ínodos para galvanoplastia' },
  {
    value: '2449199',
    name: '2449199 - Metalurgia de outros metais não ferrosos e suas ligas não especificados anteriormente'
  },
  { value: '2451200', name: '2451200 - Fundição de ferro e aço' },
  { value: '2452100', name: '2452100 - Fundições de metais não-ferrosos e suas ligas' },
  { value: '2511000', name: '2511000 - Fabricação de estruturas metálicas' },
  { value: '2512800', name: '2512800 - Fabricação de esquadrias de metal' },
  { value: '2513600', name: '2513600 - Fabricação de obras de caldeiraria pesada' },
  { value: '2521700', name: '2521700 - Fabricação de tanques' },
  { value: '2522500', name: '2522500 - Fabricação de caldeiras geradoras de vapor' },
  { value: '2531401', name: '2531401 - Produção de forjados de aço' },
  { value: '2531402', name: '2531402 - Produção de forjados de metais não-ferrosos e suas ligas' },
  { value: '2532201', name: '2532201 - Produção de artefatos estampados de metal' },
  { value: '2532202', name: '2532202 - Metalurgia do pé' },
  { value: '2539000', name: '2539000 - Serviços de usinagem, tratamento e revestimento em metais' },
  { value: '2539001', name: '2539001 - Serviços de usinagem' },
  { value: '2539002', name: '2539002 - Serviços de tratamento e revestimento em metais' },
  { value: '2541100', name: '2541100 - Fabricação de artigos de cutelaria' },
  { value: '2542000', name: '2542000 - Fabricação de artigos de serralheria' },
  { value: '2543800', name: '2543800 - Fabricação de ferramentas' },
  { value: '2550101', name: '2550101 - Fabricação de equipamento bélico pesado' },
  { value: '2550102', name: '2550102 - Fabricação de armas de fogo' },
  { value: '2591800', name: '2591800 - Fabricação de embalagens metálicas' },
  { value: '2592601', name: '2592601 - Fabricação de produtos de trefilados de metal padronizados' },
  { value: '2592602', name: '2592602 - Fabricação de produtos de trefilados de metal' },
  { value: '2593400', name: '2593400 - Fabricação de artigos de metal para uso doméstico e pessoal' },
  { value: '2599301', name: '2599301 - Serviços de confecção de armações metálicas para a construção' },
  { value: '2599302', name: '2599302 - Serviço de corte e dobra de metais' },
  {
    value: '2599399',
    name: '2599399 - Fabricação de outros produtos de metal não especificados anteriormente'
  },
  { value: '2610800', name: '2610800 - Fabricação de componentes eletrônicos' },
  { value: '2621300', name: '2621300 - Fabricação de equipamentos de informática' },
  { value: '2622100', name: '2622100 - Fabricação de periféricos para equipamentos de informática' },
  { value: '2631100', name: '2631100 - Fabricação de equipamentos transmissores de comunicação' },
  {
    value: '2632900',
    name: '2632900 - Fabricação de aparelhos telefônicos e de outros equipamentos de comunicação'
  },
  {
    value: '2640000',
    name: '2640000 - Fabricação de aparelhos de recepção, gravação e amplificação de áudio e vídeo'
  },
  { value: '2651500', name: '2651500 - Fabricação de aparelhos e equipamentos de medida' },
  { value: '2652300', name: '2652300 - Fabricação de cronômetros e relógios' },
  {
    value: '2660400',
    name: '2660400 - Fabricação de aparelhos eletromédicos e eletroterapêuticos e equipamentos de irradiação'
  },
  { value: '2670101', name: '2670101 - Fabricação de equipamentos e instrumentos ópticos' },
  { value: '2670102', name: '2670102 - Fabricação de aparelhos fotográficos e cinematográficos' },
  { value: '2680900', name: '2680900 - Fabricação de mídias virgens' },
  { value: '2710401', name: '2710401 - Fabricação de geradores de corrente contínua e alternada' },
  {
    value: '2710402',
    name: '2710402 - Fabricação de transformadores conversores sincronizadores e semelhantes peças e acessórios'
  },
  { value: '2710403', name: '2710403 - Fabricação de motores elétricos' },
  { value: '2721000', name: '2721000 - Fabricação de pilhas exceto para veículos automotores' },
  { value: '2722801', name: '2722801 - Fabricação de baterias e acumuladores para veículos automotores' },
  {
    value: '2722802',
    name: '2722802 - Recondicionamento de baterias e acumuladores para veículos automotores'
  },
  {
    value: '2731700',
    name: '2731700 - Fabricação de aparelhos e equipamentos para distribuição e controle de energia elétrica'
  },
  {
    value: '2732500',
    name: '2732500 - Fabricação de material elétrico para instalações em circuito de consumo'
  },
  { value: '2733300', name: '2733300 - Fabricação de fios' },
  { value: '2740601', name: '2740601 - Fabricação de lâmpadas' },
  { value: '2740602', name: '2740602 - Fabricação de luminárias e outros equipamentos de iluminação' },
  { value: '2751100', name: '2751100 - Fabricação de fogões, peças e acessórios' },
  { value: '2759701', name: '2759701 - Fabricação de aparelhos elétricos de uso pessoal' },
  {
    value: '2759799',
    name: '2759799 - Fabricação de outros aparelhos eletrodomésticos não especificados anteriormente'
  },
  { value: '2790201', name: '2790201 - Fabricação de eletrodos, eletroímãs e isoladores' },
  { value: '2790202', name: '2790202 - Fabricação de equipamentos para sinalização e alarme' },
  {
    value: '2790299',
    name: '2790299 - Fabricação de outros equipamentos e aparelhos elétricos não especificados anteriormente'
  },
  {
    value: '2811900',
    name: '2811900 - Fabricação de motores e turbinas exceto para aviões e veículos rodoviários'
  },
  {
    value: '2812700',
    name: '2812700 - Fabricação de equipamentos hidráulicos e pneumáticos exceto válvulas'
  },
  { value: '2813500', name: '2813500 - Fabricação de válvulas, peças e acessórios' },
  { value: '2814301', name: '2814301 - Fabricação de compressores para uso industrial' },
  { value: '2814302', name: '2814302 - Fabricação de compressores para uso não industrial' },
  { value: '2815101', name: '2815101 - Fabricação de rolamentos para fins industriais' },
  { value: '2815102', name: '2815102 - Fabricação de equipamentos de transmissão para fins industriais' },
  { value: '2821601', name: '2821601 - Fabricação de fornos industriais, peças e acessórios' },
  { value: '2821602', name: '2821602 - Fabricação de estufas e fornos elétricos para fins industriais' },
  { value: '2822401', name: '2822401 - Fabricação de máquinas, peças e acessórios' },
  { value: '2822402', name: '2822402 - Fabricação de máquinas, peças e acessórios' },
  {
    value: '2823200',
    name: '2823200 - Fabricação de máquinas e aparelhos de refrigeração e ventilação para uso industrial e comercial'
  },
  {
    value: '2824101',
    name: '2824101 - Fabricação de aparelhos e equipamentos de ar condicionado para uso industrial'
  },
  {
    value: '2824102',
    name: '2824102 - Fabricação de aparelhos e equipamentos de ar condicionado para uso não industrial'
  },
  {
    value: '2825900',
    name: '2825900 - Fabricação de máquinas e equipamentos para saneamento básico e ambiental'
  },
  { value: '2829101', name: '2829101 - Fabricação de máquinas de escrever, peças e acessórios' },
  {
    value: '2829199',
    name: '2829199 - Fabricação de outras máquinas e equipamentos de uso geral não especificados anteriormente'
  },
  { value: '2831300', name: '2831300 - Fabricação de tratores agrícolas' },
  { value: '2832100', name: '2832100 - Fabricação de equipamentos para irrigação agrícola' },
  {
    value: '2833000',
    name: '2833000 - Fabricação de máquinas e equipamentos para a agricultura e pecuária exceto para irrigação'
  },
  { value: '2840200', name: '2840200 - Fabricação de máquinas-ferramenta' },
  {
    value: '2851800',
    name: '2851800 - Fabricação de máquinas e equipamentos para a prospecção e extração de petróleo'
  },
  {
    value: '2852600',
    name: '2852600 - Fabricação de outras máquinas e equipamentos para uso na extração mineral, exceto na extração de petróleo'
  },
  { value: '2853400', name: '2853400 - Fabricação de tratores exceto agrícolas' },
  {
    value: '2854200',
    name: '2854200 - Fabricação de máquinas e equipamentos para terraplenagem, peças e acessórios, exceto tratores'
  },
  {
    value: '2861500',
    name: '2861500 - Fabricação de máquinas para a indústria metalúrgica exceto máquinas-ferramenta'
  },
  {
    value: '2862300',
    name: '2862300 - Fabricação de máquinas e equipamentos para as indústrias de alimentos, peças e acessórios'
  },
  { value: '2863100', name: '2863100 - Fabricação de máquinas e equipamentos para a indústria têxtil' },
  {
    value: '2864000',
    name: '2864000 - Fabricação de máquinas e equipamentos para as indústrias do vestuário e acessórios'
  },
  {
    value: '2865800',
    name: '2865800 - Fabricação de máquinas e equipamentos para as indústrias de celulose, peças e acessórios'
  },
  { value: '2866600', name: '2866600 - Fabricação de máquinas e equipamentos para a indústria do plástico' },
  {
    value: '2869100',
    name: '2869100 - Fabricação de máquinas e equipamentos para uso industrial específico não especificados anteriormente'
  },
  { value: '2910701', name: '2910701 - Fabricação de automóveis' },
  { value: '2910702', name: '2910702 - Fabricação de chassis com motor para automóveis' },
  { value: '2910703', name: '2910703 - Fabricação de motores para automóveis' },
  { value: '2920401', name: '2920401 - Fabricação de caminhões e ônibus' },
  { value: '2920402', name: '2920402 - Fabricação de motores para caminhões e ônibus' },
  { value: '2930101', name: '2930101 - Fabricação de cabines' },
  { value: '2930102', name: '2930102 - Fabricação de carrocerias para ônibus' },
  { value: '2930103', name: '2930103 - Fabricação de cabines, exceto caminhões e ônibus' },
  {
    value: '2941700',
    name: '2941700 - Fabricação de peças e acessórios para o sistema motor de veículos automotores'
  },
  {
    value: '2942500',
    name: '2942500 - Fabricação de peças e acessórios para os sistemas de marcha e transmissão de veículos automotores'
  },
  {
    value: '2943300',
    name: '2943300 - Fabricação de peças e acessórios para o sistema de freios de veículos automotores'
  },
  {
    value: '2944100',
    name: '2944100 - Fabricação de peças e acessórios para o sistema de direção e suspensão de veículos automotores'
  },
  {
    value: '2945000',
    name: '2945000 - Fabricação de material elétrico e eletrônico para veículos automotores'
  },
  { value: '2949201', name: '2949201 - Fabricação de bancos e estofados para veículos automotores' },
  {
    value: '2949299',
    name: '2949299 - Fabricação de outras peças e acessórios para veículos automotores não especificadas anteriormente'
  },
  {
    value: '2950600',
    name: '2950600 - Recondicionamento e recuperação de motores para veículos automotores'
  },
  { value: '3011301', name: '3011301 - Construção de embarcações de grande porte' },
  { value: '3011302', name: '3011302 - Construção de embarcações para uso comercial e para usos especiais' },
  { value: '3012100', name: '3012100 - Construção de embarcações para esporte e lazer' },
  { value: '3031800', name: '3031800 - Fabricação de locomotivas' },
  { value: '3032600', name: '3032600 - Fabricação de peças e acessórios para veículos ferroviários' },
  { value: '3041500', name: '3041500 - Fabricação de aeronaves' },
  { value: '3042300', name: '3042300 - Fabricação de turbinas' },
  { value: '3050400', name: '3050400 - Fabricação de veículos militares de combate' },
  { value: '3091100', name: '3091100 - Fabricação de motocicletas' },
  { value: '3091101', name: '3091101 - Fabricação de motocicletas' },
  { value: '3091102', name: '3091102 - Fabricação de peças e acessórios para motocicletas' },
  { value: '3092000', name: '3092000 - Fabricação de bicicletas e triciclos não-motorizados' },
  {
    value: '3099700',
    name: '3099700 - Fabricação de equipamentos de transporte não especificados anteriormente'
  },
  { value: '3101200', name: '3101200 - Fabricação de móveis com predominância de madeira' },
  { value: '3102100', name: '3102100 - Fabricação de móveis com predominância de metal' },
  { value: '3103900', name: '3103900 - Fabricação de móveis de outros materiais' },
  { value: '3104700', name: '3104700 - Fabricação de colchões' },
  { value: '3211601', name: '3211601 - Lapidário de gemas' },
  { value: '3211602', name: '3211602 - Fabricação de artefatos de joalheria e ourivesaria' },
  { value: '3211603', name: '3211603 - Cunhagem de moedas e medalhas' },
  { value: '3212400', name: '3212400 - Fabricação de bijuterias e artefatos semelhantes' },
  { value: '3220500', name: '3220500 - Fabricação de instrumentos musicais' },
  { value: '3230200', name: '3230200 - Fabricação de artefatos para pesca e esporte' },
  { value: '3240001', name: '3240001 - Fabricação de jogos eletrônicos' },
  { value: '3240002', name: '3240002 - Fabricação de mesas de bilhar' },
  { value: '3240003', name: '3240003 - Fabricação de mesas de bilhar' },
  {
    value: '3240099',
    name: '3240099 - Fabricação de outros brinquedos e jogos recreativos não especificados anteriormente'
  },
  {
    value: '3250701',
    name: '3250701 - Fabricação de instrumentos não-eletrônicos e utensílios para uso médico-odontológico e de laboratório'
  },
  {
    value: '3250702',
    name: '3250702 - Fabricação de mobiliário para uso médico-odontológico e de laboratório'
  },
  {
    value: '3250703',
    name: '3250703 - Fabricação de aparelhos e utensílios para correção de defeitos físicos e aparelhos ortopédicos em geral sob encomenda'
  },
  {
    value: '3250704',
    name: '3250704 - Fabricação de aparelhos e utensílios para correção de defeitos físicos e aparelhos ortopédicos em geral'
  },
  { value: '3250705', name: '3250705 - Fabricação de materiais para medicina e odontologia' },
  { value: '3250706', name: '3250706 - Serviços de prótese dentária' },
  { value: '3250707', name: '3250707 - Fabricação de artigos ópticos' },
  {
    value: '3250708',
    name: '3250708 - Fabricação de artefatos de tecido não tecido para uso odonto-médico-hospitalar'
  },
  { value: '3250709', name: '3250709 - Serviço de laboratório óptico' },
  { value: '3291400', name: '3291400 - Fabricação de escovas' },
  { value: '3292201', name: '3292201 - Fabricação de roupas de proteção e segurança e resistentes a fogo' },
  {
    value: '3292202',
    name: '3292202 - Fabricação de equipamentos e acessórios para segurança pessoal e profissional'
  },
  { value: '3299001', name: '3299001 - Fabricação de guarda-chuvas e similares' },
  { value: '3299002', name: '3299002 - Fabricação de canetas' },
  { value: '3299003', name: '3299003 - Fabricação de letras exceto luminosos' },
  { value: '3299004', name: '3299004 - Fabricação de painéis e letreiros luminosos' },
  { value: '3299005', name: '3299005 - Fabricação de aviamentos para costura' },
  { value: '3299006', name: '3299006 - Fabricação de velas' },
  { value: '3299099', name: '3299099 - Fabricação de produtos diversos não especificados anteriormente' },
  { value: '3311200', name: '3311200 - Manutenção e reparação de tanques, exceto para veículos' },
  { value: '3312101', name: '3312101 - Manutenção e reparação de equipamentos transmissores de comunicação' },
  { value: '3312102', name: '3312102 - Manutenção e reparação de aparelhos e instrumentos de medida' },
  {
    value: '3312103',
    name: '3312103 - Manutenção e reparação de aparelhos eletromédicos e eletroterapêuticos e equipamentos de irradiação'
  },
  { value: '3312104', name: '3312104 - Manutenção e reparação de equipamentos e instrumentos ópticos' },
  { value: '3313901', name: '3313901 - Manutenção e reparação de geradores' },
  { value: '3313902', name: '3313902 - Manutenção e reparação de baterias e acumuladores elétricos' },
  { value: '3313999', name: '3313999 - Manutenção e reparação de máquinas' },
  { value: '3314701', name: '3314701 - Manutenção e reparação de máquinas motrizes não-elétricas' },
  { value: '3314702', name: '3314702 - Manutenção e reparação de equipamentos hidráulicos e pneumáticos' },
  { value: '3314703', name: '3314703 - Manutenção e reparação de válvulas industriais' },
  { value: '3314704', name: '3314704 - Manutenção e reparação de compressores' },
  {
    value: '3314705',
    name: '3314705 - Manutenção e reparação de equipamentos de transmissão para fins industriais'
  },
  { value: '3314706', name: '3314706 - Manutenção e reparação de máquinas' },
  {
    value: '3314707',
    name: '3314707 - Manutenção e reparação de máquinas e aparelhos de refrigeração e ventilação para uso industrial e comercial'
  },
  { value: '3314708', name: '3314708 - Manutenção e reparação de máquinas' },
  { value: '3314709', name: '3314709 - Manutenção e reparação de máquinas de escrever' },
  {
    value: '3314710',
    name: '3314710 - Manutenção e reparação de máquinas e equipamentos para uso geral não especificados anteriormente'
  },
  {
    value: '3314711',
    name: '3314711 - Manutenção e reparação de máquinas e equipamentos para agricultura e pecuária'
  },
  { value: '3314712', name: '3314712 - Manutenção e reparação de tratores agrícolas' },
  { value: '3314713', name: '3314713 - Manutenção e reparação de máquinas-ferramenta' },
  {
    value: '3314714',
    name: '3314714 - Manutenção e reparação de máquinas e equipamentos para a prospecção e extração de petróleo'
  },
  {
    value: '3314715',
    name: '3314715 - Manutenção e reparação de máquinas e equipamentos para uso na extração mineral'
  },
  { value: '3314716', name: '3314716 - Manutenção e reparação de tratores' },
  {
    value: '3314717',
    name: '3314717 - Manutenção e reparação de máquinas e equipamentos de terraplenagem, exceto tratores'
  },
  { value: '3314718', name: '3314718 - Manutenção e reparação de máquinas para a indústria metalúrgica' },
  {
    value: '3314719',
    name: '3314719 - Manutenção e reparação de máquinas e equipamentos para as indústrias de alimentos'
  },
  {
    value: '3314720',
    name: '3314720 - Manutenção e reparação de máquinas e equipamentos para a indústria têxtil, do couro e calçados'
  },
  {
    value: '3314721',
    name: '3314721 - Manutenção e reparação de máquinas e aparelhos para a indústria de celulose'
  },
  {
    value: '3314722',
    name: '3314722 - Manutenção e reparação de máquinas e aparelhos para a indústria do plástico'
  },
  {
    value: '3314799',
    name: '3314799 - Manutenção e reparação de outras máquinas e equipamentos para usos industriais não especificados anteriormente'
  },
  { value: '3315500', name: '3315500 - Manutenção e reparação de veículos ferroviários' },
  { value: '3316301', name: '3316301 - Manutenção e reparação de aeronaves' },
  { value: '3316302', name: '3316302 - Manutenção de aeronaves na pista' },
  { value: '3317101', name: '3317101 - Manutenção e reparação de embarcações e estruturas flutuantes' },
  { value: '3317102', name: '3317102 - Manutenção e reparação de embarcações para esporte e lazer' },
  {
    value: '3319800',
    name: '3319800 - Manutenção e reparação de equipamentos e produtos não especificados anteriormente'
  },
  { value: '3321000', name: '3321000 - Instalação de máquinas e equipamentos industriais' },
  { value: '3329501', name: '3329501 - Serviços de montagem de móveis de qualquer material' },
  { value: '3329599', name: '3329599 - Instalação de outros equipamentos não especificados anteriormente' },
  { value: '3511500', name: '3511500 - Geração de energia elétrica' },
  { value: '3511501', name: '3511501 - Geração de energia elétrica' },
  {
    value: '3511502',
    name: '3511502 - Atividades de coordenação e controle da operação da geração e transmissão de energia elétrica'
  },
  { value: '3512300', name: '3512300 - Transmissão de energia elétrica' },
  { value: '3513100', name: '3513100 - Comércio atacadista de energia elétrica' },
  { value: '3514000', name: '3514000 - Distribuição de energia elétrica' },
  { value: '3520401', name: '3520401 - Produção de gás; processamento de gás natural' },
  { value: '3520402', name: '3520402 - Distribuição de combustíveis gasosos por redes urbanas' },
  { value: '3530100', name: '3530100 - Produção e distribuição de vapor' },
  { value: '3600601', name: '3600601 - Captação' },
  { value: '3600602', name: '3600602 - Distribuição de água por caminhões' },
  { value: '3701100', name: '3701100 - Gestão de redes de esgoto' },
  { value: '3702900', name: '3702900 - Atividades relacionadas a esgoto' },
  { value: '3811400', name: '3811400 - Coleta de resíduos não-perigosos' },
  { value: '3812200', name: '3812200 - Coleta de resíduos perigosos' },
  { value: '3821100', name: '3821100 - Tratamento e disposição de resíduos não-perigosos' },
  { value: '3822000', name: '3822000 - Tratamento e disposição de resíduos perigosos' },
  { value: '3831901', name: '3831901 - Recuperação de sucatas de alumínio' },
  { value: '3831999', name: '3831999 - Recuperação de materiais metálicos' },
  { value: '3832700', name: '3832700 - Recuperação de materiais plásticos' },
  { value: '3839401', name: '3839401 - Usinas de compostagem' },
  { value: '3839499', name: '3839499 - Recuperação de materiais não especificados anteriormente' },
  { value: '3900500', name: '3900500 - Descontaminação e outros serviços de gestão de resíduos' },
  { value: '4110700', name: '4110700 - Incorporaçãodeempreendimentosimobiliários' },
  { value: '4120400', name: '4120400 - Construção de edifícios' },
  { value: '4211101', name: '4211101 - Construção de rodovias e ferrovias' },
  { value: '4211102', name: '4211102 - Pintura para sinalização em pistas rodoviárias e aeroportos' },
  { value: '4212000', name: '4212000 - Construção de obras de arte especiais' },
  { value: '4213800', name: '4213800 - Obras de urbanização - ruas' },
  { value: '4221901', name: '4221901 - Construção de barragens e represas para geração de energia elétrica' },
  { value: '4221902', name: '4221902 - Construção de estações e redes de distribuição de energia elétrica' },
  { value: '4221903', name: '4221903 - Manutenção de redes de distribuição de energia elétrica' },
  { value: '4221904', name: '4221904 - Construção de estações e redes de telecomunicações' },
  { value: '4221905', name: '4221905 - Manutenção de estações e redes de telecomunicações' },
  {
    value: '4222701',
    name: '4222701 - Construção de redes de abastecimento de água exceto obras de irrigação'
  },
  { value: '4222702', name: '4222702 - Obras de irrigação' },
  { value: '4223500', name: '4223500 - Construção de redes de transportes por dutos' },
  { value: '4291000', name: '4291000 - Obras portuárias' },
  { value: '4292801', name: '4292801 - Montagem de estruturas metálicas' },
  { value: '4292802', name: '4292802 - Obras de montagem industrial' },
  { value: '4299501', name: '4299501 - Construção de instalações esportivas e recreativas' },
  { value: '4299599', name: '4299599 - Outras obras de engenharia civil não especificadas anteriormente' },
  { value: '4311801', name: '4311801 - Demolição de edifícios e outras estruturas' },
  { value: '4311802', name: '4311802 - Preparação de canteiro e limpeza de terreno' },
  { value: '4312600', name: '4312600 - Perfurações e sondagens' },
  { value: '4313400', name: '4313400 - Obras de terraplenagem' },
  { value: '4319300', name: '4319300 - Serviços de preparação do terreno não especificados anteriormente' },
  { value: '4321500', name: '4321500 - Instalação e manutenção elétrica' },
  { value: '4322301', name: '4322301 - Instalações hidráulicas' },
  { value: '4322302', name: '4322302 - Instalação e manutenção de sistemas centrais de ar condicionado' },
  { value: '4322303', name: '4322303 - Instalações de sistema de prevenção contra incêndio' },
  { value: '4329101', name: '4329101 - Instalação de painéis publicitários' },
  {
    value: '4329102',
    name: '4329102 - Instalação de equipamentos para orientação e navegação marítima, fluvial e lacustre'
  },
  { value: '4329103', name: '4329103 - Instalação de escadas e esteiras rolantes' },
  {
    value: '4329104',
    name: '4329104 - Montagem e instalação de sistemas e equipamentos de iluminação e sinalização em vias públicas'
  },
  { value: '4329105', name: '4329105 - Tratamentos térmicos' },
  {
    value: '4329199',
    name: '4329199 - Outras obras de instalações em construções não especificadas anteriormente'
  },
  { value: '4330401', name: '4330401 - Impermeabilização em obras de engenharia civil' },
  {
    value: '4330402',
    name: '4330402 - Instalação de porta-objetos, divisórias e armários embutidos de qualquer material'
  },
  { value: '4330403', name: '4330403 - Obras de acabamento em gesso e estuque' },
  { value: '4330404', name: '4330404 - Serviços de pintura de edifícios em geral' },
  { value: '4330405', name: '4330405 - Aplicação de revestimentos e de resinas em interiores e exteriores' },
  { value: '4330499', name: '4330499 - Outras obras de acabamento da construção' },
  { value: '4391600', name: '4391600 - Obras de fundações' },
  { value: '4399101', name: '4399101 - Administração de obras' },
  { value: '4399102', name: '4399102 - Montagem e desmontagem de andaimes e outras estruturas temporárias' },
  { value: '4399103', name: '4399103 - Obras de alvenaria' },
  {
    value: '4399104',
    name: '4399104 - Serviços de operação e fornecimento de equipamentos para transporte e elevação de cargas e pessoas para uso em obras'
  },
  { value: '4399105', name: '4399105 - Perfuração e construção de poços de água' },
  {
    value: '4399199',
    name: '4399199 - Serviços especializados para construção não especificados anteriormente'
  },
  { value: '4511101', name: '4511101 - Comércio a varejo de automóveis' },
  { value: '4511102', name: '4511102 - Comércio a varejo de automóveis' },
  { value: '4511103', name: '4511103 - Comércio por atacado de automóveis' },
  { value: '4511104', name: '4511104 - Comércio por atacado de caminhões novos e usados' },
  { value: '4511105', name: '4511105 - Comércio por atacado de reboques e semi-reboques novos e usados' },
  { value: '4511106', name: '4511106 - Comércio por atacado de ônibus e microônibus novos e usados' },
  {
    value: '4512901',
    name: '4512901 - Representantes comerciais e agentes do comércio de veículos automotores'
  },
  { value: '4512902', name: '4512902 - Comércio sob consignação de veículos automotores' },
  { value: '4520001', name: '4520001 - Serviços de manutenção e reparação mecânica de veículos automotores' },
  {
    value: '4520002',
    name: '4520002 - Serviços de lanternagem ou funilaria e pintura de veículos automotores'
  },
  { value: '4520003', name: '4520003 - Serviços de manutenção e reparação elétrica de veículos automotores' },
  { value: '4520004', name: '4520004 - Serviços de alinhamento e balanceamento de veículos automotores' },
  { value: '4520005', name: '4520005 - Serviços de lavagem' },
  { value: '4520006', name: '4520006 - Serviços de borracharia para veículos automotores' },
  { value: '4520007', name: '4520007 - Serviços de instalação' },
  { value: '4520008', name: '4520008 - Serviços de capotaria' },
  {
    value: '4530701',
    name: '4530701 - Comércio por atacado de peças e acessórios novos para veículos automotores'
  },
  { value: '4530702', name: '4530702 - Comércio por atacado de pneumáticos e câmaras-de-ar' },
  {
    value: '4530703',
    name: '4530703 - Comércio a varejo de peças e acessórios novos para veículos automotores'
  },
  {
    value: '4530704',
    name: '4530704 - Comércio a varejo de peças e acessórios usados para veículos automotores'
  },
  { value: '4530705', name: '4530705 - Comércio a varejo de pneus e câmaras-de-ar' },
  {
    value: '4530706',
    name: '4530706 - Representantes comerciais e agentes do comércio de peças e acessórios novos e usados para veículos automotores'
  },
  { value: '4541201', name: '4541201 - Comércio por atacado de motocicletas e motonetas' },
  {
    value: '4541202',
    name: '4541202 - Comércio por atacado de peças e acessórios para motocicletas e motonetas'
  },
  { value: '4541203', name: '4541203 - Comércio a varejo de motocicletas e motonetas novas' },
  { value: '4541204', name: '4541204 - Comércio a varejo de motocicletas e motonetas usadas' },
  {
    value: '4541205',
    name: '4541205 - Comércio a varejo de peças e acessórios para motocicletas e motonetas'
  },
  {
    value: '4541206',
    name: '4541206 - Comércio a varejo de peças e acessórios novos para motocicletas e motonetas'
  },
  {
    value: '4541207',
    name: '4541207 - Comércio a varejo de peças e acessórios usados para motocicletas e motonetas'
  },
  {
    value: '4542101',
    name: '4542101 - Representantes comerciais e agentes do comércio de motocicletas e motonetas'
  },
  { value: '4542102', name: '4542102 - Comércio sob consignação de motocicletas e motonetas' },
  { value: '4543900', name: '4543900 - Manutenção e reparação de motocicletas e motonetas' },
  {
    value: '4611700',
    name: '4611700 - Representantes comerciais e agentes do comércio de matérias-primas agrícolas e animais vivos'
  },
  {
    value: '4612500',
    name: '4612500 - Representantes comerciais e agentes do comércio de combustíveis, produtos siderúrgicos e químicos'
  },
  { value: '4613300', name: '4613300 - Representantes comerciais e agentes do comércio de madeira' },
  {
    value: '4614100',
    name: '4614100 - Representantes comerciais e agentes do comércio de máquinas, embarcações e aeronaves'
  },
  { value: '4615000', name: '4615000 - Representantes comerciais e agentes do comércio de eletrodomésticos' },
  {
    value: '4616800',
    name: '4616800 - Representantes comerciais e agentes do comércio de têxteis e artigos de viagem'
  },
  {
    value: '4617600',
    name: '4617600 - Representantes comerciais e agentes do comércio de produtos alimentícios'
  },
  { value: '4618401', name: '4618401 - Representantes comerciais e agentes do comércio de medicamentos' },
  {
    value: '4618402',
    name: '4618402 - Representantes comerciais e agentes do comércio de instrumentos e materiais odonto-médico-hospitalares'
  },
  { value: '4618403', name: '4618403 - Representantes comerciais e agentes do comércio de jornais' },
  {
    value: '4618499',
    name: '4618499 - Outros representantes comerciais e agentes do comércio especializado em produtos não especificados anteriormente'
  },
  {
    value: '4619200',
    name: '4619200 - Representantes comerciais e agentes do comércio de mercadorias em geral não especializado'
  },
  { value: '4621400', name: '4621400 - Comércio atacadista de café em grão' },
  { value: '4622200', name: '4622200 - Comércio atacadista de soja' },
  { value: '4623101', name: '4623101 - Comércio atacadista de animais vivos' },
  {
    value: '4623102',
    name: '4623102 - Comércio atacadista de couros, peles e outros subprodutos não-comestíveis de origem animal'
  },
  { value: '4623103', name: '4623103 - Comércio atacadista de algodão' },
  { value: '4623104', name: '4623104 - Comércio atacadista de fumo em folha não beneficiado' },
  { value: '4623105', name: '4623105 - Comércio atacadista de cacau' },
  { value: '4623106', name: '4623106 - Comércio atacadista de sementes, plantas e gramas' },
  { value: '4623107', name: '4623107 - Comércio atacadista de sisal' },
  {
    value: '4623108',
    name: '4623108 - Comércio atacadista de matérias-primas agrícolas com atividade de fracionamento e acondicionamento associada'
  },
  { value: '4623109', name: '4623109 - Comércio atacadista de alimentos para animais' },
  {
    value: '4623199',
    name: '4623199 - Comércio atacadista de matérias-primas agrícolas não especificadas anteriormente'
  },
  { value: '4631100', name: '4631100 - Comércio atacadista de leite e laticínios' },
  { value: '4632001', name: '4632001 - Comércio atacadista de cereais e leguminosas beneficiados' },
  { value: '4632002', name: '4632002 - Comércio atacadista de farinhas' },
  {
    value: '4632003',
    name: '4632003 - Comércio atacadista de cereais e leguminosas beneficiados, amidos e féculas com atividade de fracionamento e acondicionamento associada'
  },
  {
    value: '4633801',
    name: '4633801 - Comércio atacadista de frutas, raiz, tubérculos, hortaliças e legumes frescos'
  },
  { value: '4633802', name: '4633802 - Comércio atacadista de aves vivas e ovos' },
  {
    value: '4633803',
    name: '4633803 - Comércio atacadista de coelhos e outros pequenos animais vivos para alimentação'
  },
  { value: '4634601', name: '4634601 - Comércio atacadista de carnes bovinas e suínas e derivados' },
  { value: '4634602', name: '4634602 - Comércio atacadista de aves abatidas e derivados' },
  { value: '4634603', name: '4634603 - Comércio atacadista de pescados e frutos do mar' },
  { value: '4634699', name: '4634699 - Comércio atacadista de carnes e derivados de outros animais' },
  { value: '4635401', name: '4635401 - Comércio atacadista de água mineral' },
  { value: '4635402', name: '4635402 - Comércio atacadista de cerveja' },
  {
    value: '4635403',
    name: '4635403 - Comércio atacadista de bebidas com atividade de fracionamento e acondicionamento associada'
  },
  { value: '4635499', name: '4635499 - Comércio atacadista de bebidas não especificadas anteriormente' },
  { value: '4636201', name: '4636201 - Comércio atacadista de fumo beneficiado' },
  { value: '4636202', name: '4636202 - Comércio atacadista de cigarros' },
  { value: '4637101', name: '4637101 - Comércio atacadista de café torrado' },
  { value: '4637102', name: '4637102 - Comércio atacadista de açúcar' },
  { value: '4637103', name: '4637103 - Comércio atacadista de óleos e gorduras' },
  { value: '4637104', name: '4637104 - Comércio atacadista de pães, biscoitos e similares' },
  { value: '4637105', name: '4637105 - Comércio atacadista de massas alimentícias' },
  { value: '4637106', name: '4637106 - Comércio atacadista de sorvetes' },
  { value: '4637107', name: '4637107 - Comércio atacadista de chocolates, balas, bombons e semelhantes' },
  {
    value: '4637199',
    name: '4637199 - Comércio atacadista especializado em outros produtos alimentícios não especificados anteriormente'
  },
  { value: '4639701', name: '4639701 - Comércio atacadista de produtos alimentícios em geral' },
  { value: '4639702', name: '4639702 - Comércio atacadista de produtos alimentícios em geral' },
  { value: '4641901', name: '4641901 - Comércio atacadista de tecidos' },
  { value: '4641902', name: '4641902 - Comércio atacadista de artigos de cama' },
  { value: '4641903', name: '4641903 - Comércio atacadista de artigos de armarinho' },
  { value: '4642701', name: '4642701 - Comércio atacadista de artigos do vestuário e acessórios' },
  {
    value: '4642702',
    name: '4642702 - Comércio atacadista de roupas e acessórios para uso profissional e de segurança do trabalho'
  },
  { value: '4643501', name: '4643501 - Comércio atacadista de calçados' },
  { value: '4643502', name: '4643502 - Comércio atacadista de bolsas' },
  { value: '4644301', name: '4644301 - Comércio atacadista de medicamentos e drogas de uso humano' },
  { value: '4644302', name: '4644302 - Comércio atacadista de medicamentos e drogas de uso veterinário' },
  {
    value: '4645101',
    name: '4645101 - Comércio atacadista de instrumentos e materiais para uso médico-hospitalar e de laboratórios'
  },
  { value: '4645102', name: '4645102 - Comércio atacadista de prosteses e artigos de ortopedia' },
  { value: '4645103', name: '4645103 - Comércio atacadista de produtos odontológicos' },
  { value: '4646001', name: '4646001 - Comércio atacadista de cosméticos e produtos de perfumaria' },
  { value: '4646002', name: '4646002 - Comércio atacadista de produtos de higiene pessoal' },
  { value: '4647801', name: '4647801 - Comércio atacadista de artigos de escritório e de papelaria' },
  { value: '4647802', name: '4647802 - Comércio atacadista de livros' },
  {
    value: '4649401',
    name: '4649401 - Comércio atacadista de equipamentos elétricos de uso pessoal e doméstico'
  },
  {
    value: '4649402',
    name: '4649402 - Comércio atacadista de aparelhos eletrônicos de uso pessoal e doméstico'
  },
  { value: '4649403', name: '4649403 - Comércio atacadista de bicicletas' },
  { value: '4649404', name: '4649404 - Comércio atacadista de móveis e artigos de colchoaria' },
  { value: '4649405', name: '4649405 - Comércio atacadista de artigos de tapeçaria; persianas e cortinas' },
  { value: '4649406', name: '4649406 - Comércio atacadista de lustres' },
  { value: '4649407', name: '4649407 - Comércio atacadista de filmes, DVDs, fitas e discos' },
  { value: '4649408', name: '4649408 - Comércio atacadista de produtos de higiene' },
  {
    value: '4649409',
    name: '4649409 - Comércio atacadista de produtos de higiene com atividade de fracionamento e acondicionamento associada'
  },
  {
    value: '4649410',
    name: '4649410 - Comércio atacadista de jóias, inclusive pedras preciosas e semipreciosas lapidadas'
  },
  {
    value: '4649499',
    name: '4649499 - Comércio atacadista de outros equipamentos e artigos de uso pessoal e doméstico não especificados anteriormente'
  },
  { value: '4651601', name: '4651601 - Comércio atacadista de equipamentos de informática' },
  { value: '4651602', name: '4651602 - Comércio atacadista de suprimentos para informática' },
  {
    value: '4652400',
    name: '4652400 - Comércio atacadista de componentes eletrônicos e equipamentos de telefonia e comunicação'
  },
  { value: '4661300', name: '4661300 - Comércio atacadista de máquinas' },
  {
    value: '4662100',
    name: '4662100 - Comércio atacadista de máquinas, mineração e construção; partes e peças'
  },
  {
    value: '4663000',
    name: '4663000 - Comércio atacadista de Máquinas e equipamentos para uso industrial; partes e peças'
  },
  { value: '4664800', name: '4664800 - Comércio atacadista de máquinas' },
  {
    value: '4665600',
    name: '4665600 - Comércio atacadista de máquinas e equipamentos para uso comercial; partes e peças'
  },
  { value: '4669901', name: '4669901 - Comércio atacadista de bombas e compressores; partes e peças.' },
  {
    value: '4669999',
    name: '4669999 - Comércio atacadista de outras máquinas e equipamentos não especificados anteriormente; partes e peças.'
  },
  { value: '4671100', name: '4671100 - Comércio atacadista de madeira e produtos derivados' },
  { value: '4672900', name: '4672900 - Comércio atacadista de ferragens e ferramentas' },
  { value: '4673700', name: '4673700 - Comércio atacadista de material elétrico' },
  { value: '4674500', name: '4674500 - Comércio atacadista de cimento' },
  { value: '4679601', name: '4679601 - Comércio atacadista de tintas' },
  { value: '4679602', name: '4679602 - Comércio atacadista de mármores e granitos' },
  { value: '4679603', name: '4679603 - Comércio atacadista de vidros vitriais e molduras' },
  {
    value: '4679604',
    name: '4679604 - Comércio atacadista especializado de materiais de construção não especificados anteriormente'
  },
  { value: '4679699', name: '4679699 - Comércio atacadista de materiais de construção em geral' },
  {
    value: '4681801',
    name: '4681801 - Comércio atacadista de álcool carburante, gasolina e demais derivados de petróleo, exceto lubrificantes, não realizado por transportador.'
  },
  {
    value: '4681802',
    name: '4681802 - Comércio atacadista de combustíveis realizado por transportador retalhista (T.R.R.)'
  },
  { value: '4681803', name: '4681803 - Comércio atacadista de combustíveis de origem vegetal' },
  { value: '4681804', name: '4681804 - Comércio atacadista de combustíveis de origem mineral em bruto' },
  { value: '4681805', name: '4681805 - Comércio atacadista de lubrificantes' },
  { value: '4682600', name: '4682600 - Comércio atacadista de gás liquefeito de petróleo (GLP)' },
  {
    value: '4683400',
    name: '4683400 - Comércio atacadista de defensivos agrícolas, fertilizantes e corretivos do solo'
  },
  { value: '4684201', name: '4684201 - Comércio atacadista de resinas e elastômeros' },
  { value: '4684202', name: '4684202 - Comércio atacadista de solventes' },
  {
    value: '4684299',
    name: '4684299 - Comércio atacadista de outros produtos químicos e petroquímicos não especificados anteriormente'
  },
  { value: '4685100', name: '4685100 - Comércio atacadista de produtos siderúrgicos e metalúrgicos' },
  { value: '4686901', name: '4686901 - Comércio atacadista de papel e papelão em bruto' },
  { value: '4686902', name: '4686902 - Comércio atacadista de embalagens' },
  { value: '4687701', name: '4687701 - Comércio atacadista de resíduos de papel e papelão' },
  { value: '4687702', name: '4687702 - Comércio atacadista de resíduos e sucatas não-metálicos' },
  { value: '4687703', name: '4687703 - Comércio atacadista de resíduos e sucatas metálicos' },
  { value: '4689301', name: '4689301 - Comércio atacadista de produtos da extração mineral' },
  { value: '4689302', name: '4689302 - Comércio atacadista de fios e fibras beneficiados' },
  {
    value: '4689399',
    name: '4689399 - Comércio atacadista especializado em outros produtos intermediários não especificados anteriormente'
  },
  { value: '4691500', name: '4691500 - Comércio atacadista de mercadorias em geral' },
  { value: '4692300', name: '4692300 - Comércio atacadista de mercadorias em geral' },
  { value: '4693100', name: '4693100 - Comércio atacadista de mercadorias em geral' },
  { value: '4711301', name: '4711301 - Comércio varejista de mercadorias em geral' },
  { value: '4711302', name: '4711302 - Comércio varejista de mercadorias em geral' },
  { value: '4712100', name: '4712100 - Comércio varejista de mercadorias em geral, mercearias e armazéns' },
  { value: '4713001', name: '4713001 - Lojas de departamentos ou magazines' },
  { value: '4713002', name: '4713002 - Lojas de variedades' },
  { value: '4713003', name: '4713003 - Lojas duty free de aeroportos internacionais' },
  { value: '4713004', name: '4713004 - Lojas de departamentos ou magazines' },
  { value: '4713005', name: '4713005 - Lojas francas (Duty free) de aeroportos' },
  { value: '4721101', name: '4721101 - Padaria e confeitaria com predominância de produção própria' },
  { value: '4721102', name: '4721102 - Padaria e confeitaria com predominância de revenda' },
  { value: '4721103', name: '4721103 - Comércio varejista de laticínios e frios' },
  { value: '4721104', name: '4721104 - Comércio varejista de doces, bombons e semelhantes' },
  { value: '4722901', name: '4722901 - Comércio varejista de carnes - açougues' },
  { value: '4722902', name: '4722902 - Peixaria' },
  { value: '4723700', name: '4723700 - Comércio varejista de bebidas' },
  { value: '4724500', name: '4724500 - Comércio varejista de hortifrutigranjeiros' },
  { value: '4729601', name: '4729601 - Tabacaria' },
  { value: '4729602', name: '4729602 - Comércio varejista de mercadorias em lojas de conveniência' },
  {
    value: '4729699',
    name: '4729699 - Comércio varejista de produtos alimentícios em geral ou especializado em produtos alimentícios não especificados anteriormente'
  },
  { value: '4731800', name: '4731800 - Comércio varejista de combustíveis para veículos automotores' },
  { value: '4732600', name: '4732600 - Comércio varejista de lubrificantes' },
  { value: '4741500', name: '4741500 - Comércio varejista de tintas e materiais para pintura' },
  { value: '4742300', name: '4742300 - Comércio varejista de material elétrico' },
  { value: '4743100', name: '4743100 - Comércio varejista de vidros' },
  { value: '4744001', name: '4744001 - Comércio varejista de ferragens e ferramentas' },
  { value: '4744002', name: '4744002 - Comércio varejista de madeira e artefatos' },
  { value: '4744003', name: '4744003 - Comércio varejista de materiais hidráulicos' },
  { value: '4744004', name: '4744004 - Comércio varejista de calçada britada, tijolos e telhas' },
  {
    value: '4744005',
    name: '4744005 - Comércio varejista de materiais de construção não especificados anteriormente'
  },
  { value: '4744006', name: '4744006 - Comércio varejista de pedras para revestimento' },
  { value: '4744099', name: '4744099 - Comércio varejista de materiais de construção em geral' },
  {
    value: '4751200',
    name: '4751200 - Comércio varejista especializado de equipamentos e suprimentos de informática'
  },
  {
    value: '4751201',
    name: '4751201 - Comércio varejista especializado de equipamentos e suprimentos de informática'
  },
  { value: '4751202', name: '4751202 - Recarga de cartuchos para equipamentos de informática' },
  {
    value: '4752100',
    name: '4752100 - Comércio varejista especializado de equipamentos de telefonia e comunicação'
  },
  {
    value: '4753900',
    name: '4753900 - Comércio varejista especializado de eletrodomésticos e equipamentos de áudio e vídeo'
  },
  { value: '4754701', name: '4754701 - Comércio varejista de móveis' },
  { value: '4754702', name: '4754702 - Comércio varejista de artigos de colchoaria' },
  { value: '4754703', name: '4754703 - Comércio varejista de artigos de iluminação' },
  { value: '4755501', name: '4755501 - Comércio varejista de tecidos' },
  { value: '4755502', name: '4755502 - Comercio varejista de artigos de armarinho' },
  { value: '4755503', name: '4755503 - Comercio varejista de artigos de cama' },
  {
    value: '4756300',
    name: '4756300 - Comércio varejista especializado de instrumentos musicais e acessórios'
  },
  {
    value: '4757100',
    name: '4757100 - Comércio varejista especializado de peças e acessórios para aparelhos eletroeletrônicos para uso doméstico'
  },
  { value: '4759801', name: '4759801 - Comércio varejista de artigos de tapeçaria' },
  {
    value: '4759899',
    name: '4759899 - Comércio varejista de outros artigos de uso pessoal e doméstico não especificados anteriormente'
  },
  { value: '4761001', name: '4761001 - Comércio varejista de livros' },
  { value: '4761002', name: '4761002 - Comércio varejista de jornais e revistas' },
  { value: '4761003', name: '4761003 - Comércio varejista de artigos de papelaria' },
  { value: '4762800', name: '4762800 - Comércio varejista de discos, DVDs e fitas' },
  { value: '4763601', name: '4763601 - Comércio varejista de brinquedos e artigos recreativos' },
  { value: '4763602', name: '4763602 - Comércio varejista de artigos esportivos' },
  { value: '4763603', name: '4763603 - Comércio varejista de bicicletas e triciclos; peças e acessórios' },
  { value: '4763604', name: '4763604 - Comércio varejista de artigos de caça' },
  {
    value: '4763605',
    name: '4763605 - Comércio varejista de embarcações e outros veículos recreativos; peças e acessórios'
  },
  { value: '4771701', name: '4771701 - Comércio varejista de produtos farmacêuticos' },
  { value: '4771702', name: '4771702 - Comércio varejista de produtos farmacêuticos' },
  { value: '4771703', name: '4771703 - Comércio varejista de produtos farmacêuticos homeopáticos' },
  { value: '4771704', name: '4771704 - Comércio varejista de medicamentos veterinários' },
  { value: '4772500', name: '4772500 - Comércio varejista de cosméticos' },
  { value: '4773300', name: '4773300 - Comércio varejista de artigos médicos e ortopédicos' },
  { value: '4774100', name: '4774100 - Comércio varejista de artigos de óptica' },
  { value: '4781400', name: '4781400 - Comércio varejista de artigos do vestuário e acessórios' },
  { value: '4782201', name: '4782201 - Comércio varejista de calçados' },
  { value: '4782202', name: '4782202 - Comércio varejista de artigos de viagem' },
  { value: '4783101', name: '4783101 - Comércio varejista de artigos de joalheria' },
  { value: '4783102', name: '4783102 - Comércio varejista de artigos de relojoaria' },
  { value: '4784900', name: '4784900 - Comércio varejista de gás liquefeito de petróleo (GLP)' },
  { value: '4785701', name: '4785701 - Comércio varejista de antiguidades' },
  { value: '4785799', name: '4785799 - Comércio varejista de outros artigos usados' },
  { value: '4789001', name: '4789001 - Comércio varejista de suvenires' },
  { value: '4789002', name: '4789002 - Comércio varejista de plantas e flores naturais' },
  { value: '4789003', name: '4789003 - Comércio varejista de objetos de arte' },
  {
    value: '4789004',
    name: '4789004 - Comércio varejista de animais vivos e de artigos e alimentos para animais de estimação'
  },
  { value: '4789005', name: '4789005 - Comércio varejista de produtos saneantes domissanitários' },
  { value: '4789006', name: '4789006 - Comércio varejista de fogos de artifício e artigos pirotécnicos' },
  { value: '4789007', name: '4789007 - Comércio varejista de equipamentos para escritório' },
  { value: '4789008', name: '4789008 - Comércio varejista de artigos fotográficos e para filmagem' },
  { value: '4789009', name: '4789009 - Comércio varejista de armas e munições' },
  {
    value: '4789099',
    name: '4789099 - Comércio varejista de outros produtos não especificados anteriormente'
  },
  { value: '4911600', name: '4911600 - Transporte ferroviário de carga' },
  {
    value: '4912401',
    name: '4912401 - Transporte ferroviário de passageiros intermunicipal e interestadual'
  },
  {
    value: '4912402',
    name: '4912402 - Transporte ferroviário de passageiros municipal e em região metropolitana'
  },
  { value: '4912403', name: '4912403 - Transporte metroviário' },
  { value: '4921301', name: '4921301 - Transporte rodoviário coletivo de passageiros municipal' },
  {
    value: '4921302',
    name: '4921302 - Transporte rodoviário coletivo de passageiros intermunicipal em região metropolitana'
  },
  {
    value: '4922101',
    name: '4922101 - Transporte rodoviário coletivo de passageiros intermunicipal exceto em região metropolitana'
  },
  { value: '4922102', name: '4922102 - Transporte rodoviário coletivo de passageiros interestadual' },
  { value: '4922103', name: '4922103 - Transporte rodoviário coletivo de passageiros internacional' },
  { value: '4923001', name: '4923001 - Serviço de táxi' },
  {
    value: '4923002',
    name: '4923002 - Serviço de transporte de passageiros - locação de automóveis com motorista'
  },
  { value: '4924800', name: '4924800 - Transporte escolar' },
  { value: '4929901', name: '4929901 - Transporte rodoviário coletivo de passageiros municipal' },
  {
    value: '4929902',
    name: '4929902 - Transporte rodoviário coletivo de passageiros intermunicipal interestadual e internacional'
  },
  { value: '4929903', name: '4929903 - Organização de excursões em veículos rodoviários próprios' },
  {
    value: '4929904',
    name: '4929904 - Organização de excursões em veículos rodoviários próprios interestadual e internacional'
  },
  {
    value: '4929999',
    name: '4929999 - Outros transportes rodoviários de passageiros não especificados anteriormente'
  },
  { value: '4930201', name: '4930201 - Transporte rodoviário de carga municipal.' },
  {
    value: '4930202',
    name: '4930202 - Transporte rodoviário de carga intermunicipal, interestadual e internacional'
  },
  { value: '4930203', name: '4930203 - Transporte rodoviário de produtos perigosos' },
  { value: '4930204', name: '4930204 - Transporte rodoviário de mudanças' },
  { value: '4940000', name: '4940000 - Transporte dutoviário' },
  { value: '4950700', name: '4950700 - Trens turísticos' },
  { value: '5011401', name: '5011401 - Transporte marítimo de cabotagem - Carga' },
  { value: '5011402', name: '5011402 - Transporte marítimo de cabotagem - passageiros' },
  { value: '5012201', name: '5012201 - Transporte marítimo de longo curso - Carga' },
  { value: '5012202', name: '5012202 - Transporte marítimo de longo curso - Passageiros' },
  { value: '5021101', name: '5021101 - Transporte por navegação interior de carga exceto travessia' },
  {
    value: '5021102',
    name: '5021102 - Transporte por navegação interior de carga interestadual e internacional, exceto travessia'
  },
  {
    value: '5022001',
    name: '5022001 - Transporte por navegação interior de passageiros em linhas regulares exceto travessia'
  },
  {
    value: '5022002',
    name: '5022002 - Transporte por navegação interior de passageiros em linhas regulares interestaduais e internacionais, exceto travessia.'
  },
  { value: '5030101', name: '5030101 - Navegação de apoio marítimo' },
  { value: '5030102', name: '5030102 - Navegação de apoio portuário' },
  { value: '5030103', name: '5030103 - Serviço de rebocadores e empurradores' },
  { value: '5091201', name: '5091201 - Transporte por navegação de travessia' },
  { value: '5091202', name: '5091202 - Transporte por navegação de travessia intermunicipal' },
  { value: '5099801', name: '5099801 - Transporte aquaviário para passeios turísticos' },
  { value: '5099899', name: '5099899 - Outros transportes aquaviários não especificados anteriormente' },
  { value: '5111100', name: '5111100 - Transporte aéreo de passageiros regular' },
  { value: '5112901', name: '5112901 - Serviço de táxi aéreo e locação de aeronaves com tripulação' },
  { value: '5112999', name: '5112999 - Outros serviços de transporte aéreo de passageiros não-regular' },
  { value: '5120000', name: '5120000 - Transporte aéreo de carga' },
  { value: '5130700', name: '5130700 - Transporte espacial' },
  { value: '5211701', name: '5211701 - Armazéns gerais - emissão de warrant' },
  { value: '5211702', name: '5211702 - Guarda-móveis' },
  { value: '5211799', name: '5211799 - Depósitos de mercadorias para terceiros' },
  { value: '5212500', name: '5212500 - Carga e descarga' },
  { value: '5221400', name: '5221400 - Concessões rodoviárias e serviços relacionados' },
  { value: '5222200', name: '5222200 - Terminais rodoviários e ferroviários' },
  { value: '5223100', name: '5223100 - Estacionamento de veículos' },
  { value: '5229001', name: '5229001 - Serviços de apoio ao transporte por táxi' },
  { value: '5229002', name: '5229002 - Serviços de reboque de veículos' },
  {
    value: '5229099',
    name: '5229099 - Outras atividades auxiliares dos transportes terrestres não especificadas anteriormente'
  },
  { value: '5231101', name: '5231101 - Administração da infraestrutura portuária' },
  { value: '5231102', name: '5231102 - Atividades do Operador Portuário' },
  { value: '5231103', name: '5231103 - Gestão de terminais aquaviários' },
  { value: '5232000', name: '5232000 - Atividades de agenciamento marítimo' },
  {
    value: '5239700',
    name: '5239700 - Atividades auxiliares dos transportes aquaviários não especificadas anteriormente'
  },
  { value: '5239701', name: '5239701 - Serviços de praticagem' },
  {
    value: '5239799',
    name: '5239799 - Atividades auxiliares dos transportes aquaviários não especificadas anteriormente'
  },
  { value: '5240101', name: '5240101 - Operação dos aeroportos e campos de aterrissagem' },
  { value: '5240199', name: '5240199 - Atividades auxiliares dos transportes aéreos' },
  { value: '5250801', name: '5250801 - Comissaria de despachos' },
  { value: '5250802', name: '5250802 - Atividades de despachantes aduaneiros' },
  { value: '5250803', name: '5250803 - Agenciamento de cargas' },
  { value: '5250804', name: '5250804 - Organização logística do transporte de carga' },
  { value: '5250805', name: '5250805 - Operador de transporte multimodal - OTM' },
  { value: '5310501', name: '5310501 - Atividades do Correio Nacional' },
  { value: '5310502', name: '5310502 - Atividades de franqueadas do Correio Nacional' },
  { value: '5320201', name: '5320201 - Serviços de malote não realizados pelo Correio Nacional' },
  { value: '5320202', name: '5320202 - Serviços de entrega rápida' },
  { value: '5510801', name: '5510801 - Hotéis' },
  { value: '5510802', name: '5510802 - Apart-hotel' },
  { value: '5510803', name: '5510803 - Motociclistas' },
  { value: '5590601', name: '5590601 - Albergues' },
  { value: '5590602', name: '5590602 - Campings' },
  { value: '5590603', name: '5590603 - Pensees (alojamento)' },
  { value: '5590699', name: '5590699 - Outros alojamentos não especificados anteriormente' },
  { value: '5611201', name: '5611201 - Restaurantes e similares' },
  { value: '5611202', name: '5611202 - Bares e outros estabelecimentos especializados em servir bebidas' },
  { value: '5611203', name: '5611203 - Lanchonetesde sucos e similares"' },
  { value: '5611204', name: '5611204 - Bares e outros estabelecimentos especializados em servir bebidas' },
  { value: '5611205', name: '5611205 - Bares e outros estabelecimentos especializados em servir bebidas' },
  { value: '5612100', name: '5612100 - Serviços ambulantes de alimentação' },
  {
    value: '5620101',
    name: '5620101 - Fornecimento de alimentos preparados preponderantemente para empresas'
  },
  { value: '5620102', name: '5620102 - Serviços de alimentação para eventos e recepções - bufê' },
  { value: '5620103', name: '5620103 - Cantinas - serviços de alimentação privativos' },
  {
    value: '5620104',
    name: '5620104 - Fornecimento de alimentos preparados preponderantemente para consumo domiciliar'
  },
  { value: '5811500', name: '5811500 - Edição de livros' },
  { value: '5812300', name: '5812300 - Edição de jornais' },
  { value: '5812301', name: '5812301 - Edição de jornais diários' },
  { value: '5812302', name: '5812302 - Edição de jornais não diários' },
  { value: '5813100', name: '5813100 - Edição de revistas' },
  { value: '5819100', name: '5819100 - Edição de cadastros' },
  { value: '5821200', name: '5821200 - Edição integrada à impressão de livros' },
  { value: '5822100', name: '5822100 - Edição integrada à impressão de jornais' },
  { value: '5822101', name: '5822101 - Edição integrada na impressão de jornais diários' },
  { value: '5822102', name: '5822102 - Edição integrada à impressão de jornais não diários' },
  { value: '5823900', name: '5823900 - Edição integrada à impressão de revistas' },
  { value: '5829800', name: '5829800 - Edição integrada na impressão de cadastros' },
  { value: '5911101', name: '5911101 - Estúdios cinematográficos' },
  { value: '5911102', name: '5911102 - Produção de filmes para publicidade' },
  { value: '5911199', name: '5911199 - Atividades de produção cinematográfica' },
  { value: '5912001', name: '5912001 - Serviços de dublagem' },
  { value: '5912002', name: '5912002 - Serviços de mixagem sonora em produção audiovisual' },
  { value: '5912099', name: '5912099 - Atividades de pós-produção cinematográfica' },
  { value: '5913800', name: '5913800 - Distribuição cinematográfica' },
  { value: '5914600', name: '5914600 - Atividades de exibição cinematográfica' },
  { value: '5920100', name: '5920100 - Atividades de gravação de som e de edição de música' },
  { value: '6010100', name: '6010100 - Atividades de rádio' },
  { value: '6021700', name: '6021700 - Atividades de televisão aberta' },
  { value: '6022501', name: '6022501 - Programadoras' },
  { value: '6022502', name: '6022502 - Atividades relacionadas à televisão por assinatura' },
  { value: '6110801', name: '6110801 - Serviços de telefonia fixa comutada - STFC' },
  { value: '6110802', name: '6110802 - Serviços de redes de transportes de telecomunicações - SRTT' },
  { value: '6110803', name: '6110803 - Serviços de comunicação multimídia - SCM' },
  {
    value: '6110899',
    name: '6110899 - Serviços de telecomunicações por fio não especificados anteriormente'
  },
  { value: '6120501', name: '6120501 - Telefonia móvel celular' },
  { value: '6120502', name: '6120502 - Serviço móvel especializado - SME' },
  {
    value: '6120599',
    name: '6120599 - Serviços de telecomunicações sem fio não especificados anteriormente'
  },
  { value: '6130200', name: '6130200 - Telecomunicações por satélite' },
  { value: '6141800', name: '6141800 - Operadoras de televisão por assinatura por cabo' },
  { value: '6142600', name: '6142600 - Operadoras de televisão por assinatura por microondas' },
  { value: '6143400', name: '6143400 - Operadoras de televisão por assinatura por satélite' },
  { value: '6190601', name: '6190601 - Provedores de acesso às redes de comunicações' },
  { value: '6190602', name: '6190602 - Provedores de voz sobre protocolo internet - VOIP' },
  {
    value: '6190699',
    name: '6190699 - Outras atividades de telecomunicações não especificadas anteriormente'
  },
  { value: '6201500', name: '6201500 - Desenvolvimento de programas de computador sob encomenda' },
  { value: '6201501', name: '6201501 - Desenvolvimento de programas de computador sob encomenda' },
  { value: '6201502', name: '6201502 - Web design' },
  {
    value: '6202300',
    name: '6202300 - Desenvolvimento e licenciamento de programas de computador customizáveis'
  },
  {
    value: '6203100',
    name: '6203100 - Desenvolvimento e licenciamento de programas de computador não-customizáveis'
  },
  { value: '6204000', name: '6204000 - Consultoria em tecnologia da informação' },
  { value: '6209100', name: '6209100 - Suporte técnico' },
  { value: '6311900', name: '6311900 - Tratamento de dados' },
  { value: '6319400', name: '6319400 - Portais' },
  { value: '6391700', name: '6391700 - Agências de notícias' },
  {
    value: '6399200',
    name: '6399200 - Outras atividades de prestação de serviços de informação não especificadas anteriormente'
  },
  { value: '6410700', name: '6410700 - Banco Central' },
  { value: '6421200', name: '6421200 - Bancos comerciais' },
  { value: '6422100', name: '6422100 - Bancos múltiplos' },
  { value: '6423900', name: '6423900 - Caixas econômicas' },
  { value: '6424701', name: '6424701 - Bancos cooperativos' },
  { value: '6424702', name: '6424702 - Cooperativas centrais de crédito' },
  { value: '6424703', name: '6424703 - Cooperativas de crédito mútuo' },
  { value: '6424704', name: '6424704 - Cooperativas de crédito rural' },
  { value: '6431000', name: '6431000 - Bancos múltiplos' },
  { value: '6432800', name: '6432800 - Bancos de investimento' },
  { value: '6433600', name: '6433600 - Bancos de desenvolvimento' },
  { value: '6434400', name: '6434400 - Agências de fomento' },
  { value: '6435201', name: '6435201 - Sociedades de crédito imobiliário' },
  { value: '6435202', name: '6435202 - Associações de poupança e empréstimo' },
  { value: '6435203', name: '6435203 - Companhias hipotecárias' },
  { value: '6436100', name: '6436100 - Sociedades de crédito' },
  { value: '6437900', name: '6437900 - Sociedades de crédito ao microempreendedor' },
  { value: '6438701', name: '6438701 - Bancos de câmbio' },
  {
    value: '6438799',
    name: '6438799 - Outras instituições de intermediação não-monetária não especificadas anteriormente'
  },
  { value: '6440900', name: '6440900 - Arrendamento mercantil' },
  { value: '6450600', name: '6450600 - Sociedades de capitalização' },
  { value: '6461100', name: '6461100 - Holdings de instituições financeiras' },
  { value: '6462000', name: '6462000 - Holdings de instituições não-financeiras' },
  { value: '6463800', name: '6463800 - Outras sociedades de participação' },
  { value: '6470101', name: '6470101 - Fundos de investimento' },
  { value: '6470102', name: '6470102 - Fundos de investimento previdenciários' },
  { value: '6470103', name: '6470103 - Fundos de investimento imobiliários' },
  { value: '6491300', name: '6491300 - Sociedades de fomento mercantil - factoring' },
  { value: '6492100', name: '6492100 - Securitização de créditos' },
  { value: '6493000', name: '6493000 - Administração de consórcios para aquisição de bens e direitos' },
  { value: '6499901', name: '6499901 - Clubes de investimento' },
  { value: '6499902', name: '6499902 - Sociedades de investimento' },
  { value: '6499903', name: '6499903 - Fundo garantidor de crédito' },
  { value: '6499904', name: '6499904 - Caixas de financiamento de corporações' },
  { value: '6499905', name: '6499905 - Concessão de crédito pelas OSCIP' },
  {
    value: '6499999',
    name: '6499999 - Outras atividades de serviços financeiros não especificadas anteriormente'
  },
  { value: '6511101', name: '6511101 - Sociedade seguradora de seguros vida' },
  { value: '6511102', name: '6511102 - Planos de auxílio-funeral' },
  { value: '6512000', name: '6512000 - Sociedade seguradora de seguros não vida' },
  { value: '6520100', name: '6520100 - Sociedade seguradora de seguros saúde' },
  { value: '6530800', name: '6530800 - Resseguros' },
  { value: '6541300', name: '6541300 - Previdência complementar fechada' },
  { value: '6542100', name: '6542100 - Previdência complementar aberta' },
  { value: '6550200', name: '6550200 - Planos de saúde' },
  { value: '6611801', name: '6611801 - Bolsa de valores' },
  { value: '6611802', name: '6611802 - Bolsa de mercadorias' },
  { value: '6611803', name: '6611803 - Bolsa de mercadorias e futuros' },
  { value: '6611804', name: '6611804 - Administração de mercados de balcão organizados' },
  { value: '6612601', name: '6612601 - Corretoras de títulos e valores mobiliários' },
  { value: '6612602', name: '6612602 - Distribuidoras de títulos e valores mobiliários' },
  { value: '6612603', name: '6612603 - Corretoras de câmbio' },
  { value: '6612604', name: '6612604 - Corretoras de contratos de mercadorias' },
  { value: '6612605', name: '6612605 - Agentes de investimentos em aplicações financeiras' },
  { value: '6613400', name: '6613400 - Administração de cartões de crédito' },
  { value: '6619301', name: '6619301 - Serviços de liquidação e custódia' },
  { value: '6619302', name: '6619302 - Correspondentes de instituições financeiras' },
  { value: '6619303', name: '6619303 - Representações de bancos estrangeiros' },
  { value: '6619304', name: '6619304 - Caixas eletrônicos' },
  { value: '6619305', name: '6619305 - Operadoras de cartões de débito' },
  {
    value: '6619399',
    name: '6619399 - Outras atividades auxiliares dos serviços financeiros não especificadas anteriormente'
  },
  { value: '6621501', name: '6621501 - Peritos e avaliadores de seguros' },
  { value: '6621502', name: '6621502 - Auditoria e consultoria atuarial' },
  { value: '6622300', name: '6622300 - Corretores e agentes de seguros' },
  { value: '6629100', name: '6629100 - Atividades auxiliares dos seguros' },
  { value: '6630400', name: '6630400 - Atividades de administração de fundos por contrato ou comissão' },
  { value: '6810201', name: '6810201 - Compra e venda de imóveis próprios' },
  { value: '6810202', name: '6810202 - Aluguel de imóveis próprios' },
  { value: '6810203', name: '6810203 - Loteamento de imóveis próprios' },
  { value: '6821801', name: '6821801 - Corretagem na compra e venda e avaliação de imóveis' },
  { value: '6821802', name: '6821802 - Corretagem no aluguel de imóveis' },
  { value: '6822600', name: '6822600 - Gestão e administração da propriedade imobiliária' },
  { value: '6911701', name: '6911701 - Serviços advocatícios' },
  { value: '6911702', name: '6911702 - Atividades auxiliares da justiça' },
  { value: '6911703', name: '6911703 - Agente de propriedade industrial' },
  { value: '6912500', name: '6912500 - Cartórios' },
  { value: '6920601', name: '6920601 - Atividades de contabilidade' },
  { value: '6920602', name: '6920602 - Atividades de consultoria e auditoria contábil e tributária' },
  { value: '7020400', name: '7020400 - Atividades de consultoria em gestão empresarial' },
  { value: '7111100', name: '7111100 - Serviços de arquitetura' },
  { value: '7112000', name: '7112000 - Serviços de engenharia' },
  { value: '7119701', name: '7119701 - Serviços de cartografia' },
  { value: '7119702', name: '7119702 - Atividades de estudos geológicos' },
  { value: '7119703', name: '7119703 - Serviços de desenho técnico relacionados à arquitetura e engenharia' },
  { value: '7119704', name: '7119704 - Serviços de perícia técnica relacionados à segurança do trabalho' },
  {
    value: '7119799',
    name: '7119799 - Atividades técnicas relacionadas à engenharia e arquitetura não especificadas anteriormente'
  },
  { value: '7120100', name: '7120100 - Testes e análises técnicas' },
  {
    value: '7210000',
    name: '7210000 - Pesquisa e desenvolvimento experimental em ciências físicas e naturais'
  },
  {
    value: '7220700',
    name: '7220700 - Pesquisa e desenvolvimento experimental em ciências sociais e humanas'
  },
  { value: '7311400', name: '7311400 - Agências de publicidade' },
  { value: '7312200', name: '7312200 - Agenciamento de espaços para publicidade' },
  { value: '7319001', name: '7319001 - Criação de estandes para feiras e exposições' },
  { value: '7319002', name: '7319002 - Promoção de vendas' },
  { value: '7319003', name: '7319003 - Marketing direto' },
  { value: '7319004', name: '7319004 - Consultoria em publicidade' },
  { value: '7319099', name: '7319099 - Outras atividades de publicidade não especificadas anteriormente' },
  { value: '7320300', name: '7320300 - Pesquisas de mercado e de opinião pública' },
  { value: '7410201', name: '7410201 - Design' },
  { value: '7410202', name: '7410202 - Design de interiores' },
  { value: '7410203', name: '7410203 - Design de produto' },
  { value: '7410299', name: '7410299 - atividades de design não especificadas anteriormente' },
  { value: '7420001', name: '7420001 - Atividades de produção de fotografias' },
  { value: '7420002', name: '7420002 - Atividades de produção de fotografias aéreas e submarinas' },
  { value: '7420003', name: '7420003 - Laboratórios fotográficos' },
  { value: '7420004', name: '7420004 - Filmagem de festas e eventos' },
  { value: '7420005', name: '7420005 - Serviços de microfilmagem' },
  { value: '7490101', name: '7490101 - Serviços de tradução' },
  { value: '7490102', name: '7490102 - Escafandria e mergulho' },
  {
    value: '7490103',
    name: '7490103 - Serviços de agronomia e de consultoria às atividades agrícolas e pecuárias'
  },
  {
    value: '7490104',
    name: '7490104 - Atividades de intermediação e agenciamento de serviços e negócios em geral'
  },
  { value: '7490105', name: '7490105 - Agenciamento de profissionais para atividades esportivas' },
  { value: '7490199', name: '7490199 - Outras atividades profissionais' },
  { value: '7500100', name: '7500100 - Atividades veterinárias' },
  { value: '7711000', name: '7711000 - Locação de automóveis sem condutor' },
  { value: '7719501', name: '7719501 - Locação de embarcações sem tripulação' },
  { value: '7719502', name: '7719502 - Locação de aeronaves sem tripulação' },
  {
    value: '7719599',
    name: '7719599 - Locação de outros meios de transporte não especificados anteriormente'
  },
  { value: '7721700', name: '7721700 - Aluguel de equipamentos recreativos e esportivos' },
  { value: '7722500', name: '7722500 - Aluguel de fitas de vídeo' },
  { value: '7723300', name: '7723300 - Aluguel de objetos do vestuário' },
  { value: '7729201', name: '7729201 - Aluguel de aparelhos de jogos eletrônicos' },
  { value: '7729202', name: '7729202 - Aluguel de móveis' },
  { value: '7729203', name: '7729203 - Aluguel de material médico' },
  {
    value: '7729299',
    name: '7729299 - Aluguel de outros objetos pessoais e domésticos não especificados anteriormente'
  },
  { value: '7731400', name: '7731400 - Aluguel de máquinas e equipamentos agrícolas sem operador' },
  { value: '7732201', name: '7732201 - Aluguel de máquinas e equipamentos para construção sem operador' },
  { value: '7732202', name: '7732202 - Aluguel de andaimes' },
  { value: '7733100', name: '7733100 - Aluguel de máquinas e equipamentos para escritórios' },
  {
    value: '7739001',
    name: '7739001 - Aluguel de máquinas e equipamentos para extração de minérios e petróleo'
  },
  { value: '7739002', name: '7739002 - Aluguel de equipamentos científicos sem operador' },
  { value: '7739003', name: '7739003 - Aluguel de palcosexceto andaimes"' },
  {
    value: '7739099',
    name: '7739099 - Aluguel de outras máquinas e equipamentos comerciais e industriais não especificados anteriormente'
  },
  { value: '7740300', name: '7740300 - Gestão de ativos intangíveis não-financeiros' },
  { value: '7810800', name: '7810800 - Seleção e agenciamento de mão-de-obra' },
  { value: '7820500', name: '7820500 - Locação de mão-de-obra temporária' },
  { value: '7830200', name: '7830200 - Fornecimento e gestão de recursos humanos para terceiros' },
  { value: '7911200', name: '7911200 - Agências de viagens' },
  { value: '7912100', name: '7912100 - Operadores turísticos' },
  {
    value: '7990200',
    name: '7990200 - Serviços de reservas e outros serviços de turismo não especificados anteriormente'
  },
  { value: '8011101', name: '8011101 - Atividades de vigilância e segurança privada' },
  { value: '8011102', name: '8011102 - Serviços de adestramento de cães de guarda' },
  { value: '8012900', name: '8012900 - Atividades de transporte de valores' },
  { value: '8020000', name: '8020000 - Atividades de monitoramento de sistemas de segurança' },
  { value: '8020001', name: '8020001 - Atividades de monitoramento de sistemas de segurança eletrônica' },
  { value: '8020002', name: '8020002 - Outras atividades de serviços de segurança' },
  { value: '8030700', name: '8030700 - Atividades de investigação particular' },
  { value: '8111700', name: '8111700 - Serviços combinados para apoio a edifícios' },
  { value: '8112500', name: '8112500 - Condomínios prediais' },
  { value: '8121400', name: '8121400 - Limpeza em prédios e em domicílios' },
  { value: '8122200', name: '8122200 - Imunização e controle de pragas urbanas' },
  { value: '8129000', name: '8129000 - Atividades de limpeza não especificadas anteriormente' },
  { value: '8130300', name: '8130300 - Atividades paisagísticas' },
  { value: '8211300', name: '8211300 - Serviços combinados de escritório e apoio administrativo' },
  { value: '8219901', name: '8219901 - Fotocópias' },
  {
    value: '8219999',
    name: '8219999 - Preparação de documentos e serviços especializados de apoio administrativo não especificados anteriormente'
  },
  { value: '8220200', name: '8220200 - Atividades de teleatendimento' },
  { value: '8230001', name: '8230001 - Serviços de organização de feiras, exposições e festas' },
  { value: '8230002', name: '8230002 - Casas de festas e eventos' },
  { value: '8291100', name: '8291100 - Atividades de cobranças e informações cadastrais' },
  { value: '8292000', name: '8292000 - Envasamento e empacotamento sob contrato' },
  { value: '8299701', name: '8299701 - Medição de consumo de energia elétrica' },
  { value: '8299702', name: '8299702 - Emissão de vales-alimentação' },
  { value: '8299703', name: '8299703 - Serviços de gravação de carimbos' },
  { value: '8299704', name: '8299704 - Leiloeiros independentes' },
  { value: '8299705', name: '8299705 - Serviços de levantamento de fundos sob contrato' },
  { value: '8299706', name: '8299706 - Casas lotéricas' },
  { value: '8299707', name: '8299707 - Salas de acesso à internet' },
  {
    value: '8299799',
    name: '8299799 - Outras atividades de serviços prestados principalmente às empresas não especificadas anteriormente'
  },
  { value: '8411600', name: '8411600 - Administração pública em geral' },
  {
    value: '8412400',
    name: '8412400 - Regulamento das atividades de saúde, serviços culturais e outros serviços sociais'
  },
  { value: '8413200', name: '8413200 - Regulação das atividades econômicas' },
  { value: '8421300', name: '8421300 - Relações exteriores' },
  { value: '8422100', name: '8422100 - Defesa' },
  { value: '8423000', name: '8423000 - Justiça' },
  { value: '8424800', name: '8424800 - Segurança e ordem pública' },
  { value: '8425600', name: '8425600 - Defesa Civil' },
  { value: '8430200', name: '8430200 - Seguridade social obrigatória' },
  { value: '8511200', name: '8511200 - Educação infantil - creche' },
  { value: '8512100', name: '8512100 - Educação infantil - pré-escola' },
  { value: '8513900', name: '8513900 - Ensino fundamental' },
  { value: '8520100', name: '8520100 - Ensino médio' },
  { value: '8531700', name: '8531700 - Educação superior - graduação' },
  { value: '8532500', name: '8532500 - Educação superior - graduação e pós-graduação' },
  { value: '8533300', name: '8533300 - Educação superior - pós-graduação e extensão' },
  { value: '8541400', name: '8541400 - Educação profissional de nível técnico' },
  { value: '8542200', name: '8542200 - Educação profissional de nível tecnológico' },
  { value: '8550301', name: '8550301 - Administração de caixas escolares' },
  { value: '8550302', name: '8550302 - Atividades de apoio à educação' },
  { value: '8591100', name: '8591100 - Ensino de esportes' },
  { value: '8592901', name: '8592901 - Ensino de dança' },
  { value: '8592902', name: '8592902 - Ensino de artes cênicas' },
  { value: '8592903', name: '8592903 - Ensino de música' },
  { value: '8592999', name: '8592999 - Ensino de arte e cultura não especificado anteriormente' },
  { value: '8593700', name: '8593700 - Ensino de idiomas' },
  { value: '8599601', name: '8599601 - Formação de condutores' },
  { value: '8599602', name: '8599602 - Cursos de pilotagem' },
  { value: '8599603', name: '8599603 - Treinamento em informática' },
  { value: '8599604', name: '8599604 - Treinamento em desenvolvimento profissional e gerencial' },
  { value: '8599605', name: '8599605 - Cursos preparatórios para concursos' },
  { value: '8599699', name: '8599699 - Outras atividades de ensino não especificadas anteriormente' },
  { value: '8610101', name: '8610101 - Atividades de atendimento hospitalar' },
  {
    value: '8610102',
    name: '8610102 - Atividades de atendimento em pronto-socorro e unidades hospitalares para atendimento a urgências'
  },
  { value: '8621601', name: '8621601 - UTI móvel' },
  { value: '8621602', name: '8621602 - Serviços móveis de atendimento a urgências' },
  { value: '8622400', name: '8622400 - Serviços de remoção de pacientes' },
  {
    value: '8630501',
    name: '8630501 - Atividade médica ambulatorial com recursos para realização de procedimentos cirúrgicos'
  },
  {
    value: '8630502',
    name: '8630502 - Atividade médica ambulatorial com recursos para realização de exames complementares'
  },
  { value: '8630503', name: '8630503 - Atividade médica ambulatorial restrita a consultas' },
  { value: '8630504', name: '8630504 - Atividade odontológica' },
  {
    value: '8630505',
    name: '8630505 - Atividade odontológica sem recursos para realização de procedimentos cirúrgicos'
  },
  { value: '8630506', name: '8630506 - Serviços de vacinação e imunização humana' },
  { value: '8630507', name: '8630507 - Atividades de reprodução humana assistida' },
  { value: '8630599', name: '8630599 - Atividades de atenção ambulatorial não especificadas anteriormente' },
  { value: '8640201', name: '8640201 - Laboratórios de anatomia patológica e citológica' },
  { value: '8640202', name: '8640202 - Laboratórios clínicos' },
  { value: '8640203', name: '8640203 - Serviços de diálise e nefrologia' },
  { value: '8640204', name: '8640204 - Serviços de tomografia' },
  { value: '8640205', name: '8640205 - Serviços de diagnóstico por imagem com uso de radiação ionizante' },
  { value: '8640206', name: '8640206 - Serviços de ressonância magnética' },
  { value: '8640207', name: '8640207 - Serviços de diagnóstico por imagem sem uso de radiação ionizante' },
  { value: '8640208', name: '8640208 - Serviços de diagnóstico por registro gráfico - ECG' },
  {
    value: '8640209',
    name: '8640209 - Serviços de diagnóstico por métodos ópticos - endoscopia e outros exames análogos'
  },
  { value: '8640210', name: '8640210 - Serviços de quimioterapia' },
  { value: '8640211', name: '8640211 - Serviços de radioterapia' },
  { value: '8640212', name: '8640212 - Serviços de hemoterapia' },
  { value: '8640213', name: '8640213 - Serviços de litotripsia' },
  { value: '8640214', name: '8640214 - Serviços de bancos de células e tecidos humanos' },
  {
    value: '8640299',
    name: '8640299 - Atividades de serviços de complementação diagnóstica e terapêutica não especificadas anteriormente'
  },
  { value: '8650001', name: '8650001 - Atividades de enfermagem' },
  { value: '8650002', name: '8650002 - Atividades de profissionais da nutrição' },
  { value: '8650003', name: '8650003 - Atividades de psicologia e psicanálise' },
  { value: '8650004', name: '8650004 - Atividades de fisioterapia' },
  { value: '8650005', name: '8650005 - Atividades de terapia ocupacional' },
  { value: '8650006', name: '8650006 - Atividades de fonoaudiologia' },
  { value: '8650007', name: '8650007 - Atividades de terapia de nutrição enteral e parenteral' },
  {
    value: '8650099',
    name: '8650099 - Atividades de profissionais da área de saúde não especificadas anteriormente'
  },
  { value: '8660700', name: '8660700 - Atividades de apoio à gestão de saúde' },
  {
    value: '8690901',
    name: '8690901 - Atividades de práticas integrativas e complementares em saúde humana'
  },
  { value: '8690902', name: '8690902 - Atividades de banco de leite humano' },
  { value: '8690903', name: '8690903 - Atividades de acupuntura' },
  { value: '8690904', name: '8690904 - Atividades de podologia' },
  {
    value: '8690999',
    name: '8690999 - Outras atividades de atenção à saúde humana não especificadas anteriormente'
  },
  { value: '8711501', name: '8711501 - Clínicas e residências geriátricas' },
  { value: '8711502', name: '8711502 - Instituições de longa permanência para idosos' },
  { value: '8711503', name: '8711503 - Atividades de assistência a deficientes físicos' },
  { value: '8711504', name: '8711504 - Centros de apoio a pacientes com câncer e com AIDS' },
  { value: '8711505', name: '8711505 - Condomínios residenciais para idosos e deficientes físicos' },
  {
    value: '8712300',
    name: '8712300 - Atividades de fornecimento de infraestrutura de apoio e assistência a paciente no domicílio'
  },
  { value: '8720401', name: '8720401 - Atividades de centros de assistência psicossocial' },
  {
    value: '8720499',
    name: '8720499 - Atividades de assistência psicossocial e saúde a portadores de distúrbios psíquicos'
  },
  { value: '8730101', name: '8730101 - Orfanatos' },
  { value: '8730102', name: '8730102 - Albergues assistenciais' },
  {
    value: '8730199',
    name: '8730199 - Atividades de assistência social prestadas em residências coletivas e particulares não especificadas anteriormente'
  },
  { value: '8800600', name: '8800600 - Serviços de assistência social sem alojamento' },
  { value: '9001901', name: '9001901 - Produção teatral' },
  { value: '9001902', name: '9001902 - Produção musical' },
  { value: '9001903', name: '9001903 - Produção de espetáculos de dança' },
  { value: '9001904', name: '9001904 - Produção de espetáculos circenses' },
  { value: '9001905', name: '9001905 - Produção de espetáculos de rodeios' },
  { value: '9001906', name: '9001906 - Atividades de sonorização e de iluminação' },
  { value: '9001999', name: '9001999 - Artes cínicas' },
  { value: '9002701', name: '9002701 - Atividades de artistas plásticos' },
  { value: '9002702', name: '9002702 - Restauro de obras de arte' },
  { value: '9003500', name: '9003500 - Gestão de espaços para artes cênicas' },
  { value: '9101500', name: '9101500 - Atividades de bibliotecas e arquivos' },
  {
    value: '9102301',
    name: '9102301 - Atividades de museus e de exploração de lugares e prédios históricos e atrações similares'
  },
  { value: '9102302', name: '9102302 - Restauro e conservação de lugares e prédios históricos' },
  {
    value: '9103100',
    name: '9103100 - Atividades de jardins botânicos, parques nacionais, reservas ecológicas e áreas de proteção ambiental'
  },
  { value: '9200301', name: '9200301 - Casas de bingo' },
  { value: '9200302', name: '9200302 - Exploração de apostas em corridas de cavalos' },
  {
    value: '9200399',
    name: '9200399 - Exploração de jogos de azar e apostas não especificados anteriormente'
  },
  { value: '9311500', name: '9311500 - Gestão de instalações de esportes' },
  { value: '9312300', name: '9312300 - Clubes sociais' },
  { value: '9313100', name: '9313100 - Atividades de condicionamento físico' },
  { value: '9319101', name: '9319101 - Produção e promoção de eventos esportivos' },
  { value: '9319199', name: '9319199 - Outras atividades esportivas não especificadas anteriormente' },
  { value: '9321200', name: '9321200 - Parques de diversão e parques temáticos' },
  { value: '9329801', name: '9329801 - Discotecassal�es de dan�a e similares"' },
  { value: '9329802', name: '9329802 - Exploração de boliches' },
  { value: '9329803', name: '9329803 - Exploração de jogos de sinuca' },
  { value: '9329804', name: '9329804 - Exploração de jogos eletrônicos recreativos' },
  {
    value: '9329899',
    name: '9329899 - Outras atividades de recreação e lazer não especificadas anteriormente'
  },
  { value: '9411100', name: '9411100 - Atividades de organizações associativas patronais e empresariais' },
  { value: '9412000', name: '9412000 - Atividades de organizações associativas profissionais' },
  { value: '9412001', name: '9412001 - Atividades de fiscalização profissional' },
  { value: '9412099', name: '9412099 - Outras atividades associativas profissionais' },
  { value: '9420100', name: '9420100 - Atividades de organizações sindicais' },
  { value: '9430800', name: '9430800 - Atividades de associações de defesa de direitos sociais' },
  { value: '9491000', name: '9491000 - Atividades de organizações religiosas ou filosóficas' },
  { value: '9492800', name: '9492800 - Atividades de organizações políticas' },
  { value: '9493600', name: '9493600 - Atividades de organizações associativas ligadas à cultura e à arte' },
  { value: '9499500', name: '9499500 - Atividades associativas não especificadas anteriormente' },
  {
    value: '9511800',
    name: '9511800 - Reparação e manutenção de computadores e de equipamentos periféricos'
  },
  { value: '9512600', name: '9512600 - Reparação e manutenção de equipamentos de comunicação' },
  {
    value: '9521500',
    name: '9521500 - Reparação e manutenção de equipamentos eletroeletrônicos de uso pessoal e doméstico'
  },
  { value: '9529101', name: '9529101 - Reparação de calçados' },
  { value: '9529102', name: '9529102 - Chaveiros' },
  { value: '9529103', name: '9529103 - Reparação de relógios' },
  { value: '9529104', name: '9529104 - Reparação de bicicletas' },
  { value: '9529105', name: '9529105 - Reparação de artigos do mobiliário' },
  { value: '9529106', name: '9529106 - Reparação de joias' },
  {
    value: '9529199',
    name: '9529199 - Reparação e manutenção de outros objetos e equipamentos pessoais e domésticos não especificados anteriormente'
  },
  { value: '9601701', name: '9601701 - Lavanderias' },
  { value: '9601702', name: '9601702 - Tinturarias' },
  { value: '9601703', name: '9601703 - Toalheiros' },
  { value: '9602501', name: '9602501 - Cabeleireiros' },
  { value: '9602502', name: '9602502 - Atividades de estética e outros serviços de cuidados com a beleza' },
  { value: '9603301', name: '9603301 - Gestão e manutenção de cemitérios' },
  { value: '9603302', name: '9603302 - Serviços de crematório' },
  { value: '9603303', name: '9603303 - Serviços de sepultamento' },
  { value: '9603304', name: '9603304 - Serviços de funerárias' },
  { value: '9603305', name: '9603305 - Serviços de somatoconservação' },
  {
    value: '9603399',
    name: '9603399 - Atividades funerárias e serviços relacionados não especificados anteriormente'
  },
  { value: '9609201', name: '9609201 - Clínicas de estética e similares' },
  { value: '9609202', name: '9609202 - Agências matrimoniais' },
  { value: '9609203', name: '9609203 - Alojamento' },
  { value: '9609204', name: '9609204 - Exploração de máquinas de serviços pessoais acionadas por moeda' },
  { value: '9609205', name: '9609205 - Atividades de sauna e banhos' },
  { value: '9609206', name: '9609206 - Serviços de tatuagem e colocação de piercing' },
  { value: '9609207', name: '9609207 - Alojamento de animais domésticos' },
  { value: '9609208', name: '9609208 - Higiene e embelezamento de animais domésticos' },
  {
    value: '9609299',
    name: '9609299 - Outras atividades de serviços pessoais não especificadas anteriormente'
  },
  { value: '9700500', name: '9700500 - Serviços domésticos' },
  { value: '9900800', name: '9900800 - Organismos internacionais e outras instituições extraterritoriais' },
  { value: '8888888', name: '8888888 - Atividade Econômica não informada' }
]
