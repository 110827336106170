import React, { useState, useEffect, useRef } from 'react'
import axios from 'api/axios'
import {
  Button,
  Flex,
  FormControl,
  Icon,
  Input,
  Select,
  Text,
  Textarea,
  SimpleGrid,
  IconButton,
  HStack
} from '@chakra-ui/react'
import authHeader from 'hooks/authHeader'
import { getUserAccountId, getUserAuthentication } from 'hooks/authService'
import { ptBR } from 'contexts/dadosBrasileiros'
import { FaFlag } from 'react-icons/fa'
import { addLocale } from 'primereact/api'
import { MultiSelect } from 'primereact/multiselect'
import { Calendar } from 'primereact/calendar'
import { SelectButton } from 'primereact/selectbutton'
import { InputTextarea } from 'primereact/inputtextarea'
import { Dropdown } from 'primereact/dropdown'
// import { Button } from 'primereact/button'
import { cnaes, porteEmpresas, naturezasJuridicas, estadosRegioes } from 'contexts/dadosBrasileiros'
import { Slider } from 'primereact/slider'
import { Toast } from 'primereact/toast'
// import { PriorityComponent } from './priorityComponent'
import { SpeedDial } from 'primereact/speeddial'
import { Tooltip } from 'primereact/tooltip'

export default function FormActivities({ entity, entityId, dealData, refreshComponent }) {
  const account_id = getUserAccountId()
  const {
    user: { id: userId }
  } = getUserAuthentication()
  // console.log('userData', userId)

  const activityId = null // puxar activityId de params

  // dealdata
  // {
  //   "id": 100,
  //   "account_id": 4,
  //   "company_id": 145,
  //   "contact_id": 253,
  //   "step_id": 31,
  //   "name": "Hebevet",
  //   "deal_data": {
  //     "description": ""
  //   },
  //   "tags": null,
  //   "created_at": "2024-12-11T01:39:34.944Z",
  //   "updated_at": "2024-12-11T01:39:34.944Z",
  //   "company": {
  //     "name": "Hebevet",
  //     "contact_data": {
  //       "x": "",
  //       "email": [
  //         {
  //           "tipo": "CNPJ",
  //           "endereco_email": "hebevet.o3@gmail.com"
  //         }
  //       ],
  //       "website": "",
  //       "youtube": "",
  //       "facebook": "https://www.facebook.com/Hebevet.ozonio",
  //       "linkedin": "",
  //       "telefone": [
  //         {
  //           "tipo": "Fixo",
  //           "numero": "55 (11) 49795281"
  //         },
  //         {
  //           "tipo": "Móvel",
  //           "numero": "55 (11) 93349770"
  //         }
  //       ],
  //       "instagram": "https://www.instagram.com/hebevet.ozonio"
  //     }
  //   },
  //   "contact": {
  //     "first_name": "Luciene",
  //     "last_name": "Cazella Dos Santos",
  //     "position": "Sócio-Administrador ",
  //     "contact_data": {
  //       "x": "",
  //       "email": [
  //         {
  //           "email": "",
  //           "endereco_email": "cazellalu@hotmail.com"
  //         }
  //       ],
  //       "address": {
  //         "uf": "",
  //         "cep": "",
  //         "pais": "",
  //         "bairro": "",
  //         "numero": "",
  //         "endereco": "",
  //         "municipio": "",
  //         "complemento": ""
  //       },
  //       "facebook": "",
  //       "linkedin": "",
  //       "telefone": [
  //         {
  //           "numero": ""
  //         }
  //       ],
  //       "descricao": "",
  //       "instagram": ""
  //     }
  //   },
  //   "step": {
  //     "id": 31,
  //     "account_id": 4,
  //     "pipeline_id": 8,
  //     "name": "Qualificação",
  //     "goal": null,
  //     "objective": "",
  //     "order": 4,
  //     "pipeline": {
  //       "id": 8,
  //       "name": "Comercial"
  //     }
  //   }
  // }

  console.log('dealData: ', dealData)

  const [accountSettings, setAccountSettings] = useState({
    vono_token: '',
    vono_records: {
      vono_key: '',
      vono_token: ''
    },
    resend_api_key: '',
    api_instance_name: '',
    api_instance_token: '',
    approach_min_interval: 20,
    max_daily_triggers: 200
  })

  function getDealWhatsappNumbers(dealData) {
    const dealWhatsApps = []

    // Formata o número para o formato do WhatsApp (removendo espaços, parênteses, e traços)
    const formatWhatsAppNumber = (numero) => numero.replace(/\D/g, '')

    // Verifica e extrai números da empresa
    if (dealData.company && dealData.company.contact_data && dealData.company.contact_data.telefone) {
      dealData.company.contact_data.telefone.forEach((telefone) => {
        if (telefone.tipo === 'Móvel' || telefone.tipo === 'Celular') {
          dealWhatsApps.push({
            nome: dealData.company.name,
            numero: formatWhatsAppNumber(telefone.numero)
          })
        }
      })
    }

    // Verifica e extrai números do contato
    if (dealData.contact && dealData.contact.contact_data && dealData.contact.contact_data.telefone) {
      dealData.contact.contact_data.telefone.forEach((telefone) => {
        if (telefone.numero) {
          dealWhatsApps.push({
            nome: `${dealData.contact.first_name} ${dealData.contact.last_name} - ${dealData.contact.position || ''}`.trim(),
            numero: formatWhatsAppNumber(telefone.numero)
          })
        }
      })
    }

    return dealWhatsApps
  }

  const whatsapplist = getDealWhatsappNumbers(dealData)
  console.log('whatsapplist', whatsapplist)

  const tomorrow9am = getNextBusinessDayAt9AM()
  const [startDate, setstartDate] = useState(tomorrow9am)
  const [templateId, setTemplateId] = useState(null)

  const [activity, setActivity] = useState({
    owner_id: userId && userId,
    account_id: account_id && account_id,
    entity: entity && entity,
    entity_id: entityId && entityId,
    type: 'task',
    name: 'atividade entidade status',
    description: '',
    status: 'todo',
    coments: {},
    start_date: null,
    dead_line: null,
    flag: 'normal',
    activity_data: {}
  })

  const [whatsAppTemplatesList, setWhatsAppTemplatesList] = useState([])
  const [emailsTemplatesList, setEmailsTemplatesList] = useState([])

  useEffect(() => {
    getMessagesTemplates()
    getAccountSettings()
  }, [])

  useEffect(() => {
    console.log('activityState: ', activity)
    console.log('accountSettings: ', accountSettings)
  }, [activity, accountSettings])

  async function getAccountSettings() {
    if (!account_id) return

    try {
      const response = await axios.get(`/accounts/${account_id}`, {
        headers: authHeader()
      })

      setAccountSettings(response.data.settings)

      console.log('Account Settings', response.data)
    } catch (error) {
      console.log('Erro ao carregar dados da conta.', error)
    }
  }

  async function getMessagesTemplates() {
    if (!account_id) return

    try {
      const response = await axios.get(`/templates?account_id=${account_id}`, { headers: authHeader() })
      console.log('Templates disponíveis', response.data.data)

      const whatsapp = response.data.data.filter((item) => item.channel === 'WhatsApp')
      const emails = response.data.data.filter((item) => item.channel === 'Email')

      setEmailsTemplatesList(emails)
      setWhatsAppTemplatesList(whatsapp)
    } catch (error) {
      console.log('Erro ao listar os templates: ', error)
    }
  }

  async function handleChange(event) {
    const { name, value } = event.target
    setActivity((prevActivity) => ({
      ...prevActivity,
      [name]: value
    }))

    if (name === 'type') {
      setActivity((prevActivity) => ({
        ...prevActivity,
        name: value
      }))
    }
  }

  async function handleChangeTemplate(event) {
    const { name, value } = event.target
    setTemplateId(value)

    const rawMessageTemplate = whatsAppTemplatesList.find((item) => item.id === value)
    const plainTextMessage = formatHtmlToWhatsAppMessage(rawMessageTemplate.message.content)
    const templateMessage = applyDealDataToVariables(plainTextMessage, dealData)
    console.log('rawMessageTemplate', rawMessageTemplate)
    console.log('updatedMessageTemplate', templateMessage)

    setActivity((prevActivity) => ({
      ...prevActivity,
      activity_data: {
        ...prevActivity.activity_data,
        message: templateMessage
      }
    }))
  }

  async function handleChangeActivityData(event) {
    const { name, value } = event.target
    setActivity((prevActivity) => ({
      ...prevActivity,
      activity_data: {
        ...prevActivity.activity_data,
        [name]: value
      }
    }))
  }

  async function handleChangeDate(event) {
    const { value } = event.target
    const timestamp_start_date = convertToTimestamp(value)

    setstartDate(value)
    setActivity((prevActivity) => ({
      ...prevActivity,
      start_date: timestamp_start_date
    }))
  }

  async function handleSubmit(event) {
    event.preventDefault()

    let activityPostData = { ...activity }

    if (activity.type === 'message') {
      const server = process.env.REACT_APP_EVOLUTION_BASEURL
      console.log('Mensagem a ser enviada: ', activity.activity_data.message)

      const whatsappMessageToken = {
        number: activity.activity_data.whatsapp,
        // number: '551151283600',
        delay: 500,
        text: activity.activity_data.message
      }

      const response = await fetch(`${server}/message/sendText/${accountSettings.api_instance_name}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          apikey: accountSettings.api_instance_token
        },
        body: JSON.stringify(whatsappMessageToken)
      })
      console.log('Mensagem enviada', response)

      activityPostData = {
        ...activity,
        description: activity.activity_data.message,
        status: 'done'
      }
    }

    try {
      const response = await axios.post('/activities', activityPostData, { headers: authHeader() })
      // console.log('activity response: ', response)
      refreshComponent(response)
      setActivity((prevActivity) => ({
        ...prevActivity,
        type: 'call',
        name: 'atividade entidade status',
        description: '',
        status: 'todo',
        coments: [],
        start_date: null,
        dead_line: null
      }))
      toastSuccess('Atividade adicionada.')
    } catch (error) {
      console.log('error', error)
      toastError('Erro ao adicionar atividade')
    }
    return
  }

  function convertToTimestamp(localDateTime) {
    return new Date(localDateTime).getTime()
  }
  function renderLocalDateTime(timestamp) {
    return new Date(timestamp).toLocaleString()
  }

  function getNextBusinessDayAt9AM() {
    const date = new Date()

    date.setHours(9, 0, 0, 0)

    let dayOfWeek = date.getDay()

    if (dayOfWeek === 6) {
      date.setDate(date.getDate() + 2)
    } else if (dayOfWeek === 0) {
      date.setDate(date.getDate() + 1)
    } else {
      date.setDate(date.getDate() + 1)

      dayOfWeek = date.getDay()
      if (dayOfWeek === 6) {
        date.setDate(date.getDate() + 2)
      } else if (dayOfWeek === 0) {
        date.setDate(date.getDate() + 1)
      }
    }

    return date
  }

  const toast = useRef(null)
  const toastSuccess = (message) => {
    toast.current.show({ severity: 'success', summary: 'Success', detail: message })
  }
  const toastError = (message) => {
    toast.current.show({
      severity: 'error',
      summary: 'Error',
      detail: message
    })
  }

  function formatHtmlToWhatsAppMessage(htmlTemplate) {
    // Remove as tags <p>, <br> e outras tags HTML, substituindo por quebras de linha ou espaços
    const plainText = htmlTemplate
      .replace(/<p>/g, '') // Remove <p>
      .replace(/<\/p>/g, '\n') // Substitui </p> por quebra de linha
      .replace(/<br\s*\/?>/g, '\n') // Substitui <br> por quebra de linha
      .replace(/<\/?[^>]+(>|$)/g, '') // Remove outras tags HTML

    // Retorna o texto limpo
    return plainText.trim()
  }

  function applyDealDataToVariables(script, dealData) {
    const nomeContato = dealData.contact.first_name
    const nomeEmpresa = dealData.company.name
    // const contactGender = dealData.contact.gender

    let message = script
    if (message.includes('{{NomeContato}}')) {
      message = message.replaceAll('{{NomeContato}}', nomeContato)
    }
    if (message.includes('{{NomeEmpresa}}')) {
      message = message.replaceAll('{{NomeEmpresa}}', nomeEmpresa)
    }
    // if (message.includes('{{o/a}}')) {
    //   message = message.replaceAll('{{o/a}}', contactGender === 'female' ? 'a' : 'o')
    // }

    return message
  }

  const [showPriorities, setShowPriorities] = useState(false)
  const [activityPriority, setActivityPriority] = useState({
    value: 'Prioridade normal',
    flag: 'normal',
    color: '#e9e9e9'
  })

  const prioritiesList = [
    {
      value: 'Baixa prioridade',
      flag: 'low',
      color: '#49BFFF'
    },
    {
      value: 'Prioridade normal',
      flag: 'normal',
      color: '#e9e9e9'
    },
    {
      value: 'Alta prioridade',
      flag: 'high',
      color: '#F6E05E'
    },
    {
      value: 'Máxima prioridade ',
      flag: 'top',
      color: '#E53E3E'
    }
  ]

  addLocale('ptBR', {
    irstDayOfWeek: 1,
    dayNames: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
    dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'],
    dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
    monthNames: [
      'Janeiro',
      'Fevereiro',
      'Março',
      'Abril',
      'Maio',
      'Junho',
      'Julho',
      'Agosto',
      'Setembro',
      'Outubro',
      'Novembro',
      'Dezembro'
    ],
    monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
    today: 'Hoje',
    clear: 'Limpar'
  })

  return (
    <Flex bg="white" borderRadius="20px" p="20px" width={'100%'}>
      <form onSubmit={handleSubmit}>
        <Flex flexDirection="column" w="full">
          <Flex flexDirection={'row'} alignItems={'flex-start'} width={'100%'}>
            <Text fontSize="18px" fontWeight="800" mb="24px" mr="20px">
              Atividade
            </Text>
            <SelectButton
              name="type"
              value={activity.type}
              onChange={handleChange}
              options={[
                { value: 'call', name: 'Ligação' },
                { value: 'task', name: 'Tarefa' },
                { value: 'email', name: 'Email' },
                { value: 'message', name: 'WhatsApp' },
                { value: 'meeting', name: 'Reunião' },
                { value: 'visit', name: 'Visita' }
              ]}
              optionLabel="name"
              style={{
                fontSize: '12px',
                fontWeight: '500',

                margin: '0 20px 10px 0'
              }}
              pt={{
                button: {
                  style: { fontSize: '12px', fontWeight: '500', padding: '5px 10px', borderRadius: '20px' }
                },
                label: { style: { fontSize: '12px', fontWeight: '500' } }
              }}
            />
          </Flex>
          <Flex alignItems={'center'}>
            <Calendar
              value={startDate}
              onChange={handleChangeDate}
              locale="ptBR"
              showIcon
              iconPos="left"
              showTime
              dateFormat="D, dd/M/yy"
              hourFormat="24"
              variant="outlined"
              pt={{
                root: {
                  style: {
                    fontSize: '14px',
                    margin: '0px',
                    width: '220px'
                  }
                }
              }}
            />
            <HStack onMouseOver={() => setShowPriorities(true)} onMouseLeave={() => setShowPriorities(false)}>
              <IconButton
                colorScheme={'white'}
                color={activityPriority.color}
                variant="outline"
                aria-label="Priority"
                borderRadius={'50px'}
                size="sm"
                p="1px"
                w="20px"
                icon={<Icon as={FaFlag} />}
                onClick={() => {
                  setActivity((prevState) => ({ ...prevState, flag: activityPriority.flag }))
                }}
              />
              {showPriorities &&
                prioritiesList.map((item) => (
                  <IconButton
                    colorScheme={'gray'}
                    color={item.color}
                    aria-label="Priority"
                    borderRadius={'50px'}
                    variant="outline"
                    size="sm"
                    p="1px"
                    w="20px"
                    icon={<Icon as={FaFlag} />}
                    onMouseOver={() => setShowPriorities(true)}
                    onMouseLeave={() => setShowPriorities(false)}
                    onClick={() => {
                      setActivity((prevState) => ({ ...prevState, flag: item.flag }))
                      setActivityPriority({
                        flag: item.flag,
                        color: item.color,
                        value: item.value
                      })
                    }}
                  />
                ))}
            </HStack>
          </Flex>

          <Flex flexDirection={'row'}>
            <Flex>
              {activityId && (
                <SelectButton
                  name="status"
                  value={activity.status}
                  onChange={handleChange}
                  options={[
                    { value: 'todo', name: 'Pendente' },
                    // { value: 'doing', name: 'Fazendo' },
                    // { value: 'blocked', name: 'Impedida' },
                    // { value: 'postponed', name: 'Adiada' },
                    { value: 'canceled', name: 'Cancelada' },
                    { value: 'done', name: 'Realizada' }
                  ]}
                  optionLabel="name"
                  style={{
                    fontSize: '12px',
                    width: '100%',
                    margin: '0 0 10px 0px'
                  }}
                  pt={{
                    button: {
                      style: {
                        fontSize: '12px',
                        fontWeight: '500',
                        padding: '5px 10px',
                        borderRadius: '20px'
                      }
                    },
                    label: { style: { fontSize: '12px', fontWeight: '500' } }
                  }}
                />
              )}
            </Flex>
          </Flex>
          {activity.type !== 'message' && (
            <Flex w="full" gap="20px" justifyContent={'space-between'} alignItems="flex-end">
              <InputTextarea
                name="description"
                value={activity.description}
                onChange={handleChange}
                placeholder="Descrição da atividade"
                rows={2}
                cols="53"
                style={{
                  // width: '100%',
                  padding: '10px 10px',
                  margin: '0 0 0px 0',
                  fontSize: '14px'
                }}
                pt={{
                  label: { style: { whiteSpace: 'normal' } },
                  token: { style: { margin: '0 6px 6px 0', padding: '2px 5px 2px 8px' } },
                  wrapper: { style: { maxWidth: '600px' } }
                }}
              />
              <Button
                w="fit-content"
                borderRadius="20px"
                background={'#49BFFF'}
                color="white"
                colorScheme="blue"
                fontSize={'14px'}
                type="submit"
                p="10px 20px"
              >
                Adicionar
              </Button>
            </Flex>
          )}
          {activity.type === 'message' && (
            <Flex flexDirection={'column'} gap="10px" mt="10px">
              <SimpleGrid columns="2" gap="10px">
                <Dropdown
                  name="whatsapp"
                  value={activity.activity_data.whatsapp}
                  // loading={}
                  onChange={handleChangeActivityData}
                  placeholder="Selecione o numero"
                  options={whatsapplist}
                  optionLabel="nome"
                  optionValue="numero"
                  // className="w-full"
                  style={{ borderRadius: '20px' }}
                  pt={{
                    root: { background: '#fafafa', padding: '3px ', borderRadius: '20px' },
                    input: {
                      style: {
                        width: '200px',
                        background: 'rgb(250, 250, 250)',
                        fontSize: '14px',
                        padding: '7px 10px',
                        borderRadius: '20px 0 0 20px'
                        // color: 'white'
                      }
                    },
                    trigger: {
                      style: {
                        background: 'rgb(250, 250, 250)',
                        fontSize: '14px',
                        // color: 'white',
                        borderRadius: '0 20px 20px 0'
                      }
                    },
                    item: {
                      style: {
                        fontSize: '14px',
                        padding: '8px 16px',
                        width: '100%',
                        background: '#fafafa'
                      }
                    },
                    panel: {
                      style: { marginTop: '2px', height: 'fit-content', borderRadius: '6px' }
                    }
                  }}
                />
                <Dropdown
                  name="template"
                  value={''}
                  // loading={}
                  onChange={handleChangeTemplate}
                  placeholder="Modelos de mensagem"
                  options={whatsAppTemplatesList}
                  optionLabel="name"
                  optionValue="id"
                  // className="w-full"
                  style={{ borderRadius: '20px' }}
                  pt={{
                    root: { background: '#fafafa', padding: '3px ', borderRadius: '20px' },
                    input: {
                      style: {
                        width: '200px',
                        background: 'rgb(250, 250, 250)',
                        fontSize: '14px',
                        padding: '7px 10px',
                        borderRadius: '20px 0 0 20px'
                        // color: 'white'
                      }
                    },
                    trigger: {
                      style: {
                        background: 'rgb(250, 250, 250)',
                        fontSize: '14px',
                        // color: 'white',
                        borderRadius: '0 20px 20px 0'
                      }
                    },
                    item: {
                      style: {
                        fontSize: '14px',
                        padding: '8px 16px',
                        width: '100%',
                        background: '#fafafa'
                      }
                    },
                    panel: {
                      style: { marginTop: '2px', height: 'fit-content', borderRadius: '6px' }
                    }
                  }}
                />
              </SimpleGrid>
              <InputTextarea
                name="message"
                value={activity.activity_data.message}
                onChange={handleChangeActivityData}
                placeholder="Mensagem"
                rows={5}
                cols="53"
                style={{
                  // width: '100%',
                  padding: '10px 10px',
                  margin: '0 0 0px 0',
                  fontSize: '14px',
                  background: 'rgb(250, 250, 250)'
                  // borderRadius: '0 20px 20px 0'
                }}
                pt={{
                  label: { style: { whiteSpace: 'normal' } },
                  token: { style: { margin: '0 6px 6px 0', padding: '2px 5px 2px 8px' } },
                  wrapper: { style: { maxWidth: '600px' } }
                }}
              />
              <Button
                w="fit-content"
                borderRadius="20px"
                background={'#49BFFF'}
                color="white"
                colorScheme="blue"
                fontSize={'14px'}
                type="submit"
                p="10px 20px"
              >
                Enviar
              </Button>
            </Flex>
          )}
        </Flex>
      </form>
      <Toast position="bottom-center" ref={toast} />
    </Flex>
  )
}
