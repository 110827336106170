import {
  Flex,
  IconButton,
  Box,
  Button,
  ButtonGroup,
  FormControl,
  FormLabel,
  Input,
  Portal,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  Link,
  Stack,
  useDisclosure,
  Text,
  Grid,
  GridItem,
  Tag,
  useColorModeValue
} from '@chakra-ui/react'
import React, { useState, useEffect, useRef } from 'react'
import { InputText } from 'primereact/inputtext'
import {
  FaUserPlus,
  FaLinkedinIn,
  FaEnvelopeOpen,
  FaPhone,
  FaEnvelope,
  FaWhatsapp,
  FaMobile,
  FaSuitcase
} from 'react-icons/fa'
import { InputMask } from 'primereact/inputmask'
import { Message } from 'primereact/message'
import { Toast } from 'primereact/toast'
import axios from 'api/axios'
import authHeader from 'hooks/authHeader'
import { getUserAccountId } from 'hooks/authService'
import DealForms from 'views/admin/formDeal/components/dealForm'

export default function CompanyDeals({ company_id }) {
  const account_id = getUserAccountId()
  const { onOpen, onClose, isOpen } = useDisclosure()
  const firstFieldRef = React.useRef(null)
  const newDealFirstFieldRef = React.useRef(null)
  const textColor = useColorModeValue('navy.700', 'white')

  const [companyDeals, setCompanyDeals] = useState({})

  const [contactForm, setContactForm] = useState({
    account_id: account_id && parseInt(account_id, 10),
    company_id: (company_id && parseInt(company_id, 10)) || '',
    title: '',
    name: '',
    deal_data: {
      description: ''
    }
  })

  useEffect(() => {
    getCompanyContacts()
  }, [])

  useEffect(() => {
    console.log('contactForm Contact Popover', contactForm)
  }, [contactForm])

  async function getCompanyContacts() {
    if (!company_id || !account_id) return
    try {
      const response = await axios.get(`/deals?account_id=${account_id}&company_id=${company_id}`, {
        headers: authHeader()
      })
      setCompanyDeals(response.data.data)
      console.log('Company Deals: ', response.data.data)
    } catch (error) {
      console.log('Erro ao carregar os dados.', error)
    }
  }

  const handleContactChange = (event) => {
    const { name, value } = event.target

    console.log('HandleChange ContactPopover', name, value)

    setContactForm((prevState) => ({
      ...prevState,
      [name]: value
    }))
  }

  const handleContactSubmit = async (event) => {
    event.preventDefault()
    try {
      const response = await axios.post('/contacts', contactForm, { headers: authHeader() })
      console.log('Contato adicionado.', response)
    } catch (error) {
      console.log('Erro ao adicionar o contato.', error)
    }
  }

  const toast = useRef(null)
  const toastSuccess = (message) => {
    toast.current.show({ severity: 'success', summary: 'Success', detail: message })
  }
  const toastError = (message) => {
    toast.current.show({
      severity: 'error',
      summary: 'Error',
      detail: message
    })
  }

  return (
    <Flex flexDirection={'column'} gap="20px" width={'full'}>
      <Flex
        bg="white"
        p="15px 20px"
        borderRadius={'20px'}
        h="fit-content"
        w="full"
        justifyContent={'space-between'}
        flexDir={'column'}
      >
        <Popover
          isOpen={isOpen}
          initialFocusRef={firstFieldRef}
          onOpen={onOpen}
          onClose={onClose}
          placement="bottom"
          closeOnBlur={false} // Evita o fechamento ao clicar fora
        >
          <Flex w="full" justifyContent={'space-between'} mb="24px">
            <Text display="flex" fontSize="xl" fontWeight="800" color={textColor}>
              Negócios
            </Text>
            <PopoverTrigger>
              <Tag
                bg="#E2E8F0"
                w={'fit-content'}
                fontSize={'0.7rem'}
                borderRadius={'20px'}
                onClick={onOpen}
                cursor="pointer"
                h={'24px'}
              >
                + Novo negócio
              </Tag>
            </PopoverTrigger>
          </Flex>
          {/* <Portal> */}
          <PopoverContent
            p={0}
            m={0}
            borderRadius="20px"
            boxShadow={'2xl'}
            width={'600px'}
            _focus={{ border: 'none' }}
          >
            <DealForms companyId={company_id} onClose={onClose} inicialFocus={newDealFirstFieldRef} />
            <PopoverArrow />
            <PopoverCloseButton mt="5px" p="15px" borderRadius={'50px'} />
          </PopoverContent>
          {/* </Portal> */}
        </Popover>
        {companyDeals &&
          companyDeals.length > 0 &&
          companyDeals.map((deal) => (
            <Link href={`/admin/deal/${deal.id}`}>
              <Flex mb="8px" gap="5px" justifyContent={'space-between'}>
                <Flex flexDirection={'column'}>
                  <Text fontSize={'0.95rem'}>{`${deal.name}`}</Text>
                  <Text fontSize={'0.85rem'} color="#A0AEC0">{`${deal.deal_data.description}`}</Text>
                </Flex>
                <Flex gap="5px">
                  <Tag
                    fontSize={'0.75rem'}
                    borderRadius={'20px'}
                    background={'rgb(116, 198, 245)'}
                    color="white"
                    h={'24px'}
                  >
                    {deal.step.name}
                  </Tag>
                </Flex>
              </Flex>
            </Link>
          ))}
      </Flex>

      <Flex></Flex>
      <Toast position="bottom-center" ref={toast} />
    </Flex>
  )
}
