import {
  Flex,
  IconButton,
  Box,
  Button,
  ButtonGroup,
  FormControl,
  FormLabel,
  Input,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  Stack,
  useDisclosure,
  Grid,
  GridItem,
  Tag,
  Text,
  useColorModeValue,
  Link
} from '@chakra-ui/react'
import React, { useState, useEffect, useRef } from 'react'
import { InputText } from 'primereact/inputtext'
import {
  FaUserPlus,
  FaLinkedinIn,
  FaEnvelopeOpen,
  FaPhone,
  FaEnvelope,
  FaWhatsapp,
  FaMobile
} from 'react-icons/fa'
import { InputMask } from 'primereact/inputmask'
import { Message } from 'primereact/message'
import { Toast } from 'primereact/toast'
import axios from 'api/axios'
import authHeader from 'hooks/authHeader'
import { getUserAccountId } from 'hooks/authService'
import { capitalizeText } from 'hooks/capitalizeText'

export default function CompanyContacts({ company_id, socios, updateContacts }) {
  const account_id = getUserAccountId()
  const { onOpen, onClose, isOpen } = useDisclosure()
  const firstFieldRef = React.useRef(null)
  const textColor = useColorModeValue('navy.700', 'white')

  // console.log('company_id', company_id)
  // console.log('socios...', socios)

  const [companyContacts, setCompanyContacts] = useState([])

  const [contactForm, setContactForm] = useState({
    account_id: account_id && parseInt(account_id, 10),
    company_id: (company_id && parseInt(company_id, 10)) || '',
    title: '',
    first_name: '',
    last_name: '',
    position: '',
    contact_data: {
      linkedin: '',
      instagram: '',
      facebook: '',
      x: '',
      email: [{ email: '' }],
      telefone: [{ numero: '', tipo: '' }],
      address: {
        endereco: '',
        numero: '',
        complemento: '',
        bairro: '',
        cep: '',
        municipio: '',
        uf: '',
        pais: ''
      },
      descricao: '',
      faixa_etaria: ''
    }
  })

  useEffect(() => {
    addSociosToState()
    getCompanyContacts()
  }, [socios])

  useEffect(() => {
    // console.log('companyContacts', companyContacts)
    // console.log('socios', socios)
  }, [companyContacts, socios])

  // function splitName(fullName) {
  //   const fullNameCapitalized = capitalizeText(fullName)
  //   const nameParts = fullNameCapitalized.trim().split(' ')
  //   const first_name = nameParts[0]
  //   const last_name = nameParts.slice(1).join(' ')
  //   return {
  //     first_name,
  //     last_name
  //   }
  // }

  function addSociosToState() {
    // console.log('socios array: ', Array.isArray(socios))
    return (
      Array.isArray(socios) &&
      socios.map((socio) => {
        setCompanyContacts((prevContacts) => [...prevContacts, socio])
        // console.log('sócio: ', socio)
      })
    )
  }

  async function getCompanyContacts() {
    if (!company_id || !account_id) return

    try {
      const response = await axios.get(`/contacts?account_id=${account_id}&company_id=${company_id}`, {
        headers: authHeader()
      })
      setCompanyContacts(response.data.data)
      console.log('Company Contacts: ', response.data.data)
    } catch (error) {
      console.log('Erro ao carregar os dados.', error)
    }
  }

  const handleContactChange = (event) => {
    const { name, value } = event.target

    console.log('HandleChange ContactPopover', name, value)

    // Handle nested fields like email and telefone
    if (name === 'email') {
      setContactForm((prevState) => ({
        ...prevState,
        contact_data: {
          ...prevState.contact_data,
          email: [{ email: value }]
        }
      }))
    } else if (name === 'telefone') {
      setContactForm((prevState) => ({
        ...prevState,
        contact_data: {
          ...prevState.contact_data,
          telefone: [{ numero: value }]
        }
      }))
    } else if (name === 'linkedin') {
      setContactForm((prevState) => ({
        ...prevState,
        contact_data: {
          ...prevState.contact_data,
          linkedin: value
        }
      }))
    } else {
      // Handle other fields directly
      setContactForm((prevState) => ({
        ...prevState,
        [name]: value
      }))
    }
  }

  const [isEmailValid, setIsEmailValid] = useState(true)

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

  const handleEmailChange = (e) => {
    const value = e.target.value
    setContactForm((prevState) => ({
      ...prevState,
      contact_data: {
        ...prevState.contact_data,
        email: [{ email: value }]
      }
    }))

    // Validação do e-mail
    if (emailRegex.test(value)) {
      setIsEmailValid(true)
    } else {
      setIsEmailValid(false)
    }
  }

  const handleContactSubmit = async (event) => {
    event.preventDefault()
    try {
      const response = await axios.post('/contacts', contactForm, { headers: authHeader() })
      console.log('Contato adicionado.', response)
      toastSuccess('Contato adicionado.')
      updateContacts(contactForm)
    } catch (error) {
      toastError('Erro ao adicionar o contato.')
      console.log('Erro ao adicionar o contato.', error)
    }
  }

  const toast = useRef(null)
  const toastSuccess = (message) => {
    toast.current.show({ severity: 'success', summary: 'Success', detail: message })
  }
  const toastError = (message) => {
    toast.current.show({
      severity: 'error',
      summary: 'Error',
      detail: message
    })
  }

  function normalizePhoneNumber(phoneNumber) {
    // Remove tudo que não é dígito
    const digitsOnly = phoneNumber.replace(/\D/g, '')
    return digitsOnly
  }

  return (
    <Flex flexDirection={'column'} gap="20px" width={'full'}>
      <Flex bg="white" p="15px 20px" borderRadius={'20px'} h="fit-content" flexDir={'column'}>
        <Flex>
          <Popover
            isOpen={isOpen}
            initialFocusRef={firstFieldRef}
            onOpen={onOpen}
            onClose={onClose}
            placement="top"
            closeOnBlur={false} // Evita o fechamento ao clicar fora
          >
            <Flex w="full" justifyContent={'space-between'} mb="24px">
              <Text display="flex" fontSize="xl" fontWeight="800" color={textColor}>
                Contatos
              </Text>

              <PopoverTrigger>
                <Tag
                  bg="#E2E8F0"
                  w={'fit-content'}
                  fontSize={'0.7rem'}
                  borderRadius={'20px'}
                  onClick={onOpen}
                  cursor="pointer"
                  h={'24px'}
                >
                  + Novo contato
                </Tag>
              </PopoverTrigger>
            </Flex>
            <PopoverContent
              p={5}
              borderRadius="20px"
              boxShadow={'2xl'}
              w={'30hv'}
              _focus={{ border: 'none' }}
            >
              <PopoverArrow />
              <PopoverCloseButton mt="5px" p="15px" borderRadius={'50px'} />
              <Stack spacing={4}>
                <Flex>
                  <Text fontSize={'18px'} fontWeight={'800'}>
                    Novo contato
                  </Text>
                </Flex>
                <form onSubmit={handleContactSubmit}>
                  <Grid templateColumns="repeat(4, 1fr)" gap="20px">
                    <GridItem colSpan={2}>
                      <FormControl>
                        <label
                          style={{
                            color: '#A0AEC0',
                            fontSize: '12px',
                            fontWeight: '500',
                            paddingLeft: '0px'
                          }}
                        >
                          Nome
                        </label>
                        <InputText
                          // ref={firstFieldRef}
                          name="first_name"
                          value={contactForm.first_name}
                          onChange={handleContactChange}
                          focusBorderColor="transparent"
                          style={{
                            padding: '8px',
                            width: '100%',
                            background: '#fafafa'
                          }}
                          className="outline-none"
                        />
                      </FormControl>
                      <FormControl>
                        <label
                          style={{
                            color: '#A0AEC0',
                            fontSize: '12px',
                            fontWeight: '500',
                            paddingLeft: '0px'
                          }}
                        >
                          Sobrenome
                        </label>
                        <InputText
                          name="last_name"
                          value={contactForm.last_name}
                          onChange={handleContactChange}
                          focusBorderColor="transparent"
                          style={{
                            padding: '8px',
                            width: '100%',
                            background: '#fafafa'
                          }}
                          className="outline-none"
                        />
                      </FormControl>
                      <FormControl>
                        <label
                          style={{
                            color: '#A0AEC0',
                            fontSize: '12px',
                            fontWeight: '500',
                            paddingLeft: '0px'
                          }}
                        >
                          Posição
                        </label>
                        <InputText
                          name="position"
                          value={contactForm.position}
                          onChange={handleContactChange}
                          focusBorderColor="transparent"
                          style={{
                            padding: '8px',
                            width: '100%',
                            background: '#fafafa'
                          }}
                          className="outline-none"
                        />
                      </FormControl>
                    </GridItem>
                    <GridItem colSpan={2}>
                      <FormControl>
                        <label
                          style={{
                            color: '#A0AEC0',
                            fontSize: '12px',
                            fontWeight: '500',
                            paddingLeft: '0px'
                          }}
                        >
                          Email
                          {!isEmailValid && (
                            <Message
                              severity="error"
                              text="Informe um email válido"
                              pt={{
                                root: {
                                  style: {
                                    padding: '1px 5px 1px 3px',
                                    marginTop: '3px',
                                    borderRadius: '20px',
                                    marginLeft: '25px'
                                  }
                                },
                                icon: { style: { width: '11px' } },
                                text: { style: { fontSize: '11px', fontWeight: '400' } }
                              }}
                            />
                          )}
                        </label>
                        <div className="p-inputgroup flex-1">
                          <span className="p-inputgroup-addon">
                            <FaEnvelopeOpen />
                          </span>
                          <InputText
                            name="email"
                            value={contactForm.contact_data?.email[0]?.email}
                            onChange={handleEmailChange}
                            className={isEmailValid ? '' : 'p-invalid'}
                            focusBorderColor="transparent"
                            style={{
                              padding: '8px',
                              width: '100%',
                              background: '#fafafa'
                            }}
                          />
                        </div>
                      </FormControl>
                      <FormControl>
                        <label
                          style={{
                            color: '#A0AEC0',
                            fontSize: '12px',
                            fontWeight: '500',
                            paddingLeft: '0px'
                          }}
                        >
                          Telefone
                        </label>
                        <div className="p-inputgroup flex-1">
                          <span className="p-inputgroup-addon">
                            <FaPhone />
                          </span>
                          <InputMask
                            keyfilter="int"
                            onBlur={handleContactChange}
                            name="telefone"
                            mask="+99 (99) 99999-9999"
                            value={contactForm.contact_data.telefone[0].numero} // Certifique-se que o campo correto é 'numero'
                            focusBorderColor="transparent"
                            style={{
                              padding: '8px',
                              width: '100%',
                              background: '#fafafa'
                            }}
                            className="outline-none"
                          />
                        </div>
                      </FormControl>
                      <FormControl>
                        <label
                          style={{
                            color: '#A0AEC0',
                            fontSize: '12px',
                            fontWeight: '500',
                            paddingLeft: '0px'
                          }}
                        >
                          LinkedIn
                        </label>
                        <div className="p-inputgroup flex-1">
                          <span className="p-inputgroup-addon">
                            <FaLinkedinIn />
                          </span>
                          <InputText
                            name="linkedin"
                            value={contactForm.contact_data.linkedin}
                            onChange={handleContactChange}
                            style={{
                              padding: '8px',
                              width: '100%',
                              background: '#fafafa'
                            }}
                            className="outline-none"
                          />
                        </div>
                      </FormControl>
                    </GridItem>
                  </Grid>

                  <ButtonGroup mt="20px" display="flex" justifyContent="flex-end">
                    <Button
                      borderRadius={'50px'}
                      variant="outline"
                      borderColor={'#49BFFF'}
                      color={'#49BFFF'}
                      colorScheme="blue"
                      onClick={onClose}
                    >
                      Cancelar
                    </Button>
                    <Button borderRadius={'50px'} background={'#49BFFF'} colorScheme="blue" type="submit">
                      Adicionar
                    </Button>
                  </ButtonGroup>
                </form>
              </Stack>
            </PopoverContent>
          </Popover>
        </Flex>
        {companyContacts &&
          companyContacts?.length > 0 &&
          companyContacts.map((contact, index) => (
            <Flex mb="8px" gap="5px" justifyContent={'space-between'} key={index}>
              <Link href={`/admin/contact/${contact.id}`}>
                <Flex flexDirection={'column'}>
                  <Text
                    fontSize={'0.95rem'}
                  >{`${contact.title} ${contact.first_name} ${contact.last_name}`}</Text>
                  <Text
                    fontSize={'0.75rem'}
                    style={{ color: '#A0AEC0', fontSize: '12px', fontWeight: '500' }}
                  >
                    {`${contact.position}`}{' '}
                    {contact?.contact_data?.faixa_etaria && ` - ${contact.contact_data.faixa_etaria}`}
                  </Text>
                </Flex>
              </Link>
              <Flex gap="5px">
                {contact.contact_data?.telefone?.length > 0 &&
                  contact.contact_data?.telefone.map((tel) => (
                    <>
                      {/* {tel.tipo === 'movel' || PRECISAMOS ADICIONAR TIPO DE TELEFONE
                        (tel.tipo === 'Móvel' && (
                          <IconButton
                            isRound={true}
                            size="xs"
                            variant="solid"
                            colorScheme="blue"
                            background={'#49BFFF'}
                            aria-label="Done"
                            fontSize="10px"
                            icon={<FaMobile size={'12px'} />}
                            onClick={() => window.open(`tel:${normalizePhoneNumber(tel.numero)}`, '_blank')}
                          />
                        ))} */}
                      {tel.numero && (
                        <IconButton
                          isRound={true}
                          size="xs"
                          variant="solid"
                          colorScheme="blue"
                          background={'#49BFFF'}
                          aria-label="Done"
                          fontSize="10px"
                          icon={<FaPhone size={'11px'} />}
                          onClick={() => window.open(`tel:${tel.numero}`, '_blank')}
                          key={tel.numero}
                        />
                      )}

                      {/* <IconButton
                        isRound={true}
                        size="xs"
                        variant="solid"
                        colorScheme="blue"
                        background={'#49BFFF'}
                        aria-label="Done"
                        fontSize="10px"
                        icon={<FaWhatsapp size={'15px'} />}
                      /> */}
                    </>
                  ))}
                {contact.contact_data?.email.length > 0 &&
                  contact.contact_data?.email.map(
                    (email) =>
                      email.endereco_email && (
                        <IconButton
                          isRound={true}
                          size="xs"
                          variant="solid"
                          colorScheme="blue"
                          background={'#49BFFF'}
                          aria-label="Done"
                          fontSize="10px"
                          icon={<FaEnvelope size={'11px'} />}
                          onClick={() => window.open(`mailto:${email.endereco_email}`, '_blank')}
                        />
                      )
                  )}
              </Flex>
            </Flex>
          ))}
      </Flex>

      <Flex></Flex>
      <Toast position="bottom-center" ref={toast} />
    </Flex>
  )
}
