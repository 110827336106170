import { Button, Flex, Grid, GridItem, Icon, IconButton, SimpleGrid, Text } from '@chakra-ui/react'
import { estadosUF } from 'contexts/dadosBrasileiros'
import { Dropdown } from 'primereact/dropdown'
import { InputText } from 'primereact/inputtext'
import { useEffect, useState } from 'react'
import { FaAlgolia, FaBuilding, FaColumns, FaLocationArrow, FaSearch } from 'react-icons/fa'
import axios from 'api/axios'
import authHeader from 'hooks/authHeader'
import { faClose } from '@fortawesome/free-solid-svg-icons'
import { MdClose } from 'react-icons/md'

export default function FormPhone({ data, companyId }) {
  console.log('contactForm', data)

  useEffect(() => {
    setContactData((prevState) => ({
      ...prevState,
      telefone: data.telefone || prevState.telefone,
      email: data.email || prevState.email,
      website: data.website || prevState.website,
      linkedin: data.linkedin || prevState.linkedin,
      x: data.x || prevState.x,
      instagram: data.instagram || prevState.instagram,
      youtube: data.youtube || prevState.youtube,
      facebook: data.facebook || prevState.facebook
    }))
  }, [data])

  const [contact_data, setContactData] = useState({
    telefone: [{ tipo: '', numero: '' }],
    email: [{ tipo: '', endereco_email: '' }],
    instagram: '',
    linkedin: '',
    x: '',
    facebook: '',
    youtube: '',
    website: ''
  })

  useEffect(() => {
    console.log('contact_data atualizado', contact_data)
  }, [contact_data])

  async function handleSubmit(event) {
    event.preventDefault()

    try {
      const response = await axios.patch(
        `/companies/${companyId}`,
        { contact_data },
        { headers: authHeader() }
      )
      setTimeout(() => (window.location.href = `/admin/company/${companyId}`), 1000)
    } catch (error) {
      console.error('Erro ao atualizar a empresa,', error)
    }
  }

  function handleChange(e) {
    const { name, value } = e.target
    setContactData((prevState) => ({
      ...prevState,
      [name]: value
    }))
  }

  return (
    <Flex flexDirection={'column'} gap={'20px'} p="10px">
      <Text fontSize={'18px'} fontWeight={'800'}>
        Alterar Dados de Contato
      </Text>
      <SimpleGrid columns={2} gap={'20px'}>
        <Flex flexDirection={'column'}>
          {contact_data.telefone.length > 0 &&
            contact_data.telefone.map((item, index) => (
              <Grid templateColumns="repeat(7, 1fr)" gap={'0px 10px'} w={'100%'} key={index}>
                <GridItem w="100%" colSpan={4}>
                  <label
                    style={{
                      color: '#A0AEC0',
                      fontSize: '12px',
                      fontWeight: '500',
                      paddingLeft: '0px'
                    }}
                  >
                    Número
                  </label>
                  <Flex py="0.3rem" mt="-5px" alignItems={'center'}>
                    <div className="p-inputgroup flex-1">
                      <InputText
                        value={item.numero} // Usando item.numero para pegar o valor atual
                        name="numero"
                        onChange={(e) =>
                          setContactData((prevState) => {
                            const newTelefone = [...prevState.telefone]
                            newTelefone[index] = {
                              ...newTelefone[index],
                              numero: e.target.value // Atualizando apenas o número
                            }
                            return { ...prevState, telefone: newTelefone }
                          })
                        }
                        placeholder="Número"
                        style={{
                          padding: '0px 10px',
                          width: '100%',
                          height: '45px',
                          background: '#fafafa',
                          fontSize: '14px'
                        }}
                        className="outline-none"
                      />
                    </div>
                  </Flex>
                </GridItem>
                <GridItem w="100%" colSpan={2}>
                  <label
                    style={{
                      color: '#A0AEC0',
                      fontSize: '12px',
                      fontWeight: '500',
                      paddingLeft: '0px',
                      marginBottom: '-15px'
                    }}
                  >
                    Tipo
                  </label>
                  <div className="p-inputgroup flex-1">
                    <Dropdown
                      value={item.tipo}
                      name="tipo"
                      onChange={(e) =>
                        setContactData((prevState) => {
                          const newTelefone = [...prevState.telefone]
                          newTelefone[index] = {
                            ...newTelefone[index],
                            tipo: e.value // Atualizando apenas o tipo
                          }
                          return { ...prevState, telefone: newTelefone }
                        })
                      }
                      options={[
                        { name: 'Fixo', value: 'Fixo' },
                        { name: 'Móvel', value: 'Móvel' },
                        { name: 'WhatsApp', value: 'WhatsApp' }
                      ]}
                      optionLabel="name"
                      optionValue="value"
                      placeholder="Tipo"
                      pt={{
                        root: { width: '100%' },
                        input: {
                          style: {
                            width: '100%',
                            background: '#fafafa',
                            fontSize: '14px'
                          }
                        },
                        trigger: {
                          style: {
                            background: '#fafafa',
                            fontSize: '14px'
                          }
                        },
                        item: {
                          style: {
                            fontSize: '14px',
                            padding: '8px 16px',
                            width: '100%',
                            background: '#fafafa'
                          }
                        },
                        panel: {
                          style: { marginTop: '5px' }
                        }
                      }}
                    />
                  </div>
                </GridItem>
                <GridItem w="100%" colSpan={1}>
                  <IconButton
                    onClick={() =>
                      setContactData((prevState) => ({
                        ...prevState,
                        telefone: prevState.telefone.filter((_, i) => i !== index)
                      }))
                    }
                    colorScheme="whiteAlpha"
                    color="red"
                    // variant="outline"
                    size="sm"
                    height={'30px'}
                    width={'30px'}
                    icon={<MdClose />}
                    borderRadius={'50px'}
                    mt="30px"
                  >
                    Excluir
                  </IconButton>
                </GridItem>
              </Grid>
            ))}
          <Button
            onClick={() =>
              setContactData((prevState) => ({
                ...prevState,
                telefone: [...prevState.telefone, { tipo: '', numero: '' }]
              }))
            }
            bg="#E2E8F0"
            // w={'fit-content'}
            fontSize={'0.7rem'}
            borderRadius={'20px'}
            cursor={'pointer'}
            height={'24px'}
            mt={'8px'}
            mb="18px"
          >
            Adicionar Número
          </Button>
          {contact_data.email.length > 0 &&
            contact_data.email.map((item, index) => (
              <Grid templateColumns="repeat(7, 1fr)" gap={'0px 10px'} w={'100%'} key={index}>
                <GridItem w="100%" colSpan={4}>
                  <label
                    style={{
                      color: '#A0AEC0',
                      fontSize: '12px',
                      fontWeight: '500',
                      paddingLeft: '0px'
                    }}
                  >
                    Email
                  </label>
                  <Flex py="0.3rem" mt="-5px" alignItems={'center'}>
                    <div className="p-inputgroup flex-1">
                      <InputText
                        value={item.endereco_email} // Usando item.numero para pegar o valor atual
                        name="endereco_email"
                        onChange={(e) =>
                          setContactData((prevState) => {
                            const newEmail = [...prevState.email]
                            newEmail[index] = {
                              ...newEmail[index],
                              endereco_email: e.target.value // Atualizando apenas o número
                            }
                            return { ...prevState, email: newEmail }
                          })
                        }
                        placeholder="email"
                        style={{
                          padding: '0px 10px',
                          width: '100%',
                          height: '45px',
                          background: '#fafafa',
                          fontSize: '14px'
                        }}
                        className="outline-none"
                      />
                    </div>
                  </Flex>
                </GridItem>
                <GridItem w="100%" colSpan={2}>
                  <label
                    style={{
                      color: '#A0AEC0',
                      fontSize: '12px',
                      fontWeight: '500',
                      paddingLeft: '0px',
                      marginBottom: '-15px'
                    }}
                  >
                    Tipo
                  </label>
                  <div className="p-inputgroup flex-1">
                    <Dropdown
                      value={item.tipo}
                      name="tipo"
                      onChange={(e) =>
                        setContactData((prevState) => {
                          const newEmail = [...prevState.email]
                          newEmail[index] = {
                            ...newEmail[index],
                            tipo: e.value // Atualizando apenas o tipo
                          }
                          return { ...prevState, email: newEmail }
                        })
                      }
                      options={[
                        { name: 'CNPJ', value: 'CNPJ' },
                        { name: 'Principal', value: 'Principal' },
                        { name: 'Fiscal', value: 'Fiscal' },
                        { name: 'Suporte', value: 'Suporte' },
                        { name: 'Vendas', value: 'Vendas' }
                      ]}
                      optionLabel="name"
                      optionValue="value"
                      placeholder="Tipo"
                      pt={{
                        root: { width: '100%' },
                        input: {
                          style: {
                            width: '100%',
                            background: '#fafafa',
                            fontSize: '14px'
                          }
                        },
                        trigger: {
                          style: {
                            background: '#fafafa',
                            fontSize: '14px'
                          }
                        },
                        item: {
                          style: {
                            fontSize: '14px',
                            padding: '8px 16px',
                            width: '100%',
                            background: '#fafafa'
                          }
                        },
                        panel: {
                          style: { marginTop: '5px' }
                        }
                      }}
                    />
                  </div>
                </GridItem>
                <GridItem w="100%" colSpan={1}>
                  <IconButton
                    onClick={() =>
                      setContactData((prevState) => ({
                        ...prevState,
                        email: prevState.email.filter((_, i) => i !== index)
                      }))
                    }
                    colorScheme="whiteAlpha"
                    color="red"
                    // variant="outline"
                    size="sm"
                    height={'30px'}
                    width={'30px'}
                    icon={<MdClose />}
                    borderRadius={'50px'}
                    mt="30px"
                  >
                    Excluir
                  </IconButton>
                </GridItem>
              </Grid>
            ))}
          <Button
            onClick={() =>
              setContactData((prevState) => ({
                ...prevState,
                email: [...prevState.email, { tipo: '', endereco_email: '' }]
              }))
            }
            bg="#E2E8F0"
            // w={'fit-content'}
            fontSize={'0.7rem'}
            borderRadius={'20px'}
            cursor={'pointer'}
            height={'24px'}
            mt={'8px'}
          >
            Adicionar Email
          </Button>
        </Flex>
        <Flex flexDirection={'column'}>
          <label
            style={{
              color: '#A0AEC0',
              fontSize: '12px',
              fontWeight: '500',
              paddingLeft: '0px'
            }}
          >
            Website
          </label>
          <Flex py="0.3rem" mt="-5px" alignItems={'center'}>
            <div className="p-inputgroup flex-1">
              {/* <span className="p-inputgroup-addon">
                  <FaLocationArrow />
                </span> */}
              <InputText
                value={contact_data.website}
                name="website"
                onChange={handleChange}
                placeholder="https://"
                v-model="value1"
                style={{
                  padding: ' 0px 10px',
                  width: '100%',
                  height: '45px',
                  background: '#fafafa',
                  fontSize: '14px'
                }}
                className="outline-none"
              />
            </div>
          </Flex>
          <label
            style={{
              color: '#A0AEC0',
              fontSize: '12px',
              fontWeight: '500',
              paddingLeft: '0px'
            }}
          >
            LinkedIn
          </label>
          <Flex py="0.3rem" mt="-5px" alignItems={'center'}>
            <div className="p-inputgroup flex-1">
              {/* <span className="p-inputgroup-addon">
                  <FaLocationArrow />
                </span> */}
              <InputText
                value={contact_data.linkedin}
                name="linkedin"
                onChange={handleChange}
                placeholder="..."
                v-model="value1"
                style={{
                  padding: ' 0px 10px',
                  width: '100%',
                  height: '45px',
                  background: '#fafafa',
                  fontSize: '14px'
                }}
                className="outline-none"
              />
            </div>
          </Flex>
          <label
            style={{
              color: '#A0AEC0',
              fontSize: '12px',
              fontWeight: '500',
              paddingLeft: '0px'
            }}
          >
            Instagram
          </label>
          <Flex py="0.3rem" mt="-5px" alignItems={'center'}>
            <div className="p-inputgroup flex-1">
              {/* <span className="p-inputgroup-addon">
                  <FaLocationArrow />
                </span> */}
              <InputText
                value={contact_data.instagram}
                name="instagram"
                onChange={handleChange}
                placeholder="..."
                v-model="value1"
                style={{
                  padding: ' 0px 10px',
                  width: '100%',
                  height: '45px',
                  background: '#fafafa',
                  fontSize: '14px'
                }}
                className="outline-none"
              />
            </div>
          </Flex>
          <label
            style={{
              color: '#A0AEC0',
              fontSize: '12px',
              fontWeight: '500',
              paddingLeft: '0px'
            }}
          >
            Facebook
          </label>
          <Flex py="0.3rem" mt="-5px" alignItems={'center'}>
            <div className="p-inputgroup flex-1">
              {/* <span className="p-inputgroup-addon">
                  <FaLocationArrow />
                </span> */}
              <InputText
                value={contact_data.facebook}
                name="facebook"
                onChange={handleChange}
                placeholder="..."
                v-model="value1"
                style={{
                  padding: ' 0px 10px',
                  width: '100%',
                  height: '45px',
                  background: '#fafafa',
                  fontSize: '14px'
                }}
                className="outline-none"
              />
            </div>
          </Flex>
          <label
            style={{
              color: '#A0AEC0',
              fontSize: '12px',
              fontWeight: '500',
              paddingLeft: '0px'
            }}
          >
            X (Twitter)
          </label>
          <Flex py="0.3rem" mt="-5px" alignItems={'center'}>
            <div className="p-inputgroup flex-1">
              {/* <span className="p-inputgroup-addon">
                  <FaLocationArrow />
                </span> */}
              <InputText
                value={contact_data.x}
                name="x"
                onChange={handleChange}
                placeholder="..."
                v-model="value1"
                style={{
                  padding: ' 0px 10px',
                  width: '100%',
                  height: '45px',
                  background: '#fafafa',
                  fontSize: '14px'
                }}
                className="outline-none"
              />
            </div>
          </Flex>
          <label
            style={{
              color: '#A0AEC0',
              fontSize: '12px',
              fontWeight: '500',
              paddingLeft: '0px'
            }}
          >
            Youtube
          </label>
          <Flex py="0.3rem" mt="-5px" alignItems={'center'}>
            <div className="p-inputgroup flex-1">
              {/* <span className="p-inputgroup-addon">
                  <FaLocationArrow />
                </span> */}
              <InputText
                value={contact_data.youtube}
                name="youtube"
                onChange={handleChange}
                placeholder="..."
                v-model="value1"
                style={{
                  padding: ' 0px 10px',
                  width: '100%',
                  height: '45px',
                  background: '#fafafa',
                  fontSize: '14px'
                }}
                className="outline-none"
              />
            </div>
          </Flex>
        </Flex>
      </SimpleGrid>
      <Flex justifyContent={'right'}>
        <Button
          background={'#49BFFF'}
          colorScheme="blue"
          onClick={handleSubmit}
          width={'fit-content'}
          borderRadius={'50px'}
        >
          Salvar
        </Button>
      </Flex>
    </Flex>
  )
}
