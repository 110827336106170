import { CheckIcon, MinusIcon, SmallCloseIcon } from '@chakra-ui/icons'
import {
  Button,
  Flex,
  Grid,
  GridItem,
  Icon,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tfoot,
  Th,
  Thead,
  Tr
} from '@chakra-ui/react'

export function Plans() {
  return (
    <Flex w={'full'} mt={'80px'} gap={'20px'}>
      <Grid
        templateColumns="repeat(11, 1fr)"
        gap="20px"
        w={'100%'}
        p="30px"
        bg="white"
        borderRadius={'20px'}
        flexDirection={'column'}
        boxShadow={'lg'}
        justifyContent={'center'}
      >
        <GridItem colSpan={3}>
          <Flex p="20px" bg="white" borderRadius={'20px'} flexDirection={'column'}>
            <Text fontSize={'24px'} fontWeight={'800'} lineHeight={'24px'} mt="12px">
              Planos
            </Text>
            <Text fontSize={'24px'} fontWeight={'800'} lineHeight={'24px'} mb="12px">
              e Recursos
            </Text>
            <Text fontSize={'0.85rem'} mb="0px">
              Planos para o seu negócio crescer e atingir
            </Text>
            <Text fontSize={'0.85rem'} mb="10px">
              a máxima performance comercial.
            </Text>

            <TableContainer>
              <Table variant="simple" size="sm" width={'100%'}>
                <Thead>
                  <Tr>
                    <Th>Recursos</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  <Tr>
                    <Td>Lista de todas as empresas do Brasil</Td>
                  </Tr>
                  <Tr>
                    <Td>
                      <Flex fontSize={'14px'} width={'50px'} flexDirection={'column'}>
                        <Text flexWrap={'wrap'}>Busca por CNAEs, região, Capital Social,</Text>
                        <Text flexWrap={'wrap'}>Termo, Natureza Jurídica, Porte, Cep, DDD...</Text>
                      </Flex>
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>Adicione novas empresas no cadastro</Td>
                  </Tr>
                  <Tr>
                    <Td>CRM e gestão de vendas</Td>
                  </Tr>
                  <Tr>
                    <Td>Funil de vendas personalizado</Td>
                  </Tr>
                  <Tr>
                    <Td>Gestão de Atividades Comerciais</Td>
                  </Tr>
                  <Tr>
                    <Td>Gestão de Negócios</Td>
                  </Tr>
                  <Tr>
                    <Td>Dashboard Comercial</Td>
                  </Tr>
                  <Tr>
                    <Td>Formulário de Qualificação</Td>
                  </Tr>
                  <Tr>
                    <Td>Cadastro de ICP (Cliente Ideal)</Td>
                  </Tr>
                  <Tr>
                    <Td>Aceleração de Atividades</Td>
                  </Tr>
                  <Tr>
                    <Td>Mapeamento de Processo Comercial</Td>
                  </Tr>
                  <Tr>
                    <Td>Automação Comercial</Td>
                  </Tr>
                  <Tr>
                    <Td>Modelos de Mensagens de prospecção</Td>
                  </Tr>
                  <Tr>
                    <Td>Integração VOIP</Td>
                  </Tr>
                  <Tr>
                    <Td>Gravação de ligações</Td>
                  </Tr>
                  <Tr>
                    <Td>Gestão Comercial Avançada</Td>
                  </Tr>
                </Tbody>
                <Tfoot>
                  <Tr>
                    <Th textAlign={'center'}>
                      <Text my="5px" borderTop={'1px solid #eaeaea'} p="10px" textAlign={'left'}>
                        Mensalidade
                      </Text>
                    </Th>
                  </Tr>
                </Tfoot>
              </Table>
            </TableContainer>
          </Flex>
        </GridItem>
        <GridItem colSpan={2}>
          <Flex p="20px" bg="white" borderRadius={'20px'} flexDirection={'column'} boxShadow={'lg'}>
            <Text
              fontSize={'22px'}
              fontWeight={'800'}
              textAlign={'center'}
              lineHeight={'24px'}
              mt="12px"
              mb="12px"
              px="20px"
            >
              Listas para Prospecção
            </Text>
            <Text fontSize={'0.85rem'} mb="10px" textAlign={'center'}>
              Busque por todas as empresas do Brasil.
            </Text>

            <TableContainer>
              <Table variant="simple" size="sm" width={'100%'}>
                <Thead>
                  <Tr>
                    <Th textAlign={'center'}>Prospecção</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  <Tr>
                    <Td textAlign={'center'}>Ilimitado</Td>
                  </Tr>
                  <Tr>
                    <Td textAlign={'center'} alignItems={'center'} height={'48px'}>
                      <CheckIcon color={'green.300'} />
                    </Td>
                  </Tr>
                  <Tr>
                    <Td textAlign={'center'}>Até 300</Td>
                  </Tr>
                  <Tr>
                    <Td textAlign={'center'}>
                      <MinusIcon color={'gray.300'} />
                    </Td>
                  </Tr>
                  <Tr>
                    <Td textAlign={'center'}>
                      <MinusIcon color={'gray.300'} />
                    </Td>
                  </Tr>
                  <Tr>
                    <Td textAlign={'center'}>
                      <MinusIcon color={'gray.300'} />
                    </Td>
                  </Tr>
                  <Tr>
                    <Td textAlign={'center'}>
                      <MinusIcon color={'gray.300'} />
                    </Td>
                  </Tr>
                  <Tr>
                    <Td textAlign={'center'}>
                      <MinusIcon color={'gray.300'} />
                    </Td>
                  </Tr>
                  <Tr>
                    <Td textAlign={'center'}>
                      <MinusIcon color={'gray.300'} />
                    </Td>
                  </Tr>
                  <Tr>
                    <Td textAlign={'center'}>
                      <MinusIcon color={'gray.300'} />
                    </Td>
                  </Tr>
                  <Tr>
                    <Td textAlign={'center'}>
                      <MinusIcon color={'gray.300'} />
                    </Td>
                  </Tr>
                  <Tr>
                    <Td textAlign={'center'}>
                      <MinusIcon color={'gray.300'} />
                    </Td>
                  </Tr>
                  <Tr>
                    <Td textAlign={'center'}>
                      <MinusIcon color={'gray.300'} />
                    </Td>
                  </Tr>
                  <Tr>
                    <Td textAlign={'center'}>
                      <MinusIcon color={'gray.300'} />
                    </Td>
                  </Tr>
                  <Tr>
                    <Td textAlign={'center'}>
                      <MinusIcon color={'gray.300'} />
                    </Td>
                  </Tr>
                  <Tr>
                    <Td textAlign={'center'}>
                      <MinusIcon color={'gray.300'} />
                    </Td>
                  </Tr>
                  <Tr>
                    <Td textAlign={'center'}>
                      <MinusIcon color={'gray.300'} />
                    </Td>
                  </Tr>
                </Tbody>
                <Tfoot>
                  <Tr>
                    <Th textAlign={'center'}>
                      <Text borderTop={'1px solid #eaeaea'} py="10px" my="5px">
                        à partir de R$ 370
                      </Text>
                      <Button borderRadius={'20px'} colorScheme={'blue'} background={'#49BFFF'}>
                        Selecionar
                      </Button>
                    </Th>
                  </Tr>
                </Tfoot>
              </Table>
            </TableContainer>
          </Flex>
        </GridItem>
        <GridItem colSpan={2}>
          <Flex p="20px" bg="white" borderRadius={'20px'} flexDirection={'column'} boxShadow={'lg'}>
            <Text
              fontSize={'22px'}
              fontWeight={'800'}
              textAlign={'center'}
              lineHeight={'24px'}
              mt="12px"
              mb="12px"
              px="20px"
            >
              Funil + CRM Comercial
            </Text>

            <Text fontSize={'0.85rem'} mb="10px" textAlign={'center'}>
              Gerencie suas oportunidades e atividades comerciais.
            </Text>
            <TableContainer>
              <Table variant="simple" size="sm" width={'100%'}>
                <Thead>
                  <Tr>
                    <Th textAlign={'center'}>CRM Pro</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  <Tr>
                    <Td textAlign={'center'}>Ilimitado</Td>
                  </Tr>
                  <Tr>
                    <Td textAlign={'center'} alignItems={'center'} height={'48px'}>
                      <CheckIcon color={'green.300'} />
                    </Td>
                  </Tr>
                  <Tr>
                    <Td textAlign={'center'}>Até 500</Td>
                  </Tr>
                  <Tr>
                    <Td textAlign={'center'}>
                      <CheckIcon color={'green.300'} />
                    </Td>
                  </Tr>
                  <Tr>
                    <Td textAlign={'center'}>Até 3</Td>
                  </Tr>
                  <Tr>
                    <Td textAlign={'center'}>
                      <CheckIcon color={'green.300'} />
                    </Td>
                  </Tr>
                  <Tr>
                    <Td textAlign={'center'}>
                      <CheckIcon color={'green.300'} />
                    </Td>
                  </Tr>
                  <Tr>
                    <Td textAlign={'center'}>
                      <CheckIcon color={'green.300'} />
                    </Td>
                  </Tr>
                  <Tr>
                    <Td textAlign={'center'}>
                      <CheckIcon color={'green.300'} />
                    </Td>
                  </Tr>
                  <Tr>
                    <Td textAlign={'center'}>
                      <CheckIcon color={'green.300'} />
                    </Td>
                  </Tr>
                  <Tr>
                    <Td textAlign={'center'}>
                      <CheckIcon color={'green.300'} />
                    </Td>
                  </Tr>
                  <Tr>
                    <Td textAlign={'center'}>
                      <MinusIcon color={'gray.300'} />
                    </Td>
                  </Tr>
                  <Tr>
                    <Td textAlign={'center'}>
                      <MinusIcon color={'gray.300'} />
                    </Td>
                  </Tr>
                  <Tr>
                    <Td textAlign={'center'}>
                      <MinusIcon color={'gray.300'} />
                    </Td>
                  </Tr>
                  <Tr>
                    <Td textAlign={'center'}>
                      <MinusIcon color={'gray.300'} />
                    </Td>
                  </Tr>
                  <Tr>
                    <Td textAlign={'center'}>
                      <MinusIcon color={'gray.300'} />
                    </Td>
                  </Tr>
                  <Tr>
                    <Td textAlign={'center'}>
                      <MinusIcon color={'gray.300'} />
                    </Td>
                  </Tr>
                </Tbody>
                <Tfoot>
                  <Tr>
                    <Th textAlign={'center'}>
                      <Text borderTop={'1px solid #eaeaea'} py="10px" my="5px">
                        à partir de R$ 670
                      </Text>
                      <Button borderRadius={'20px'} colorScheme={'blue'} background={'#49BFFF'}>
                        Selecionar
                      </Button>
                    </Th>
                  </Tr>
                </Tfoot>
              </Table>
            </TableContainer>
          </Flex>
        </GridItem>
        <GridItem colSpan={2}>
          <Flex p="20px" bg="white" borderRadius={'20px'} flexDirection={'column'} boxShadow={'lg'}>
            <Text
              fontSize={'22px'}
              fontWeight={'800'}
              textAlign={'center'}
              lineHeight={'24px'}
              mt="12px"
              mb="12px"
              px="20px"
            >
              Performance em escala
            </Text>

            <Text fontSize={'0.85rem'} mb="10px" textAlign={'center'}>
              Escala comercial, automação e otimização de processos.
            </Text>
            <TableContainer>
              <Table variant="simple" size="sm" width={'100%'}>
                <Thead>
                  <Tr>
                    <Th textAlign={'center'}>Performance</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  <Tr>
                    <Td textAlign={'center'}>Ilimitado</Td>
                  </Tr>
                  <Tr>
                    <Td textAlign={'center'} alignItems={'center'} height={'48px'}>
                      <CheckIcon color={'green.300'} />
                    </Td>
                  </Tr>
                  <Tr>
                    <Td textAlign={'center'}>Ilimitado</Td>
                  </Tr>
                  <Tr>
                    <Td textAlign={'center'}>
                      <CheckIcon color={'green.300'} />
                    </Td>
                  </Tr>
                  <Tr>
                    <Td textAlign={'center'}>Ilimitado</Td>
                  </Tr>
                  <Tr>
                    <Td textAlign={'center'}>
                      <CheckIcon color={'green.300'} />
                    </Td>
                  </Tr>
                  <Tr>
                    <Td textAlign={'center'}>
                      <CheckIcon color={'green.300'} />
                    </Td>
                  </Tr>
                  <Tr>
                    <Td textAlign={'center'}>
                      <CheckIcon color={'green.300'} />
                    </Td>
                  </Tr>
                  <Tr>
                    <Td textAlign={'center'}>
                      <CheckIcon color={'green.300'} />
                    </Td>
                  </Tr>
                  <Tr>
                    <Td textAlign={'center'}>
                      <CheckIcon color={'green.300'} />
                    </Td>
                  </Tr>
                  <Tr>
                    <Td textAlign={'center'}>
                      <CheckIcon color={'green.300'} />
                    </Td>
                  </Tr>
                  <Tr>
                    <Td textAlign={'center'}>
                      <CheckIcon color={'green.300'} />
                    </Td>
                  </Tr>
                  <Tr>
                    <Td textAlign={'center'}>
                      <CheckIcon color={'green.300'} />
                    </Td>
                  </Tr>
                  <Tr>
                    <Td textAlign={'center'}>
                      <CheckIcon color={'green.300'} />
                    </Td>
                  </Tr>
                  <Tr>
                    <Td textAlign={'center'}>
                      <CheckIcon color={'green.300'} />
                    </Td>
                  </Tr>
                  <Tr>
                    <Td textAlign={'center'}>
                      <CheckIcon color={'green.300'} />
                    </Td>
                  </Tr>
                  <Tr>
                    <Td textAlign={'center'}>
                      <CheckIcon color={'green.300'} />
                    </Td>
                  </Tr>
                </Tbody>
                <Tfoot>
                  <Tr>
                    <Th textAlign={'center'}>
                      <Text borderTop={'1px solid #eaeaea'} py="10px" my="5px">
                        à partir de R$ 1.370
                      </Text>
                      <Button borderRadius={'20px'} colorScheme={'blue'} background={'#49BFFF'}>
                        Selecionar
                      </Button>
                    </Th>
                  </Tr>
                </Tfoot>
              </Table>
            </TableContainer>
          </Flex>
        </GridItem>
        <GridItem colSpan={2}>
          <Flex p="20px" bg="white" borderRadius={'20px'} flexDirection={'column'} boxShadow={'lg'}>
            <Text
              fontSize={'22px'}
              fontWeight={'800'}
              textAlign={'center'}
              lineHeight={'24px'}
              mt="12px"
              mb="12px"
              px="20px"
            >
              Performance Gerenciada
            </Text>
            <Text fontSize={'0.85rem'} mb="10px" textAlign={'center'}>
              Alta performance e gestão comercial profissional.
            </Text>
            <TableContainer>
              <Table variant="simple" size="sm" width={'100%'}>
                <Thead>
                  <Tr>
                    <Th textAlign={'center'}>Performance</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  <Tr>
                    <Td textAlign={'center'}>Ilimitado</Td>
                  </Tr>
                  <Tr>
                    <Td textAlign={'center'} alignItems={'center'} height={'48px'}>
                      <CheckIcon color={'green.300'} />
                    </Td>
                  </Tr>
                  <Tr>
                    <Td textAlign={'center'}>Ilimitado</Td>
                  </Tr>
                  <Tr>
                    <Td textAlign={'center'}>
                      <CheckIcon color={'green.300'} />
                    </Td>
                  </Tr>
                  <Tr>
                    <Td textAlign={'center'}>Ilimitado</Td>
                  </Tr>
                  <Tr>
                    <Td textAlign={'center'}>
                      <CheckIcon color={'green.300'} />
                    </Td>
                  </Tr>
                  <Tr>
                    <Td textAlign={'center'}>
                      <CheckIcon color={'green.300'} />
                    </Td>
                  </Tr>
                  <Tr>
                    <Td textAlign={'center'}>
                      <CheckIcon color={'green.300'} />
                    </Td>
                  </Tr>
                  <Tr>
                    <Td textAlign={'center'}>
                      <CheckIcon color={'green.300'} />
                    </Td>
                  </Tr>
                  <Tr>
                    <Td textAlign={'center'}>
                      <CheckIcon color={'green.300'} />
                    </Td>
                  </Tr>
                  <Tr>
                    <Td textAlign={'center'}>
                      <CheckIcon color={'green.300'} />
                    </Td>
                  </Tr>
                  <Tr>
                    <Td textAlign={'center'}>
                      <CheckIcon color={'green.300'} />
                    </Td>
                  </Tr>
                  <Tr>
                    <Td textAlign={'center'}>
                      <CheckIcon color={'green.300'} />
                    </Td>
                  </Tr>
                  <Tr>
                    <Td textAlign={'center'}>
                      <CheckIcon color={'green.300'} />
                    </Td>
                  </Tr>
                  <Tr>
                    <Td textAlign={'center'}>
                      <CheckIcon color={'green.300'} />
                    </Td>
                  </Tr>
                  <Tr>
                    <Td textAlign={'center'}>
                      <CheckIcon color={'green.300'} />
                    </Td>
                  </Tr>
                  <Tr>
                    <Td textAlign={'center'}>
                      <CheckIcon color={'green.300'} />
                    </Td>
                  </Tr>
                </Tbody>
                <Tfoot>
                  <Tr>
                    <Th textAlign={'center'}>
                      <Text borderTop={'1px solid #eaeaea'} py="10px" my="5px">
                        à partir de R$ 3.670
                      </Text>
                      <Button borderRadius={'20px'} colorScheme={'blue'} background={'#49BFFF'}>
                        Selecionar
                      </Button>
                    </Th>
                  </Tr>
                </Tfoot>
              </Table>
            </TableContainer>
          </Flex>
        </GridItem>
      </Grid>
    </Flex>
  )
}
