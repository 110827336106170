import React, { useState, useEffect, useRef } from 'react'
import {
  IconButton,
  Button,
  FormControl,
  Flex,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  useColorModeValue,
  Tag,
  Text,
  Grid,
  GridItem,
  useDisclosure,
  Link
} from '@chakra-ui/react'
import { useParams } from 'react-router-dom'
import axios from 'api/axios'
import authHeader from 'hooks/authHeader'
import Card from 'components/card/Card'
import { capitalizeText } from 'hooks/capitalizeText'
import { getUserAccountId } from 'hooks/authService'
import pipelineStatus from 'components/setPipelineStatusName'
import {
  FaWhatsapp,
  FaInstagram,
  FaLinkedin,
  FaFacebook,
  FaBuilding,
  FaPhone,
  FaBriefcase,
  FaUsers,
  FaGlobe,
  FaMobile,
  FaSearch,
  FaFileAlt,
  FaGoogle,
  FaEnvelopeOpen,
  FaEdit,
  FaTwitter,
  FaYoutube
} from 'react-icons/fa'
import { InputText } from 'primereact/inputtext'

import { confirmDialog, ConfirmDialog } from 'primereact/confirmdialog'
import { Dropdown } from 'primereact/dropdown'
import { porteEmpresas } from 'contexts/dadosBrasileiros'
import { InputTextarea } from 'primereact/inputtextarea'

import { Toast } from 'primereact/toast'
import CompanyContacts from './companyContacts'
import CompanyDeals from './CompanyDeals'
import QualificationForm from './QualificationForm'
import { formatCNPJ, formatBRL } from 'hooks/formatBRL'

import { FormAddress } from './formAddress'
import ContactDataForm from './contactDataForm'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'

export default function CompanyForm({ dados }) {
  const textColor = useColorModeValue('navy.700', 'white')
  const { onOpen, onClose, isOpen } = useDisclosure()
  const firstFieldRef = React.useRef(null)
  const [defaultStepId, setDefaultStepId] = useState(null)

  const [refreshComponent, setRefreshComponent] = useState()

  const location = useLocation()
  const routeConsulta = location.pathname.includes('/admin/novaConsulta')
  const companyId = parseInt(location.pathname.replace('/admin/company/', ''), 10)
  // console.log('company_id', company_id)
  // console.log('routeConsulta', routeConsulta)

  const accountId = getUserAccountId()
  const telefone1 = (dados && dados.estabelecimento.telefone1) || null
  const telefone2 = (dados && dados.estabelecimento.telefone2) || null

  // const { company_id: companyIdFromParams } = useParams() || ''

  const [company_id, setCompanyId] = useState(companyId)
  const [companyContacts, setCompanyContacts] = useState([])
  // const [contactId, setContactId] = useState(companyIdFromParams)
  const [loading, setLoading] = useState(false)
  const [isEmailValid, setIsEmailValid] = useState(true)

  const [formData, setFormData] = useState({
    account_id: accountId,
    name: (dados && capitalizeText(dados.estabelecimento.nome_fantasia)) || '',
    business_data: {
      cnpj: (dados && dados.estabelecimento.cnpj) || '',
      razao_social: (dados && capitalizeText(dados.razao_social)) || '',
      situacao_cadastral: (dados && dados.estabelecimento.situacao_cadastral) || '',
      natureza_juridica:
        `${dados && dados.natureza_juridica.descricao} (${dados && dados.natureza_juridica.id})` || '',
      porte: (dados && dados.porte.descricao) || '', // Micro Empresa, Demais
      data_abertura: (dados && dados.estabelecimento.data_inicio_atividade) || '',
      capital_social: (dados && dados.capital_social) || '',
      optante_simples: (dados && dados.simples) || '',
      tipo: (dados && dados.estabelecimento.tipo) || '', // matriz/filial
      atividade_principal: {
        cnae:
          `(${dados && dados.estabelecimento.atividade_principal.subclasse}) ${dados && dados.estabelecimento.atividade_principal.descricao}` ||
          ''
      },
      atividade_secundaria: [
        {
          cnae: ''
        }
      ]
    },
    contact_data: {
      telefone: [
        {
          tipo: (telefone1 && setPhoneType(telefone1)) || '', // Fixo, whatsapp, móvel, voip
          numero: (telefone1 && `55 (${dados.estabelecimento.ddd1}) ${dados.estabelecimento.telefone1}`) || ''
        },
        {
          tipo: (telefone2 && setPhoneType(telefone2)) || '', // Fixo, whatsapp, móvel, voip
          numero: (telefone2 && `55 (${dados.estabelecimento.ddd2}) ${dados.estabelecimento.telefone2}`) || ''
        }
      ],
      email: [
        {
          tipo: 'CNPJ', // principal, suporte, ...
          endereco_email: (dados && dados.estabelecimento.email) || ''
        }
      ]
    },
    address: {
      pais: (dados && dados.estabelecimento.pais.nome) || '',
      uf: (dados && dados.estabelecimento.estado.sigla) || '',
      municipio: (dados && dados.estabelecimento.cidade.nome) || '',
      bairro: (dados && capitalizeText(dados.estabelecimento.bairro)) || '',
      tipo_logradouro: (dados && capitalizeText(dados.estabelecimento.tipo_logradouro)) || '',
      logradouro: (dados && capitalizeText(dados.estabelecimento.logradouro)) || '',
      numero: (dados && dados.estabelecimento.numero) || '',
      cep: (dados && dados.estabelecimento.cep) || ''
    },
    pipeline: 'new',
    profile: {
      area_atuacao: '',
      funcionarios: '',
      faturamento_anual: '',
      descricao: ''
    },
    company_logo_url: ''
  })

  // const { status, color } = pipelineStatus(formData?.pipeline)

  const [contactForm, setContactForm] = useState({
    account_id: accountId && parseInt(accountId, 10),
    company_id: (companyId && parseInt(companyId, 10)) || '',
    title: '',
    first_name: '',
    last_name: '',
    position: '',
    contact_data: {
      email: [{ email: '' }],
      telefone: [{ numero: '', tipo: '' }],
      linkedin: '',
      instagram: '',
      facebook: '',
      x: '',
      website: '',
      youtube: '',
      address: {
        endereco: '',
        numero: '',
        complemento: '',
        bairro: '',
        cep: '',
        municipio: '',
        uf: '',
        pais: ''
      },
      descricao: '',
      faixa_etaria: ''
    }
  })

  useEffect(() => {
    getDefaultPipelineStep()
    getCompanyData()
    addSociosToState()
  }, [refreshComponent])

  useEffect(() => {
    console.log('CompanyForms companyContacts', companyContacts)
  }, [companyContacts])

  function addSociosToState() {
    return (
      dados &&
      Array.isArray(dados.socios) &&
      dados.socios.map((socio) => {
        const { first_name, last_name } = splitName(socio.nome)

        const contato = {
          account_id: accountId,
          company_id: companyId || null,
          title: '',
          first_name,
          last_name,
          position: socio?.qualificacao_socio?.descricao || '',
          contact_data: {
            x: '',
            email: [
              {
                email: ''
              }
            ],
            address: {
              uf: '',
              cep: '',
              pais: '',
              bairro: '',
              numero: '',
              endereco: '',
              municipio: '',
              complemento: ''
            },
            facebook: '',
            linkedin: '',
            telefone: [
              {
                numero: ''
              }
            ],
            descricao: '',
            instagram: '',
            faixa_etaria: socio.faixa_etaria || ''
          }
        }
        setCompanyContacts((prevContacts) => [...prevContacts, contato])
      })
    )
  }

  async function getCNPJData(cnpj) {
    try {
      const response = await fetch(`https://publica.cnpj.ws/cnpj/${cnpj}`)
      const data = await response.json()
      console.log('Response CNPJ API:', data)

      let nomeFantasia

      if (!data.estabelecimento.nome_fantasia) {
        nomeFantasia = data.razao_social
      } else {
        nomeFantasia = data.estabelecimento.nome_fantasia
      }

      setFormData((prevState) => ({
        ...prevState,
        name: nomeFantasia,
        business_data: {
          cnpj,
          razao_social: data.razao_social,
          situacao_cadastral: data.estabelecimento.situacao_cadastral,
          natureza_juridica: `${data.natureza_juridica.descricao} (${data.natureza_juridica.id})`,
          porte: data.porte.descricao,
          data_abertura: data.estabelecimento.data_inicio_atividade,
          capital_social: data.capital_social,
          optante_simples: data.simples && data.simples.simples,
          tipo: data.estabelecimento.tipo, // matriz/filial
          atividade_principal: {
            cnae: `${data.estabelecimento.atividade_principal.subclasse} - ${data.estabelecimento.atividade_principal.descricao}`
          },
          atividade_secundaria: [
            {
              cnae: '',
              descricao: ''
            }
          ]
        },
        contact_data: {
          telefone: [
            {
              tipo: 'fixo', // Fixo, whatsapp, móvel, voip
              numero: `55${data.estabelecimento.ddd1}${data.estabelecimento.telefone1}`
            }
          ],
          email: [
            {
              tipo: '', // principal, suporte, ...
              endereco_email: ''
            }
          ]
        },
        address: {
          pais: data.estabelecimento.pais.nome,
          uf: data.estabelecimento.estado.sigla,
          municipio: data.estabelecimento.cidade.nome,
          bairro: data.estabelecimento.bairro,
          tipo_logradouro: data.estabelecimento.tipo_logradouro,
          logradouro: data.estabelecimento.logradouro,
          numero: data.estabelecimento.numero,
          cep: data.estabelecimento.cep
        }
      }))
    } catch (error) {
      console.error('Error fetching CNPJ data:', error)
    }
  }

  async function getCompanyData() {
    if (!companyId) return

    setLoading(true)
    try {
      const { data } = await axios.get(`/companies?account_id=${accountId}&id=${companyId}`, {
        headers: authHeader()
      })
      setFormData(data.data[0])
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log('Erro ao carregar dados da empresa: ', error)
    }
  }

  async function getDefaultPipelineStep() {
    if (!accountId) return
    try {
      const response = await axios.get(`/pipelines?account_id=${accountId}`, { headers: authHeader() })
      const defaultStepId = getDefaultStepId(response.data.data)
      setDefaultStepId(defaultStepId)

      console.log('Account Pipelines:', response.data.data)
    } catch (error) {
      console.log('Erro ao carregar dados da empresa: ', error)
    }
  }

  function getDefaultStepId(data) {
    for (const pipeline of data) {
      if (pipeline.order === 1) {
        const step = pipeline.steps.find((step) => step.order === 1)
        if (step) {
          return step.id
        }
      }
    }
    return null // Retorna null se não encontrar
  }

  const handleChange = (e) => {
    const { name, value } = e.target

    setFormData((prevState) => {
      const newState = { ...prevState }

      const keys = name.split('.') // Divide o name em um array
      let temp = newState

      for (let i = 0; i < keys.length - 1; i++) {
        if (!temp[keys[i]]) {
          temp[keys[i]] = {}
        }
        temp = temp[keys[i]]
      }

      temp[keys[keys.length - 1]] = value

      return newState
    })
  }

  async function handleDeleteCompany() {
    if (!company_id) return

    try {
      const response = axios.delete(`/companies/${company_id}`, { headers: authHeader() })
      console.log('Empresa excluída!', response)
      toastSuccess('Empresa excluída.')
      // setTimeout(() => {
      //   window.location.href = '/admin/companies'
      // }, 3000)
    } catch (error) {
      console.log('Erro ao excluir a empresa.', error)
      toastError('Não foi possível excluir esta empresa.')
    }
  }

  const confirmDelete = () => {
    confirmDialog({
      header: 'Confirmação de Exclusão',
      message: 'Tem certeza que deseja excluir esta empresa?',
      icon: 'pi pi-exclamation-triangle text-red-700',
      // acceptClassName: 'p-2 border-round-2xl ',
      rejectClassName: 'p-1 border-round-2xl text-blue-400 ',
      acceptLabel: 'Excluir',
      rejectLabel: 'Cancelar',
      accept: () => handleDeleteCompany(), // Chama a função de exclusão se o usuário confirmar
      reject: () => console.log('Exclusão cancelada') // Ação se o usuário cancelar
    })
  }

  const handleCheckboxProfileChange = (event) => {
    const value = event.target.value
    setFormData((prevContactProfile) => {
      const newCompanyProfile = JSON.parse(JSON.stringify(prevContactProfile))

      if (event.target.checked) {
        newCompanyProfile.owner_data.profile.push(value)
      } else {
        newCompanyProfile.owner_data.profile = newCompanyProfile.owner_data.profile.filter(
          (item) => item !== value
        )
      }

      return newCompanyProfile
    })
  }

  function splitName(fullName) {
    const fullNameCapitalized = capitalizeText(fullName)
    const nameParts = fullNameCapitalized.trim().split(' ')
    const first_name = nameParts[0]
    const last_name = nameParts.slice(1).join(' ')
    return {
      first_name,
      last_name
    }
  }

  function setPhoneType(number) {
    const isMobile = number.startsWith('9')
    return isMobile ? 'Móvel' : 'Fixo'
  }

  const toast = useRef(null)
  const toastSuccess = (message) => {
    toast.current.show({ severity: 'success', summary: 'Success', detail: message })
  }
  const toastError = (message) => {
    toast.current.show({
      severity: 'error',
      summary: 'Error',
      detail: message
    })
  }

  function normalizePhoneNumber(phoneNumber) {
    // Remove tudo que não é dígito
    const digitsOnly = phoneNumber.replace(/\D/g, '')
    return digitsOnly
  }

  function normalizeDate(dateString) {
    const [year, month, day] = dateString.split('-')
    return `${day}/${month}/${year}`
  }

  const handleSubmit = async (event) => {
    event.preventDefault()

    console.log('formData antes do envio:', formData)

    if (company_id) {
      try {
        const response = await axios.patch(`/companies/${company_id}`, formData, {
          headers: authHeader()
        })
        console.log('Dados atualizados com sucesso!', response.data)
        toastSuccess('Dados atualizados!')
        // setAddCompanyAnimal(true)
      } catch (error) {
        toastError('Não foi possível adicionar os dados.')
        console.error('Ocorreu um erro ao enviar os dados:', error.response.data)
      }
    } else {
      try {
        const response = await axios.post('/companies', formData, {
          headers: authHeader()
        })
        console.log('Empresa cadastrada com sucesso!', response.data)
        // setCompanyId(response.data.id)
        // setAddCompanyAnimal(true)

        companyContacts &&
          companyContacts.length > 0 &&
          companyContacts.map(async (contact) => {
            const formContact = {
              account_id: contact.account_id,
              company_id: response.data.id,
              title: contact.title,
              first_name: contact.first_name,
              last_name: contact.last_name,
              position: contact.position,
              contact_data: {
                x: '',
                email: [
                  {
                    email: ''
                  }
                ],
                address: {
                  uf: '',
                  cep: '',
                  pais: '',
                  bairro: '',
                  numero: '',
                  endereco: '',
                  municipio: '',
                  complemento: ''
                },
                facebook: '',
                linkedin: '',
                telefone: [
                  {
                    numero: ''
                  }
                ],
                descricao: '',
                instagram: '',
                faixa_etaria: contact.contact_data.faixa_etaria
              }
            }

            const responseContact = await axios.post(`/contacts`, formContact, {
              headers: authHeader()
            })
            console.log('Contato cadastrado: ', responseContact.data)
          })
        toastSuccess('Empresa cadastrada')
      } catch (error) {
        console.error('Ocorreu um erro ao cadastrar a empresa:', error)
        toastError('Houve um erro')
      }
    }
    console.log(formData)
  }

  const handleSubmitNewDeal = async (event) => {
    event.preventDefault()

    let newCompanyId
    let newContactId // Variável para armazenar o ID do último contato criado

    console.log('formData antes do envio:', formData)

    if (company_id) {
      try {
        const response = await axios.patch(`/companies/${company_id}`, formData, {
          headers: authHeader()
        })
        console.log('Dados atualizados com sucesso!', response.data)
        toastSuccess('Dados atualizados!')
      } catch (error) {
        toastError('Não foi possível adicionar os dados.')
        console.error('Ocorreu um erro ao enviar os dados:', error.response.data)
      }
    } else {
      try {
        // Criação da empresa
        const response = await axios.post('/companies', formData, {
          headers: authHeader()
        })
        console.log('Empresa cadastrada com sucesso!', response.data)
        newCompanyId = response.data.id
        setCompanyId(response.data.id)

        // Criação dos contatos
        if (companyContacts && companyContacts.length > 0) {
          const contactPromises = companyContacts.map(async (contact) => {
            const formContact = {
              account_id: contact.account_id,
              company_id: response.data.id,
              title: contact.title,
              first_name: contact.first_name,
              last_name: contact.last_name,
              position: contact.position,
              contact_data: {
                x: '',
                email: [
                  {
                    email: ''
                  }
                ],
                address: {
                  uf: '',
                  cep: '',
                  pais: '',
                  bairro: '',
                  numero: '',
                  endereco: '',
                  municipio: '',
                  complemento: ''
                },
                facebook: '',
                linkedin: '',
                telefone: [
                  {
                    numero: ''
                  }
                ],
                descricao: '',
                instagram: '',
                faixa_etaria: contact.contact_data.faixa_etaria
              }
            }

            const responseContact = await axios.post('/contacts', formContact, {
              headers: authHeader()
            })
            console.log('Contato cadastrado: ', responseContact.data)
            return responseContact.data.id // Retorna o ID do contato criado
          })

          const contactIds = await Promise.all(contactPromises) // Aguarda todas as promessas
          newContactId = contactIds[contactIds.length - 1] // Pega o último ID
        }

        // Criação do negócio
        const dealData = {
          account_id: accountId,
          company_id: newCompanyId,
          contact_id: newContactId, // Usa o último contato criado
          name: formData.name,
          deal_data: {
            description: ''
          },
          step_id: parseInt(defaultStepId, 10)
        }

        const responseDeal = await axios.post('/deals', dealData, {
          headers: authHeader()
        })
        console.log('Negócio Criado:', responseDeal)

        toastSuccess('Empresa e negócio cadastrados.')
      } catch (error) {
        console.error('Ocorreu um erro ao cadastrar a empresa:', error)
        toastError('Houve um erro')
      }
    }

    console.log(formData)
  }

  const handleContactChange = (event) => {
    const { name, value } = event.target

    console.log('HandleChange ContactPopover', name, value)

    // Handle nested fields like email and telefone
    if (name === 'email') {
      setContactForm((prevState) => ({
        ...prevState,
        contact_data: {
          ...prevState.contact_data,
          email: [{ email: value }]
        }
      }))
    } else if (name === 'telefone') {
      setContactForm((prevState) => ({
        ...prevState,
        contact_data: {
          ...prevState.contact_data,
          telefone: [{ numero: value }]
        }
      }))
    } else if (name === 'linkedin') {
      setContactForm((prevState) => ({
        ...prevState,
        contact_data: {
          ...prevState.contact_data,
          linkedin: value
        }
      }))
    } else {
      // Handle other fields directly
      setContactForm((prevState) => ({
        ...prevState,
        [name]: value
      }))
    }
  }

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

  const handleEmailChange = (e) => {
    const value = e.target.value
    setContactForm((prevState) => ({
      ...prevState,
      contact_data: {
        ...prevState.contact_data,
        email: [{ email: value }]
      }
    }))

    // Validação do e-mail
    if (emailRegex.test(value)) {
      setIsEmailValid(true)
    } else {
      setIsEmailValid(false)
    }
  }

  const handleContactSubmit = async (event) => {
    event.preventDefault()
    try {
      const response = await axios.post('/contacts', contactForm, { headers: authHeader() })
      console.log('Contato adicionado.', response)
      toastSuccess('Contato adicionado!')
      onClose()
    } catch (error) {
      toastError('Houve um erro!')
      console.log('Erro ao adicionar o contato.', error)
    }
  }

  function redirectToCompanyPage(company_id) {
    window.location.href = `/admin/company/${company_id}`
  }

  return (
    <Grid templateColumns="repeat(5, 1fr)" gap="20px" w={'full'}>
      <GridItem colSpan={3}>
        <Card p="20px">
          <Flex justifyContent={'space-between'}>
            <Flex flexDirection={'column'} w="full">
              <Flex justifyContent="space-between" alignItems={'center'} mb="16px">
                <Link
                  href={`https://www.google.com/search?q=${formData.business_data.razao_social}+${formData.address.uf}+${formData.address.municipio}`}
                  target="_blank"
                >
                  <Flex alignItems={'center'}>
                    <FaGoogle color="#E2E8F0" />
                    <Text display="flex" fontSize="xl" fontWeight="800" ml="10px" color={textColor}>
                      {formData.business_data.razao_social}
                    </Text>
                  </Flex>
                </Link>
                <Flex>
                  <Dropdown
                    name="pipeline"
                    value={loading ? '' : formData.pipeline}
                    loading={loading ? true : false}
                    onChange={handleChange}
                    placeholder={loading ? 'Carregando' : 'Selecione o porte'}
                    options={[
                      { name: 'Nova', value: 'new' },
                      { name: 'Contatando', value: 'approaching' },
                      { name: 'Contatada', value: 'contacted' },
                      { name: 'Sem Fit', value: 'no-fit' },
                      { name: 'Qualificada', value: 'qualified' },
                      // { name: 'Nutrição', value: 'nurturing' }, // Avaliar se faz sentido.
                      { name: 'Cliente', value: 'client' }
                    ]}
                    optionLabel="name"
                    className="w-full"
                    style={{ borderRadius: '20px' }}
                    pt={{
                      root: { background: '#fafafa', padding: '3px ', borderRadius: '20px' },
                      input: {
                        style: {
                          width: '100px',
                          background: '#49bfff',
                          fontSize: '14px',
                          padding: '5px 10px',
                          borderRadius: '20px 0 0 20px',
                          color: 'white'
                        }
                      },
                      trigger: {
                        style: {
                          background: '#49bfff',
                          fontSize: '14px',
                          color: 'white',
                          borderRadius: '0 20px 20px 0'
                        }
                      },
                      item: {
                        style: {
                          fontSize: '14px',
                          padding: '8px 16px',
                          width: '100%',
                          background: '#fafafa'
                        }
                      },
                      panel: {
                        style: { marginTop: '2px', height: 'fit-content', borderRadius: '6px' }
                      }
                    }}
                  />
                </Flex>
              </Flex>
              <Flex flexWrap={'wrap'} gap={1} mb="24px">
                {formData.business_data.tipo && (
                  <Tag bg="#E2E8F0" w={'fit-content'} fontSize={'0.7rem'} borderRadius={'20px'}>
                    {formData.business_data.tipo}
                  </Tag>
                )}
                <Tag bg="#E2E8F0" w={'fit-content'} fontSize={'0.7rem'} borderRadius={'20px'}>
                  {`Situação: ${formData.business_data.situacao_cadastral}`}
                </Tag>
                <Tag bg="#E2E8F0" w={'fit-content'} fontSize={'0.7rem'} borderRadius={'20px'}>
                  {`Fundação: ${formData.business_data.data_abertura}`}
                </Tag>
                {formData.business_data.capital_social && (
                  <Tag bg="#E2E8F0" w={'fit-content'} fontSize={'0.7rem'} borderRadius={'20px'}>
                    {`Capital Social: R$ ${formatBRL(formData.business_data.capital_social)}`}
                  </Tag>
                )}
                <Tag bg="#E2E8F0" w={'fit-content'} fontSize={'0.7rem'} borderRadius={'20px'}>
                  {formData.business_data.natureza_juridica}
                </Tag>
                {formData.business_data.optante_simples && (
                  <Tag bg="#E2E8F0" w={'fit-content'} fontSize={'0.7rem'} borderRadius={'20px'}>
                    {`Simples: ${formData.business_data.optante_simples.simples}`}
                  </Tag>
                )}
                {formData.business_data.optante_simples && (
                  <Tag bg="#E2E8F0" w={'fit-content'} fontSize={'0.7rem'} borderRadius={'20px'}>
                    {`MEI: ${formData.business_data.optante_simples.mei}`}
                  </Tag>
                )}
              </Flex>
            </Flex>
            {/* <Button color={color}>{status}</Button> */}
          </Flex>

          <Flex>
            <Grid templateColumns="repeat(4, 1fr)" gap={'20px'} w={'100%'}>
              <GridItem w="100%" colSpan={2}>
                <Text display="flex" fontSize="md" fontWeight="800" color={textColor}>
                  Dados da empresa
                </Text>
                <label
                  style={{
                    color: '#A0AEC0',
                    fontSize: '12px',
                    fontWeight: '500',
                    paddingLeft: '0px'
                  }}
                >
                  CNPJ
                </label>
                <Flex py="0.3rem" mt="-5px" alignItems={'center'}>
                  <div className="p-inputgroup flex-1">
                    <span className="p-inputgroup-addon">
                      <FaBuilding />
                    </span>
                    <InputText
                      value={formatCNPJ(formData.business_data.cnpj)}
                      name="business_data.cnpj"
                      onChange={handleChange}
                      placeholder="CNPJ"
                      v-model="value1"
                      style={{
                        padding: ' 0px 10px',
                        width: '100%',
                        background: '#fafafa',
                        fontSize: '14px'
                      }}
                      className="outline-none"
                    />
                    <button
                      className="p-inputgroup-addon"
                      cursor={'pointer'}
                      style={{ background: '#f3f4f6' }}
                      onClick={() => getCNPJData(formData.business_data.cnpj)}
                    >
                      <FaSearch />
                    </button>
                  </div>
                </Flex>
                <label
                  style={{
                    color: '#A0AEC0',
                    fontSize: '12px',
                    fontWeight: '500',
                    paddingLeft: '0px'
                  }}
                >
                  Nome Fantasia
                </label>
                <Flex py="0.3rem" mt="-5px" alignItems={'center'}>
                  <div className="p-inputgroup flex-1">
                    <span className="p-inputgroup-addon">
                      <FaBuilding />
                    </span>
                    <InputText
                      value={formData.name}
                      name="name"
                      onChange={(e) =>
                        setFormData((prevState) => ({
                          ...prevState,
                          name: e.target.value
                        }))
                      }
                      placeholder="Nome da empresa"
                      v-model="value1"
                      style={{
                        padding: ' 0px 10px',
                        width: '100%',
                        background: '#fafafa',
                        fontSize: '14px'
                      }}
                      className="outline-none"
                    />
                  </div>
                </Flex>

                {/* <label
                    style={{
                      color: '#A0AEC0',
                      fontSize: '12px',
                      fontWeight: '500',
                      paddingLeft: '0px'
                    }}
                  >
                    Faturamento anual
                  </label>
                  <Flex py="0.3rem" mt="-5px" alignItems={'center'}>
                    <div className="p-inputgroup flex-1">
                      <span className="p-inputgroup-addon">
                        <FaIndustry />
                      </span>
                      <InputText
                        value={formData.profile.faturamento_anual}
                        name="profile.faturamento_anual"
                        onChange={handleChange}
                        placeholder="R$..."
                        v-model="value1"
                        style={{
                          padding: ' 0px 10px',
                          width: '100%',
                          background: '#fafafa',
                          fontSize: '14px'
                        }}
                        className="outline-none"
                      />
                    </div>
                  </Flex> */}
                <label
                  style={{
                    color: '#A0AEC0',
                    fontSize: '12px',
                    fontWeight: '500',
                    paddingLeft: '0px'
                  }}
                >
                  Porte da empresa
                </label>
                <Flex py="0.3rem" mt="-5px" alignItems={'center'}>
                  <div className="p-inputgroup flex-1">
                    <span className="p-inputgroup-addon">
                      <FaUsers />
                    </span>
                    <Dropdown
                      name="business_data.porte"
                      onChange={handleChange}
                      placeholder="Selecione o porte"
                      value={formData.business_data.porte}
                      options={porteEmpresas}
                      optionLabel="name"
                      className="w-full"
                      pt={{
                        root: { background: '#fafafa' },
                        input: {
                          style: {
                            width: '100%',
                            background: '#fafafa',
                            fontSize: '14px'
                          }
                        },
                        trigger: {
                          style: {
                            background: '#fafafa',
                            fontSize: '14px'
                          }
                        },
                        item: {
                          style: {
                            fontSize: '14px',
                            padding: '8px 16px',
                            width: '100%',
                            background: '#fafafa'
                          }
                        },
                        panel: {
                          style: { marginTop: '5px' }
                        }
                      }}
                    />
                  </div>
                </Flex>
                {/* <label
                    style={{
                      color: '#A0AEC0',
                      fontSize: '12px',
                      fontWeight: '500',
                      paddingLeft: '0px'
                    }}
                  >
                    Colaboradores
                  </label>
                  <Flex py="0.3rem" mt="-5px" alignItems={'center'}>
                    <div className="p-inputgroup flex-1">
                      <span className="p-inputgroup-addon">
                        <FaUsers />
                      </span>
                      <Dropdown
                        name="profile.funcionarios"
                        onChange={handleChange}
                        placeholder="Número de funcionários"
                        value={formData.profile.funcionarios}
                        options={[
                          { value: '1 a 10', name: '1 a 10 Funcionários' },
                          { value: '10 a 30', name: '10 a 30 Funcionários' },
                          { value: '30 a 70', name: '30 a 70 Funcionários' },
                          { value: '70 a 200', name: '70 a 200 Funcionários' },
                          { value: '200 a 500', name: '200 a 500 Funcionários' },
                          { value: '+500', name: '+500' }
                        ]}
                        optionLabel="name"
                        className="w-full"
                        pt={{
                          root: { background: '#fafafa' },
                          input: {
                            style: {
                              width: '100%',
                              background: '#fafafa',
                              fontSize: '14px'
                            }
                          },
                          trigger: {
                            style: {
                              background: '#fafafa',
                              fontSize: '14px'
                            }
                          },
                          item: {
                            style: {
                              fontSize: '14px',
                              padding: '8px 16px',
                              width: '100%',
                              background: '#fafafa'
                            }
                          },
                          panel: {
                            style: { marginTop: '5px' }
                          }
                        }}
                      />
                    </div>
                  </Flex> */}
                <label
                  style={{
                    color: '#A0AEC0',
                    fontSize: '12px',
                    fontWeight: '500',
                    paddingLeft: '0px'
                  }}
                >
                  Atuação
                </label>
                <Flex py="0.3rem" mt="-5px" alignItems={'center'}>
                  <div className="p-inputgroup flex-1">
                    <span className="p-inputgroup-addon">
                      <FaBriefcase />
                    </span>

                    <InputText
                      value={formData.profile.area_atuacao}
                      name="profile.area_atuacao"
                      onChange={handleChange}
                      style={{
                        padding: ' 0px 10px',
                        width: '100%',
                        background: '#fafafa',
                        fontSize: '14px'
                      }}
                      className="outline-none"
                      placeholder="..."
                      v-model="value1"
                    />
                  </div>
                </Flex>
                <label
                  style={{
                    color: '#A0AEC0',
                    fontSize: '12px',
                    fontWeight: '500',
                    paddingLeft: '0px'
                  }}
                >
                  Endereço da Sede
                </label>
                <Flex flexDirection={'column'} mb="15px" justifyContent={'start'}>
                  {/* <Icon as={FaBuilding} mr="8px" color="#A0AEC0" /> */}
                  <Text
                    fontSize={'14px'}
                  >{`${formData.address.tipo_logradouro} ${formData.address.logradouro}, ${formData.address.numero} - ${formData.address.bairro}`}</Text>
                  <Text
                    fontSize={'14px'}
                  >{`${formData.address.cep} - ${formData.address.municipio}, ${formData.address.uf} - ${formData.address.pais}`}</Text>
                </Flex>

                <Popover placement="right" closeOnBlur={false}>
                  <Flex mb="15px">
                    <Flex justifyContent={'space-between'}>
                      <label
                        style={{
                          color: '#A0AEC0',
                          fontSize: '12px',
                          fontWeight: '500',
                          paddingLeft: '0px'
                        }}
                      >
                        Outros endereços
                      </label>
                      <PopoverTrigger>
                        <Tag
                          bg="#E2E8F0"
                          w={'fit-content'}
                          fontSize={'0.7rem'}
                          borderRadius={'20px'}
                          cursor={'pointer'}
                          ml="10px"
                        >
                          + Novo endereço
                        </Tag>
                      </PopoverTrigger>
                    </Flex>
                    <PopoverContent
                      width="600px"
                      p={5}
                      borderRadius="20px"
                      boxShadow={'2xl'}
                      _focus={{ border: 'none' }}
                    >
                      <PopoverArrow />
                      <PopoverCloseButton mt="5px" p="15px" borderRadius={'50px'} />
                      <FormAddress />
                    </PopoverContent>
                  </Flex>
                </Popover>
              </GridItem>
              <GridItem w="100%" colSpan={2}>
                <Popover
                  isOpen={isOpen}
                  initialFocusRef={firstFieldRef}
                  onOpen={onOpen}
                  onClose={onClose}
                  placement="bottom"
                  closeOnBlur={false} // Evita o fechamento ao clicar fora
                  marginLeft="20px"
                >
                  <Flex justifyContent={'space-between'}>
                    <Text display="flex" fontSize="md" fontWeight="800" color={textColor}>
                      Dados de contato
                    </Text>
                    <PopoverTrigger>
                      <Tag
                        bg="#E2E8F0"
                        w={'fit-content'}
                        fontSize={'0.7rem'}
                        borderRadius={'20px'}
                        cursor="pointer"
                        h="24px"
                        alignItems={'center'}
                        px={'8px'}
                      >
                        <FaEdit size={'12px'} style={{ marginRight: '3px' }} />
                        <Text mt="3px">Editar</Text>
                      </Tag>
                    </PopoverTrigger>
                  </Flex>
                  <label
                    style={{
                      color: '#A0AEC0',
                      fontSize: '12px',
                      fontWeight: '500',
                      paddingLeft: '0px'
                    }}
                  >
                    Telefones
                  </label>
                  {formData.contact_data.telefone &&
                    formData.contact_data.telefone.map((telefone) => (
                      <Flex mb="8px" gap="5px" justifyContent={'space-between'} alignItems="center">
                        <Flex>
                          <Text fontSize={'0.95rem'} w="100px">
                            {telefone.tipo}
                          </Text>
                          <Text fontSize={'0.95rem'}>{telefone.numero}</Text>
                        </Flex>

                        <Flex gap="5px" alignItems="center">
                          {telefone.tipo === 'fixo' ||
                            (telefone.tipo === 'Fixo' && (
                              <IconButton
                                isRound={true}
                                size="xs"
                                variant="solid"
                                colorScheme="blue"
                                background={'#49BFFF'}
                                aria-label="Done"
                                fontSize="10px"
                                icon={<FaPhone size={'12px'} />}
                                onClick={() =>
                                  window.open(`tel:${normalizePhoneNumber(telefone.numero)}`, '_blank')
                                }
                              />
                            ))}
                          {telefone.tipo === 'WhatsApp' && (
                            <IconButton
                              isRound={true}
                              size="xs"
                              variant="solid"
                              colorScheme="blue"
                              background={'#49BFFF'}
                              aria-label="Done"
                              fontSize="10px"
                              icon={<FaWhatsapp size={'12px'} />}
                              onClick={() =>
                                window.open(`tel:${normalizePhoneNumber(telefone.numero)}`, '_blank')
                              }
                            />
                          )}
                          {telefone.tipo === 'movel' ||
                            (telefone.tipo === 'Móvel' && (
                              <IconButton
                                isRound={true}
                                size="xs"
                                variant="solid"
                                colorScheme="blue"
                                background={'#49BFFF'}
                                aria-label="Done"
                                fontSize="10px"
                                icon={<FaMobile size={'12px'} />}
                                onClick={() =>
                                  window.open(`tel:${normalizePhoneNumber(telefone.numero)}`, '_blank')
                                }
                              />
                            ))}
                        </Flex>
                      </Flex>
                    ))}

                  <PopoverContent
                    p={5}
                    borderRadius="20px"
                    boxShadow={'2xl'}
                    w={'30hv'}
                    _focus={{ border: 'none' }}
                  >
                    <PopoverArrow />
                    <PopoverCloseButton mt="5px" p="15px" borderRadius={'50px'} />
                    <ContactDataForm data={formData.contact_data} companyId={company_id} />
                  </PopoverContent>
                </Popover>

                <label
                  style={{
                    color: '#A0AEC0',
                    fontSize: '12px',
                    fontWeight: '500',
                    paddingLeft: '0px'
                  }}
                >
                  Email
                </label>
                {formData.contact_data.email &&
                  formData.contact_data.email.map((email) => (
                    <Flex mb="8px" gap="5px" justifyContent={'space-between'} alignItems="center">
                      <Flex>
                        <Text fontSize={'0.95rem'} w="100px">
                          {email.tipo}
                        </Text>
                        <Text fontSize={'0.95rem'}>{email.endereco_email}</Text>
                      </Flex>

                      <Flex gap="5px" alignItems="center">
                        {email.tipo === 'RF' && (
                          <IconButton
                            isRound={true}
                            size="xs"
                            variant="solid"
                            colorScheme="blue"
                            background={'#49BFFF'}
                            aria-label="Done"
                            fontSize="10px"
                            icon={<FaEnvelopeOpen size={'12px'} />}
                            onClick={() => window.open(`mailto:${email.endereco_email}`, '_blank')}
                          />
                        )}
                      </Flex>
                    </Flex>
                  ))}
                <label
                  style={{
                    color: '#A0AEC0',
                    fontSize: '12px',
                    fontWeight: '500',
                    paddingLeft: '0px'
                  }}
                >
                  Redes sociais
                </label>
                <Flex gap="5px" mb="20px">
                  <IconButton
                    isRound={true}
                    size="sm"
                    variant="solid"
                    colorScheme="blue"
                    background={'#49BFFF'}
                    aria-label="Done"
                    fontSize="10px"
                    icon={<FaGlobe size={'17px'} />}
                    onClick={() => window.open(formData.contact_data.website, '_blank')}
                    isDisabled={!formData.contact_data.website}
                  />

                  <IconButton
                    isRound={true}
                    size="sm"
                    variant="solid"
                    colorScheme="blue"
                    background={'#49BFFF'}
                    aria-label="Done"
                    fontSize="10px"
                    icon={<FaInstagram size={'17px'} />}
                    onClick={() => window.open(formData.contact_data.instagram, '_blank')}
                    isDisabled={!formData.contact_data.instagram}
                  />

                  <IconButton
                    isRound={true}
                    size="sm"
                    variant="solid"
                    colorScheme="blue"
                    background={'#49BFFF'}
                    aria-label="Done"
                    fontSize="10px"
                    icon={<FaLinkedin size={'17px'} />}
                    onClick={() => window.open(formData.contact_data.linkedin, '_blank')}
                    isDisabled={!formData.contact_data.linkedin}
                  />

                  <IconButton
                    isRound={true}
                    size="sm"
                    variant="solid"
                    colorScheme="blue"
                    background={'#49BFFF'}
                    aria-label="Done"
                    fontSize="10px"
                    icon={<FaFacebook size={'17px'} />}
                    onClick={() => window.open(formData.contact_data.facebook, '_blank')}
                    isDisabled={!formData.contact_data.facebook}
                  />

                  <IconButton
                    isRound={true}
                    size="sm"
                    variant="solid"
                    colorScheme="blue"
                    background={'#49BFFF'}
                    aria-label="Done"
                    fontSize="10px"
                    icon={<FaTwitter size={'17px'} />}
                    onClick={() => window.open(formData.contact_data.x, '_blank')}
                    isDisabled={!formData.contact_data.x}
                  />

                  <IconButton
                    isRound={true}
                    size="sm"
                    variant="solid"
                    colorScheme="blue"
                    background={'#49BFFF'}
                    aria-label="Done"
                    fontSize="10px"
                    icon={<FaYoutube size={'17px'} />}
                    onClick={() => window.open(formData.contact_data.youtube, '_blank')}
                    isDisabled={!formData.contact_data.youtube}
                  />
                </Flex>

                <label
                  style={{
                    color: '#A0AEC0',
                    fontSize: '12px',
                    fontWeight: '500',
                    paddingLeft: '0px'
                  }}
                >
                  Observações
                </label>
                <Flex py="0.3rem" mt="-5px" alignItems={'center'}>
                  <div className="p-inputgroup flex-1">
                    <span className="p-inputgroup-addon">
                      <FaFileAlt />
                    </span>
                    <InputTextarea
                      value={formData.profile.descricao}
                      name="profile.descricao"
                      onChange={handleChange}
                      rows="4"
                      style={{
                        padding: ' 10px 10px',
                        width: '100%',
                        background: '#fafafa',
                        fontSize: '14px'
                      }}
                      className="outline-none"
                      placeholder="..."
                      v-model="value1"
                    />
                  </div>
                </Flex>
              </GridItem>
            </Grid>
          </Flex>
          <Flex direction="column" mt="20px">
            <label
              style={{
                color: '#A0AEC0',
                fontSize: '12px',
                fontWeight: '500',
                paddingLeft: '0px'
              }}
            >
              Atividade Principal - CNAE
            </label>
            <Flex>
              <Tag bg="#E2E8F0" w={'fit-content'} fontSize={'0.7rem'} borderRadius={'20px'}>
                {formData.business_data.atividade_principal.cnae}
              </Tag>
            </Flex>
          </Flex>

          <Flex direction="column" mt="20px">
            {formData.business_data.atividade_secundaria[0].cnae && (
              <>
                <label
                  style={{
                    color: '#A0AEC0',
                    fontSize: '12px',
                    fontWeight: '500',
                    paddingLeft: '0px'
                  }}
                >
                  Atividades Secundárias - CNAE
                </label>
                {formData.business_data.atividade_secundaria.map((cnae_secundario) => (
                  <Flex key={cnae_secundario.cnae} mt="5px">
                    <Tag bg="#E2E8F0" w={'fit-content'} fontSize={'0.7rem'} borderRadius={'20px'}>
                      {`${cnae_secundario.cnae} - ${cnae_secundario.descricao}`}
                    </Tag>
                  </Flex>
                ))}
              </>
            )}
          </Flex>
          <FormControl>
            <Flex flexDirection={'row-reverse'} mt={'20px'} gap={'10px'}>
              {company_id && (
                <Button
                  fontSize="1rem"
                  background="#49BFFF"
                  colorScheme="blue"
                  color="white"
                  fontWeight="800"
                  w="fit-content"
                  h="50"
                  borderRadius="50px"
                  onClick={() => redirectToCompanyPage(company_id)}
                >
                  Visualizar empresa
                </Button>
              )}
              {routeConsulta && (
                <Button
                  fontSize="1rem"
                  background="#49BFFF"
                  colorScheme="blue"
                  color="white"
                  fontWeight="800"
                  w="fit-content"
                  h="50"
                  borderRadius="50px"
                  onClick={handleSubmitNewDeal}
                >
                  Adicionar e Criar negócio
                </Button>
              )}
              <Button
                fontSize="1rem"
                background="#49BFFF"
                colorScheme="blue"
                color="white"
                fontWeight="800"
                w="fit-content"
                h="50"
                borderRadius="50px"
                onClick={handleSubmit}
              >
                {company_id ? 'Atualizar' : 'Adicionar'}
              </Button>

              {company_id && (
                <Tag
                  colorScheme="gray"
                  borderRadius={'50px'}
                  variant="outline"
                  onClick={confirmDelete}
                  cursor={'pointer'}
                  px="15px"
                  mr="10px"
                >
                  Excluir
                </Tag>
              )}
            </Flex>
          </FormControl>
        </Card>
      </GridItem>
      <GridItem colSpan={2}>
        <CompanyContacts
          company_id={company_id}
          // socios={dados && dados.socios}
          socios={companyContacts}
          updateContacts={setRefreshComponent}
        />
        {!routeConsulta && (
          <CompanyDeals company_id={company_id} inicialFocus={firstFieldRef} onClose={onClose} />
        )}
        {!routeConsulta && <QualificationForm company_id={company_id} />}
      </GridItem>
      <Toast ref={toast} position="bottom-center" />
      <ConfirmDialog
        defaultFocus={'reject'}
        pt={{
          acceptButton: {
            style: {
              background: '#cc0000',
              borderRadius: '20px',
              color: 'white',
              padding: '5px 10px',
              marginLeft: '20px'
            }
          }
          // rejectButton: {
          //   style: {
          //     padding: '5px 10px',
          //     // border: '1px solid #49BFFF',
          //     background: '#49BFFF',
          //     borderRadius: '20px',
          //     color: '#49BFFF'
          //   }
          // }
        }}
      />
    </Grid>
  )
}
