import React, { useState, useRef, useEffect } from 'react'
import { InputText } from 'primereact/inputtext'
import { Box, Button, Flex, Grid, GridItem, Link, Text } from '@chakra-ui/react'
import { FaKey, FaListAlt, FaUser } from 'react-icons/fa'
import authHeader from 'hooks/authHeader'
import axios from 'api/axios'
import { Toast } from 'primereact/toast'
import { getUserAccountId } from 'hooks/authService'

export function Settings() {
  const account_id = getUserAccountId()

  const [formData, setFormData] = useState({
    settings: {
      vono_records: {
        vono_key: '',
        vono_token: ''
      }
    }
  })

  useEffect(() => {
    console.log('formData', formData)
  }, [formData])

  useEffect(() => {
    getAccountSettings()
  }, [])

  async function getAccountSettings() {
    try {
      const response = await axios.get(`/accounts/${account_id}`, { headers: authHeader() })
      console.log('response.data', response.data)

      const settings = response.data.settings || {
        vono_records: {
          vono_key: '',
          vono_token: ''
        }
      }

      setFormData({ settings })
    } catch (error) {
      console.error('Erro ao obter configurações:', error)
    }
  }

  function handleVonoRecordsChange(event) {
    const { name, value } = event.target

    setFormData((prevState) => ({
      ...prevState,
      settings: {
        ...prevState.settings,
        vono_records: {
          ...prevState.settings.vono_records,
          [name]: value
        }
      }
    }))
  }
  function handleSettingsChange(event) {
    const { name, value } = event.target

    setFormData((prevState) => ({
      ...prevState,
      settings: {
        ...prevState.settings,
        resend_api_key: value
      }
    }))
  }

  async function handleSubmit() {
    try {
      const response = await axios.patch(`/accounts/${account_id}`, formData, { headers: authHeader() })
      toastSuccess('Configurações atualizadas!')
      console.log('Conta atualizada com sucesso:', response.data)
    } catch (error) {
      toastError('Houve um erro ao atualizar as configurações.')
      console.error('Erro ao atualizar a conta:', error)
    }
  }

  const whatsappKey = 'Implementar'

  const toast = useRef()
  const toastSuccess = (message) => {
    toast.current.show({ severity: 'success', summary: 'Success', detail: message })
  }
  const toastError = (message) => {
    toast.current.show({
      severity: 'error',
      summary: 'Error',
      detail: message
    })
  }

  return (
    <Box w="full">
      <Flex
        mt="70px"
        p="10px"
        pl="20px"
        bg="white"
        borderRadius={'50px'}
        boxShadow={'lg'}
        mb="20px"
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <Text fontSize={'18px'} fontWeight={'800'}>
          Configurações
        </Text>
        <Button
          colorScheme="blue"
          background={'#49BFFF'}
          onClick={handleSubmit}
          borderRadius={'50px'}
          w="fit-content"
        >
          Salvar
        </Button>
      </Flex>
      <Grid templateColumns="repeat(6, 1fr)" gap="20px" w={'full'}>
        <GridItem colSpan={2}>
          <Flex p="20px" bg="white" borderRadius={'20px'} flexDirection={'column'} boxShadow={'lg'}>
            <Text fontSize={'16px'} fontWeight={'800'}>
              Integração VOIP
            </Text>
            <Text fontSize={'0.85rem'} mb="10px">
              Habilita o acesso às gravações das ligações realizadas
            </Text>
            <Flex flexDirection={'column'} mb="20px">
              <label
                style={{
                  color: '#A0AEC0',
                  fontSize: '12px',
                  fontWeight: '500',
                  paddingLeft: '0px'
                }}
              >
                Vono key
              </label>
              <div className="p-inputgroup flex-1">
                <span className="p-inputgroup-addon">
                  <FaKey />
                </span>
                <InputText
                  className="pl-2"
                  name="vono_key"
                  value={formData?.settings?.vono_records?.vono_key}
                  onChange={handleVonoRecordsChange}
                  v-model="value1"
                  style={{
                    padding: ' 0px 10px',
                    width: '100%',
                    background: '#fafafa',
                    fontSize: '14px'
                  }}
                />
              </div>
            </Flex>
            <Flex flexDirection={'column'}>
              <label
                style={{
                  color: '#A0AEC0',
                  fontSize: '12px',
                  fontWeight: '500',
                  paddingLeft: '0px'
                }}
              >
                Vono token
              </label>

              <div className="p-inputgroup flex-1">
                <span className="p-inputgroup-addon">
                  <FaKey />
                </span>
                <InputText
                  className="pl-2"
                  name="vono_token"
                  value={formData?.settings?.vono_records?.vono_token}
                  onChange={handleVonoRecordsChange}
                  v-model="value1"
                  style={{
                    padding: ' 0px 10px',
                    width: '100%',
                    background: '#fafafa',
                    fontSize: '14px'
                  }}
                />
              </div>
            </Flex>
            <Flex pt={'20px'}>
              <Link href="https://www.falevono.com.br/vonopc.exe" target="_blank">
                <Text background={'#49BFFF'} color={'white'} borderRadius={'20px'} p="5px 20px">
                  Download Vono SoftPhone
                </Text>
              </Link>
            </Flex>
          </Flex>
        </GridItem>
        <GridItem colSpan={2}>
          <Flex p="20px" bg="white" borderRadius={'20px'} flexDirection={'column'} boxShadow={'lg'}>
            <Text fontSize={'16px'} fontWeight={'800'}>
              Integração Resend
            </Text>
            <Text fontSize={'0.85rem'} mb="10px">
              Habilita o envio de emails automáticos como cadências de prospecção.
            </Text>
            <label
              style={{
                color: '#A0AEC0',
                fontSize: '12px',
                fontWeight: '500',
                paddingLeft: '0px'
              }}
            >
              Resend API key
            </label>

            <div className="p-inputgroup flex-1">
              <span className="p-inputgroup-addon">
                <FaKey />
              </span>
              <InputText
                className="pl-2"
                name="vono_token"
                value={formData?.settings?.resend_api_key}
                onChange={handleSettingsChange}
                v-model="value1"
                style={{
                  padding: ' 0px 10px',
                  width: '100%',
                  background: '#fafafa',
                  fontSize: '14px'
                }}
              />
            </div>
          </Flex>
        </GridItem>
        <GridItem colSpan={2}>
          <Flex p="20px" bg="white" borderRadius={'20px'} flexDirection={'column'} boxShadow={'lg'}>
            <Text fontSize={'16px'} fontWeight={'800'}>
              Integração WhatsApp
            </Text>
            <Text fontSize={'0.85rem'} mb="10px">
              Habilita automações com WhatsApp como cadências de prospecção e pós vendas.
            </Text>
            <label
              style={{
                color: '#A0AEC0',
                fontSize: '12px',
                fontWeight: '500',
                paddingLeft: '0px'
              }}
            >
              Evolution API key
            </label>

            <div className="p-inputgroup flex-1">
              <span className="p-inputgroup-addon">
                <FaKey />
              </span>
              <InputText
                className="pl-2"
                name="whatsapp"
                value={whatsappKey}
                onChange={handleSettingsChange}
                v-model="value1"
                style={{
                  padding: ' 0px 10px',
                  width: '100%',
                  background: '#fafafa',
                  fontSize: '14px'
                }}
              />
            </div>
          </Flex>
        </GridItem>
      </Grid>
      <Toast position="bottom-center" ref={toast} />
    </Box>
  )
}
