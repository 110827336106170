import React, { useState, useEffect, useRef } from 'react'
import axios from 'api/axios'
import {
  Button,
  Flex,
  IconButton,
  Input,
  Icon,
  Select,
  SimpleGrid,
  Tag,
  Text,
  Textarea
} from '@chakra-ui/react'
import {
  FaPhone,
  FaTasks,
  FaEnvelope,
  FaComments,
  FaHandshake,
  FaWalking,
  FaFlag,
  FaComment
} from 'react-icons/fa'
import { Divider } from 'primereact/divider'
import { Calendar } from 'primereact/calendar'
import { SelectButton } from 'primereact/selectbutton'
import authHeader from 'hooks/authHeader'
import { getUserAccountId } from 'hooks/authService'
import { OverlayPanel } from 'primereact/overlaypanel'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css' // Importe o CSS do Quill
import { MdClose } from 'react-icons/md'
import { confirmDialog, ConfirmDialog } from 'primereact/confirmdialog'

export default function Activities({ activity, refreshComponent, templatesList }) {
  const [newData, setNewData] = useState({
    // account_id: null,
    // owner_id: null,
    // entity: '',
    // entity_id: null,
    // name: '',
    description: activity.description,
    // type: '',
    status: activity.status,
    coments: activity.coments,
    start_date: activity.start_date,
    dead_line: activity.dead_line,
    flag: activity.flag
  })

  useEffect(() => {
    console.log('newData', newData)
  }, [newData])

  useEffect(() => {
    console.log('activity:', activity)
  }, [activity])

  function findTemplate(templateId) {
    if (!templatesList) return null
    return templatesList.find((template) => template.id === templateId)
  }

  const template = findTemplate(activity.activity_data.template_id)
  console.log(`template Da ATIVIDADE: ${activity.id} - `, template)
  const quillRef = useRef(null)
  const [editorContent, setEditorContent] = useState(activity.coments.comment)

  useEffect(() => {
    if (quillRef.current) {
      const editor = quillRef.current.getEditor()
      editor.on('text-change', () => {
        setEditorContent(editor.root.innerHTML)
      })
    }
  }, [])

  useEffect(() => {
    console.log('editorContent', editorContent)
  }, [editorContent])

  const op = useRef(null)

  function RenderActivityIcon({ value }) {
    const activity = activityMapping[value]

    if (!activity) {
      return <span>Ops..</span>
    }

    return (
      <div style={{ display: 'flex', alignItems: 'center', marginRight: '10px' }}>
        <span style={{ marginRight: '8px', fontSize: '14px' }}>{activity.icon}</span>
        <span style={{ fontSize: '0.85rem' }}>{activity.name}</span>
      </div>
    )
  }

  function convertTimestampToDateAndTime(timestamp) {
    // Remove os três últimos dígitos do timestamp
    const date = new Date(Math.floor(timestamp / 1000) * 1000)
    const day = date.getDate().toString().padStart(2, '0')
    const month =
      date.toLocaleString('default', { month: 'short' }).charAt(0).toUpperCase() +
      date.toLocaleString('default', { month: 'short' }).slice(1)
    const hours = date.getHours().toString().padStart(2, '0')
    const minutes = date.getMinutes().toString().padStart(2, '0')
    return `${day}/${month} • ${hours}h${minutes}`
  }

  const activityMapping = {
    call: { icon: <FaPhone />, name: 'Ligação' },
    task: { icon: <FaTasks />, name: 'Tarefa' },
    email: { icon: <FaEnvelope />, name: 'Email' },
    message: { icon: <FaComments />, name: 'Mensagem' },
    meeting: { icon: <FaHandshake />, name: 'Reunião' },
    visit: { icon: <FaWalking />, name: 'Visita' }
  }

  const [showNewComment, setShowNewComment] = useState(false)
  const [status, setStatus] = useState(activity.status)

  async function updateActivity(data) {
    try {
      const response = await axios.patch(`/activities/${activity.id}`, data, { headers: authHeader() })
      console.log('Atividade atualizada!', response)
      refreshComponent()
    } catch (error) {
      console.log('Não foi possível atualizar a atividade.', error)
    }
  }

  function submitChange(event) {
    const { name, value } = event.target
    setNewData((prevState) => ({
      ...prevState,
      [name]: value
    }))
    setStatus(value)
    updateActivity({ [name]: value })
  }

  function submitComment(event) {
    const { name, value } = event.target
    setNewData((prevState) => ({
      ...prevState,
      coments: {
        ...prevState.coments,
        now: Math.floor(Date.now() / 1000),
        comment: editorContent
      }
    }))
    setStatus(value)
    updateActivity({
      coments: {
        now: Math.floor(Date.now() / 1000),
        comment: editorContent
      }
    })
    setShowNewComment(false)
  }

  useEffect(() => {
    console.log('activity change newData: ', newData)
  }, [newData])

  console.log('activity.dead_line', activity.dead_line)

  const prioritiesList = [
    {
      value: 'Baixa prioridade',
      flag: 'low',
      color: '#49BFFF'
    },
    {
      value: 'Prioridade normal',
      flag: 'normal',
      color: '#e9e9e9'
    },
    {
      value: 'Alta prioridade',
      flag: 'high',
      color: '#F6E05E'
    },
    {
      value: 'Máxima prioridade ',
      flag: 'top',
      color: '#E53E3E'
    }
  ]

  function getColorByFlag(flag) {
    const priority = prioritiesList.find((item) => item.flag === flag)
    return priority ? priority.color : 'transparent'
  }

  const activityRef = useRef(null)

  const confirmDelete = () => {
    confirmDialog({
      header: 'Excluir atividade?',
      message: 'Tem certeza que deseja excluir esta atividade?',
      icon: 'pi pi-exclamation-triangle text-red-700',
      // acceptClassName: 'p-2 border-round-2xl ',
      rejectClassName: 'p-1 border-round-2xl text-blue-400 ',
      acceptLabel: 'Excluir',
      rejectLabel: 'Cancelar',
      accept: () => deleteActivity(), // Chama a função de exclusão se o usuário confirmar
      reject: () => console.log('Exclusão cancelada') // Ação se o usuário cancelar
    })
  }

  async function deleteActivity() {
    try {
      const response = await axios.delete(`/activities/${activity.id}`, { headers: authHeader() })
      console.log('Atividade excluída.', response)
      refreshComponent()
    } catch (error) {
      console.log('Não foi possível excluir a atividade.', error)
    }
  }

  return (
    <Flex p="10px" borderRadius="20px" w="100%" flexDirection="column">
      <Flex
        flexDirection="row"
        mb="10px"
        pb="3px"
        justifyContent="space-between"
        alignItems="center"
        style={{ borderBottom: '1px solid #ededed' }}
      >
        <Flex>
          <RenderActivityIcon value={activity.type} />
          {activity.name === 'automation' && (
            <Tag colorScheme="green" borderRadius={'20px'} mr="10px" fontSize="0.7rem" width={'fit-content'}>
              {activity.name}
            </Tag>
          )}
          {activity.name === 'auto' && (
            <Tag colorScheme="blue" borderRadius={'20px'} mr="10px" fontSize="0.7rem" width={'fit-content'}>
              {activity.name}
            </Tag>
          )}
          <Flex mt={'3px'}>
            <Text mr="20px" fontSize="0.8rem" width={'fit-content'}>
              {activity.start_date && convertTimestampToDateAndTime(activity.start_date)}
            </Text>
            <Text mr="20px" fontSize="0.8rem" width={'fit-content'}>
              {activity.dead_line && convertTimestampToDateAndTime(activity.dead_line)}
            </Text>
          </Flex>
        </Flex>
        <Flex gap="10px">
          <IconButton
            colorScheme={'white'}
            color={getColorByFlag(activity.flag)}
            // variant="outline"
            aria-label="Priority"
            borderRadius={'50px'}
            size="sm"
            p="1px"
            w="20px"
            icon={<Icon as={FaFlag} />}
          />
          <SelectButton
            name="status"
            value={newData.status}
            onChange={submitChange}
            options={[
              { value: 'todo', name: 'Pendente' },
              { value: 'doing', name: 'Agendado' }, // alterar para scheduled
              // { value: 'blocked', name: 'Impedida' },
              // { value: 'postponed', name: 'Adiada' },
              { value: 'canceled', name: 'Cancelada' },
              { value: 'done', name: 'Realizada' }
            ]}
            optionLabel="name"
            optionValue="value"
            style={{
              fontSize: '12px',
              width: '100%',
              margin: '0px'
            }}
            pt={{
              button: {
                style: { fontSize: '12px', fontWeight: '500', padding: '5px 10px', borderRadius: '20px' }
              },
              label: { style: { fontSize: '12px', fontWeight: '500' } }
            }}
          />
        </Flex>
      </Flex>
      <Flex justifyContent={'space-between'} flexDirection={'row'}>
        <Text fontSize={'14px'}>{activity.description}</Text>
        <IconButton
          onClick={confirmDelete}
          colorScheme="whiteAlpha"
          color="red"
          // variant="outline"
          size="sm"
          height={'30px'}
          width={'30px'}
          icon={<MdClose />}
          borderRadius={'50px'}
        >
          Excluir
        </IconButton>
      </Flex>
      {activity.activity_data?.template_id && (
        <>
          <OverlayPanel ref={op} style={{ maxWidth: '600px', padding: '15px', borderRadius: '20px' }}>
            <Flex flexDirection={'column'}>
              <Text
                fontSize={'14px'}
                pb="10px"
                my="5px"
                borderBottom={'1px solid #ccc'}
              >{`Assunto: ${template?.subject && template.subject}`}</Text>
              <Text pt="10px" dangerouslySetInnerHTML={{ __html: template?.message?.content }} />
            </Flex>
          </OverlayPanel>
          <Tag
            background={'#fafafa'}
            p={'3px 15px'}
            fontSize={'14px'}
            onClick={(e) => op.current.toggle(e)}
            cursor={'pointer'}
            width={'fit-content'}
            borderRadius={'20px'}
          >
            {`${template?.name && template.name}`}
          </Tag>
        </>
      )}
      <Flex mt="5px" width={'100%'}>
        {!showNewComment && (
          <Flex
            onClick={() => setShowNewComment(true)}
            background={'#fafafa'}
            padding={'10px'}
            borderRadius={'20px'}
            cursor={'text'}
            width={'100%'}
          >
            <FaComment color="#8f9bba" className="mr-3" size={'14px'} />
            {newData?.coments?.comment && (
              <Text
                dangerouslySetInnerHTML={{ __html: newData?.coments?.comment }}
                isTruncated
                noOfLines={1}
                maxW="100%"
              ></Text>
            )}
          </Flex>
        )}
        {showNewComment && (
          <Flex flexDirection={'column'} width={'100%'} background={'#fafafa'} p="10px" borderRadius={'20px'}>
            <ReactQuill
              ref={quillRef}
              value={editorContent}
              onChange={setEditorContent}
              theme="snow"
              modules={{
                toolbar: [
                  // [{ header: [1, 2, 3, 4, 5, 6, false] }],
                  // [{ size: ['small', false, 'large', 'huge'] }],
                  ['bold', 'italic', 'underline', 'strike', 'blockquote', 'color'],
                  [{ list: 'ordered' }, { list: 'bullet' }],
                  // [{ align: [] }],
                  [{ indent: '-1' }, { indent: '+1' }],
                  // [{ color: [] }, { background: [] }],
                  // [{ font: [] }],
                  ['link', 'image'],
                  ['clean'] // Adicione mais opções da barra de ferramentas conforme necessário
                ]
              }}
            />
            <Flex gap="10px" flexDirection={'row-reverse'}>
              <Button
                bg="#49BFFF"
                color="white"
                colorScheme="blue"
                borderRadius="20px"
                fontSize={'14px'}
                onClick={submitComment}
                width={'fit-content'}
              >
                Salvar
              </Button>
              <Button
                variant="outline"
                color="#49BFFF"
                colorScheme="blue"
                borderRadius="20px"
                fontSize={'14px'}
                onClick={() => setShowNewComment(false)}
                width={'fit-content'}
              >
                Cancelar
              </Button>
            </Flex>
          </Flex>
        )}
      </Flex>
      {/* <ConfirmDialog // Está dando conflito com outro ConfirmDialog que é renderizado no DealForm.
        defaultFocus={'reject'}
        ref={activityRef}
        pt={{
          acceptButton: {
            style: {
              background: '#cc0000',
              borderRadius: '20px',
              color: 'white',
              padding: '5px 10px',
              marginLeft: '20px'
            }
          }
          // rejectButton: {
          //   style: {
          //     padding: '5px 10px',
          //     // border: '1px solid #49BFFF',
          //     background: '#49BFFF',
          //     borderRadius: '20px',
          //     color: '#49BFFF'
          //   }
          // }
        }}
      /> */}
    </Flex>
  )
}
