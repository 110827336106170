import React, { useEffect } from 'react'

const Chatwoot = () => {
  useEffect(() => {
    const BASE_URL = 'https://chat.vetsales.com.br'

    // Cria o elemento script
    const script = document.createElement('script')
    script.src = `${BASE_URL}/packs/js/sdk.js`
    script.defer = true
    script.async = true

    // Callback após o script ser carregado
    script.onload = () => {
      if (window.chatwootSDK) {
        window.chatwootSDK.run({
          websiteToken: '8S3Mzw6BVdPeUaeXvXmx4xkV',
          baseUrl: BASE_URL
        })
      }
    }

    // Adiciona o script ao DOM
    document.body.appendChild(script)

    // Remove o script ao desmontar o componente
    return () => {
      document.body.removeChild(script)
    }
  }, []) // Executa apenas uma vez

  return null // Não renderiza nada no DOM
}

export default Chatwoot
