import React, { useState, useEffect, createRef } from 'react'
// import { useParams } from 'react-router-dom';
import apiDados from 'api/api-dados'
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  SimpleGrid,
  Select,
  Stack,
  Switch,
  useColorModeValue,
  useToast,
  Text
} from '@chakra-ui/react'
import { PhoneIcon, SearchIcon } from '@chakra-ui/icons'
import Card from 'components/card/Card'
// import { NotAllowedIcon } from '@chakra-ui/icons';
// import formatDate from 'hooks/formatDate';
import TableConsulta from './tableConsulta'
import ModalConsultaCnpj from './modalProspeccao'
import {
  cnaes,
  naturezasJuridicas,
  situacaoCadastral,
  estadosUF,
  listaMunicipios
} from 'contexts/dadosBrasileiros'
import { MultiSelect } from 'primereact/multiselect'
import { IconField } from 'primereact/iconfield'
import { InputIcon } from 'primereact/inputicon'
import { InputText } from 'primereact/inputtext'
import { Dropdown } from 'primereact/dropdown'
import { Chips } from 'primereact/chips'
import { VirtualScroller } from 'primereact/virtualscroller'
import { GoogleSearchApi } from './googlesearchapicomponent'

export function ConsultaCNPJRF({ loadData, updateLoadMoreFormData, formDataFromParent }) {
  const textColor = useColorModeValue('navy.700', 'white')
  let capital_social_lte = null
  let capital_social_gte = null
  let data_abertura_lte = null
  let data_abertura_gte = null

  const [cnpj, setCnpj] = useState([])
  const [trigger, setTrigger] = useState(false)
  const [entriesFound, setEntriesFound] = useState()

  const [ufFilter, setUfFilter] = useState([]) // Estados selecionados
  const [filteredMunicipios, setFilteredMunicipios] = useState([]) // Municípios filtrados

  const [formData, setFormData] = useState({
    query: {
      atividade_principal: [],
      bairro: [],
      cep: [],
      ddd: [],
      municipio: [],
      natureza_juridica: ['2062'],
      situacao_cadastral: 'ATIVA',
      termo: [],
      uf: []
    },
    page: 0,
    extras: {
      com_contato_telefonico: true,
      com_email: true,
      excluir_mei: true,
      incluir_atividade_secundaria: false,
      somente_celular: false,
      somente_filial: false,
      somente_fixo: false,
      somente_matriz: false,
      somente_mei: false
    },
    range_query: {
      capital_social: { lte: capital_social_lte, gte: capital_social_gte },
      data_abertura: { lte: data_abertura_lte, gte: data_abertura_gte }
    }
  })

  const cleanFormData = {
    query: {
      atividade_principal: [],
      bairro: [],
      cep: [],
      ddd: [],
      municipio: [],
      natureza_juridica: [],
      situacao_cadastral: 'ATIVA',
      termo: [],
      uf: []
    },
    page: 0,
    extras: {
      com_contato_telefonico: false,
      com_email: false,
      excluir_mei: false,
      incluir_atividade_secundaria: false,
      somente_celular: false,
      somente_filial: false,
      somente_fixo: false,
      somente_matriz: false,
      somente_mei: false
    },
    range_query: {
      capital_social: {
        lte: capital_social_lte,
        gte: capital_social_gte
      },
      data_abertura: {
        lte: data_abertura_lte,
        gte: data_abertura_gte
      }
    }
  }

  const [resultado, setResultado] = useState([])
  const [loading, setLoading] = useState(false)
  const [sendAlert, setSendAlert] = useState('')

  const cnaeMultiselectLabelTemplate = (option) => {
    return (
      <div className="flex align-items-center">
        <div>{`(${option.value}) ${option.name}`}</div>
      </div>
    )
  }

  useEffect(() => {
    // formDataFromParent && setFormData(formDataFromParent) //
    console.log('formDataFromParent', formDataFromParent)
    console.log('formData', formData)
  }, [formDataFromParent])

  useEffect(() => {
    formData && updateLoadMoreFormData(formData)
  }, [formData])

  useEffect(() => {
    resultado && loadData(resultado)
  }, [resultado])

  useEffect(() => {
    if (ufFilter.length > 0) {
      // Filtra os municípios com base nos estados selecionados
      const municipiosFiltrados = listaMunicipios.filter((municipio) => ufFilter.includes(municipio.uf))
      setFilteredMunicipios(municipiosFiltrados)
    } else {
      // Caso nenhum estado esteja selecionado, limpa os municípios
      setFilteredMunicipios([])
    }
  }, [ufFilter])

  const handleChange = (event) => {
    const { name, value } = event.target
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value
    }))
    console.log(`Name: ${name}, value: ${value}`)
  }

  const handleUfChange = (e) => {
    const { name, value } = e.target
    setFormData((prevFormData) => ({
      ...prevFormData,
      query: {
        ...prevFormData.query,
        [name]: value
      }
    }))
    setUfFilter(value) // Atualiza os estados selecionados
  }

  const handleQueryChange = (event) => {
    const { name, value } = event.target
    setFormData((prevFormData) => ({
      ...prevFormData,
      query: {
        ...prevFormData.query,
        [name]: value
      }
    }))
    // console.log(`Query Name: ${name}, value: ${value}`)
    // console.log(`formData.query: ${JSON.stringify(formData?.query)}`)
  }

  const handleCnpjConsultaChange = (event) => {
    const { value } = event.target
    setCnpj(value)
    // console.log(`Query Name: ${event.name}, value: ${value}`)
  }

  const handleExtraChange = (event) => {
    const { name, checked } = event.target
    setFormData((prevFormData) => ({
      ...prevFormData,
      extras: {
        ...prevFormData.extras,
        [name]: checked
      }
    }))
    // console.log(`Extra Name: ${name}, value: ${checked}`)
    // console.log(`event.taget: ${JSON.stringify(checked)}`)
  }

  // const handleLoadMore = async () => {
  //   setLoading(true)
  //   const { page: currentPage } = formData
  //   console.log('Página original:', currentPage)
  //   setFormData((prevState) => ({ ...prevState, page: currentPage + 1 }))
  //   console.log('Página nova:', formData?.page)

  //   try {
  //     const {
  //       data: {
  //         data: { cnpj }
  //       }
  //     } = await apiDados.post('/cnpj/search', formData)

  //     const cnpjsUnicos = new Set(resultado.map((item) => item.cnpj))
  //     const novosCnpjs = cnpj.filter((item) => !cnpjsUnicos.has(item.cnpj))
  //     setResultado((prevData) => [...prevData, ...novosCnpjs])

  //     console.log('Sucesso!', novosCnpjs)
  //     console.log('Novo formData:', formData)
  //   } catch (error) {
  //     setSendAlert('error')
  //     console.error('Erro ao carregar mais dados:', error.response)
  //   }
  //   setLoading(false)
  // }

  const handleSubmit = async (event) => {
    event.preventDefault()

    try {
      const {
        data: {
          data,
          count,
          success,
          page,
          data: { cnpj }
        }
      } = await apiDados.post('/cnpj/search', formData)
      console.log('Sucesso!', cnpj)
      console.log('count!', data.count)
      setEntriesFound(data.count)
      setResultado(cnpj)
      setSendAlert('success')
      console.log('Sucesso! Page:', page)
      console.log('Sucesso! Sucess: ', success)
      console.log('Sucesso! Data:', data)
      console.log('Sucesso! formData:', formData)
    } catch (error) {
      setSendAlert('error')
      console.error('Ocorreu um erro ao enviar os dados:', error.response)
    }
    // console.log(`Resultado: ${JSON.stringify(resultado)}`);
    // console.log(`formData: ${JSON.stringify(formData)}`);
  }

  const { termo, atividade_principal, natureza_juridica, situacao_cadastral, uf, municipio, cep } =
    formData?.query || {}

  return (
    <Card>
      <form onSubmit={handleSubmit}>
        <FormControl>
          <SimpleGrid columns={{ sm: 1, md: 1 }} spacing={{ base: '20px', xl: '20px' }}>
            <Stack w="100%">
              <FormLabel display="flex" ms="4px" mb="-2px" fontSize="sm" fontWeight="500" color={textColor}>
                Consulta CNPJ
              </FormLabel>
              <InputGroup>
                <Input
                  value={cnpj}
                  name="cnpj"
                  onChange={handleCnpjConsultaChange}
                  variant="auth"
                  fontSize="sm"
                  ms={{ base: '0px', md: '0px' }}
                  placeholder="Digite"
                  mb="24px"
                  fontWeight="500"
                  size="md"
                />
                <InputRightElement width="2.7rem">
                  <IconButton
                    colorScheme={formData.telefone1 ? 'green' : 'gray'}
                    aria-label="Call Segun"
                    size="sm"
                    onClick={() => setTrigger(cnpj)}
                    icon={<SearchIcon />}
                  />
                </InputRightElement>
              </InputGroup>
            </Stack>
            <Stack w="100%">
              <label className="pl-2 font-medium text-sm">Razão Social ou termo de busca</label>
              <Chips
                value={termo}
                name="termo"
                onChange={handleQueryChange}
                v-model="value1"
                placeholder="Separe por vírgulas"
                separator=","
                style={{
                  fontSize: '12px',
                  // width: '90%',
                  margin: '0 0 10px 0',
                  background: 'rgb(252 252, 255)',
                  borderRadius: '20px'
                }}
                pt={{
                  input: {
                    style: { fontSize: '12px' }
                  },
                  inputToken: { style: { fontSize: '12px' } },
                  container: {
                    style: { padding: '10px', background: 'rgb(252 252, 255)', borderRadius: '20px' }
                  },
                  item: { style: { fontSize: '12px', padding: '8px' } },
                  token: {
                    style: {
                      fontSize: '12px',
                      margin: '0 6px 6px 0',
                      padding: '2px 5px 2px 8px',
                      background: 'rgb(240, 245, 250)'
                    }
                  },
                  wrapper: { style: { maxWidth: '600px' } }
                }}
              />
              {/* <FormLabel
                    display="flex"
                    ms="4px"
                    mb="-2px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                  >
                    Razão Social
                  </FormLabel>
                  <Input
                    value={termo}
                    name="termo"
                    onChange={handleQueryChange}
                    variant="auth"
                    fontSize="sm"
                    ms={{ base: '0px', md: '0px' }}
                    placeholder="Digite"
                    mb="24px"
                    fontWeight="500"
                    size="md"
                  />*/}
            </Stack>

            <Stack>
              <label className="pl-2 font-medium text-sm">Atividades Principais CNAES</label>
              <MultiSelect
                name="atividade_principal"
                value={atividade_principal}
                placeholder="Selecione"
                onChange={handleQueryChange}
                options={cnaes}
                optionLabel="name"
                optionValue="value"
                // itemTemplate={cnaeMultiselectLabelTemplate}
                // maxSelectedLabels={5}
                display="chip"
                multiple
                filter
                style={{
                  fontSize: '12px',
                  width: '100%',
                  margin: '0 0 10px 0',
                  background: 'rgb(252 252, 255)',
                  borderRadius: '20px'
                }}
                pt={{
                  label: { style: { whiteSpace: 'normal' } },
                  wrapper: { style: { marginTop: '5px', borderRadius: '20px', height: 'auto' } },
                  item: { style: { fontSize: '14px', padding: '8px' } },
                  token: {
                    style: {
                      margin: '0 6px 6px 0',
                      padding: '2px 5px 2px 8px',
                      background: 'rgb(240, 245, 250)'
                    }
                  },
                  wrapper: { style: { maxWidth: '600px' } }
                }}
              />
            </Stack>
            <Stack>
              <label className="pl-2 font-medium text-sm">Natureza Jurídica</label>
              <MultiSelect
                name="natureza_juridica"
                value={natureza_juridica}
                placeholder="Selecione"
                onChange={handleQueryChange}
                options={naturezasJuridicas}
                optionLabel="name"
                optionValue="value"
                itemTemplate={cnaeMultiselectLabelTemplate}
                // maxSelectedLabels={5}
                display="chip"
                multiple
                filter
                style={{
                  fontSize: '12px',
                  width: '100%',
                  margin: '0 0 10px 0',
                  background: 'rgb(252 252, 255)',
                  borderRadius: '20px'
                }}
                pt={{
                  label: { style: { whiteSpace: 'normal' } },
                  wrapper: { style: { marginTop: '5px', borderRadius: '20px', height: 'auto' } },
                  item: { style: { fontSize: '14px', padding: '8px' } },
                  token: {
                    style: {
                      margin: '0 6px 6px 0',
                      padding: '2px 5px 2px 8px',
                      background: 'rgb(240, 245, 250)'
                    }
                  },
                  wrapper: { style: { maxWidth: '600px' } }
                }}
              />
            </Stack>

            <Stack w="100%">
              <label className="pl-2 font-medium text-sm">Situação Cadastral</label>
              <Dropdown
                name="situacao_cadastral"
                value={situacao_cadastral}
                placeholder="Selecione"
                onChange={handleQueryChange}
                options={situacaoCadastral}
                optionLabel="name"
                optionValue="value"
                className="text-small"
                style={{
                  fontSize: '12px',
                  width: '100%',
                  margin: '0 0 10px 0',
                  background: 'rgb(252 252, 255)',
                  borderRadius: '20px'
                }}
                pt={{
                  input: { style: { fontSize: '12px', padding: '10px' } },
                  wrapper: { style: { marginTop: '5px', borderRadius: '20px', height: 'auto' } },
                  item: { style: { fontSize: '14px', padding: '8px' } },
                  token: {
                    style: {
                      margin: '0 6px 6px 0',
                      padding: '2px 5px 2px 8px',
                      background: 'rgb(240, 245, 250)'
                    }
                  },
                  wrapper: { style: { maxWidth: '600px' } }
                }}
              />
            </Stack>

            <Stack>
              <label className="pl-2 font-medium text-sm">Data de abertura</label>
              <Flex spacing={2} direction="row" my={3} justifyContent="space-between">
                <InputText
                  value={formData.data_abertura_lte}
                  name="data_abertura_lte"
                  onChange={handleChange}
                  type="date"
                  v-model="value1"
                  placeholder="Search"
                  style={{
                    fontSize: '12px',
                    width: '43%',
                    margin: '0 0 10px 0',
                    background: 'rgb(252 252, 255)',
                    borderRadius: '20px',
                    padding: '10px 20px'
                  }}
                />
                <span
                  style={{
                    fontSize: '12px',
                    padding: ' 10px 0'
                  }}
                >
                  até
                </span>
                <InputText
                  value={formData.data_abertura_gte}
                  name="data_abertura_gte"
                  onChange={handleChange}
                  type="date"
                  v-model="value1"
                  placeholder="Search"
                  style={{
                    fontSize: '12px',
                    width: '43%',
                    margin: '0 0 10px 0',
                    background: 'rgb(252 252, 255)',
                    borderRadius: '20px',
                    padding: '10px 20px'
                  }}
                />
              </Flex>
            </Stack>
            <Stack>
              <label className="pl-2 font-medium text-sm">Capital Social</label>
              <Flex spacing={2} direction="row" my={3} justifyContent="space-between">
                <InputText
                  value={formData.capital_social_lte}
                  name="capital_social_lte"
                  onChange={handleChange}
                  v-model="value1"
                  placeholder="De R$..."
                  style={{
                    fontSize: '12px',
                    width: '43%',
                    margin: '0 0 10px 0',
                    background: 'rgb(252 252, 255)',
                    borderRadius: '20px',
                    padding: '10px 20px'
                  }}
                />
                <span
                  style={{
                    fontSize: '12px',
                    padding: ' 10px 0'
                  }}
                >
                  até
                </span>
                <InputText
                  value={formData.capital_social_gte}
                  name="capital_social_gte"
                  onChange={handleChange}
                  v-model="value1"
                  placeholder="R$..."
                  style={{
                    fontSize: '12px',
                    width: '43%',
                    margin: '0 0 10px 0',
                    background: 'rgb(252 252, 255)',
                    borderRadius: '20px',
                    padding: '10px 20px'
                  }}
                />
              </Flex>
            </Stack>

            <Stack>
              <label className="pl-2 font-medium text-sm">Estados</label>
              <MultiSelect
                name="uf"
                value={uf}
                placeholder="Selecione"
                onChange={handleUfChange} // Atualiza o estado dos estados selecionados
                options={estadosUF}
                optionLabel="name"
                optionValue="value"
                filter
                display="chip"
                style={{
                  fontSize: '12px',
                  width: '100%',
                  margin: '0 0 10px 0',
                  background: 'rgb(252 252, 255)',
                  borderRadius: '20px'
                }}
                pt={{
                  label: { style: { whiteSpace: 'normal' } },
                  wrapper: { style: { marginTop: '5px', borderRadius: '20px', height: 'auto' } },
                  item: { style: { fontSize: '14px', padding: '8px' } },
                  token: {
                    style: {
                      margin: '0 6px 6px 0',
                      padding: '2px 5px 2px 8px',
                      background: 'rgb(240, 245, 250)'
                    }
                  },
                  wrapper: { style: { maxWidth: '600px' } }
                }}
              />
            </Stack>
            <Stack>
              <FormLabel
                display="flex"
                ms="4px"
                mb="-1px"
                fontSize="sm"
                fontWeight="500"
                color={ufFilter.length ? textColor : '#dadada'}
              >
                Municípios
              </FormLabel>
              <MultiSelect
                name="municipio"
                value={municipio}
                placeholder="Selecione"
                onChange={handleQueryChange}
                options={filteredMunicipios} // Utiliza os municípios filtrados
                optionLabel="name"
                optionValue="value"
                filter
                display="chip"
                disabled={!ufFilter.length}
                style={{
                  fontSize: '12px',
                  width: '100%',
                  margin: '0 0 10px 0',
                  background: 'rgb(252 252, 255)',
                  borderRadius: '20px'
                }}
                pt={{
                  label: { style: { whiteSpace: 'normal' } },
                  wrapper: { style: { marginTop: '5px', borderRadius: '20px', height: 'auto' } },
                  item: { style: { fontSize: '14px', padding: '8px' } },
                  token: {
                    style: {
                      margin: '0 6px 6px 0',
                      padding: '2px 5px 2px 8px',
                      background: 'rgb(240, 245, 250)'
                    }
                  },
                  wrapper: { style: { maxWidth: '600px' } }
                }}
              />
            </Stack>

            <Flex
              spacing={{ base: '20px', xl: '20px' }}
              direction="row"
              justifyContent="space-between"
              mb="24px"
            >
              <Stack w={'49%'}>
                <label className="pl-2 font-medium text-sm">CEPs</label>
                <Chips
                  value={cep}
                  name="cep"
                  onChange={handleQueryChange}
                  v-model="value1"
                  placeholder="..."
                  keyfilter={/^[0-9,]*$/}
                  separator=","
                  style={{
                    fontSize: '12px',
                    // width: '90%',
                    margin: '0 0 10px 0',
                    background: 'rgb(252 252, 255)',
                    borderRadius: '20px'
                  }}
                  pt={{
                    input: {
                      style: { fontSize: '12px', width: '70px' }
                    },
                    inputToken: { style: { fontSize: '12px' } },
                    container: {
                      style: { padding: '8px', background: 'rgb(252 252, 255)', borderRadius: '20px' }
                    },
                    item: { style: { fontSize: '12px', padding: '8px' } },
                    token: {
                      style: {
                        fontSize: '12px',
                        margin: '0 6px 6px 0',
                        padding: '2px 5px 2px 8px',
                        background: 'rgb(240, 245, 250)'
                      }
                    },
                    wrapper: { style: { maxWidth: '600px' } }
                  }}
                />
                <small style={{ marginTop: '-8px', marginLeft: '5px' }}>Separe por vírgulas</small>
              </Stack>
              <Stack w={'49%'}>
                <label className="pl-2 font-medium text-sm">DDDs</label>
                <Chips
                  value={formData.query.ddd}
                  name="ddd"
                  onChange={handleQueryChange}
                  v-model="value1"
                  placeholder="..."
                  keyfilter={/^[0-9,]*$/}
                  separator=","
                  style={{
                    fontSize: '12px',
                    // width: '90%',
                    margin: '0 0 10px 0',
                    background: 'rgb(252 252, 255)',
                    borderRadius: '20px'
                  }}
                  pt={{
                    input: {
                      style: { fontSize: '12px', width: '60px' }
                    },
                    inputToken: { style: { fontSize: '12px' } },
                    container: {
                      style: { padding: '10px', background: 'rgb(252 252, 255)', borderRadius: '20px' }
                    },
                    item: { style: { fontSize: '12px', padding: '8px' } },
                    token: {
                      style: {
                        fontSize: '12px',
                        margin: '0 6px 6px 0',
                        padding: '2px 5px 2px 8px',
                        background: 'rgb(240, 245, 250)'
                      }
                    },
                    wrapper: { style: { maxWidth: '600px' } }
                  }}
                />
                <small style={{ marginTop: '-8px', marginLeft: '5px' }}>Separe por vírgulas</small>
              </Stack>
            </Flex>
          </SimpleGrid>
          {trigger && <ModalConsultaCnpj cnpj={trigger} />}
          <SimpleGrid
            // spacing={2} my={3}
            alignItems="center"
            columns={{ sm: 2, md: 2, xl: 2 }}
            justifyContent="center"
          >
            <Stack spacing={1} direction="row" my={1}>
              <Switch
                id="somente_matriz"
                name="somente_matriz"
                checked={formData.extras.somente_matriz}
                onChange={handleExtraChange}
              />
              <FormLabel
                display="flex"
                ms="4px"
                mb="-2px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                // w='120px'
                htmlFor="somente_matriz"
              >
                Matriz
              </FormLabel>
            </Stack>
            <Stack spacing={1} direction="row" my={1}>
              <Switch
                id="somente_filial"
                name="somente_filial"
                checked={formData.extras.somente_filial}
                onChange={handleExtraChange}
              />
              <FormLabel
                display="flex"
                ms="4px"
                mb="-2px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                // w='120px'
                htmlFor="somente_filial"
              >
                Filial
              </FormLabel>
            </Stack>
            <Stack spacing={1} direction="row" my={1}>
              <Switch
                id="somente_mei"
                name="somente_mei"
                checked={formData.extras.somente_mei}
                onChange={handleExtraChange}
              />
              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                mb="8px"
                // w='120px'
                htmlFor="somente_mei"
              >
                MEIs
              </FormLabel>
            </Stack>
            <Stack spacing={1} direction="row" my={1}>
              <Switch
                id="excluir_mei"
                name="excluir_mei"
                checked={formData.extras.excluir_mei}
                defaultChecked={true}
                onChange={handleExtraChange}
              />

              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                mb="8px"
                // w='120px'
                htmlFor="excluir_mei"
              >
                Excluir MEI
              </FormLabel>
            </Stack>
            <Stack spacing={1} direction="row" my={1}>
              <Switch
                id="com_email"
                name="com_email"
                checked={formData.extras.com_email}
                defaultChecked={true}
                onChange={handleExtraChange}
              />
              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                mb="8px"
                // w='120px'
                htmlFor="com_email"
              >
                Email
              </FormLabel>
            </Stack>
            <Stack spacing={1} direction="row" my={1}>
              <Switch
                id="com_contato_telefonico"
                name="com_contato_telefonico"
                checked={formData.extras.com_contato_telefonico}
                defaultChecked={true}
                onChange={handleExtraChange}
              />
              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                mb="8px"
                // w='120px'
                htmlFor="com_contato_telefonico"
              >
                Telefone
              </FormLabel>
            </Stack>
            <Stack spacing={1} direction="row" my={1}>
              <Switch
                id="somente_celular"
                name="somente_celular"
                checked={formData.extras.somente_celular}
                onChange={handleExtraChange}
              />
              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                mb="8px"
                // w='120px'
                htmlFor="somente_celular"
              >
                Celular
              </FormLabel>
            </Stack>
            <Stack spacing={2} direction="row" verticalAlign="center">
              <Switch
                id="somente_fixo"
                name="somente_fixo"
                checked={formData.extras.somente_fixo}
                onChange={handleExtraChange}
              />
              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                mb="8px"
                htmlFor="somente_fixo"
              >
                Fixo
              </FormLabel>
            </Stack>
          </SimpleGrid>
          <Stack spacing={2} direction="row" verticalAlign="center" mt="20px" justifyContent="flex-end">
            <Box
              fontSize="sm"
              variant="brand"
              fontWeight="500"
              h="50"
              w="auto"
              padding="15px 20px"
              borderRadius="100px"
              style={{
                backgroundColor: '#3965ff',
                color: '#ffffff',
                cursor: 'pointer',
                justifySelf: 'center'
              }}
              onClick={() => {
                setFormData(cleanFormData)
              }}
            >
              Limpar
            </Box>
            <Button
              type="submit"
              fontSize="sm"
              variant="brand"
              fontWeight="500"
              h="50"
              minWidth="130px"
              borderRadius="100px"
            >
              Gerar lista
            </Button>
          </Stack>
        </FormControl>
      </form>
    </Card>
  )
}
