import React, { useState, useEffect, useRef } from 'react'
import { Box, GridItem, Grid, IconButton, Icon, Button, Flex, Link, Tag, Text } from '@chakra-ui/react'
import {
  FaPhone,
  FaFlag,
  FaEnvelope,
  FaTasks,
  FaRegCalendarAlt,
  FaRegEnvelope,
  FaCommentAlt,
  FaComment,
  FaBriefcase,
  FaWhatsapp,
  FaBuilding,
  FaUserTie,
  FaMapMarkerAlt
} from 'react-icons/fa'
import { SelectButton } from 'primereact/selectbutton'
import { InputTextarea } from 'primereact/inputtextarea'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css' // Importe o CSS do Quill
import { MdClose, MdMobileFriendly, MdPhone, MdWhatsapp } from 'react-icons/md'
import axios from 'api/axios'
import authHeader from 'hooks/authHeader'

export function ActiveActivityCard({ selectedActivity, handleChange }) {
  const quillRef = useRef(null)

  useEffect(() => {
    console.log('selectedActivity', selectedActivity)
  }, [selectedActivity])

  const [showNewComment, setShowNewComment] = useState(false)
  const [editorContent, setEditorContent] = useState('')
  const [activityScriptList, setActivityScriptList] = useState([])

  useEffect(() => {
    getActiveActivityScript()
    getActivityComment(selectedActivity)
  }, [selectedActivity])

  function getActivityComment(selectedActivity) {
    if (!selectedActivity?.coments?.comment) return
    return setEditorContent(selectedActivity.coments.comment)
  }

  // const [editorContent, setEditorContent] = useState(selectedActivity.coments.comment)

  // selectedActivity = {
  //   "id": 196,
  //   "account_id": 4,
  //   "owner_id": 5,
  //   "entity": "deals",
  //   "entity_id": 46,
  //   "name": "auto",
  //   "description": "Validar email do decisor e telefone de contato da empresa.\n\n• Se tivermos o email do decisor, avançar para próxima etapa do funil (1ª abordagem - automação)\n• Se não tivermos o email do decisor, ligar para empresa e tentar contato.\n• Tentar o pitch inicial, qualificar e agendar uma apresentação.\n• Pegar Email do decisor",
  //   "type": "task",
  //   "status": "todo",
  //   "coments": {
  //     "now": 1732037119,
  //     "comment": "<p>Não temos o email do decisor.</p>"
  //   },
  //   "start_date": "1732037101931",
  //   "dead_line": null,
  //   "created_at": "2024-11-19T17:25:02.234Z",
  //   "updated_at": "2024-11-19T17:25:02.234Z",
  //   "flag": "normal",
  //   "activity_data": {},
  //   "owner": {
  //     "first_name": "Rolf",
  //     "last_name": "d'Ottenfels",
  //     "role": "main",
  //     "email": "suporte@vetsales.com.br"
  //   },
  //   "deal": {
  //     "id": 46,
  //     "account_id": 4,
  //     "company_id": 76,
  //     "contact_id": 131,
  //     "step_id": 28,
  //     "name": "Murta Clinica Veterinaria",
  //     "deal_data": {
  //       "description": ""
  //     },
  //     "tags": null,
  //     "created_at": "2024-10-21T18:46:08.937Z",
  //     "updated_at": "2024-10-21T18:46:08.937Z",
  //     "company": {
  //       "name": "Murta Clinica Veterinaria",
  //       "contact_data": {
  //         "email": [
  //           {
  //             "tipo": "CNPJ",
  //             "endereco_email": "elomurta@hotmail.com"
  //           }
  //         ],
  //         "telefone": [
  //           {
  //             "tipo": "Móvel",
  //             "numero": "55 (44) 99906825"
  //           },
  //           {
  //             "tipo": "Fixo",
  //             "numero": "55 (44) 30265297"
  //           }
  //         ]
  //       }
  //     },
  //     "contact": {
  //       "first_name": "Eloisa",
  //       "last_name": "Marcia Murta",
  //       "position": "Sócio-Administrador ",
  //       "contact_data": {
  //         "x": "",
  //         "email": [
  //           {
  //             "email": ""
  //           }
  //         ],
  //         "address": {
  //           "uf": "",
  //           "cep": "",
  //           "pais": "",
  //           "bairro": "",
  //           "numero": "",
  //           "endereco": "",
  //           "municipio": "",
  //           "complemento": ""
  //         },
  //         "facebook": "",
  //         "linkedin": "",
  //         "telefone": [
  //           {
  //             "numero": ""
  //           }
  //         ],
  //         "descricao": "",
  //         "instagram": ""
  //       }
  //     },
  //     "step": {
  //       "id": 28,
  //       "account_id": 4,
  //       "pipeline_id": 8,
  //       "name": "Contatar",
  //       "goal": null,
  //       "objective": "",
  //       "order": 1,
  //       "pipeline": {
  //         "id": 8,
  //         "name": "Comercial"
  //       }
  //     }
  //   }
  // }

  const [newData, setNewData] = useState({
    // account_id: null,
    // owner_id: null,
    // entity: '',
    // entity_id: null,
    // name: '',
    description: selectedActivity?.description,
    // type: '',
    status: selectedActivity?.status,
    coments: selectedActivity?.coments?.comment,
    dead_line: selectedActivity?.dead_line,
    flag: selectedActivity?.flag
  })

  useEffect(() => {
    if (quillRef.current) {
      const editor = quillRef.current.getEditor()
      editor.on('text-change', () => {
        setEditorContent(editor.root.innerHTML)
      })
    }
  }, [])

  async function getActiveActivityScript() {
    if (!selectedActivity?.deal?.step_id) return

    try {
      const response = await axios.get(
        `/scripts?account_id=${selectedActivity.account_id}&step_id=${selectedActivity.deal.step_id}`,
        { headers: authHeader() }
      )

      setActivityScriptList(response.data.data)
      console.log('getActiveActivityScript', response.data)
    } catch (error) {
      console.log('Erro ao obter o script da atividade', error)
    }
  }

  function submitComment(event) {
    const { name, value } = event.target

    setNewData((prevState) => ({
      ...prevState,
      coments: {
        ...prevState.coments,
        now: Math.floor(Date.now() / 1000),
        comment: editorContent
      }
    }))

    updateActivity({
      coments: {
        now: Math.floor(Date.now() / 1000),
        comment: editorContent
      }
    })
    setShowNewComment(false)
  }

  async function updateActivity(data) {
    try {
      const response = await axios.patch(`/activities/${selectedActivity.id}`, data, {
        headers: authHeader()
      })
      console.log('Atividade atualizada!', response)
    } catch (error) {
      console.log('Não foi possível atualizar a atividade.', error)
    }
  }

  function getActivityIcon(activityType) {
    switch (activityType) {
      case 'call':
        return <FaPhone color="white" />
      case 'task':
        return <FaTasks color="white" />
      case 'meeting':
        return <FaRegCalendarAlt color="white" />
      case 'visit':
        return <FaMapMarkerAlt color="white" />
      case 'email':
        return <FaRegEnvelope color="white" />
      case 'message':
        return <FaCommentAlt color="white" />
      default:
        return null
    }
  }

  const prioritiesList = [
    {
      value: 'Baixa prioridade',
      flag: 'low',
      color: '#49BFFF'
    },
    {
      value: 'Prioridade normal',
      flag: 'normal',
      color: '#e9e9e9'
    },
    {
      value: 'Alta prioridade',
      flag: 'high',
      color: '#F6E05E'
    },
    {
      value: 'Máxima prioridade ',
      flag: 'top',
      color: '#E53E3E'
    }
  ]

  function getColorByFlag(flag) {
    const priority = prioritiesList.find((item) => item.flag === flag)
    return priority ? priority.color : 'transparent'
  }

  function ScriptMessage({ side, message }) {
    let option = {}

    switch (side) {
      case 'seller':
        option.side = 'Vendedor: '
        option.schema = 'whatsapp'
        option.alignment = 'flex-end'
        option.borderFormat = '15px 15px 5px 15px'
        break
      case 'action':
        option.side = ''
        option.schema = 'teal'
        option.alignment = 'center'
        option.borderFormat = '15px'
        break
      case 'alert':
        option.side = ''
        option.schema = 'red'
        option.alignment = 'center'
        option.borderFormat = '15px'
        break
      default:
        option.side = `${side}:`
        option.schema = 'messenger'
        option.borderFormat = '5px 15px 15px 15px'
        option.alignment = 'flex-start'

        break
    }

    return (
      <Flex alignSelf={option.alignment} maxWidth="70%" marginY="10px">
        <Tag
          colorScheme={option.schema}
          width="fit-content"
          p="5px 10px"
          alignItems={'start'}
          boxShadow={'sm'}
          borderRadius={option.borderFormat}
        >
          <strong style={{ marginRight: '5px' }}>{option.side}</strong>
          {message}
        </Tag>
      </Flex>
    )
  }

  function normalizePhoneNumber(phoneNumber) {
    // Remove tudo que não é dígito
    const digitsOnly = phoneNumber.replace(/\D/g, '')
    return digitsOnly
  }

  return (
    <Flex gap={'20px'} mt="25px">
      <Flex
        flexDirection="column"
        bg="white"
        borderRadius={'20px'}
        p="20px"
        w="600px"
        h="60hv"
        boxShadow={'lg'}
      >
        {!selectedActivity?.type && (
          <Flex>
            <Text fontSize={'1rem'} fontWeight={'800'} mb="0px">
              Não há atividades cadastradas.
            </Text>
          </Flex>
        )}
        {selectedActivity?.type && (
          <Grid templateColumns="repeat(5, 1fr)" gap={2}>
            <GridItem w="100%" colSpan={4}>
              <Link href={`/admin/deal/${selectedActivity.deal.id}`}>
                <Flex gap={3} alignItems={'center'}>
                  <FaBriefcase color="#8f9bba" />
                  <Text>{`${selectedActivity.deal.name}`}</Text>

                  <IconButton
                    colorScheme={'white'}
                    color={getColorByFlag(selectedActivity.flag)}
                    // variant="outline"
                    aria-label="Priority"
                    borderRadius={'50px'}
                    size="sm"
                    p="1px"
                    w="20px"
                    icon={<Icon as={FaFlag} />}
                  />
                </Flex>
              </Link>
            </GridItem>
            <GridItem
              w="100%"
              style={{ display: 'flex', justifyContent: 'flex-end', height: 'fit-content' }}
              colSpan={1}
            >
              <Flex flexDirection={'column'} alignItems={'flex-end'}>
                <Flex gap={3}>
                  <Tag
                    bg="#E2E8F0"
                    w={'fit-content'}
                    fontSize={'0.75rem'}
                    borderRadius={'20px'}
                    mb="20px"
                  >{`${selectedActivity.deal.step.name}`}</Tag>
                </Flex>

                <Flex flexDirection={'row '} alignItems={'start'} gap="10px" ml="10px">
                  {selectedActivity.deal.contact.phone && (
                    <Link href={`tel:${selectedActivity.contact_phone}`}>
                      <Flex p="10px" ml="3px" bg="gray.200" borderRadius={'20px'} alignItems={'center'}>
                        <FaPhone />
                      </Flex>
                    </Link>
                  )}
                  {selectedActivity.contact_phone_type === 'whatsapp' && (
                    <Link href={`mailto:${selectedActivity.contact_phone}`}>
                      <Flex p="10px" ml="3px" bg="gray.200" borderRadius={'20px'} alignItems={'center'}>
                        <FaWhatsapp />
                      </Flex>
                    </Link>
                  )}
                  {selectedActivity.contact_email && (
                    <Link href={`mailto:${selectedActivity.contact_email}`}>
                      <Flex p="10px" ml="3px" bg="gray.200" borderRadius={'20px'} alignItems={'center'}>
                        <FaEnvelope />
                      </Flex>
                    </Link>
                  )}
                </Flex>
              </Flex>
            </GridItem>
            <GridItem
              w="100%"
              style={{ display: 'flex', justifyContent: 'flex-start', height: 'fit-content' }}
              colSpan={3}
            >
              <Link href={`/admin/contact/${selectedActivity.deal.contact_id}`}>
                <Flex>
                  <FaUserTie color="#8f9bba" style={{ marginTop: '3px' }} />
                  <Flex flexDirection={'column'} ml="10px">
                    <Text
                      fontSize={'0.9rem'}
                      fontWeight={'800'}
                    >{`${selectedActivity.deal.contact.first_name} ${selectedActivity.deal.contact.last_name}`}</Text>
                    <Text fontSize={'0.9rem'}>{`${selectedActivity.deal.contact.position}`}</Text>
                  </Flex>
                </Flex>
              </Link>
            </GridItem>
            <GridItem
              w="100%"
              style={{ display: 'flex', justifyContent: 'flex-end', height: 'fit-content' }}
              colSpan={2}
            >
              <Flex flexDirection={'column'}>
                {selectedActivity.deal.contact.contact_data.telefone.length > 0 &&
                  selectedActivity.deal.contact.contact_data.telefone.map((phone, index) => (
                    <Link href={`tel:${normalizePhoneNumber(phone.numero)}`}>
                      {phone?.numero && (
                        <Tag
                          fontSize={'0.8rem'}
                          colorScheme="whatsapp"
                          key={index}
                          borderRadius={'20px'}
                          p="5px 10px"
                        >
                          {phone.tipo === 'Whatsapp' && <MdWhatsapp style={{ marginRight: '5px' }} />}
                          {phone.tipo === 'Fixo' && <MdPhone style={{ marginRight: '5px' }} />}
                          {phone.tipo === 'Móvel' && <MdMobileFriendly style={{ marginRight: '5px' }} />}
                          {phone.numero}
                        </Tag>
                      )}
                    </Link>
                  ))}
              </Flex>
            </GridItem>
            <GridItem
              w="100%"
              style={{ display: 'flex', justifyContent: 'flex-start', height: 'fit-content' }}
              colSpan={3}
            >
              <Link href={`/admin/company/${selectedActivity.deal.company_id}`}>
                <Flex>
                  <FaBuilding color="#8f9bba" style={{ marginTop: '3px' }} />
                  <Flex flexDirection={'column'} ml="10px">
                    <Text
                      fontSize={'0.9rem'}
                      fontWeight={'800'}
                    >{`${selectedActivity.deal.company.name} `}</Text>
                  </Flex>
                </Flex>
              </Link>
            </GridItem>
            <GridItem
              w="100%"
              style={{ display: 'flex', justifyContent: 'flex-end', height: 'fit-content' }}
              colSpan={2}
            >
              <Flex flexDirection={'column'} gap={'5px'}>
                {selectedActivity.deal.company.contact_data.telefone.length > 0 &&
                  selectedActivity.deal.company.contact_data.telefone.map((phone, index) => (
                    <Link href={`tel:${normalizePhoneNumber(phone.numero)}`}>
                      {phone.numero && (
                        <Tag
                          fontSize={'0.8rem'}
                          colorScheme="whatsapp"
                          key={index}
                          borderRadius={'20px'}
                          p="5px 10px"
                        >
                          {phone.tipo === 'Whatsapp' && <MdWhatsapp style={{ marginRight: '5px' }} />}
                          {phone.tipo === 'Fixo' && <MdPhone style={{ marginRight: '5px' }} />}
                          {phone.tipo === 'Móvel' && <MdMobileFriendly style={{ marginRight: '5px' }} />}

                          {phone.numero}
                        </Tag>
                      )}
                    </Link>
                  ))}
              </Flex>
            </GridItem>
            <GridItem w="100%" colSpan={5}>
              <Flex flexDirection={'row'} mt="20px" alignItems="center">
                <Box bg={'#4dadd7'} p="10px" borderRadius="50px" mr="10px" w="37px" h="37px">
                  {selectedActivity.type && getActivityIcon(selectedActivity.type)}
                </Box>
                <Flex flexDirection={'column'}>
                  {/* <Text>{selectedActivity.deal_name}</Text> */}
                  <Text fontSize={'0.85rem'}>{selectedActivity.description}</Text>
                </Flex>
              </Flex>
            </GridItem>
            <GridItem w="100%" colSpan={5}>
              <Flex mt="5px" width={'100%'}>
                {!showNewComment && (
                  <Flex
                    onClick={() => setShowNewComment(true)}
                    background={'#fafafa'}
                    padding={'10px'}
                    borderRadius={'20px'}
                    cursor={'text'}
                    width={'100%'}
                  >
                    <FaComment color="#8f9bba" className="mr-3" size={'14px'} />
                    {editorContent && (
                      <Text
                        dangerouslySetInnerHTML={{ __html: editorContent }}
                        isTruncated
                        noOfLines={1}
                        maxW="100%"
                      ></Text>
                    )}
                  </Flex>
                )}
                {showNewComment && (
                  <Flex
                    flexDirection={'column'}
                    width={'100%'}
                    background={'#fafafa'}
                    p="10px"
                    borderRadius={'20px'}
                  >
                    <ReactQuill
                      ref={quillRef}
                      value={editorContent}
                      onChange={setEditorContent}
                      theme="snow"
                      modules={{
                        toolbar: [
                          // [{ header: [1, 2, 3, 4, 5, 6, false] }],
                          // [{ size: ['small', false, 'large', 'huge'] }],
                          ['bold', 'italic', 'underline', 'strike', 'blockquote', 'color'],
                          [{ list: 'ordered' }, { list: 'bullet' }],
                          // [{ align: [] }],
                          [{ indent: '-1' }, { indent: '+1' }],
                          // [{ color: [] }, { background: [] }],
                          // [{ font: [] }],
                          ['link', 'image'],
                          ['clean'] // Adicione mais opções da barra de ferramentas conforme necessário
                        ]
                      }}
                    />
                    <Flex gap="10px" flexDirection={'row-reverse'}>
                      <Button
                        bg="#49BFFF"
                        color="white"
                        colorScheme="blue"
                        borderRadius="20px"
                        fontSize={'14px'}
                        onClick={submitComment}
                        width={'fit-content'}
                      >
                        Salvar
                      </Button>
                      <Button
                        variant="outline"
                        color="#49BFFF"
                        colorScheme="blue"
                        borderRadius="20px"
                        fontSize={'14px'}
                        onClick={() => setShowNewComment(false)}
                        width={'fit-content'}
                      >
                        Cancelar
                      </Button>
                    </Flex>
                  </Flex>
                )}
              </Flex>
            </GridItem>
            <GridItem w="100%" colSpan={5}>
              <SelectButton
                name="status"
                value={selectedActivity.status}
                onChange={handleChange}
                options={[
                  { value: 'todo', name: 'Pendente' },
                  // { value: 'doing', name: 'Fazendo' },
                  // { value: 'blocked', name: 'Impedida' },
                  { value: 'postponed', name: 'Adiada' },
                  { value: 'canceled', name: 'Cancelada' },
                  { value: 'done', name: 'Realizada' }
                ]}
                optionLabel="name"
                style={{
                  display: 'flex',
                  fontSize: '12px',
                  width: '100%',
                  margin: '0px 0 0px 0px',
                  justifyContent: 'flex-end'
                }}
                pt={{
                  button: {
                    style: { fontSize: '12px', fontWeight: '500', padding: '5px 10px', borderRadius: '20px' }
                  },
                  label: { style: { fontSize: '12px', fontWeight: '500' } }
                }}
              />
            </GridItem>
          </Grid>
        )}
      </Flex>
      {activityScriptList.length > 0 && (
        <Flex bg="white" borderRadius={'20px'} p="20px" boxShadow={'lg'}>
          <Flex
            id="script-list"
            flexDirection="column"
            w="100%"
            maxHeight="55vh" // Set maximum height
            overflowY="auto" // Enable vertical scrolling
            sx={{
              // Custom scrollbar styling
              '&::-webkit-scrollbar': {
                width: '8px'
              },
              '&::-webkit-scrollbar-track': {
                background: '#f1f1f1'
              },
              '&::-webkit-scrollbar-thumb': {
                background: '#888',
                borderRadius: '4px'
              },
              '&::-webkit-scrollbar-thumb:hover': {
                background: '#555'
              }
            }}
          >
            {activityScriptList.map((item, index) => (
              <Flex
                flexDirection={'column'}
                key={index}
                borderRadius={'20px'}
                boxShadow={'sm'}
                p="20px"
                maxWidth={'640px'}
                mb="20px"
                mr="20px"
              >
                <Text fontSize={'1rem'} fontWeight={'800'} mb="13px" key={item.id}>
                  {item.name}
                </Text>

                <Text fontSize={'0.85rem'} fontWeight={'500'} mb="12px" key={item.id} mt="">
                  {item.subject}
                </Text>
                <Text
                  dangerouslySetInnerHTML={{ __html: item.message }}
                  fontSize={'0.8rem'}
                  fontWeight={'500'}
                  mb="12px"
                  pl="12px"
                >
                  {/* {item.message} */}
                </Text>
                <label
                  style={{
                    color: '#A0AEC0',
                    fontSize: '12px',
                    fontWeight: '500',
                    paddingLeft: '0px'
                  }}
                >
                  Roteiro do script
                </label>
                <Flex
                  background={'#fafafa'}
                  padding={'20px'}
                  borderRadius={'10px'}
                  flexDirection={'column'}
                  alignItems={'center'}
                  maxWidth={'600px'}
                >
                  {item.script.length > 0 &&
                    item.script.map((messageItem, messageIndex) => (
                      <Flex
                        width={'100%'}
                        alignItems={'center'}
                        // flexFlow={messageItem.side === 'seller' ? 'row-reverse' : 'row'}
                      >
                        <Flex flexDirection={'column'} alignItems={'center'} width={'100%'}>
                          <ScriptMessage
                            key={messageIndex}
                            side={messageItem.side}
                            message={messageItem.message}
                          />
                        </Flex>
                      </Flex>
                    ))}
                </Flex>
              </Flex>
            ))}
          </Flex>
        </Flex>
      )}
    </Flex>
  )
}
