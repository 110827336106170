import React, { useState, useEffect, useRef } from 'react'
import axios from 'api/axios'
// import { useParams } from 'react-router-dom';
import apiDados from '../../../api/api-dados'
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Input,
  InputGroup,
  InputRightElement,
  SimpleGrid,
  Select,
  Stack,
  Switch,
  useColorModeValue,
  useToast,
  Text,
  PopoverTrigger,
  Tag,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  useDisclosure,
  Popover
} from '@chakra-ui/react'
import { PhoneIcon, SearchIcon } from '@chakra-ui/icons'
import Card from '../../../components/card/Card'
// import { NotAllowedIcon } from '@chakra-ui/icons';
// import formatDate from 'hooks/formatDate';
import TableConsulta from './components/tableConsulta'
// import ModalConsultaCnpj from './components/modalProspeccao'
import {
  cnaes,
  naturezasJuridicas,
  situacaoCadastral,
  estadosUF,
  listaMunicipios
} from 'contexts/dadosBrasileiros'
import { MultiSelect } from 'primereact/multiselect'
import { IconField } from 'primereact/iconfield'
import { InputIcon } from 'primereact/inputicon'
import { InputText } from 'primereact/inputtext'
import { Dropdown } from 'primereact/dropdown'
import { Chips } from 'primereact/chips'
import { Toast } from 'primereact/toast'
import { GoogleSearchApi } from './components/googlesearchapicomponent'
import { ConsultaCNPJRF } from './components/consultaCNPJRF'
import authHeader from 'hooks/authHeader'
import { getUserAccountId } from 'hooks/authService'
import { MdClose } from 'react-icons/md'

export default function Settings() {
  const textColor = useColorModeValue('navy.700', 'white')
  const { onOpen, onClose, isOpen } = useDisclosure()
  const accountId = getUserAccountId()

  let capital_social_lte = null
  let capital_social_gte = null
  let data_abertura_lte = null
  let data_abertura_gte = null

  const [entriesFound, setEntriesFound] = useState()
  const [loadMoreFormData, setLoadMoreFormData] = useState()

  const [formData, setFormData] = useState({
    query: {
      atividade_principal: [],
      bairro: [],
      cep: [],
      ddd: [],
      municipio: [],
      natureza_juridica: ['2062'],
      situacao_cadastral: 'ATIVA',
      termo: [],
      uf: []
    },
    page: 0,
    extras: {
      com_contato_telefonico: true,
      com_email: true,
      excluir_mei: true,
      incluir_atividade_secundaria: false,
      somente_celular: false,
      somente_filial: false,
      somente_fixo: false,
      somente_matriz: false,
      somente_mei: false
    },
    range_query: {
      capital_social: { lte: capital_social_lte, gte: capital_social_gte },
      data_abertura: { lte: data_abertura_lte, gte: data_abertura_gte }
    }
  })

  const [resultado, setResultado] = useState([])
  const [companiesList, setCompaniesList] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    getCompaniesList()
  }, [])

  useEffect(() => {
    console.log('consulta formData', formData)
  }, [formData])

  useEffect(() => {
    console.log('consulta loadMoreFormData', loadMoreFormData)
  }, [loadMoreFormData])

  async function getCompaniesList() {
    if (!accountId) return

    try {
      const response = await axios.get(`/companies-list?account_id=${accountId}`, { headers: authHeader() })
      console.log('response companies-list', response)
      setCompaniesList(response.data.data)
    } catch (error) {
      console.log('Erro ao carregar a lista', error)
    }
    return
  }

  function getFormattedDateTime() {
    const now = new Date()

    // Mapeamento dos meses em formato abreviado
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

    // Formatação dos componentes da data e hora
    const day = now.getDate()
    const month = months[now.getMonth()]
    const year = now.getFullYear()

    const hours = String(now.getHours()).padStart(2, '0')
    const minutes = String(now.getMinutes()).padStart(2, '0')

    // Combinação final no formato desejado
    return `${day} ${month} ${year} • ${hours}:${minutes}`
  }

  async function saveList() {
    const today = getFormattedDateTime()

    const listData = {
      account_id: accountId,
      name: `Lista ${today} • ${resultado.length} empresas`,
      data: {
        resultado: JSON.stringify(resultado),
        query: JSON.stringify(formData)
      }
    }
    try {
      const response = await axios.post(`/companies-list`, listData, { headers: authHeader() })
      console.log('Lista salva com sucesso', response)
      toastSuccess('Lista salva.')
    } catch (error) {
      console.log('Não foi possível salvar a lista', error)
      toastError('Erro ao salvar a lista.')
    }
  }

  async function deleteList(id) {
    try {
      const response = await axios.delete(`/companies-list/${id}`, { headers: authHeader() })
      console.log('Lista excluída com sucesso', response)
      toastSuccess('Lista excluída.')
    } catch (error) {
      console.log('Não foi possível excluír a lista', error)
      toastError('Erro ao excluír a lista.')
    }
  }

  const toast = useRef(null)
  const toastSuccess = (message) => {
    toast.current.show({ severity: 'success', summary: 'Success', detail: message })
  }
  const toastError = (message) => {
    toast.current.show({
      severity: 'error',
      summary: 'Error',
      detail: message
    })
  }

  const handleLoadMore = async () => {
    setLoading(true)
    const { page: currentPage } = loadMoreFormData
    console.log('Página original:', currentPage)
    setLoadMoreFormData((prevState) => ({ ...prevState, page: currentPage + 1 }))
    console.log('Página nova:', loadMoreFormData?.page)

    try {
      const {
        data: {
          data: { cnpj }
        }
      } = await apiDados.post('/cnpj/search', loadMoreFormData)

      const cnpjsUnicos = new Set(resultado.map((item) => item.cnpj))
      const novosCnpjs = cnpj.filter((item) => !cnpjsUnicos.has(item.cnpj))
      setResultado((prevData) => [...prevData, ...novosCnpjs])

      console.log('Sucesso!', novosCnpjs)
      console.log('Novo loadMoreFormData:', loadMoreFormData)
    } catch (error) {
      console.error('Erro ao carregar mais dados:', error.response)
    }
    setLoading(false)
  }

  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <Grid height="0px" templateRows="repeat(2, 1fr)" templateColumns="repeat(6, 1fr)" gap={5}>
        <GridItem rowSpan={2} colSpan={2}>
          <ConsultaCNPJRF
            loadData={setResultado}
            updateLoadMoreFormData={setLoadMoreFormData}
            formDataFromParent={formData}
          />
        </GridItem>
        <GridItem colSpan={4}>
          <Box>
            <Flex>
              <Flex>
                <Popover
                  isOpen={isOpen}
                  // initialFocusRef={firstFieldRef}
                  onOpen={onOpen}
                  onClose={onClose}
                  placement="bottom"
                  closeOnBlur={false} // Evita o fechamento ao clicar fora
                >
                  <Flex w="full" justifyContent={'space-between'} mb="24px">
                    <PopoverTrigger>
                      {/* <Tag
                        bg="#E2E8F0"
                        w={'fit-content'}
                        fontSize={'0.7rem'}
                        borderRadius={'20px'}
                        onClick={onOpen}
                        cursor="pointer"
                        h={'24px'}
                      >
                        + Novo contato
                      </Tag> */}
                      <Button
                        colorScheme="blue"
                        background={'#49BFFF'}
                        borderRadius={'50px'}
                        onClick={onOpen}
                        disabled={loading}
                        mr="10px"
                      >
                        Suas listas
                      </Button>
                    </PopoverTrigger>
                  </Flex>
                  <PopoverContent
                    p={5}
                    borderRadius="20px"
                    boxShadow={'2xl'}
                    w={'30hv'}
                    _focus={{ border: 'none' }}
                  >
                    <PopoverArrow />
                    <PopoverCloseButton mt="5px" p="15px" borderRadius={'50px'} />
                    <Flex gap={'10px'} flexDirection={'column'} p={'20px'}>
                      <Text fontSize={'24px'} fontWeight={'800'}>
                        Listas salvas
                      </Text>
                      {companiesList?.length > 0 &&
                        companiesList.map((company, index) => (
                          <Flex key={index} gap={'10px'} p={'10px'} justifyContent={'space-between'}>
                            <Text>{company.name}</Text>
                            <Button
                              colorScheme="blue"
                              background={'#49BFFF'}
                              borderRadius={'50px'}
                              onClick={() => {
                                setResultado(JSON.parse(company.data.resultado))
                                setFormData(JSON.parse(company.data.query))
                                setLoadMoreFormData(JSON.parse(company.data.query)) // Precisamos atualizar o loadMoreFormData aqui
                              }}
                              disabled={loading}
                              mr="5px"
                              height={'30px'}
                              fontWeight={500}
                            >
                              Carregar Lista
                            </Button>
                            <Button
                              colorScheme="red"
                              variant="outline"
                              borderRadius={'50px'}
                              onClick={() => deleteList(company.id)}
                              disabled={loading}
                              p="3px 10px"
                              height={'30px'}
                              width={'fit-content'}
                              fontWeight={500}
                            >
                              <MdClose /> Excluir
                            </Button>
                          </Flex>
                        ))}
                    </Flex>
                  </PopoverContent>
                </Popover>
              </Flex>
              <Flex>
                {resultado && resultado.length > 0 && (
                  <Button
                    variant="brand"
                    colorScheme="blue"
                    borderRadius={'50px'}
                    onClick={saveList}
                    // disabled={loading}
                    cursor={'pointer'}
                  >
                    Salvar lista
                  </Button>
                )}
              </Flex>
            </Flex>
          </Box>
        </GridItem>
        <GridItem colSpan={4}>
          {resultado && resultado.length > 0 && (
            <Card>
              <Stack>
                <Text fontSize={'18px'} fontWeight={'800'} mb="20px">
                  {entriesFound} Empresas encontradas
                </Text>
                <TableConsulta tableData={resultado || []} />
              </Stack>

              <Button
                colorScheme="blue"
                background={'#49BFFF'}
                borderRadius="20px"
                onClick={handleLoadMore}
                disabled={loading}
              >
                Carregar Mais
              </Button>
            </Card>
          )}
        </GridItem>
      </Grid>
      {/* <SimpleGrid mb="20px" columns={{ sm: 1, md: 2 }} spacing={{ base: '20px', xl: '20px' }}>
        {resultado && resultado.length > 0 && (
          <Card>
            <Stack>
              <Text fontSize={'18px'} fontWeight={'800'} mb="20px">
                {entriesFound} Empresas encontradas
              </Text>
              <TableConsulta tableData={resultado || []} />
            </Stack>

            <Button onClick={handleLoadMore} disabled={loading}>
              Carregar Mais
            </Button>
          </Card>
        )}
      </SimpleGrid> */}
      {/* <GoogleSearchApi /> //Colocar pra rodar */}
      <Toast position="bottom-center" ref={toast} />
    </Box>
  )
}
