import React, { useState, useEffect, useRef } from 'react'
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Box,
  FormHelperText,
  InputRightElement,
  IconButton,
  Icon,
  Grid,
  GridItem,
  Button,
  Checkbox,
  FormControl,
  InputGroup,
  InputLeftAddon,
  FormLabel,
  Flex,
  HStack,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Textarea,
  SimpleGrid,
  Select,
  Stack,
  useColorModeValue,
  Text,
  Tag
} from '@chakra-ui/react'
import { useParams } from 'react-router-dom'
import axios from 'api/axios'
import authHeader from 'hooks/authHeader'
import Card from 'components/card/Card'
import { getUserAccountId } from 'hooks/authService'
import {
  FaBuilding,
  FaBriefcase,
  FaCalendarAlt,
  FaCheckCircle,
  FaCity,
  FaDollarSign,
  FaEnvelope,
  FaFacebook,
  FaFileAlt,
  FaGlobe,
  FaGavel,
  FaHome,
  FaIndustry,
  FaInfoCircle,
  FaInstagram,
  FaLinkedin,
  FaMapMarkerAlt,
  FaMapPin,
  FaMapSigns,
  FaPhone,
  FaPlus,
  FaSearch,
  FaUserTie,
  FaUsers,
  FaWhatsapp
} from 'react-icons/fa'
import { InputText } from 'primereact/inputtext'
import { Dropdown } from 'primereact/dropdown'
import { InputMask } from 'primereact/inputmask'
import { InputTextarea } from 'primereact/inputtextarea'
import { Toast } from 'primereact/toast'
import { confirmDialog, ConfirmDialog } from 'primereact/confirmdialog'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'

export default function CompanyForm() {
  const textColor = useColorModeValue('navy.700', 'white')

  const account_id = getUserAccountId()

  const { contact_id: contactIdFromParams } = useParams() || ''
  const [contact_id, setContactId] = useState(contactIdFromParams)
  const [companiesList, setCompaniesList] = useState()

  const location = useLocation()
  const contactId = parseInt(location.pathname.replace('/admin/contact/', ''), 10)
  // console.log('contactId', contactId)

  const [formData, setFormData] = useState({
    account_id: account_id,
    company_id: null,
    title: '',
    first_name: '',
    last_name: '',
    position: '',
    contact_data: {
      whatsapp: '',
      telefone: [
        {
          tipo: '', // Fixo, whatsapp, móvel, voip
          numero: ''
        }
      ],
      email: [
        {
          tipo: '', // principal, suporte, ...
          endereco_email: ''
        }
      ],
      address: {
        pais: '',
        uf: '',
        municipio: '',
        bairro: '',
        tipo_logradouro: '',
        logradouro: '',
        numero: '',
        cep: ''
      },
      descricao: ''
    },
    tags: []
  })

  useEffect(() => {
    console.log('formData', formData)
  }, [account_id, formData])

  useEffect(() => {
    getContactData()
    getAccountCompanies()
  }, [])

  async function getContactData() {
    if (!contact_id) return
    try {
      const { data } = await axios.get(`/contacts/${contact_id}`, { headers: authHeader() })
      console.log('getContactData', data)
      setFormData({
        account_id: data.account_id,
        company_id: data.company_id,
        contact_data: data.contact_data,
        first_name: data.first_name,
        last_name: data.last_name,
        position: data.position,
        title: data.title,
        tags: data.tags
      })
    } catch (error) {
      console.log('Erro ao carregar dados da empresa: ', error)
    }
  }

  async function getAccountCompanies() {
    if (!account_id) return
    try {
      const { data } = await axios.get(`/companies?account_id=${account_id}`, {
        headers: authHeader(),
        params: {
          $select: ['name', 'id'] // Specify fields to select
        }
      })
      setCompaniesList(data.data)
      console.log('CompaniesList', data.data)
    } catch (error) {
      console.log('Erro ao carregar dados da empresa: ', error)
    }
  }

  const handleChange = (e) => {
    const { name, value } = e.target

    setFormData((prevState) => {
      const newState = { ...prevState }

      const keys = name.split('.') // Divide o name em um array
      let temp = newState

      for (let i = 0; i < keys.length - 1; i++) {
        if (!temp[keys[i]]) {
          temp[keys[i]] = {}
        }
        temp = temp[keys[i]]
      }

      temp[keys[keys.length - 1]] = value

      return newState
    })
  }

  const handleSubmit = async (event) => {
    event.preventDefault()

    if (contact_id) {
      try {
        const response = await axios.patch(`/contacts/${contact_id}`, formData, {
          headers: authHeader()
        })
        toastSuccess('Contato cadastrado.')
        console.log('Dados atualizados com sucesso!', response.data)
      } catch (error) {
        toastError('Ocorreu um erro ao cadastrar.')
        console.error('Ocorreu um erro ao enviar os dados:', error.response.data)
      }
    } else {
      try {
        const response = await axios.post('/contacts', formData, {
          headers: authHeader()
        })
        console.log('Contato cadastrado com sucesso!', response.data)
        setContactId(response.data.id)
        toastSuccess('Contato cadastrado.')
      } catch (error) {
        console.error('Ocorreu um erro ao cadastrar o contato:', error.response.data)
        toastError('Ocorreu um erro ao cadastrar.')
      }
    }
    console.log(formData)
  }

  async function handleDeleteContact() {
    if (!contact_id) return

    try {
      const response = axios.delete(`/contacts/${contact_id}`, { headers: authHeader() })
      console.log('Contato excluído!', response)
      toastSuccess('Contato excluído.')
      setTimeout(() => {
        window.location.href = '/admin/companies'
      }, 3000)
    } catch (error) {
      console.log('Erro ao excluir o contato.', error)
      toastError('Não foi possível excluir este contato.')
    }
  }

  const confirmDelete = () => {
    confirmDialog({
      header: 'Confirmação de Exclusão',
      message: 'Tem certeza que deseja excluir este contato?',
      icon: 'pi pi-exclamation-triangle text-red-700',
      // acceptClassName: 'p-2 border-round-2xl ',
      rejectClassName: 'p-1 border-round-2xl text-blue-400 ',
      acceptLabel: 'Excluir',
      rejectLabel: 'Cancelar',
      accept: () => handleDeleteContact(), // Chama a função de exclusão se o usuário confirmar
      reject: () => console.log('Exclusão cancelada') // Ação se o usuário cancelar
    })
  }

  const toast = useRef(null)
  const toastSuccess = (message) => {
    toast.current.show({ severity: 'success', summary: 'Success', detail: message })
  }
  const toastError = (message) => {
    toast.current.show({
      severity: 'danger',
      summary: 'Error',
      detail: message
    })
  }

  return (
    <>
      <Card>
        <Flex justifyContent={'space-between'}>
          <Text display="flex" ms="4px" fontSize="xl" fontWeight="800" color={textColor} mb="8px">
            {formData.first_name ? `${formData.first_name} ${formData.last_name}` : 'Novo Contato'}
          </Text>
        </Flex>

        <Grid templateColumns="repeat(4, 1fr)" gap="16px">
          <GridItem colSpan={4}>
            <label
              style={{
                color: '#A0AEC0',
                fontSize: '12px',
                fontWeight: '500',
                paddingLeft: '0px'
              }}
            >
              Nome e Sobrenome
            </label>
            <Flex py="0.3rem" mt="-5px" alignItems={'center'}>
              <div className="p-inputgroup flex-1 gap-1">
                <span className="p-inputgroup-addon">
                  <FaUserTie />
                </span>
                <Dropdown
                  value={formData.title}
                  name="title"
                  className="w-4"
                  onChange={(e) =>
                    setFormData((prevState) => ({
                      ...prevState,
                      title: e.value // Armazene apenas o id
                    }))
                  }
                  placeholder="Saudação"
                  options={['', 'Sr.', 'Sra.', 'Dr.', 'Dra.']}
                  optionLabel="name"
                  pt={{
                    root: { background: '#fafafa' },
                    input: {
                      style: {
                        background: '#fafafa',
                        fontSize: '14px',
                        padding: '10px'
                      }
                    },
                    trigger: {
                      style: {
                        background: '#fafafa',
                        fontSize: '14px'
                      }
                    },
                    item: {
                      style: {
                        fontSize: '14px',
                        padding: '8px 16px',
                        width: '100%',
                        background: '#fafafa'
                      }
                    },
                    panel: {
                      style: { marginTop: '5px' }
                    }
                  }}
                />
                <InputText
                  value={formData.first_name}
                  name="first_name"
                  onChange={handleChange}
                  placeholder="..."
                  v-model="value1"
                  style={{
                    padding: ' 0px 10px',
                    width: '100%',
                    background: '#fafafa',
                    fontSize: '14px'
                  }}
                  className="outline-none"
                />
                <InputText
                  value={formData.last_name}
                  name="last_name"
                  onChange={handleChange}
                  placeholder="..."
                  v-model="value1"
                  style={{
                    padding: ' 0px 10px',
                    width: '100%',
                    background: '#fafafa',
                    fontSize: '14px'
                  }}
                  className="outline-none p-inputgroup-addon"
                />
              </div>
            </Flex>
          </GridItem>
          <GridItem colSpan={2}>
            <label
              style={{
                color: '#A0AEC0',
                fontSize: '12px',
                fontWeight: '500',
                paddingLeft: '0px'
              }}
            >
              Cargo
            </label>
            <Flex py="0.3rem" mt="-5px" alignItems={'center'}>
              <div className="p-inputgroup flex-1">
                <span className="p-inputgroup-addon">
                  <FaUserTie />
                </span>
                <InputText
                  value={formData.position}
                  name="position"
                  onChange={handleChange}
                  placeholder="..."
                  v-model="value1"
                  style={{
                    padding: ' 0px 10px',
                    width: '100%',
                    background: '#fafafa',
                    fontSize: '14px'
                  }}
                  className="outline-none"
                />
              </div>
            </Flex>
            <label
              style={{
                color: '#A0AEC0',
                fontSize: '12px',
                fontWeight: '500',
                paddingLeft: '0px'
              }}
            >
              Email
            </label>
            <Flex py="0.3rem" mt="-5px" alignItems={'center'}>
              <div className="p-inputgroup flex-1">
                <span className="p-inputgroup-addon">
                  <FaEnvelope />
                </span>
                <InputText
                  value={formData.contact_data.email[0]?.endereco_email}
                  name="endereco_email"
                  onChange={(e) =>
                    setFormData((prevState) => ({
                      ...prevState,
                      contact_data: {
                        ...prevState.contact_data,
                        email: Array.isArray(prevState.contact_data.email)
                          ? prevState.contact_data.email.map((mail, index) =>
                              index === 0 ? { ...mail, endereco_email: e.target.value } : mail
                            )
                          : [{ tipo: '', endereco_email: e.target.value }] // Garantir que seja um array
                      }
                    }))
                  }
                  placeholder="..."
                  v-model="value1"
                  style={{
                    padding: ' 0px 10px',
                    width: '100%',
                    background: '#fafafa',
                    fontSize: '14px'
                  }}
                  className="outline-none"
                />
              </div>
            </Flex>
          </GridItem>
          <GridItem colSpan={2}>
            <label
              style={{
                color: '#A0AEC0',
                fontSize: '12px',
                fontWeight: '500',
                paddingLeft: '0px'
              }}
            >
              Empresa
            </label>
            <Flex py="0.3rem" mt="-5px" alignItems={'center'}>
              <div className="p-inputgroup flex-1">
                <span className="p-inputgroup-addon">
                  <FaIndustry />
                </span>
                <Dropdown
                  value={companiesList?.find((company) => company.id === formData.company_id)}
                  name="company_id"
                  onChange={(e) =>
                    setFormData((prevState) => ({
                      ...prevState,
                      company_id: e.value.id // Armazene apenas o id
                    }))
                  }
                  placeholder="Selecione a empresa"
                  options={companiesList}
                  optionLabel="name"
                  className="w-full"
                  filter
                  pt={{
                    root: { background: '#fafafa', width: '100%' },
                    input: {
                      style: {
                        width: '100%',
                        background: '#fafafa',
                        fontSize: '14px',
                        padding: '10px'
                      }
                    },
                    trigger: {
                      style: {
                        background: '#fafafa',
                        fontSize: '14px'
                      }
                    },
                    item: {
                      style: {
                        fontSize: '14px',
                        padding: '8px 16px',
                        width: '100%',
                        background: '#fafafa'
                      }
                    },
                    panel: {
                      style: { marginTop: '5px' }
                    }
                  }}
                />
              </div>
            </Flex>
            <label
              style={{
                color: '#A0AEC0',
                fontSize: '12px',
                fontWeight: '500',
                paddingLeft: '0px'
              }}
            >
              Telefone
            </label>
            <Flex py="0.3rem" mt="-5px" alignItems={'center'}>
              <div className="p-inputgroup flex-1">
                <span className="p-inputgroup-addon">
                  <FaPhone />
                </span>
                <InputMask
                  keyfilter="int"
                  mask="+99 (99) 99999-9999"
                  value={formData.contact_data.telefone[0].numero}
                  name="numero"
                  onBlur={(e) =>
                    setFormData((prevState) => ({
                      ...prevState,
                      contact_data: {
                        ...prevState.contact_data,
                        telefone: prevState.contact_data.telefone.map((tel, index) =>
                          index === 0 ? { ...tel, numero: e.target.value } : tel
                        )
                      }
                    }))
                  }
                  placeholder="..."
                  style={{
                    padding: '0px 10px',
                    width: '100%',
                    background: '#fafafa',
                    fontSize: '14px'
                  }}
                  className="outline-none"
                />
              </div>
            </Flex>
          </GridItem>
        </Grid>

        <label
          style={{
            color: '#A0AEC0',
            fontSize: '12px',
            fontWeight: '500',
            paddingLeft: '0px'
          }}
        >
          Descrição
        </label>
        <div className="p-inputgroup flex-1">
          <span className="p-inputgroup-addon">
            <FaUserTie />
          </span>
          <InputTextarea
            value={formData.contact_data.descricao}
            name="descricao"
            rows={'4'}
            onChange={(e) =>
              setFormData({
                ...formData,
                contact_data: {
                  ...formData.contact_data,
                  descricao: e.target.value
                }
              })
            }
            placeholder="..."
            v-model="value1"
            style={{
              padding: ' 10px',
              width: '100%',
              background: '#fafafa',
              fontSize: '14px'
            }}
            className="outline-none"
          />
        </div>
        <Flex gap={'20px'} flexDirection={'row-reverse'}>
          <Button
            mt="20px"
            background={'#49BFFF'}
            colorScheme="blue"
            onClick={handleSubmit}
            borderRadius={'50px'}
          >
            Salvar
          </Button>
          {contact_id && (
            <Button
              mt="20px"
              // background={'#49BFFF'}
              colorScheme="blue"
              onClick={confirmDelete}
              borderRadius={'50px'}
              variant="outline"
            >
              Excluir
            </Button>
          )}
        </Flex>
      </Card>
      <Toast position="bottom-center" ref={toast} />
      <ConfirmDialog
        defaultFocus={'reject'}
        pt={{
          acceptButton: {
            style: {
              background: '#cc0000',
              borderRadius: '20px',
              color: 'white',
              padding: '5px 10px',
              marginLeft: '20px'
            }
          }
          // rejectButton: {
          //   style: {
          //     padding: '5px 10px',
          //     // border: '1px solid #49BFFF',
          //     background: '#49BFFF',
          //     borderRadius: '20px',
          //     color: '#49BFFF'
          //   }
          // }
        }}
      />
    </>
  )
}
