import React, { useEffect, useRef, useState } from 'react'
import { Button, Flex, IconButton, Stack, Tag, Text } from '@chakra-ui/react'
import { Card } from 'primereact/card'
import { Timeline } from 'primereact/timeline'
import { Accordion, AccordionTab } from 'primereact/accordion'
import { getUserAccountId } from 'hooks/authService'
import { InputText } from 'primereact/inputtext'
import { FaComment, FaEdit, FaFilter, FaPlus, FaTrash, FaTrashAlt, FaUserTie } from 'react-icons/fa'
import { Dropdown } from 'primereact/dropdown'
import ReactQuill from 'react-quill'
import { MdClose } from 'react-icons/md'
import axios from 'api/axios'
import authHeader from 'hooks/authHeader'
import { Toast } from 'primereact/toast'

export default function PlayBook() {
  const accountId = getUserAccountId()

  const [editorContent, setEditorContent] = useState('')
  const [newScriptSide, setNewScriptSide] = useState('')
  const [newScriptMessage, setNewScriptMessage] = useState('')
  const [refreshState, setRefreshState] = useState('')
  const [campaignSteps, setCampaignSteps] = useState([])

  const [accountScriptList, setAccountScriptList] = useState([])

  const quillRef = React.useRef(null)

  const [scriptMessage, setScriptMessage] = useState({
    account_id: accountId, //
    campaign_id: 6, //
    step_id: 29, // criar
    name: '', // criar
    subject: '', //
    message: '', // html
    script: [
      // {
      //   side: '',
      //   message: ''
      // }
      //
      // {
      //   name: '',
      //   instructions: '',
      //   scripts: [
      //     // {
      //     //   side: '',
      //     //   message: ''
      //     // }
      //   ]
      // }
    ]
  })

  useEffect(() => {
    getAccountScripts()
    getAccountCampaigns()
    console.log('accountScriptList', accountScriptList)
  }, [refreshState])

  useEffect(() => {
    console.log('accountScriptList', accountScriptList)
  }, [accountScriptList])

  async function getAccountCampaigns() {
    if (!accountId) return
    try {
      const response = await axios.get(`/campaigns?account_id=${accountId}`, {
        headers: authHeader()
      })

      getCampaignPipelineSteps(response.data.data[0].pipeline.id)
      setScriptMessage((prev) => ({ ...prev, campaign_id: response.data.data[0].id }))
      console.log('Campanhas da conta: ', response.data.data)
    } catch (error) {
      console.error('Houve um erro ao buscar os Pipelines da conta.', error)
    }
  }

  async function getCampaignPipelineSteps(pipelineId) {
    if (!pipelineId) return

    try {
      const response = await axios.get(`/steps?account_id=${accountId}&pipeline_id=${pipelineId}`, {
        headers: authHeader()
      })
      const orderedSteps = response.data.data.sort((a, b) => a.order - b.order)
      setCampaignSteps(orderedSteps)
      console.log('Steps da Campanha: ', orderedSteps)
    } catch (error) {
      console.error('Houve um erro ao buscar os Pipelines da conta.', error)
    }
  }

  async function getAccountScripts() {
    if (!accountId) return
    try {
      const response = await axios.get(`/scripts?account_id=${accountId}`, { headers: authHeader() })
      const indexedScripts = response.data.data.reduce((acc, script) => {
        const stepId = script.step_id // Supondo que 'step_id' está presente no script
        if (!acc[stepId]) {
          acc[stepId] = []
        }
        acc[stepId].push(script)
        return acc
      }, {})
      setAccountScriptList(indexedScripts)
    } catch (error) {}
  }

  function ScriptMessage({ side, message }) {
    let option = {}

    switch (side) {
      case 'seller':
        option.side = 'Vendedor: '
        option.schema = 'whatsapp'
        option.alignment = 'flex-end'
        option.borderFormat = '15px 15px 5px 15px'
        break
      case 'action':
        option.side = ''
        option.schema = 'teal'
        option.alignment = 'center'
        option.borderFormat = '15px'
        break
      case 'alert':
        option.side = ''
        option.schema = 'red'
        option.alignment = 'center'
        option.borderFormat = '15px'
        break
      default:
        option.side = `${side}:`
        option.schema = 'messenger'
        option.borderFormat = '5px 15px 15px 15px'
        option.alignment = 'flex-start'

        break
    }

    return (
      <Flex alignSelf={option.alignment} maxWidth="70%" marginY="10px">
        <Tag
          colorScheme={option.schema}
          width="fit-content"
          p="5px 10px"
          alignItems={'start'}
          boxShadow={'sm'}
          borderRadius={option.borderFormat}
        >
          <strong style={{ marginRight: '5px' }}>{option.side}</strong>
          {message}
        </Tag>
      </Flex>
    )
  }
  // function ScriptMessage({ side, message }) {
  //   const isSeller = side === 'seller'

  //   let option = {}

  //   switch (side) {
  //     case 'seller':
  //       option.side = 'Vendedor'
  //       option.schema = 'whatsapp'
  //       option.alignment = 'flex-end'
  //       option.borderFormat = '20px 20px 5px 20px'
  //       break
  //     case 'Objeção':
  //       option.side = 'Objeção'
  //       option.schema = 'red'
  //       option.alignment = 'center'
  //       option.borderFormat = '20px'
  //       break
  //     default:
  //       option.side = side
  //       option.schema = 'messenger'
  //       option.borderFormat = '5px 20px 20px 20px'
  //       option.alignment = 'flex-start'

  //       break
  //   }

  //   return (
  //     <Flex alignSelf={isSeller ? 'flex-end' : 'flex-start'} maxWidth="70%" marginY="10px">
  //       <Tag
  //         colorScheme={isSeller ? 'whatsapp' : 'messenger'}
  //         width="fit-content"
  //         p="5px"
  //         alignItems={'start'}
  //         boxShadow={'sm'}
  //       >
  //         <strong style={{ marginRight: '5px' }}>{isSeller ? 'Vendedor:' : `${side}:`}</strong>
  //         {message}
  //       </Tag>
  //     </Flex>
  //   )
  // }

  function handleChange(e) {
    setScriptMessage({ ...scriptMessage, [e.target.name]: e.target.value })
    return
  }

  function addScriptMessageToCase(caseIndex) {
    const newCase = [...scriptMessage.script]
    newCase.push({ side: newScriptSide, message: newScriptMessage })
    setScriptMessage({ ...scriptMessage, script: newCase })
    setNewScriptMessage('')
    return
  }

  function deleteMessage(messageIndex) {
    setScriptMessage((prevState) => {
      const updatedScript = [...prevState.script]
      updatedScript.splice(messageIndex, 1) // Remove 1 element at messageIndex
      return { ...prevState, script: updatedScript }
    })
  }

  async function submitScript() {
    try {
      const payload = {
        ...scriptMessage,
        script: JSON.stringify(scriptMessage.script),
        message: editorContent
      }
      const response = await axios.post(`/scripts`, payload, { headers: authHeader() })
      setRefreshState(Date.now())
      toastSuccess('Script salvo com sucesso')
      console.log('Script salvo com sucesso:', response.data)

      setScriptMessage({
        account_id: accountId, //
        campaign_id: '', //
        step_id: '', // criar
        name: '', // criar
        subject: '', //
        message: '', // html
        script: []
      })
    } catch (error) {
      toastError('Erro ao salvar o script')
      console.log('Error ao salvar o script:', error)
    }
  }

  async function deleteScript(scriptId) {
    try {
      const response = await axios.delete(`/scripts/${scriptId}`, { headers: authHeader() })
      console.log('Script excluído com sucesso:', response.data)
      setRefreshState(Date.now()) // Trigger a refresh to update the UI
      toastSuccess('Script excluído com sucesso')
    } catch (error) {
      console.error('Erro ao excluir o script:', error)
      toastError('Erro ao excluir o script')
    }
  }

  function SalesProcess() {
    const events = [
      {
        step: 'Contatar',
        objective: 'Validar dados de contato e iniciar a prospecção das empresas nessa lista.',
        icon: 'pi pi-shopping-cart',
        description: 'Lista de empresas a serem prospectadas',
        color: '#9C27B0',
        activities: [{ type: 'task', description: 'Validar os dados da empresa' }],
        conversionRate: '80%',
        stepDuration: 'até 2 dias',
        advanceCondition:
          'Precisamos: Dados validados: telefone ou whatsapp e email. Precisa ter CNAE 7500100 Atividades veterinárias. Recomendado Capital Social acima de 20Mil. Precisamos ter os nomes dos sócios.'
      },
      {
        step: '1a Abordagem',
        objective: 'Estabelecer um contato com o decisor',
        icon: 'pi pi-cog',
        description:
          'O lead passa por uma cadência de abordagens diferentes que tem o objetivo de chamar a atenção para a nossa solução, despertar interesse e gerar levantada de mão. Nessa etapa nossa automação comercial atua enviando emails até que o cliente responda alguma das abordagens.',
        color: '#673AB7',
        activities: [{ type: 'call' }],
        conversionRate: '20%',
        stepDuration: 'até 20 dias',
        advanceCondition: 'Cliente respondeu a qualquer abordagem da automação cadenciada.'
      },
      {
        step: 'Contato',
        objective:
          'Despertar o interesse de prospects que não conhecem nossa solução, identificar se há oportunidade de negócio, gerar encantamento, iniciar a construção de credibilidade e avançar para a qualificação',
        icon: 'pi pi-shopping-cart',
        description: 'Descartar lead se não houver fit',
        color: '#FF9800',
        activities: [{ type: 'call' }],
        conversionRate: '40%',
        stepDuration: '0 a 5 dias',
        advanceCondition: 'Cliente pagou.'
      },
      {
        step: 'Qualificação',
        objective:
          'Compreender a condição do cliente e se ele possui as condições para avançar no processo comercial',
        icon: 'pi pi-shopping-cart',
        description:
          'Qualificação NTBA. Base de clientes mínima de 3000 tutores. Precisa ter uma pessoa para absorver a demanda de agendamentos no whatsapp. Possibilidade de exportar lista de clientes facilmente.',
        color: '#FF9800',
        activities: [{ type: 'call' }],
        conversionRate: '40%',
        stepDuration: '0 a 5 dias',
        advanceCondition: 'Cliente pagou.'
      },
      {
        step: 'Apresentação',
        objective: '16/10/2020 10:00',
        icon: 'pi pi-check',
        description: 'lalala',
        color: '#607D8B',
        activities: [{ type: 'call' }],
        conversionRate: '40%',
        stepDuration: '0 a 5 dias',
        advanceCondition: 'Cliente pagou.'
      },
      {
        step: 'Proposta',
        objective: '16/10/2020 10:00',
        icon: 'pi pi-check',
        description: 'lalala',
        color: '#607D8B',
        activities: [{ type: 'call' }],
        conversionRate: '40%',
        stepDuration: '0 a 5 dias',
        advanceCondition: 'Cliente pagou.'
      },
      {
        step: 'Negociação',
        objective: '16/10/2020 10:00',
        icon: 'pi pi-check',
        description: 'lalala',
        color: '#607D8B',
        activities: [{ type: 'call' }],
        conversionRate: '40%',
        stepDuration: '0 a 5 dias',
        advanceCondition: 'Cliente pagou.'
      },
      {
        step: 'Fechamento/Ganhamos',
        objective: '16/10/2020 10:00',
        icon: 'pi pi-check',
        description: 'lalala',
        color: '#607D8B',
        activities: [{ type: 'call' }],
        conversionRate: '40%',
        stepDuration: '0 a 5 dias',
        advanceCondition: 'Cliente pagou.'
      },
      {
        step: 'Perdemos',
        objective: '16/10/2020 10:00',
        icon: 'pi pi-check',
        description: 'lalala',
        color: '#607D8B',
        activities: [{ type: 'call' }],
        conversionRate: '40%',
        stepDuration: '0 a 5 dias',
        advanceCondition: 'Cliente pagou.'
      }
    ]

    const customizedMarker = (item) => {
      return (
        <span
          className="flex w-2rem h-2rem align-items-center justify-content-center text-white border-circle z-1 shadow-1"
          style={{ backgroundColor: item.color }}
        >
          <i className={item.icon}></i>
        </span>
      )
    }

    const customizedContent = (item) => {
      return (
        <Flex
          mr="10px"
          flexDirection="column"
          background={'white'}
          borderRadius={'20px'}
          p={'20px'}
          minWidth={'250px'}
        >
          <Text fontSize={'0.8rem'} fontWeight={'800'}>
            Objetivo
          </Text>
          <Text fontSize={'0.8rem'}>{item.objective}</Text>
        </Flex>
      )
    }

    return (
      <div className="card">
        <Timeline
          value={events}
          align="top"
          className="customized-timeline"
          marker={customizedMarker}
          content={customizedContent}
          layout="horizontal"
          opposite={(item) => item.step}
        />
      </div>
    )
  }

  const toast = useRef(null)
  const toastSuccess = (message) => {
    toast.current.show({ severity: 'success', summary: 'Success', detail: message })
  }
  const toastError = (message) => {
    toast.current.show({
      severity: 'error',
      summary: 'Error',
      detail: message
    })
  }

  return (
    <Flex flexDirection={'column'} gap={'20px'} mt="80px">
      <Flex background={'white'} borderRadius={'20px'} p={'20px'} flexDirection={'column'}>
        Recurso em desenvolvimento.
      </Flex>
      {accountId === 4 && (
        <>
          <Flex gap={'20px'} background={'white'} borderRadius={'20px'} p={'20px'}>
            <Text fontWeight={'800'} fontSize={'1.2rem'}>
              Quem Somos
            </Text>
          </Flex>
          <Flex gap={'20px'} background={'white'} borderRadius={'20px'} p={'20px'}>
            <Text fontWeight={'800'} fontSize={'1.2rem'}>
              O que entregamos
            </Text>
          </Flex>
          <Flex gap={'20px'} background={'white'} borderRadius={'20px'} p={'20px'}>
            <Text fontWeight={'800'} fontSize={'1.2rem'}>
              Nossos Valores
            </Text>
          </Flex>
          <Flex gap={'20px'} background={'white'} borderRadius={'20px'} p={'20px'}>
            <Text fontWeight={'800'} fontSize={'1.2rem'}>
              Nossa Visão
            </Text>
          </Flex>
          <Flex flexDirection={'column'} gap={'20px'} background={'white'} borderRadius={'20px'} p={'20px'}>
            <Text fontWeight={'800'} fontSize={'1.2rem'}>
              Metas Comerciais
            </Text>
            <Text fontSize={'1rem'}>
              <p>
                Meta mensal: <b>5 novos contratos semestrais</b> por vendedor.
              </p>
              <p>Para atingirmos esse objetivo, é necessário adicionar 40 novas empresas por dia ao funil.</p>
            </Text>
            Escreva os objetivos financeiros mensais e trimestrais. E explique baseado nos KPIs, como iremos
            alcançar essas metas.
          </Flex>
          <SalesProcess />
          <Flex flexDirection={'column'} gap={'20px'} background={'white'} borderRadius={'20px'} p={'20px'}>
            <Text fontWeight={'800'} fontSize={'1.2rem'}>
              Scripts
            </Text>
            <Flex
              flexDirection={'column'}
              padding={'20px'}
              boxShadow={'lg'}
              borderRadius={'20px'}
              width={'700px'}
            >
              <Flex gap={'20px'}>
                <Flex flexDirection={'column'}>
                  <label
                    style={{
                      color: '#A0AEC0',
                      fontSize: '12px',
                      fontWeight: '500',
                      paddingLeft: '0px'
                    }}
                  >
                    Nome
                  </label>
                  <Flex py="0.3rem" mt="-5px" alignItems={'center'}>
                    <div className="p-inputgroup flex-1">
                      <span className="p-inputgroup-addon">
                        <FaComment />
                      </span>
                      <InputText
                        value={scriptMessage.name}
                        name="name"
                        onChange={handleChange}
                        placeholder="..."
                        v-model="value1"
                        style={{
                          padding: ' 0px 10px',
                          width: '100%',
                          background: '#fafafa',
                          fontSize: '14px'
                        }}
                        className="outline-none"
                      />
                    </div>
                  </Flex>
                </Flex>
                <Flex flexDirection={'column'}>
                  <label
                    style={{
                      color: '#A0AEC0',
                      fontSize: '12px',
                      fontWeight: '500',
                      paddingLeft: '0px'
                    }}
                  >
                    Etapa do Funil
                  </label>
                  <Flex py="0.3rem" mt="-5px" alignItems={'center'}>
                    <div className="p-inputgroup flex-1 gap-1">
                      <span className="p-inputgroup-addon">
                        <FaFilter />
                      </span>
                      <Dropdown
                        value={scriptMessage.step_id}
                        name="step_id"
                        className="w-4"
                        onChange={handleChange}
                        placeholder="Selecione"
                        options={campaignSteps}
                        optionLabel="name"
                        optionValue="id"
                        pt={{
                          root: { background: '#fafafa' },
                          input: {
                            style: {
                              background: '#fafafa',
                              fontSize: '14px',
                              padding: '10px'
                            }
                          },
                          trigger: {
                            style: {
                              background: '#fafafa',
                              fontSize: '14px'
                            }
                          },
                          item: {
                            style: {
                              fontSize: '14px',
                              padding: '8px 16px',
                              width: '100%',
                              background: '#fafafa'
                            }
                          },
                          panel: {
                            style: { marginTop: '5px' }
                          }
                        }}
                      />
                    </div>
                  </Flex>
                </Flex>
                <Flex flexDirection={'column'}>
                  <label
                    style={{
                      color: '#A0AEC0',
                      fontSize: '12px',
                      fontWeight: '500',
                      paddingLeft: '0px'
                    }}
                  >
                    Assunto
                  </label>
                  <Flex py="0.3rem" mt="-5px" alignItems={'center'}>
                    <div className="p-inputgroup flex-1">
                      <span className="p-inputgroup-addon">
                        <FaComment />
                      </span>
                      <InputText
                        value={scriptMessage.subject}
                        name="subject"
                        onChange={handleChange}
                        placeholder="..."
                        v-model="value1"
                        style={{
                          padding: ' 0px 10px',
                          width: '100%',
                          background: '#fafafa',
                          fontSize: '14px'
                        }}
                        className="outline-none"
                      />
                    </div>
                  </Flex>
                </Flex>
              </Flex>
              <Flex flexDirection={'column'}>
                <label
                  style={{
                    color: '#A0AEC0',
                    fontSize: '12px',
                    fontWeight: '500',
                    paddingLeft: '0px'
                  }}
                >
                  Instruções ou recomendações
                </label>

                <ReactQuill
                  ref={quillRef}
                  value={editorContent}
                  onChange={setEditorContent}
                  theme="snow"
                  modules={{
                    toolbar: [
                      // [{ header: [1, 2, 3, 4, 5, 6, false] }],
                      // [{ size: ['small', false, 'large', 'huge'] }],
                      ['bold', 'italic', 'underline', 'strike', 'blockquote', 'color'],
                      [{ list: 'ordered' }, { list: 'bullet' }],
                      // [{ align: [] }],
                      [{ indent: '-1' }, { indent: '+1' }],
                      // [{ color: [] }, { background: [] }],
                      // [{ font: [] }],
                      ['link', 'image'],
                      ['clean'] // Adicione mais opções da barra de ferramentas conforme necessário
                    ]
                  }}
                />
              </Flex>
              <Flex flexDirection={'column'}>
                <label
                  style={{
                    color: '#A0AEC0',
                    fontSize: '12px',
                    fontWeight: '500',
                    paddingLeft: '0px'
                  }}
                >
                  Roteiro do script
                </label>
                <Flex
                  background={'#fafafa'}
                  padding={'20px'}
                  borderRadius={'10px'}
                  flexDirection={'column'}
                  alignItems={'center'}
                >
                  {scriptMessage.script.length > 0 &&
                    scriptMessage.script.map((messageItem, messageIndex) => (
                      <Flex
                        width={'100%'}
                        alignItems={'center'}
                        // flexFlow={messageItem.side === 'seller' ? 'row-reverse' : 'row'}
                      >
                        <Flex flexDirection={'column'} alignItems={'center'} width={'100%'}>
                          <ScriptMessage
                            key={messageIndex}
                            side={messageItem.side}
                            message={messageItem.message}
                          />
                        </Flex>
                        <IconButton
                          icon={<MdClose />}
                          variant="outline"
                          colorScheme="blackAlpha"
                          size={'xs'}
                          ml="5px"
                          onClick={() => deleteMessage(messageIndex)}
                        />
                      </Flex>
                    ))}
                  <Flex
                    gap="20px"
                    background="#eaeaea"
                    borderRadius={'50px'}
                    padding={'5px'}
                    justifyContent={'center'}
                    width={'100%'}
                    mt={'20px'}
                  >
                    <Dropdown
                      value={newScriptSide}
                      name="step_id"
                      className="w-4"
                      onChange={(e) => {
                        setNewScriptSide(e.target.value)
                      }}
                      placeholder="Tipo de mensagem"
                      options={[
                        { name: 'Vendedor', value: 'seller' },
                        { name: 'Atendente', value: 'Atendente' },
                        { name: 'Decisor', value: 'Decisor' },
                        { name: 'Ação', value: 'action' },
                        { name: 'Alerta', value: 'alert' }
                      ]}
                      optionLabel="name"
                      style={{ background: 'rgba(0,0,0,0)', borderRadius: '20px 0px 0px 20px' }}
                      pt={{
                        input: {
                          style: {
                            background: 'rgba(0,0,0,0)',
                            color: '#666666',
                            fontSize: '14px',
                            padding: '10px',
                            width: '300px',
                            borderRadius: '20px 0px 0px 20px'
                          }
                        },
                        trigger: {
                          style: {
                            background: 'rgba(0,0,0,0)',
                            color: '#666666',
                            fontSize: '14px'
                          }
                        },
                        item: {
                          style: {
                            fontSize: '14px',
                            padding: '8px 16px',
                            width: '100%',
                            background: '#fafafa'
                          }
                        },
                        panel: {
                          style: { marginTop: '5px' }
                        },
                        root: { background: '#fafafa' }
                      }}
                    />
                    <InputText
                      placeholder="Mensagem..."
                      value={newScriptMessage}
                      name="message"
                      onChange={(e) => {
                        setNewScriptMessage(e.target.value)
                      }}
                      v-model="value1"
                      style={{
                        padding: ' 0px 10px',
                        width: '100%',
                        color: '#666666',
                        background: 'rgba(0,0,0,0)',
                        fontSize: '14px'
                      }}
                      className="outline-none"
                    />
                    <IconButton
                      icon={<FaPlus color="#666666" />}
                      variant="outline"
                      borderRadius={'50px'}
                      // colorScheme="#blackAlpha"
                      // background="#666666"
                      // color="white"
                      onClick={() => addScriptMessageToCase()} // caseIndex
                    >
                      Adicionar
                    </IconButton>
                  </Flex>
                </Flex>
              </Flex>
              <Flex justifyContent={'flex-end'}>
                <Button
                  colorScheme="blue"
                  background={'#49BFFF'}
                  width={'fit-content'}
                  borderRadius={'50px'}
                  mt="10px"
                  onClick={submitScript}
                >
                  Salvar
                </Button>
              </Flex>
            </Flex>

            {/* {scriptMessage.script?.length > 0 &&
              scriptMessage.script.map((caseItem, index) => (
                <Flex
                  flexDirection={'column'}
                  padding={'20px'}
                  boxShadow={'lg'}
                  borderRadius={'20px'}
                  width={'600px'}
                >
                  <Flex flexDirection={'column'} mb="10px"></Flex>
                </Flex>
              ))} */}
            <Accordion>
              {campaignSteps.length > 0 &&
                campaignSteps.map((step) => (
                  <AccordionTab key={step.id} header={`Scripts de ${step.name}`}>
                    {accountScriptList[step.id]?.length > 0 ? (
                      accountScriptList[step.id].map((item, index) => (
                        <Flex
                          flexDirection={'column'}
                          key={index}
                          borderRadius={'20px'}
                          boxShadow={'lg'}
                          p="20px"
                          maxWidth={'640px'}
                          mb="20px"
                          mr="20px"
                        >
                          <Flex justifyContent={'space-between'} mb="-18px">
                            <Text fontSize={'1rem'} fontWeight={'800'} mb="13px" key={item.id}>
                              {item.name}
                            </Text>
                            <Flex>
                              <IconButton
                                icon={<FaEdit />}
                                variant="outline"
                                colorScheme="blackAlpha"
                                size={'xs'}
                                ml="5px"
                                onClick={'() => deleteMessage(messageIndex)'}
                              />
                              <IconButton
                                icon={<FaTrash />}
                                variant="outline"
                                colorScheme="blackAlpha"
                                size={'xs'}
                                ml="5px"
                                onClick={() => deleteScript(item.id)}
                              />
                            </Flex>
                          </Flex>
                          <Text fontSize={'0.85rem'} fontWeight={'500'} mb="12px" key={item.id} mt="">
                            {item.subject}
                          </Text>
                          <Text
                            dangerouslySetInnerHTML={{ __html: item.message }}
                            fontSize={'0.8rem'}
                            fontWeight={'500'}
                            mb="12px"
                            pl="12px"
                          >
                            {/* {item.message} */}
                          </Text>
                          <label
                            style={{
                              color: '#A0AEC0',
                              fontSize: '12px',
                              fontWeight: '500',
                              paddingLeft: '0px'
                            }}
                          >
                            Roteiro do script
                          </label>
                          <Flex
                            background={'#fafafa'}
                            padding={'20px'}
                            borderRadius={'10px'}
                            flexDirection={'column'}
                            alignItems={'center'}
                            maxWidth={'600px'}
                          >
                            {item.script.length > 0 &&
                              item.script.map((messageItem, messageIndex) => (
                                <Flex
                                  width={'100%'}
                                  alignItems={'center'}
                                  // flexFlow={messageItem.side === 'seller' ? 'row-reverse' : 'row'}
                                >
                                  <Flex flexDirection={'column'} alignItems={'center'} width={'100%'}>
                                    <ScriptMessage
                                      key={messageIndex}
                                      side={messageItem.side}
                                      message={messageItem.message}
                                    />
                                  </Flex>
                                </Flex>
                              ))}
                          </Flex>
                        </Flex>
                      ))
                    ) : (
                      <Text>Nenhum script disponível</Text>
                    )}
                  </AccordionTab>
                ))}
            </Accordion>
          </Flex>
          <Flex flexDirection={'column'} gap={'20px'} background={'white'} borderRadius={'20px'} p={'20px'}>
            <Text fontWeight={'800'} fontSize={'1.2rem'}>
              Matriz de Objeções
            </Text>
            <Flex flexDirection={'column'}>
              <Accordion>
                <AccordionTab header="#1 Mande mais informações">
                  <Text ml="25px">
                    <p>
                      O grande ponto sobre sobrepor essa objeção é entender quem é o lead que realmente quer
                      mais informações e quem é o lead que quer apenas te dispensar, mas está querendo fazer
                      isso de uma maneira educada.
                    </p>
                    <br />
                    <p>
                      <b>Pergunte</b>
                    </p>
                    <ul style={{ marginLeft: '20px' }}>
                      <li>O que você gostaria de receber?</li>
                      <li>Quando?</li>
                      <li>Por que?</li>
                    </ul>
                    <br />
                    <p>
                      Se ele estiver interessado, ele tenderá a lhe dar essas informações. Mas caso ele esteja
                      apenas desinteressado, tenderá a deixar claro.
                    </p>
                  </Text>
                </AccordionTab>
                <AccordionTab header="#2 Não tenho tempo agora">
                  <Text ml="25px">
                    <p>
                      Tempo é, simplesmente, uma questão de prioridade. As pessoas têm tempo e o tempo é igual
                      para todo mundo.{' '}
                    </p>
                    <p>
                      Quando o lead fala que não tem tempo, o primeiro diagnóstico que você tem que fazer é
                      que você não está despertando interesse suficiente.
                    </p>
                    <br />
                  </Text>
                  <Flex
                    maxWidth={'600px'}
                    flexDirection={'column'}
                    p="20px"
                    background={'#fafafa'}
                    borderRadius={'20px'}
                    mb="30px"
                  >
                    <ScriptMessage side={'Decisor'} message={'Não tenho tempo no momento'} />
                    <ScriptMessage
                      side={'seller'}
                      message={
                        'Esse é exatamento o motivo pelo qual a minha solução talvez seja ainda mais relevante pra você.'
                      }
                    />
                    <ScriptMessage
                      side={'seller'}
                      message={
                        'Entra o Gancho: Vamos marcar uma conversa rápida para eu te explicar como                       eu posso te ajudar a ter mais tempo e previsibilidade financeira na sua clínica.'
                      }
                    />
                    <ScriptMessage
                      side={'seller'}
                      message={
                        'E se você achar que a minha solução realmente não irá te ajudar, nós não tomamos mais o tempo um do outro.'
                      }
                    />
                  </Flex>
                </AccordionTab>
                <AccordionTab header="#3 Já tenho uma solução assim ou estou com um concorrente...">
                  <Text ml="25px">
                    <p>O lead não compreendeu o que fazemos.</p>
                    <br />
                  </Text>
                  <Flex
                    maxWidth={'600px'}
                    flexDirection={'column'}
                    p="20px"
                    background={'#fafafa'}
                    borderRadius={'20px'}
                    mb="30px"
                  >
                    <ScriptMessage
                      side={'Decisor'}
                      message={'Já tenho uma solução assim ou estou com um concorrente...'}
                    />
                    <ScriptMessage
                      side={'seller'}
                      message={
                        'Na verdade, a nossa solução é a única no mercado e até esse momento não temos concorrentes diretos que resolvem o problema que a gente resolve.'
                      }
                    />
                    <ScriptMessage
                      side={'seller'}
                      message={
                        'O que provavelmente tenha é um recurso do seu sistema que envia mensagens automaticamente para lembrar os tutores sobre tomar a próxima vacinas. Certo?'
                      }
                    />
                    <ScriptMessage
                      side={'seller'}
                      message={
                        'Fazemos muito mais que isso: o nosso sistema atua como um assistente comercial que contata diariamente uma parte de toda a sua base de clientes fazendo perguntas sobre possíveis sintomas que o animal possa estar passando. Dessa forma, sua clínica consegue identificar um estágio inicial de problemas que o animal possa estar passando e com isso agendar uma consulta e seguir para o procedimento adequado para tratar rapidamente a doença.'
                      }
                    />
                    <ScriptMessage
                      side={'seller'}
                      message={
                        'Quero muito agendar uma apresentação rápida para você conhecer a nossa solução. A gente resolve um grande problema nas clínicas que ninguém mais resolve que é aumentar a receita da clínica, com a sua base de clientes atual, promovendo ações de saúde preventiva real e relacionamento.'
                      }
                    />
                  </Flex>
                </AccordionTab>
                <AccordionTab header="#4 Estou sem orçamento">
                  <p className="m-0"></p>
                </AccordionTab>
                <AccordionTab header="#5 Última cartada ou apelo">
                  <p className="m-0"></p>
                </AccordionTab>
              </Accordion>
            </Flex>
          </Flex>
        </>
      )}
      <Toast position="bottom-center" ref={toast} />
    </Flex>
  )
}
